import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import DraggableWatchlist from './draggable-watchlist';
import './draggable-list.scss';

@withTranslation()
class DraggableWatchlistList extends Component {
  render() {
    const {
      watchlists, t, changeSort, copyWatchlist, deleteWatchlist, disabledControls,
    } = this.props;

    return (
      <ul className="watchlists__lists-table">
        <li className="heading">
          <span className="th col-drag-handle">{t('SORT')}</span>
          <span className="th col-title">{t('WATCHLISTS_TITLE')}</span>
          <span className="th col-players">{t('WATCHLISTS_PLAYERS', { context: window.genderContext })}</span>
          <span className="th col-date">{t('WATCHLISTS_ADDED')}</span>
          <span className="th col-date">{t('WATCHLISTS_UPDATED')}</span>
          <span className="th col-author ">{t('WATCHLISTS_AUTHOR')}</span>
          <span className="th col-controls" />
        </li>
        {watchlists.map((wl, i) => (
          <DraggableWatchlist
            key={wl._id}
            index={i}
            watchlist={wl}
            changeSort={changeSort}
            copyWatchlist={copyWatchlist}
            deleteWatchlist={deleteWatchlist}
            disabledControls={disabledControls}
          />
        ))}
      </ul>
    );
  }
}

DraggableWatchlistList.propTypes = {
  watchlists: PropTypes.array,
  changeSort: PropTypes.func,
  copyWatchlist: PropTypes.func,
  deleteWatchlist: PropTypes.func,
  disabledControls: PropTypes.bool,
  t: PropTypes.func,
};

export default DraggableWatchlistList;
