import React, { Component } from 'react';
import { connect } from 'react-redux';
import './cancel.scss';

import { withTranslation } from 'react-i18next';
import { cancelSubscription } from '../../../../../actions/group';
import Confirm from '../../../../confirm';
import { NotificationManager } from '../../../../notifications';

@withTranslation()
@connect((state) => {
  return {};
}, { cancelSubscription })
export default class CancelSubscription extends Component {
  constructor(props) {
    super(props);

    this.cancelSubscription = this.cancelSubscription.bind(this);
  }

  render() {
    const { t } = this.props;
    return (
      <p>
        <span className="link link-red btn" onClick={this.cancelSubscription}>{t('PLAN_CANCEL_BUTTON')}</span>
      </p>
    );
  }

  cancelSubscription() {
    const { t } = this.props;
    const CancelText = t('CANCEL_PLAN_MESSAGE');

    Confirm(
      CancelText,
      {
        cancelText: t('NO'),
        confirmText: t('YES'),
      },
    ).then(
      () => {
        NotificationManager.create({
          message: 'SAVING',
          id: 'id',
          timeOut: 3000,
        });
        this.props.cancelSubscription().then((res) => {
          if (res.payload.data) {
            NotificationManager.create({
              message: 'PLAN_SAVED_MESSAGE',
              id: 'id',
              timeOut: 3000,
            });
          } else {
            NotificationManager.create({
              message: 'PLAN_NOT_SAVED_MESSAGE',
              id: 'id',
              timeOut: 3000,
              type: 'error',
            });
          }
        });
      },
      () => {},
    );
  }
}
