import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import './substitutions.scss';
import SubstitutionControls from './substitution-controls';
import FadeScreen from '../../../ui/fade-screen';
import Icon from '../../../Icon';
import eventTracker from '../../../../helpers/event-tracker';

export default function Substitutions({
  timeline, changeInnerControl, changeOuterControl, changePlayerControl, player_id, side, values, pauseTimer, resumeTimer, changeTime,
}) {
  const [selected, setSelected] = useState(null);
  const [canUse, setCanUse] = useState({ useIn: true, useOut: true });

  useEffect(() => {
    let canUseIn = true;
    let canUseOut = true;

    _.forEach(values, (value) => {
      if (Array.isArray(value.event_type)) {
        if (value.event_type.indexOf('substitution_in') !== -1) {
          canUseIn = false;
        }
        if (value.event_type.indexOf('substitution_out') !== -1) {
          canUseIn = false;
          canUseOut = false;
        }
      }
    });

    setCanUse({ useIn: canUseIn, useOut: canUseOut });
  }, [values]);

  return (
    <div className="single-report__substitutions">
      {
        canUse.useIn ? (
          <button
            type="button"
            className="single-report__substitutions__up"
            onClick={() => {
              setSelected('substitution_in');
            }}
          >
            <Icon name="substitutionin" />
          </button>
        ) : null
      }
      {
        canUse.useOut ? (
          <button
            type="button"
            className="single-report__substitutions__down"
            onClick={() => {
              setSelected('substitution_out');
            }}
          >
            <Icon name="substitutionout" />
          </button>
        ) : null
      }
      {
        selected ? (
          <FadeScreen>
            <SubstitutionControls
              type={selected}
              values={values}
              currentTimeline={timeline}
              onSave={(time, values) => {
                const event = {
                  value: '',
                  event_type: [selected],
                  timeline: time,
                  tags: [],
                };

                if (Array.isArray(values?.event_type)) {
                  const index = values.event_type.indexOf(selected);
                  if (index === -1) {
                    event.event_type = [...values.event_type, selected];
                  }
                }

                eventTracker.trackEvent('Match', 'Use substitution button');
                changePlayerControl({ player_id, side });
                setTimeout(() => {
                  changeInnerControl({ value: 'event_type' });
                  setTimeout(() => {
                    changeOuterControl(event);
                    changeInnerControl({ value: null });
                    changeTime(time);
                    if (selected === 'substitution_in') {
                      resumeTimer();
                    } else {
                      pauseTimer();
                    }
                  }, 0);
                  setSelected(null);
                }, 0);
              }}
              onClose={() => {
                setSelected(null);
              }}
            />
          </FadeScreen>
        ) : null
      }
    </div>
  );
}

Substitutions.propTypes = {
  timeline: PropTypes.number,
  changeInnerAndOuterControl: PropTypes.func,
  changePlayerControl: PropTypes.func,
  player_id: PropTypes.string,
  side: PropTypes.string,
  changeInnerControl: PropTypes.func,
  changeOuterControl: PropTypes.func,
  values: PropTypes.object,
  pauseTimer: PropTypes.func,
  resumeTimer: PropTypes.func,
  changeTime: PropTypes.func,
};
