import './area-players.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DropTarget } from 'react-dnd';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';

import Player from './player';

import { changePosition } from '../../../../../actions/match';

@withTranslation()
@connect((state) => ({
  match: state.match,
  matches: state.matches,
  user: state.auth.user,
}), { changePosition })

@DropTarget('player', {
  drop(props, monitor, component) {
    if (monitor.didDrop()) return;
    const { side, information } = monitor.getItem();
    const target = component.target.getBoundingClientRect();
    const source = monitor.getClientOffset();
    const left = source.x - target.left;
    const top = source.y - target.top;
    const { snapshot, data } = props.match;

    if (snapshot === 'lineup') { return; }

    if (!(_.filter(props.match.current_positions[side][props.match.snapshot], (player) => player.active && player.player_id !== information.key).length === 11)) {
      props.changePosition({
        side, left, top, information,
      });
    }
  },
}, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver({ shallow: true }),
  isOverCurrent: monitor.isOver({ shallow: true }),
  canDrop: monitor.canDrop(),
  itemType: monitor.getItemType(),
}))

export default class Players extends Component {
  static propTypes = {
    current_positions: PropTypes.object,
    snapshot: PropTypes.string,
    match: PropTypes.object,
    canDrop: PropTypes.bool,
    matches: PropTypes.array,
    user: PropTypes.object,
    connectDropTarget: PropTypes.func,
  };

  constructor() {
    super();

    this.state = {
      players: {
        home: 0,
        away: 0,
      },
      started: false,
    };

    this.renderPlayers = this.renderPlayers.bind(this);
  }

  renderPlayers() {
    const { current_positions, snapshot } = this.props.match;

    if (!_.size(current_positions)) { return null; }

    const homePlayers = _.chain(_.get(current_positions, `home[${snapshot}]`, []))
      .filter((player) => player.active)
      .map((player) => (<Player key={player.player_id} side="home" {...{ player }} />));

    const awayPlayers = _.chain(_.get(current_positions, `away[${snapshot}]`, []))
      .filter((player) => player.active)
      .map((player) => (<Player key={player.player_id} side="away" {...{ player }} />));

    return [...homePlayers, ...awayPlayers];
  }

  renderGetStarted() {
    const {
      match, canDrop, matches, user, t,
    } = this.props;
    const { started } = this.state;

    if (started
      || matches?.filter((match) => match.user_id === user._id).length > 3
      || (_.get(match, 'data.home.formations') && _.get(match.data.home.formations[match.snapshot], 'id'))
      || (_.get(match, 'data.away.formations') && _.get(match.data.away.formations[match.snapshot], 'id'))
      || _.chain(match).get('current_positions.home.lineup', []).filter((player) => player.active || player.substitution).value().length
      || _.chain(match).get('current_positions.away.lineup', []).filter((player) => player.active || player.substitution).value().length
    ) {
      return null;
    }

    return (
      <div className="get-started">
        <div className="align">
          <h2>{t('GET_STARTED')}</h2>
          <ol>
            <li>{t('GET_STARTED_1')}</li>
            <li>{t('GET_STARTED_2', { context: window.genderContext })}</li>
            <li>{t('GET_STARTED_3')}</li>
            <li>{t('GET_STARTED_4', { context: window.genderContext })}</li>
          </ol>
          <div className="btn" onClick={() => { this.setState({ started: true }); }}>{t('LETS_GO')}</div>
        </div>
      </div>
    );
  }

  render() {
    const { connectDropTarget } = this.props;

    return (
      <div className="area-players">
        {
          connectDropTarget(
            <div className="area-players" ref={(target) => this.target = target}>
              { this.renderPlayers() }
            </div>,
          )
        }
        { this.renderGetStarted() }
      </div>
    );
  }
}
