import axios from 'axios';
import eventTracker from '../helpers/event-tracker';
import { ROOT_URL } from './index';

export const SET_PDF_MATCH = 'SET_PDF_MATCH';
export const CHANGE_TEAM_NOTE = 'CHANGE_TEAM_NOTE';
export const CHANGE_PLAYER_NOTE = 'CHANGE_PLAYER_NOTE';
export const CHANGE_GOAL = 'CHANGE_GOAL';
export const CHANGE_FINAL_RATING = 'CHANGE_FINAL_RATING';
export const CHANGE_SNAPSHOT = 'CHANGE_SNAPSHOT';
export const CHANGE_NOTE_TAGS = 'CHANGE_NOTE_TAGS';
export const UPDATE_SINGLE_REPORT_MINS = 'UPDATE_SINGLE_REPORT_MINS';
export const CLEAR_PDF = 'CLEAR_PDF';

export function setPdfMatch(match) {
  eventTracker.trackEvent('Report', 'Open report');
  return {
    type: SET_PDF_MATCH,
    match,
  };
}

export function changeTeamNote(params) {
  eventTracker.trackEvent('Report', 'Change team note');
  return {
    type: CHANGE_TEAM_NOTE,
    ...params,
  };
}

export function changePlayerNote(params) {
  eventTracker.trackEvent('Report', 'Change player note');
  return {
    type: CHANGE_PLAYER_NOTE,
    ...params,
  };
}

export function changeNoteTags(params) {
  eventTracker.trackEvent('Report', 'Change player note tags');
  return {
    type: CHANGE_NOTE_TAGS,
    ...params,
  };
}

export function changeGoal(params) {
  eventTracker.trackEvent('Report', 'Change goals');
  return {
    type: CHANGE_GOAL,
    ...params,
  };
}

export function changeFinalRating(params) {
  eventTracker.trackEvent('Report', 'Change final rating');

  if (params.value) {
    axios.post(`${ROOT_URL}/final-ratings/`, params);
  } else {
    axios.delete(`${ROOT_URL}/final-ratings/?player_id=${params.player_id}&match_id=${params.match_id}`, params);
  }

  return {
    type: CHANGE_FINAL_RATING,
    ...params,
  };
}

export function changeSnapshot(params) {
  eventTracker.trackEvent('Report', 'Change snapshot');
  return {
    type: CHANGE_SNAPSHOT,
    ...params,
  };
}

export function updateSingleReportMins({ ...params }) {
  eventTracker.trackEvent('Match', 'Change single report notes');
  return {
    type: UPDATE_SINGLE_REPORT_MINS,
    ...params,
  };
}

export function clearPDF() {
  return {
    type: CLEAR_PDF,
  };
}
