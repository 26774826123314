import './tabs.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { changeTab } from '../../../../actions/match';

@withTranslation()
@connect((state) => ({
  match: state.match,
}), { changeTab })

export default class RootTabs extends Component {
  static propTypes = {
    changeTab: PropTypes.func,
    match: PropTypes.object,
  };

  constructor() {
    super();

    this.renderTabs = this.renderTabs.bind(this);
  }

  renderTabs() {
    const { changeTab, t } = this.props;
    const { sidebar } = this.props.match;
    const { tabs, tab: active } = sidebar;

    return tabs.map((tab) => (<a onClick={() => { changeTab(tab); }} className={`${tab === active ? 'active' : ''}`} key={tab}>{t(tab.replace('&nbsp;', ' ').toUpperCase())}</a>));
  }

  render() {
    return (
      <div className="tabs">
        { this.renderTabs() }
      </div>
    );
  }
}
