import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory, Link } from 'react-router';
import moment from 'moment/moment';
import { withTranslation } from 'react-i18next';
import { cancelSubscription, bookAdditionalPersonLicence } from '../../../../../actions/group';
import { NotificationManager } from '../../../../notifications';
import Confirm from '../../../../confirm';
import Icon from '../../../../Icon';
import { validateBilling } from '../../../../../helpers';

@withTranslation()
@connect((state) => {
  return {
    group: state.group,
    countries: state.countries,
  };
}, { cancelSubscription, bookAdditionalPersonLicence })
export default class PaymentMethod extends Component {
  constructor(props) {
    super(props);
    this.bookAdditionalPersonLicence = this.bookAdditionalPersonLicence.bind(this);
  }

  render() {
    const {
      currentPlan, t, countries, group,
    } = this.props;

    const { currentSource } = currentPlan;
    let brand = null;

    if (!currentSource) {
      return (
        <div className="payment">
          {!currentSource ? <p><strong>{t('CHOOSE_PAYMENT_METHOD')}</strong></p> : null}
          <Link
            to="/settings/billing/add-card"
            onClick={(e) => {
              e.preventDefault();
              if (Object.keys(validateBilling(group, { countries })).length) {
                browserHistory.replace('/settings/billing/address');
              } else {
                browserHistory.replace('/settings/billing/add-card');
              }
            }}
          >
            {t('ADD_PAYMENT_METHOD')}
          </Link>
        </div>
      );
    }

    if (currentSource.type === 'card') {
      brand = _.get(currentSource, 'brand', '').toLowerCase();
    }

    if (currentSource.type === 'sepa') {
      brand = 'sepa';
    }

    return (
      <div className="payment">
        <p>
          <span className="payment-type">
            <Icon name={brand === 'visa' ? 'visa' : brand === 'mastercard' ? 'mastercard' : brand === 'american express' ? 'amex' : brand === 'sepa' ? 'sepa' : ''} />
            {brand}
            {' '}
            ...-
            {currentSource.last4}
            {brand !== 'sepa' ? (
              <span className="payment-expires">
                {t('EXPIRES')}
                :
                {' '}
                {currentSource.exp_month}
                {' '}
                /
                {' '}
                {currentSource.exp_year}
              </span>
            ) : '' }
          </span>
          <Link
            to="/settings/billing/add-card"
            onClick={(e) => {
              e.preventDefault();
              if (Object.keys(validateBilling(group, { countries })).length) {
                browserHistory.replace('/settings/billing/address');
              } else {
                browserHistory.replace('/settings/billing/add-card');
              }
            }}
          >
            {t('CHANGE_PAYMENT_METHOD')}
          </Link>
        </p>

        {
          currentPlan.subscription.cancel_at_period_end
            ? (
              <p>{t('SUBSCRIPTION_WILL_BE_CANCELED', { date: moment(currentPlan.subscription.cancel_at * 1000).format('DD.MM.YYYY') })}</p>
            )
            : null
        }
      </div>
    );
  }

  bookAdditionalPersonLicence() {
    const { t } = this.props;
    const text = (dismiss) => {
      return (
        <div>
          <h2 className="billing-confirm-settings-h2">{t('ADDITIONAL_LICENSE_PROMPT')}</h2>
        </div>
      );
    };

    Confirm(text, { context: this.context, confirmText: t('AGREE'), cancelText: t('CANCEL') })
      .then(
        () => {
          NotificationManager.create({
            message: 'SAVING',
            id: 'id',
            timeOut: 3000,
          });
          this.props.bookAdditionalPersonLicence()
            .then((res) => {
              if (res.payload.data) {
                NotificationManager.create({
                  message: 'PLAN_SAVED_MESSAGE',
                  id: 'id',
                  timeOut: 3000,
                });
              } else {
                NotificationManager.create({
                  message: 'PLAN_NOT_SAVED_MESSAGE',
                  id: 'id',
                  timeOut: 3000,
                  type: 'error',
                });
              }
            });
        },
        () => {},
      );
  }
}
