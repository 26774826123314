import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import { parsePlusTime } from '../../../../../helpers';

import { selectNote, deleteNote, setNoteMode } from '../../../../../actions/notes';
import Tag from '../tag';

import './notes-list.scss';
import { withTranslation } from 'react-i18next';

@withTranslation()
@connect((state) => ({
  dictionaries: state.notes.dictionaries,
  selectedNote: state.notes.selectedNote,
  teams: state.teams,
}), { selectNote, deleteNote, setNoteMode })
export default class NotesList extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { notes } = this.props;

    return (
      <ul className="notes-list">
        { notes.map((note, i) => this.renderListItem(note, i)) }
      </ul>
    );
  }

  renderListItem(note, i) {
    const { tagsByKey, matchesByKey } = this.props.dictionaries;
    const { t, teams } = this.props;

    const noteTags = note.tags.map((t) => tagsByKey[t]);

    if (!note) {
      return null;
    }

    const createDate = note.updatedAt || note.createdAt;
    const date = createDate ? moment(createDate).format('DD.MM.YYYY - HH:mm') : '';
    const selectedNoteId = _.get(this.props.selectedNote, '_id', null);

    const activeClass = selectedNoteId === note._id ? 'active' : '';

    let fakeTitle = '';

    if (note.match && note.time) {
      const { home, away } = note.match;
      const homeTitle = _.get(teams, `[${home}].name`, '');
      const awayTitle = _.get(teams, `[${away}].name`, '');

      fakeTitle = `${+note.time !== 0 ? parsePlusTime(note.time.toString()) : '0\''}, ${homeTitle} vs. ${awayTitle}`;
    }

    return (
      <li
        className={`notes-list__item ${activeClass}`}
        key={note._id || i}
        onClick={() => {
          const { selectedNote } = this.props;
          if (selectedNote && !selectedNote.title && !selectedNote.value) {
            this.props.setNoteMode(null);
            this.props.deleteNote(selectedNote._id);
          } else {
            this.props.selectNote(note);
          }
        }}
      >
        <div className="notes-list__item-text">
          <h4>{note.title || fakeTitle || t('NOTE_TITLE_PLACEHOLDER')}</h4>
          <p className="text-preview">{note.value || t('NOTE_TEXT_PLACEHOLDER')}</p>
          { this.renderTags(noteTags, note.event_type) }

          {
            note.match_id
              ? (
                <div className="notes-list__item-meta">
                  { _.get(matchesByKey, `[${note.match_id}].name`, '') }
                </div>
              )
              : null
          }

          <div className="notes-list__item-meta">
            <div className="author">{`${note.user.lastName}`}</div>
            <div className="date">{date}</div>
          </div>
        </div>
      </li>
    ); // active
  }

  renderTags(tags, event_type) {
    const { t } = this.props;

    return (
      <div className="notes-list__item-tag">
        {
          event_type && Array.isArray(event_type) && event_type.map((tp) => {
            return <Tag tag={t(tp)} key={tp} />;
          })
        }
        {
          tags && Array.isArray(tags) && tags.map((t) => {
            return <Tag tag={t.tag} key={t._id} />;
          })
        }
      </div>
    );
  }
}
