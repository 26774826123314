import './pdf-events.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { getPlayerImage, getTeamImage } from '../../../helpers';

@withTranslation()
@connect((state) => ({
  pdf: state.pdf,
  matches: state.matches,
  teams: state.teams,
  stadiums: state.stadiums,
}), { })
export default class pdfHeader extends Component {
  static propTypes = {
    teams: PropTypes.object,
    pdf: PropTypes.object,
    stadiums: PropTypes.object,
    changeGoal: PropTypes.func,
  };

  parsePlusTime(time) {
    const period = parseInt(time.slice(0, 1));
    const min = parseInt(time.slice(1, 4));

    if (period === 1 && min > 45) {
      return `45' + ${min - 45}`;
    } if (period === 2 && min > 90) {
      return `90' + ${min - 90}`;
    }
    if (min < 1) return "01'";
    return `${`000${min}`.substr(min < 100 ? -2 : -3)}'`;
  }

  renderRow(goalItem, i) {
    const { t } = this.props;

    const playerName = goalItem.player.short_name || goalItem.player.last_name;

    const teamLogo = <span className="team-logo" style={{ backgroundImage: `url(${getTeamImage(goalItem.team)})` }} />;
    const playerPhoto = <span className="player-photo" style={{ backgroundImage: `url(${getPlayerImage(goalItem.player)})` }} />;

    const time = this.parsePlusTime(goalItem.time.toString());

    if (goalItem.side === 'home') {
      return (
        <tr key={i} className={goalItem.event_type.indexOf('own_goal') !== -1 ? 'own-goal' : null}>
          <td className="goal-info">
            {teamLogo}
            {' '}
            <span className="goal-time">{time}</span>
            {' '}
            {playerPhoto}
            {' '}
            <span className="goal-player">{playerName}</span>
          </td>
          <td className="events-goal">{goalItem.goals}</td>
          <td>{goalItem.event_type.indexOf('own_goal') !== -1 ? t('own_goal') : null}</td>
        </tr>
      );
    }
    return (
      <tr key={i} className={goalItem.event_type.indexOf('own_goal') !== -1 ? 'own-goal' : null}>
        <td className="right">{goalItem.event_type.indexOf('own_goal') !== -1 ? t('own_goal') : null}</td>
        <td className="events-goal">{goalItem.goals}</td>
        <td className="goal-info">
          {teamLogo}
          {' '}
          <span className="goal-time">{time}</span>
          {' '}
          {playerPhoto}
          {' '}
          <span className="goal-player">{playerName}</span>
        </td>
      </tr>
    );
  }

  render() {
    const { pdf } = this.props;
    const { events, match } = pdf;

    let homegoals = 0;
    let awaygoals = 0;

    let goalsTable = ['home', 'away'].map((side) => {
      return events[side]
        .filter((e) => (e.event_type.indexOf('goal') !== -1 || e.event_type.indexOf('own_goal') !== -1))
        .map((e) => {
          return {
            ...e, side, player: match[side].players[e.player_id].information, team: match[side].team_id, event_type: e.event_type,
          };
        });
    });

    const tempGoals = [];

    goalsTable = _.flatten(goalsTable);

    goalsTable.map((e) => {
      if (e.event_type.indexOf('goal') !== -1) {
        tempGoals.push({
          ...e,
          event_type: ['goal'],
        });
      }

      if (e.event_type.indexOf('own_goal') !== -1) {
        tempGoals.push({
          ...e,
          event_type: ['own_goal'],
        });
      }
    });

    goalsTable = tempGoals;
    goalsTable = _.orderBy(goalsTable, ['time'], ['asc']);
    goalsTable = goalsTable.map((g) => {
      if (g.event_type.indexOf('goal') !== -1) {
        if (g.side === 'home') {
          return { ...g, goals: `${++homegoals} : ${awaygoals}` };
        }
        return { ...g, goals: `${homegoals} : ${++awaygoals}` };
      }
      if (g.side === 'home') {
        return { ...g, goals: `${homegoals} : ${++awaygoals}` };
      }
      return { ...g, goals: `${++homegoals} : ${awaygoals}` };
    });

    return (
      <div className="pdf-events">
        <table>
          {
            goalsTable.map((g, i) => this.renderRow(g, i))
          }
        </table>
      </div>
    );
  }
}
