import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory, Link } from 'react-router';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

import Select from 'react-select';
import { withTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import axios from 'axios';
import { changeLoading, ROOT_URL } from '../../../../../actions';
import { changeGroupPlan, getGroup, saveGroupData } from '../../../../../actions/group';
import { NotificationManager } from '../../../../notifications';
import { styleBillingForm, theme } from '../../../../ui/select_props';
import RadioSwitcher from '../../../../ui/radio-switcher';

import './billing_form.scss';
import Icon from '../../../../Icon';
import { validateBilling } from '../../../../../helpers';

function FieldInput({ input, tip, ...rest }) {
  return (
    <div className="input-container">
      { rest.label ? (
        <label>
          {rest.label}
          {tip ? <div className="inp-helper" data-tip={tip}>?</div> : null }
        </label>
      ) : null}
      <input
        {...input}
        name={rest.name}
        placeholder={rest.placeholder}
        className={`inp input ${rest.className} ${rest.meta.touched && rest.meta.error ? 'no-valid' : ''}`}
      />
      {tip ? <ReactTooltip effect="solid" arrowColor="transparent" /> : null}
    </div>
  );
}

function FieldSelect({ input, ...rest }) {
  const transformedValue = transformValue(input.value, rest.options);

  return (
    <div className="input-container">
      { rest.label ? <label>{rest.label}</label> : null }
      <Select
        {...input}
        valueKey="value"
        value={transformedValue}
        options={rest.options}
        placeholder={rest.placeholder}
        styles={styleBillingForm}
        theme={theme}
        isSearchable
        onChange={(value) => { input.onChange(value ? value.value : ''); }}
        onBlur={() => input.onBlur()}
        onFocus={input.onFocus}
        isError={rest.meta.error && rest.meta.touched}
      />
    </div>
  );
}

function transformValue(value, options) {
  const filteredOptions = options.filter((option) => {
    return option.value === value;
  });
  return filteredOptions[0] || null;
}

@withTranslation()
@connect((state) => {
  const selector = formValueSelector('billingDetails');
  return {
    user: state.auth.user,
    group: state.group,
    subscription: state.subscription,
    countries: state.countries,
    formValues: {
      isCompany: selector(state, 'isCompany'),
    },
    initialValues: state.group && state.group._id ? {
      name: state.group.name,
      firstName: state.group.firstName,
      lastName: state.group.lastName,
      clubEmail: state.group.clubEmail,
      clubPosition: state.group.clubPosition,
      city: state.group.city,
      streetNumber: state.group.streetNumber,
      isCompany: state.group.isCompany,
      zipCode: state.group.zipCode,
      country: state.group.country,
      phone: state.group.phone,
      vatNumber: state.group.vatNumber,
      billingName: state.group.billingName,
    } : undefined,
  };
}, {
  getGroup, saveGroupData, changeGroupPlan, changeLoading,
})
@reduxForm({
  form: 'billingDetails',
  validate: validateBilling,
})
export default class BilllingForm extends Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      countriesOptions: [],
    };
  }

  componentDidMount() {
    const { group } = this.props;

    if (!group) {
      this.props.getGroup();
    }

    const { countries, i18n } = this.props;
    const lng = i18n.language;
    const countriesOptions = _.chain(countries.list).orderBy(`name_${lng}`).map((country) => {
      return { value: country.alpha2code, label: _.get(country, `name_${lng}`, _.get(country, 'name_en', null)) };
    }).filter((country) => country.label && country.value)
      .value();

    this.setState({ countriesOptions });
    ReactTooltip.rebuild();
  }

  onSubmit(values) {
    const { subscription, t, query } = this.props;
    const currentSource = _.get(subscription, 'details.currentSource', null);

    this.props.changeLoading({ component: 'app', loading: true, message: values.isCompany ? 'VAT_VALIDATION_CAN_TAKE_A_WHILE' : null });

    NotificationManager.create({
      message: 'SAVING',
      id: 'id',
      timeOut: 3000,
    });
    this.props.saveGroupData(values).then((res) => {
      if (res.payload.data) {
        const checkBillingAndTaxValidity = () => {
          setTimeout(() => {
            axios.get(`${ROOT_URL}/group/validate-tax-address`).then((vRes) => {
              const { pending, success } = vRes.data;

              if (pending) {
                checkBillingAndTaxValidity();
              } else if (success) {
                this.props.changeLoading({ component: 'app', loading: false, message: null });


                NotificationManager.create({
                  message: 'GROUP_SAVED',
                  id: 'id',
                  timeOut: 3000,
                });

                if (currentSource) {
                  if (query.plan) {
                    this.props.changeGroupPlan(query.plan).then((res) => {
                      browserHistory.push('/settings/billing');
                      document.location.reload();
                    });
                  } else {
                    browserHistory.push('/settings/billing');
                  }
                } else if (!query.plan) {
                  browserHistory.push('/settings/billing/add-card');
                } else {
                  browserHistory.push(`/settings/billing/add-card?plan=${query.plan}&amount=${query.amount}`);
                }

              } else {
                this.props.changeLoading({ component: 'app', loading: false, message: null });
                NotificationManager.create({
                  message: 'GROUP_NOT_SAVED_CHECK_BILLING_ADDRESS_OR_VAT',
                  id: 'id',
                  timeOut: 3000,
                  type: 'error',
                });
              }
            });
          }, 2000);
        };

        setTimeout(() => {
          checkBillingAndTaxValidity();
        }, 2000);
      } else {
        NotificationManager.create({
          message: 'GROUP_NOT_SAVED',
          id: 'id',
          timeOut: 3000,
          type: 'error',
        });
      }
    });
  }

  render() {
    const {
      handleSubmit, t, group, formValues, query,
    } = this.props;
    const { isCompany } = formValues;
    const reason = _get(query, 'reason', null);

    const needSubmitAddress = reason === 'address';

    return (
      <div className="billing-address-form-page">
        <form className="billing-section biling-form" onSubmit={handleSubmit(this.onSubmit)}>
          <h1 className="settings-h1 overlay-h1">
            <Link to="/settings/billing">
              &lsaquo;
              {' '}
              {t('BACK')}
            </Link>
            {t('BILLING_INFORMATION')}
          </h1>
          {needSubmitAddress ? (
            <p className="biling-form__warning-message">
              <strong>{t('BILLING_ADDRESS_WARNING')}</strong>
              <br />
              {t('BILLING_ADDRESS_WARNING_MESSAGE')}
            </p>
          ) : null}
          <div className="form-row">
            <Field component={FieldInput} name="firstName" label={t('FIRST_NAME_LABEL')} placeholder={t('FIRST_NAME_PLACEHOLDER')} />
            <Field component={FieldInput} name="lastName" label={t('LAST_NAME_LABEL')} placeholder={t('LAST_NAME_PLACEHOLDER')} />
          </div>
          <div className="form-row">
            <Field component={FieldInput} name="clubEmail" label={t('CLUB_EMAIL_LABEL')} placeholder={t('CLUB_EMAIL_PLACEHOLDER')} />
            <Field component={FieldInput} name="phone" label={t('PHONE_LABEL')} placeholder={t('PHONE_PLACEHOLDER')} />
          </div>
          <div className="form-row">
            <Field component={FieldInput} name="clubPosition" label={t('POSITION_LABEL')} placeholder={t('OPTIONAL')} />
            <Field component={FieldSelect} label={t('COUNTRY')} options={this.state.countriesOptions} name="country" placeholder={t('COUNTRY_PLACEHOLDER')} />
          </div>
          <div className="form-row">
            <div className="input-container-full-width">
              <Field
                key={group && group._id}
                name="isCompany"
                component={RadioSwitcher}
                options={[
                  {
                    value: false,
                    label: t('PERSONAL_USAGE'),
                    icon: <Icon name="user" />,
                  },
                  {
                    value: true,
                    label: t('COMPANY_USAGE'),
                    icon: <Icon name="company" />,
                  },
                ]}
              />
            </div>
          </div>

          {isCompany ? (
            <div className="form-row">
              <Field component={FieldInput} name="billingName" label={t('COMPANY_NAME')} placeholder={t('COMPANY_NAME_PLACEHOLDER')} />
              <Field component={FieldInput} name="vatNumber" label={t('VAT_LABEL')} placeholder={t('VAT_PLACEHOLDER')} tip={t('WRONG_VAT_WARNING')} />
            </div>
          ) : null}

          <h4 className="settings-h4">{t('BILLING_ADDRESS')}</h4>
          <div className="form-row">
            <Field component={FieldInput} name="city" placeholder={t('CITY_PLACEHOLDER')} />
            <Field component={FieldInput} name="streetNumber" placeholder={t('STREET_NUMBER_PLACEHOLDER')} />
          </div>
          <div className="form-row">
            <Field component={FieldInput} name="zipCode" placeholder={t('POSTALCODE_PLACEHOLDER')} />
          </div>
          <div className="form-row submit-row">
            <button className="btn btn-secondary" onClick={() => { browserHistory.push('/settings/billing'); }}>{t('CANCEL')}</button>
            <button className="btn" type="submit">{t('SAVE_CHANGES')}</button>
          </div>
        </form>
      </div>
    );
  }
}

BilllingForm.propTypes = {
  handleSubmit: PropTypes.func,
  t: PropTypes.func,
  group: PropTypes.object,
  formValues: PropTypes.object,
  query: PropTypes.object,
};
