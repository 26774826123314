import React, { useState } from 'react';
import axios from 'axios';
import _ from 'lodash';
import AsyncSelect from 'react-select/async';
import { withTranslation } from 'react-i18next';
import { theme, styleHome } from '../select_props';

import { ROOT_URL } from '../../../actions';
import Option from './match-option';
import SingleValue from './match-option';
import Control from './control';
import Menu from './menu';
import LoadingMessage from './loading-message';
import {
  getTeamName, getTeamImage, getPlayerName, getPlayerImage,
} from '../../../helpers';

function MatchesSearch(props) {
  const {
    teams, t, i18n,
  } = props;
  const lng = i18n.language;

  const [ isLoading, setLoading ] = useState(false);

  return (
    <AsyncSelect
      name={props.name}
      styles={styleHome}
      cacheOptions
      clearable={false}
      maxHeight={200}
      placeholder={props.t('SEARCH')}
      isLoading={isLoading}
      noOptionsMessage={props.noResultsText}
      components={{
        Option,
        SingleValue,
        Control,
        Menu,
        LoadingMessage,
        DownChevron: null,
        CrossIcon: null,
        DropdownIndicator: null,
      }}
      hideSelectedOptions
      value={null}
      className="home-search"
      classNamePrefix="react-select"
      loadOptions={(inputValue) => {
        setLoading(true);
        return axios.get(`${ROOT_URL}/v2/matches/search`, {
          params: {
            needle: inputValue,
          },
        }).then((result) => {
          const matches = result.data.reports;
          const allPlayers = result.data.players;

          const playersByKey = _.keyBy([...allPlayers.mmPlayers, ...allPlayers.playerEntries], 'player_id');

          const options = matches.map((m) => {
            const home = teams[m.home.team_id];
            const away = teams[m.away.team_id];
            const homeName = getTeamName(home, lng, t);
            const awayName = getTeamName(away, lng, t);
            const homeImg = getTeamImage(home.id);
            const awayImg = getTeamImage(away.id);
            let playerName = null;
            let playerImg = null;

            if (m.type === 'single') {
              const player = playersByKey[m.player_id];
              playerName = getPlayerName(player);
              playerImg = getPlayerImage(player);
            }

            return {
              value: m.match_id,
              label: `${homeName} - ${awayName}`,
              type: m.type,
              playerName,
              playerImg,
              home,
              away,
              homeImg,
              homeName,
              awayName,
              awayImg,
              date: m.date,
            };
          });

          setLoading(false);
          return options;
        }).catch((err) => {
          console.error(err.message);
          setLoading(false);
          return [];
        });
      }}
      onChange={(value) => {
        props.onChange(value);
      }}
      theme={theme}
    />
  );
}

export default withTranslation()(MatchesSearch);
