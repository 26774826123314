import axios from 'axios';
import { ROOT_URL } from '.';
import eventTracker, { EVENTS } from '../helpers/event-tracker';
import { FAVORITES_RESET_FILTER } from './favorites';

export const FETCH_ENTRIES = 'FETCH_ENTRIES';
export const CHANGE_ENTRIES_FILTER = 'CHANGE_ENTRIES_FILTER';
export const MERGE_PLAYERS = 'MERGE_PLAYERS';
export const DELETE_PLAYER = 'DELETE_PLAYER';
export const ENTRIES_RESET_FILTER = 'ENTRIES_RESET_FILTER';

export function fetchAllEntries() {
  const request = axios.get(`${ROOT_URL}/entries`);

  return {
    type: FETCH_ENTRIES,
    payload: request,
  };
}

export function changeEntriesFilter(filter) {
  return {
    type: CHANGE_ENTRIES_FILTER,
    payload: filter,
  };
}

export function mergePlayers(player, destinationPlayer) {
  const request = axios.post(`${ROOT_URL}/players/merge`, { player, destinationPlayer });
  eventTracker.trackEvent('Watchlist', 'Merge custom player');

  return {
    type: MERGE_PLAYERS,
    payload: request,
  };
}

export function deletePlayer(player) {
  const request = axios.post(`${ROOT_URL}/players`, [{
    type: 'DELETE',
    team_id: player.team_id,
    player,
  }]);

  return {
    type: DELETE_PLAYER,
    payload: request,
  };
}

export function resetFilter() {
  eventTracker.trackEvent('Watchlist', 'Reset filter');

  return {
    type: ENTRIES_RESET_FILTER,
  };
}
