import axios from 'axios';
import { ROOT_URL } from './index';
import eventTracker from '../helpers/event-tracker';

export const CHANGE_PRIMARY_CONTROL = 'CHANGE_PRIMARY_CONTROL';
export const TOGGLE_SECONDARY_CONTROL = 'TOGGLE_SECONDARY_CONTROL';
export const SELECT_ALL_SECONDARY_CONTROLS = 'SELECT_ALL_SECONDARY_CONTROLS';
export const DESELECT_ALL_SECONDARY_CONTROLS = 'DESELECT_ALL_SECONDARY_CONTROLS';

export const CREATE_INDICATOR = 'CREATE_INDICATOR';
export const UPDATE_INDICATOR = 'UPDATE_INDICATOR';
export const DELETE_INDICATOR = 'DELETE_INDICATOR';

export function changePrimaryControl({ ...params }) {
  return {
    type: CHANGE_PRIMARY_CONTROL,
    ...params,
  };
}
export function toggleSecondaryControl({ ...params }) {
  return {
    type: TOGGLE_SECONDARY_CONTROL,
    ...params,
  };
}

export function selectAllSecondaryControls(position_id) {
  return {
    type: SELECT_ALL_SECONDARY_CONTROLS,
    position_id,
  };
}

export function deselectAllSecondaryControls(position_id) {
  return {
    type: DESELECT_ALL_SECONDARY_CONTROLS,
    position_id,
  };
}

export function createIndicator(indicatorDto) {
  const request = axios.post(`${ROOT_URL}/indicators/`, indicatorDto);

  eventTracker.trackEvent('Indicators', 'Create custom indicator');
  return {
    type: CREATE_INDICATOR,
    payload: request,
  };
}

export function updateIndicator(id, indicatorDto) {
  const request = axios.post(`${ROOT_URL}/indicators/${id}`, indicatorDto);

  eventTracker.trackEvent('Indicators', 'Update custom indicator');
  return {
    type: UPDATE_INDICATOR,
    payload: request,
  };
}

export function deleteIndicator(id) {
  const request = axios.delete(`${ROOT_URL}/indicators/${id}`);

  eventTracker.trackEvent('Indicators', 'Delete custom indicator');
  return {
    type: DELETE_INDICATOR,
    payload: request,
  };
}
