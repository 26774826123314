import React from 'react';
import { connect } from 'react-redux';
import Icon from '../../Icon';

function Help({ lang, indicators }) {
  const orderedIndicators = _.chain(indicators.info).filter((i) => i.public);

  const english = orderedIndicators.orderBy('name').map((i) => {
    return {
      name: i.name,
      description: i.description,
      key: i.key,
    };
  }).value();

  const german = orderedIndicators.map(
    (indicator) => {
      return {
        ...indicator,
        name_de: indicator.name_de || indicator.name,
        description: indicator.description_de || indicator.description,
      };
    },
  ).orderBy('name_de').map((i) => {
    return {
      name: i.name_de || i.name,
      description: i.description_de || i.description,
      key: i.key,
    };
  }).value();

  const portuguese = orderedIndicators.map(
    (indicator) => {
      return {
        ...indicator,
        name_pt: indicator.name_pt || indicator.name,
        description: indicator.description_pt || indicator.description,
      };
    },
  ).orderBy('name_pt').map((i) => {
    return {
      name: i.name_pt,
      description: i.description_pt,
      key: i.key,
    };
  }).value();

  switch (lang) {
    case 'de':
      return (
        <div>
          <h1 className="overlay-h1">Alle verfügbaren Indikatoren</h1>
          {
            german.map((i) => {
              return (
                <p className="help-indicator" key={i.key}>
                  <Icon name={i.key} />
                  <span className="help-indicator-body">
                    <strong>{i.name}</strong>
                    {i.description}
                  </span>
                </p>
              );
            })
          }
        </div>
      );

    case 'pt':
      return (
        <div>
          <h1 className="overlay-h1">Todos os atalhos Indicadores</h1>

          {
            portuguese.map((i) => {
              return (
                <p className="help-indicator" key={i.key}>
                  <Icon name={i.key} />
                  <span className="help-indicator-body">
                    <strong>{i.name}</strong>
                    {i.description}
                  </span>
                </p>
              );
            })
          }

        </div>
      );

    default:

      return (
        <div>
          <h1 className="overlay-h1">All available shortcuts</h1>
          {
          english.map((i) => {
            return (
              <p className="help-indicator" key={i.key}>
                <Icon name={i.key} />
                <span className="help-indicator-body">
                  <strong>{i.name}</strong>
                  {i.description}
                </span>
              </p>
            );
          })
        }
        </div>
      );
  }
}

export default connect((state) => {
  return {
    indicators: state.indicators,
  };
})(Help);
