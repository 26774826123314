import Cookies from 'js-cookie';
import {
  OPEN_SHADOW_TAB,
  CLOSE_SHADOW_TAB,
  FETCH_SHADOW_TABS,
} from '../actions/shadow-teams';

import { AUTH_LOGOUT } from '../actions/auth';

const initState = [];

export default function (state = initState, action) {
  switch (action.type) {
    case FETCH_SHADOW_TABS:
      const json = Cookies.get(`shadowTabs_${action.payload.userId}`);
      const tabs = json && json !== 'undefined' ? JSON.parse(Cookies.get(`shadowTabs_${action.payload.userId}`)) : initState;
      return tabs;
    case OPEN_SHADOW_TAB:
      const isOpen = state.findIndex((v) => action.payload.id === v) !== -1;
      let newState = [...state];
      if (!isOpen) {
        newState = [action.payload.id, ...newState];
        Cookies.set(`shadowTabs_${action.payload.userId}`, JSON.stringify(newState));
      }
      return newState;
    case CLOSE_SHADOW_TAB:
      const index = state.findIndex((v) => action.payload.id === v);
      let newTabs = [...state];
      if (index !== -1) {
        newTabs = [...state.slice(0, index), ...state.slice(index + 1)];
        Cookies.set(`shadowTabs_${action.payload.userId}`, JSON.stringify(newTabs));
      }
      return newTabs;
    case AUTH_LOGOUT:
      return initState;
    default:
      return state;
  }
}
