import React from 'react';
import { withTranslation } from 'react-i18next';
import './create-match-button.scss';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { updateReportsLimits } from '../../actions/subscription';
import { fetchAddMatch } from '../../actions';
import eventTracker from '../../helpers/event-tracker';
import EditMatch from '../ui/match-edit-form';

@withTranslation()
@connect((state) => {
  return {
    teams: state.teams,
    leagues: state.leagues,
    subscription: state.subscription,
  };
}, { fetchAddMatch, updateReportsLimits })
class CreateMatchButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isAdd: false,
    };
  }

  renderAddItem() {
    const { t, subscription } = this.props;

    if (subscription.reports.max !== -1 && subscription.reports.current >= subscription.reports.max) {
      return null;
    }

    return (
      <button
        className="btn create-match-button"
        onClick={() => {
          this.setState({ isAdd: true });
          eventTracker.trackEvent('homepage', 'click add match');
        }}
      >
        +
        {' '}
        {t('CREATE_REPORT')}
      </button>
    );
  }

  renderAddForm() {
    return (
      <div className="homepage__reports-form-container">
        <div className="homepage__report-edit">
          <EditMatch
            hideScrollbars={() => {}}
            showScrollbars={() => {}}
            onSubmit={({ newMatch }) => {
              const { subscription } = this.props;
              const {
                date, stadium, home, away, league, assign_to, type, player_id,
              } = newMatch;

              this.setState({ edit: false });

              if (subscription.reports.max !== -1 && subscription.reports.current >= subscription.reports.max) {
                return null;
              }
              this.props.updateReportsLimits({ current: ++subscription.reports.current });
              this.props.fetchAddMatch({
                date,
                stadium_id: stadium,
                league_id: league,
                home_team_id: home,
                away_team_id: away,
                assign_to,
                type,
                player_id,
              }, subscription).then((result) => {
                if (result.payload.match) {
                  eventTracker.trackEvent('homepage', 'match created');
                  browserHistory.push(`/match/${result.payload.match.match_id}`);
                }
              }).catch((err) => {
                console.log(err);
              });
              this.setState({ isAdd: false });
            }}
            onClose={() => this.setState({ isAdd: false })}
          />
        </div>
      </div>
    );
  }

  render() {
    const { isAdd } = this.state;

    return (
      <div className="create-match-button__container">
        {this.renderAddItem()}
        {isAdd ? this.renderAddForm() : null}
      </div>
    );
  }
}

CreateMatchButton.propTypes = {
  t: PropTypes.func,
  reports: PropTypes.array,
  subscription: PropTypes.object,
};

export default CreateMatchButton;
