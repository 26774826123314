import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, browserHistory } from 'react-router';
import Scrollbars from 'react-custom-scrollbars';

import './home.scss';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { changeLoading } from '../../actions';
import { fetchHome } from '../../actions/home';
import Icon from '../Icon';

import Reports from './reports';
import Players from './players';
import Watchlists from './watchlists';
import accountApi from '../../actions/account';
import PlayerSearch from '../ui/player-search';
import GenderSwitcher from './gender-switcher';
import CreateMatchButton from './create-button';

@withTranslation()
@connect((state) => ({
  user: state.auth.user,
  home: state.home,
  teams: state.teams,
  positions: state.positions,
  reports: state.reports,
}), {
  changeLoading, fetchHome,
})
class Homepage extends Component {
  componentDidMount() {
    const {
      changeLoading,
      fetchHome,
    } = this.props;

    const promises = [];
    promises.push(fetchHome());
    if (this.props.user.role.tag !== 'user') {
      promises.push(this.props.dispatch(accountApi.actions.groupUsers()));
    }

    changeLoading({ component: 'app', loading: true });
    Promise.all(promises).then(() => {
      changeLoading({ component: 'app', loading: false });
    });
  }

  render() {
    const {
      t, user, home, teams, positions, reports,
    } = this.props;

    const {demoMatch, demoSingle, players} = reports;

    return (
      <Scrollbars autoHide>
        <div className="homepage">
          <aside className="homepage__sidebar">
            <h1 className="homepage__greeting">
              {t('HOME_GREETING')}
              {' '}
              <br />
              {' '}
              <strong>{`${user.firstName}`}</strong>
            </h1>
          </aside>
          <main className="homepage__content">
            <div className="homepage_row homepage__search">
              <GenderSwitcher />
              <PlayerSearch
                teams={teams}
                positions={positions}
                noResultsText={(props) => {
                  if (!props.inputValue) { return null; }
                  return t('NO_PLAYERS_FOUND', { context: window.genderContext });
                }}
                onChange={(value) => { browserHistory.push(`/players/${value.value}/summary`); }}
              />
              <CreateMatchButton reports={home.reports} />
            </div>
            <div className="homepage__row">
              <div className="homepage__block watchlists-block">
                <div className="homepage__block-header">
                  <Link to="/watchlists/">
                    <Icon name="watchlists-home" className="watchlists-icon" />
                    {t('RECENT_WATCHLISTS')}
                    <Icon name="chevron-right-new" className="chevron" />
                  </Link>
                </div>
                <div className="homepage__block-content">
                  <Watchlists watchlists={home.watchlists} />
                </div>
              </div>
              <div className="homepage__block players-block">
                <div className="homepage__block-header">
                  <Link to="/players/recently-rated/">
                    <Icon name="player-home" className="players-icon" />
                    {t('RECENT_PLAYERS', { context: window.genderContext })}
                    <Icon name="chevron-right-new" className="chevron" />
                  </Link>
                </div>
                <div className="homepage__block-content">
                  <Players players={home.players} />
                </div>
              </div>
            </div>
            <div className="homepage__row">
              <div className="homepage__block matches-block">
                <div className="homepage__block-header">
                  <Link to="/reports/">
                    <Icon name="match-home" className="pitch-icon" />
                    {t('RECENT_REPORTS')}
                    <Icon name="chevron-right-new" className="chevron" />
                  </Link>
                </div>
                <div className="homepage__block-content">
                  <Reports reports={home.reports} players={{...home.reportPlayers, ...players}} demoMatch={demoMatch} demoSingle={demoSingle} />
                </div>
              </div>
            </div>
          </main>
        </div>
      </Scrollbars>
    );
  }
}

Homepage.propTypes = {
  t: PropTypes.func,
  user: PropTypes.object,
  changeLoading: PropTypes.func,
  fetchHome: PropTypes.func,
  home: PropTypes.object,
};

export default Homepage;
