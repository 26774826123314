import axios from 'axios';

import { ROOT_URL } from '.';

import eventTracker from '../helpers/event-tracker';

export const ADD_TO_FAVORITE = 'ADD_TO_FAVORITE';
export const REMOVE_FROM_FAVORITE = 'REMOVE_FROM_FAVORITE';
export const FETCH_FAVORITES_INFO = 'FETCH_FAVORITES_INFO';
export const FETCH_FAVORITE = 'FETCH_FAVORITE';
export const UPDATE_FAVORITE = 'UPDATE_FAVORITE';
export const RESET_FAVORITE = 'RESET_FAVORITE';
export const CHANGE_FAVORITE_FILTER = 'CHANGE_FAVORITE_FILTER';
export const FETCH_FAVORITES_LIST = 'FETCH_FAVORITES_LIST';
export const ADD_PLAYER_INFO = 'ADD_PLAYER_INFO';
export const UPDATE_PLAYER_IN_MATCH = 'UPDATE_FAVORITE_IN_MATCH';
export const DELETE_PLAYER_FROM_MATCH = 'DELETE_PLAYER_FROM_MATCH';
export const CREATE_NEW_PLAYER_FROM_FAVORITES = 'CREATE_NEW_PLAYER_FROM_FAVORITES';
export const FAVORITE_ADD_WATCHLIST = 'FAVORITE_ADD_WATCHLIST';
export const FAVORITE_REMOVE_WATCHLIST = 'FAVORITE_REMOVE_WATCHLIST';
export const FAVORITES_RESET_FILTER = 'FAVORITES_RESET_FILTER';

export function deletePlayerFromMatch(player_id, match_id) {
  return {
    type: DELETE_PLAYER_FROM_MATCH,
    payload: {
      player_id, match_id,
    },
  };
}

export function updateFavorite(player) {
  if (player.player_id !== 'demo') {
    axios.post(`${ROOT_URL}/players`, [{ player, team_id: player.team_id, type: 'MODIFY' }]);
  }
  eventTracker.trackEvent('Watchlist', 'Update player information');

  return {
    type: UPDATE_FAVORITE,
    payload: player,
  };
}

export function addWatchlist(watchlist) {
  return {
    type: FAVORITE_ADD_WATCHLIST,
    payload: watchlist,
  };
}

export function removeWatchlist(id) {
  return {
    type: FAVORITE_REMOVE_WATCHLIST,
    payload: id,
  };
}

export function updatePlayerInMatch(player) {
  return {
    type: UPDATE_PLAYER_IN_MATCH,
    payload: player,
  };
}

export function addPlayerInfo(player) {
  return {
    type: ADD_PLAYER_INFO,
    payload: player,
  };
}

export function fetchFavoritesInfo() {
  const request = axios.get(`${ROOT_URL}/favorites`);

  return {
    type: FETCH_FAVORITES_INFO,
    payload: request,
  };
}

export function fetchFavoritesList() {
  const request = axios.get(`${ROOT_URL}/favorites/list`);
  return {
    type: FETCH_FAVORITES_LIST,
    payload: request,
  };
}

export function addToFavorite(player_id, information, match_id, user_id, category) {
  if (player_id === 'demo') {
    return {
      type: 'FAKE_DEMO_ADD_TO_FAVORITES',
    };
  }

  if (!player_id && information.key) {
    player_id = information.key;
  }

  const isUserCreated = Number.isNaN(+player_id);

  const request = axios.post(`${ROOT_URL}/favorites`, {
    player_id, information, match_id, user_id, category, isUserCreated,
  });

  eventTracker.trackEvent('Watchlist', 'Add player');

  return {
    type: ADD_TO_FAVORITE,
    payload: request,
  };
}

export function removeFromFavorites(favorite_id) {
  const request = axios.delete(`${ROOT_URL}/favorites/${favorite_id}`);

  eventTracker.trackEvent('Watchlist', 'Remove player');

  return {
    type: REMOVE_FROM_FAVORITE,
    payload: favorite_id,
  };
}

export function fetchFavorite(id) {
  const request = axios.get(`${ROOT_URL}/favorites/${id}`);

  return {
    type: FETCH_FAVORITE,
    payload: request,
  };
}

export function resetFavorite() {
  return {
    type: RESET_FAVORITE,
    payload: null,
  };
}

export function changeFavoritesFilter(filter) {
  return {
    type: CHANGE_FAVORITE_FILTER,
    payload: filter,
  };
}

export function createNewPlayer(player) {
  eventTracker.trackEvent('Watchlist', 'Create new player from watchlist');

  return {
    type: CREATE_NEW_PLAYER_FROM_FAVORITES,
    payload: player,
  };
}

export function resetFilter() {
  eventTracker.trackEvent('Watchlist', 'Reset filter');

  return {
    type: FAVORITES_RESET_FILTER,
  };
}
