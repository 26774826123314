import React from 'react';
import { components } from 'react-select';
import { useTranslation } from 'react-i18next';

export default function (props) {
  const { t } = useTranslation();
  const inputValue = props?.selectProps?.inputValue;
  return (
    <components.LoadingMessage {...props}>
      {inputValue ? `${t('LOADING')}...` : t('START_TYPING_FOR_SEARCH_REPORT') }
    </components.LoadingMessage>
  );
}
