import { createStore, applyMiddleware, compose } from 'redux';
import promise from 'redux-promise';
import ReduxThunk from 'redux-thunk';
import rootReducer from '../reducers';

export default function configureStore(initialState) {
  let middleware = applyMiddleware(promise);
  let enhancer;

  if (process.env.NODE_ENV !== 'production') {
    const middlewares = [ReduxThunk, promise];
    middleware = applyMiddleware(...middlewares);

    enhancer = compose(
      middleware,
    );
  } else {
    const middlewares = [ReduxThunk, promise];
    middleware = applyMiddleware(...middlewares);
    enhancer = compose(middleware);
  }

  const store = createStore(rootReducer, initialState, enhancer);

  return store;
}
