import React from 'react';
import * as PropTypes from 'prop-types';
import Icon from '../../Icon';
import PlayerImage from '../../player-image';
import DataItemChangeableSimple from '../../favorite/components/data_item_changeable_simple';
import DataItemChangeable from '../../favorite/components/data_item_changeable';
import { generateFootOptions } from '../../../constants';

import './player-information.scss';

export default function PlayerInformation({
  isEdit, onCancelClick, onSaveClick, onEditClick, t, onDataChange, player, notEditable,
}) {
  const valueRenderer = (value) => value;
  return (
    <div className="player-information__main">
      {isEdit ? (
        <div className="player-information__edit-buttons">
          <button
            className="player-information__edit-button cancel"
            onClick={onCancelClick}
          >
            {t('CANCEL')}
          </button>
          <button
            className="player-information__edit-button save"
            onClick={onSaveClick}
          >
            {t('SAVE')}
          </button>
        </div>
      ) : (
        !notEditable ? (
          <div className="player-information__edit-buttons">
            <button
              className="player-information__edit-button edit"
              onClick={onEditClick}
            >
              <Icon name="pencil" />
              {' '}
              {t('EDIT')}
            </button>
          </div>
        ) : null
      )}
      <div className="content-row">
        <div className="player-data__image">
          <PlayerImage className="player-image" src={player.playerImage} />
        </div>
        <div className="player-data__passport">
          <div className="player-data__firstName">
            <DataItemChangeableSimple
              label={t('FIRST_NAME')}
              value={player.first_name}
              name="first_name"
              inputValue={player.first_name}
              type="text"
              onChange={onDataChange}
              isChanging={!!isEdit}
              valueRenderer={valueRenderer}
            />
          </div>
          <div className="player-data__lastName">
            <DataItemChangeableSimple
              label={t('LAST_NAME')}
              value={player.last_name}
              name="last_name"
              inputValue={player.last_name}
              type="text"
              onChange={onDataChange}
              isChanging={!!isEdit}
              valueRenderer={valueRenderer}
            />
          </div>
          <div className="player-data__nation">{player.country}</div>
        </div>
      </div>
      <div className="content-row align-end white-space-wrap">
        <DataItemChangeable
          label={t('NUMBER_SHORT')}
          value={player.jersey}
          name="jersey"
          inputValue={player.jersey}
          type="number"
          onChange={onDataChange}
          isChanging={!!isEdit}
          valueRenderer={valueRenderer}
        />
        <DataItemChangeable
          label={t('AGE')}
          value={player.age}
          name="birth_date"
          inputValue={player.birth_date}
          type="date"
          onChange={onDataChange}
          isChanging={!!isEdit}
        />
        <DataItemChangeable
          label={t('FOOT')}
          value={player.foot_translated}
          type="select"
          name="foot"
          inputValue={{
            label: t(player.foot),
            value: t(player.foot),
          }}
          options={generateFootOptions(t)}
          onChange={onDataChange}
          isChanging={!!isEdit}
        />
        <DataItemChangeable
          label={t('WEIGHT')}
          value={player.weight && `${player.weight}kg` || 'N/D'}
          inputValue={player.weight}
          type="number"
          name="weight"
          onChange={onDataChange}
          isChanging={!!isEdit}
        />
        <DataItemChangeable
          label={t('HEIGHT')}
          value={player.height && `${player.height}cm` || 'N/D'}
          inputValue={player.height}
          type="number"
          name="height"
          onChange={onDataChange}
          isChanging={!!isEdit}
        />
      </div>
    </div>
  );
}

PlayerInformation.propTypes = {
  isEdit: PropTypes.bool,
  onCancelClick: PropTypes.func,
  onSaveClick: PropTypes.func,
  onEditClick: PropTypes.func,
  t: PropTypes.func,
  onDataChange: PropTypes.func,
  player: PropTypes.object,
  notEditable: PropTypes.bool,
};
