import _ from 'lodash';
import localforage from 'localforage';
import {
  AUTH_LOGIN_REQUEST_SUCCESS, AUTH_LOGIN_REQUEST_ERROR,
  AUTH_REGISTER_REQUEST_SEND, AUTH_REGISTER_REQUEST_SUCCESS, AUTH_REGISTER_REQUEST_ERROR,
  AUTH_LOGOUT, UPDATE_FAVORITE_SORTS, UPDATE_SHADOW_TEAMS_TABS, UPDATE_FAVORITES_TABS, UPDATE_WATCHLIST_SORTS,
  UPDATE_USER, UPDATE_SURVEY,
} from '../actions/auth';

import { USE_GROUP_CREDITS, FETCH_CREDITS_AND_BONUS_MILESTONES } from '../actions/group';

import eventTracker from '../helpers/event-tracker';

const initState = {
  user: null,
  signInError: null,
  signUpSuccess: false,
  signUpErrors: [],
};

export default function (state = initState, action) {
  switch (action.type) {
    case UPDATE_USER:
      return {
        ...state,
        user: { ...state.user, ...action.payload },
      };

    case AUTH_LOGIN_REQUEST_SUCCESS:
      localStorage.setItem('userId', action.payload._id);
      localStorage.setItem('selectedGender', action.payload.selectedGender);
      eventTracker.trackUserId(action.payload._id);
      eventTracker.trackPageView();
      return { ...state, user: action.payload, signInError: null };

    case AUTH_LOGIN_REQUEST_ERROR:
      return { ...state, signInError: action.payload };

    case AUTH_LOGOUT:
      localStorage.removeItem('userId');
      localStorage.removeItem('selectedGender');
      eventTracker.resetUserId();
      if (action.payload && action.payload.data) {
        return { ...state, sessionId: null, user: null };
      }
      return state;

    case AUTH_REGISTER_REQUEST_ERROR:
      return { ...state, signUpErrors: action.payload };

    case AUTH_REGISTER_REQUEST_SUCCESS:
      return { ...state, signUpSuccess: true };

    case AUTH_REGISTER_REQUEST_SEND:
      return { ...state, signUpSuccess: false };

    case UPDATE_FAVORITE_SORTS:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      };

    case UPDATE_WATCHLIST_SORTS:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      };

    case UPDATE_FAVORITES_TABS:
      return {
        ...state,
        user: {
          ...state.user,
          favoritesTabs: [...action.payload],
        },
      };

    case UPDATE_SHADOW_TEAMS_TABS:
      return {
        ...state,
        user: {
          ...state.user,
          shadowTeamsTabs: [...action.payload],
        },
      };

    case USE_GROUP_CREDITS:
      return {
        ...state,
        user: {
          ...state.user,
          group: {
            ...state.user.group,
            credits: _.get(action.payload, 'data.newCredits', 0),
          },
        },
      };
    case FETCH_CREDITS_AND_BONUS_MILESTONES:
      return {
        ...state,
        user: {
          ...state.user,
          group: {
            ...state.user.group,
            credits: _.get(action.payload, 'data.credits', 0),
            bonusMilestones: _.get(action.payload, 'data.bonusMilestones', 0),
          },
        },
      };

    case UPDATE_SURVEY: {
      return {
        ...state,
        user: {
          ...state.user,
          group: {
            ...state.user.group,
            ...action.payload,
          },
        },
      };
    }

    default:
      return state;
  }
}
