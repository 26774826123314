import React from 'react';
import './team-view.scss';

export default function (props) {
  return (
    <div className="team-view">
      {props.noImage ? null : (
        <div
          className="team-view__img"
          style={{ backgroundImage: `url(https://static.sp7f.com/teams/${props.value}.jpg)` }}
        />
      )}
      <div className="team-view__label">
        {props.label}
      </div>
    </div>
  );
}
