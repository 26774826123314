import './defaultlineup.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import { withTranslation } from 'react-i18next';
import { saveDefaultLineup } from '../../../../actions/match';

@withTranslation()
class DefaultLineups extends Component {
  static propTypes = {
    match: PropTypes.object,
    positions: PropTypes.object,
    height: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    saveDefaultLineup: PropTypes.func,
    user: PropTypes.object,
    t: PropTypes.func,
  };

  constructor() {
    super();

    this.saveLineup = this.saveLineup.bind(this);
    this.isDefaultLineup = this.isDefaultLineup.bind(this);
    this.renderButton = this.renderButton.bind(this);
  }

  saveLineup(side) {
    const {
      match, saveDefaultLineup,
    } = this.props;

    const team = _.get(match, `data[${side}].team_id`, null);
    const lineup = _.get(match, `current_positions[${side}].lineup`, {});
    const formation = _.get(match, `data[${side}].formations.lineup.id`, null);
    const lineupObject = _.chain(lineup).map((player) => {
      return { player_id: player.player_id, position_id_detail: player.position_id_detail, substitution: player.substitution };
    }).filter((p) => p.position_id_detail || p.substitution).value();

    saveDefaultLineup(lineupObject, team, formation, side);
  }

  isDefaultLineup(side) {
    const { match } = this.props;

    const lineup = _.get(match, `current_positions[${side}].lineup`, {});
    const defaultLineup = _.get(match, `data[${side}].defaultLineup`, {});
    const formation = _.get(match, `data[${side}].formations.lineup.id`, null);
    const lineupObject = {
      formation_id: formation,
      lineup: _.chain(lineup).map((player) => {
        return { player_id: player.player_id, position_id_detail: player.position_id_detail, substitution: player.substitution };
      }).filter((p) => p.position_id_detail || p.substitution).value(),
    };

    if (!lineupObject.lineup.length && _.isEmpty(defaultLineup)) return true;

    if (+defaultLineup.formation_id !== +lineupObject.formation_id) {
      return false;
    }

    if (lineupObject.lineup.length !== defaultLineup.lineup.length) {
      return false;
    }

    let result = true;

    lineupObject.lineup.map((p) => {
      const { player_id, position_id_detail, substitution } = p;
      const defaultPos = defaultLineup.lineup.find((p) => player_id === p.player_id);
      if (!defaultPos) { result = false; return; }
      if (defaultPos.position_id_detail !== position_id_detail) { result = false; return; }
      if (defaultPos.substitution !== substitution) { result = false; }
    });

    return result;
  }

  renderButton(side) {
    const {
      t,
    } = this.props;

    const isDefaultLineup = this.isDefaultLineup(side);

    return (
      <div
        className={`save-default-lineup save-default-lineup_${side}`}
        key={side}
      >
        {
          !isDefaultLineup ? (
            <a
              className="save-default-lineup__btn"
              onClick={() => this.saveLineup(side)}
            >
              {t('SAVE_LINEUP', side)}
            </a>
          ) : null
        }
      </div>
    );
  }

  render() {
    const { width } = this.props.match.layout;

    return (
      <div className="default-lineup-controls" style={{ width }}>
        { ['home', 'away'].map((side) => this.renderButton(side)) }
      </div>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    match: state.match,
    positions: state.positions,
    teams: state.teams,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, { saveDefaultLineup })(DefaultLineups);
