import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Icon from '../../Icon';

import './invite.scss';

@withTranslation()
class Invite extends Component {
  render() {
    const { invite, t } = this.props;
    const iconName = invite.status === 'subscribe_pro' || invite.status === 'subscribe_free' ? 'celebrate' : 'present';
    const showCheckmark = invite.status === 'subscribe_pro' || invite.status === 'subscribe_free';
    let message = '';
    let profit = 0;
    switch (invite.status) {
      case 'new':
        message = 'INVITATION_PENDING';
        break;
      case 'registered':
        message = 'INVITATION_REGISTERED';
        break;
      case 'active':
        message = 'INVITATION_ACCOUNT_ACTIVATED';
        break;
      case 'subscribe_free':
        message = 'INVITATION_FREE_SUBSCRIPTION';
        profit = 1;
        break;
      case 'subscribe_pro':
        message = 'INVITATION_PRO_SUBSCRIPTION';
        profit = 10;
        break;
      default:
        message = '';
    }

    return (
      <div className="invite-item">
        <div className="invite-item__col invite-item__status"><Icon name={iconName} /></div>
        <div className="invite-item__col invite-item__email">{invite.inviteeEmail}</div>
        <div className="invite-item__col invite-item__message">{t(message)}</div>
        <div className="invite-item__col invite-item__profit">{profit ? `${profit} Cr` : ''}</div>
        <div className="invite-item__col invite-item__control">
          { showCheckmark ? <Icon name="checkmark" /> : null }
        </div>
      </div>
    );
  }
}

Invite.propTypes = {
  invite: PropTypes.object,
  t: PropTypes.func,
};

export default Invite;
