import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { changeInnerAndOuterControl, changePlayerControl } from '../../../../actions/match';
import IndicatorsList from './indicators-list';
import Indicator from './indicator';

import './shortcuts-controls.scss';
import Icon from '../../../Icon';

@withTranslation()
@connect((state) => {
  return {
    match: state.match,
    indicators: state.indicators,
    formations: state.formations,
    positions: state.positions,
  };
}, { changeInnerAndOuterControl, changePlayerControl })
export default class ShortcutsConrols extends React.Component {
  constructor(props) {
    super(props);
    this.setPlayer = this.setPlayer.bind(this);
  }

  renderShortcut(shortcut) {
    const {
      indicators, i18n, changeInnerAndOuterControl, match, scrollTop,
    } = this.props;
    const lng = i18n.language;
    const {
      side, player_id, current_values, layout,
    } = match;
    const value = current_values[side][player_id];
    const information = indicators.info;
    const shortcutInfo = information[shortcut];
    return (
      <Indicator
        scrollTop={scrollTop}
        values={value}
        key={shortcut}
        lng={lng}
        id={shortcut}
        info={shortcutInfo}
        changeInnerAndOuterControl={changeInnerAndOuterControl}
        setPlayer={this.setPlayer}
      />
    );
  }

  setPlayer() {
    const { match, changePlayerControl } = this.props;
    const { player_id, side } = match;
    changePlayerControl({ player_id, side });
  }

  render() {
    const {
      t, changeInnerAndOuterControl, indicators, activePosition, match, positions, scrollTop,
    } = this.props;
    const {
      side, player_id, current_values, layout,
    } = match;
    const { mediaQuery, height } = layout;

    if (_.isEmpty(activePosition)) {
      return null;
    }

    const value = current_values[side][player_id];
    const { position_id_upper } = activePosition;

    const primaryIndicators = _.get(indicators, `positions[${position_id_upper}].primary`, [])
      .filter((i) => _.get(indicators, ['info', i, 'public'], true));
    const otherIndicators = _.get(indicators, `positions[${position_id_upper}].other`, [])
      .filter((i) => _.get(indicators, ['info', i, 'public'], true));
    const disabledIndicators = _.get(indicators, `positions[${position_id_upper}].disabled`, [])
      .filter((i) => _.get(indicators, ['info', i, 'public'], true));

    const information = indicators.info;

    const indicatorsByGroup = _.chain(otherIndicators).filter((control) => disabledIndicators.indexOf(control) === -1).groupBy((indicator) => indicators.info[indicator].class).value();
    const groupsName = ['Off the Ball', 'On the Ball', 'Physis'];
    const controlsHeight = mediaQuery !== 'mobile' && height >= 700 ? layout.height - 251 - 125 : 'auto'; // body height - player info - shortcuts

    return (
      <div className="single-report__shortcuts-controls">
        <div className="row indented single-report__shortcuts-controls__row  single-report__shortcuts-controls__primary">
          <div className="col-12 indented">
            <div className="title-with-help">
              <h4>{t('SHORTCUTS_SR')}</h4>
              <p className="controls-list-help">
                <a
                  target="_blank"
                  href="https://intercom.help/scoutpad/en/articles/1994409-where-can-i-change-my-scouting-indicators"
                  rel="noreferrer"
                >
                  <Icon
                    name="help-question-mark"
                  />
                  {' '}
                  {t('INDICATOR_HOW_TO_ADD')}
                </a>
              </p>
            </div>

            {primaryIndicators.map((primary) => this.renderShortcut(primary))}
          </div>
        </div>
        <div
          className="row indented single-report__shortcuts-controls__row single-report__shortcuts-controls__groups"
          style={{ height: controlsHeight }}
        >
          {groupsName.map((group) => (
            <IndicatorsList
              values={value}
              key={group}
              setPlayer={this.setPlayer}
              changeInnerAndOuterControl={changeInnerAndOuterControl}
              classNames="col-4 indented"
              title={group}
              indicators={indicatorsByGroup[group] || []}
              information={information}
              mediaQuery={mediaQuery}
              height={height}
              scrollTop={scrollTop}
            />
          ))}
        </div>
      </div>
    );
  }
}

ShortcutsConrols.propTypes = {
  t: PropTypes.func,
  changeInnerAndOuterControl: PropTypes.func,
  indicators: PropTypes.array,
  match: PropTypes.object,
  formations: PropTypes.array,
};
