import React from 'react';

import './single.scss';

import { useTranslation } from 'react-i18next';
import Player from './player';
import Match from './match';
import Rates from './rates';

function Index() {
  const {
    t,
    i18n,
  } = useTranslation();
  return (
    <div>
      <Match/>
      <Player/>
      <Rates t={t} lng={i18n.language}/>
    </div>
  );
}

Index.propTypes = {};

export default Index;
