import _ from 'lodash';
import axios from 'axios';

import { FETCH_INDICATORS, ROOT_URL } from '../actions/index';
import {
  CHANGE_PRIMARY_CONTROL, TOGGLE_SECONDARY_CONTROL, SELECT_ALL_SECONDARY_CONTROLS, DESELECT_ALL_SECONDARY_CONTROLS, CREATE_INDICATOR, DELETE_INDICATOR,
} from '../actions/indicators';
import { AUTH_LOGIN_REQUEST_SUCCESS } from '../actions/auth';
import { UPPER_POSITIONS } from '../constants';

const INITIAL_STATE = {
  info: {
    notes: {
      name: 'Notes',
      name_de: 'Notizen',
      short: 'Notes',
      short_de: 'Notizen',
    },
    more: {
      name: 'More',
      name_de: 'Mehr',
      short: 'More',
      short_de: 'Mehr',
    },
    favorite: {
      name: 'Favorite',
    },
  },
  constant: ['notes', 'more'],
  primary: ['tackle', 'backbone', 'long_pass', 'vision'],
  other: [],
  disabled: [],
  primary_goalkeeper: ['save', 'rush_out', 'reflexes', 'claim'],
  other_goalkeeper: [],
  positions: {
    1: { primary: ['save', 'rush_out', 'reflexes', 'claim'] },
    2: { primary: ['tackle', 'backbone', 'long_pass', 'vision'] },
    3: { primary: ['dribbling', 'speed', 'offensive_transition', 'crossing'] },
    4: { primary: ['anticipation', 'tackle', 'short_pass', 'strength'] },
    5: { primary: ['vision', 'technique', 'solutions', 'agility'] },
    6: { primary: ['vision', 'technique', 'shot', 'agility'] },
    7: { primary: ['speed', 'dribbling', 'defensive_transition', 'crossing'] },
    8: { primary: ['shot', 'composure', 'first_touch', 'strength'] },
  },
};

export default function (state = INITIAL_STATE, action) {
  let clone;

  switch (action.type) {
    case FETCH_INDICATORS: {
      const { positions } = state;
      const defaultPrimary = state.primary;

      UPPER_POSITIONS.forEach((pos) => {
        const dict = _.get(action, 'payload.indicators', {});
        const primary = _.get(positions, `[${pos.id}].primary`, defaultPrimary).filter((indicator) => dict[indicator]);
        const disabled = _.get(positions, `[${pos.id}].disabled`, []);

        let defaultOther;
        if (pos.id !== 1) {
          defaultOther = _.filter(dict, (indicator) => primary.indexOf(indicator.key) === -1 && indicator.Fielder).map((indicator) => indicator.key);
        } else {
          defaultOther = _.filter(dict, (indicator) => primary.indexOf(indicator.key) === -1 && (indicator.Fielder || indicator.Goalkeeper)).map((indicator) => indicator.key);
        }
        const other = _.get(positions, `[${pos.id}].other `, defaultOther).filter((indicator) => dict[indicator]);

        if (primary.length < 4) {
          const index = other.findIndex((indicator) => dict[indicator].public === true);
          primary.push(other[index]);
          other.splice(index, 1);
        }

        positions[pos.id] = {
          primary, disabled, other,
        };
      });

      return {
        ...state,
        info: {
          ...action.payload.indicators,
        },
        primary: state.primary.length ? state.primary : _.filter(action.payload.indicators, (indicator) => indicator.primary).map((indicator) => indicator.key),
        other: state.primary.length
          ? _.filter(action.payload.indicators, (indicator) => state.primary.indexOf(indicator.key) === -1 && indicator.Fielder).map((indicator) => indicator.key)
          : _.filter(action.payload.indicators, (indicator) => !indicator.primary && indicator.Fielder).map((indicator) => indicator.key),
        other_goalkeeper: _.filter(action.payload.indicators, (indicator) => state.primary_goalkeeper.indexOf(indicator.key) === -1 && indicator.Goalkeeper).map((indicator) => indicator.key),
        positions,
      };
    }

    case AUTH_LOGIN_REQUEST_SUCCESS: {
      clone = _.cloneDeep(state);
      if (action.payload.controls && action.payload.controls.primary && action.payload.controls.primary.length) {
        clone.primary = action.payload.controls.primary;
      }
      if (action.payload.controls && action.payload.controls.disabled) {
        clone.disabled = action.payload.controls.disabled;
      }
      if (action.payload.controls && action.payload.controls.positions) {
        clone.positions = action.payload.controls.positions;
      }
      return clone;
    }

    case CHANGE_PRIMARY_CONTROL: {
      clone = _.cloneDeep(state);

      const { index, control, position_id } = action;

      clone.positions[position_id].primary[index] = control;
      clone.positions[position_id].other = state.positions[position_id].other.filter((other) => other !== control);
      clone.positions[position_id].other = [
        ...clone.positions[position_id].other,
        state.positions[position_id].primary[index],
      ];

      axios.post(`${ROOT_URL}/user/controls`, {
        controls: {
          primary: clone.primary,
          disabled: clone.disabled,
          positions: clone.positions,
        },
      });

      return clone;
    }

    case TOGGLE_SECONDARY_CONTROL: {
      clone = _.cloneDeep(state);

      const disabled = _.get(state, `positions[${action.position_id}].disabled`, []);

      if (disabled.indexOf(action.key) === -1) {
        clone.positions[action.position_id].disabled.push(action.key);
      } else {
        clone.positions[action.position_id].disabled = state.positions[action.position_id].disabled.filter((control) => control !== action.key);
      }

      axios.post(`${ROOT_URL}/user/controls`, {
        controls: {
          primary: clone.primary,
          disabled: clone.disabled,
          positions: clone.positions,
        },
      });

      return clone;
    }

    case SELECT_ALL_SECONDARY_CONTROLS:
      clone = _.cloneDeep(state);
      clone.positions[action.position_id].disabled = [];

      axios.post(`${ROOT_URL}/user/controls`, {
        controls: {
          primary: clone.primary,
          disabled: clone.disabled,
          positions: clone.positions,
        },
      });

      return clone;

    case DESELECT_ALL_SECONDARY_CONTROLS:
      clone = _.cloneDeep(state);
      clone.positions[action.position_id].disabled = [...clone.positions[action.position_id].other];

      axios.post(`${ROOT_URL}/user/controls`, {
        controls: {
          primary: clone.primary,
          disabled: clone.disabled,
          positions: clone.positions,
        },
      });
      return clone;
    default:
      return state;
  }
}
