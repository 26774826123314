import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import PlayerInformation from '../../ui/player-information';
import PlayerTeamInformation from '../../ui/player-team-information';
import { formatPlayerData, getWatchlistCategory } from '../../../helpers';
import AddToFavorite from '../../ui/add-to-favorites';
import Icon from '../../Icon';

@withTranslation()
@connect((state) => {
  return {
    positions: state.positions,
    match: state.pdf.match,
    teams: state.teams,
    leagues: state.leagues,
    player: _.get(state.pdf.match, `[${state.pdf.side}].players[${state.pdf.player_id}].information`, null),
    player_id: state.pdf.player_id,
    side: state.pdf.side,
    favorites: state.favorites,
  };
})
class Player extends Component {
  constructor(props) {
    super(props);

    this.state = { edit: false };

    this.onEditClick = this.onEditClick.bind(this);
    this.onCancelClick = this.onCancelClick.bind(this);
    this.onSaveClick = this.onSaveClick.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onEditClick() {
    const { player } = this.props;

    this.setState({
      edit: {
        ...player,
      },
    });
  }

  onCancelClick() {
    this.setState({
      edit: null,
    });
  }

  onSaveClick() {
    const {
      match, updatePlayerInMatch, changePlayerControl, side, player_id,
    } = this.props;
    const { edit } = this.state;
    const { match_id } = match.data;

    changePlayerControl({ player_id, side });
    updatePlayerInMatch({ ...edit }, match_id);
    this.setState({
      edit: null,
    });
  }

  onChange(property) {
    const { player } = this.props;
    if (!player) return;
    const { value } = property;
    if (value.value) {
      property.value = value.value;
    }

    const newPlayer = {
      ...player,
      ...this.state.edit,
    };
    newPlayer[property.name] = property.value;

    this.setState({
      edit: {
        ...newPlayer,
      },
    });
  }

  render() {
    const {
      t, teams, i18n, leagues, positions, player, favorites, player_id,
    } = this.props;
    const lng = i18n.language;

    const positionOptions = _.chain(positions)
      .filter((p) => p.upper_position)
      .orderBy(['upper_rank'], ['asc'])
      .map((p) => ({ value: p.id, label: _.get(p, `desc_short_${lng}`, _.get(p, 'desc_short', 'N/D')) }))
      .value();

    const formattedPlayer = formatPlayerData(player, t, teams, lng, leagues, positions);
    const { list } = favorites;
    const fav = list.find((f) => f.player_id == player_id);

    return (
      <div className="pdf-block">
        <div className="col single-pdf__player player-information">
          <PlayerInformation
            isEdit={false}
            notEditable
            onCancelClick={this.onCancelClick}
            t={t}
            onSaveClick={this.onSaveClick}
            onEditClick={this.onEditClick}
            player={formattedPlayer}
            onDataChange={this.onChange}
          />
        </div>
        <div className="col single-pdf__team">
          <PlayerTeamInformation
            isEdit={false}
            notEditable
            t={t}
            onDataChange={this.onChange}
            position_options={positionOptions}
            player={formattedPlayer}
            hideValue
          />
        </div>
        <div className="col single-pdf__category">
          <h4 className="pdf-small-title">{t('CATEGORY')}</h4>
          <AddToFavorite
            match_id={null}
            player={player}
            added_message={t('WATCHLIST_CATEGORY_CHANGED')}
          >
            <div className="favorite-selector">
              <Icon name="favorite" />
              {' '}
              {getWatchlistCategory(fav, t)}
              {' '}
              <Icon name="chevron_down" className="chevron-down" />
            </div>
          </AddToFavorite>
        </div>
      </div>
    );
  }
}

Player.propTypes = {
  t: PropTypes.func,
  positions: PropTypes.array,
  match: PropTypes.object,
  teams: PropTypes.array,
  leagues: PropTypes.array,
  player: PropTypes.object,
  player_id: PropTypes.string,
  side: PropTypes.string,
  i18n: PropTypes.object,
  favorites: PropTypes.object,
};

export default Player;
