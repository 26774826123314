import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';

import { getCurrencySign, getPaymentMethodName, renderDate } from '../../../../helpers';

@withTranslation()
class CustomerInformation extends Component {
  constructor() {
    super();

    this.copyVerificationLink = this.copyVerificationLink.bind(this);
  }

  renderLimits(current, max) {
    return `${+current} / ${max === 'INFINITY' ? '∞' : +max}`;
  }

  copyVerificationLink(emailConfirmationCode) {
    const link = process.env.NODE_ENV === 'production' ? 'https://api.scoutpad.de' : 'https://dev-api.scoutpad.de';
    navigator.clipboard.writeText(`${link}/registration/activation/${emailConfirmationCode}`).then(() => {
      alert('Link was copied to clipboard');
    });
  }

  render() {
    const {
      t, i18n, countries, manuallyVerifyCustomerEmail,
    } = this.props;
    const lng = i18n.language;

    const {
      information, limits, users, invites, bonus,
    } = this.props.customer;
    const customer = information;

    let planName = t('NO_PLAN');
    if (customer.plan) {
      planName = customer.plan !== 'free' ? _.get(customer, 'stripe_cache.plan_name', t('UNKNOWN_PLAN', { plan_id: customer.plan })) : 'Free';
    }

    const { emailConfirmed, emailConfirmationCode } = customer;
    const coupon = customer.coupon ? customer.coupon : '—';
    const paymentMethod = _.get(customer, 'stripe_cache.payment_method', null);
    const paymentMethodFormatted = getPaymentMethodName(paymentMethod, '—');
    const currencySign = getCurrencySign(customer.currency);

    const renewalDate = _.get(customer, 'stripe_cache.current_period_end', null);
    const formattedRenewal = renderDate(renewalDate);
    const registered = renderDate(customer.createdAt);
    const trialEnd = _.get(customer, 'stripe_cache.trial_end', null);
    const formattedTrialEnd = renderDate(trialEnd);
    const acceptPrivacy = renderDate(customer.acceptPrivacyDate);
    const lastLoggedIn = renderDate(customer.lastLoggedIn);

    const vatFormatted = customer.vatNumber || '—';
    const postalFormatted = customer.zipCode || '—';
    const cityFormatted = customer.city || '—';
    const streetNumberFormatted = customer.streetNumber || '—';
    const countryFormatted = customer.country ? _.get(countries.listByAlpha2, `${customer.country}.name_${lng}`, _.get(countries.listByAlpha2, `${customer.country}.name`, customer.country)) : '—';

    const credits = customer.credits || 0;
    const daysSessions = customer.daysSessions || 0;

    const invitesTotal = invites.length;
    const invitesFree = invites.filter((i) => i.status === 'subscribe_free').length;
    const invitesPro = invites.filter((i) => i.status === 'subscribe_pro').length;

    const totalMilestones = 7;
    let activeMilestones = 0;

    if (bonus) {
      delete bonus.earn2;
      _.map(bonus, (i) => {
        if (i.active === true) {
          activeMilestones++;
        }
      });
    }

    let isTest = false;

    if (process.env.NODE_ENV !== 'production') {
      isTest = true;
    }

    const stripeUrl = `https://dashboard.stripe.com/${isTest ? 'test/' : ''}customers/${customer.customer_id.stripe}`;
    console.log({ information });

    return (
      <div className="customer-page__info">
        <div className="customer-page__row">
          <div className="customer-page__column">
            <p className="customer-page__id">
              ID:
              {' '}
              {customer._id}
            </p>
            <p className="customer-page__users">
              <span className="label">
                { t('USERS') }
                :
              </span>
              {' '}
              {this.renderLimits(users.length, limits.scouts.max)}
            </p>
            <p>
              <span className="label">
                {t('REPORTS')}
                :
              </span>
              {' '}
              {this.renderLimits(limits.reports.current, limits.reports.max)}
              {' '}
            </p>
            <p>
              <span className="label">
                {t('WATCHLIST')}
                :
              </span>
              {' '}
              {this.renderLimits(limits.favorites.current, limits.favorites.max)}
              {' '}
            </p>
            <p>
              <span className="label">
                {t('SHADOW_TEAMS')}
                :
              </span>
              {' '}
              {this.renderLimits(limits.shadowTeams.current, limits.shadowTeams.max)}
              {' '}
            </p>
          </div>
          <div className="customer-page__column">
            <p className="customer-list-item__plan">
              {planName ? `${planName},` : '-,'}
              {' '}
              {currencySign}
            </p>
            <p className="customer-list-item__coupon">
              <span className="label">
                {t('COUPON')}
                :
              </span>
              {' '}
              { coupon }
            </p>
            <p className="customer-list-item__payment_method">
              <span className="label">
                {t('PAYMENT_METHOD')}
                :
              </span>
              {' '}
              {paymentMethodFormatted}
            </p>
            <p className="customer-list-item__renewal_date">
              <span className="label">
                {t('RENEWAL_DATE')}
                :
              </span>
              {' '}
              {formattedRenewal}
            </p>
            <p className="customer-list-item__stripe-link"><a href={stripeUrl} target="_blank" rel="noreferrer">Go to stripe dashboard</a></p>
          </div>
          <div className="customer-page__column">
            <p className="customer-list-item__registered">
              <span className="label">
                {t('REGISTERED')}
                :
              </span>
              {' '}
              {registered}
            </p>
            <p className="customer-list-item__trial-end">
              <span className="label">
                {t('TRIAL_END')}
                :
              </span>
              {' '}
              {formattedTrialEnd}
            </p>
            <p className="customer-list-item__accept-privacy">
              <span className="label">
                {t('ACCEPT_PRIVACY')}
                :
              </span>
              {' '}
              {acceptPrivacy}
            </p>
            <p className="customer-list-item__last-logged-in">
              <span className="label">
                {t('LAST_LOGGED_IN')}
                :
              </span>
              {' '}
              {lastLoggedIn}
            </p>
            <p>
              {
                !emailConfirmed ? (
                  <>
                    <span className="js-link email-verify-link no-margin" onClick={() => this.copyVerificationLink(emailConfirmationCode)}>Copy verify link</span>
                    {' / '}
                    <span className="js-link email-verify-link" onClick={() => manuallyVerifyCustomerEmail(customer._id)}>Verify email</span>
                  </>
                ) : <span className="label">Email confirmed</span>
              }
            </p>
          </div>
          <div className="customer-page__column">
            <p className="customer-list-item__credits">
              <span className="label">
                {t('ADMIN_CREDITS')}
                :
              </span>
              {' '}
              {credits}
            </p>
            <p className="customer-list-item__milestones">
              <span className="label">
                {t('ADMIN_MILESTONES')}
                :
              </span>
              {' '}
              {activeMilestones}
              {' '}
              /
              {' '}
              {totalMilestones}
            </p>
            <p className="customer-list-item__sessions">
              <span className="label">
                {t('ADMIN_SESSIONS')}
                :
              </span>
              {' '}
              {daysSessions}
            </p>
            <p className="customer-list-item__referrals">
              <span className="label">
                {t('ADMIN_REFERRALS_FREE_PRO')}
                :
              </span>
              {' '}
              {invitesTotal}
              {' '}
              /
              {' '}
              {invitesFree}
              {' '}
              /
              {' '}
              {invitesPro}
            </p>
          </div>
          <div className="customer-page__column">
            <p>
              <span className="label">
                {t('VAT')}
                :
              </span>
              {' '}
              {vatFormatted}
              {' '}
            </p>
            <p>
              <span className="label">
                {t('COUNTRY')}
                :
              </span>
              {' '}
              {countryFormatted}
              {' '}
            </p>
            <p>
              <span className="label">
                {t('POSTALCODE')}
                :
              </span>
              {' '}
              {postalFormatted}
              {' '}
            </p>
            <p>
              <span className="label">
                {t('CITY')}
                :
              </span>
              {' '}
              {cityFormatted}
              {' '}
            </p>
            <p>
              <span className="label">
                {t('ADDRESS')}
                :
              </span>
              {' '}
              {streetNumberFormatted}
              {' '}
            </p>
          </div>
        </div>
      </div>
    );
  }
}

CustomerInformation.propTypes = {
  customer: PropTypes.object,
  countries: PropTypes.object,
  t: PropTypes.func,
  i18n: PropTypes.object,
  manuallyVerifyCustomerEmail: PropTypes.func,
};

export default CustomerInformation;
