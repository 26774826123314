import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import moment from 'moment';
import { withTranslation } from 'react-i18next';
import Header from '../header';

import DataItem from '../../favorite/components/data_item';

@withTranslation()
@connect((state) => {
  return {
    pdf: state.pdf,
    stadiums: state.stadiums,
  };
})
class MatchInfo extends Component {
  render() {
    const { pdf, stadiums, t } = this.props;
    const stadiumId = _.get(pdf, 'match.stadium_id', null);
    const stadiumName = _.get(stadiums, `[${stadiumId}].stadium`, t('UNKNOWN_STADIUM'));
    const matchDate = _.get(pdf, 'match.date', new Date());
    const formattedMatchDate = moment(matchDate).format('DD-MM-YYYY');
    const formattedMatchTime = moment(matchDate).format('HH:ss');

    return (
      <div className="pdf-match-info">
        <div className="pdf-stats-row small-header">
          <Header small />
        </div>
        <div className="pdf-stats-row info">
          <DataItem
            value={formattedMatchDate}
            label={t('DATE')}
          />

          <DataItem
            value={formattedMatchTime}
            label={t('TIME')}
          />

          <DataItem
            value={stadiumName}
            label={t('ARENA')}
          />

          <DataItem
            value={pdf?.match?.user}
            label={t('CREATED_BY')}
          />
        </div>
      </div>
    );
  }
}

MatchInfo.propTypes = {
  pdf: PropTypes.object,
  stadiums: PropTypes.array,
  t: PropTypes.func,
};

export default MatchInfo;
