import React from 'react';
import { components } from 'react-select';

import Icon from '../../../Icon';
import './control.scss';

export default function (props) {
  const { innerProps, innerRef, data } = props;
  return (
    <components.Control {...props} className="player-search-control">
      <Icon name="search" className="search-icon" />
      {props.children}
    </components.Control>
  );
}
