import React, { Component } from 'react';
import { Link } from 'react-router';
import PropTypes from 'prop-types';

import { findDOMNode } from 'react-dom';
import { DragSource, DropTarget } from 'react-dnd';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import Icon from '../Icon';
import AddToFavorite from '../ui/add-to-favorites';
import PlayerImage from '../player-image';
import PlayerMenu from '../ui/player-table-menu';

import {
  getTeamImage, getWatchlistCategory,
} from '../../helpers';
import NotePopup from '../ui/note-popup';
import DemoBadge from '../ui/demo-badge';

const cardSource = {
  beginDrag(props) {
    return {
      id: props.id,
      index: props.index,
    };
  },
};

const cardTarget = {
  hover(props, monitor, component) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;
    if (dragIndex === hoverIndex) {
      return;
    }
    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
    const clientOffset = monitor.getClientOffset();
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return;
    }
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return;
    }
    props.changeOrder(dragIndex, hoverIndex);
    monitor.getItem().index = hoverIndex;
  },
};

@withTranslation()
@connect((state) => ({
  teams: state.teams,
  positions: state.positions,
  favorites: state.favorites.list,
}))
@DropTarget('WATCHLIST_PLAYER', cardTarget, (connect) => ({
  connectDropTarget: connect.dropTarget(),
}))
@DragSource('WATCHLIST_PLAYER', cardSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging(),
}))
class DraggablePlayer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isNoteOpen: false,
    };
  }

  render() {
    const {
      player,
      watchlist,
      isDragging,
      connectDragSource,
      connectDropTarget,
      t,
      index,
      disabledControls,
      disableDrag,
    } = this.props;

    if (!player) return null;

    const watchlistIsDemo = watchlist._id === 'demo';
    const playerIsDemo = player.player_id === 'demo';
    const zIndex = watchlist.players.length - index;

    const actions = [
      {
        name: 'ADD_A_NOTE',
        action: () => {
          this.setState({ isNoteOpen: true });
        },
        icon: <Icon name="note" className="list-icon add-note-icon" />,
      },
      {
        name: 'REMOVE_FROM_WATCHLIST',
        action: () => {
          this.props.removePlayer(player.player_id);
        },
        icon: <Icon name="close" className="list-icon close-icon" />,
      },
    ];

    return connectDropTarget(<li key={index} className={`dragable-player ${(disabledControls || watchlistIsDemo) && !playerIsDemo ? 'inactive' : ''} ${isDragging ? 'dragging' : ''}`} style={{ zIndex }}>
      { playerIsDemo ? <DemoBadge /> : null }
      <span className="col-drag-handle">
        {!disableDrag ? connectDragSource(<span className="drag-handle"><Icon name="dragger" /></span>) : (
          <span data-tip={t('SWITCH_TO_MANUAL')} className="drag-handle disabled"><Icon name="dragger" /></span>)}
      </span>
      <span className="td col-player">
        <Link to={`/watchlists/${watchlist._id}/${player.player_id}`} className="dragable-player__name">
          <PlayerImage className="player-image" src={player.playerImage} />
          <span className="last-name">{ player.last_name }</span>
          {' '}
          { player.first_name !== player.last_name ? player.first_name : null }
        </Link>
      </span>
      <span className="td col-position">
        {player.position_name}
      </span>
      <span className="col-age">
        {player.birth_date}
      </span>
      <span className="td col-team">
        <span className="team">
          <PlayerImage className="team-image" src={getTeamImage(player.team_id)} />
          {player.team_name}
        </span>
      </span>
      <span className="td col-date">
        {player.contract_until}
      </span>
      <span className="td col-final-rating">
        {player.final_rating_formatted}
      </span>
      <span className="td col-favorite">
        <AddToFavorite
          match_id={null}
          isDemo={watchlistIsDemo}
          demoCategory={_.get(player.fav, 'category', null)}
          player={player.raw}
          added_message={t('WATCHLIST_CATEGORY_CHANGED')}
        >
          <div className="favorite-selector">
            <Icon name="favorite" />
            {' '}
            {getWatchlistCategory(player.fav, t)}
            {' '}
            <Icon name="chevron_down" className="chevron-down" />
          </div>
        </AddToFavorite>
      </span>
      <span className="td col-menu">
        <PlayerMenu player={player} actionsList={actions} />
      </span>
      { this.state.isNoteOpen ? <NotePopup player={player} onCloseClick={() => { this.setState({ isNoteOpen: false }); }} /> : null }
      { disableDrag ? <ReactTooltip effect="solid" arrowColor="transparent" /> : null }
    </li>);
  }
}

DraggablePlayer.propTypes = {
  player: PropTypes.object,
  watchlist: PropTypes.object,
  teams: PropTypes.object,
  positions: PropTypes.object,
  t: PropTypes.func,
  i18n: PropTypes.object,
  removePlayer: PropTypes.func,
  disabledControls: PropTypes.bool,
  index: PropTypes.number,
  isDragging: PropTypes.bool,
  connectDragSource: PropTypes.func,
  connectDropTarget: PropTypes.func,
  disableDrag: PropTypes.bool,
};

export default DraggablePlayer;
