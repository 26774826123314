import React, { Component } from 'react';
import './data_item_changeable_simple.scss';
import Icon from '../../../Icon';

export default class DataInputChangeable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isChanging: false,
      isHover: false,
      value: props.inputValue,
    };

    this.onBlur = this.onBlur.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.setState({
      value: this.props.inputValue,
    });
  }

  onBlur(value) {
    this.setState({
      isChanging: false,
    });
  }

  onClick() {
    this.setState({
      isChanging: true,
    });
  }

  onChange(value) {
    this.setState({ value });
    if (!value) {
      this.props.onChange({ value: this.state.value, name: this.props.name });
    } else {
      this.props.onChange({ value, name: this.props.name });
    }
  }

  render() {
    const {
      label, value, postfix, prefix, type,
    } = this.props;
    const { isHover } = this.state;
    const { isChanging } = this.props;
    let printValue = value;

    if (value === null || value === undefined) {
      printValue = 'N/D';
    } else {
      printValue = value;

      if (postfix) {
        printValue += postfix;
      }

      if (prefix) {
        printValue = prefix + printValue;
      }
    }

    let InputComponent = null;

    if (type === 'text') {
      InputComponent = (
        <TextInput
          onBlur={this.onBlur}
          onChange={this.onChange}
          name={this.props.name}
          value={this.state.value}
        />
      );
    }

    const ValueComponent = (
      <div
        onClick={this.onClick}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseOut}
      >
        {printValue}
        {isHover ? (<Icon name="pencil" className="data-item-changeable__edit" />) : null}
      </div>
    );

    return (
      <div className={`data-item-changeable-simple ${isChanging ? 'edit-mode' : ''}`}>
        {
            isChanging ? (
              <div className="data-item-changeable__input">
                {InputComponent}
                <div className="data-item-changeable__label">{label}</div>
              </div>
            ) : ValueComponent
          }
      </div>
    );
  }
}

function TextInput(props) {
  return (
    <input
      onBlur={(e) => props.onBlur(e.target.value)}
      onChange={(e) => props.onChange(e.target.value)}
      value={props.value || ''}
      autoFocus
      className="text_input"
      type="text"
    />
  );
}
