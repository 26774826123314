import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import VirtualizedSelect from 'react-virtualized-select';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import { getSeasonFromDate, getSeasonFromId, getSeasonIdFromDate } from '../../helpers';

import { fetchFavorite, resetFavorite } from '../../actions/favorites';
import { changeLoading } from '../../actions';
import { updateFavoriteTabs } from '../../actions/auth';

import Sections from '../sections';
import Summary from './summary';
import Reports from './reports';
import Notes from './notes';
import Analytics from './analytics';

import './favorite.scss';
import { canUseNotesSection, canUseAnalyticsSection } from '../../helpers/limits';

@withTranslation()
@connect((state) => ({
  subscription: state.subscription,
  leagues: state.leagues,
  matches: state.matches,
  info: state.favorites.detail,
  favorites: state.favorites,
  favoritesFilter: state.favorites.filter,
  user: state.auth.user,
  users: state.users,
}), {
  fetchFavorite, resetFavorite, changeLoading, updateFavoriteTabs,
})

export default class Favorite extends Component {
  static propTypes = {
    id: PropTypes.number,
    matches: PropTypes.array,
  };

  constructor(props) {
    super(props);
    this.state = {
      season: 0,
      scout: 0,
      league: 0,
    };

    this.addToTabs = this.addToTabs.bind(this);
  }

  componentDidMount() {
    const id = this.props.params.favorite_id;

    this.props.resetFavorite();
    this.props.fetchFavorite(id).then(() => {
      this.addToTabs(id);
    });
  }

  componentDidUpdate(prevProps) {
    const id = prevProps.params.favorite_id;
    const next_id = this.props.params.favorite_id;
    if (id !== next_id) {
      this.props.resetFavorite();
      this.props.fetchFavorite(next_id).then(() => {
        this.addToTabs(next_id);
      });
      this.setState({ league: 0, season: 0, scout: 0 });
    }
  }

  addToTabs(id) {
    const { user, info, favorites } = this.props;

    if (info.id === 'demo') { return; }

    const { list } = favorites;
    const { favoritesTabs } = user;
    const favorite = list.find((p) => p.player_id == id);

    if (favorite && favorite.user_id !== user._id) {
      if (favoritesTabs.findIndex((t) => t == id) === -1) {
        this.props.updateFavoriteTabs(user._id, [...favoritesTabs, id]);
      }
    }
  }

  render() {
    const id = this.props.params.favorite_id;
    const {
      info, favoritesFilter, t, subscription, user,
    } = this.props;

    const { page } = this.props.params;

    const { matches, stats, finalRatings } = info;

    let filteredMatches = [...(matches || [])];
    let filteredStats = [...(stats || [])];
    let filteredFinalRatings = [...(finalRatings || [])];
    let overallRating = filteredFinalRatings.length ? (_.sumBy(filteredFinalRatings, 'value') / filteredFinalRatings.length).toFixed(2) : 0;

    if (filteredMatches) {
      if (favoritesFilter.user_id) {
        filteredMatches = filteredMatches.filter((m) => +m.user_id === +favoritesFilter.user_id);
      }

      filteredMatches = filteredMatches.filter((match) => {
        const finalRating = _.sum(_.flatten([...['home', 'away'].map((side) => {
          return _.get(match, `${side}.final_rating[${id}]`, null);
        })]));

        const stats = _.get(match, `home.values[${id}]`, null) || _.get(match, `away.values[${id}]`, null);

        if (stats && stats[100000]) {
          if (Object.keys(stats[100000]).length < 2) {
            delete stats[100000];
          }
        }

        return finalRating || stats && Object.keys(stats).length;
      });
    }

    if (this.state.scout) {
      filteredFinalRatings = filteredFinalRatings.filter((r) => {
        const { user_id } = r;
        return user_id == this.state.scout;
      });
      filteredMatches = filteredMatches.filter((m) => m.user_id === this.state.scout);
      filteredStats = filteredStats.filter((s) => (!s.user_id || (+s.user_id === +this.state.scout)));
    }

    if (this.state.league) {
      filteredMatches = filteredMatches.filter((m) => m.league_id === this.state.league);
      filteredStats = filteredStats.filter((s) => s.league_id === this.state.league);
    }

    if (this.state.season) {
      filteredMatches = filteredMatches.filter((m) => getSeasonIdFromDate(m.date) === this.state.season);
      filteredStats = filteredStats.filter((s) => s.season_id === this.state.season);

      filteredFinalRatings = filteredFinalRatings.filter((r) => {
        const { match_id } = r;
        const match = matches.find((m) => m.match_id === match_id);
        if (!match) return false;
        return getSeasonIdFromDate(match.date) === this.state.season;
      });
    }

    overallRating = filteredFinalRatings.length ? (_.sumBy(filteredFinalRatings, 'value') / filteredFinalRatings.length).toFixed(2) : 0;

    const sections = [
      {
        name: t('SUMMARY'),
        link: 'summary',
        component: <Summary matches={filteredMatches} stats={filteredStats} league={this.state.league} season={this.state.season} finalRatings={filteredFinalRatings} overallRating={overallRating} />,
        render: true,
      },
      {
        name: t('MATCH_REPORTS'),
        link: 'reports',
        component: <Reports matches={filteredMatches} playerId={id} finalRatings={filteredFinalRatings} />,
        render: true,
        disabled: !matches || !matches.length,
      },
      {
        name: t('ANALYTICS'),
        link: 'analytics',
        component: <Analytics league={this.state.league} season={this.state.season} stats={filteredStats} />,
        render: user.selectedGender !== 'female',
      },
      {
        name: t('NOTES'),
        link: 'notes',
        component: canUseNotesSection(subscription) ? <Notes player_id={id} /> : null,
        deactivated: !canUseNotesSection(subscription),
        render: true,
      },
    ];

    let basePath = `players/${id}`;

    if (this.props.routeParams.watchlist_id) {
      basePath = `watchlists/${this.props.routeParams.watchlist_id}/${id}`;
    }

    if (this.props.routeParams.shadow_team_id) {
      basePath = `shadow-teams/${this.props.routeParams.shadow_team_id}/${id}`;
    }

    return (
      <div className="favorite">
        <header className="favorite-header">
          <div className="container">
            <div className="favorite__header-left">
              { this.renderPlayerInfo() }
            </div>
            <div className="favorite__header-right">
              { this.renderLeagueFilter() }
              { this.renderSeasonFilter() }
              { this.renderScoutFilter() }
            </div>
          </div>
        </header>

        <Sections name={basePath} list={sections} current={page} />
      </div>
    );
  }

  renderLeagueFilter() {
    const { t, leagues, i18n } = this.props;
    const { matches, stats } = this.props.info;

    const lng = i18n.language;
    const options = [{ value: 0, label: t('ALL_LEAGUES') }];
    const optsHelper = {};

    if ((!matches || !matches.length) && (!stats || !stats.length)) return;

    stats.map((stat) => {
      const leagueId = stat.league_id;
      if (!optsHelper[leagueId] && leagueId) {
        const league = leagues[leagueId];
        const league_name = _.get(league, `name_${lng}`, _.get(league, 'name', t('UNKNOWN_LEAGUE')));
        options.push({ value: leagueId, label: league_name });
        optsHelper[leagueId] = true;
      }
    });

    matches.map((match) => {
      const leagueId = match.league_id;

      if (!optsHelper[leagueId] && leagueId) {
        const league = leagues[leagueId];
        const league_name = _.get(league, `name_${lng}`, _.get(league, 'name', t('UNKNOWN_LEAGUE')));
        options.push({ value: leagueId, label: league_name });
        optsHelper[leagueId] = true;
      }
    });

    const leagueTitle = _.get(options.find((o) => o.value === this.state.league), 'label', null);

    return (
      <div className="favorite__season-filter" title={leagueTitle}>
        <VirtualizedSelect
          value={this.state.league}
          clearable={false}
          searchable={false}
          onChange={(value) => { this.setState({ league: value.value }); }}
          options={options}
          optionRenderer={({
            focusedOption, focusOption, option, selectValue, style, valueArray,
          }) => {
            let classNames = '';

            if (focusedOption === option) {
              classNames = 'VirtualizedSelectFocusedOption';
            }

            if (option.value === (valueArray[0].value)) {
              classNames = 'VirtualizedSelectSelectedOption';
            }

            return (
              <div
                className={`VirtualizedSelectOption ${classNames}`}
                style={style}
                onClick={() => selectValue(option)}
                onMouseEnter={() => focusOption(option)}
                title={option.label}
                key={option.value}
              >
                {option.label}
              </div>
            );
          }}
        />
        <label>{t('DATA_REPORTS_LEAGUE')}</label>
      </div>
    );
  }

  renderScoutFilter() {
    const { matches } = this.props.info;
    const { user, t } = this.props;
    const users = this.props.users.data;
    const options = [{ value: 0, label: t('ALL_SCOUTS') }];
    const optsHelper = {};
    if (!matches || !matches.length || !users.length || user.roleId === 0) return null;
    const usersByKey = _.keyBy(users, 'id');
    const id = this.props.params.favorite_id;

    matches.map((match) => {
      const { user_id } = match;

      if (!optsHelper[user_id]) {
        const finalRating = _.sum(_.flatten([...['home', 'away'].map((side) => {
          return _.get(match, `${side}.final_rating[${id}]`, null);
        })]));
        const stats = _.get(match, `home.values[${id}]`, null) || _.get(match, `away.values[${id}]`, null);
        if (stats && stats[100000]) {
          if (Object.keys(stats[100000]).length < 2) {
            delete stats[100000];
          }
        }
        if (finalRating || stats && Object.keys(stats).length) {
          if (!usersByKey[user_id]) { return; }
          options.push({ value: user_id, label: `${usersByKey[user_id].firstName} ${usersByKey[user_id].lastName}` });
          optsHelper[user_id] = true;
        }
      }
    });

    return (
      <div className="favorite__scout-filter">
        <VirtualizedSelect
          value={this.state.scout}
          clearable={false}
          searchable={false}
          onChange={(value) => { this.setState({ scout: value.value }); }}
          options={options}
        />
        <label>{t('DATA_AND_REPORTS_BY_ALL_SCOUTS')}</label>
      </div>
    );
  }

  renderSeasonFilter() {
    const { t } = this.props;
    const { matches, stats } = this.props.info;
    let options = [{ value: 0, label: t('ALL_SEASON') }];
    let unsortedOptions = [];
    const optsHelper = {};

    if ((!matches || !matches.length) && (!stats || !stats.length)) return;

    stats.map((stat) => {
      const season = getSeasonFromId(stat.season_id);
      if (!optsHelper[season]) {
        unsortedOptions.push({ value: stat.season_id, label: `${t('SEASON')} ${season}` });
        optsHelper[season] = true;
      }
    });

    matches.map((match) => {
      const season = getSeasonFromDate(match.date);
      const season_id = getSeasonIdFromDate(match.date);
      if (!optsHelper[season]) {
        unsortedOptions.push({ value: season_id, label: `${t('SEASON')} ${season}` });
        optsHelper[season] = true;
      }
    });

    unsortedOptions = _.orderBy(unsortedOptions, (i) => +i.value, ['desc']);

    options = [...options, ...unsortedOptions];

    return (
      <div className="favorite__season-filter">
        <VirtualizedSelect
          value={this.state.season}
          clearable={false}
          searchable={false}
          onChange={(value) => { this.setState({ season: value.value }); }}
          options={options}
        />
        <label>{t('DATA_REPORTS_SEASON')}</label>
      </div>
    );
  }

  renderPlayerInfo() {
    const { player } = this.props.info;

    if (!player) {
      return (
        <div className="favorite__title">
          <span className="placeholder fn" />
          <span className="placeholder ln" />
        </div>
      );
    }

    return (
      <div className="favorite__title">
        {player.first_name}
        {' '}
        {player.last_name}
      </div>
    );
  }
}
