import './pdf-pitch.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import { formatQuarterAndBirthYear, getWindowWidth } from '../../../helpers';
import PitchEventsIcons from '../../ui/pitch-events-icons';

@connect((state) => ({
  pdf: state.pdf,
}), { })

export default class pdfPitch extends Component {
  static propTypes = {
    pdf: PropTypes.object,
    snapshot: PropTypes.string.isRequired,
    width: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  };

  constructor() {
    super();

    this.renderPlayers = this.renderPlayers.bind(this);
  }

  renderPlayers() {
    const { pdf, snapshot, icons } = this.props;

    const getPositions = (side) => _
      .chain(_.get(pdf, `match[${side}].positions[${snapshot}]`, []))
      .filter((player) => player.active)
      .map((player) => {
        const { information } = pdf.match[side].players[player.player_id];
        const substituted = player.swap && player.swapTime;

        const { left, top } = player.coordinates || { left: 0, top: 0 };
        const formattedAge = formatQuarterAndBirthYear(information);

        return (
          <div key={player.player_id} className={`pdf-pitch-player ${side} ${snapshot} ${substituted ? 'substituted in' : ''}`} style={{ left: `${left * 100}%`, top: `${top * 100}%` }}>
            <div className="player__name">{ information.last_name }</div>
            <div className="player__number">
              <div className="player__number-text">{ information.jersey }</div>
              <div className="player__birthdate">{formattedAge}</div>
            </div>
            <PitchEventsIcons
              side={side}
              match={pdf.match}
              player_id={player.player_id}
              type={icons}
            />
          </div>
        );
      })
      .value();

    return [...getPositions('home'), ...getPositions('away')];
  }

  render() {
    const mediaQuery = getWindowWidth() > 960 ? 1 : 2 / 3;
    const width = this.props.width * mediaQuery;
    const height = width / 1.5;

    return (
      <div className="pdf-pitch" style={{ width }}>
        <svg className="area__svg" {...{ width, height }}>
          <pattern id="Pattern" x="0" y="0" width="147px" height="147px" patternUnits="userSpaceOnUse">
            <path fill="#148535" d="M1.5,147L147,1.5V75l-72,72H1.5z M0,74.9L74.9,0H1.4L0,1.4V74.9z" />
          </pattern>

          <rect fill="#0f6630" {...{ width, height }} />
          <rect fill="url(#Pattern)" {...{ width, height }} />

          <line className="pixel-perfect" x1="50%" y1="0" x2="50%" y2="100%" stroke="rgba(255, 255, 255, .75)" />

          <rect fill="transparent" stroke="rgba(255, 255, 255, .75)" className="pixel-perfect" x={-0.5} y={Math.floor((height - height * 0.5) / 2)} width={Math.floor(width * 0.17)} height={Math.floor(height * 0.5)} />
          <rect fill="transparent" stroke="rgba(255, 255, 255, .75)" className="pixel-perfect" x={-0.5} y={Math.floor((height - height * 0.25) / 2)} width={Math.floor(width * 0.07)} height={Math.floor(height * 0.25)} />

          <rect fill="transparent" stroke="rgba(255, 255, 255, .75)" className="pixel-perfect" x={Math.floor(width - width * 0.17 + 1.5)} y={Math.floor((height - height * 0.5) / 2)} width={Math.floor(width * 0.17)} height={Math.floor(height * 0.5)} />
          <rect fill="transparent" stroke="rgba(255, 255, 255, .75)" className="pixel-perfect" x={Math.floor(width - width * 0.07 + 1.5)} y={Math.floor((height - height * 0.25) / 2)} width={Math.floor(width * 0.07)} height={Math.floor(height * 0.25)} />

          <rect fill="transparent" stroke="#537C3A" className="pixel-perfect" x={0.5} y={0.5} width={Math.floor(width - 1)} height={Math.floor(height - 1)} />

          <circle fill="transparent" stroke="rgba(255, 255, 255, .75)" cx="50%" cy="50%" r={Math.floor(height * 0.25 / 2)} />
        </svg>

        { this.renderPlayers() }
      </div>
    );
  }
}
