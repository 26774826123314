import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, reduxForm } from 'redux-form';
import Validator from 'validator';
import { withTranslation } from 'react-i18next';
import { NotificationManager } from '../../../../notifications';

import { saveGroupData } from '../../../../../actions/group';

function FieldInput({ input, t, ...rest }) {
  return (
    <div className="input-container">
      { rest.label ? <label>{rest.label}</label> : null }
      <input
        {...input}
        name={rest.name}
        placeholder={rest.placeholder}
        className={`inp input ${rest.className} ${rest.meta.touched && rest.meta.error ? 'no-valid' : ''}`}
      />
    </div>
  );
}

@withTranslation()
@connect((state) => ({
  user: state.auth.user,
  initialValues: state.group && state.group._id ? {
    name: state.group.name,
  } : undefined,
}), { saveGroupData })
@reduxForm({
  form: 'groupNameForm',
  validate,
})
export default class GroupForm extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      error: null,
    };
  }

  onSubmit(values) {
    const { t } = this.props;

    NotificationManager.create({
      message: 'SAVING',
      id: 'id',
      timeOut: 3000,
    });

    this.props.saveGroupData({ ...this.props.group, name: values.name }).then(() => {
      NotificationManager.create({
        message: 'GROUP_SAVED',
        id: 'id',
        timeOut: 3000,
      });
    });
  }

  render() {
    const { handleSubmit, t } = this.props;

    return (
      <form className="my-account__form" onSubmit={handleSubmit(this.onSubmit)}>
        <h2 className="settings-h1 overlay-h1">{t('GROUP')}</h2>
        <div className="form-row">
          <Field component={FieldInput} name="name" t={t} label={t('GROUP_NAME')} placeholder={t('GROUP_NAME')} />
        </div>
        { this.state.error ? <p className="error-message">{ this.state.error }</p> : null}
        <div className="form-row submit-row">
          <button className="btn" type="submit">{t('SAVE_CHANGES')}</button>
        </div>
      </form>
    );
  }
}

export function validate(values) {
  const errors = {};

  if (!values.name) {
    errors.name = 'GROUP_NAME_ERROR';
  }

  return errors;
}
