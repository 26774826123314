import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Link } from 'react-router';

export default function isActive(Component) {
  class AuthenticatedComponent extends PureComponent {
    static propTypes = {
      user: PropTypes.object,
    };

    render() {
      const isActive = _.get(this.props, 'user.active', true);

      return isActive ? <Component {...this.props} /> : null;
    }
  }

  const mapStateToProps = (state) => ({
    user: state.auth.user,
  });

  return connect(mapStateToProps)(AuthenticatedComponent);
}
