import React, { Component } from 'react';
import Select from 'react-select';

import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import isTouchDevice from 'is-touch-device';
import _ from 'lodash';
import {
  updateShadowTeam,
} from '../../actions/shadow-teams';

import {
  updateShadowTeamsTab,
} from '../../actions/auth';

import { styleFilter, filterTheme } from '../ui/select_props';
import { getPositionShadowTeam } from '../../helpers';
import Icon from '../Icon';
import AddPlayerPane from '../favorites/components/add-player';

import './shadow-team.scss';

import Position from './components/position';

import DEMO_PLAYERS_MAN from '../../../demo_players_filtered.json';
import DEMO_PLAYERS_WOMAN from '../../../demo_players_women_filtered.json';
import { exceededShadowTeamsLimit } from '../../helpers/limits';

@withTranslation()
@connect((state, own) => {
  const DEMO_PLAYERS = window.genderContext === 'female' ? DEMO_PLAYERS_WOMAN : DEMO_PLAYERS_MAN;
  return {
    formations: state.formations,
    shadowTeams: state.shadowTeams,
    user: state.auth.user,
    positions: state.positions,
    team_id: own.params.shadow_team_id,
    favorites: own.params.shadow_team_id.toLowerCase() !== 'demo' ? state.favorites : {
      detail: {},
      list: DEMO_PLAYERS.map((p) => p),
      info: _.keyBy(DEMO_PLAYERS, 'key'),
      fetched: true,
      filters: state.favorites.filter,
    },
    team: { ...state.shadowTeams.find((team) => team._id === own.params.shadow_team_id) },
    subscription: state.subscription,
  };
}, {
  updateShadowTeam, updateShadowTeamsTab,
})
export default class ShadowTeam extends Component {
  constructor(props) {
    super(props);

    const { t } = props;

    let onlyMyPlayers = localStorage.getItem('shadowOnlyMyPlayers');
    if (onlyMyPlayers === null) {
      onlyMyPlayers = true;
    }

    if (onlyMyPlayers === 'false') {
      onlyMyPlayers = false;
    }

    this.state = {
      onlyMyPlayers,
      showAdd: false,
      playersListAll: [],
      choosenPlayers: [],
      name: props.team.name || '',
      players: props.team.players || {},
      activePosition: null,
      formationPresets: [
        {
          label: t('PRESET_CUSTOM'),
          value: 0,
        },
        {
          label: t('PRESET_BEST_U21'),
          value: 1,
        },
        {
          label: t('PRESET_BEST_RATING'),
          value: 2,
        },
        {
          label: t('PRESET_BEST_OFFENCE'),
          value: 3,
        },
        {
          label: t('PRESET_BEST_DEFENCE'),
          value: 4,
        },
      ],
    };

    this.changeFormation = this.changeFormation.bind(this);
    this.setPlayer = this.setPlayer.bind(this);
    this.setActive = this.setActive.bind(this);
    this.closePopup = this.closePopup.bind(this);
    this.movePlayer = this.movePlayer.bind(this);
    this.removePlayer = this.removePlayer.bind(this);
    this.addToTabs = this.addToTabs.bind(this);
  }

  componentDidMount() {
    const {
      positions, favorites, user, team, team_id,
    } = this.props;
    const { list, info } = favorites;

    const teamPlayers = team.players || {};
    const choosenPlayers = _.chain(teamPlayers).map((pos) => pos).flatten().uniq()
      .value();

    let playersListAll = list.map((player) => {
      const { player_id } = player;
      let updPlayer = { ...player };

      if (info[player_id]) {
        updPlayer = { ...updPlayer, ...info[player_id] };
      }
      updPlayer.position_name = _.get(positions, `${updPlayer.position_id}.position_name`, null);
      updPlayer.position_id = +updPlayer.position_id;
      updPlayer.season_rating = +updPlayer.season_rating || 0;

      return updPlayer;
    });

    playersListAll = _.orderBy(playersListAll, ['position_id', 'season_rating'], ['asc', 'desc']);

    this.setState({ playersListAll, choosenPlayers });

    if (team_id !== 'demo') {
      this.addToTabs();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.team._id !== this.props.team._id) {
      this.setState({
        name: this.props.team.name,
        players: this.props.team.players || {},
        popup: {
          show: false,
        },
      });
    }

    if (this.props.favorites !== prevProps.favorites || this.props.team !== prevProps.team) {
      const teamPlayers = this.props.team.players || {};
      const choosenPlayers = _.chain(teamPlayers).map((pos) => pos).flatten().uniq()
        .value();

      const { list, info } = this.props.favorites;
      const { positions } = this.props;

      let playersListAll = list
        .map((player) => {
          const { player_id } = player;
          let updPlayer = { ...player };

          if (info[player_id]) {
            updPlayer = { ...updPlayer, ...info[player_id] };
          }
          updPlayer.position_name = _.get(positions, `${updPlayer.position_id}.position_name`, null);
          updPlayer.position_id = +updPlayer.position_id;
          updPlayer.season_rating = +updPlayer.season_rating || 0;

          return updPlayer;
        });

      playersListAll = _.orderBy(playersListAll, ['position_id', 'season_rating'], ['asc', 'desc']);

      this.setState({ playersListAll, choosenPlayers });
    }

    this.addToTabs();
  }

  addToTabs() {
    const { team, user } = this.props;
    const { shadowTeamsTabs } = user;

    if (!team._id) return;

    if (team.user_id !== user._id) {
      if (shadowTeamsTabs.findIndex((t) => t == team._id) === -1) {
        this.props.updateShadowTeamsTab(user._id, [...shadowTeamsTabs, team._id]);
      }
    }
  }

  changeFormation(formation) {
    const {
      t, formations, team, positions,
    } = this.props;
    let shouldUpdatePlayers = false;

    if (!_.isEmpty(team.players)) {
      if (!confirm(t('SHADOW_CHANGE_FORMATION'))) {
        return;
      }
    }

    const currentFormation = formations[team.formation_id];
    const newFormation = formations[formation.value];

    // RB and LB should be moved LWB and RWB on 3-5-... formations
    const isCurrentLB = currentFormation.positions.find((p) => positions[p.position_id_detail].desc_short === 'LB') || null;
    const isNewLWB = newFormation.positions.find((p) => positions[p.position_id_detail].desc_short === 'LWB') || null;

    const isCurrentRB = currentFormation.positions.find((p) => positions[p.position_id_detail].desc_short === 'RB') || null;
    const isNewRWB = newFormation.positions.find((p) => positions[p.position_id_detail].desc_short === 'RWB') || null;

    const players = _.cloneDeep(team.players);

    if (isCurrentLB && isNewLWB) {
      if (players[isCurrentLB.position_id_detail]) {
        players[isNewLWB.position_id_detail] = [...players[isCurrentLB.position_id_detail]];
        shouldUpdatePlayers = true;
      }
    }

    if (isCurrentRB && isNewRWB) {
      if (players[isCurrentRB.position_id_detail]) {
        players[isNewRWB.position_id_detail] = [...players[isCurrentRB.position_id_detail]];
        shouldUpdatePlayers = true;
      }
    }

    const newTeam = {
      ...team, players, formation_id: formation.value, preset: 0,
    };

    if (shouldUpdatePlayers) {
      this.setState({ players });
    }

    this.props
      .updateShadowTeam(newTeam);
  }

  changeName(name) {
    this.props
      .updateShadowTeam({ ...this.props.team, name });
    this.setState({ name });
  }

  closePopup() {
    this.setState({
      popup: { show: false },
    });
  }

  setPlayer(position_id, player_id) {
    let players = _.cloneDeep(this.state.players);

    if (!players) players = {};
    if (!players[position_id]) players[position_id] = [];

    players[position_id].push(player_id);

    this.setState({ players });
    this.setActive(position_id, false);
    this.props
      .updateShadowTeam({ ...this.props.team, players });
  }

  setActive(position_id, isActive) {
    if (isActive) {
      this.setState({ activePosition: position_id });
    } else {
      this.setState({ activePosition: null });
    }
  }

  openAddPlayer() {
    this.setState({ showAdd: true });
  }

  closeAddPlayer() {
    this.setState({ showAdd: false });
  }

  renderSettings() {
    const {
      team, formations, t, user,
    } = this.props;

    const formationsOptions = [];
    const errors = false;

    _.forIn(formations, (val) => {
      if (val.id != 0 && val.id != 1) {
        formationsOptions.push({ value: +val.id, label: val.name, s: val.name.replace(/-/ig, '') });
      }
      return true;
    });

    return (
      <div className="shadow-team__settings clearfix">
        <div className="shadow-team__name">
          <label>{t('SHADOW_TEAM_NAME')}</label>
          <div className="input">
            <input
              className={`${errors.name ? 'invalid' : ''}`}
              type="text"
              onChange={(event) => this.changeName(event.target.value)}
              value={this.state.name}
              placeholder={t('SHADOW_TEAM_NAME')}
            />
          </div>
        </div>
        <div className="shadow-team__formation">
          <label>{t('SHADOW_TEAM_FORMATION')}</label>
          <div className="input">
            <Select
              options={formationsOptions}
              className={`${errors.formation ? 'styled-react-select invalid' : 'styled-react-select'}`}
              onChange={(option) => {
                if (!option) return;
                this.changeFormation(option);
              }}
              styles={styleFilter}
              isSearchable={!isTouchDevice()}
              theme={filterTheme}
              value={formationsOptions.find((f) => +f.value === +team.formation_id)}
              filterOptions={(opts, f) => opts.filter((o) => o.label.indexOf(f) !== -1 || o.s.indexOf(f) !== -1)}
              placeholder={t('SHADOW_TEAM_FORMATION')}
            />
          </div>
        </div>
        {
          user.role.tag !== 'user'
            ? (
              <div className="shadow-team__checkbox">
                <div className="group-check">
                  <label
                    onClick={() => {
                      localStorage.setItem('shadowOnlyMyPlayers', !this.state.onlyMyPlayers);
                      this.setState({ onlyMyPlayers: !this.state.onlyMyPlayers });
                    }}
                    className="checked"
                  >
                    <span className={`checkbox ${!this.state.onlyMyPlayers ? 'checked' : ''}`}>
                      <Icon name="checkbox2" />
                    </span>
                    <span className="checkbox_label">
                      { t('SHOW_GROUP_PLAYERS') }
                    </span>

                  </label>
                </div>
              </div>
            )
            : null
        }
        <div className="shadow-team__favorite-add">
          <button
            className="btn add-new-player"
            onClick={() => {
              this.openAddPlayer();
            }}
          >
            <Icon name="plus" className="create-icon" />
            {' '}
            {t('PLAYER', { context: window.genderContext })}
          </button>
        </div>

      </div>
    );
  }

  movePlayer(position_id, player_id, dir) {
    const players = _.cloneDeep(this.state.players);
    const index = players[position_id].findIndex((p) => p === player_id);

    let tmp;

    if (dir === 'up') {
      tmp = players[position_id][index];
      players[position_id][index] = players[position_id][index - 1];
      players[position_id][index - 1] = tmp;
    } else {
      tmp = players[position_id][index];
      players[position_id][index] = players[position_id][index + 1];
      players[position_id][index + 1] = tmp;
    }

    this.setState({ players });
    this.props
      .updateShadowTeam({ ...this.props.team, players });
  }

  removePlayer(position_id, player_id) {
    const players = _.cloneDeep(this.state.players);
    const index = players[position_id].findIndex((p) => p === player_id);

    players[position_id] = [
      ...players[position_id].slice(0, index),
      ...players[position_id].slice(index + 1),
    ];

    this.setState({ players });
    this.props
      .updateShadowTeam({ ...this.props.team, players });
  }

  renderFormation() {
    const {
      team, positions, user, formations, team_id,
    } = this.props;
    const { players, onlyMyPlayers, playersListAll } = this.state;
    const formation = formations[team.formation_id];
    const formation_positions = _.get(formation, 'positions', []);

    const playersById = _.keyBy(playersListAll, 'player_id');

    return formation_positions.map((p, i) => {
      if (+p.position_id_detail === 0) return null;

      const position = positions[p.position_id_detail];
      const { desc_short } = position;
      const coords = getPositionShadowTeam(desc_short, formation.name);

      const posPlayers = players && players[position.id] || [];
      const isActive = +this.state.activePosition === +p.position_id_detail;

      const activePosPlayers = posPlayers.filter((player) => playersById[player]);

      return (
        <Position
          playersList={playersListAll}
          playersById={playersById}
          players={activePosPlayers}
          position={position}
          coords={coords}
          team_id={team_id}
          key={p.position_id_detail}
          setPlayer={this.setPlayer}
          setActive={this.setActive}
          isActive={isActive}
          movePlayer={this.movePlayer}
          removePlayer={this.removePlayer}
          user_id={user._id}
          onlyMyPlayers={onlyMyPlayers}
          choosenPlayers={this.state.choosenPlayers}
        />
      );
    });
  }

  render() {
    const { subscription } = this.props;
    const ratio = 3.1 / 3;
    const width = 680;
    const height = Math.round(width * ratio);
    const isActive = this.state.activePosition;
    const isDisabled = exceededShadowTeamsLimit(subscription);

    return (
      <div className={`shadow-team__content ${isDisabled ? 'disabled' : null}`}>
        <div className="shadow-team__lineup-container">
          { this.renderSettings() }
          <div className="shadow-team__lineup">
            <div className={`shadow-team__lineup-content ${isActive ? 'active' : ''}`} style={{ width, height }}>
              <svg className="area__svg" {...{ width, height }}>

                <linearGradient id="three_opacity_stops" y2="1" x2="0">
                  <stop offset="0" stopColor="#263741" stopOpacity="0.6" />
                  <stop offset="0.2" stopColor="#263741" stopOpacity="0.2" />
                  <stop offset="1" stopColor="#263741" stopOpacity="0" />
                </linearGradient>
                <pattern id="Pattern" x="0" y="0" width="147px" height="147px" patternUnits="userSpaceOnUse">
                  <path fill="#148535" d="M1.5,147L147,1.5V75l-72,72H1.5z M0,74.9L74.9,0H1.4L0,1.4V74.9z" />
                </pattern>

                <rect fill="#0f6630" {...{ width, height }} />
                <rect fill="url(#Pattern)" {...{ width, height }} />

                <rect fill="transparent" stroke="rgba(255, 255, 255, .75)" className="pixel-perfect" x={0.5} y={0.5} width={Math.floor(width - 1)} height={Math.floor(height - 1)} />
                <line className="pixel-perfect" x1="0%" y1="50%" x2="100%" y2="50%" stroke="rgba(255, 255, 255, .75)" />

                <rect fill="transparent" stroke="rgba(255, 255, 255, .75)" className="pixel-perfect" x={Math.floor((width - width * 0.5) / 2)} y={0.5} width={Math.floor(width * 0.5)} height={Math.floor(height * 0.17)} />
                <rect fill="transparent" stroke="rgba(255, 255, 255, .75)" className="pixel-perfect" x={Math.floor((width - width * 0.25) / 2)} y={0.5} width={Math.floor(width * 0.25)} height={Math.floor(height * 0.07)} />

                <rect fill="transparent" stroke="rgba(255, 255, 255, .75)" className="pixel-perfect" x={Math.floor((width - width * 0.5) / 2)} y={Math.floor(height - height * 0.17 + 1)} width={Math.floor(width * 0.5)} height={Math.floor(height * 0.17)} />
                <rect fill="transparent" stroke="rgba(255, 255, 255, .75)" className="pixel-perfect" x={Math.floor((width - width * 0.25) / 2)} y={Math.floor(height - height * 0.07 + 1)} width={Math.floor(width * 0.25)} height={Math.floor(height * 0.07)} />

                <rect fill="url(#three_opacity_stops)" {...{ width, height }} />

                <circle fill="transparent" stroke="rgba(255, 255, 255, .75)" cx="50%" cy="50%" r={Math.floor(width * 0.25 / 2)} />
              </svg>
              { this.renderFormation() }
              <div className="fade" />
            </div>
          </div>
        </div>

        { this.state.showAdd ? (
          <div>
            <div className="add-player-fade" />
            <AddPlayerPane onClickOutside={() => { this.closeAddPlayer(); }} />
          </div>
        ) : null }

      </div>
    );
  }
}
