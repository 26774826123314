import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import InfiniteLoader from 'react-window-infinite-loader';
import VirtualGrid from '../../ui/virtual-list-grid';
import './reports-list.scss';
import MatchCard from '../../ui/match-card';
import MatchLoader from '../../ui/match-card/loader';
import { useTranslation } from 'react-i18next';
import EditMatch from '../../ui/match-edit-form';
import DemoMessage from '../../ui/demo-message';

export default function ReportsList({
  list,
  hasNextPage,
  isListLoading,
  loadNextPage,
  total,
  teams,
  leagues,
  authUser,
  players,
  deleteReport,
  updateReport,
  reportsPerPage,
  emptyMessage,
}) {
  const [edit, setEdit] = useState(null);
  const { t, i18n } = useTranslation();
  const lng = i18n.language;
  const itemSize = [338, 138];
  const [size, setSize] = useState(itemSize);
  const loaderRef = useRef(null);
  const containerRef = useRef(null);

  const resizeHandler = () => {
    const rect = containerRef.current.getBoundingClientRect();
    setSize([rect?.width, rect?.height]);
  };

  useEffect(() => {
    resizeHandler();
    window.addEventListener('resize', resizeHandler);
    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  const itemCount = hasNextPage ? list.length + 3 : list.length;
  const loadMoreItems = isListLoading ? () => {} : loadNextPage;

  let columnCount = Math.floor(size[0] / itemSize[0]);
  if (columnCount > 3) { columnCount = 3; }
  const rowCount = Math.ceil(itemCount / columnCount);

  const onDelete = (_id) => {
    deleteReport(_id);
    setEdit(false);
  };
  const onUpdate = ({ oldMatch, newMatch }) => {
    const updObject = {
      _id: oldMatch._id,
      stadium_id: newMatch.stadium,
      league_id: newMatch.league,
      date: newMatch.date,
      assign_to: newMatch.assign_to,
    };
    delete updObject.home;
    delete updObject.away;
    delete updObject.player;
    delete updObject.player_id;
    delete updObject.start;
    updateReport(updObject);
    setEdit(false);
  };

  const renderItem = ({
    columnIndex, rowIndex, style, data,
  }) => {
    const matchIndex = (rowIndex * columnCount) + columnIndex;

    if (list[matchIndex] === undefined && matchIndex <= total - 1) {
      return (
        <div style={style}>
          <MatchLoader />
        </div>
      );
    }

    if (list[matchIndex] === undefined) {
      return null;
    }

    const report = list[matchIndex];
    return (
      <div style={style}>
        <MatchCard
          report={report}
          index={matchIndex}
          teams={teams}
          leagues={leagues}
          t={t}
          lng={lng}
          authUser={authUser}
          players={players}
          onEdit={(report) => setEdit(report)}
        />
      </div>
    );
  };

  if (!list.length && isListLoading) {
    return (
      <div className="reports-list" ref={containerRef}>
        {
          [0, 1, 2, 3, 4, 5].map((i) => {
            return (
              <React.Fragment key={i}>
                <div className="match-card__container">
                  <MatchLoader />
                </div>
                {(i + 1) % columnCount === 0 ? <br /> : null}
              </React.Fragment>
            );
          })
        }

      </div>
    );
  }

  if (!list.length) {
    return (
      <div className="reports-list" ref={containerRef}>
        <div className="reports-list__empty-container">
          <DemoMessage arrowPosition="top-right" text={emptyMessage} className="reports-list__empty-message" />
        </div>
      </div>
    );
  }

  return (
    <div className="reports-list" ref={containerRef}>
      <InfiniteLoader
        ref={loaderRef}
        isItemLoaded={(index) => !hasNextPage || index < list.length}
        itemCount={itemCount}
        loadMoreItems={loadMoreItems}
        minimumBatchSize={reportsPerPage}
      >
        {
          ({ onItemsRendered, ref }) => {
            const newItemsRendered = (gridData) => {
              const useOverscanForLoading = true;
              const {
                visibleRowStartIndex,
                visibleRowStopIndex,
                visibleColumnStopIndex,
                overscanRowStartIndex,
                overscanRowStopIndex,
                overscanColumnStopIndex,
              } = gridData;

              const endCol = (useOverscanForLoading || true
                ? overscanColumnStopIndex
                : visibleColumnStopIndex) + 1;

              const startRow = useOverscanForLoading || true
                ? overscanRowStartIndex
                : visibleRowStartIndex;
              const endRow = useOverscanForLoading || true
                ? overscanRowStopIndex
                : visibleRowStopIndex;

              const visibleStartIndex = startRow * endCol;
              const visibleStopIndex = endRow * endCol;

              onItemsRendered({
                // call onItemsRendered from InfiniteLoader so it can load more if needed
                visibleStartIndex,
                visibleStopIndex,
              });
            };

            return (
              <VirtualGrid
                item={renderItem}
                itemData={list}
                count={itemCount}
                onItemsRendered={newItemsRendered}
                columnCount={columnCount}
                rowCount={rowCount}
                ref={ref}
                width={size[0]}
                height={size[1]}
                itemSize={itemSize}
                list={list}
                classNames="reports__virtual-grid"
              />
            );
          }
        }
      </InfiniteLoader>
      {edit
        ? (
          <EditMatch
            match={edit}
            onClose={() => setEdit(false)}
            onDelete={onDelete}
            onSubmit={onUpdate}
            players={players}
          />
        )
        : null}
    </div>
  );
}

ReportsList.propTypes = {
  list: PropTypes.array,
  renderReport: PropTypes.func,
  hasNextPage: PropTypes.bool,
  loadNextPage: PropTypes.func,
  isListLoading: PropTypes.bool,
  total: PropTypes.number,
  teams: PropTypes.object,
  leagues: PropTypes.object,
  authUser: PropTypes.object,
  players: PropTypes.object,
  deleteReport: PropTypes.func,
  updateReport: PropTypes.func,
  reportsPerPage: PropTypes.number,
  emptyMessage: PropTypes.string,
};
