import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router';
import Icon from '../../Icon';

@withTranslation()
@connect((state) => {
  return {
    user: state.auth.user,
    subscription: state.subscription,
  };
}, {})
class RestartSubscriptionMessage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      minimized: false,
    };
  }

  render() {
    const { user, t, subscription } = this.props;
    const { minimized } = this.state;

    if (subscription.plan || !user) {
      return null;
    }

    const contacts = `: ${user.group.firstName} ${user.group.lastName}, ${user.group.clubEmail}`;

    return (
      <div className={`restart-subscription main-message ${minimized ? 'minimized' : ''}`}>
        {!minimized ? (
          <div>
            <span className="close" onClick={() => { this.setState({ minimized: true }); }}>
              <Icon name="close" />
            </span>
            <h2>{t('YOUR_SUBSCRIPTION_ENDED')}</h2>
            {user.role.tag !== 'user' ? (
              <div>
                <h5>{t('YOUR_SUBSCRIPTION_ENDED_MESSAGE', { context: window.genderContext })}</h5>
                <p className="p__btn">
                  <Link to="/settings/billing/" className="btn">{t('RESTART_YOUR_SUBSCRIPTION')}</Link>
                </p>
              </div>
            ) : (
              <div>
                <h5>{t('YOUR_SUBSCRIPTION_ENDED_MESSAGE_USER', { contacts })}</h5>
                <h5>{t('USER_TRANSFER_MESSAGE')}</h5>
                <p className="p__btn"><button className="btn" onClick={this.transferAccount}>{t('TRANSFER_ACCOUNT_BUTTON')}</button></p>
              </div>
            )}
          </div>
        ) : (
          <div>
            {
              user.role.tag !== 'user' ? (
                <span>
                  {t('YOUR_SUBSCRIPTION_ENDED_MESSAGE', { context: window.genderContext })}
                  <Link to="/settings/billing/">{t('RESTART_YOUR_SUBSCRIPTION')}</Link>
                </span>
              ) : (
                <span>
                  {t('YOUR_SUBSCRIPTION_ENDED_MESSAGE_USER', { contacts })}
                </span>
              )
            }

          </div>
        )}
      </div>
    );
  }
}

RestartSubscriptionMessage.propTypes = {
  user: PropTypes.object,
  subscription: PropTypes.object,
  t: PropTypes.func,
};

export default RestartSubscriptionMessage;
