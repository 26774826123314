import React from 'react';
import './tag.scss';

export default function (props) {
  return (
    <span className="note-tag">
      #
      {props.tag}
    </span>
  );
}
