import './pdf-bench.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import PitchEventsIcons from '../../ui/pitch-events-icons';

@withTranslation()
@connect((state) => ({
  pdf: state.pdf,
  positions: state.positions,
}), { })

export default class pdfPitch extends Component {
  static propTypes = {
    pdf: PropTypes.object,
    positions: PropTypes.object,
  };

  constructor() {
    super();

    this.renderPlayers = this.renderPlayers.bind(this);
  }

  renderPlayers(side) {
    const {
      pdf, positions, t, i18n,
    } = this.props;
    const lng = i18n.language;

    const lineup = _.get(pdf, `match[${side}].positions.lineup`, []);
    const benchLineupFilterComparator = (player) => (player.substitution && !player.active);

    const players = _
      .chain(lineup)
      .filter(benchLineupFilterComparator)
      .value();

    if (!_.size(players)) return null;

    const renderGroup = (group) => group.map((player) => {
      const { information } = pdf.match[side].players[player.player_id];
      const position_short = _.get(positions, `[${information.position_id}].desc_short_${lng}`, _.get(positions, `[${information.position_id}].desc_short`, null));

      return (
        <div key={player.player_id} className={`pdf-list-player ${side}`}>
          <span className="player__number">{ information.jersey }</span>
          <span className="player__name">{ information.last_name }</span>
          { position_short ? (
            <span className="player__position">
              {' '}
              { position_short }
            </span>
          ) : null }
          <PitchEventsIcons
            match={pdf.match}
            player_id={player.player_id}
            side={side}
            type="white-small-list"
          />
        </div>
      );
    });

    const chunkAndRenderGroups = (players) => _
      .chain(players)
      .sortBy((player) => pdf.match[side].players[player.player_id].information.position_id)
      .chunk(Math.ceil(players.length / 2))
      .map((group, g) => <div className="col-6" key={g}>{ renderGroup(group) }</div>)
      .value();

    return (
      <div>
        <div className="bench-title">{t('BENCH')}</div>
        <div className="row">
          { chunkAndRenderGroups(players) }
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="pdf-bench">
        <div className="row">
          <div className="col-6">
            { this.renderPlayers('home') }
          </div>
          <div className="col-6">
            { this.renderPlayers('away') }
          </div>
        </div>
      </div>
    );
  }
}
