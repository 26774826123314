import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import { Link } from 'react-router';

import '../favorites/favorites.scss';
import { withTranslation } from 'react-i18next';

import './watchlist_wrapper.scss';
import { connect } from 'react-redux';
import { changeLoading } from '../../actions';
import { changeFavoritesFilter } from '../../actions/favorites';
import { fetchWatchlists } from '../../actions/watchlists';
import accountApi from '../../actions/account';

import { exceededWatchlistLimit } from '../../helpers/limits';
import DemoWatchlistMan from '../../../demo_watchlist.json';
import DemoWatchlistWomen from '../../../demo_watchlist_women.json';
import congratulationsConfirm from '../ui/congratulations-confirm';

@connect((state) => ({
  favorites: state.favorites,
  watchlists: state.watchlists,
  subscription: state.subscription,
  user: state.auth.user,
  users: state.users,
}), {
  changeLoading, changeFavoritesFilter, fetchWatchlists,
})
@withTranslation()
class WatchlistWrapper extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const {
      user, users, watchlists, subscription,
    } = this.props;
    if (exceededWatchlistLimit(subscription)) {
      congratulationsConfirm([{ type: 'UPGRADE_PLAN', _id: 'UPGRAD_FAKE_ID' }]);
    }

    const promises = [];

    if (user.role.tag !== 'user') {
      if (!users.data.length) {
        this.props.changeFavoritesFilter({ list_user_id: user._id });
      }
      promises.push(this.props.dispatch(accountApi.actions.groupUsers()));
    }

    if (!watchlists.fetched) {
      promises.push(this.props.fetchWatchlists());
    }

    this.props.changeLoading({ component: 'app', loading: true });
    Promise.all(promises)
      .then(() => {
        this.props.changeLoading({ component: 'app', loading: false });
      });
  }

  render() {
    const {
      t, watchlists, subscription, user,
    } = this.props;
    let { list } = watchlists;

    if (!list.length) {
      const DemoWatchlist = window.genderContext === 'female' ? DemoWatchlistWomen : DemoWatchlistMan;
      list = [DemoWatchlist];
    }

    return (
      <div className="favorites-wrapper">
        <div className="sub-nav">
          <div className="sub-nav__navigation watchlists">
            <Scrollbars autoHide>
              <div className="pad">
                <h2>{t('WATCHLISTS')}</h2>
                <ul>
                  <li><Link to="/watchlists/" activeClassName="active" onlyActiveOnIndex>{t('ALL_WATCHLISTS')}</Link></li>
                  {list.length ? <li className="divider" /> : null }
                  { list.map((wl) => {
                    return (<li key={wl._id}><Link to={`/watchlists/${wl._id}`} activeClassName="active">{wl.name}</Link></li>);
                  }) }
                </ul>
              </div>
            </Scrollbars>
          </div>
          <div className="sub-nav__content watchlists">
            <Scrollbars autoHide>
              <div className="container">
                { this.props.children }
              </div>
            </Scrollbars>
          </div>
        </div>
      </div>
    );
  }
}

WatchlistWrapper.propTypes = {
  t: PropTypes.func,
  changeLoading: PropTypes.func,
  fetchWatchlists: PropTypes.func,
  changeFavoritesFilter: PropTypes.func,
  favorites: PropTypes.object,
  watchlists: PropTypes.object,
  subscription: PropTypes.object,
  user: PropTypes.object,
  users: PropTypes.object,
};

export default WatchlistWrapper;
