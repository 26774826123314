import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import './side-add-player.scss';

import { withTranslation } from 'react-i18next';
import Icon from '../../Icon';

import AddFromDB from './add-from-db';
import AddNew from './add-new';

import { addPlayerToggle } from '../../../actions/match';

@withTranslation()
@connect((state) => ({
  match: state.match,
}), { addPlayerToggle })

export default class AddPlayer extends Component {
  static propTypes = {
    addPlayerToggle: PropTypes.func,
  };

  render() {
    const { t } = this.props;

    return (
      <div className="side-add-player">
        <a className="side-add-player__close" onClick={() => { this.props.addPlayerToggle(false); }}><Icon name="close" /></a>
        <div className="side-add-player__title">{t('ADD_PLAYER', { context: window.genderContext })}</div>
        <AddNew />
        <AddFromDB />
      </div>
    );
  }
}
