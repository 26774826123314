import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import AsyncCreatable from 'react-select/async-creatable';
import { Portal } from 'react-portal';

import './note-popup.scss';
import Icon from '../../Icon';
import { style, theme } from '../select_props';
import {
  createNote, updateNote, createNoteTag, fetchNoteTags,
} from '../../../actions/notes';
import { NotificationManager } from '../../notifications';
import eventTracker from '../../../helpers/event-tracker';

@connect((state) => {
  return {
    user: state.auth.user,
  };
}, {
  createNoteTag,
  fetchNoteTags,
  createNote,
  updateNote,
})
@withTranslation()
class NotePopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: '',
      value: '',
      tags: [],
    };

    this.changeInputValue = this.changeInputValue.bind(this);
    this.createNote = this.createNote.bind(this);
  }

  changeInputValue(name, value) {
    this.setState({ [name]: value });
  }

  createNote() {
    const { player, user } = this.props;
    const { player_id } = player;

    const { value, title, tags } = this.state;

    this.props.createNote(user, player_id).then((result) => {
      const { data } = result.payload;
      const note = {
        ...data, value, title, tags: tags.map((t) => t.value),
      };
      this.props.updateNote(note);
      NotificationManager.create({
        message: 'NOTE_ADDED',
        id: 'id',
        timeOut: 3000,
        type: 'success',
      });
      eventTracker.trackEvent('Favorites', 'Add note via quick note popup');
      this.props.onCloseClick();
    });
  }

  render() {
    const { t, onCloseClick, player } = this.props;
    const { title, text } = this.state;

    const fName = player.first_name || '';
    const lName = player.last_name || '';

    const name = (`${fName} ${lName}`).trim();

    return (
      <Portal>
        <div className="popup fade-in create-note-form__wrapper">
          <div className="create-note-form">
            <h1>
              <Icon name="note" className="title-icon" />
              {t('ADD_NOTE_TO', { name })}
            </h1>
            <Icon name="close" onClick={onCloseClick} className="close-icon" />
            <div className="input">
              <input
                name="text"
                onChange={(e) => { this.changeInputValue('title', e.target.value); }}
                value={title}
                placeholder={t('NOTE_TITLE_PLACEHOLDER')}
                onFocus={() => {
                  window.scrollTo(0, 0);
                  document.body.scrollTop = 0;
                }}
              />
            </div>
            <div className="input">
              <textarea
                name="value"
                onChange={(e) => { this.changeInputValue('value', e.target.value); }}
                value={text}
                placeholder={t('NOTE_TEXT_PLACEHOLDER')}
                onFocus={() => {
                  window.scrollTo(0, 0);
                  document.body.scrollTop = 0;
                }}
              />
            </div>
            <div className="input">
              <AsyncCreatable
                value={this.state.tags}
                className="note-tag-select"
                placeholder={t('ADD_TAG')}
                theme={theme}
                styles={style}
                promptTextCreator={(value) => t('CREATE_TAG', value)}
                defaultOptions
                cacheOptions
                onCreateOption={(value) => {
                  this.props.createNoteTag({ tag: value }).then((action) => {
                    const { data } = action.payload;
                    const v = [...this.state.tags, { value: data._id, label: data.tag }];
                    this.setState({ tags: v });
                  });
                }}
                loadOptions={(inputValue) => {
                  return this.props.fetchNoteTags().then((result) => {
                    let list = [];
                    if (!result.payload.data || !result.payload.data.length) {
                      return [];
                    }
                    list = result.payload.data.map((t) => ({ value: t._id, label: t.tag })).filter((t) => new RegExp(inputValue, 'ig').test(t.label));
                    return list;
                  });
                }}
                isMulti
                searchable
                onChange={(val) => {
                  const v = Array.isArray(val) ? [...val] : [val];
                  this.setState({ tags: v });
                }}
                onFocus={() => {
                  window.scrollTo(0, 0);
                  document.body.scrollTop = 0;
                }}
              />
            </div>
            <button onClick={this.createNote} className="btn">{t('DONE')}</button>
          </div>
        </div>
      </Portal>
    );
  }
}

NotePopup.propTypes = {
  player_id: PropTypes.string,
  onCloseClick: PropTypes.func,
  fetchNoteTags: PropTypes.func,
  player: PropTypes.object,
};

export default NotePopup;
