import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { push, replace } from 'react-router-redux';
import _ from 'lodash';

import { withTranslation } from 'react-i18next';
import NotesFilter from './components/notes-filter';
import NotesAside from './components/notes-aside';
import NotesContent from './components/notes-content';

import { changeLoading } from '../../../actions/index';
import {
  updateNotesDictionaries, fetchNoteTags, fetchNotes, updateNotesFilter, selectNote, clearNotes,
} from '../../../actions/notes';

import './notes.scss';
import { getWindowWidth } from '../../../helpers';

const getWindowHeight = (minus = 0) => document.documentElement.clientHeight ? document.documentElement.clientHeight - minus : window.innerHeight - minus;

@withTranslation()
@connect((state) => ({
  app: state,
  favorites: state.favorites,
  user: state.auth.user,
  notes: state.notes,
  auth: state.auth,
  account: state,
  teams: state.teams,
}), {
  updateNotesDictionaries, fetchNoteTags, fetchNotes, changeLoading, replace, push, selectNote, updateNotesFilter, clearNotes,
})
export default class Notes extends Component {
  constructor() {
    super();

    this.state = {
      height: getWindowHeight(),
      loaded: false,
    };

    this.handleResize = this.handleResize.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.notes.tags !== this.props.notes.tags) {
      this.prepareTagsDictionaries(this.props);
    }

    if (prevProps.notes.notes !== this.props.notes.notes) {
      this.prepareNotesDictionaries(this.props);
      this.props.updateNotesFilter(this.props.notes.filter);
    }

    if ((prevProps.notes.filteredNotes !== this.props.notes.filteredNotes)) {
      if (!this.props.notes.mode) {
        if (this.props.notes.filteredNotes.length) {
          this.props.selectNote(this.props.notes.filteredNotes[0]);
        } else {
          this.props.selectNote(null);
        }
      }
    }
  }

  componentDidMount() {
    this.props.clearNotes();

    const promises = [];

    this.props.changeLoading({ component: 'favorite', loading: true });
    this.setState({ loaded: false });

    promises.push(this.props.fetchNoteTags(this.props.player_id));
    promises.push(this.props.fetchNotes(this.props.player_id));

    Promise.all(promises).then(() => {
      if (this.props.notes.tags) {
        this.prepareTagsDictionaries(this.props);
      }

      if (this.props.notes.notes) {
        this.prepareNotesDictionaries(this.props);
      }

      if (this.props.player_id) {
        this.props.selectNote(null);
        this.props.updateNotesFilter({ player_id: this.props.player_id });
      } else {
        this.props.updateNotesFilter({ player_id: null });
      }

      this.props.changeLoading({ component: 'favorite', loading: false });
      this.setState({ loaded: true });

      window.addEventListener('resize', this.handleResize);
      this.handleResize();
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  prepareNotesDictionaries(props) {
    const { teams, t, i18n } = props;
    const { notes, tags } = props.notes;
    const { player_id } = props;

    const lng = i18n.language;

    let temp_notes = notes;

    if (player_id) {
      temp_notes = temp_notes.filter((n) => n.player_id == player_id);
    }

    let matchIds = temp_notes.map((n) => n.match_id).filter((mid) => mid);
    matchIds = _.uniq(matchIds);

    const groupByArrayOfValues = (array, key) => {
      return array.reduce((result, currentValue) => {
        if (Array.isArray(currentValue[key])) {
          currentValue[key].map((et) => {
            (result[et] = result[et] || []).push(currentValue);
          });
        } else if (currentValue[key]) {
          (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
        }
        return result;
      }, {});
    };

    const notesByKey = _.keyBy(temp_notes, 'id');
    const notesByUser = _.groupBy(temp_notes, (note) => `${note.user.firstName} ${note.user.lastName}`);
    const notesByMatch = _.groupBy(temp_notes, 'match_id');
    const notesByEvents = groupByArrayOfValues(temp_notes, 'event_type');

    const notesByTag = {};
    tags.forEach((t) => {
      const tagId = t._id;
      notesByTag[tagId] = temp_notes.filter((note) => note.tags.find((t) => t === tagId));
    });

    const matchesByKey = {};
    matchIds.map((mid) => {
      matchesByKey[mid] = _.get(notesByMatch, `[${mid}][${0}].match`, null);
      const homeTeam = _.get(teams, `${matchesByKey[mid].home}.name_${lng}`, _.get(teams, `${matchesByKey[mid].home}.name`, t('UNKNOWN_TEAM')));
      const awayTeam = _.get(teams, `${matchesByKey[mid].away}.name_${lng}`, _.get(teams, `${matchesByKey[mid].away}.name`, t('UNKNOWN_TEAM')));
      matchesByKey[mid].name = `${homeTeam} vs. ${awayTeam}, ${moment(matchesByKey[mid].date).format('DD.MM.YYYY')}`;
    });

    this.props.updateNotesDictionaries({
      notesByKey,
      notesByUser,
      notesByTag,
      notesByMatch,
      matchesByKey,
      notesByEvents,
    });
  }

  prepareTagsDictionaries(props) {
    const { tags } = props.notes;
    const tagsByKey = _.keyBy(tags, '_id');
    const tagsOptions = tags.map((t) => ({ value: t._id, label: t.tag }));

    this.props.updateNotesDictionaries({
      tagsByKey,
      tagsOptions,
    });
  }

  handleResize() {
    let minus = 0;

    const header = document.querySelector('.favorite-header');
    const nav = document.querySelector('.section-nav');
    const headerHeight = header ? header.offsetHeight : 0;
    const navHeight = nav ? nav.offsetHeight : 0;
    minus += headerHeight + navHeight + 120;
    let height = getWindowHeight(minus);

    if (getWindowWidth() <= 959) {
      height += 60;
    }

    this.setState({ height });
  }

  render() {
    const { height, loaded } = this.state;
    const { player_id } = this.props;

    if (!loaded) {
      return null;
    }

    return (
      <div className="favorites notes-page">
        <div className="notes-content-container player-notes">
          <div className="container notes-desktop-container">
            <div className="notes-desktop-body">
              <NotesFilter
                height={height}
                player_id={player_id}
              />
              <NotesAside
                height={height}
                player_id={player_id}
              />
              <NotesContent
                height={height}
                player_id={player_id}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
