import React, { Component } from 'react';
import { Link } from 'react-router';
import { withTranslation } from 'react-i18next';

@withTranslation()
export default class BillingInformation extends Component {
  render() {
    const { group, t, countries } = this.props;
    const properties = ['billingName', 'streetNumber', 'zipCode', 'country', 'vatNumber'];
    const items = properties.map((p, i) => this.renderBillingAddressRow(p, i));

    return (
      <p>
        {t('INVOICE_TO')}
        <br />
        { items }
        <Link to="/settings/billing/address">{t('CHANGE')}</Link>
      </p>
    );
  }

  renderBillingAddressRow(property, index) {
    const { group, countries, i18n } = this.props;
    const lng = i18n.language;

    if (property === 'country' && group.country) {
      return (
        <span key={index}>
          {_.get(countries, `listByAlpha2[${group.country}].name_${lng}`, _.get(countries, `listByAlpha2[${group.country}].name_en`, ''))}
          <br />
        </span>
      );
    }
    return group[property] ? (
      <span key={index}>
        {property === 'vatNumber' ? 'VAT: ' : null}
        {' '}
        {group[property]}
        <br />
      </span>
    ) : null;
  }
}
