import axios from 'axios';

import _ from 'lodash';
import { ROOT_URL } from './index';
import congratulationsConfirm from '../components/ui/congratulations-confirm';

export const SEEN_NOTIFICATION = 'SEEN_NOTIFICATION';
export const FETCH_NOTIFICATIONS = 'FETCH_NOTIFICATIONS';

export function seenNotification(id) {
  const request = axios.post(`${ROOT_URL}/notifications/`, { id });

  return {
    type: SEEN_NOTIFICATION,
    payload: request,
  };
}

export function fetchNotifications() {
  const request = axios.get(`${ROOT_URL}/notifications/`);

  return {
    type: FETCH_NOTIFICATIONS,
    payload: request,
  };
}
