import _ from 'lodash';
import { FETCH_RECENTLY_RATED, CHANGE_RECENTLY_RATED_FILTER, RECENTLY_RATED_RESET_FILTER } from '../actions/recently-rated';

const filterDefault = {
  name: '',
  user_id: false,
  list_user_id: null,
  position: undefined,
  team: undefined,
};

const INITIAL_STATE = {
  players: [],
  playersById: {},
  teams: [],
  stadiums: [],
  fetched: true,
  filter: filterDefault,
  filterInitial: filterDefault,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_RECENTLY_RATED:
      const players = _.get(action.payload, 'data.players', []);

      return {
        ...state,
        players,
        playersById: _.keyBy(players, 'player_id'),
        fetched: true,
      };

    case CHANGE_RECENTLY_RATED_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.payload,
        },
      };

    case RECENTLY_RATED_RESET_FILTER: {
      return {
        ...state,
        filter: state.filterInitial,
      }
    }

    default:
      return state;
  }
}
