import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import './suggestions-table.scss';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import _ from 'lodash';
import { Link } from 'react-router';
import PlayerImage from '../player-image';
import Icon from '../Icon';
import AddToFavorite from '../ui/add-to-favorites';
import Checkbox from '../ui/checkbox';

import { getPlayerImage, getTeamImage, getWatchlistCategory } from '../../helpers';

@withTranslation()
@connect((state) => {
  return {
    teams: state.teams,
    positions: state.positions,
    favorites: state.favorites.list,
  };
})
class SuggestionsTable extends Component {
  renderPlayer(player, index) {
    const {
      teams, positions, i18n, t, favorites, players, watchlist, selectedPlayers,
    } = this.props;
    const lng = i18n.language;

    const fav = favorites.find((f) => f.player_id == player.player_id);

    player.birth_date_raw = player.birth_date && player.birth_date !== '1900-01-01' ? moment().diff(player.birth_date, 'years') : 0;
    player.join_date_raw = player.join_date && player.join_date !== '1900-01-01' ? +moment(player.join_date, 'YYYY-MM-DD').unix() : 0;
    player.contract_until_raw = player.contract_until && player.contract_until !== '1900-01-01' ? +moment(player.contract_until, 'YYYY-MM-DD').unix() : 0;

    player.team_name = _.get(teams, `[${player.team_id}].name_${lng}`, _.get(teams, `[${player.team_id}].name`, '-'));
    player.position_name = _.get(positions, `[${player.position_id}].desc_short_${lng}`, _.get(positions, `[${player.position_id}].desc_short`, '-'));
    player.birth_date = player.birth_date && player.birth_date !== '1900-01-01' ? moment().diff(player.birth_date, 'years') : '–';
    player.country = player.country || '-';
    player.join_date = player.join_date && moment(player.join_date, 'YYYY-MM-DD').isValid() && moment(player.join_date, 'YYYY-MM-DD') > moment('1970-01-01', 'YYYY-MM-DD') ? moment(player.join_date, 'YYYY-MM-DD').format('DD/MM/YYYY') : '–';
    player.contract_until = player.contract_until && moment(player.contract_until, 'YYYY-MM-DD').isValid() && moment(player.contract_until, 'YYYY-MM-DD') > moment('1970-01-01', 'YYYY-MM-DD') ? moment(player.contract_until, 'YYYY-MM-DD').format('DD/MM/YYYY') : '–';
    const playerImage = getPlayerImage(player);
    const zIndex = players.length - index;

    const isChecked = selectedPlayers.findIndex((p) => p == player.player_id) !== -1;

    return (
      <li key={player.player_id} className="tr suggestion__player" style={{ zIndex }}>
        <span className="td col-checkbox">
          <Checkbox
            isChecked={isChecked}
            onClick={() => {
              this.props.onCheckPlayer(player);
            }}
          />
        </span>
        <span className="td col-name">
          <Link to={`/watchlists/${watchlist._id}/${player.player_id}`} className="col-name__player">
            <PlayerImage className="player-image" src={playerImage} />
            <span className="last-name">{ player.last_name }</span>
            {' '}
            { player.first_name !== player.last_name ? player.first_name : null }
          </Link>
        </span>
        <span className="td col-position">
          {player.position_name}
        </span>
        <span className="td col-age">
          {player.birth_date}
        </span>
        <span className="td col-team">
          <span className="team">
            <PlayerImage className="team-image" src={getTeamImage(player.team_id)} />
            {player.team_name}
          </span>
        </span>
        <span className="td col-date">{player.join_date}</span>
        <span className="td col-date">{player.contract_until}</span>
        <span className="td col-category">
          <AddToFavorite
            match_id={null}
            player={player}
            added_message={t('WATCHLIST_CATEGORY_CHANGED')}
          >
            <div className="favorite-selector">
              <Icon name="favorite" />
              {' '}
              {getWatchlistCategory(fav, t)}
              {' '}
              <Icon name="chevron_down" className="chevron-down" />
            </div>
          </AddToFavorite>
        </span>
      </li>
    );
  }

  render() {
    const { t, players } = this.props;
    return (
      <div className="suggestions-table__wrapper">
        <div className="suggestions-table">
          <div className="heading">
            <div className="tr">
              <span className="th col-checkbox" />
              <span className="th col-name">{t('NAME')}</span>
              <span className="th col-position">{t('POS')}</span>
              <span className="th col-age">{t('AGE')}</span>
              <span className="th col-team">{t('TEAM')}</span>
              <span className="th col-date">{t('IN_TEAM_SINCE')}</span>
              <span className="th col-date">{t('CONTRACT_UNTIL')}</span>
              <span className="th col-category">{t('CATEGORY')}</span>
            </div>
          </div>
          <ul>
            { players.map((p, i) => {
              return this.renderPlayer(p, i);
            }) }
          </ul>
        </div>
      </div>
    );
  }
}

SuggestionsTable.propTypes = {
  players: PropTypes.array,
  watchlist: PropTypes.object,
  onCheckPlayer: PropTypes.func,
  selectedPlayers: PropTypes.array,
};

export default SuggestionsTable;
