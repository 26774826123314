import React from 'react';

export default function ({ lang }) {
  switch (lang) {
    case 'de':
      return (
        <div>
          <h1 className="overlay-h1">Videos</h1>
          <p className="hero">
            Schneller mehr wissen. Unsere Video Tutorials unterstützen dich dabei, ScoutPad sicher zu beherrschen und die Funktionen näher kennen zu lernen. Einfach und vor allem schnell.
            <br />
            Diese Anleitung mit einfachen Tipps und Tricks ist ideal für visuelle Lerner, um das Beste aus ScoutPad herauszuholen.
          </p>
          <h4>Erste schritte</h4>
          <div dangerouslySetInnerHTML={{ __html: "<iframe src='https://player.vimeo.com/video/704072812' width='800' height='450' frameborder='0' webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>" }} />
        </div>
      );

    case 'pt':
      return (
        <div>
          <h1 className="overlay-h1">Começar</h1>
          <p className="hero">
            Tutoriais em vídeo rápidos com dicas e truques para tirar o máximo proveito do ScoutPad.
            <br />
            Estas passagens são perfeitas para alunos visuais que preferem assistir a um vídeo do que ler um manual.
          </p>
          <h4>Acesso iniciado</h4>
          <div dangerouslySetInnerHTML={{ __html: "<iframe src='https://player.vimeo.com/video/704072812' width='800' height='450' frameborder='0' webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>" }} />
        </div>
      );

    default:
      return (
        <div>
          <h1 className="overlay-h1">Videos</h1>
          <p className="hero">
            Quick video tutorials with tips and tricks for getting the most out of ScoutPad.
            <br />
            These walkthroughs are perfect for visual learners who would rather watch a video than read a manual.
          </p>
          <h4>Getting started</h4>
          <div dangerouslySetInnerHTML={{ __html: "<iframe src='https://player.vimeo.com/video/704072812' width='800' height='450' frameborder='0' webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>" }} />
        </div>
      );
  }
}
