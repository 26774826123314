import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import './player.scss';

import { withTranslation } from 'react-i18next';
import PlayerInformation from '../../../ui/player-information';
import PlayerTeamInformation from '../../../ui/player-team-information';
import PositionSelector from '../position-selector';

import { formatPlayerData } from '../../../../helpers';
import Icon from '../../../Icon';

import { updatePlayerInMatch } from '../../../../actions/favorites';

import { changePlayerControl, changeInnerControl } from '../../../../actions/match';

@withTranslation()
@connect((state) => {
  return {
    match: state.match,
    positions: state.positions,
    teams: state.teams,
    leagues: state.leagues,
    player: _.get(state.match, `data[${state.match.side}].players[${state.match.player_id}].information`, null),
    player_id: state.match.player_id,
    side: state.match.side,
  };
}, { changePlayerControl, changeInnerControl, updatePlayerInMatch })
export default class Player extends Component {
  constructor(props) {
    super(props);

    this.state = { edit: null };

    this.onEditClick = this.onEditClick.bind(this);
    this.onCancelClick = this.onCancelClick.bind(this);
    this.onSaveClick = this.onSaveClick.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onNoteClick = this.onNoteClick.bind(this);
  }

  onEditClick() {
    const { player } = this.props;

    this.setState({
      edit: {
        ...player,
      },
    });
  }

  onCancelClick() {
    this.setState({
      edit: null,
    });
  }

  onSaveClick() {
    const {
      match, updatePlayerInMatch, changePlayerControl, side, player_id,
    } = this.props;
    const { edit } = this.state;
    const { match_id } = match.data;

    changePlayerControl({ player_id, side });
    updatePlayerInMatch({ ...edit }, match_id);
    this.setState({
      edit: null,
    });
  }

  onChange(property) {
    const { player } = this.props;
    if (!player) return;
    const { value } = property;
    if (value.value) {
      property.value = value.value;
    }

    const newPlayer = {
      ...player,
      ...this.state.edit,
    };
    newPlayer[property.name] = property.value;

    this.setState({
      edit: {
        ...newPlayer,
      },
    });
  }

  onNoteClick() {
    const {
      changePlayerControl, changeInnerControl, player_id, side,
    } = this.props;

    changePlayerControl({ player_id, side });
    changeInnerControl({ value: 'notes' });
  }

  render() {
    const {
      positions, t, i18n, player, teams, leagues, activePosition,
    } = this.props;
    const lng = i18n.language;

    if (!player) return null;

    const positionOptions = _.chain(positions)
      .filter((p) => p.upper_position)
      .orderBy(['upper_rank'], ['asc'])
      .map((p) => ({ value: p.id, label: _.get(p, `desc_short_${lng}`, _.get(p, 'desc_short', 'N/D')) }))
      .value();

    const formattedPlayer = formatPlayerData(player, t, teams, lng, leagues, positions);

    return (
      <div className="single-report__player player-information">
        <div className="single-report__player__info">
          <PlayerInformation
            isEdit={!!this.state.edit}
            onCancelClick={this.onCancelClick}
            t={t}
            onSaveClick={this.onSaveClick}
            onEditClick={this.onEditClick}
            player={formattedPlayer}
            onDataChange={this.onChange}
          />
        </div>
        <div className="single-report__player__team">
          <PlayerTeamInformation
            t={t}
            onDataChange={this.onChange}
            isEdit={!!this.state.edit}
            position_options={positionOptions}
            player={formattedPlayer}
            hideValue
            canChangeTeam={false}
          />
        </div>
        <div className="single-report__player__position">
          <PositionSelector activePosition={activePosition} />
          {!_.isEmpty(activePosition) ? (
            <button className="note-button" onClick={(this.onNoteClick)}>
              <Icon name="note_neo" />
            </button>
          ) : null}
        </div>
      </div>
    );
  }
}
