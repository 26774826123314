import React, { Component } from 'react';
import sub from 'date-fns/sub';
import DatePicker from 'react-datepicker/dist/react-datepicker';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Icon from '../../Icon';

import './filter-dates.scss';

@withTranslation()
export default class FilterDates extends Component {
  static propTypes = {
    handleChangeStart: PropTypes.func,
    handleChangeEnd: PropTypes.func,
    handleChangeBoth: PropTypes.func,
    t: PropTypes.func,
    allTimesStart: PropTypes.any,
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    classes: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.handleChangeStart = this.handleChangeStart.bind(this);
    this.handleChangeEnd = this.handleChangeEnd.bind(this);
    this.handleBoth = this.handleBoth.bind(this);
  }

  handleChangeStart(value) {
    this.props.handleChangeStart(value);
  }

  handleChangeEnd(value) {
    this.props.handleChangeEnd(value);
  }

  handleBoth(start) {
    this.props.handleChangeBoth(start, new Date());
    this.start.setOpen(false);
    this.end.setOpen(false);
  }

  renderQuickButtons() {
    const { t, allTimesStart } = this.props;

    return (
      <div className="filter-date__quick-buttons">
        <span
          className="filter-date__quick-button"
          onClick={() => this.handleBoth(sub(new Date(), { months: 1 }))}
        >
          {t('LAST_MONTH')}
        </span>
        <span
          className="filter-date__quick-button"
          onClick={() => this.handleBoth(sub(new Date(), { years: 1 }))}
        >
          {t('LAST_YEAR')}
        </span>
        <span
          className="filter-date__quick-button"
          onClick={() => this.handleBoth(allTimesStart)}
        >
          {t('ALL_TIME')}
        </span>
      </div>
    );
  }

  render() {
    const classes = this.props.className;
    const { startDate, endDate, t } = this.props;

    return (
      <div className={`${classes} filter-date`}>
        <label className="filter-date__range-start-container">
          <DatePicker
            ref={(s) => this.start = s}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            selected={startDate}
            dateFormat="dd MMM yy"
            onChange={this.handleChangeStart}
            className="filter-date__range filter-date__range-start"
            calendarClassName=" filter-date__calendar-range-start"
            popperPlacement="bottom-start"
            placeholderText={t('CHOOSE_DATE')}
          >
            {this.renderQuickButtons()}
          </DatePicker>
          <Icon name="chevron_down" />
        </label>
        <span>—</span>
        <label className="filter-date__range-end-container">
          <DatePicker
            ref={(e) => this.end = e}
            startDate={startDate}
            endDate={endDate}
            selected={endDate}
            selectsEnd
            dateFormat="dd MMM yy"
            onChange={this.handleChangeEnd}
            className="filter-date__range filter-date__range-end"
            calendarClassName=" filter-date__calendar-range-end"
            popperPlacement="bottom-end"
            placeholderText={t('CHOOSE_DATE')}
          >
            {this.renderQuickButtons()}
          </DatePicker>
          <Icon name="chevron_down" />
        </label>
      </div>
    );
  }
}
