import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import * as d3 from 'd3';
import { browserHistory } from 'react-router';
import Icon from '../../Icon';
import Image from '../../player-image';

import './reports.scss';
import { withTranslation } from 'react-i18next';

@withTranslation()
@connect((state) => {
  return {
    info: state.favorites.detail,
    teams: state.teams,
    leagues: state.leagues,
    user: state.auth.user,
  };
}, {})
export default class Reports extends Component {
  render() {
    const {
      matches, user, t, playerId,
    } = this.props;
    const isActive = _.get(user, 'active', false);

    if (!matches || !matches.length || playerId === 'demo') {
      return (
        <div className="reports">
          <p className="empty-message">{t('NO_REPORTS_FOR_PLAYER')}</p>
        </div>
      );
    }

    return (
      <div className="reports">
        <table>
          <thead>
            <tr>
              <th>{t('HOME_TEAM')}</th>
              <th>{t('AWAY_TEAM')}</th>
              <th>{t('RESULT')}</th>
              <th>{t('DATE')}</th>
              <th>{t('COMPETITION')}</th>
              <th>{t('OVERALL_RATING')}</th>
              <th />
            </tr>
          </thead>
          <tbody>
            { matches.map((match, index) => this.renderRow(match, index, isActive)) }
          </tbody>
        </table>
      </div>
    );
  }

  renderRow(match, index, isActive) {
    const {
      teams, leagues, playerId, t, i18n, info,
    } = this.props;

    const lng = i18n.language;
    const { finalRatings } = info;

    const home_name = _.get(teams, `[${match.home.team_id}].name_${lng}`, _.get(teams, `[${match.home.team_id}].name`, t('UNKNOWN_TEAM')));
    const away_name = _.get(teams, `[${match.away.team_id}].name_${lng}`, _.get(teams, `[${match.away.team_id}].name`, t('UNKNOWN_TEAM')));
    const home_image = `https://static.sp7f.com/teams/${match.home.team_id}.jpg`;
    const away_image = `https://static.sp7f.com/teams/${match.away.team_id}.jpg`;
    const date = moment(match.date).format('DD.MM.YYYY');
    const league = _.get(leagues, `[${match.league_id}].name_${lng}`, _.get(leagues, `[${match.league_id}].name`, t('UNKNOWN_LEAGUE')));
    const goals = `${match.homegoals || 0 + match.homegoals_ht || 0}:${match.awaygoals || 0 + match.awaygoals_ht || 0}`;
    let rating = 0;

    if (finalRatings && finalRatings.length) {
      const finalRating = finalRatings.find(({ match_id }) => match_id === match.match_id);
      if (finalRating) {
        rating = finalRating.value;
      }
    }

    return (
      <tr key={index} onClick={(e) => { e.stopPropagation(); isActive && this.rowClick(match, playerId); }} className={isActive ? '' : 'inactive'}>
        <td>
          {' '}
          <Image className="team-image" src={home_image} />
          {' '}
          { home_name }
        </td>
        <td>
          {' '}
          <Image className="team-image" src={away_image} />
          {' '}
          { away_name }
        </td>
        <td>{goals}</td>
        <td>{date}</td>
        <td>{league}</td>
        <td>{this.renderRating(rating, t)}</td>
        <td className="pdf-cell">
          <Icon name="pdf" className="pdf-link" onClick={(e) => { e.stopPropagation(); this.pdfClick(match, playerId); }} />
        </td>
      </tr>
    );
  }

  pdfClick(match, playerId) {
    browserHistory.push(`/pdf/${match.match_id}?from=${playerId}`);
  }

  rowClick(match, playerId) {
    browserHistory.push(`/match/${match.match_id}?from=${playerId}`);
  }

  renderRating(rate, t) {
    const color = d3.scaleLinear().domain([1, 2, 3, 4, 5]).range(['#1D619F', '#6CB5D7', '#A4A6A4', '#F98736', '#DA4B06']);
    const title = [t('NONE'), '--', '-', '0', '+', '++'];
    const intRate = Math.round(rate);

    if (!intRate) {
      return (
        <span className="rating-rate">
          <span className="legend" style={{ backgroundColor: '#FFF' }} />
          { title[0] }
        </span>
      );
    }
    return (
      <span className="rating-rate">
        <span className="legend" style={{ backgroundColor: color(intRate) }} />
        { title[intRate] }
      </span>
    );
  }
}
