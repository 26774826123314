import React, { Component } from 'react';
import './data_item_changeable.scss';
import moment from 'moment';
import MaskedInput from 'react-maskedinput';
import Select from 'react-select';
import Icon from '../../../Icon';
import { editSelectTheme, styleEditSelect } from '../../../ui/select_props';
import TeamSelect from '../../../ui/teams-select/simple-async';
import PlayerImage from '../../../player-image';

export default class DataInputChangeable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isChanging: false,
      isHover: false,
      value: props.inputValue,
    };

    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseOut = this.onMouseOut.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.setState({
      value: this.props.inputValue,
    });
  }

  onMouseEnter() {
    this.setState({
      // isHover: true,
    });
  }

  onMouseOut() {
    this.setState({
      // isHover: false,
    });
  }

  onBlur(value) {
    this.setState({
      isChanging: false,
    });
  }

  onClick() {
    this.setState({
      isChanging: true,
    });
  }

  onChange(value) {
    this.setState({ value });
    this.props.onChange({ value, name: this.props.name });
  }

  render() {
    const {
      label, value, postfix, prefix, type, style, icon, image
    } = this.props;
    const { isHover } = this.state;
    const { isChanging } = this.props;

    let printValue = value;
    const imageComponent = null;

    if (value === null || value === undefined) {
      printValue = 'N/D';
    } else {
      printValue = value;

      if (postfix) {
        printValue += postfix;
      }

      if (prefix) {
        printValue = prefix + printValue;
      }
    }

    let InputComponent = null;

    if (type === 'text') {
      InputComponent = (
        <TextInput
          onBlur={this.onBlur}
          onChange={this.onChange}
          name={this.props.name}
          value={this.state.value}
        />
      );
    }

    if (type === 'date') {
      InputComponent = (
        <DateInput
          onBlur={this.onBlur}
          onChange={this.onChange}
          name={this.props.name}
          value={this.state.value}
        />
      );
    }
    if (type === 'select') {
      InputComponent = (
        <SelectInput
          options={this.props.options}
          onBlur={this.onBlur}
          onChange={this.onChange}
          name={this.props.name}
          value={this.state.value}
        />
      );
    }
    if (type === 'number') {
      InputComponent = (
        <NumberInput
          onBlur={this.onBlur}
          onChange={this.onChange}
          name={this.props.name}
          value={this.state.value}
        />
      );
    }

    if (type === 'team_select') {
      InputComponent = (
        <TeamSelectInput
          onBlur={this.onBlur}
          onChange={this.onChange}
          name={this.props.name}
          value={this.state.value}
        />
      );
    }

    const ValueComponent = (
      <div
        className="data-item-changeable__value"
        onClick={this.onClick}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseOut}
      >
        {printValue}
        {isHover ? (<Icon name="pencil" className="data-item-changeable__edit" />) : null}
      </div>
    );

    return (
      <div className={`data-item-changeable ${isChanging ? 'edit-mode' : ''}`}>
        { icon ? <div className="data-item__icon">{icon}</div> : null }
        <div className="data-item__content">
          {
              isChanging ? (
                <div className="data-item-changeable__input">
                  {InputComponent}
                </div>
              ) : (
                <div className="data-item__value">
                  {image ? <PlayerImage className="data-item__image" src={image} /> : null }
                  {ValueComponent}
                </div>
              )
            }
          <div className="data-item-changeable__label">{label}</div>
        </div>
      </div>
    );
  }
}

function TextInput(props) {
  return (
    <input
      onBlur={(e) => props.onBlur(e.target.value)}
      onChange={(e) => props.onChange(e.target.value)}
      value={props.value || ''}
      autoFocus
      className="text_input"
      type="text"
    />
  );
}

function NumberInput(props) {
  return (
    <input
      onBlur={(e) => props.onBlur(e.target.value)}
      onChange={(e) => props.onChange(e.target.value)}
      value={props.value || ''}
      autoFocus={!props.disableAutofocus}
      className="number_input"
      type="number"
    />
  );
}

function DateInput(props) {
  let { value } = props;
  if (value && value.value) {
    value = value.value;
  }

  return (
    <MaskedInput
      onBlur={(e) => {
        const { value } = e.target;
        const val = moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD');
        props.onChange({
          name: props.name, value: val,
        });
      }}
      value={moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY')}
      mask="11/11/1111"
      placeholderChar=" "
      placeholder="DD/MM/YYYY"
      autoFocus
      className="date_input"
    />
  );
}

function SelectInput(props) {
  return (
    <Select
      {...props}
      styles={styleEditSelect}
      theme={editSelectTheme}
      hideSelectedOptions
      onBlur={(e) => {
        props.onBlur(e.target.value);
      }}
      onChange={(value) => {
        props.onChange(value);
      }}
      value={props.value}
      autoFocus
      className="select_input"
    />
  );
}

function TeamSelectInput(props) {
  return (
    <TeamSelect
      {...props}
      onBlur={(e) => { props.onBlur(e.target.value); }}
      onChange={props.onChange}
      value={props.value}
      baseOptions={[]}
    />
  );
}
