import React from 'react';
import PropTypes from 'prop-types';

import './player.scss';

export default function Player({ img, name }) {
  return (
    <div className="match-card__player">
      <div className="match-card__player-image" style={{ backgroundImage: `url(${img})` }} />
      <span className="match-card__name">{name}</span>
    </div>
  );
}

Player.propTypes = {
  img: PropTypes.string,
  name: PropTypes.string,
};
