import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Icon from '../../Icon';

@withTranslation()
@connect((state) => {
  return {
    user: state.auth.user,
    subscription: state.subscription,
  };
}, {})
class RestartSubscriptionMessage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      minimized: false,
    };
  }

  render() {
    const { t } = this.props;
    const { minimized } = this.state;

    return (
      <div className={`restart-subscription main-message ${minimized ? 'minimized' : ''}`}>
        <div>
          <span className="close" onClick={() => { this.setState({ minimized: true }); }}>
            <Icon name="close" />
          </span>
          <h2>{t('FAILED_TAX_WARNING')}</h2>
          <div>
            <h5 dangerouslySetInnerHTML={{ __html: t('FAILED_TAX_WARNING_MESSAGE') }} />
          </div>
        </div>
      </div>
    );
  }
}

RestartSubscriptionMessage.propTypes = {
  user: PropTypes.object,
  subscription: PropTypes.object,
  t: PropTypes.func,
};

export default RestartSubscriptionMessage;
