import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import './player-controls.scss';

import Inner from './inner';
import Outer from './outer';

import { hideControls } from '../../../../actions/match';
import { getWindowWidth } from '../../../../helpers';

@connect(() => ({}), { hideControls })

export default class Control extends Component {
  static propTypes = {
    hideControls: PropTypes.func,
  };

  constructor() {
    super();

    this.state = {
      timer: null,
    };

    this.stopTimer = this.stopTimer.bind(this);
    this.startTimer = this.startTimer.bind(this);
  }

  componentWillUnmount() {
    const { timer } = this.state;
    timer && clearInterval(timer);
    this.setState({
      timer: null,
    });
  }

  stopTimer() {
    const { timer } = this.state;
    timer && clearInterval(timer);
    this.setState({
      timer: null,
    });
  }

  startTimer() {
    this.setState({
      timer: setTimeout(() => { this.props.hideControls(); }, 2000),
    });
  }

  render() {
    const mediaQuery = getWindowWidth() > 960 ? '175' : '120';
    const { timer } = this.state;

    return (
      <svg className={`player-controls ${timer ? 'saved' : ''}`}>
        <g transform={`translate(${mediaQuery}, ${mediaQuery})`}>
          <Inner stopTimer={this.stopTimer} />
          <Outer startTimer={this.startTimer} />
        </g>
      </svg>
    );
  }
}
