import React, { Component } from 'react';
import { Link } from 'react-router';

import './player-controls.scss';
import { withTranslation } from 'react-i18next';

import Icon from '../../../Icon';
import PlayerCard from '../player-card';

@withTranslation()
export default class PlayerControls extends Component {
  render() {
    const {
      isFirst, isLast, player_id, position_id, movePlayer, removePlayer, toggleMenu, t, coords, player_data, team_id,
    } = this.props;

    const x = (1 - coords.x) * 100;

    return (
      <div className={`shadow-team__player-controls ${x <= 50 ? 'left' : 'right'}`}>

        <ul>
          <li className="profile">
            <PlayerCard playerData={player_data} shadow_team_id={team_id} />
          </li>
          { !isFirst ? (
            <li onClick={() => { movePlayer(position_id, player_id, 'up'); }}>
              <span className="tri-up list-icon" />
              {' '}
              {t('SHADOW_MOVE_UP')}
            </li>
          ) : null }
          { !isLast ? (
            <li onClick={() => { movePlayer(position_id, player_id, 'down'); }}>
              <span className="tri-down list-icon" />
              {' '}
              {t('SHADOW_MOVE_DOWN')}
            </li>
          ) : null }
          <li onClick={() => { toggleMenu(position_id, player_id); removePlayer(position_id, player_id); }}>
            <Icon name="close" className="remove list-icon" />
            {' '}
            {t('SHADOW_REMOVE_PLAYER', { context: window.genderContext })}
          </li>
        </ul>
      </div>
    );
  }
}
