import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import InfiniteLoader from 'react-window-infinite-loader';
import VirtualGrid from '../../ui/virtual-list-grid';

import './reports-list.scss';
import UpcomingCard from '../../ui/match-card/upcoming';
import MatchLoader from '../../ui/match-card/loader';
import { useTranslation } from 'react-i18next';
import EditMatch from '../../ui/match-edit-form';
import { canAddReport } from '../../../helpers/limits';
import eventTracker from '../../../helpers/event-tracker';
import { browserHistory } from 'react-router';

export default function UpcomingList({
  list,
  hasNextPage,
  isListLoading,
  loadNextPage,
  total,
  teams,
  leagues,
  reportsPerPage,
  subscription,
  updateReportsLimits,
  fetchAddMatch,
}) {
  const [edit, setEdit] = useState(null);
  const { t, i18n } = useTranslation();
  const lng = i18n.language;
  const itemSize = [338, 138];
  const [size, setSize] = useState(itemSize);
  const loaderRef = useRef(null);
  const containerRef = useRef(null);

  const resizeHandler = () => {
    const rect = containerRef.current.getBoundingClientRect();
    setSize([rect?.width, rect?.height]);
  };

  useEffect(() => {
    resizeHandler();
    window.addEventListener('resize', resizeHandler);
    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  const itemCount = hasNextPage ? list.length + 3 : list.length;
  const loadMoreItems = isListLoading ? () => {} : loadNextPage;

  let columnCount = Math.floor(size[0] / itemSize[0]);
  if (columnCount > 3) { columnCount = 3; }
  const rowCount = Math.ceil(itemCount / columnCount);

  const onAddMatch = ({ oldMatch, newMatch }) => {
    const {
      date, stadium, home, away, league, assign_to, type, player_id,
    } = newMatch;

    if (!canAddReport(subscription)) {
      return null;
    }

    eventTracker.trackEvent('Reports', 'Add match from upcoming');

    updateReportsLimits({ current: subscription.reports.current + 1 });
    return fetchAddMatch({
      date,
      stadium_id: stadium,
      league_id: league,
      home_team_id: home,
      away_team_id: away,
      assign_to,
      type,
      player_id,
    }, subscription).then((result) => {
      if (result.payload.match) {
        eventTracker.trackEvent('homepage', 'match created');
        browserHistory.push(`/match/${result.payload.match.match_id}`);
      }
    }).catch((err) => {
      console.log(err);
    });
  };

  const renderItem = ({
    columnIndex, rowIndex, style, data,
  }) => {
    const matchIndex = (rowIndex * columnCount) + columnIndex;

    if (list[matchIndex] === undefined && matchIndex <= total - 1) {
      return (
        <div style={style}>
          <MatchLoader />
        </div>
      );
    }

    if (list[matchIndex] === undefined) {
      return null;
    }

    const report = list[matchIndex];
    return (
      <div style={style}>
        <UpcomingCard
          report={report}
          index={matchIndex}
          teams={teams}
          leagues={leagues}
          onClick={(match) => {
            const newMatch = {
              home: { team_id: match.team_id_home },
              away: { team_id: match.team_id_away },
              type: 'match',
              date: new Date(match.date),
              league_id: match.league_id,
            };
            setEdit(newMatch);
          }}
          t={t}
          lng={lng}
        />
      </div>
    );
  };

  if (!list.length && isListLoading) {
    return (
      <div className="reports-list" ref={containerRef}>
        {
          [0, 1, 2, 3, 4, 5].map((i) => {
            return (
              <React.Fragment key={i}>
                <div className="match-card__container">
                  <MatchLoader />
                </div>
                {(i + 1) % columnCount === 0 ? <br /> : null}
              </React.Fragment>
            );
          })
        }

      </div>
    );
  }

  if(!list.length) {
    return (
      <div className="reports-list" ref={containerRef}>
        <p className="reports-empty">{t('NO_UPCOMING_MATCHES_FOUND')}</p>
      </div>
    );
  }

  return (
    <div className="reports-list" ref={containerRef}>
      <InfiniteLoader
        ref={loaderRef}
        isItemLoaded={(index) => !hasNextPage || index < list.length}
        itemCount={itemCount}
        loadMoreItems={loadMoreItems}
        minimumBatchSize={reportsPerPage}
      >
        {
          ({ onItemsRendered, ref }) => {
            const newItemsRendered = (gridData) => {
              const useOverscanForLoading = true;
              const {
                visibleRowStartIndex,
                visibleRowStopIndex,
                visibleColumnStopIndex,
                overscanRowStartIndex,
                overscanRowStopIndex,
                overscanColumnStopIndex,
              } = gridData;

              const endCol = (useOverscanForLoading || true
                ? overscanColumnStopIndex
                : visibleColumnStopIndex) + 1;

              const startRow = useOverscanForLoading || true
                ? overscanRowStartIndex
                : visibleRowStartIndex;
              const endRow = useOverscanForLoading || true
                ? overscanRowStopIndex
                : visibleRowStopIndex;

              const visibleStartIndex = startRow * endCol;
              const visibleStopIndex = endRow * endCol;

              onItemsRendered({
                // call onItemsRendered from InfiniteLoader so it can load more if needed
                visibleStartIndex,
                visibleStopIndex,
              });
            };

            return (
              <VirtualGrid
                item={renderItem}
                itemData={list}
                count={itemCount}
                onItemsRendered={newItemsRendered}
                columnCount={columnCount}
                rowCount={rowCount}
                ref={ref}
                width={size[0]}
                height={size[1]}
                itemSize={itemSize}
                list={list}
                classNames="reports__virtual-grid"
              />
            );
          }
        }
      </InfiniteLoader>
      {edit
        ? (
          <EditMatch
            isAdd
            match={edit}
            onClose={() => setEdit(false)}
            onSubmit={onAddMatch}
            players={{}}
          />
        )
        : null}
    </div>
  );
}

UpcomingList.propTypes = {
  list: PropTypes.array,
  renderReport: PropTypes.func,
  hasNextPage: PropTypes.bool,
  loadNextPage: PropTypes.func,
  isListLoading: PropTypes.bool,
  total: PropTypes.number,
  teams: PropTypes.object,
  leagues: PropTypes.object,
  authUser: PropTypes.object,
  players: PropTypes.object,
  deleteReport: PropTypes.func,
  updateReport: PropTypes.func,
  reportsPerPage: PropTypes.number,
  subscription: PropTypes.object,
  updateReportsLimits: PropTypes.func,
  fetchAddMatch: PropTypes.func,
};
