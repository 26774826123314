import _ from 'lodash';

export function canAddWatchlist(subscription) {
  if (!subscription.plan || !subscription.active) { return false; }
  if (subscription.favorites.max === 'INFINITY') {
    return true;
  }
  if (+subscription.favorites.current < +subscription.favorites.max) {
    return true;
  }

  return false;
}

export function canAddShadowTeams(subscription) {
  if (!subscription.plan || !subscription.active) return false;
  if (subscription.shadowTeams.max === 0) { return false; }
  return subscription.shadowTeams.max === 'INFINITY' || +subscription.shadowTeams.current < +subscription.shadowTeams.max;
}

export function canUseShadowTeams(subscription) {
  if (!subscription.plan || !subscription.active && !subscription.isTrial) { return false; }
  if (subscription.shadowTeams && subscription.shadowTeams.max === 0 && !subscription.isTrial) { return false; }

  return true;
}

export function exceededShadowTeamsLimit(subscription) {
  return subscription.shadowTeams.max !== 'INFINITY' && ((subscription.shadowTeams.max === 0 && +subscription.shadowTeams.current) || (+subscription.shadowTeams.current > +subscription.shadowTeams.max));
}

export function canUseWatchlists(subscription) {
  if (!subscription.plan || !subscription.active && !subscription.isTrial) { return false; }
  if (subscription.watchlists && subscription.watchlists.max === 0 && !subscription.isTrial) { return false; }

  return true;
}
export function canAddWatchlists(subscription) {
  if (!subscription.plan || !subscription.active) return false;
  if (subscription.watchlists.max === 0) { return false; }
  return subscription.watchlists.max === 'INFINITY' || +subscription.watchlists.current < +subscription.watchlists.max;
}

export function exceededWatchlistLimit(subscription) {
  return subscription.watchlists.max !== 'INFINITY' && ((subscription.watchlists.max === 0 && +subscription.watchlists.current) || (+subscription.watchlists.current > +subscription.watchlists.max));
}

export function canUseNotesSection(subscription) {
  if (!subscription.plan) { return false; }
  if (subscription.isFree && !subscription.isTrial) { return false; }
  if (!subscription.active) { return false; }

  return true;
}

export function canUseAnalyticsSection(subscription) {
  if (!subscription.active || !subscription.plan) return false;

  return subscription.canUseAnalytics;
}

export function canAddReport(subscription) {
  if (!subscription.active || !subscription.plan) return false;
  return subscription.reports.max === 'INFINITY' || +subscription.reports.current < +subscription.reports.max;
}

export function canExportXML(subscription) {
  if (!subscription.plan || !subscription.active) return false;
  return subscription.xmlExport;
}

export function isGroupUsers(subscription) {
  return _.get(subscription, 'scouts.current', 0) > 1;
}
