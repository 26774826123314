import axios from 'axios';
import { ROOT_URL } from '.';

export const FETCH_CUSTOMERS = 'FETCH_CUSTOMERS';
export const CHANGE_SORT = 'CHANGE_SORT';
export const CHANGE_FILTER = 'CHANGE_FILTER';
export const ERROR_CUSTOMERS = 'ERROR_CUSTOMERS';
export const FETCH_CUSTOMER = 'FETCH_CUSTOMER';
export const ERROR_CUSTOMER = 'ERROR_CUSTOMER';
export const ADD_CUSTOMER = 'ADD_CUSTOMER';
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';
export const DELETE_CUSTOMER = 'DELETE_CUSTOMER';
export const LOAD_CUSTOMERS_LIST = 'LOAD_CUSTOMERS_LIST';
export const LOAD_CUSTOMER_DETAILS = 'LOAD_CUSTOMER_DETAILS';
export const GENERATE_FILTERS = 'GENERATE_FILTERS';

export const DELETE_CUSTOMER_USER = 'DELETE_CUSTOMER_USER';
export const UPDATE_CUSTOMER_USER = 'UPDATE_CUSTOMER_USER';
export const CREATE_CUSTOMER_USER = 'CREATE_CUSTOMER_USER';
export const RESET_CUSTOMER = 'RESET_CUSTOMER';

export const RESET_CUSTOMER_USER_PASSWORD = 'RESET_PASSWORD';

export const MANUALLY_VERIFY_CUSTOMER_EMAIL = 'MANUALLY_VERIFY_CUSTOMER_EMAIL';

export function manuallyVerifyCustomerEmail(id) {
  const request = axios.get(`${ROOT_URL}/customers/verify-email/${id}`);
  return {
    type: MANUALLY_VERIFY_CUSTOMER_EMAIL,
    payload: request,
  };
}

export function resetPassword(id) {
  const request = axios.get(`${ROOT_URL}/customers/reset-password/${id}`);
  return {
    type: RESET_CUSTOMER_USER_PASSWORD,
    payload: request,
  };
}

export function resetCustomer() {
  return {
    type: RESET_CUSTOMER,
  };
}

export function deactivateCustomer(id, active) {
  const request = axios.post(`${ROOT_URL}/customers/${id}`, {
    active,
  });
  return {
    type: UPDATE_CUSTOMER,
    payload: request,
  };
}

export function deleteCustomer(id) {
  const request = axios.delete(`${ROOT_URL}/customers/${id}`);
  return {
    type: DELETE_CUSTOMER,
    payload: id,
  };
}

export function createCustomer(customer) {
  const request = axios.post(`${ROOT_URL}/customers/create/`, {
    ...customer,
  });

  return {
    type: ADD_CUSTOMER,
    payload: request,
  };
}

export function updateCustomer(customer) {
  const request = axios.post(`${ROOT_URL}/customers/${customer._id}`, {
    ...customer,
  });

  return {
    type: UPDATE_CUSTOMER,
    payload: request,
  };
}

export function generateFilters(plans) {
  return {
    type: GENERATE_FILTERS,
    payload: { plans },
  };
}

export function changeFilters(filters) {
  return {
    type: CHANGE_FILTER,
    payload: filters,
  };
}

export function changeSort(property, direction) {
  return {
    type: CHANGE_SORT,
    payload: { property, direction },
  };
}

export function fetchCustomers(page, filters, sort, direction) {
  return (dispatch) => {
    dispatch({
      type: LOAD_CUSTOMERS_LIST,
      page,
    });

    const queryFilters = {};

    _.forOwn(filters, (value, key) => {
      queryFilters[key] = value.dbValue;
    });

    const request = axios.post(`${ROOT_URL}/customers/`, {
      page, filters: queryFilters, sort, direction,
    });
    request.then((response) => {
      dispatch({
        type: FETCH_CUSTOMERS,
        payload: response.data,
        page,
      });
    }).catch((err) => {
      dispatch({
        type: ERROR_CUSTOMERS,
        payload: err.message,
      });
    });
  };
}

export function fetchCustomer(id) {
  return (dispatch) => {
    dispatch({
      type: LOAD_CUSTOMER_DETAILS,
      id,
    });

    const request = axios.get(`${ROOT_URL}/customers/${id}`);

    return request.then((response) => {
      dispatch({
        type: FETCH_CUSTOMER,
        payload: response.data.details,
      });
    }).catch((err) => {
      console.log(err);
      dispatch({
        type: ERROR_CUSTOMER,
        payload: err.response ? err.response.data : err.message,
      });
    });
  };
}

export function deleteUser(user) {
  axios.delete(`${ROOT_URL}/users/${user._id}`);

  return {
    type: DELETE_CUSTOMER_USER,
    payload: user._id,
  };
}
export function updateUser(user) {
  const request = axios.put(`${ROOT_URL}/users/${user._id}`, user);
  return {
    type: UPDATE_CUSTOMER_USER,
    payload: request,
  };
}
export function createUser(user) {
  const request = axios.post(`${ROOT_URL}/auth/register`, { user });

  return {
    type: CREATE_CUSTOMER_USER,
    payload: request,
  };
}
