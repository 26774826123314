import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import onClickOutside from 'react-onclickoutside';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import moment from 'moment';
import Tappable from 'react-tappable';
import axios from 'axios';
import isTouchDevice from 'is-touch-device';
import Scrollbars from 'react-custom-scrollbars';
import { mergePlayers } from '../../../../actions/entries';
import { changeLoading, ROOT_URL } from '../../../../actions/index';

import './merge-screen.scss';
import PlayerImage from '../../../player-image';
import AddNew from '../../../match/add-player/add-new';
import PlayerCard from '../../../shadow-team/components/player-card';

import confirm from '../../../confirm';
import { fetchFavoritesList, fetchFavoritesInfo } from '../../../../actions/favorites';
import { getTeamImage } from '../../../../helpers';

@withTranslation()
@connect((state) => ({
  favorites: state.favorites,
  subscription: state.subscription,
  user: state.auth.user,
}), {
  mergePlayers, changeLoading, fetchFavoritesList, fetchFavoritesInfo,
})
@onClickOutside
export default class MergeScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: '',
      list: [],
      hovered: null,
      player_pos: 0,
      scrollTop: 0,
      addPlayerMode: false,
    };

    this.fetchPlayers = this.fetchPlayers.bind(this);
    this.mergePlayer = this.mergePlayer.bind(this);
    this.changeFilter = this.changeFilter.bind(this);
    this.renderPlayer = this.renderPlayer.bind(this);
    this.setAddPlayerMode = this.setAddPlayerMode.bind(this);
    this.fetchPlayers = _.debounce(this.fetchPlayers, 350);
  }

  componentDidMount() {
    this.fetchPlayers();
  }

  handleClickOutside() {
    this.props.onClickOutside();
  }

  mergePlayer(destinationPlayer) {
    const { t, player } = this.props;

    const text = (dismiss, onCheck, isChecked) => (
      <div className="privacy-confirm">
        <h2 className="h2">{t('MERGE_CONFIRM_TITLE', { context: window.genderContext })}</h2>
        <p dangerouslySetInnerHTML={{ __html: t('MERGE_CONFIRM_TEXT', { context: window.genderContext }) }} />
      </div>
    );

    confirm(text, {
      context: this,
      cancelText: t('CANCEL'),
      confirmText: t('CONTINUE'),
    }).then(() => {
      this.props.changeLoading({ component: 'app', loading: true });
      this.props.mergePlayers(player, destinationPlayer.player_id).then(() => {
        Promise.all([this.props.fetchFavoritesList(), this.props.fetchFavoritesInfo()]).then(() => {
          this.props.changeLoading({ component: 'app', loading: false });
          browserHistory.replace(`/players/my/${destinationPlayer.player_id}`);
          this.props.onClickOutside();
        });
      });
    });
  }

  fetchPlayers(needle) {
    const request = axios.get(`${ROOT_URL}/players/search/`, {
      params: { needle },
    });
    request.then((result) => {
      this.setState({ list: result.data.players });
    });
  }

  changeFilter(needle) {
    this.setState({ filter: needle });
    this.fetchPlayers(needle);
  }

  renderPlayer(player, i) {
    let playerName = '';
    const birth_year = moment(player.birth_date, 'YYYY-MM-DD').year();
    const age = player.birth_date && birth_year !== 1900 ? `(${moment().year() - (+birth_year)})` : '';

    if (player.first_name && player.last_name) {
      playerName = `${player.last_name}, ${player.first_name} ${age}`;
    }

    if (!player.first_name && player.last_name) {
      playerName = `${player.last_name} ${age}`;
    }

    if (!player.last_name) {
      playerName = player.short_name + age;
    }

    const teamImage = getTeamImage(player.team_id);

    return (
      <li key={`${player.player_id}_${i}`}>
        <Tappable
          onClick={() => {
            // this.mergePlayer(player);
          }}
          onMouseEnter={() => {
            this.setState({ hovered: player, player_pos: i });
          }}
          onMouseLeave={() => {
            this.setState({ hovered: null, player_pos: 0 });
          }}
          onTouchStart={() => {
            this.setState({ hovered: player, player_pos: i });
          }}
          onTouchEnd={() => {
            this.setState({ hovered: null, player_pos: 0 });
          }}
          onTap={() => {
            this.mergePlayer(player);
          }}
        >
          <PlayerImage className="team-image" src={teamImage} />
          {playerName}
        </Tappable>
      </li>
    );
  }

  setAddPlayerMode(isOpen) {
    this.setState({
      addPlayerMode: isOpen,
    });
  }

  render() {
    const { t, subscription, player } = this.props;
    const { filter, list } = this.state;

    const filteredList = list.filter((p) => p.player_id !== player);

    // todo: change to max favorites helper
    return (
      <div className="player-merge-screen">
        <div className="header">
          <h4>{t('MERGE_WITH_DATABASE_PLAYER', { context: window.genderContext })}</h4>
          {subscription.favorites.max !== 'INFINITY' ? (
            <p
              className={`limit ${subscription.favorites.current === subscription.favorites.max ? 'error' : ''}`}
            >
              {t('WATCHLIST_LIMITS_ON_PLAN', {
                current: subscription.favorites.max - subscription.favorites.current > 0 ? subscription.favorites.max - subscription.favorites.current : 0,
                max: subscription.favorites.max,
              })}
            </p>
          )
            : null}
          {!this.state.addPlayerMode ? (
            <input
              type="text"
              name="search"
              placeholder={t('TYPE_TO_SEARCH_PLAYER', { context: window.genderContext })}
              value={filter}
              autoFocus={!isTouchDevice()}
              onChange={(e) => {
                this.changeFilter(e.target.value);
              }}
            />
          ) : null}

        </div>

        {
          this.state.addPlayerMode ? (
            <div className="add-new-player">
              <AddNew
                withClose
                onCancelClick={() => { this.setState({ addPlayerMode: false }); }}
                onCreate={(player) => {
                  const id = `new-${player.team_id}_${ID()}`;
                  const newPlayer = { ...player, id, player_id: id };
                  this.props.createNewPlayer(newPlayer);
                  this.addPlayer(newPlayer);
                }}
              />
            </div>
          ) : (
            <div className="players-list">
              {this.state.hovered
                ? (
                  <PlayerCard
                    playerData={this.state.hovered}
                    top={this.state.player_pos * 40 - this.state.scrollTop}
                    className="add left"
                  />
                ) : null}
              <Scrollbars
                style={{ height: window.innerHeight - 152 }}
                onScrollFrame={(vals) => {
                  this.setState({ scrollTop: vals.scrollTop });
                }}
              >
                <ul>
                  {list.length > 0 ? filteredList.map(this.renderPlayer)
                    : <div className="no-results">{t('NO_PLAYERS_FOR_SEARCH', { context: window.genderContext })}</div>}
                </ul>
              </Scrollbars>
            </div>
          )
        }
      </div>
    );
  }
}
