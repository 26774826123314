import { GET_GROUP_INVOICES } from '../actions/group';

const INITIAL_STATE = [];

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_GROUP_INVOICES:
      return action.payload.data;

    default:
      return state;
  }
}
