import Validator from 'validator';
import { checkVAT, countries } from 'jsvat';

export function validateBilling(values, props) {
  // firstName,
  // lastName,
  // clubEmail,
  // phone,
  // isCompany
  // billingName, (company name)
  // clubPosition,
  // country,
  // vatNumber
  // city
  // streetNumber
  // zipCode

  // Also used in ../payment_method.js, props = countries;
  const errors = {};

  if (!values.firstName) {
    errors.firstName = 'Please enter a first name';
  }

  if (!values.lastName) {
    errors.lastName = 'Please enter a last name';
  }

  if (!values.clubEmail || !Validator.isEmail(values.clubEmail)) {
    errors.clubEmail = 'Please enter a valid email address';
  }

  if (!props.countries.listByAlpha2[values.country]) {
    errors.country = 'Please enter country';
  }

  if (!values.city) {
    errors.city = 'Please enter city';
  }

  if (!values.zipCode) {
    errors.zipCode = 'Please enter zipCode';
  }

  if (!values.streetNumber) {
    errors.streetNumber = 'Please enter Street and Number';
  }

  if (values.isCompany) {
    if (!values.billingName || values.billingName.length > 30) {
      errors.billingName = 'Please enter company name';
    }
    if (!values.vatNumber || values.vatNumber.length < 5) {
      errors.vatNumber = 'Please enter vat number';
    }
  }

  return errors;
}
