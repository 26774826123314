import React, { useEffect, useState, useRef } from 'react';
import { Portal } from 'react-portal';
import Icon from '../../../Icon';

export default function ({
  id,
  value: currentValue,
  onClickHandler,
  left,
  top,
  onMouseEnter,
  onMouseLeave,
  isOpen,
  toggle,
  valuerRef,
}) {
  const valuesLabels = ['--', '-', '0', '+', '++'];
  const [value, setValue] = useState(currentValue);
  const controlsRef = useRef(null);
  const [timer, setTimer] = useState(null);

  const timeoutHandler = () => { toggle(false); };

  useEffect(() => {
    setValue(value);
    timer && clearTimeout(timer);
    const newTimer = setTimeout(timeoutHandler, 2000);
    setTimer(newTimer);

    return () => {
      timer && clearTimeout(timer);
    };
  }, []);

  const isSelected = (i) => !isNaN(value) && value == i - 2;

  return (
    <Portal>
      <div
        ref={valuerRef}
        style={{ left: left < 10 ? 10 : left, top }}
        className="indicator-control__values"
        ref={controlsRef}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {valuesLabels.map((v, i) => (
          <span
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onClickHandler(e, i - 2);
              setValue(i - 2);
              timer && clearTimeout(timer);
              const newTimer = setTimeout(timeoutHandler, 3000);
              setTimer(newTimer);
            }}
            key={v}
            className={`indicator-control__value indicator-control__value${i} ${isSelected(i) ? 'selected' : ''}`}
          >
            {isSelected(i) ? <Icon name="checkmark" /> : v}
          </span>
        ))}
      </div>
    </Portal>
  );
}
