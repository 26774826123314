import React, { Component } from 'react';
import _ from 'lodash';
import Icon from '../../../Icon';
import PlayersList from '../players-list';
import AddPlayers from '../add-players';

import './position.scss';

import onClickOutside from 'react-onclickoutside';

class Position extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isActive: false,
      mode: null,
      menu_player_id: null,
      isFullList: false,
    };

    this.setPlayerEnchanced = this.setPlayerEnchanced.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleList = this.toggleList.bind(this);
  }

  handleClickOutside(evt) {
    if (this.state.isActive) {
      this.setState({
        isActive: false, mode: null, menu_player_id: false, isFullList: false,
      });
      const { position, setActive } = this.props;
      setActive(position.id, false);
    }
  }

  setPlayerEnchanced(position_id, player_id) {
    this.state = {
      isActive: false,
      mode: null,
      isFullList: false,
    };
    this.props.setPlayer(position_id, player_id);
  }

  toggleMenu(position_id, player_id) {
    const { setActive } = this.props;

    if (player_id !== this.state.menu_player_id) {
      this.setState({
        isActive: true,
        mode: 'menu',
        menu_player_id: player_id,
      });
      setActive(position_id, true);
    } else {
      this.setState({
        isActive: false,
        mode: null,
        menu_player_id: null,
        isFullList: false,
      });
      setActive(position_id, false);
    }
  }

  toggleList(isFullList, callback) {
    const { setActive } = this.props;

    if (isFullList) {
      setActive(this.props.position.id, true);
      this.setState({
        isActive: true,
        isFullList: true,
      });
    } else {
      setActive(this.props.position.id, false);
      this.setState({
        isActive: false,
        isFullList: false,
      });
    }

    setTimeout(() => { callback && callback(); }, 0);
  }

  render() {
    const {
      players,
      playersList,
      position,
      coords,
      isActive,
      setActive,
      movePlayer,
      removePlayer,
      user_id,
      onlyMyPlayers,
      team_id,
      playersById,
    } = this.props;
    const { mode } = this.state;

    const left = ((coords.x) * 100).toFixed(1);
    let top = (coords.y * 100).toFixed(1);

    if (top === '17.5') top = 21.5;

    return (
      <div
        className={`shadow-team__position ${isActive ? 'active' : ''}`}
        style={{ left: `${left}%`, top: `${top}%` }}
      >
        {
          players.length < 11 ? (
            <div
              className={`position-mark position-${position.desc_short}`}
              onClick={
                () => {
                  if (!isActive) {
                    setActive(position.id, true);
                    this.setState({ isActive: true, mode: 'add' });
                  } else {
                    setActive(position.id, false);
                    this.setState({ isActive: false, mode: null });
                  }
                }
              }
            >
              <Icon name="plus" className="create-icon" />
            </div>
          ) : (
            <div className="position-mark__empty" />
          )
        }

        <div className="shadow-team__players">
          {players && players.length ? (
            <PlayersList
              toggleMenu={this.toggleMenu}
              menu_id={this.state.menu_player_id}
              position={position}
              toggleList={this.toggleList}
              isFullList={this.state.isFullList}
              coords={coords}
              players={players}
              playersList={playersById}
              movePlayer={movePlayer}
              removePlayer={removePlayer}
              team_id={team_id}
            />
          ) : null }
          {isActive && mode == 'add' ? (
            <AddPlayers
              playersList={playersList}
              setPlayer={this.setPlayerEnchanced}
              position={position}
              user_id={user_id}
              onlyMyPlayers={onlyMyPlayers}
              choosenPlayers={players}
              coords={coords}
              team_id={team_id}
            />
          ) : null }
        </div>
      </div>
    );
  }
}

export default onClickOutside(Position);
