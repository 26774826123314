import React from 'react';
import Select from 'react-select';
import { theme, style } from '../select_props';

import DropdownIndicator from './components/dropdown-indicator';

export default function SelectBox({
  input, t, lng, ...rest
}) {
  return (
    <Select
      {...rest}
      name={input.name}
      styles={style}
      theme={theme}
      hideSelectedOptions
      className={rest.meta.touched && rest.meta.error ? 'no-valid' : ''}
      value={input.value}
      components={{ DropdownIndicator }}
      onChange={(value) => {
        rest.changeCallback && rest.changeCallback(value);
        input.onChange(value);
      }}
    />
  );
}
