import React, { Component } from 'react';
import './plans_selector.scss';
import { browserHistory } from 'react-router';
import { withTranslation } from 'react-i18next';
import Confirm from '../../../../confirm';
import { NotificationManager } from '../../../../notifications';
import Icon from '../../../../Icon';
import { compareCurrentSubscriptionWithPlan, validateBilling } from '../../../../../helpers';

@withTranslation()
export default class PlansSelector extends Component {
  constructor(props) {
    super(props);

    this.changePlan = this.changePlan.bind(this);
    this.onCloseClick = this.onCloseClick.bind(this);
  }

  renderPlan(plan) {
    const {
      currentPlan, plans, t, i18n,
    } = this.props;
    const lng = i18n.language;

    let current_id = _.get(currentPlan, 'plan.id', null);
    if (!plans.find((p) => p.id == current_id) && currentPlan !== null) {
      current_id = 'custom_plan';
    }

    const planTitle = _.get(plan, `title_${lng}`, plan.title);
    const planSubtitle = _.get(plan, `subtitle_${lng}`, plan.subtitle);
    const planDescription = _.get(plan, `description_${lng}`, plan.description);

    return (
      <div className="plan" key={plan.id}>
        <div className="plan-head">
          <h4>{planTitle}</h4>
          <h5>{planSubtitle}</h5>
        </div>
        <div className="body" dangerouslySetInnerHTML={{ __html: planDescription }} />
        <p className="price">
          <span>{ this.renderPlanPrice(plan) }</span>
          {' '}
          { plan.amount ? t('AT_MONTH') : null }
        </p>
        <div className="cta">
          { current_id === plan.id ? <span className="current-plan">{t('YOUR_CURRENT_PLAN')}</span> : this.renderPlanCTA(plan) }
        </div>
      </div>
    );
  }

  onCloseClick() {
    this.props.onCloseClick();
  }

  renderPlanPrice(plan) {
    const { interval } = this.props;

    if (!plan.amount) return null;

    const currencySign = plan.currency === 'usd' ? '$' : String.fromCharCode(8364);

    if (interval === 'year') {
      return (plan.amount / 12).toFixed(2) + currencySign;
    }

    return plan.amount + currencySign;
  }

  renderPlanCTA(plan) {
    const { t } = this.props;
    if (plan.amount === null && plan.interval === null) {
      return <span className="current-plan" dangerouslySetInnerHTML={{ __html: t('CONTACT_US_FOR_QUOTE') }} />;
    }
    return <button className="btn btn-cta" onClick={this.changePlan.bind(this, plan)}>{t('SELECT_PLAN')}</button>;
  }

  renderComparison(comparison) {
    const { t } = this.props;
    return (
      <div className="plan-comparison">
        <p>{t('PLAN_COMPARE_REMINDER')}</p>
        <ul>
          {comparison.map((c) => {
            return (
              <li key={c.type}>
                <span className="type">
                  {t(c.type)}
                  :
                </span>
                <span className="current">{ c.current === 'INFINITY' ? '∞' : t(c.current) }</span>
                <span className="arrow">→</span>
                <span className="next">{t(c.next)}</span>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  changePlan(plan) {
    const {
      t, group, source, countries, subscription,
    } = this.props;

    const comparison = compareCurrentSubscriptionWithPlan(subscription, plan);

    const text = (dismiss) => {
      return (
        <div>
          <h2 className="billing-confirm-settings-h2">{t('CHANGE_PLAN_POPUP_TITLE')}</h2>
          <p>
            { t('CHANGE_PLAN_POPUP1')}
            {' '}
            <a href="/help/terms" onClick={(e) => { e.preventDefault(); dismiss(); browserHistory.push('/help/terms'); }}>{t('CHANGE_PLAN_POPUP2')}</a>
            <br />
            {t('CHANGE_PLAN_POPUP3')}
            {' '}
            <a href="/help/terms" onClick={(e) => { e.preventDefault(); dismiss(); browserHistory.push('/help/terms'); }}>{t('CHANGE_PLAN_POPUP4')}</a>
            .
          </p>
          <p>{t('COUPON_REMINDER')}</p>
          {comparison.length ? this.renderComparison(comparison) : null}
        </div>
      );
    };

    Confirm(text, { context: this.context, confirmText: t('AGREE'), cancelText: t('CANCEL') })
      .then(
        () => {
          NotificationManager.create({
            message: 'SAVING',
            id: 'id',
            timeOut: 3000,
          });
          this.props.changeLoading({ component: 'billing', loading: true });

          if (plan.id !== 'free') {
            if (Object.keys(validateBilling(group, { countries })).length) {
              browserHistory.replace(`/settings/billing/address?plan=${plan.id}&amount=${plan.amount}&currency=${plan.currency}`);
              this.props.changeLoading({ component: 'billing', loading: false });
              return;
            }
            if (!source) {
              browserHistory.replace(`/settings/billing/add-card?plan=${plan.id}&amount=${plan.amount}&currency=${plan.currency}`);
              this.props.changeLoading({ component: 'billing', loading: false });
              return;
            }
          }

          this.props.changeGroupPlan(plan.id)
            .then((res) => {
              this.props.changeLoading({ component: 'billing', loading: false });

              if (res?.payload?.data?.message === 'unrecognized_location') {
                browserHistory.replace(`/settings/billing/address?plan=${plan.id}&amount=${plan.amount}&currency=${plan.currency}&reason=address`);
                return;
              }

              if (res.payload.data) {
                NotificationManager.create({
                  message: 'PLAN_SAVED_MESSAGE',
                  id: 'id',
                  timeOut: 3000,
                });
              } else {
                NotificationManager.create({
                  message: 'PLAN_NOT_SAVED_MESSAGE',
                  id: 'id',
                  timeOut: 3000,
                  type: 'error',
                });
              }

              this.onCloseClick();
              if (this.props.onChangePlan) { this.props.onChangePlan(plan); }
            });
        },
        () => {},
      );
  }

  render() {
    const {
      plans, interval, currentPlan, t,
    } = this.props;

    const preparedPlans = plans
      .filter((plan) => plan.interval === interval && plan.id !== 'free')
      .sort((a, b) => a.amount - b.amount);

    preparedPlans.push({
      currency: 'eur',
      amount: null,
      description: t('CUSTOM_PLAN_DESCRIPTION'),
      subtitle: t('CUSTOM_PLAN_SUBTITLE'),
      id: 'custom_plan',
      interval: null,
      public: 'true',
      scouts: '0',
      title: t('CUSTOM_PLAN'),
    });

    return (
      <div>
        {currentPlan ? <span onClick={this.onCloseClick} className="plan-selector-close"><Icon name="close" /></span> : null}
        <div className="plan-selector">
          { preparedPlans.map((plan) => { return this.renderPlan(plan); }) }
        </div>

        {
          _.get(currentPlan, 'id', null) !== 'free' ? (
            <p className="plan-selector__downgrade">
              <span onClick={() => { this.changePlan(plans[0]); }}>{t('DOWNGRADE_TO_FREE')}</span>
            </p>
          ) : null
        }
      </div>
    );
  }
}
