import axios from 'axios';
import localforage from 'localforage';
import { MATCHES_COLLECTION } from './constants';


import { ROOT_URL } from '../actions';

export default function (props) {
  const userId = localStorage.getItem('userId');
  const gender = localStorage.getItem('selectedGender');
  const collection = `evaluations-${MATCHES_COLLECTION}-${gender}-${userId}`;
  const collectionDelete = `evaluations-delete-${MATCHES_COLLECTION}-${gender}-${userId}`;

  switch (window.online) {
    case true:
      const updateOfflinePromise = localforage
        .getItem(collectionDelete)
        .then((toDelete) => {
          toDelete = JSON.parse(toDelete);
          if (toDelete && toDelete.length) {
            axios.post(`${ROOT_URL}/matches/indicators/delete`, toDelete)
              .then(() => {
                return localforage.setItem(collection, JSON.stringify([]));
              });
          }
        });

      if (props) {
        return axios.post(`${ROOT_URL}/matches/indicators/delete`, [props]);
      }
      return updateOfflinePromise;

    case false:
      if (!props) {
        return;
      }

      return Promise.all([
        localforage.getItem(collection),
        localforage.getItem(collectionDelete),
      ]).then(([evaluations, toDelete]) => {
        evaluations = JSON.parse(evaluations);
        toDelete = JSON.parse(toDelete);

        const index = evaluations.findIndex(ev => ev.match_id === props.match_id && ev.side === props.side && ev.time === props.time && ev.player_id === props.player_id && ev.key === props.key);

        if (index !== -1) {
          const new_evaluations = [...evaluations.slice(0, index), ...evaluations.slice(index + 1)];
          return localforage.setItem(collection, JSON.stringify(new_evaluations));
        }
        toDelete.push(props);
        return localforage.setItem(collectionDelete, JSON.stringify(toDelete));
      });
  }
}
