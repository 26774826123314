import React from 'react';
import { components } from 'react-select';
import CustomerView from '../customer-view';

export default function (props) {
  const { innerProps, innerRef, data } = props;
  const optionData = { ...data, isOption: true };
  return (
    <components.Option {...props}>
      <CustomerView {...optionData} />
    </components.Option>
  );
}
