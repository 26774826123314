import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import './player-table-menu.scss';
import onClickOutside from 'react-onclickoutside';

@withTranslation()
@onClickOutside
class PlayerTableMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      timer: null,
    };

    this.toggleMenu = this.toggleMenu.bind(this);
    this.mouseLeave = this.mouseLeave.bind(this);
    this.mouseEnter = this.mouseEnter.bind(this);
  }

  handleClickOutside() {
    this.setState({ isOpen: false });
  }

  mouseLeave() {
    if (this.state.isOpen) {
      const timer = setTimeout(() => {
        this.setState({ isOpen: false });
      }, 700);
      this.setState({ timer });
    }
  }

  mouseEnter() {
    if (this.state.isOpen) {
      const { timer } = this.state;
      if (timer) {
        clearTimeout(timer);
        this.setState({ timer: null });
      }
    }
  }

  renderItem(item, index) {
    const { t } = this.props;
    return (
      <li onClick={() => this.onActionClick(item)} key={index}>
        { item.icon ? <span className="icon-container">{item.icon}</span> : null }
        {t(item.name)}
      </li>
    );
  }

  onActionClick(item) {
    const { timer } = this.state;
    if (timer) {
      clearTimeout(timer);
      this.setState({ timer: null });
    }
    this.setState({ isOpen: false });

    item.action();
  }

  renderMenu() {
    const { actionsList } = this.props;
    return (
      <div className="player-table-menu__list-wrapper">
        <ul className="player-table-menu__list">
          {
            actionsList.map((item, index) => this.renderItem(item, index))
          }
        </ul>
      </div>
    );
  }

  toggleMenu() {
    const { isOpen } = this.state;

    this.setState({ isOpen: !isOpen, timer: null });
  }

  render() {
    const { actionsList } = this.props;
    const { isOpen } = this.state;

    if (!actionsList.length) {
      return null;
    }

    return (
      <div
        className={`player-table-menu ${isOpen ? 'active' : ''}`}
        onMouseEnter={this.mouseEnter}
        onMouseLeave={this.mouseLeave}
      >
        <button
          className={`player-table-menu__button ${isOpen ? 'active' : ''}`}
          onClick={this.toggleMenu}
        >
          <i />
        </button>
        {
          isOpen ? (this.renderMenu()) : null
        }
      </div>
    );
  }
}

PlayerTableMenu.propTypes = {
  player: PropTypes.object,
  actionsList: PropTypes.array,
};

export default PlayerTableMenu;
