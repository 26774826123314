import React, { Component } from 'react';

import './limits.scss';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

@withTranslation()
@connect((state) => ({
  subscription: state.subscription,
}), { })
export default class Limits extends Component {
  renderLimits(name, current, max) {
    const width = max !== 0 ? (current * 100) / max : 100;

    return (
      <div className="plan-limits__limit">
        <h5>{name}</h5>
        <div className="plan-limits__body">
          <div className="plan-limits__progress">
            <span style={{
              width: `${width}%`,
            }}
            />
          </div>
          <div className="plan-limits__text">
            {current}
            {' '}
            /
            {max}
          </div>
        </div>
      </div>
    );
  }

  renderNotAllowed(name) {
    const { t } = this.props;
    return (
      <div className="plan-limits__limit">
        <h5>{name}</h5>
        <div className="plan-limits__body">
          <div className="plan-limits__text">
            {t('NOT_ALLOWED_ON_THIS_PLAN')}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { subscription, t } = this.props;

    if (!subscription || (
      subscription.scouts.max === 'INFINITY'
      && subscription.reports.max === 'INFINITY'
      && subscription.favorites.max === 'INFINITY'
      && subscription.watchlists.max === 'INFINITY'
      && subscription.shadowTeams.max === 'INFINITY'
    )) {
      return null;
    }

    return (
      <div className="plan-limits">
        <h2 className="settings-h2">{t('SUBSCRIPTION_LIMITS')}</h2>
        {subscription.scouts.max !== 'INFINITY' ? this.renderLimits(t('SCOUTS'), subscription.scouts.current, subscription.scouts.max) : null}
        {subscription.reports.max !== 'INFINITY' ? this.renderLimits(t('REPORTS'), subscription.reports.current, subscription.reports.max) : null}
        {subscription.favorites.max !== 'INFINITY' ? this.renderLimits(t('LIMIT_FAVORITES'), subscription.favorites.current, subscription.favorites.max) : null}
        {subscription.shadowTeams && subscription.shadowTeams.max !== 0 ? subscription.shadowTeams.max !== 'INFINITY' ? this.renderLimits(t('SHADOW_TEAMS'), subscription.shadowTeams.current, subscription.shadowTeams.max) : null
          : this.renderNotAllowed(t('SHADOW_TEAMS'))}
        {subscription.watchlists && subscription.watchlists.max !== 0 ? subscription.watchlists.max !== 'INFINITY' ? this.renderLimits(t('WATCHLISTS'), subscription.watchlists.current, subscription.watchlists.max) : null
          : this.renderNotAllowed(t('WATCHLISTS'))}
      </div>
    );
  }
}
