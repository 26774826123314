import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import SubNav from '../sub-nav';
import Foo from './foo';
import Customers from './customers';

import accountApi from '../../actions/account';
import { getPlans } from '../../actions/plans';
import { changeLoading } from '../../actions/index';

import './admin.scss';

@withTranslation()
@connect((state) => ({
  auth: state.auth,
}), { getPlans, changeLoading, getRoles: accountApi.actions.roles })
class Admin extends Component {
  static displayName = 'Admin';

  componentDidMount() {
    this.props.changeLoading({ component: 'app', loading: true });

    Promise.all([
      this.props.getPlans(),
      this.props.getRoles(),
    ]).then(() => {
      this.props.changeLoading({ component: 'app', loading: false });
    });
  }

  render() {
    const {
      t, params: { page }, children,
    } = this.props;

    const sections = [
      {
        name: t('CUSTOMERS'),
        link: 'customers',
        component: children,
        render: true,
      },
      /* {
        name: t('STATISTICS'),
        link: 'statistics',
        component: <Foo {...this.props} />,
        render: true,
      },
      {
        name: t('BILLING_WARNINGS'),
        link: 'billing-warnings',
        component: <Foo {...this.props} />,
        render: true,
      }, */
    ];

    return (
      <div className="admin">
        <SubNav title="Admin" name="admin" list={sections} current={page} containerClassName="admin">
          {/* <div className="admin__rebuild-stripe-cache">
            <span className="js-link">Update stripe cache</span>
          </div> */}
        </SubNav>
      </div>
    );
  }
}

Admin.propTypes = {
  t: PropTypes.func,
  params: PropTypes.object,
  auth: PropTypes.object,
};

export default Admin;
