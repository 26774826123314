import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Select from 'react-select';
import isTouchDevice from 'is-touch-device';
import { connect } from 'react-redux';
import _ from 'lodash';
import { browserHistory } from 'react-router';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import { filterTheme, styleFilter } from '../ui/select_props';
import { canAddWatchlists, canUseWatchlists, exceededWatchlistLimit } from '../../helpers/limits';
import { changeLoading } from '../../actions';
import { changeFavoritesFilter } from '../../actions/favorites';
import Icon from '../Icon';

import { ALPHABET, DNDOptions } from '../../constants';

import { updateWatchlistsLimits } from '../../actions/subscription';
import {
  fetchWatchlists, createWatchlist, deleteWatchlist, copyWatchlist,
} from '../../actions/watchlists';
import { updateWatchlistsSorts } from '../../actions/auth';
import DraggableWatchlistList from './draggable-wathlists-list';
import { insertAndShift } from '../../helpers';
import WatchlistEditForm from './watchlist-edit-form';

import DemoWatchlistMan from '../../../demo_watchlist.json';
import DemoWatchlistWomen from '../../../demo_watchlist_women.json';
import DemoMessage from '../ui/demo-message';
import UpgradeButton from '../ui/upgrade-button';

@connect((state) => ({
  favorites: state.favorites,
  watchlists: state.watchlists,
  subscription: state.subscription,
  user: state.auth.user,
  users: state.users,
}), {
  changeLoading,
  changeFavoritesFilter,
  fetchWatchlists,
  createWatchlist,
  deleteWatchlist,
  updateWatchlistsLimits,
  copyWatchlist,
  updateWatchlistsSorts,
})
@withTranslation()
class Watchlists extends Component {
  constructor(props) {
    super(props);

    this.createWatchlist = this.createWatchlist.bind(this);
    this.copyWatchlist = this.copyWatchlist.bind(this);
    this.deleteWatchlist = this.deleteWatchlist.bind(this);
    this.changeSort = this.changeSort.bind(this);
    this.showCreateForm = this.showCreateForm.bind(this);
    this.hideCreateForm = this.hideCreateForm.bind(this);

    this.state = {
      watchlists: [],
      mode: null,
      editWatchlist: null,
    };
  }

  componentDidMount() {
    const favoriteFilters = this.props.favorites.filter;
    let watchlistsFiltered = [...this.props.watchlists.list];
    const watchlistsSort = this.props.user.watchlistsSort || {};

    if (favoriteFilters.list_user_id) {
      watchlistsFiltered = watchlistsFiltered.filter((t) => +t.user_id === +favoriteFilters.list_user_id);
    }

    watchlistsFiltered = watchlistsFiltered.map((wl) => ({ ...wl, sort: !isNaN(+watchlistsSort[wl._id]) ? watchlistsSort[wl._id] : 9999 }));
    watchlistsFiltered = _.orderBy(watchlistsFiltered, ['sort', 'updatedAt'], ['asc', 'desc']);
    this.setState({
      ...this.state,
      watchlists: [...watchlistsFiltered],
    });
  }

  componentDidUpdate(prevProps) {
    const props = prevProps;
    const nextProps = this.props;

    if (
      props.watchlists !== nextProps.watchlists
      || props.user !== nextProps.user
      || props.favorites !== nextProps.favorites
    ) {
      const favoriteFilters = nextProps.favorites.filter;
      let watchlistsFiltered = [...nextProps.watchlists.list];
      const watchlistsSort = nextProps.user.watchlistsSort || {};

      if (favoriteFilters.list_user_id) {
        watchlistsFiltered = watchlistsFiltered.filter((t) => +t.user_id === +favoriteFilters.list_user_id);
      }

      watchlistsFiltered = watchlistsFiltered.map((wl) => ({ ...wl, sort: !isNaN(+watchlistsSort[wl._id]) ? watchlistsSort[wl._id] : 9999 }));
      watchlistsFiltered = _.orderBy(watchlistsFiltered, ['sort', 'updatedAt'], ['asc', 'desc']);
      this.setState({
        ...this.state,
        watchlists: [...watchlistsFiltered],
      });
    }
  }

  createWatchlist(name, description, color_id, icon_id) {
    const { subscription, user } = this.props;
    const limits = subscription.watchlists;

    if (!name) {
      for (let i = 0; i < ALPHABET.length; i++) {
        name = `Watchlist ${ALPHABET[i]}`;
        const index = this.props.watchlists.list.findIndex((t) => t.name === name);
        if (index === -1) {
          break;
        }
      }
    }

    this.props.createWatchlist(name, description, color_id, icon_id).then((result) => {
      const { _id } = result.payload.data;
      const watchlistsSort = user.watchlistsSort || {};
      this.props.updateWatchlistsSorts(user._id, { ...watchlistsSort, [_id]: 0 });
      this.props.updateWatchlistsLimits({ current: ++limits.current });
      browserHistory.push(`/watchlists/${_id}`);
    });
  }

  changeSort(dragIndex, hoverIndex) {
    const { user } = this.props;
    const watchlistsSort = user.watchlistsSort || {};

    const watchlistsFiltered = [...this.state.watchlists];

    insertAndShift(watchlistsFiltered, dragIndex, hoverIndex);

    watchlistsFiltered.map((wl, i) => {
      const id = wl._id;
      const index = i;
      watchlistsSort[id] = index;
    });

    this.props.updateWatchlistsSorts(user._id, watchlistsSort);
  }

  copyWatchlist(_id) {
    const { subscription } = this.props;
    const limits = subscription.watchlists;

    this.props.copyWatchlist(_id).then((result) => {
      const { _id } = result.payload.data;
      this.props.updateWatchlistsLimits({ current: ++limits.current });
      browserHistory.push(`/watchlists/${_id}`);
    });
  }

  deleteWatchlist(watchlist) {
    const { t, subscription, user } = this.props;
    const limits = subscription.watchlists;

    let confirmText = t('WATCHLISTS_DELETE_CONFIRM');

    if (user._id != watchlist.user_id) {
      confirmText = t('WATCHLISTS_DELETE_GROUP_CONFIRM');
    }

    if (confirm(confirmText)) {
      this.props.updateWatchlistsLimits({ current: --limits.current });
      this.props.deleteWatchlist(watchlist._id);
    }
  }

  showCreateForm() {
    this.setState({ mode: 'create' });
  }

  hideCreateForm() {
    this.setState({ mode: null });
  }

  renderCreateLink() {
    const { t } = this.props;

    return (
      <span className="create-team btn watchlists__create" onClick={this.showCreateForm}>
        <Icon name="plus" className="create-icon" />
        {t('ADD_A_NEW_WATCHLIST')}
      </span>
    );
  }

  renderWatchlistsTable(watchlists) {
    const { subscription } = this.props;
    const disableControls = !canUseWatchlists(subscription) || exceededWatchlistLimit(subscription);

    return (
      <DraggableWatchlistList
        watchlists={watchlists}
        copyWatchlist={this.copyWatchlist}
        deleteWatchlist={this.deleteWatchlist}
        changeSort={this.changeSort}
        disabledControls={disableControls}
      />
    );
  }

  renderDemoWatchlist() {
    const { t, subscription } = this.props;

    const DemoWatchlist = window.genderContext === 'female' ? DemoWatchlistWomen : DemoWatchlistMan;

    return (
      <div className="demo-watchlists-list">
        {
          this.renderWatchlistsTable([DemoWatchlist])
        }
        {
          canUseWatchlists(subscription) ? <DemoMessage text={t('DEMO_WATCHLIST_MESSAGE')} arrowPosition="top-left" /> : null
        }

      </div>
    );
  }

  render() {
    const {
      t, user, users, subscription,
    } = this.props;
    const favoriteFilters = this.props.favorites.filter;

    const { watchlists } = this.state;
    const fullWatchlists = this.props.watchlists.list;

    const scoutsOptions = _(users.data)
      .filter((u) => u._id !== user._id)
      .filter((u) => {
        return (fullWatchlists.findIndex((t) => t.user_id === u._id) !== -1) || (u._id == favoriteFilters.list_user_id);
      })
      .orderBy('lastName')
      .map((u) => {
        return { value: u._id, label: `${u.firstName} ${u.lastName}` };
      })
      .value();

    scoutsOptions.unshift({ value: null, label: t('ALL_SCOUTS') });
    scoutsOptions.unshift({ value: '-', label: '-', isDisabled: true });
    scoutsOptions.unshift({ value: user._id, label: t('MY_WATCHLISTS') });

    const scoutValue = scoutsOptions.find((o) => o.value === favoriteFilters.list_user_id);

    const showAdd = canAddWatchlists(subscription);
    const canUse = canUseWatchlists(subscription);
    const exceeded = exceededWatchlistLimit(subscription);

    const showFilter = user.role.tag !== 'user' && users.data.length > 1 && canUse;

    const upgradeMessage = user.role.tag !== 'user' ? (
      <UpgradeButton message={t('UPGRADE_TO_PRO_WATCHLISTS', { context: window.genderContext })} classes="watchlists" />
    ) : (<p className="upgrade-message-user">{t('USER_UPGRADE_TO_PRO_WATCHLISTS', { context: window.genderContext })}</p>);

    const emptyMessage = user.role.tag !== 'user' && favoriteFilters.list_user_id !== user._id ? <p className="empty-message">{t('NO_WATCHLISTS_SCOUT')}</p> : null;

    return (
      <div className="pad">
        <div className="watchlists__heading">
          <h3>{t('WATCHLISTS')}</h3>
        </div>
        <div className="watchlists__controls">
          {
            showFilter ? (
              <div className="watchlists__filters">
                <div className="filter-container">
                  <div className="filter select-scout">
                    <div>
                      <label>{t('WATCHLISTS_BY')}</label>
                      <Select
                        styles={styleFilter}
                        theme={filterTheme}
                        placeholder={t('ALL_SCOUTS')}
                        options={scoutsOptions}
                        clearable
                        isSearchable={!isTouchDevice()}
                        defaultValue={scoutValue}
                        value={scoutValue}
                        onChange={(val) => {
                          this.props.changeFavoritesFilter({ list_user_id: val.value });
                        }}
                        isMulti={false}
                        className="matches-list-filter__item"
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : null

          }
          { showAdd ? this.renderCreateLink() : null }
        </div>
        <DndProvider backend={TouchBackend} options={DNDOptions}>
          {
            watchlists.length ? this.renderWatchlistsTable(watchlists) : emptyMessage
          }

          {
            !watchlists.length ? this.renderDemoWatchlist() : null
          }
        </DndProvider>
        { !canUse || (canUse && !showAdd) || exceeded ? upgradeMessage : null }

        { this.state.mode ? <WatchlistEditForm mode="create" onCloseClick={this.hideCreateForm} onCreateClick={this.createWatchlist} /> : null }
      </div>
    );
  }
}

Watchlists.propTypes = {
  updateWatchlistsSorts: PropTypes.func,
  createWatchlist: PropTypes.func,
  fetchWatchlists: PropTypes.func,
  changeLoading: PropTypes.func,
  changeFavoritesFilter: PropTypes.func,
  updateWatchlistsLimits: PropTypes.func,
  t: PropTypes.func,
  favorites: PropTypes.object,
  watchlists: PropTypes.object,
  subscription: PropTypes.object,
  user: PropTypes.object,
  users: PropTypes.object,
  colors: PropTypes.array,
  icons: PropTypes.array,
};

export default Watchlists;
