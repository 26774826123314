import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import { updateWatchlist, resetCurrentWatchlist, fetchWatchlist } from '../../actions/watchlists';
import { changeLoading } from '../../actions';
import Icon from '../Icon';
import { getPlayerImage, insertAndShift } from '../../helpers';
import AddPlayerPane from '../favorites/components/add-player';
import EditForm from './watchlist-edit-form';
import WatchlistSuggestions from './watchlist-suggestions';

import DraggableList from './draggable-list';

import './watchlist.scss';
import WatchlistIcon from '../ui/watchlist-icon';
import { canUseWatchlists, exceededWatchlistLimit } from '../../helpers/limits';

@withTranslation()
@connect((state, own) => {
  return {
    watchlist_id: own.routeParams.watchlist_id,
    watchlist: state.watchlists.current,
    user: state.auth.user,
    colors: state.colors,
    icons: state.icons,
    subscription: state.subscription,
    teams: state.teams,
    positions: state.positions,
    favorites: state.favorites.list,
  };
}, {
  updateWatchlist, resetCurrentWatchlist, fetchWatchlist, changeLoading,
})
class Watchlist extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showAdd: false,
      mode: null,
      sort: 'sort',
      sortDirection: 'asc',
    };

    this.openAddPlayer = this.openAddPlayer.bind(this);
    this.closeAddPlayer = this.closeAddPlayer.bind(this);
    this.addPlayer = this.addPlayer.bind(this);
    this.removePlayer = this.removePlayer.bind(this);
    this.changeOrder = this.changeOrder.bind(this);
    this.openEditWindow = this.openEditWindow.bind(this);
    this.closeEditWindow = this.closeEditWindow.bind(this);
    this.updateWatchlist = this.updateWatchlist.bind(this);
    this.changeSort = this.changeSort.bind(this);
  }

  componentDidMount() {
    this.props.changeLoading({ component: 'app', loading: true });
    this.props.resetCurrentWatchlist();
    this.props.fetchWatchlist(this.props.watchlist_id).then((result) => {
      this.props.changeLoading({ component: 'app', loading: false });
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.watchlist_id !== this.props.watchlist_id) {
      this.props.resetCurrentWatchlist();
      this.props.changeLoading({ component: 'app', loading: true });
      this.props.fetchWatchlist(this.props.watchlist_id).then((result) => {
        this.props.changeLoading({ component: 'app', loading: false });
      });
    }
  }

  updateWatchlist(name, description, color_id, icon_id) {
    this.props.updateWatchlist({
      ...this.props.watchlist,
      name,
      description,
      color_id,
      icon_id,
    });
    this.setState({ mode: null });
  }

  openEditWindow() {
    this.setState({ mode: 'edit' });
  }

  closeEditWindow() {
    this.setState({ mode: null });
  }

  openAddPlayer() {
    this.setState({ showAdd: true });
  }

  closeAddPlayer() {
    this.setState({ showAdd: false });
  }

  addPlayer(player) {
    const { watchlist } = this.props;
    const players = [...watchlist.players];
    if (players.findIndex((p) => p.player_id == player.player_id) === -1) {
      players.push(player.player_id);
      this.props.updateWatchlist({
        ...this.props.watchlist,
        players,
        details: { ...watchlist.details, [player.player_id]: player },
      });
      this.closeAddPlayer();
    }
  }

  removePlayer(id) {
    const { t, watchlist, user } = this.props;

    let confirmText = t('WATCHLIST_CONFIRM_REMOVE', { context: window.genderContext });

    if (watchlist.user_id != user._id) {
      confirmText = t('WATCHLIST_CONFIRM_GROUP_REMOVE', { context: window.genderContext });
    }

    if (confirm(confirmText)) {
      const index = watchlist.players.findIndex((p) => p == id);
      if (index !== -1) {
        const newPlayers = [...watchlist.players.slice(0, index), ...watchlist.players.slice(index + 1)];
        this.props.updateWatchlist({
          ...this.props.watchlist,
          players: newPlayers,
        });
      }
    }
  }

  changeOrder(dragIndex, hoverIndex) {
    const { watchlist } = this.props;

    const newPlayers = [...watchlist.players];
    insertAndShift(newPlayers, dragIndex, hoverIndex);
    this.props.updateWatchlist({
      ...this.props.watchlist,
      players: newPlayers,
    });
  }

  changeSort(sort, sortDirection) {
    this.setState({ sort, sortDirection });
  }

  preparePlayerData(player, watchlistIsDemo, index) {
    const {
      i18n, favorites, teams, positions,
    } = this.props;
    const lng = i18n.language;
    const p = { ...player };
    p.raw = player;

    p.sort = index;
    p.birth_date_raw = p.birth_date && p.birth_date !== '1900-01-01' ? moment().diff(p.birth_date, 'years') : 0;
    p.join_date_raw = p.join_date && p.join_date !== '1900-01-01' ? +moment(p.join_date, 'YYYY-MM-DD').unix() : 0;
    p.contract_until_raw = p.contract_until && p.contract_until !== '1900-01-01' ? +moment(p.contract_until, 'YYYY-MM-DD').unix() : 0;

    p.team_name = _.get(teams, `[${p.team_id}].name_${lng}`, _.get(teams, `[${p.team_id}].name`, '-'));
    p.position_name = _.get(positions, `[${p.position_id}].desc_short_${lng}`, _.get(positions, `[${p.position_id}].desc_short`, '-'));
    p.birth_date = p.birth_date && p.birth_date !== '1900-01-01' ? moment().diff(p.birth_date, 'years') : '–';
    p.country = p.country || '-';
    p.join_date = p.join_date && moment(p.join_date, 'YYYY-MM-DD').isValid() && moment(p.join_date, 'YYYY-MM-DD') > moment('1970-01-01', 'YYYY-MM-DD') ? moment(p.join_date, 'YYYY-MM-DD').format('DD/MM/YYYY') : '–';
    p.contract_until = p.contract_until && moment(p.contract_until, 'YYYY-MM-DD').isValid() && moment(p.contract_until, 'YYYY-MM-DD') > moment('1970-01-01', 'YYYY-MM-DD') ? moment(p.contract_until, 'YYYY-MM-DD').format('DD/MM/YYYY') : '–';

    p.playerImage = getPlayerImage(p);

    p.fav = favorites.find((f) => f.player_id == p.player_id);
    if (!p.fav && watchlistIsDemo) {
      p.fav = {
        category: _.get(player, 'favorite.category', 0),
        final_rating: _.get(player, 'favorite.final_rating', null),
      };
    }

    p.final_rating_formatted = 'N/D';
    if (p.fav) {
      p.final_rating = p.fav.final_rating;
      p.final_rating_formatted = p.final_rating === null || p.final_rating === undefined ? 'N/D' : p.final_rating.toFixed(2);
      p.category = p.fav.category || 0;
    }

    return p;
  }

  renderPlayers() {
    const { watchlist, subscription } = this.props;
    const { sort, sortDirection } = this.state;

    const watchlistIsDemo = watchlist._id === 'demo';
    const isDisabledControls = !canUseWatchlists(subscription) || exceededWatchlistLimit(subscription);
    let preparedSortedPlayers = watchlist.players.map((p, i) => this.preparePlayerData(watchlist.details[p], watchlistIsDemo, i));
    preparedSortedPlayers = _.orderBy(preparedSortedPlayers, [sort], [sortDirection]);

    return (
      <DraggableList
        changeOrder={this.changeOrder}
        removePlayer={this.removePlayer}
        sort={sort}
        sortDirection={sortDirection}
        changeSort={this.changeSort}
        watchlist={watchlist}
        players={preparedSortedPlayers}
        disabledControls={isDisabledControls}
        disableDrag={sort !== 'sort'}
      />
    );
  }

  render() {
    const {
      watchlist, t, subscription,
    } = this.props;
    if (!watchlist) {
      return null;
    }

    if (this.props.children) {
      return this.props.children;
    }

    const hideControls = !canUseWatchlists(subscription) || exceededWatchlistLimit(subscription);

    return (
      <div className="watchlist">
        <div className="watchlist__heading">
          <WatchlistIcon
            icon_id={watchlist.icon_id}
            color_id={watchlist.color_id}
          />
          <div className="watchlist__heading-top">
            <h2>{watchlist.name}</h2>
            {watchlist._id !== 'demo' && !hideControls ? (
              <span className="edit" onClick={() => { this.openEditWindow(); }}>
                <Icon name="pencil" className="edit-icon" />
                {t('EDIT')}
              </span>
            ) : null}
            {!hideControls ? (
              <button
                className="btn add-new-player"
                onClick={() => {
                  this.openAddPlayer();
                }}
              >
                <Icon name="plus" className="create-icon" />
                {' '}
                {t('PLAYER', { context: window.genderContext })}
              </button>
            ) : <span />}
          </div>
          { watchlist.description ? <p className="watchlist__description">{watchlist.description}</p> : null }
        </div>
        { !watchlist.players || !watchlist.players.length ? <div className="empty-message">{t('EMPTY_WATCHLIST_2')}</div> : this.renderPlayers() }

        { this.state.showAdd ? (
          <div>
            <div className="add-player-fade" />
            <AddPlayerPane onAddPlayer={this.addPlayer} onClickOutside={() => { this.closeAddPlayer(); }} />
          </div>
        ) : null }

        {this.state.mode ? <EditForm mode="update" name={watchlist.name} color_id={watchlist.color_id} icon_id={watchlist.icon_id} description={watchlist.description} id={watchlist._id} onCloseClick={this.closeEditWindow} onCreateClick={this.updateWatchlist} /> : null}

        { !watchlist.players || !watchlist.players.length ? <WatchlistSuggestions watchlist={watchlist} /> : null }

      </div>
    );
  }
}

Watchlist.propTypes = {
  t: PropTypes.func,
  watchlist: PropTypes.object,
  user: PropTypes.object,
  updateWatchlist: PropTypes.func,
  resetCurrentWatchlist: PropTypes.func,
  fetchWatchlist: PropTypes.func,
  changeLoading: PropTypes.func,
  colors: PropTypes.array,
  icons: PropTypes.array,
  subscription: PropTypes.object,
};

export default Watchlist;
