import axios from 'axios';
import localforage from 'localforage';
import moment from 'moment';
import _ from 'lodash';
import { NotificationManager } from '../components/notifications';

import storageEvaluations from './storageEvaluations';
import storageEvaluationsDelete from './storageEvaluationsDelete';

import { ROOT_URL } from '../actions';
import { MATCHES_COLLECTION } from './constants';

export default function () {
  const userId = localStorage.getItem('userId');

  window.addEventListener('online', () => {
    NotificationManager.create({
      message: 'SYNCHRONIZATION',
      id: 'id',
      timeOut: 3000,
    });

    setTimeout(() => {
      Promise.all([
        storageEvaluations(null),
        storageEvaluationsDelete(null),
      ]).then(
        () => {
          Promise
            .all([
              axios.get(`${ROOT_URL}/matches`),
              localforage.getItem(`${MATCHES_COLLECTION}-${userId}`),
            ])
            .then(([resServer, matchesClient]) => {
              matchesClient = JSON.parse(matchesClient);
              const matchesServer = resServer.data.matches;

              if (matchesClient && matchesClient.length) {
                const matchesClientClone = _.keyBy(matchesClient, (match) => match._id);
                const matchesServerClone = _.keyBy(matchesServer, (match) => match._id);

                _.forEach(matchesServerClone, (serverMatch, key) => {
                  if (matchesClientClone[key]) {
                    if (moment(serverMatch.ts).unix() < moment(matchesClientClone[key].ts).unix()) {
                      delete matchesClientClone[key].ts;
                      axios.post(`${ROOT_URL}/match/`, {
                        match: matchesClientClone[key],
                      });
                      console.info('autoupdate matches from client');
                    } else {
                      matchesClientClone[key] = serverMatch;
                      console.info('autoupdate matches from server');
                    }
                  } else {
                    matchesClientClone[key] = serverMatch;
                    console.info('auto add match from server');
                  }
                });

                localforage.setItem(`${MATCHES_COLLECTION}-${userId}`, JSON.stringify(_.toArray(matchesClientClone)));

                NotificationManager.create({
                  message: 'SYNCHRONIZATION_DONE',
                  id: 'id',
                  timeOut: 3000,
                });

                return Promise.resolve({
                  matches: _.toArray(matchesClientClone),
                });
              }
              localforage.setItem(`${MATCHES_COLLECTION}-${userId}`, JSON.stringify(matchesServer));
              return Promise.resolve({
                matches: matchesServer,
              });
            })
            .catch((err) => {
              NotificationManager.create({
                message: 'SYNCHRONIZATION_ERROR',
                id: 'id',
                timeOut: 3000,
                type: 'error',
              });
              console.error(err);
            });
        },
      );
    }, 300);
    console.log('AUTO UPDATE START', window.online);
  });
}
