import axios from 'axios';
import _ from 'lodash';
import { ROOT_URL } from '.';

import eventTracker from '../helpers/event-tracker';

export const FETCH_NOTES = 'FETCH_NOTES';
export const UPDATE_NOTE = 'UPDATE_NOTE';
export const DELETE_NOTE = 'DELETE_NOTE';
export const CREATE_NOTE = 'CREATE_NOTE';
export const UPDATE_NOTE_FILTER = 'UPDATE_NOTE_FILTER';
export const UPDATE_NOTE_DICTIONARIES = 'UPDATE_NOTE_DICTIONARIES';
export const SELECT_NOTE = 'SELECT_NOTE';
export const FETCH_NOTE_TAGS = 'FETCH_NOTE_TAGS';
export const CREATE_NOTE_TAG = 'CREATE_NOTE_TAG';
export const SET_NOTE_MODE = 'SET_NOTE_MODE';
export const CLEAR_NOTES = 'CLEAR_NOTES';

export function clearNotes() {
  return {
    type: CLEAR_NOTES,
  };
}

export function fetchNoteTags(playerId, needle) {
  const request = axios.get(`${ROOT_URL}/note-tags/`);

  if (playerId === 'demo') {
    return {
      type: FETCH_NOTE_TAGS,
      playerId,
    };
  }

  return {
    type: FETCH_NOTE_TAGS,
    payload: request,
  };
}

export function createNoteTag(tag) {
  const request = axios.post(`${ROOT_URL}/note-tags/`, tag);

  eventTracker.trackEvent('Notes', 'Create note tag');
  return {
    type: CREATE_NOTE_TAG,
    payload: request,
  };
}

export function updateNotesDictionaries(lists) {
  return {
    type: UPDATE_NOTE_DICTIONARIES,
    payload: lists,
  };
}

export function fetchNotes(playerId) {
  const request = axios.get(`${ROOT_URL}/notes/${playerId}`);

  if (playerId === 'demo') {
    return {
      type: FETCH_NOTES,
      playerId,
    };
  }

  return {
    type: FETCH_NOTES,
    payload: request,
  };
}

export function updateNotesFilter(filter) {
  return {
    type: UPDATE_NOTE_FILTER,
    payload: filter,
  };
}

export function selectNote(note) {
  eventTracker.trackEvent('Notes', 'Select note');
  return {
    type: SELECT_NOTE,
    payload: note,
  };
}

export function createNote(user, player_id) {
  let request = Promise.resolve({
    data: {
      _id: Math.random(),
      updatedAt: new Date(),
      createdAt: new Date(),
      match_id: null,
      time: 0,
      player_id: 'demo',
      side: 'home',
      user_id: user._id,
      team_id: 156,
      value: '',
      event_type: [],
      tags: [],
    },
  });

  if (player_id !== 'demo') {
    request = axios.post(`${ROOT_URL}/notes`, { player_id });
  }

  eventTracker.trackEvent('Notes', 'Create note');
  return {
    type: CREATE_NOTE,
    payload: request,
  };
}

export function deleteNote(id) {
  const request = axios.delete(`${ROOT_URL}/notes/${id}`);

  eventTracker.trackEvent('Notes', 'Delete note');
  return {
    type: DELETE_NOTE,
    payload: id,
  };
}

const throttledRequest = _.debounce((id, note) => {
  const request = axios.post(`${ROOT_URL}/notes/${id}`, note);
}, 1000);

export function updateNote(note) {
  if (note.player_id !== 'demo') {
    throttledRequest(note._id, note);
  }
  return {
    type: UPDATE_NOTE,
    payload: note,
  };
}

export function setNoteMode(mode) {
  return {
    type: SET_NOTE_MODE,
    payload: mode,
  };
}
