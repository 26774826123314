import './Auth.scss';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import i18next from '../../i18n';

import Login from '../../components/Auth/Login';
import Reset from '../../components/Auth/Reset';

export default class Auth extends Component {
  static propTypes = {
    location: PropTypes.object,
    params: PropTypes.object,
  };

  componentDidMount() {
    const { location } = this.props;
    const { language } = location.query;

    if (language) {
      i18next.changeLanguage(language);
      moment.locale(language);
    }
  }

  renderAuthType() {
    const { location, params } = this.props;
    const auth_type = params.type;

    switch (auth_type) {
      case 'login':
        return <Login />;
      case 'reset':
        return <Reset to={location.query.to || null} mode={location.query.mode} email={location.query.email} id={location.query.id} />;
      default:
        return null;
    }
  }

  render() {
    return (
      <div className="auth">
        { this.renderAuthType() }
      </div>
    );
  }
}
