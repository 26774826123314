import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';

import CustomersHeader from './customers-header';
import CustomersList from './customers-list';
import FiltersConstructor from '../../ui/filters-constructor';
import Select from '../../ui/filter-select';

import {
  fetchCustomers, changeSort, changeFilters, deleteCustomer, deactivateCustomer, resetCustomer,
} from '../../../actions/customers';

import './customers.scss';

@withTranslation()
@connect((state) => {
  return {
    customers: state.customers,
  };
}, {
  fetchCustomers,
  changeSort,
  changeFilters,
  deleteCustomer,
  deactivateCustomer,
  resetCustomer,
})
class Customers extends Component {
  static displayName = 'Customers';

  constructor(props) {
    super(props);

    const { t } = this.props;

    // TODO: Move sort options to reducer
    this.state = {
      sortOptions: [
        {
          value: 'createdAt-asc',
          label: `${t('REGISTERED')}, ${t('asc')}`,
          field: 'createdAt',
          dir: 'asc',
        },
        {
          value: 'createdAt-desc',
          label: `${t('REGISTERED')}, ${t('desc')}`,
          field: 'createdAt',
          dir: 'desc',
        },
        {
          value: 'stripe_cache.trial_end-asc',
          label: `${t('TRIAL_END')}, ${t('asc')}`,
          field: 'stripe_cache.trial_end',
          dir: 'asc',
        },
        {
          value: 'stripe_cache.trial_end-desc',
          label: `${t('TRIAL_END')}, ${t('desc')}`,
          field: 'stripe_cache.trial_end',
          dir: 'desc',
        },
        {
          value: 'acceptPrivacy-asc',
          label: `${t('ACCEPT_PRIVACY')}, ${t('asc')}`,
          field: 'acceptPrivacyDate',
          dir: 'asc',
        },
        {
          value: 'acceptPrivacy-desc',
          label: `${t('ACCEPT_PRIVACY')}, ${t('desc')}`,
          field: 'acceptPrivacyDate',
          dir: 'desc',
        },
        {
          value: 'lastLoggedIn-asc',
          label: `${t('LAST_LOGGED_IN')}, ${t('asc')}`,
          field: 'lastLoggedIn',
          dir: 'desc',
        },
        {
          value: 'lastLoggedIn-desc',
          label: `${t('LAST_LOGGED_IN')}, ${t('desc')}`,
          field: 'lastLoggedIn',
          dir: 'desc',
        },
      ],
    };

    this.changeSort = this.changeSort.bind(this);
    this.changeFilter = this.changeFilter.bind(this);
    this.clearAllFilters = this.clearAllFilters.bind(this);
    this.applyFilters = this.applyFilters.bind(this);
    this.onEditClick = this.onEditClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onDeactivateClick = this.onDeactivateClick.bind(this);
  }

  componentDidMount() {
    this.props.resetCustomer();
  }

  changeSort(value) {
    const { customers } = this.props;
    this.props.changeSort(value.field, value.dir);
    this.props.fetchCustomers(0, customers.filterValues, value.field, value.dir);
  }

  changeFilter(value, filter) {
    const { filterValues } = this.props.customers;

    const newFilter = {
      ...filterValues,
      [filter.field]: value,
    };

    if (value.inputValue === null) {
      delete newFilter[filter.field];
    }

    this.props.changeFilters(newFilter);
  }

  applyFilters() {
    const { filterValues, sortProperty, sortDirection } = this.props.customers;

    this.props.fetchCustomers(0, filterValues, sortProperty, sortDirection);
  }

  clearAllFilters() {
    const { sortProperty, sortDirection } = this.props.customers;
    this.props.changeFilters({});
    this.props.fetchCustomers(0, {}, sortProperty, sortDirection);
  }

  onEditClick(id) {
    browserHistory.push(`/admin/customers/${id}?edit=true`);
  }

  onDeleteClick(id) {
    const { deleteCustomer, t } = this.props;
    if (confirm(t('DELETE_CUSTOMER'))) {
      deleteCustomer(id);
    }
  }

  onDeactivateClick(id, active) {
    const { deactivateCustomer } = this.props;
    deactivateCustomer(id, active);
  }

  render() {
    if (this.props.children) {
      return this.props.children;
    }

    const { fetchCustomers, customers, t } = this.props;
    const { sortOptions } = this.state;
    const sortValue = sortOptions.find((o) => o.value === `${customers.sortProperty}-${customers.sortDirection}`);

    return (
      <div className="customers">
        <CustomersHeader />
        <div className="customers__filters">
          <div className="customers__sort">
            <Select
              options={sortOptions}
              onChange={this.changeSort}
              placeholder="Change sort"
              value={sortValue}
            />
          </div>
          <div className="customers__filters-container">
            <FiltersConstructor
              title={t('CUSTOMERS_FILTER')}
              filters={customers.filtersList}
              onFilterChange={this.changeFilter}
              clearAllFilters={this.clearAllFilters}
              applyFilters={this.applyFilters}
              values={customers.filterValues}
            />
          </div>
        </div>
        <div
          className="customers__list"
        >
          <CustomersList
            customers={customers}
            fetch={fetchCustomers}
            edit={this.onEditClick}
            deactivate={this.onDeactivateClick}
            remove={this.onDeleteClick}
          />
        </div>
      </div>
    );
  }
}

Customers.propTypes = {
  children: PropTypes.any,
  fetchCustomers: PropTypes.func,
  deleteCustomer: PropTypes.func,
  deactivateCustomer: PropTypes.func,
  resetCustomer: PropTypes.func,
  changeSort: PropTypes.func,
  changeFilters: PropTypes.func,
  customers: PropTypes.object,
  t: PropTypes.func,
};

export default Customers;
