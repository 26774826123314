import React, { useState } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { withTranslation } from 'react-i18next';

import AsyncSelect from 'react-select/async';
import PropTypes from 'prop-types';
import SingleValue from './league-value';
import Option from './league-option';
import GroupHeading from './country-group-heading';
import DropdownIndicator from '../select/components/dropdown-indicator';
import Group from './country-group';
import {
  styleFilter, filterTheme, styleLeagueSelect,
} from '../select_props';
import { ROOT_URL } from '../../../actions';

import './league-select.scss';

function LeagueSelect({
  value, name, onChange, t, i18n, countries, emptyOption, baseOptions, ...rest
}) {
  const lng = i18n.language;
  const [inp, setInput] = useState('');
  const defaultLeagues = rest.leagues;
  const defaultOptions = defaultLeagues && defaultLeagues.length ? defaultLeagues : true;

  return (
    <AsyncSelect
      {...rest}
      name={name}
      noImage
      styles={styleLeagueSelect}
      inputValue={inp}
      onInputChange={(value, action) => {
        if (action?.action !== 'input-blur' && action?.action !== 'menu-close') {
          setInput(value);
        }
      }}
      cacheOptions
      clearable={false}
      maxHeight={200}
      defaultOptions={defaultOptions}
      placeholder={t('TYPE_TO_SEARCH_LEAGUE')}
      menuShouldScrollIntoView
      hideSelectedOptions
      formatGroupLabel={(data) => {
        // TODO: country by lang
        const countryCode = _.get(countries, `listById[${data.id}].alpha3code`, null);
        const img = `https://static.sp7f.com/v2/countries/${countryCode}.jpg`;
        return (
          <div className="country-view">
            <div
              className="country-view__img"
              style={{ backgroundImage: `url(${img})` }}
            />
            {data.label}
          </div>
        );
      }}
      components={{
        Option,
        SingleValue,
        GroupHeading,
        Group,
        DropdownIndicator,
      }}
      value={value}
      loadOptions={(inputValue) => {
        const baseOptionsTranslated = [...baseOptions.map((o) => ({ ...o, label: t(o.label), firstLevel: true }))];
        return axios.get(`${ROOT_URL}/leagues/search`, {
          params: {
            needle: inputValue,
          },
        }).then((result) => {
          const options = _.groupBy(result.data.map((l) => ({
            value: l.id,
            label: _.get(l, `name_${lng}`, _.get(l, 'name', t('UNKNOWN_LEAGUE'))),
            country_id: l.country_id,
            league_id_master: l.league_id_master,
          })), 'country_id');

          let grouped = [];

          for (const key in options) {
            const opts = options[key];
            grouped.push({
              label: _.get(countries, `listById[${key}].name_${lng}`, _.get(countries, `listById[${key}].name`, t('UNKNOWN'))),
              id: key,
              options: opts.sort((a, b) => { return a.label.localeCompare(b.label); }),
            });
          }

          grouped = _.orderBy(grouped, ['label'], ['asc']);
          if (emptyOption && !inputValue) {
            grouped.unshift({
              id: '-',
              label: t('NO_LEAGUE'),
              value: '',
              firstLevel: true,
            });
          }
          return [...baseOptionsTranslated, ...grouped];
        }).catch((err) => {
          console.error(err.message);
          return [...baseOptionsTranslated];
        });
      }}
      onChange={(value) => {
        rest.changeCallback && rest.changeCallback(value);
        onChange(value);
      }}
      theme={filterTheme}
    />
  );
}

LeagueSelect.propTypes = {
  emptyOption: PropTypes.bool,
  countries: PropTypes.object,
  i18n: PropTypes.object,
  t: PropTypes.func,
};

const connectedLeagueSelected = connect((state) => {
  return {
    countries: state.countries,
  };
}, {})(LeagueSelect);

export default withTranslation()(connectedLeagueSelected);
