import React, { Component } from 'react';
import PlayerImage from '../../../player-image';
import { withTranslation } from 'react-i18next';

import { Scrollbars } from 'react-custom-scrollbars';
import PlayerCard from '../player-card';

import './add-players.scss';
import { connect } from 'react-redux';
import Tappable from 'react-tappable';
import isTouchDevice from 'is-touch-device';
import { getTeamImage } from '../../../../helpers';

@withTranslation()
@connect((state, own) => {
  return {
    positions: state.positions,
    user: state.auth.user,
  };
}, {})
export default class AddPlayers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: '',
      hovered: null,
      scrollTop: 0,
      player_pos: 0,
    };

    this.renderPlayer = this.renderPlayer.bind(this);
  }

  renderPlayer(player, i) {
    const {
      setPlayer, position, positions, t, i18n,
    } = this.props;

    const lng = i18n.language;
    let data = player;

    if (data.type === 'position') {
      return <li key={`pos-${data.id}-${i}`} className="shadow-team__add-player__position">{ _.get(positions, `[${data.id}].desc_long_${lng}`, _.get(positions, `[${data.id}].desc_long`, t('UNKNOWN'))) }</li>;
    }

    if (data.information) {
      data = { ...data.information, ...data };
    }

    if (!data.first_name && !data.last_name) {
      return null;
    }

    const teamImage = getTeamImage(data.team_id);

    return (
      <li
        className={`shadow-team__add-player__player ${i === 0 ? 'active' : ''}`}
        key={`${player.player_id}_${i}`}
      >
        <Tappable
          onClick={() => { setPlayer(position.id, player.id); }}
          onMouseEnter={() => { this.setState({ hovered: player, player_pos: i }); }}
          onMouseLeave={() => { this.setState({ hovered: null, player_pos: 0 }); }}
          onTouchStart={() => { this.setState({ hovered: player, player_pos: i }); }}
          onTouchEnd={() => { this.setState({ hovered: null, player_pos: 0 }); }}
          onTap={() => { setPlayer(position.id, player.player_id); }}
        >
          <PlayerImage className="team-image" src={teamImage} />
          {`${data.last_name}, ${data.first_name}`}
        </Tappable>
      </li>
    );
  }

  render() {
    const {
      playersList, t, user_id, onlyMyPlayers, choosenPlayers, coords, setPlayer, position, user, team_id,
    } = this.props;

    let filteredList = playersList;
    filteredList = filteredList.filter((pl) => choosenPlayers.findIndex((cp) => cp === pl.player_id) === -1);

    if (onlyMyPlayers && user.role.tag !== 'user' && team_id !== 'demo') {
      filteredList = filteredList.filter((p) => p.user_id == user_id);
    }

    if (this.state.filter) {
      filteredList = filteredList.filter((data) => {
        const regexp = new RegExp(this.state.filter, 'ig');
        const fullName = data.first_name + data.last_name;
        return regexp.test(fullName);
      });
    }

    filteredList = _.orderBy(filteredList, ['position_id', 'last_name']);

    let previousPosition = _.get(filteredList[0], 'position_id', null);

    const tempList = [];
    for (let i = 0; i < filteredList.length; i++) {
      if (i === 0) {
        tempList.push({ type: 'position', id: filteredList[i].position_id });
      }

      if (filteredList[i].position_id !== previousPosition) {
        tempList.push({ type: 'position', id: filteredList[i].position_id });
        previousPosition = filteredList[i].position_id;
      }

      tempList.push(filteredList[i]);
    }

    let content = null;

    if (tempList.length === 0) {
      content = (
        <div className="empty-message">
          {t('NO_PLAYERS_FOR_SEARCH', { context: window.genderContext })}
        </div>
      );
    } else if (tempList.length <= 6) {
      content = (
        <ul>
          {
            tempList.map(this.renderPlayer)
          }
        </ul>
      );
    } else {
      content = (
        <Scrollbars
          style={{ height: 200 }}
          autoHide
          onScrollFrame={(vals) => {
            this.setState({ scrollTop: vals.scrollTop });
          }}
        >
          <ul>
            {
              tempList.map(this.renderPlayer)
            }
          </ul>
        </Scrollbars>
      );
    }

    const x = (1 - coords.x) * 100;

    return (
      <div className="shadow-team__add-player">
        { this.state.hovered ? <PlayerCard playerData={this.state.hovered} top={this.state.player_pos * 35 - this.state.scrollTop + 53} className={`add ${x <= 50 ? 'left' : 'right'}`} /> : null }
        <div className="shadow-team__add-player-heading">
          { t('SHADOW_ADD_PLAYER', { context: window.genderContext }) }
        </div>
        <div className="shadow-team__add-player-filter">
          <input
            type="text"
            autoFocus={!isTouchDevice()}
            onChange={(e) => this.setState({ filter: e.target.value })}
            value={this.state.filter}
            placeholder={t('TYPE_TO_SEARCH_PLAYER', { context: window.genderContext })}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                let data = filteredList[0];
                if (data.information) {
                  data = { ...data.information, ...data };
                }
                setPlayer(position.id, data.player_id);
              }
            }}
          />
        </div>
        { content }
      </div>
    );
  }
}
