import React from 'react';
import PropTypes from 'prop-types';

import './match-card.scss';

export default function MatchLoader({ noAnimation }) {
  return (
    <div className={`match-card match-card__loading ${noAnimation ? 'no-animation' : ''}`} />
  );
}

MatchLoader.propTypes = {
  noAnimation: PropTypes.bool,
};
