import axios from 'axios';
import {
  storageMatches, storageMatch, storageMatchUpdate,
  storagePositions, storageAddMatch, storageTeams, storageStadiums, storageLeagues, storageIndicators, storageFormations, storageEvaluations, storageEvaluationsDelete,
} from '../storageMiddleware';

import eventTracker, { EVENTS } from '../helpers/event-tracker';

export const SYNC_EVALUATIONS = 'SYNC_EVALUATIONS';
export const FETCH_MATCHES = 'FETCH_MATCHES';
export const FETCH_TEAMS = 'FETCH_TEAMS';
export const FETCH_ADD_MATCH = 'FETCH_ADD_MATCH';
export const UPDATE_MATCH = 'UPDATE_MATCH';
export const DELETE_MATCH = 'DELETE_MATCH';
export const FETCH_POSITIONS = 'FETCH_POSITIONS';
export const FETCH_STADIUMS = 'FETCH_STADIUMS';
export const ADD_STADIUM = 'ADD_STADIUM';
export const FETCH_LEAGUES = 'FETCH_LEAGUES';
export const FETCH_INDICATORS = 'FETCH_INDICATORS';
export const FETCH_MATCH = 'FETCH_MATCH';
export const FETCH_FORMATIONS = 'FETCH_FORMATIONS';
export const FETCH_COLORS = 'FETCH_COLORS';
export const FETCH_ICONS = 'FETCH_ICONS';
export const LOADING = 'LOADING';
export const ADD_PLAYER_GENERAL = 'ADD_PLAYER_GENERAL';

export const ADD_TEAM = 'ADD_TEAM';

export const ADD_LEAGUE = 'ADD_LEAGUE';

let ROOT_URL = 'https://dev-api.scoutpad.de';

switch (process.env.NODE_ENV) {
  case 'development':
    ROOT_URL = 'http://localhost:8080';
    break;
  case 'production':
    ROOT_URL = 'https://api.scoutpad.de';
    break;
  case 'staging':
    ROOT_URL = 'https://dev-api.scoutpad.de';
    break;
}

export { ROOT_URL };

export function fetchMatches(props) {
  return {
    type: FETCH_MATCHES,
    payload: storageMatches(props),
  };
}

export function fetchMatch(props) {
  return {
    type: FETCH_MATCH,
    payload: storageMatch(props),
  };
}

export function deleteMatch(props) {
  eventTracker.trackEvent('Match', 'Delete match');
  return {
    type: DELETE_MATCH,
    payload: props,
  };
}

export function fetchTeams(props) {
  return {
    type: FETCH_TEAMS,
    payload: storageTeams(props),
  };
}

export function createTeam(team) {
  const request = axios
    .post(`${ROOT_URL}/teams`, [team]);

  eventTracker.trackEvent('Match', 'Create custom team');

  return {
    type: ADD_TEAM,
    payload: request,
  };
}

export function createLeague(league) {
  const request = axios
    .post(`${ROOT_URL}/leagues`, [league]);

  eventTracker.trackEvent('Match', 'Create custom league');

  return {
    type: ADD_LEAGUE,
    payload: request,
  };
}

export function createPlayer(data) {
  const request = axios.post(`${ROOT_URL}/players`, [data]);

  return {
    type: ADD_PLAYER_GENERAL,
    payload: request,
  };
}

export function updateMatch(props) {
  eventTracker.trackEvent('Match', 'Update match');

  return {
    type: UPDATE_MATCH,
    payload: storageMatchUpdate(props),
  };
}

export function fetchAddMatch(props, subscription) {
  eventTracker.trackEvent('Match', 'Create match');

  return {
    type: FETCH_ADD_MATCH,
    payload: storageAddMatch(props, subscription),
  };
}

export function fetchPositions(props) {
  return {
    type: FETCH_POSITIONS,
    payload: storagePositions(props),
  };
}

export function fetchFormations(props) {
  return {
    type: FETCH_FORMATIONS,
    payload: storageFormations(props),
  };
}

export function fetchStadiums(props) {
  return {
    type: FETCH_STADIUMS,
    payload: storageStadiums(props),
  };
}

export function addStadium(stadium) {
  const request = axios
    .post(`${ROOT_URL}/stadiums`, { stadium })
    .then(
      (success) => {
        return success.data;
      },
      (error) => {
        return error.data;
      },
    );

  eventTracker.trackEvent('Match', 'Add custom stadium');

  return {
    type: ADD_STADIUM,
    payload: request,
  };
}

export function fetchLeagues(props) {
  return {
    type: FETCH_LEAGUES,
    payload: storageLeagues(props),
  };
}

export function fetchIndicators(props) {
  return {
    type: FETCH_INDICATORS,
    payload: storageIndicators(props),
  };
}

export function changeLoading(params) {
  return {
    type: LOADING,
    ...params,
  };
}

export function syncEvaluations() {
  return {
    type: SYNC_EVALUATIONS,
    payload: Promise.all([
      storageEvaluations(null),
      storageEvaluationsDelete(null),
    ]),
  };
}

export function fetchColors() {
  const request = axios.get(`${ROOT_URL}/colors/`);
  return {
    type: FETCH_COLORS,
    payload: request,
  };
}
export function fetchIcons() {
  const request = axios.get(`${ROOT_URL}/icons/`);
  return {
    type: FETCH_ICONS,
    payload: request,
  };
}
