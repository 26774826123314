import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import Icon from '../../../Icon';
import './player-note.scss';

import { changeOuterControl, increaseGoal, decreaseGoal } from '../../../../actions/match';
import { style, theme } from '../../../ui/select_props';
import Creatable from 'react-select/creatable';

import { createNoteTag } from '../../../../actions/notes';
import eventTracker from '../../../../helpers/event-tracker';
import { NotificationManager } from '../../../notifications';

@withTranslation()
@connect((state) => {
  return {
    match: state.match,
    notes: state.notes,
  };
}, {
  changeOuterControl, increaseGoal, decreaseGoal, createNoteTag,
})
class Note extends Component {
  static propTypes = {
    increaseGoal: PropTypes.func,
    decreaseGoal: PropTypes.func,
    changeOuterControl: PropTypes.func,
    handleSubmit: PropTypes.func,
    match: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.setNoteType = this.setNoteType.bind(this);

    const { timeline } = props.match;
    const { player, side } = props.match.controls;
    const { current_values } = props.match;

    const event_type = _.get(current_values, `[${side}][${player}].event_type`, []);
    const note = _.get(current_values, `[${side}][${player}].notes`, '');
    const tags = _.get(current_values, `[${side}][${player}].tags`, []);

    this.state = {
      tags,
      event_type,
      timeline,
      note,
    };
  }

  onSubmit(e) {
    e && e.preventDefault();

    let { event_type, note, tags } = this.state;
    event_type = Array.isArray(event_type) ? event_type : [];
    if (!note && !event_type.length) return;

    if (note) {
      note = note.indexOf('\n\n') ? note : `${note}\n\n`;
      eventTracker.trackEvent('Match', 'Add / edit player note');
    }

    const event = {
      value: note,
      event_type,
      timeline: this.state.timeline,
      tags,
    };

    this.props.changeOuterControl(event);
  }

  setNoteType(event_type, e) {
    const { match } = this.props;
    const { side } = match.controls;

    const op_side = side === 'home' ? 'away' : 'home';

    const current_events = Array.isArray(this.state.event_type) ? this.state.event_type : [];
    const eventIndex = current_events.indexOf(event_type);

    if (eventIndex !== -1) {
      eventTracker.trackEvent('Match', `Remove event ${event_type}`);
      const newEvents = [...current_events.slice(0, eventIndex), ...current_events.slice(eventIndex + 1)];
      this.setState({ event_type: newEvents });

      if (event_type === 'goal') {
        this.props.decreaseGoal(side, this.state.timeline);
      }

      if (event_type === 'own_goal') {
        this.props.decreaseGoal(op_side, this.state.timeline);
      }

      if (event_type === 'assist' && match.type === 'single') {
        this.props.decreaseGoal(side, this.state.timeline);
      }
    } else {
      eventTracker.trackEvent('Match', `Add event ${event_type}`);
      this.setState({ event_type: [...current_events, event_type] });
      if (event_type === 'goal') {
        this.props.increaseGoal(side, this.state.timeline);
      }

      if (event_type === 'own_goal') {
        this.props.increaseGoal(op_side, this.state.timeline);
      }

      if (event_type === 'assist' && match.type === 'single') {
        this.props.increaseGoal(side, this.state.timeline);
      }
    }
  }

  render() {
    const {
      handleSubmit, match, t,
    } = this.props;
    const userTags = this.props.notes.tags;
    const { data, controls } = match;
    const { information } = _.get(data, `[${controls.side}].players[${controls.player}]`, {});
    const { timeline, tags } = this.state;

    let playerValues = _.get(data, `[${controls.side}].values[${controls.player}]`, {});
    playerValues = _.cloneDeep(playerValues);
    delete playerValues[timeline];
    const yellow = _.chain(playerValues).filter((o) => Array.isArray(o.event_type) && o.event_type.indexOf('yellow') !== -1).value().length;

    const isYellowRed = yellow > 0;

    const tagsOptions = Array.isArray(userTags) ? userTags.map((t) => ({ value: t._id, label: t.tag })) : [];
    const tagsByKey = _.keyBy(userTags, '_id');

    return (
      <form className={`player-note ${match.type === 'single' ? 'player-note__single' : ''}`} onSubmit={this.onSubmit}>
        {match.type === 'single' ? (
          <h3>{t('NOTES')}</h3>
        ) : null}
        <div className="events">
          <label>
            {t('ADD_AN_EVENT')}
            :
          </label>
          {isYellowRed ? (
            <button className={`event-button ${this.state.event_type.indexOf('yellow_red') !== -1 ? 'active' : ''}`} onClick={(e) => this.setNoteType('yellow_red', e)}>
              <Icon name="yellow" />
              {t('yellow_red')}
            </button>
          ) : (
            <button className={`event-button ${this.state.event_type.indexOf('yellow') !== -1 ? 'active' : ''}`} onClick={(e) => this.setNoteType('yellow', e)}>
              <Icon name="yellow" />
              {t('yellow')}
            </button>
          )}

          <button className={`event-button ${this.state.event_type.indexOf('red') !== -1 ? 'active' : ''}`} onClick={(e) => this.setNoteType('red', e)}>
            <Icon name="red" />
            {t('red')}
          </button>
          <br />
          <button className={`event-button ${this.state.event_type.indexOf('goal') !== -1 ? 'active' : ''}`} onClick={(e) => this.setNoteType('goal', e)}>
            <Icon name="goals" className="goal" />
            {t('goal')}
          </button>
          <button className={`event-button ${this.state.event_type.indexOf('assist') !== -1 ? 'active' : ''}`} onClick={(e) => this.setNoteType('assist', e)}>
            <Icon name="assists" className="assists" />
            {t('assist')}
          </button>
          <button className={`event-button ${this.state.event_type.indexOf('own_goal') !== -1 ? 'active' : ''}`} onClick={(e) => this.setNoteType('own_goal', e)}>
            <Icon name="owngoal" className="goal" />
            {t('own_goal')}
          </button>
        </div>
        <textarea
          placeholder={t('NOTES_PLACEHOLDER', { player: information.last_name })}
          name="notes"
          id="player-note"
          value={this.state.note}
          onChange={(e) => { this.setState({ note: e.target.value }); }}
          onBlur={this.onSubmit}
        />
        <Creatable
          value={
            tags.map((t) => ({ label: tagsByKey[t].tag, value: tagsByKey[t]._id }))
          }
          className="note-tag-select"
          placeholder={t('ADD_TAG')}
          theme={theme}
          styles={style}
          promptTextCreator={(value) => t('CREATE_TAG', value)}
          onCreateOption={(value) => {
            if (!window.online) {
              NotificationManager.create({
                message: 'CANT_ADD_TAG_OFFLINE',
                id: 'id',
                timeOut: 3000,
                type: 'error',
              });
              return;
            }
            eventTracker.trackEvent('Match', 'Create note tag');
            eventTracker.trackEvent('Notes', 'Create note tag');
            this.props.createNoteTag({ tag: value }).then((action) => {
              const { data } = action.payload;
              const v = [...tags, data._id];
              this.setState({ tags: v });
              setTimeout(() => {
                this.onSubmit();
              }, 0);
            });
          }}
          options={tagsOptions}
          isMulti
          searchable
          onChange={(val) => {
            this.setState({ tags: [...val.map((v) => v.value)] });
            eventTracker.trackEvent('Match', 'Add note tag');
            eventTracker.trackEvent('Notes', 'Add note tag');
            setTimeout(() => {
              this.onSubmit();
            }, 0);
          }}
        />
        { this.state.event_type.length ? (
          <div className="events-tags">
            {
              this.state.event_type.map((et) => {
                return (
                  <span className="tag" key={et}>
                    #
                    {t(et)}
                  </span>
                );
              })
            }
          </div>
        ) : null}
      </form>
    );
  }
}

export default Note;
