import React from 'react';
import './data_item_icon.scss';
import Icon from '../../../Icon';

export default function (props) {
  const {
    label, value, postfix, prefix, icon,
  } = props;

  let printValue = value;

  if (value === null || value === undefined) {
    printValue = 'N/D';
  } else {
    printValue = value;

    if (postfix) {
      printValue += postfix;
    }

    if (prefix) {
      printValue = prefix + printValue;
    }
  }

  return (
    <div className="data-item-icon">
      { icon ? (
        <div className="data-item-icon__icon">
          <Icon name={icon} />
        </div>
      ) : null }
      <div className="data-item-icon__column">
        <div className="data-item-icon__value">
          {printValue}
        </div>
        <div className="data-item-icon__label">{label}</div>
      </div>
    </div>
  );
}
