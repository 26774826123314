import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import Lottie from 'lottie-react';
import { registration } from '../../actions/auth';
import { getPlans } from '../../actions/plans';
import { fetchAllCountries } from '../../actions/countries';
import i18next from '../../i18n';
import Icon from '../../components/Icon';
import { ROOT_URL } from '../../actions';

import './registration.scss';
import eventTracker from '../../helpers/event-tracker';
import { default as animationData } from '../../components/loader.json';

const EUR_COUNTRIES_LIST = ['AT', 'BE', 'HR', 'CZ', 'CY', 'EE', 'GB', 'FI', 'FR', 'DE', 'GR', 'HU', 'IS', 'IE',
  'IT', 'LV', 'LI', 'LT', 'LU', 'MT', 'MC', 'NL', 'NO', 'PL', 'PT', 'RO', 'SM', 'SK', 'SI', 'ES', 'SE', 'CH'];

@withTranslation()
@connect((state, props) => {
  return {
    query: props.location.query,
    plans: state.plans,
    countries: state.countries,
  };
}, { registration, getPlans, fetchAllCountries })
export default class Registration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        email: '',
        firstName: '',
        lastName: '',
        planId: '',
        currency: 'eur',
        language: 'en',
      },
      plans: {
        usd: [],
        eur: [],
      },
      errors: {},
      state: 'form',
      errorMessage: '',
      countryOptions: [],
      currencyOptions: [
        { value: 'usd', label: 'USD' },
        { value: 'eur', label: 'EUR' },
      ],
    };

    this.onChange = this.onChange.bind(this);
    this.submit = this.submit.bind(this);
    this.validate = this.validate.bind(this);
    this.onCurrencyChange = this.onCurrencyChange.bind(this);
    this.findPlan = this.findPlan.bind(this);
  }

  componentDidMount() {
    this.setState({ state: 'loading' });

    const { ref } = this.props.query;

    if (ref) {
      eventTracker.trackEvent('Referral program', 'Open referral link', ref);
      const ifrm = document.createElement('iframe');
      ifrm.setAttribute('src', `${ROOT_URL}/set-ref-cookie/?ref=${ref}`);
      ifrm.style.width = '1px';
      ifrm.style.height = '1px';
      document.body.appendChild(ifrm);
    }

    Promise.all([
      this.props.getPlans('usd'),
      this.props.getPlans('eur'),
    ]).then(([plans_usd, plans_eur]) => {
      let { language } = this.props.query;

      switch (language) {
        case 'en':
          i18next.changeLanguage('en');
          moment.locale('en');
          this.setState({ ...this.state, form: { ...this.state.form, language: 'en' } });
          break;
        case 'de':
          i18next.changeLanguage('de');
          moment.locale('de');
          this.setState({ ...this.state, form: { ...this.state.form, language: 'de' } });
          break;
        case 'pt':
          language = 'pt';
          i18next.changeLanguage('pt');
          moment.locale('pt');
          this.setState({ ...this.state, form: { ...this.state.form, language: 'pt' } });
          break;
        default:
          language = 'en';
          i18next.changeLanguage('en');
          moment.locale('en');
          this.setState({ ...this.state, form: { ...this.state.form, language: 'en' } });
      }

      this.setState({
        state: 'form',
        language: language || 'en',
        plans: {
          usd: plans_usd,
          eur: plans_eur,
        },
        form: {
          ...this.state.form,
          planId: this.findPlan(this.state.form.currency, {
            usd: plans_usd,
            eur: plans_eur,
          }),
        },
      });
    });
  }

  findPlan(currency, fetchedPlans) {
    let { interval, plan } = this.props.query;

    currency = currency || 'eur';

    if (interval !== 'year') {
      interval = 'month';
    }

    let title = 'Single';

    if (plan) {
      switch (plan.toLowerCase()) {
        case 'small':
          title = 'Single';
          break;
        case 'medium':
          title = 'Single Pro';
          break;
        case 'free':
          title = 'free';
          break;
        default:
          title = 'small';
          break;
      }
    }

    const { plans } = fetchedPlans ? fetchedPlans[currency].payload.data : this.state.plans[currency].payload.data;
    let planId = '';

    if (title !== 'free') {
      const plan = plans.find((p) => {
        return p.interval === interval && p.title === title && p.id !== 'free' && p.public === 'true';
      });

      if (plan) {
        planId = plan.id;
      }
    } else {
      planId = 'free';
    }

    return planId;
  }

  onCurrencyChange(value) {
    const currency = value;
    const planId = this.findPlan(currency);

    this.setState({
      form: {
        ...this.state.form,
        currencyValue: value,
        currency,
        planId,
      },
    });
  }

  renderErrorMessage() {
    const { t } = this.props;

    if (this.state.errorMessage !== 'USER_ALREADY_EXISTS') {
      return <p className="error-message">{t(this.state.errorMessage)}</p>;
    }
    return (
      <p className="error-message">
        {t('USER_ALREADY_EXISTS1')}
        {' '}
        <Link
          to="/auth/login"
        >
          {t('USER_ALREADY_EXISTS2')}
        </Link>
      </p>
    );
  }

  checkError(name) {
    return this.state.errors[name];
  }

  onChange(value, name) {
    this.setState({
      form: {
        ...this.state.form,
        [name]: value,
      },
    });
  }

  submit(e) {
    e.preventDefault();

    if (this.validate()) {
      this.setState({ state: 'loading' });

      this.props.registration(this.state.form)
        .then((result) => {
          const { data } = result.payload;

          if (data.status === 'OK') {
            if (window.dataLayer) {
              window.dataLayer.push({
                event: 'signUp',
              });
            }
            this.setState({ state: 'success', errorMessage: '' });
          } else {
            this.setState({ state: 'form', errorMessage: data.message });
          }
        });
    }
  }

  validate() {
    const errors = {
      count: 0,
    };
    let errorMessage = '';
    const { form } = this.state;

    if (!validateEmail(form.email)) {
      errors.email = true;
      errors.count++;
      errorMessage = 'EMAIL_INVALID';
    }

    ['firstName', 'lastName'].map((name) => {
      if (!form[name].length) {
        errors[name] = true;
        errors.count++;
        errorMessage = 'ENTER_FIELDS';
      }
    });

    this.setState({ errors, errorMessage });

    return !errors.count;
  }

  render() {
    const {
      state, form,
    } = this.state;
    const { t } = this.props;

    return (
      <div className="registration-form">
        <div className="auth-logo">
          <div className="top-shade" />
          <img src="/images/logo-full.svg" width="196" height="57" />
        </div>
        <div className="wrapper">
          <main className="main" role="main">
            {
              state === 'form' ? (
                <form id="SignupForm" onSubmit={this.submit} noValidate>
                  <div className="row">
                    <div className="col">
                      {this.state.errorMessage != '' ? this.renderErrorMessage() : null}

                      <h2 className="heading">{t('REGISTER')}</h2>

                      <div className="input">
                        <label>{t('FIRST_NAME_LABEL')}</label>
                        <input
                          className={this.checkError('firstName') ? 'error' : ''}
                          type="text"
                          name="firstName"
                          value={form.firstName}
                          onChange={(e) => this.onChange(e.target.value, 'firstName')}
                          placeholder={t('FIRST_NAME_LABEL')}
                        />
                      </div>

                      <div className="input">
                        <label>{t('LAST_NAME_LABEL')}</label>
                        <input
                          className={this.checkError('lastName') ? 'error' : ''}
                          type="text"
                          name="lastName"
                          value={form.lastName}
                          onChange={(e) => this.onChange(e.target.value, 'lastName')}
                          placeholder={t('LAST_NAME_LABEL')}
                        />
                      </div>
                      <div className="input">
                        <label>{t('EMAIL_ADDRESS')}</label>
                        <input
                          className={this.checkError('email') ? 'error' : ''}
                          type="email"
                          name="email"
                          value={form.email}
                          onChange={(e) => this.onChange(e.target.value, 'email')}
                          placeholder={t('EMAIL_ADDRESS')}
                          noValidate
                        />
                      </div>

                      <div className="preferred-currency">
                        {t('PREFERRED_CURRENCY')}

                        <span className="currency-changer">
                          <span className={`eur ${form.currency === 'eur' ? 'active' : ''}`} onClick={() => this.onCurrencyChange('eur')}>€</span>
                          <span className={`usd ${form.currency === 'usd' ? 'active' : ''}`} onClick={() => this.onCurrencyChange('usd')}>$</span>
                        </span>
                      </div>

                      <div className="terms" dangerouslySetInnerHTML={{ __html: t('SIGN_UP_FOOTER') }} />

                      <input className="btn" type="submit" value={t('SIGN_UP')} />
                      <p className="signin"><Link to="/">{t('LINK_TO_SIGNIN')}</Link></p>
                    </div>

                    <div className="col features">
                      <ul>
                        <li className="heading">{t('FEATURE_0')}</li>
                        <li className="text">{t('FEATURE_INTRO')}</li>
                        <li>
                          <Icon name="checkmark-green" />
                          {' '}
                          <span dangerouslySetInnerHTML={{ __html: t('FEATURE_1') }} />
                        </li>
                        <li>
                          <Icon name="checkmark-green" />
                          {' '}
                          <span dangerouslySetInnerHTML={{ __html: t('FEATURE_2') }} />
                        </li>
                        <li>
                          <Icon name="checkmark-green" />
                          {' '}
                          <span dangerouslySetInnerHTML={{ __html: t('FEATURE_3') }} />
                        </li>
                        <li>
                          <Icon name="checkmark-green" />
                          {' '}
                          <span dangerouslySetInnerHTML={{ __html: t('FEATURE_4') }} />
                        </li>
                        <li>
                          <Icon name="checkmark-green" />
                          {' '}
                          <span dangerouslySetInnerHTML={{ __html: t('FEATURE_5') }} />
                        </li>
                      </ul>
                    </div>
                  </div>
                </form>
              ) : null
            }

            {
              state === 'success' ? (
                <div className="success-wrapper message">
                  <h2 className="success-header">{t('SUCCESS_HEADING')}</h2>
                  <img src="/images/mail.svg" width="125" height="124" className="img" />
                  <p className="success-text">{t('SUCCESS_TEXT')}</p>
                </div>
              ) : null
            }

            {
              state === 'loading' ? (
                <div id="Loader">
                  <div className="loader-image">
                    <Lottie
                      loop
                      autoplay
                      animationData={animationData}
                      style={{
                        height: 120,
                        width: 120,
                      }}
                      preserveAspectRatio="xMidYMid slice"
                    />
                  </div>
                </div>
              ) : null
            }
          </main>
        </div>
      </div>
    );
  }
}

function validateEmail(email) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
