import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import PlayerSelect from '../../../ui/player-select';

import './add-from-db.scss';

import PlayerImage from '../../../player-image';

import { ROOT_URL } from '../../../../actions/index';
import { addPlayerFromDB } from '../../../../actions/match';
import { getPlayerImage } from '../../../../helpers';

@withTranslation()
@connect((state) => ({
  positions: state.positions,
  teams: state.teams,
  match: state.match,
}), { addPlayerFromDB })

class AddFromDB extends Component {
  static propTypes = {
    positions: PropTypes.object,
    addPlayerFromDB: PropTypes.func,
  };

  constructor() {
    super();

    this.state = {
      player: null,
      players: {},
    };

    this.onChange = this.onChange.bind(this);
    this.getOptions = this.getOptions.bind(this);
    this.renderPlayer = this.renderPlayer.bind(this);
  }

  onChange(value) {
    this.setState({
      player: value.data,
    });
  }

  getOptions(input) {
    if (!input.length) return Promise.resolve([]);

    return axios.get(`${ROOT_URL}/players/search/${input}`)
      .then((response) => {
        const { players } = response.data;
        this.setState({ players: _.keyBy(players, 'key') });
        return players.map((player) => ({
          value: player.key,
          label: `${player.first_name} ${player.last_name}`,
        }));
      })
      .then((json) => ({ options: json }));
  }

  renderPlayer() {
    const { positions, addPlayerFromDB, t } = this.props;
    const { player } = this.state;

    if (!player) return null;

    const {
      key, jersey, position_id, age, country, foot, height, weight,
    } = player;

    const playerImage = getPlayerImage(player);

    return (
      <div>
        <div className="control-player-info">
          <PlayerImage className="player__image" src={playerImage} />
          { jersey ? (
            <div className="player__position">
              <b>{t('NUMBER')}</b>
              {' '}
              { jersey }
            </div>
          ) : null }
          { _.size(positions) && +position_id ? (
            <div className="player__position">
              <b>{t('POSITION')}</b>
              {' '}
              { t(`${positions[position_id].desc_short}`) }
            </div>
          ) : null }
          { age ? (
            <div className="player__position">
              <b>{t('AGE')}</b>
              {' '}
              { age }
            </div>
          ) : null }
          { country ? (
            <div className="player__position">
              <b>{t('COUNTRY')}</b>
              {' '}
              { country }
            </div>
          ) : null }
          { foot ? (
            <div className="player__position">
              <b>{t('FOOT')}</b>
              {' '}
              { t(`${foot}`) }
            </div>
          ) : null }
          { height ? (
            <div className="player__position">
              <b>{t('HEIGHT')}</b>
              {' '}
              { height }
            </div>
          ) : null }
          { weight ? (
            <div className="player__position">
              <b>{t('WEIGHT')}</b>
              {' '}
              { weight }
            </div>
          ) : null }
        </div>
        <a className="btn btn-block" onClick={() => { addPlayerFromDB({ player }); this.setState({ player: null }); }}>{t('ADD')}</a>
      </div>
    );
  }

  render() {
    const { t } = this.props;
    const { player } = this.state;
    const { teams, match } = this.props;
    const excludePlayerIds = _.keys({ ..._.get(match, 'data.home.players', {}), ..._.get(match, 'data.away.players', {}) });

    return (
      <div className="add-from-db">
        <div className="add-from-db__title">{t('ADD_PLAYER_FROM_DB')}</div>
        <PlayerSelect
          name="player-search"
          value={player ? player.key : ''}
          onChange={this.onChange}
          noResultsText={t('NO_RESULTS')}
          teams={teams}
          exclude={excludePlayerIds}
        />

        { this.renderPlayer() }
      </div>
    );
  }
}

export default AddFromDB;
