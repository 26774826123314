import './pdf-substitution.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import { withTranslation } from 'react-i18next';
import PitchEventsIcons from '../../ui/pitch-events-icons';
import { parsePlusTime } from '../../../helpers';

@withTranslation()
@connect((state) => ({
  pdf: state.pdf,
}), { })

export default class pdfPitch extends Component {
  static propTypes = {
    pdf: PropTypes.object,
  };

  constructor() {
    super();

    this.renderPlayers = this.renderPlayers.bind(this);
  }

  renderPlayers(side) {
    const { pdf, t } = this.props;

    if (!_.size(pdf.substitutions[side])) return null;

    const renderPlayers = (substitutions, to, time) => substitutions.map((substitution) => {
      const { information } = pdf.match[side].players[substitution.player_id];

      return (
        <div className={`pdf-substitution-player ${to}`} key={substitution.player_id}>
          <div className={`pdf-list-player ${side}`}>
            <div className="player__number">{ information.jersey }</div>
            <div className="player__name">{ information.last_name }</div>
            <PitchEventsIcons
              match={pdf.match}
              player_id={information.player_id}
              side={side}
              type="white-small-list"
            />
          </div>
        </div>
      );
    });

    const renderMins = () => _.map(pdf.substitutions[side], (substitutions, time) => (
      <div className="pdf-substitutions-list" key={`${side} ${time}`}>
        <div className="pdf-substitutions-min">{ parsePlusTime(time) }</div>
        <div className="row">
          <div className="col-6">
            { renderPlayers(substitutions.filter((substitution) => substitution.substitution), 'out') }
          </div>
          <div className="col-6">
            { renderPlayers(substitutions.filter((substitution) => !substitution.substitution), 'in') }
          </div>
        </div>
      </div>
    ));

    return (
      <div>
        <div className="substitution-title">{t('SUBSTITUTION')}</div>
        { renderMins() }
      </div>
    );
  }

  render() {
    return (
      <div className="pdf-substitution">
        <div className="row">
          <div className="col-6">
            { this.renderPlayers('home') }
          </div>
          <div className="col-6">
            { this.renderPlayers('away') }
          </div>
        </div>
      </div>
    );
  }
}
