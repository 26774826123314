import React from 'react';
import PropTypes from 'prop-types';

function Icon({ name, ...props }) {
  if (name.startsWith('custom_')) {
    name = 'bars';
  }

  return (
    <svg {...props} role="img">
      <use xlinkHref={`/images/sprite.symbol.v107.svg#${name}`} />
    </svg>
  );
}

Icon.propTypes = {
  name: PropTypes.string,
};

export default Icon;
