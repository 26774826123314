import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';

import './player-search.scss';

import Icon from '../../../Icon';

@withTranslation()
export default class PlayersSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: props.defaultName,
    };

    this.update = this.update.bind(this);
    this.search = this.search.bind(this);

    this.search = _.debounce(this.search, 500);
  }

  update(value) {
    this.setState({ name: value });
    this.search();
  }

  search() {
    this.props.search(this.state.name);
  }

  render() {
    const { t } = this.props;

    return (
      <div className="player-search">
        <input
          type="text"
          placeholder={t('SEARCH_BY_NAME')}
          value={this.state.name}
          onChange={(e) => this.update(e.target.value)}
        />
        <Icon name="search" />
      </div>
    );
  }
}
