import React from 'react';
import './league-view.scss';

export default function (props) {
  return (
    <div className="team-view">
      {
        props.noImage ? null : (
          <div
            className="team-view__img"
            style={{ backgroundImage: `url(https://static.sp7f.com/v2/leagues/${props.league_id_master}.png)` }}
          />
        )
      }
      <div className="team-view__label">
        {props.label}
      </div>
    </div>
  );
}
