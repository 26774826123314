import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { fetchFavoritesList, fetchFavoritesInfo } from '../../actions/favorites';
import { updateWatchlist } from '../../actions/watchlists';
import SmallLoader from '../ui/small-loader';
import SuggestionsTable from './suggestions-table';

import './watchlist-suggestions.scss';

@withTranslation()
@connect((state) => ({
  favorites: state.favorites,
}), { fetchFavoritesList, fetchFavoritesInfo, updateWatchlist })
class WatchlistSuggestions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      loaded: false,
      selectedPlayers: [],
      page: 1,
      info: {},
      list: [],
      showPerPage: 5,
    };

    this.onCheckPlayer = this.onCheckPlayer.bind(this);
    this.addPlayers = this.addPlayers.bind(this);
  }

  componentDidMount() {
    this.setState({ loading: true });

    const promises = [
      this.props.fetchFavoritesInfo(),
      this.props.fetchFavoritesList(),
    ];

    Promise.all(promises).then((result) => {
      let info = _.get(result, '[0].payload.data.favorites', []);
      info = _.keyBy(info, 'player_id');

      this.setState({
        loading: false, loaded: true, info, list: _.get(result, '[1].payload.data.favorites', []),
      });
    });
  }

  addPlayers() {
    const { watchlist } = this.props;
    const { info } = this.state;
    const { selectedPlayers } = this.state;
    const players = [...watchlist.players];

    const details = {};
    selectedPlayers.map((p) => {
      details[p] = { ...info[p] };
    });

    this.props.updateWatchlist({
      ...this.props.watchlist,
      players: [...players, ...selectedPlayers],
      details: { ...watchlist.details, ...details },
    });
  }

  onCheckPlayer(player) {
    const { selectedPlayers } = this.state;
    let newPlayers;
    const index = selectedPlayers.findIndex((p) => p == player.player_id);
    if (index !== -1) {
      newPlayers = [...selectedPlayers.slice(0, index), ...selectedPlayers.slice(index + 1)];
    } else {
      newPlayers = [...selectedPlayers, player.player_id];
    }

    this.setState({ selectedPlayers: newPlayers });
  }

  renderPlayersList() {
    let players = [];
    const { watchlist, t } = this.props;
    const {
      list, info, selectedPlayers, page, showPerPage,
    } = this.state;

    players = [...list];

    if (!players.length) { return this.renderEmptyMessage(); }
    const total = players.length;
    const hasNextPage = page * showPerPage < total;

    players = players.map((p) => ({ ...p, ...info[p.player_id] }));
    players = _.orderBy(players, ['category'], ['desc']);

    players = players.slice(0, page * showPerPage);

    return (
      <div className="suggestions-list">
        <SuggestionsTable players={players} onCheckPlayer={this.onCheckPlayer} watchlist={watchlist} selectedPlayers={selectedPlayers} />
        { hasNextPage ? (<button className="show-more" onClick={() => { this.setState({ page: page + 1 }); }}>{t('SHOW_MORE')}</button>) : null }
        { selectedPlayers.length ? (<button className="btn add-selected" onClick={this.addPlayers}>{t('ADD_SELECTED_TO_WATCHLIST')}</button>) : null}
      </div>
    );
  }

  renderEmptyMessage() {
    const { t } = this.props;
    return (
      <p className="empty-message">
        {t('SUGGESTION_EMPTY_LIST', { context: window.genderContext })}
      </p>
    );
  }

  renderLoadingScreen() {
    return (<div className="loading"><SmallLoader /></div>);
  }

  render() {
    const { t } = this.props;

    return (
      <div className="watchlist__suggestions">
        <h2>{t('SUGGESTIONS')}</h2>
        { this.state.loaded ? this.renderPlayersList() : this.renderLoadingScreen() }
      </div>
    );
  }
}

WatchlistSuggestions.propTypes = {
  onAddPlayersClick: PropTypes.func,
  watchlist: PropTypes.object,
  updateWatchlist: PropTypes.func,
};

export default WatchlistSuggestions;
