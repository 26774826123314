import './timeline.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import Select from 'react-select';
import { withTranslation } from 'react-i18next';
import Input from './input';

import Icon from '../../../Icon';

import {
  changeTimeLine, setGoalsForPeriod, changeInnerAndOuterControl, changePlayerControl, changeOuterControl, changeInnerControl,
} from '../../../../actions/match';
import Substitutions from '../../single-report/substitutions';

let interval;
let startDate = null;

@withTranslation()
@connect((state) => ({
  match: state.match,
}), {
  changeTimeLine, setGoalsForPeriod, changeInnerAndOuterControl, changePlayerControl, changeOuterControl, changeInnerControl,
})

class Timeline extends Component {
  static propTypes = {
    match: PropTypes.object,
    changeTimeLine: PropTypes.func,
  };

  constructor() {
    super();

    this.state = {
      timeline: 0,
      min: 0,
      sec: 0, // with minutes
      enable: false,
      max: 120 * 60,
      timer_start: null,
      period: null,
      changeMode: false,
    };

    this.toggleTimer = this.toggleTimer.bind(this);
    this.periodChange = this.periodChange.bind(this);
    this.saveTime = this.saveTime.bind(this);
    this.focusHandler = this.focusHandler.bind(this);
    this.blurHandler = this.blurHandler.bind(this);

    this.pauseTimer = this.pauseTimer.bind(this);
    this.resumeTimer = this.resumeTimer.bind(this);
    this.changeTime = this.changeTime.bind(this);
  }

  componentDidUpdate() {
    const { timeline } = this.props.match;

    if (timeline !== this.state.timeline) {
      this.setState({
        timeline,
        period: parseInt(`${timeline}`.slice(0, 1)),
        min: parseInt(`${timeline}`.slice(1, 4)),
        sec: parseInt(`${timeline}`.slice(1, 4) * 60) + parseInt(`${timeline}`.slice(4, 6)),
      });
    }
  }

  componentWillUnmount() {
    clearInterval(interval);
    this.setState({ enable: false });
    // this.saveTime();
  }

  saveTime() {
    startDate = new Date();
    const minParsed = `000${this.state.min}`.substr(-3);
    const secParsed = `00${this.state.sec - this.state.min * 60}`.substr(-2);

    this.props.changeTimeLine(parseInt(`${this.state.period}${minParsed}${secParsed}`));
  }

  toggleTimer(switchTo) {
    const {
      sec, enable, max, period,
    } = this.state;

    if ((switchTo === 'pause' || (enable && switchTo !== 'resume')) && sec < 120 * 60) {
      clearInterval(interval);
      this.saveTime();
      this.setState({ enable: false, timer_start: null });
    } else {
      if (switchTo === 'resume') {
        clearInterval(interval);
        this.saveTime();
        this.setState({ enable: false, timer_start: null });
      }

      const startDate = new Date();

      if (!period) {
        this.props.setGoalsForPeriod('1');
      }

      this.setState({ enable: true, timer_start: startDate, period: period || '1' });

      interval = setInterval(() => {
        const newSec = sec + Math.floor((new Date() - this.state.timer_start) / 1000);
        const newMin = Math.floor(newSec / 60);

        if (newSec < 120 * 60) {
          this.setState({
            enable: true,
            sec: newSec,
            min: newMin,
            max: newSec > 100 * 60 ? newSec : max,
          });

          this.saveTime();
        } else {
          clearInterval(interval);
          this.saveTime();
          this.setState({ enable: false, timer_start: null });
        }
      }, 1000);
    }
  }

  pauseTimer() {
    this.toggleTimer('pause');
  }

  resumeTimer() {
    this.toggleTimer('resume');
  }

  changeTime(timeline) {
    const period = parseInt(`${timeline}`.slice(0, 1), 10);
    const min = parseInt(`${timeline}`.slice(1, 4), 10);
    const sec = parseInt(`${timeline}`.slice(4, 6), 10);

    this.setState({
      period,
      min,
      sec: min * 60 + sec,
    });
    this.saveTime();
  }

  focusHandler() {
    const { period, enable } = this.state;
    (period && enable) && this.toggleTimer();
  }

  blurHandler(unit, value) {
    const {
      period, enable, sec, min,
    } = this.state;

    startDate = new Date();

    if (unit === 'min') {
      this.setState({
        min: value,
        sec: value * 60 + sec % 60,
      });
    }
    if (unit === 'sec') {
      this.setState({
        sec: min * 60 + value,
      });
    }

    (period && !enable) && setTimeout(this.toggleTimer, 0);
    this.saveTime();
  }

  periodChange(value) {
    const { period } = this.state;
    const { periods } = this.props.match;

    if (!period || +period !== +value.value) {
      this.props.setGoalsForPeriod(value.value);
      this.setState({
        period: +value.value,
        min: periods[value.value].startTime,
        sec: periods[value.value].startTime * 60,
      });
    } else {
      this.setState({
        period: +value.value,
      });
    }

    setTimeout(this.toggleTimer, 0);
  }

  render() {
    const {
      sec, min, enable, period, timeline,
    } = this.state;

    const {
      data, periods, player_id, side,
    } = this.props.match;
    const {
      t, changeInnerAndOuterControl, changePlayerControl, changeOuterControl, changeInnerControl,
    } = this.props;

    if (!_.size(data)) return null;

    const periodOptions = _.map(periods, (period, value) => ({ label: t(period.label), value }));

    const minParsed = `000${min}`.substr(min < 100 ? -2 : -3);
    const secParsed = `00${sec % 60}`.substr(-2);
    const values = _.get(this.props.match, `data[${side}].values[${player_id}]`, {});

    return (
      <div className="timeline">
        <div className="clock">
          <span className="unit">{t('MIN')}</span>
          <Input defaultValue={minParsed} onFocus={this.focusHandler} onBlur={this.blurHandler} unit="min" />
          <span className="colon">:</span>
          <Input defaultValue={secParsed} onFocus={this.focusHandler} onBlur={this.blurHandler} unit="sec" />
          <span className="unit">{t('SEC')}</span>
        </div>
        <Select
          className="Select"
          classNamePrefix="Select"
          name="form-field-name"
          value={period}
          onChange={this.periodChange}
          options={periodOptions}
          clearable={false}
          isSearchable={false}
        />
        <a className={`toggle-timer ${enable ? 'pause' : 'play'}`} onClick={this.toggleTimer}>
          <Icon name={`${enable ? 'pause' : 'play'}`} />
          <span className="timer-status">
            {t('TAP_TO')}
            {' '}
            {enable ? t('PAUSE') : t('START') }
            <br />
            {enable && period ? t(periods[period].label) : t('RECORDING') }
          </span>
        </a>
        { this.props.match.type === 'single' ? (
          <Substitutions
            player_id={player_id}
            side={side}
            timeline={timeline}
            changeInnerAndOuterControl={changeInnerAndOuterControl}
            changePlayerControl={changePlayerControl}
            changeOuterControl={changeOuterControl}
            changeInnerControl={changeInnerControl}
            values={values}
            pauseTimer={this.pauseTimer}
            resumeTimer={this.resumeTimer}
            changeTime={this.changeTime}
          />
        ) : null }
      </div>
    );
  }
}

export default Timeline;
