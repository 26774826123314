export const colors = {
  primary: '#35B374',
  primary75: '#188538',
  primary50: '#2DC46E',
  primary25: '#e4fff1',

  danger: '#DE350B',
  dangerLight: '#FFBDAD',
  disabled: '#637581',

  neutral0: 'hsl(0, 0%, 100%)',
  neutral5: 'hsl(0, 0%, 95%)',
  neutral10: 'hsl(0, 0%, 90%)',
  neutral20: 'hsl(0, 0%, 80%)',
  neutral30: 'hsl(0, 0%, 70%)',
  neutral40: 'hsl(0, 0%, 60%)',
  neutral50: 'hsl(0, 0%, 50%)',
  neutral60: 'hsl(0, 0%, 40%)',
  neutral70: 'hsl(0, 0%, 30%)',
  neutral80: 'hsl(0, 0%, 20%)',
  neutral90: 'hsl(0, 0%, 10%)',
};

const borderRadius = 4;
const baseUnit = 3; /* Used to calculate consistent margin/padding on elements */
const controlHeight = 38; /* The minimum height of the control */
const controlHeightSmall = 18; /* The minimum height of the control */
const menuGutter = baseUnit * 2; /* The amount of space between the control and menu */

const editBaseUnit = 1;
const editControlHeight = 16;

export const spacing = {
  baseUnit,
  controlHeight,
  menuGutter,
};

export const theme = {
  borderRadius,
  colors,
  spacing,
};

export const filterTheme = {
  ...theme,
};

export const editSelectTheme = {
  ...theme,
  spacing: {
    ...theme.spacing,
    controlHeight: editControlHeight,
    baseUnit: editBaseUnit,
  },
};

export const accountTheme = {
  ...theme,
  spacing: {
    ...theme.spacing,
    controlHeight: controlHeightSmall,
  },
};

export const style = {
  control: (styles, { isFocused, isDisabled }) => ({
    ...styles,
    backgroundColor: 'transparent',
    borderLeft: 'none',
    borderRight: 'none',
    borderTop: 'none',
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: isFocused ? '#FFFFFF' : isDisabled ? colors.disabled : colors.primary,
    borderRadius: 'none',
    boxShadow: isFocused ? 'none' : 'none',
    ':hover': {
      borderBottomColor: isFocused ? '#FFFFFF' : isDisabled ? colors.disabled : colors.primary,
      boxShadow: 'none',
    },
    whiteSpace: 'nowrap',
  }),
  option: (styles) => ({
    ...styles,
    color: '#000000',
    textAlign: 'left',
  }),
  placeholder: (styles, { isFocused, isDisabled }) => ({
    ...styles,
    color: isDisabled ? colors.disabled : isFocused ? '#FFF' : colors.primary,
    fontSize: '16px',
  }),
  dropdownIndicator: (styles, { isFocused, isDisabled }) => ({
    color: isFocused ? '#FFFFFF' : isDisabled ? colors.disabled : colors.primary,
    ':hover': {
      color: isDisabled ? colors.disabled : '#FFFFFF',
    },
  }),
  indicatorSeparator: (styles) => ({
    display: 'none',
  }),
  input: (styles) => ({
    ...styles,
    fontSize: '16px',
    color: '#FFFFFF',
  }),
  valueContainer: (styles) => ({
    ...styles,
    color: '#FFFFFF',
  }),
  singleValue: (styles, { isDisabled }) => ({
    ...styles,
    fontSize: '16px',
    color: isDisabled ? colors.disabled : '#FFFFFF',
  }),
  multiValue: (styles) => ({
    ...styles,
    fontSize: '16px',
    color: '#FFFFFF',
    background: 'rgba(255,255,255, .15)',
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    color: '#FFFFFF',
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    ':hover': {
      backgroundColor: 'transparent',
      color: colors.primary50,
    },
  }),
  loadingIndicator: (styles) => ({
    ...styles,
    fill: '#FFFFFF',
    color: '#FFFFFF',
  }),
};

export const styleHome = {
  ...style,
  option: (styles, props) => ({
    ...styles,
    color: '#FFFFFF',
    textAlign: 'left',
  }),
  placeholder: (styles, { isFocused, isDisabled }) => ({
    ...styles,
    color: '#FFFFFF',
    fontSize: '16px',
  }),
};

export const styleLeagueSelect = {
  ...style,
  placeholder: (styles, { isFocused }) => ({
    ...styles,
    color: isFocused ? '#FFFFFF' : colors.primary,
    fontSize: '16px',
  }),
  group: (styles) => ({
    ...styles,
  }),
  groupHeading: (styles) => ({
    ...styles,
    fontSize: '16px',
    lineHeight: '22px',
    color: '#000',
    textTransform: 'none',
  }),
  option: (styles, props) => ({
    ...styles,
    color: '#000000',
    textAlign: 'left',
    paddingLeft: props.firstLevel ? '9px' : '40px',
  }),
};

export const styleBillingForm = {
  ...style,
  control: (styles, { isFocused, ...rest }) => ({
    ...styles,
    backgroundColor: 'transparent',
    borderLeft: 'none',
    borderRight: 'none',
    borderTop: 'none',
    borderBottom: rest.selectProps.isError ? '1px solid #d33a61' : isFocused ? '1px solid #FFFFFF' : '1px solid #2DC46E',
    borderRadius: 'none',
    boxShadow: isFocused ? 'none' : 'none',
    ':hover': {
      borderBottom: isFocused ? '1px solid #FFFFFF' : '1px solid #2DC46E',
      boxShadow: 'none',
    },
    minHeight: '30px',
  }),
};

export const styleFilter = {
  ...style,
  control: (styles, { isFocused }) => ({
    ...styles,
    backgroundColor: 'transparent',
    border: 'none',
    color: '#2DC46E',
    borderBottom: isFocused ? '1px solid #FFFFFF' : '1px solid #2DC46E',
    borderRadius: 'none',
    boxShadow: isFocused ? 'none' : 'none',
    ':hover': {
      borderBottom: isFocused ? '1px solid #FFFFFF' : '1px solid #2DC46E',
      boxShadow: 'none',
    },
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    color: '#2DC46E',
    ':hover': {
      color: '#2DC46E',
    },
  }),
  placeholder: (styles, { selectProps }) => ({
    ...styles,
    color: selectProps.placeholderColor ? selectProps.placeholderColor : '#2DC46E',
  }),
  singleValue: (styles, { selectProps }) => ({
    ...styles,
    color: selectProps.color ? selectProps.color : '#2DC46E',
  }),
  option: (styles, state) => ({
    ...styles,
    color: state.isDisabled ? '#CCC' : '#000000',

  }),
};

export const styleFilterNotes = {
  ...style,
  control: (styles, { isFocused }) => ({
    ...styles,
    backgroundColor: 'transparent',
    border: 'none',
    color: '#2DC46E',
    borderBottom: isFocused ? '1px solid #FFFFFF' : '1px solid #2DC46E',
    borderRadius: 'none',
    boxShadow: isFocused ? 'none' : 'none',
    ':hover': {
      borderBottom: isFocused ? '1px solid #FFFFFF' : '1px solid #2DC46E',
      boxShadow: 'none',
    },
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    color: '#2DC46E',
    ':hover': {
      color: '#2DC46E',
    },
  }),
  placeholder: (styles) => ({
    ...styles,
    color: '#2DC46E',
  }),
  singleValue: (styles) => ({
    ...styles,
    color: '#2DC46E',
  }),
  option: (styles, state) => ({
    ...styles,
    fontSize: '13px',
    lineHeight: '18px',
    color: state.isDisabled ? '#CCC' : '#000000',
  }),
  menuList: (styles, state) => ({
    ...styles,
    width: '320px',
  }),
  menu: (styles, state) => ({
    ...styles,
    width: '320px',
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
};

export const styleEditSelect = {
  ...style,
  container: (styles) => ({
    ...styles,
    padding: 0,
    height: '22px',
  }),
  control: (styles, { isFocused }) => ({
    ...styles,
    backgroundColor: 'transparent',
    border: 'none',
    color: '#FFFFFF',
    borderBottom: isFocused ? '1px solid #FFFFFF' : '1px solid #2DC46E',
    borderRadius: 'none',
    boxShadow: isFocused ? 'none' : 'none',
    padding: 0,
    fontSize: '16px',
    lineHeight: '20px',
    height: '21px',
    ':hover': {
      borderBottom: isFocused ? '1px solid #FFFFFF' : '1px solid #2DC46E',
      boxShadow: 'none',
    },
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    color: '#2DC46E',
    ':hover': {
      color: '#2DC46E',
    },
  }),
  placeholder: (styles) => ({
    ...styles,
    color: '#FFFFFF',
  }),
  singleValue: (styles) => ({
    ...styles,
    color: '#FFFFFF',
  }),
  option: (styles) => ({
    ...styles,
    color: '#000000',
  }),
};

export const styleTableEditSelect = {
  ...style,
  container: (styles) => ({
    ...styles,
    padding: 0,
    height: '33px',
  }),
  control: (styles, { isFocused, selectProps }) => ({
    ...styles,
    backgroundColor: 'transparent',
    border: 'none',
    color: '#FFFFFF',
    borderBottom: isFocused ? '1px solid #FFFFFF' : selectProps.invalid ? '1px solid #d33a61' : '1px solid #2DC46E',
    borderRadius: 'none',
    boxShadow: isFocused ? 'none' : 'none',
    padding: '5px 0',
    fontSize: '16px',
    lineHeight: '22px',
    height: '33px',
    ':hover': {
      borderBottom: isFocused ? '1px solid #FFFFFF' : '1px solid #2DC46E',
      boxShadow: 'none',
    },
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    color: '#2DC46E',
    ':hover': {
      color: '#2DC46E',
    },
  }),
  placeholder: (styles) => ({
    ...styles,
    color: '#FFFFFF',
  }),
  singleValue: (styles) => ({
    ...styles,
    color: '#FFFFFF',
  }),
  option: (styles) => ({
    ...styles,
    color: '#000000',
  }),
};

export const registrationStyle = {
  control: (styles, { isFocused, selectProps }) => ({
    ...styles,
    backgroundColor: selectProps.isError ? 'rgba(255, 235, 235, .85)' : 'rgba(255, 255, 255, .9)',
    border: '1px solid #CCCCCC',
    borderTop: 'none',
    borderRadius: selectProps.lastOne ? '0 0 6px 6px' : 'none',
    boxShadow: isFocused ? 'none' : 'none',
    whiteSpace: 'nowrap',
  }),
  option: (styles) => ({
    ...styles,
    color: '#000000',
  }),
  placeholder: (styles, { isError, selectProps }) => ({
    ...styles,
    color: '#666666',
    fontSize: '15px',
    opacity: 1,
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    color: '#666666',
    ':hover': {
      color: '#2DC46E',
    },
  }),
  indicatorSeparator: (styles) => ({
    display: 'none',
  }),
  input: (styles) => ({
    ...styles,
    color: '#000000',
  }),
  valueContainer: (styles) => ({
    ...styles,
    color: '#000000',
  }),
  singleValue: (styles) => ({
    ...styles,
    color: '#000000',
  }),
  loadingIndicator: (styles) => ({
    ...styles,
    fill: '#666666',
    color: '#666666',
  }),
};

export const stylePDF = {
  control: (styles, { isFocused }) => ({
    ...styles,
    backgroundColor: 'transparent',
    borderLeft: 'none',
    borderRight: 'none',
    borderTop: 'none',
    borderBottom: isFocused ? '1px solid #2DC46E' : '1px solid #CCCCCC',
    borderRadius: 'none',
    boxShadow: isFocused ? 'none' : 'none',
    ':hover': {
      borderBottom: isFocused ? '1px solid #2DC46E' : '1px solid #CCCCCC',
      boxShadow: 'none',
    },
    whiteSpace: 'nowrap',
  }),
  option: (styles) => ({
    ...styles,
    color: '#000000',
    textAlign: 'left',
  }),
  placeholder: (styles) => ({
    ...styles,
    color: '#CCCCCC',
    fontSize: '13px',
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    color: '#000000',
    ':hover': {
      color: '#000000',
    },
  }),
  indicatorSeparator: (styles) => ({
    display: 'none',
  }),
  input: (styles) => ({
    ...styles,
    color: '#000000',
  }),
  valueContainer: (styles) => ({
    ...styles,
    color: '#000000',
  }),
  singleValue: (styles) => ({
    ...styles,
    color: '#000000',
  }),
  multiValue: (styles) => ({
    ...styles,
    color: '#000000',
    background: 'rgba(0, 0, 0, .1)',
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    color: '#000000',
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: '#000000',
    ':hover': {
      backgroundColor: 'transparent',
      color: colors.primary50,
    },
  }),
  loadingIndicator: (styles) => ({
    ...styles,
    fill: '#000000',
    color: '#000000',
  }),
};
