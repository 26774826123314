import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import './favorite_stats.scss';
import { scaleLinear, rgb } from 'd3';

import { withTranslation } from 'react-i18next';
import Icon from '../../../Icon';

const IndicatorColors = ['#1E619F', '#6CB5D7', '#A4A6A4', '#F98736', '#DA4B06'];
const Positions = ['10%', '30%', '50%', '70%', '90%'];

@withTranslation()
@connect((state) => {
  return {
    indicators: state.indicators,
    favoritesFilter: state.favorites.filter,
  };
}, {})
export default class StatsChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tab: 0,
    };

    this.setTab = this.setTab.bind(this);
  }

  render() {
    const { matches, favoritesFilter } = this.props;
    const { tab } = this.state;
    let useMatches = matches;

    if (tab) {
      useMatches = matches.slice(-tab);
    }

    const stats = this.preparePlayerStats(useMatches);

    return (
      <div className="favorite-stats">
        { this.renderTabs() }
        <div className={`favorite-stats__body ${tab === 0 ? 'first-active' : 'last-active'}`}>
          { this.renderChart(stats) }
        </div>

      </div>
    );
  }

  preparePlayerStats(matches) {
    const { playerId } = this.props;
    const playerStats = matches.map((m) => {
      return _.get(m, `home.values[${playerId}]`, null) || _.get(m, `away.values[${playerId}]`, null);
    });

    const averageStats = {};

    playerStats.forEach((match) => {
      _.forOwn(match, (entry) => {
        _.forOwn(entry, (indicator, key) => {
          if (key === 'player_id' || key === 'event_id' || key === 'notes') return;
          if (!averageStats[key]) averageStats[key] = 0;
          averageStats[key] += +indicator;
          if (!averageStats[`${key}_entries_`]) averageStats[`${key}_entries_`] = 0;
          averageStats[`${key}_entries_`] += 1;
          averageStats[`${key}_avg_`] = averageStats[key] / averageStats[`${key}_entries_`];
        });
      });
    });

    return averageStats;
  }

  renderTabs() {
    const { t } = this.props;
    const { tab } = this.state;

    return (
      <div className="favorite-stats__nav">
        <div className={`favorite-stats__nav-tab ${tab === 0 ? 'active' : ''}`} onClick={() => { this.setTab(0); }}>{t('AVERAGE_PERFORMANCE')}</div>
        <div className={`favorite-stats__nav-tab ${tab === 1 ? 'active' : ''}`} onClick={() => { this.setTab(1); }}>{t('LAST_REPORT')}</div>
      </div>
    );
  }

  setTab(count) {
    this.setState({
      tab: count,
    });
  }

  renderChart(stats) {
    const { indicators, t } = this.props;
    const groupedIndicators = _.groupBy(indicators.info, 'class');
    const groupsMap = ['Off the Ball', 'On the Ball', 'Physis'];

    const groupsWithStats = groupsMap.filter((group) => {
      const indicators = groupedIndicators[group].filter((indicator) => {
        const { key } = indicator;

        return stats[key] !== undefined;
      });

      return indicators.length;
    });

    if (!groupsWithStats.length) {
      return (
        <div className="favorite-stats__chart">
          <p className="empty-message">
            {t('EVALUATIONS_EMPTY', { context: window.genderContext })}
          </p>
        </div>
      );
    }

    return (
      <div className="favorite-stats__chart">
        {
          groupsWithStats.map((g) => {
            return this.renderChartGroup(g, groupedIndicators, stats);
          })
        }
      </div>
    );
  }

  renderChartGroup(group, groupedIndicators, stats) {
    const { t } = this.props;

    return (
      <table className="favorite-stats__chart-section" key={group}>
        <thead>
          <tr>
            <th className="group-name">{t(group.toUpperCase().split(' ').join('_'))}</th>
            <th className="label">- -</th>
            <th className="label">-</th>
            <th className="label">0</th>
            <th className="label">+</th>
            <th className="label">+ +</th>
          </tr>
        </thead>
        <tbody>
          {
            this.renderChartGroupContent(group, groupedIndicators, stats)
          }
        </tbody>
      </table>
    );
  }

  renderChartGroupContent(group, groupedIndicators, stats) {
    const { i18n } = this.props;
    const lng = i18n.language;

    const indicators = groupedIndicators[group];

    return indicators.map((indicator) => {
      const { key } = indicator;
      const value = stats[`${key}_avg_`];

      if (!value && value !== 0) {
        return null;
      }

      const domain = [-2, -1, 0, 1, 2];

      const colorScale = scaleLinear()
        .domain(domain)
        .range(IndicatorColors.map((c) => rgb(c)));

      const indicatorName = _.get(indicator, `name_${lng}`, _.get(indicator, 'name', null));
      return (
        <tr key={indicator.key}>
          <td className="indicator-name">
            <Icon name={indicator.key} />
            {indicatorName}
            {' '}
            (
            {value === Math.round(value) ? value : value.toFixed(1)}
            )
          </td>
          <td className="value" colSpan={5}>
            <span className="line" />
            <span className="line" />
            <span className="line" />
            <span className="line" />
            <span className="line" />
            <span
              className="val-indicator"
              style={
                {
                  backgroundColor: colorScale(Math.round(value)),
                  left: `${(((value + 2) / 4 * 0.8) * 100) + 10}%`,
                }
              }
            />
          </td>
        </tr>
      );
    });
  }
}
