import './pitch-events-icons.scss';

import React from 'react';
import Icon from '../../Icon';

export default function ({
  match, player_id, side, type,
}) {
  let playerValues = _.get(match, `[${side}].values[${player_id}]`, {});
  playerValues = _.cloneDeep(playerValues);

  const haveYellow = _.chain(playerValues).filter((o) => Array.isArray(o.event_type) && o.event_type.indexOf('yellow') !== -1).value().length;
  const haveYellowRed = _.chain(playerValues).filter((o) => Array.isArray(o.event_type) && o.event_type.indexOf('yellow_red') !== -1).value().length;
  const haveRed = _.chain(playerValues).filter((o) => Array.isArray(o.event_type) && o.event_type.indexOf('red') !== -1).value().length;
  const haveGoal = _.chain(playerValues).filter((o) => Array.isArray(o.event_type) && o.event_type.indexOf('goal') !== -1).value().length;
  const haveOwnGoal = _.chain(playerValues).filter((o) => Array.isArray(o.event_type) && o.event_type.indexOf('own_goal') !== -1).value().length;

  const icons = [];

  if (haveYellowRed) {
    icons.push(<Icon name="yellow_red" className="yellow_red" key="yellow_red" />);
  } else {
    if (haveYellow) {
      icons.push(<Icon name="card-pitch" className="yellow" key="yellow" />);
    }

    if (haveRed) {
      icons.push(<Icon name="card-pitch" className="red" key="red" />);
    }
  }

  if (haveGoal) {
    if (haveGoal <= 2) {
      Array.from({ length: haveGoal }, (v, i) => {
        icons.push(<Icon name="goal" className="goal" key={`goal_${i}`} />);
      });
    } else {
      icons.push((<span className="goal-icon" key="goal_multi">
        <Icon name="goal" className="goal" />
        x
        {haveGoal}
      </span>));
    }
  }

  if (haveOwnGoal) {
    if (haveOwnGoal <= 2) {
      Array.from({ length: haveOwnGoal }, (v, i) => {
        icons.push(<Icon name="goal" className="own-goal" key={`own-goal_${i}`} />);
      });
    } else {
      icons.push((<span className="goal-icon" key="own-goal_multi">
        <Icon name="goal" className="own-goal" />
        x
        {haveOwnGoal}
                  </span>));
    }
  }

  return icons.length
    ? (
      <span className={`event-icons event-icons__${type}`}>
        {icons}
      </span>
    ) : null;
}
