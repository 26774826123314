import React, { Component } from 'react';
import ReactMarkdown from 'react-markdown';
import breaks from 'remark-breaks';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import moment from 'moment';
import _ from 'lodash';

import { withTranslation } from 'react-i18next';
import Image from '../../../../ui/image';
import Tag from '../tag';
import NotesList from '../notes-list';
import { selectNote, deleteNote, setNoteMode } from '../../../../../actions/notes';
import Icon from '../../../../Icon';
import { getTeamImage, parsePlusTime } from '../../../../../helpers';
import PlayerImage from '../../../../player-image';

import './note.scss';

@withTranslation()
@connect((state) => ({
  dictionaries: state.notes.dictionaries,
  selectedNote: state.notes.selectedNote,
  moreNotes: state.notes.moreNotes,
  teams: state.teams,
}), { selectNote, deleteNote, setNoteMode })

export default class Note extends Component {
  constructor(props) {
    super(props);

    this.deleteNote = this.deleteNote.bind(this);
  }

  render() {
    const note = this.props.selectedNote;
    const { player_id, t, teams } = this.props;
    const { matchesByKey } = this.props.dictionaries;

    if (!note) {
      return null;
    }
    const createDate = note.updatedAt || note.createdAt;
    const date = createDate ? moment(createDate).format('DD.MM.YYYY - HH:mm') : '';
    const playerProfileCls = player_id ? 'player-profile' : '';

    let fakeTitle = '';
    if (note.match && note.time) {
      const { home, away } = note.match;
      const homeTitle = _.get(teams, `[${home}].name`, '');
      const awayTitle = _.get(teams, `[${away}].name`, '');

      fakeTitle = `${+note.time !== 0 ? parsePlusTime(note.time.toString()) : '0\''}, ${homeTitle} vs. ${awayTitle}`;
    }

    return (
      <div>
        <div className={`note-content ${playerProfileCls}`} onDoubleClick={() => {}}>
          <div className="note-content__title">
            <h2>{note.title || fakeTitle || t('NOTE_TITLE_PLACEHOLDER')}</h2>
            { note._id ? <a onClick={() => { this.props.setNoteMode('edit'); }} className="note-content__button edit-note"><Icon name="editnote2" /></a> : null }
            { note._id ? <a onClick={this.deleteNote} className="note-content__button delete-note"><Icon name="trashcan2" /></a> : null }
          </div>
          <div className="note-content__text">
            {note.value ? <ReactMarkdown children={note.value} plugins={[breaks]} /> : t('NOTE_TEXT_PLACEHOLDER') }
          </div>
          <div className="note-content__tags">
            {this.renderEvents()}
            {this.renderTags()}
          </div>
          {
            note.match_id
              ? (
                <div className="note-content__meta">
                  {_.get(matchesByKey, `[${note.match_id}].name`, '')}
                </div>
              ) : null
          }
          <div className="note-content__meta">
            By
            {`${note.user.firstName} ${note.user.lastName}${date ? `, ${date}` : ''}`}
          </div>
          { note.match_id ? this.renderTeamNote() : null }
        </div>
        { !player_id ? this.renderMoreNotes() : null }
      </div>
    );
  }

  deleteNote() {
    const { t } = this.props;
    const note = this.props.selectedNote;

    if (confirm(t('CONFIRM_DELETE_NOTE'))) {
      this.props.deleteNote(note._id);
    }
  }

  renderTeamNote() {
    const {
      teams, i18n, t, selectedNote,
    } = this.props;

    const lng = i18n.language;
    const { match } = selectedNote;

    if (!match) return null;

    const { side } = match;

    const teamNote = _.get(match, `team_note_${match.side}`, '');
    const teamTags = _.get(match, `team_tags_${match.side}`, []);
    const teamId = _.get(match, `${side}`, null);
    const teamName = _.get(teams, `[${teamId}].name_${lng}`, _.get(teams, `[${teamId}].name`), t('UNKNOWN_TEAM'));

    if (!teamNote) return null;

    const teamImg = teamId ? getTeamImage(teamId) : null;

    return (
      <div className="team-note">
        { teamName ? (

          <h5 className="team-name">
            {t('TEAMNOTES')}
            {teamImg ? <PlayerImage className="team-note-image" src={teamImg} /> : null }
            {teamName}
          </h5>
        ) : null }
        { teamNote ? <ReactMarkdown source={teamNote} plugins={[breaks]} /> : null }
      </div>
    );
  }

  renderPlayer() {
    const { t } = this.props;
    const { favoritePlayersByKey } = this.props.dictionaries;
    const note = this.props.selectedNote;
    const player = _.get(favoritePlayersByKey, `[${note.player_id}]`, null);

    if (!player) return null;

    return (
      <div className="note-content__player">
        <h4>{t('PLAYER_INFORMATION')}</h4>
        <div className="player-card">
          <span className="player-text">
            <span className="player-name-first">{player.first_name}</span>
            <span className="player-name-last">{player.last_name}</span>
            <span className="player-team">{player.team_name}</span>
          </span>
        </div>
      </div>
    );
  }

  renderTags() {
    const { tags } = this.props.selectedNote;
    const { tagsByKey } = this.props.dictionaries;

    return tags.map((t) => <Tag tag={tagsByKey[t].tag} key={t} />);
  }

  renderEvents() {
    const { event_type } = this.props.selectedNote;
    const { t } = this.props;

    if (!Array.isArray(event_type)) return null;

    return event_type.map((et) => <Tag tag={t(et)} key={et} />);
  }

  renderMoreNotes() {
    const { moreNotes, t } = this.props;

    if (!moreNotes.length) return null;

    return (
      <div className="note-content__more">
        <h4>{t('MORE_NOTES')}</h4>
        <NotesList notes={moreNotes} />
      </div>
    );
  }
}
