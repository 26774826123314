import axios from 'axios';
import localforage from 'localforage';
import _ from 'lodash';

import { ROOT_URL } from '../actions';

export default function (props) {
  const gender = localStorage.getItem('selectedGender');
  const collectionName = `teams_${gender}`;

  switch (window.online) {
    case true:
      return axios
        .get(`${ROOT_URL}/teams`, {
          params: props,
        })
        .then((res) => {
          const teams = _.keyBy(res.data.teams, 'id');

          localforage.setItem(collectionName, JSON.stringify(teams));
          return Promise.resolve({ teams });
        })
        .catch((err) => {
          console.error(err);
        });

    case false:
      return localforage
        .getItem(collectionName)
        .then((teams) => Promise.resolve({ teams: JSON.parse(teams) }))
        .catch((err) => {
          console.error(err);
        });
  }
}
