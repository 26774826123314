import axios from 'axios';
import localforage from 'localforage';
import { MATCHES_COLLECTION } from './constants';

import { ROOT_URL } from '../actions';

export default function (props) {
  const gender = localStorage.getItem('selectedGender');
  const userId = localStorage.getItem('userId');
  const collection = `players-${MATCHES_COLLECTION}-${gender}-${userId}`;

  switch (window.online) {
    case true:
      const updateOfflinePromise = Promise.all([
        localforage.getItem(collection),
      ]).then(([players]) => {
        players = JSON.parse(players);
        if (players && players.length) {
          axios.post(`${ROOT_URL}/players`, players).then(() => {
            localforage.setItem(collection, JSON.stringify([]));
          });
        }
      });

      if (props) {
        return axios.post(`${ROOT_URL}/players`, [props]);
      }
      return updateOfflinePromise;

    case false:

      if (!props) {
        return;
      }

      return localforage
        .getItem(collection)
        .then((players) => {
          players = JSON.parse(players);

          const tmp = players || [];
          tmp.push(props);
          return localforage.setItem(collection, JSON.stringify(tmp));
        });
  }
}
