import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { connect } from 'react-redux';

import Icon from '../../../../Icon';
import NotesList from '../notes-list';
import { updateNotesFilter, createNote, selectNote } from '../../../../../actions/notes';
import { addToFavorite } from '../../../../../actions/favorites';
import './notes-aside.scss';

import { withTranslation } from 'react-i18next';

@withTranslation()
@connect((state) => ({
  favorites: state.favorites.list,
  search: state.notes.filter.search,
  filteredNotes: state.notes.filteredNotes,
  user: state.auth.user,
  filter: state.notes.filter,
  notes: state.notes.notes,
  selectedNote: state.notes.selectedNote,
  emptyNote: state.notes.emptyNote,
  playerOptions: state.notes.dictionaries.playersOptions,
}), {
  updateNotesFilter, createNote, addToFavorite, selectNote,
})
export default class NotesAside extends Component {
  constructor(props) {
    super(props);

    this.createNote = this.createNote.bind(this);
  }

  componentDidUpdate(prevProps) {
    const {
      emptyNote, notes, player_id, selectedNote, i18n,
    } = this.props;

    const lng = i18n.language;

    const isPlayerHaveNotes = notes.filter((n) => n.player_id === player_id).length;
    if ((!notes.length || (player_id && !isPlayerHaveNotes)) && !selectedNote) {
      this.props.selectNote({
        ...emptyNote,
        title: emptyNote[`title_${lng}`] || emptyNote.title,
        value: emptyNote[`value_${lng}`] || emptyNote.value,
      });
    }
  }

  render() {
    const {
      height, filteredNotes, emptyNote, notes, player_id, filter, t, lng,
    } = this.props;

    const list = [...filteredNotes];

    const isPlayerHaveNotes = list.length;

    if ((!notes.length || (player_id && !isPlayerHaveNotes)) && !filter.search) {
      list.push({
        ...emptyNote,
        title: emptyNote[`title_${lng}`] || emptyNote.title,
        value: emptyNote[`value_${lng}`] || emptyNote.value,
      });
    }

    return (
      <div className="notes-aside">
        <div className="notes-add">
          <div className="search-field-container">
            <input
              type="text"
              name="search"
              placeholder={t('SEARCH')}
              className="search-field"
              id="NotesSearch"
              value={this.props.search}
              onChange={(e) => { this.props.updateNotesFilter({ search: e.target.value }); }}
            />
            <label htmlFor="NotesSearch"><Icon name="search" /></label>
          </div>
          <button
            className="note-add-button"
            onClick={this.createNote}
          />
        </div>
        <Scrollbars style={{ height }} autoHide>
          <div className="notes-aside__wrapper">
            <NotesList notes={list} player_id={player_id} />
          </div>
        </Scrollbars>
      </div>
    );
  }

  createNote() {
    const {
      user, filter, playerOptions, player_id, favorites,
    } = this.props;

    if (!playerOptions.length && !player_id) {
      alert('Please add some favorite players, or start creating notes on player profile page');
      return;
    }

    const usr = {
      _id: user._id,
      firstName: user.firstName,
      lastName: user.lastName,
      name: `${user.firstName} ${user.lastName}`,
    };

    this.props.createNote(usr, player_id);
  }
}
