import store from '../store';
import _ from 'lodash';

import { ROOT_URL } from '../actions';
import { MATCHES_COLLECTION } from './constants';
import axios from 'axios/index';

import localforage from 'localforage';

// todo: matchupdate in matches list
export default function (match_id, action, props) {
  const mode = window.online ? 'online' : 'offline';

  let user_id = null;

  const gender = localStorage.getItem('selectedGender');
  const collectionName = `${MATCHES_COLLECTION}_${gender}`;

  if (store) {
    const s = store.getState();
    user_id = _.get(s, 'auth.user._id', null);
  }

  const localCollection = `history-${collectionName}-${user_id}`;

  switch (window.online) {
    case true:
      return axios.post(`${ROOT_URL}/history`, {
        user_id, match_id, action, props, mode,
      });
    case false:
      return localforage.getItem(localCollection).then((collection) => {
        collection = JSON.parse(collection);
        if (!collection) { collection = []; }
        collection.push({
          user_id, match_id, action, props: { ...props, date: new Date() }, mode,
        });
        return localforage.setItem(localCollection, JSON.stringify(collection));
      });
  }
}
