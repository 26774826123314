import axios from 'axios';
import localforage from 'localforage';
import _ from 'lodash';

import { ROOT_URL } from '../actions';

export default function (props) {
  const gender = localStorage.getItem('selectedGender');
  const collectionName = `leagues_${gender}`;
  switch (window.online) {
    case true:
      return axios
        .get(`${ROOT_URL}/leagues`, {
          params: props,
        })
        .then((res) => {
          const leagues = _.keyBy(res.data.leagues, 'id');
          localforage.setItem(collectionName, JSON.stringify(leagues));
          return Promise.resolve({ leagues });
        })
        .catch((err) => {
          console.error(err);
        });

    case false:
      return localforage
        .getItem(collectionName)
        .then((leagues) => Promise.resolve({ leagues: JSON.parse(leagues) }))
        .catch((err) => {
          console.error(err);
        });
  }
}
