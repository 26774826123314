import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './checkbox.scss';

class Checkbox extends Component {
  render() {
    const { isChecked } = this.props;
    return (
      <span className={`r-checkbox ${isChecked ? 'checked' : ''}`} onClick={this.props.onClick}>
        <span className={`round-checkmark ${isChecked ? 'checked' : ''}`} />
      </span>
    );
  }
}

Checkbox.propTypes = {
  isChecked: PropTypes.bool,
  onClick: PropTypes.func,
};

export default Checkbox;
