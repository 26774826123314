import axios from 'axios';
import { browserHistory } from 'react-router';
import moment from 'moment';
import i18next from '../i18n';
import eventTracker, { EVENTS } from '../helpers/event-tracker';

import { ROOT_URL } from '.';

export const AUTH_LOGIN_REQUEST_SEND = 'AUTH_LOGIN_REQUEST_SEND';
export const AUTH_LOGIN_REQUEST_SUCCESS = 'AUTH_LOGIN_REQUEST_SUCCESS';
export const AUTH_LOGIN_REQUEST_ERROR = 'AUTH_LOGIN_REQUEST_ERROR';
export const AUTH_REGISTER_REQUEST_SEND = 'AUTH_REGISTER_REQUEST_SEND';
export const AUTH_REGISTER_REQUEST_SUCCESS = 'AUTH_REGISTER_REQUEST_SUCCESS';
export const AUTH_REGISTER_REQUEST_ERROR = 'AUTH_REGISTER_REQUEST_ERROR';
export const ACCOUNT_DELETE = 'ACCOUNT_DELETE';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const PASSWORD_RESET = 'PASSWORD_RESET';
export const SET_PRIVACY = 'SET_PRIVACY';
export const REGISTER = 'REGISTER';
export const TRANSFER_ACCOUNT = 'TRANSFER_ACCOUNT';
export const UPDATE_FAVORITE_SORTS = 'UPDATE_FAVORITE_SORTS';
export const UPDATE_WATCHLIST_SORTS = 'UPDATE_WATCHLIST_SORTS';
export const UPDATE_SURVEY = 'UPDATE_SURVEY';

export const UPDATE_SHADOW_TEAMS_TABS = 'UPDATE_SHADOW_TEAMS_TABS';
export const UPDATE_FAVORITES_TABS = 'UPDATE_FAVORITES_TABS';

export const UPDATE_USER = 'UPDATE_USER';

export function updateSurvey(reasons) {
  axios.post(`${ROOT_URL}/group/survey`, reasons);
  return {
    type: UPDATE_SURVEY,
    payload: reasons,
  };
}

export function updateUser(data) {
  return {
    type: UPDATE_USER,
    payload: data,
  };
}

export function transferAccount() {
  const request = axios.post(`${ROOT_URL}/auth/transfer`);

  eventTracker.trackEvent('Auth', 'Transfer account');

  return {
    type: TRANSFER_ACCOUNT,
    payload: request,
  };
}

export function deleteAccount(password) {
  const request = axios.post(`${ROOT_URL}/auth/delete`, { password });

  eventTracker.trackEvent('Auth', 'Delete account');

  return {
    type: ACCOUNT_DELETE,
    payload: request,
  };
}

export function sendLoginRequest(userData) {
  return function (dispatch) {
    dispatch({
      type: AUTH_LOGIN_REQUEST_SEND,
    });

    axios
      .post(`${ROOT_URL}/auth/login`, { ...userData, isWeb: true })
      .then((result) => {
        const { user, success } = result.data;

        if (success) {
          if (window.dataLayer) {
            window.dataLayer.push({
              event: 'logIn',
            });
          }
          dispatch({
            type: AUTH_LOGIN_REQUEST_SUCCESS,
            payload: user,
          });

          eventTracker.trackEvent('Auth', 'Login');

          if (browserHistory.getCurrentLocation().query && browserHistory.getCurrentLocation().query.next) {
            browserHistory.replace(browserHistory.getCurrentLocation().query.next);
          } else {
            browserHistory.replace('/');
          }
        } else {
          eventTracker.trackEvent('Auth', `Login error - ${result.message}`);
          dispatch({
            type: AUTH_LOGIN_REQUEST_ERROR,
            payload: result.message,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        eventTracker.trackEvent('Auth', `Login error - ${error.response.data.message}`);
        dispatch({
          type: AUTH_LOGIN_REQUEST_ERROR,
          payload: error.response.data.message,
        });
      });
  };
}

export function logout() {
  const request = axios.get(`${ROOT_URL}/auth/logout`);

  eventTracker.trackEvent('Auth', 'Logout');

  return {
    type: AUTH_LOGOUT,
    payload: request,
  };
}

export function checkAuth() {
  return function (dispatch) {
    axios
      .get(`${ROOT_URL}/auth/iAm`)
      .then((result) => {
        const { user, success } = result.data;

        if (user && user.lang) {
          i18next.changeLanguage(user.lang);
          moment.locale(user.lang);
        }

        if (success) {
          if (window) {
            window.intercomSettings = {
              app_id: 'd22mu9n4',
            };

            if (window.Intercom) {
              window.Intercom('update', {
                name: `${user.firstName} ${user.lastName}`,
                email: user.email,
                group: user.group.name,
                role: user.role.name,
                id: user._id,
                just_registered: false,
                product_id: 'pad',
                job_title: user.position,
                user_hash: user.intercom_user_hash,
              });
            }
          }

          dispatch({
            type: AUTH_LOGIN_REQUEST_SUCCESS,
            payload: user,
          });
        } else {
          if (browserHistory.getCurrentLocation().pathname && browserHistory.getCurrentLocation().pathname.indexOf('auth') === -1) {
            browserHistory.replace({
              pathname: '/auth/login',
              query: {
                next: browserHistory.getCurrentLocation().pathname,
              },
            });
          } else if (browserHistory.getCurrentLocation().pathname.indexOf('auth') !== -1 && browserHistory.getCurrentLocation().pathname.indexOf('reset') !== -1) {
            browserHistory.replace({
              pathname: '/auth/reset',
              query: {
                to: browserHistory.getCurrentLocation().query.to || '',
              },
            });
          } else {
            browserHistory.replace('/auth/login');
          }
          new Error('Not Logged In');
        }
      })
      .catch((error) => {
        console.error(error);

        dispatch({ type: AUTH_LOGOUT });
        window.location = '/auth/login';
      });
  };
}

export function sendRegisterRequest(userData) {
  return function (dispatch) {
    dispatch({
      type: AUTH_REGISTER_REQUEST_SEND,
    });

    eventTracker.trackEvent('Auth', 'Register group member');

    axios
      .post(`${ROOT_URL}/auth/register`, { user: userData })
      .then((result) => {
        const { status } = result;

        if (status === 200) {
          dispatch({
            type: AUTH_REGISTER_REQUEST_SUCCESS,
          });
        } else {
          dispatch({
            type: AUTH_REGISTER_REQUEST_ERROR,
            payload: result.data.message,
          });
        }
      })
      .catch((error) => {
        console.error(error);

        dispatch({
          type: AUTH_REGISTER_REQUEST_ERROR,
          payload: error.response.data.message,
        });
      });
  };
}

export function resetPassword({
  email, password, token, mode,
}) {
  const body = {};
  if (email) body.email = email;
  if (token) body.token = token;
  if (password) body.password = password;
  body.mode = mode;

  const request = axios.post(`${ROOT_URL}/auth/forgot`, body);

  eventTracker.trackEvent('Auth', 'Reset password');

  return {
    type: PASSWORD_RESET,
    payload: request,
  };
}

export function setPrivacy(privacy) {
  const request = axios.post(`${ROOT_URL}/auth/privacy`, { isAgree: privacy });

  eventTracker.trackEvent('Auth', 'Accept privacy');

  return {
    type: SET_PRIVACY,
    payload: request,
  };
}

export function registration(form) {
  const request = axios.post(`${ROOT_URL}/registration`, form).then((result) => {
    const { payload } = result.data;

    if (result.data.status !== 'error') {
      const { user, newGroup } = payload;

      eventTracker.trackEvent('Auth', 'Registration');

      if (window.Intercom) {
        window.Intercom('update', {
          name: `${user.firstName} ${user.lastName}`,
          email: user.email,
          group: newGroup.name,
          role: 'Group admin',
          id: user._id,
          just_registered: true,
          set_password: false,
          product_id: 'pad',
          job_title: user.position,
        });
      }
    }

    return result;
  });

  return {
    type: REGISTER,
    payload: request,
  };
}

export function updateFavoriteSorts(id, sorts, isGroup) {
  let request;
  if (isGroup) {
    request = axios.put(`${ROOT_URL}/users/${id}`, { favoritesSort: sorts });
  } else {
    request = axios.put(`${ROOT_URL}/users/${id}`, { userFavoritesSort: sorts });
  }

  eventTracker.trackEvent('Watchlist', 'Update players sort');

  return {
    type: UPDATE_FAVORITE_SORTS,
    payload: isGroup ? { favoritesSort: sorts } : { userFavoritesSort: sorts },
  };
}

export function updateWatchlistsSorts(id, sorts) {
  const request = axios.put(`${ROOT_URL}/users/${id}`, { watchlistsSort: sorts });

  eventTracker.trackEvent('Watchlists', 'Update watchlists sort');

  return {
    type: UPDATE_WATCHLIST_SORTS,
    payload: { watchlistsSort: sorts },
  };
}

export function updateFavoriteTabs(id, tabs) {
  const request = axios.put(`${ROOT_URL}/users/${id}`, { favoritesTabs: tabs });

  return {
    type: UPDATE_FAVORITES_TABS,
    payload: tabs,
  };
}

export function updateShadowTeamsTab(id, tabs) {
  const request = axios.put(`${ROOT_URL}/users/${id}`, { shadowTeamsTabs: tabs });

  return {
    type: UPDATE_SHADOW_TEAMS_TABS,
    payload: tabs,
  };
}
