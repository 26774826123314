import axios from 'axios';
import { ROOT_URL } from '.';

export const FETCH_COUNTRIES = 'FETCH_COUNTRIES';

export function fetchAllCountries() {
  const request = axios.get(`${ROOT_URL}/countries`);

  return {
    type: FETCH_COUNTRIES,
    payload: request,
  };
}
