import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import './Login.scss';

import Lottie from 'lottie-react';
import { resetPassword, sendLoginRequest } from '../../actions/auth';
import { default as animationData } from '../loader.json';

@withTranslation()
@connect((state) => ({
  signInError: state.auth.signInError,
}), { resetPassword, sendLoginRequest })

class Login extends Component {
  static propTypes = {
    resetPassword: PropTypes.func,
    sendLoginRequest: PropTypes.func,
    to: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      state: null,
      successEmail: false,
      successPassword: false,
      errorEmail: false,
      errorToken: false,
      changePassword: {
        newPassword: '',
        repeatPassword: '',
        error: false,
      },
      step: 0,
    };

    this.submitEmail = this.submitEmail.bind(this);
    this.submitPassword = this.submitPassword.bind(this);
    this.renderAuthContainer = this.renderAuthContainer.bind(this);
  }

  componentDidMount() {
    const { to, resetPassword } = this.props;

    if (to) {
      resetPassword({ token: to })
        .then((res) => {
          if (!res.payload.data.success) {
            this.setState({ errorToken: true });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  onInputPassword(value, input) {
    const { changePassword } = this.state;

    this.setState({
      changePassword: {
        ...changePassword,
        [input]: value,
        error: false,
      },
    });
  }

  submitPassword(event) {
    event.preventDefault();

    const { changePassword } = this.state;
    const {
      resetPassword, to, t, mode, email, id,
    } = this.props;

    if (changePassword.newPassword.length < 6 || changePassword.repeatPassword < 6) {
      this.setState({ changePassword: { ...changePassword, error: t('PLEASE_ENTER_AT_LEAST_N_CHARACTERS', { n: 6 }) } });
    } else if (changePassword.newPassword !== changePassword.repeatPassword) {
      this.setState({ changePassword: { ...changePassword, error: t('PLEASE_ENTER_SAME_PASSWORD') } });
    } else {
      // this.setState({ changePassword: { ...changePassword, error: false }, successPassword: true });
      resetPassword({
        password: changePassword.newPassword,
        token: to,
        mode,
      }).then((res) => {
        if (res.payload.data.success) {
          // login user;
          if (mode === 'create' && email) {
            this.setState({ state: 'loading' });
            if (window.Intercom && id && email) {
              window.Intercom('update', {
                id,
                email,
                set_password: true,
              });
            }
            setTimeout(() => {
              // this.setState({ state: null });
              this.props.sendLoginRequest({ email: decodeURIComponent(email), password: changePassword.newPassword });
            }, 4000);
          } else {
            this.setState({ changePassword: { ...changePassword, error: false }, successPassword: true });
          }
        } else {
          this.setState({ changePassword: { ...changePassword, error: res.payload.data.message }, successPassword: false });
        }
      });
    }
  }

  submitEmail(event) {
    event.preventDefault();

    const email = this.email.value.toLowerCase().trim();
    const { resetPassword } = this.props;

    resetPassword({ email })
      .then((res) => {
        if (res.payload.data.success) {
          this.setState({ successEmail: true });
        } else {
          this.setState({ errorEmail: res.payload.data.message });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  renderAuthContainer() {
    const {
      errorToken, successPassword, changePassword, successEmail, errorEmail,
    } = this.state;
    const { to, t, mode } = this.props;

    if (errorToken) {
      return (
        <div>
          <div className="auth-title">{t('LINK_ISNT_WORKING')}</div>
          <p className="auth-text">{t('LINK_ISNT_WORKING_EXPLAIN')}</p>
        </div>
      );
    }
    if (successPassword) {
      return (
        <div>
          <div className="auth-title">{t('PASSWORD_UPDATED')}</div>
          <p className="auth-text">{t('PASSWORD_UPDATED_EXPLAIN')}</p>
        </div>
      );
    } if (to) {
      return (
        <div>
          <div className="auth-title">{t('SET_PASSWORD')}</div>
          <form className="auth-login" onSubmit={this.submitPassword}>
            <div className="input">
              <label>{t('SET_PASSWORD')}</label>
              <input type="password" value={changePassword.newPassword} onChange={(event) => this.onInputPassword(event.target.value, 'newPassword')} placeholder={t('PASSWORD')} />
            </div>
            <div className="input">
              <label><label>{t('REPEAT_PASSWORD')}</label></label>
              <input type="password" value={changePassword.repeatPassword} onChange={(event) => this.onInputPassword(event.target.value, 'repeatPassword')} placeholder={t('PASSWORD')} />
            </div>
            <div className={`error-message ${changePassword.error ? 'visible' : ''}`}>{ changePassword.error }</div>
            <button className="btn btn-block" type="submit">{ mode === 'create' ? t('CREATE_ACCOUNT') : t('CHANGE_PASSWORD')}</button>
          </form>
        </div>
      );
    } if (successEmail) {
      return (
        <div>
          <div className="auth-title">{t('EMAIL_SENT')}</div>
          <p className="auth-text">{t('EMAIL_SENT_EXPLAIN')}</p>
        </div>
      );
    }
    return (
      <div>
        <div className="auth-title">{t('PASSWORD RESET')}</div>
        <p className="auth-text" dangerouslySetInnerHTML={{ __html: t('RESET_TEXT', { site_name: 'scoutpad.de' }) }} />
        <form className="auth-login" onSubmit={this.submitEmail}>
          <input type="email" ref={(c) => { this.email = c; }} placeholder="E-mail" required />
          <div className={`error-message ${errorEmail ? 'visible' : ''}`}>{ errorEmail }</div>
          <button className="btn btn-block" type="submit">{t('GET_RESET_LINK')}</button>
        </form>
      </div>
    );
  }

  render() {
    const { t, mode } = this.props;
    const { state } = this.state;

    return (
      <div className="wrapper">
        <div className="auth-logo">
          <div className="top-shade" />
          <img src="/images/logo-full.svg" width="196" height="57" />
        </div>
        <div className="auth-align">
          <div className="auth-container">
            { this.renderAuthContainer() }
            {
              mode !== 'create' ? (
                <div className="auth-helpers clearfix">
                  <Link to="/auth/login" className="pull-left">{t('SIGNIN')}</Link>
                  <a className="pull-right" href="mailto:support@scoutpad.de">{t('CONTACT')}</a>
                </div>
              ) : null
            }
          </div>
        </div>
        {
          state === 'loading' ? (
            <div id="Loader">
              <div className="loader-image">
                <Lottie
                  loop
                  autoplay
                  animationData={animationData}
                  style={{
                    height: 120,
                    width: 120,
                  }}
                  preserveAspectRatio="xMidYMid slice"
                />
              </div>
            </div>
          ) : null
        }
      </div>
    );
  }
}

export default Login;
