import './snapshots.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';

import Modal from 'react-modal';
import Icon from '../../../Icon';

import { changeSnapshot, createSnapshot, removeSnapshot } from '../../../../actions/match';

@withTranslation()
@connect((state) => ({
  match: state.match,
}), { changeSnapshot, createSnapshot, removeSnapshot })

class Lineups extends Component {
  static propTypes = {
    changeSnapshot: PropTypes.func,
    createSnapshot: PropTypes.func,
    removeSnapshot: PropTypes.func,
    match: PropTypes.object,
  };

  constructor() {
    super();

    this.state = {
      modalIsOpen: false,
      newSnapshotName: '',
      error: false,
    };

    this.renderTabs = this.renderTabs.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.removeSnapshotHandler = this.removeSnapshotHandler.bind(this);
    this.changeNewSnapshotName = this.changeNewSnapshotName.bind(this);
    this.createNewSnapshot = this.createNewSnapshot.bind(this);
  }

  removeSnapshotHandler(snapshotName, event) {
    const { t } = this.props;

    event.preventDefault();

    if (window.confirm(t('REMOVE_SNAPSHOT'))) {
      this.props.removeSnapshot(snapshotName);
    }
  }

  renderTabs() {
    const { data, snapshot } = this.props.match;

    if (!_.size(data)) return null;

    const snapshots = _.keys(data.home.positions).filter((screenName) => screenName !== 'lineup');

    return _.map(snapshots, (value, key) => (
      <li key={key} className={`${snapshot === value ? 'active' : ''}`} style={{ maxWidth: `${snapshots.length < 4 ? (100 / (snapshots.length + 2)) : (100 / (snapshots.length + 1))}%` }}>
        <a className="snapshot-name" onClick={() => { this.props.changeSnapshot(value); }}>{ value }</a>
        <a className="remove-snapshot" href="#" onClick={(e) => this.removeSnapshotHandler(value, e)}><Icon name="close" /></a>
      </li>
    ));
  }

  closeModal() {
    this.setState({ modalIsOpen: false, newSnapshotName: '' });
  }

  changeNewSnapshotName(event) {
    this.setState({ newSnapshotName: event.target.value });
  }

  createNewSnapshot(event) {
    event.preventDefault();
    const { createSnapshot, match, t } = this.props;
    let { newSnapshotName } = this.state;

    const snapshotsList = _.keys(match.data.home.positions).filter((screenName) => screenName !== 'lineup');

    if (!newSnapshotName) {
      newSnapshotName = `Snapshot ${snapshotsList.length}`;
    }

    if (_.indexOf(snapshotsList, newSnapshotName) !== -1) {
      this.setState({
        error: t('SNAPSHOT_ALREADY_EXISTS'),
      });
    } else {
      this.setState({
        error: null,
      });
      createSnapshot(newSnapshotName);
      this.closeModal();
    }
  }

  render() {
    const { t } = this.props;
    const { data, snapshot, layout } = this.props.match;
    const { modalIsOpen, newSnapshotName } = this.state;

    if (!_.size(data)) return null;

    const snapshots = _.keys(data.home.positions).filter((screenName) => screenName !== 'lineup');

    return (
      <div className="snapshots">
        <ul style={{ width: layout.height }}>
          <li className={`${snapshot === 'lineup' ? 'active' : ''}`}><a onClick={() => { this.props.changeSnapshot('lineup'); }}>{t('LINEUP')}</a></li>
          { this.renderTabs() }
          { snapshots.length < 4 ? (
            <li className="create">
              <a onClick={() => { this.setState({ modalIsOpen: true }); }}>
                +
                {t('CREATE_SNAPSHOT')}
              </a>
            </li>
          ) : null }
        </ul>

        <Modal
          shouldCloseOnOverlayClick
          onRequestClose={this.closeModal}
          isOpen={modalIsOpen}
          className={{
            base: 'modal',
            afterOpen: 'myClass_after-open',
            beforeClose: 'myClass_before-close',
          }}
          overlayClassName={{
            base: 'modal-overlay',
            afterOpen: 'myOverlayClass_after-open',
            beforeClose: 'myOverlayClass_before-close',
          }}
        >
          <h3 className="modal-title">{t('CREATE_SNAPSHOT')}</h3>
          <p className="modal-subtitle">{t('ENTER_SNAPSHOT_NAME')}</p>
          <form onSubmit={this.createNewSnapshot}>
            <input
              autoFocus
              onChange={this.changeNewSnapshotName}
              value={newSnapshotName}
              type="text"
              placeholder={t('SNAPSHOT_NAME_PLACEHOLDER')}
              className={this.state.error ? 'error' : ''}
            />
            {this.state.error ? <div className="snapshot-error-message">{this.state.error}</div> : null}
            <button className="btn">{t('SAVE')}</button>
          </form>
        </Modal>

      </div>
    );
  }
}

export default Lineups;
