import React from 'react';
import { components } from 'react-select';
import PlayerView from '../player-view';

import './options.scss';

export default function (props) {
  const { innerProps, innerRef, data } = props;
  return (
    <components.Option {...props} className="player-option">
      <PlayerView {...data} />
    </components.Option>
  );
}
