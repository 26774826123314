import React, { useState } from 'react';
import axios from 'axios';
import { withTranslation } from 'react-i18next';

import AsyncCreatableSelect from 'react-select/async-creatable';
import PropTypes from 'prop-types';
import SingleValue from './league-value';
import Option from './league-option';
import GroupHeading from './country-group-heading';
import DropdownIndicator from '../select/components/dropdown-indicator';
import Group from './country-group';
import { theme, styleLeagueSelect } from '../select_props';
import { ROOT_URL } from '../../../actions';

import './league-select.scss';
import LeagueForm from '../league-form';

function LeagueSelect({
  input, t, i18n, countries, emptyOption, ...rest
}) {
  const lng = i18n.language;
  const [inp, setInput] = useState('');
  const [showCreateForm, setShowCreateForm] = useState(false);
  const defaultLeagues = rest.leagues;
  const defaultOptions = defaultLeagues && defaultLeagues.length ? defaultLeagues : true;

  return (
    <>
      <AsyncCreatableSelect
        className={rest.meta.touched && rest.meta.error ? 'no-valid' : ''}
        {...rest}
        name={input.name}
        styles={styleLeagueSelect}
        inputValue={inp}
        onInputChange={(value, action) => {
          if (action?.action !== 'input-blur' && action?.action !== 'menu-close') {
            setInput(value);
          }
        }}
        clearable={false}
        maxHeight={200}
        defaultOptions={defaultOptions}
        placeholder={t('TYPE_TO_SEARCH_LEAGUE')}
        menuShouldScrollIntoView
        hideSelectedOptions
        formatGroupLabel={(data) => {
          const countryCode = _.get(countries, `listById[${data.id}].alpha3code`, null);
          const img = `https://static.sp7f.com/v2/countries/${countryCode}.jpg`;
          return (
            <div className="country-view">
              <div
                className="country-view__img"
                style={{ backgroundImage: `url(${img})` }}
              />
              {data.label}
            </div>
          );
        }}
        components={{
          Option,
          SingleValue,
          GroupHeading,
          Group,
          DropdownIndicator,
        }}
        value={input.value}
        loadOptions={(inputValue) => {
          return axios.get(`${ROOT_URL}/leagues/search`, {
            params: {
              needle: inputValue,
            },
          }).then((result) => {
            const options = _.groupBy(result.data.map((l) => ({
              value: l.id,
              label: _.get(l, `name_${lng}`, _.get(l, 'name', t('UNKNOWN_LEAGUE'))),
              country_id: l.country_id,
              league_id_master: l.league_id_master,
            })), 'country_id');

            let grouped = [];

            for (const key in options) {
              const opts = options[key];
              grouped.push({
                label: _.get(countries, `listById[${key}].name_${lng}`, _.get(countries, `listById[${key}].name`, t('UNKNOWN'))),
                id: key,
                options: opts.sort((a, b) => { return a.label.localeCompare(b.label); }),
              });
            }

            grouped = _.orderBy(grouped, ['label'], ['asc']);
            if (emptyOption && !inputValue) {
              grouped.unshift({
                id: '-',
                label: t('NO_LEAGUE'),
                value: '',
                firstLevel: true,
              });
            }
            return grouped;
          }).catch((err) => {
            console.error(err.message);
            return [];
          });
        }}
        onChange={(value) => {
          rest.changeCallback && rest.changeCallback(value);
          input.onChange(value);
        }}
        theme={theme}
        onCreateOption={(value) => {
          setShowCreateForm(value);
        }}
      />
      {showCreateForm && (
        <LeagueForm
          name={showCreateForm}
          onCreate={(value) => {
            rest.changeCallback && rest.changeCallback(value);
            input.onChange(value);
            setShowCreateForm(null);
          }}
          onCancel={() => {
            setShowCreateForm(null);
          }}
        />
      )}
    </>
  );
}

LeagueSelect.propTypes = {
  emptyOption: PropTypes.bool,
  countries: PropTypes.object,
  i18n: PropTypes.object,
  t: PropTypes.func,
  input: PropTypes.object,
};

export default withTranslation()(LeagueSelect);
