import axios from 'axios';
import { ROOT_URL } from '.';

export const FETCH_RECENTLY_RATED = 'FETCH_RECENTLY_RATED';
export const CHANGE_RECENTLY_RATED_FILTER = 'CHANGE_RECENTLY_RATED_FILTER';
export const RECENTLY_RATED_RESET_FILTER = 'RECENTLY_RATED_RESET_FILTER';

export function fetchRecentlyRated() {
  const request = axios.get(`${ROOT_URL}/recently-rated`);

  return {
    type: FETCH_RECENTLY_RATED,
    payload: request,
  };
}

export function changeRecentlyRatedFilter(filter) {
  return {
    type: CHANGE_RECENTLY_RATED_FILTER,
    payload: filter,
  };
}

export function resetFilter() {
  return {
    type: RECENTLY_RATED_RESET_FILTER,
  };
}
