import axios from 'axios';
import localforage from 'localforage';
import _ from 'lodash';

import { ROOT_URL } from '../actions';

export default function (props) {
  switch (window.online) {
    case true:
      return axios
        .get(`${ROOT_URL}/formations`, {
          params: props,
        })
        .then((res) => {
          const formations = _.keyBy(res.data.formations.map((f) => {
		  	return { ...f, positions: f.positions.filter((f) => f.position_id_detail !== 34) };
		  }), 'id');

          localforage.setItem('formations', JSON.stringify(formations));
          return Promise.resolve({ formations });
        })
        .catch((err) => {
          console.error(err);
        });

    case false:
      return localforage
        .getItem('formations')
        .then((formations) => Promise.resolve({ formations: JSON.parse(formations) }))
        .catch((err) => {
          console.error(err);
        });
  }
}
