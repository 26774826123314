import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import { Link } from 'react-router';
import { useTranslation } from 'react-i18next';
import { fetchOffline } from '../../actions/reports';

import './reports.scss';
import { isGroupUsers } from '../../helpers/limits';
import { getPlayerImage, getTeamImage, getTeamName } from '../../helpers';

function ReportsWrapper({
  children, subscription, user, reports, fetchOffline, teams,
}) {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    fetchOffline();
  }, []);

  const canUseGroup = isGroupUsers(subscription);
  const isAdmin = user.role.tag !== 'user';
  const { offline, offlinePlayers, players } = reports;
  const allPlayers = { ...offlinePlayers, ...players };

  const renderSingle = (match) => {
    const { player_id } = match;
    const player = allPlayers[player_id];
    const img = player && getPlayerImage(player);
    return (
      <>
        <span className="offline-report__player-img" style={{ backgroundImage: `url(${img})` }} />
        {player ? `${player.first_name} ${player.last_name}` : ''}
      </>
    );
  };

  const renderMatch = (match) => {
    const homeTeam = teams[match.home.team_id] || {};
    const awayTeam = teams[match.away.team_id] || {};

    const homeTeamImg = getTeamImage(homeTeam?.id);
    const awayTeamImg = getTeamImage(awayTeam?.id);
    const homeTeamName = getTeamName(homeTeam, i18n.language, t);
    const awayTeamName = getTeamName(awayTeam, i18n.language, t);
    return (
      <>
        <span className="offline-report__match">
          <span className="offline-report__team-img" style={{ backgroundImage: `url(${homeTeamImg})` }} />
          <span className="offline-report__team-img" style={{ backgroundImage: `url(${awayTeamImg})` }} />
        </span>
        {`${homeTeamName} ${awayTeamName}`}
      </>
    );
  };

  const renderOffline = (offline, index) => {
    return (
      <li className="offline-report" key={offline.match_id}>
        <Link to={`/match/${offline.match_id}`}>
          { offline.type === 'single' ? renderSingle(offline) : renderMatch(offline) }
        </Link>
      </li>
    );
  };

  return (
    <div className="sub-nav">
      <div className="sub-nav__navigation reports">
        <Scrollbars autoHide>
          <div className="pad">
            <h2>{t('REPORTS')}</h2>
            <ul>
              <li className="overview-link"><Link to="/reports/" activeClassName="active" onlyActiveOnIndex>{t('OVERVIEW')}</Link></li>
              <li><Link to="/reports/coming-up/" activeClassName="active">{t('COMING_UP')}</Link></li>
              <li><Link to="/reports/team/" activeClassName="active">{t('TEAM_REPORTS')}</Link></li>
              <li><Link to="/reports/players/" activeClassName="active">{t('PLAYER_REPORTS')}</Link></li>
              { canUseGroup && isAdmin ? (<li><Link to="/reports/group/" activeClassName="active">{t('REPORTS_OF_MY_GROUP')}</Link></li>) : null }
              { canUseGroup ? <li><Link to="/reports/assigned/" activeClassName="active">{t('ASSIGNED_REPORTS')}</Link></li> : null }
              {offline.length ? <li className="overview-divider-title">{t('RECENTLY_OPENED')}</li> : null}
              {offline.length ? offline.map(renderOffline) : null}
            </ul>
          </div>
        </Scrollbars>
      </div>
      <div className="sub-nav__content reports">
        <Scrollbars autoHide>
          <div className="container reports-container">
            { children }
          </div>
        </Scrollbars>
      </div>
    </div>
  );
}

ReportsWrapper.propType = {
  chidren: PropTypes.any,
  subscription: PropTypes.object,
  user: PropTypes.object,
  reports: PropTypes.object,
  fetchOffline: PropTypes.func,
  teams: PropTypes.object,
};

export default connect((state) => {
  return {
    reports: state.reports,
    subscription: state.subscription,
    user: state.auth.user,
    teams: state.teams,
  };
}, { fetchOffline })(ReportsWrapper);
