import storageMatches from './storageMatches.js';
import storageMatch from './storageMatch.js';
import storageMatchUpdate from './storageMatchUpdate.js';
import storagePositions from './storagePositions.js';
import storageDeleteMatch from './storageDeleteMatch.js';
import storageAddMatch from './storageAddMatch.js';
import storageTeams from './storageTeams.js';
import storageStadiums from './storageStadiums.js';
import storageLeagues from './storageLeagues.js';
import storageIndicators from './storageIndicators.js';
import autoUpdate from './autoUpdate.js';
import storageFormations from './storageFormations.js';
import storageMatchHistory from './storageMatchHistory';
import storageEvaluations from './storageEvaluations';
import storageEvaluationsDelete from './storageEvaluationsDelete';
import storagePlayers from './storagePlayers';

/* TODO: Catch Internet connection */

autoUpdate();

export {
  storageMatches,
  storageMatch,
  storageMatchUpdate,
  storagePositions,
  storageDeleteMatch,
  storageAddMatch,
  storageTeams,
  storageStadiums,
  storageLeagues,
  storageIndicators,
  storageFormations,
  storageMatchHistory,
  storageEvaluations,
  storageEvaluationsDelete,
  storagePlayers,
};
