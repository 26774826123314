import React from 'react';
import { components } from 'react-select';
import Icon from '../../../Icon';

import './group-heading.scss';

export default function (props) {
  const { handleHeaderClick } = props;
  const { isOpen } = props;

  const newProps = { ...props };

  delete newProps.handleHeaderClick;
  delete newProps.isOpen;

  return (
    <div onClick={() => handleHeaderClick(props.id)} className="dd-group-heading">
      <div className="dd-heading">
        <components.GroupHeading {...newProps} />
      </div>
      <div className="dd-indicator-container">
        <Icon name="icon-chevron-league-select" className={`dd-indicator ${isOpen ? 'dd-indicator-open' : ''}`} />
      </div>
    </div>
  );
}
