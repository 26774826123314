import axios from 'axios';

import { ROOT_URL } from '.';

export const FETCH_SUBSCRIPTION = 'FETCH_SUBSCRIPTION';
export const UPDATE_FAVORITE_LIMITS = 'UPDATE_FAVORITE_LIMITS';
export const UPDATE_REPORTS_LIMITS = 'UPDATE_REPORTS_LIMITS';
export const UPDATE_USERS_LIMITS = 'UPDATE_USERS_LIMITS';
export const UPDATE_SHADOW_TEAM_LIMITS = 'UPDATE_SHADOW_TEAM_LIMITS';
export const UPDATE_WATCHLISTS_LIMITS = 'UPDATE_WATCHLISTS_LIMITS';

export function fetchSubscription() {
  const request = axios.get(`${ROOT_URL}/subscription`);

  return {
    type: FETCH_SUBSCRIPTION,
    payload: request,
  };
}

export function updateFavoriteLimits(props) {
  return {
    type: UPDATE_FAVORITE_LIMITS,
    payload: props,
  };
}
export function updateReportsLimits(props) {
  return {
    type: UPDATE_REPORTS_LIMITS,
    payload: props,
  };
}
export function updateUserLimits(props) {
  return {
    type: UPDATE_USERS_LIMITS,
    payload: props,
  };
}

export function updateShadowTeamLimits(props) {
  return {
    type: UPDATE_SHADOW_TEAM_LIMITS,
    payload: props,
  };
}

export function updateWatchlistsLimits(props) {
  return {
    type: UPDATE_WATCHLISTS_LIMITS,
    payload: props,
  };
}
