import localeEn from 'date-fns/locale/en-GB';
import localeDe from 'date-fns/locale/de';
import localePt from 'date-fns/locale/pt';

import formatRelative from 'date-fns/formatRelative';

import _ from 'lodash';
import moment from 'moment/moment';
import formatDate from 'date-fns/format';

export const getLastTimeLine = function (timelines, timeline) {
  const times = _
    .chain(timelines)
    .keys()
    .map((time) => +time)
    .orderBy((time) => time, 'desc')
    .value();

  for (let i = 0, l = times.length; i < l; i++) {
    if (times[i] <= timeline) {
      return times[i];
    }
  }
};

export const getWindowHeight = () => document.documentElement.clientHeight ? document.documentElement.clientHeight : window.innerHeight;

export const getWindowWidth = () => document.documentElement.clientWidth ? document.documentElement.clientWidth : window.innerWidth;

export const outerControlLabel = (key) => {
  const intKey = +key;

  switch (intKey) {
    case -2: return '––';
    case -1: return '–';
    case 0: return '0';
    case 1: return '+';
    case 2: return '++';
    default: return null;
  }
};

export const parsePlusTime = (time) => {
  const period = parseInt(time.slice(0, 1));
  const min = parseInt(time.slice(1, 4));

  if (period === 1 && min > 45) {
    return `45' + ${min - 45}`;
  } if (period === 2 && min > 90) {
    return `90' + ${min - 90}`;
  }
  if (min < 1) return "01'";
  return `${`000${min}`.substr(min < 100 ? -2 : -3)}'`;
};

export function getPosition(pos) {
  if (!pos) {
    return { x: 0, y: 0 };
  }

  const firstChar = pos.charAt(0);
  const secondChar = pos.charAt(1);
  const thirdChar = pos.charAt(2);
  let positionX = 0;
  let positionY = 0;

  if (pos === 'GK') positionX = 1 / 2;
  else if (thirdChar !== '' && thirdChar !== 'B') {
    if (thirdChar === 'L') positionX = 1 / 6 * 1.9;
    else if (thirdChar === 'C') positionX = 1 / 2;
    else if (thirdChar === 'R') positionX = 1 / 6 * 4.1;
  } else if (firstChar === 'L') positionX = 1 / 6 * 0.6;
  else if (firstChar === 'R') positionX = 1 / 6 * 5.4;
  else if (firstChar === 'C') positionX = 1 / 2;

  if (pos === 'GK') positionY = 1 - 1 / 4 * 0.2;
  else if ((firstChar + secondChar) === 'CB') positionY = 1 - 1 / 4 * 0.8;
  else if ((firstChar + secondChar) === 'DM') positionY = 1 - 1 / 4 * 1.4;
  else if ((firstChar + secondChar) === 'CM') positionY = 1 - 1 / 2;
  else if ((firstChar + secondChar) === 'AM') positionY = 1 - 1 / 4 * 2.5;
  else if ((firstChar + secondChar) === 'FW') positionY = 1 - 1 / 4 * 3.3;
  else if (pos === 'LWB' || pos === 'RWB') positionY = 1 - 1 / 4 * 1.3;
  else if (secondChar === 'B') positionY = 1 - 1 / 4 * 0.9;
  else if (secondChar === 'M') positionY = 1 - 1 / 2;
  else if (secondChar === 'W') positionY = 1 - 1 / 4 * 3;

  return { x: positionX, y: positionY };
}

export function getPositionShadowTeam(pos, formation) {
  if (!pos) {
    return { x: 0, y: 0 };
  }

  // fine tune from Michael
  if (formation === '4-2-3-1') {
    if (pos === 'LM') pos = 'LW';
    if (pos === 'RM') pos = 'RW';
  }

  if (formation === '4-1-3-2') {
    if (pos === 'CMC') pos = 'AMC';
  }

  if (formation === '4-2-4-0') {
    if (pos === 'CML') pos = 'DML';
    if (pos === 'CMR') pos = 'DMR';
    if (pos === 'FWR') pos = 'AMR';
    if (pos === 'FWL') pos = 'AML';
  }

  if (formation === '4-2-2-2') {
    if (pos === 'CML') pos = 'AML';
    if (pos === 'CMR') pos = 'AMR';
  }

  const firstChar = pos.charAt(0);
  const secondChar = pos.charAt(1);
  const thirdChar = pos.charAt(2);
  let positionX = 0;
  let positionY = 0;

  if (pos === 'GK') positionX = 1 / 2;
  else if (thirdChar !== '' && thirdChar !== 'B') {
    if (thirdChar === 'L') positionX = 1 / 6 * 1.9;
    else if (thirdChar === 'C') positionX = 1 / 2;
    else if (thirdChar === 'R') positionX = 1 / 6 * 4.1;
  } else if (firstChar === 'L') positionX = 1 / 6 * 0.6;
  else if (firstChar === 'R') positionX = 1 / 6 * 5.4;
  else if (firstChar === 'C') positionX = 1 / 2;

  if (pos === 'GK') positionY = 1;
  else if ((firstChar + secondChar) === 'CB') positionY = 1 - 1 / 4 * 0.8;
  else if ((firstChar + secondChar) === 'DM') positionY = 1 - 1 / 4 * 1.7;
  else if ((firstChar + secondChar) === 'CM') positionY = 1 - 1 / 2 * 1.1;
  else if ((firstChar + secondChar) === 'AM') positionY = 1 - 1 / 4 * 2.6;
  else if ((firstChar + secondChar) === 'FW') positionY = 1 - 1 / 4 * 3.45;
  else if (pos === 'LWB' || pos === 'RWB') positionY = 1 - 1 / 4 * 1.3;
  else if (secondChar === 'B') positionY = 1 - 1 / 4 * 0.9;
  else if (secondChar === 'M') positionY = 1 - 1 / 2 * 1.1;
  else if (secondChar === 'W') positionY = 1 - 1 / 4 * 3;

  return { x: positionX, y: positionY };
}

export function getPositionMatch(pos, align) {
  const { x: y, y: x } = getPosition(pos);

  switch (align) {
    case 'home':
      return { x: -(x - 1) / 2, y };
    case 'away':
      return { x: (x + 1) / 2, y: -(y - 1) };
    default:
      return { x: null, y: null };
  }
}

export function validateEmail(email) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function validateText(text, required, minLength, maxLength) {
  if (!text && required) {
    return false;
  }

  if (minLength) {
    if (text.length < minLength) {
      return false;
    }
  }

  if (maxLength) {
    if (text.length > maxLength) {
      return false;
    }
  }

  return true;
}

export function getSeasonFromDate(date) {
  const readyDate = moment(date);
  const year = readyDate.format('YYYY');
  const month = readyDate.get('month');
  let season = '';

  if (month > 5) {
    season = `${+year}`;
  } else {
    season = `${+year - 1}`;
  }

  return season;
}

export function getSeasonFromId(season_id) {
  return season_id;
}

export function getSeasonIdFromDate(date) {
  const readyDate = moment(date);
  const month = readyDate.get('month');

  if (month > 5) {
    return +readyDate.format('YYYY');
  }
  return +readyDate.format('YYYY') - 1;
}

export function getCurrencySign(currency) {
  return currency === 'eur' ? String.fromCharCode(8364) : '$';
}

export function getPaymentMethodName(pm, noSign) {
  if (pm === 'sepa_debit') return 'SEPA Debit';
  if (pm === 'card') return 'Card';
  return noSign;
}

export function renderDate(date, format = 'DD.MM.YYYY', invalid = '—') {
  if (date) {
    const mdate = moment(date);
    return mdate.isValid() ? mdate.format(format || 'DD.MM.YYYY') : invalid;
  }
  return invalid;
}

export function insertAndShift(data, src, dest) {
  if (src === dest) { return; }

  const tmp = data[src];
  if (src < dest) {
    data.copyWithin(src, src + 1, dest + 1);
  } else /* if (src > dest) */ {
    data.copyWithin(dest + 1, dest, src);
  }
  data[dest] = tmp;
}

export function getWatchlistCategory(favorite, t) {
  const categories = [t('CAT_NO_CATEGORY'), t('CAT_NOTABLE'), t('CAT_PERSPECTIVE'), t('CAT_FOCUS'), t('CAT_IMMEDIATELY')];

  let category = 0;
  if (favorite) {
    if (!isNaN(favorite.category)) {
      category = favorite.category + 1 || 1;
    }
  }

  return categories[category];
}

export function compareCurrentSubscriptionWithPlan(subscription, nextPlan) {
  // subscription: favorites, reports, scouts, shadowTeams, watchlists ( {current, max: "INFINITY",  } ), xmlExport (true, false)
  // nextPlan: watchlist, watchlists ("0" - INFINITY,  "-1" - Disabled), reports, scouts, shadowTeams, xml_exprot ("true", "false")

  const compareProps = [
    {
      planName: 'scouts',
      subName: 'scouts',
      type: 'Number',
      localizedString: 'SCOUTS',
    },
    {
      planName: 'reports',
      subName: 'reports',
      type: 'Number',
      localizedString: 'REPORTS',
    },
    {
      planName: 'watchlist',
      subName: 'favorites',
      type: 'Number',
      localizedString: 'LIMIT_FAVORITES',
    },
    {
      planName: 'shadowTeams',
      subName: 'shadowTeams',
      type: 'Number',
      localizedString: 'SHADOW_TEAMS',
    },
    {
      planName: 'watchlists',
      subName: 'watchlists',
      type: 'Number',
      localizedString: 'WATCHLISTS',
    },
    {
      planName: 'xml_export',
      subName: 'xmlExport',
      type: 'Bool',
      localizedString: 'XML_EXPORT',
    },
  ];

  const result = [];

  compareProps.map((prop) => {
    const current = subscription[prop.subName];
    const next = nextPlan[prop.planName];
    const { type } = prop;
    let comparator = compareNumberValue;
    if (type === 'Bool') { comparator = compareBoolValue; }
    const compare = comparator(current, next);
    if (compare) {
      result.push({ type: prop.localizedString, ...compare });
    }
  });

  return _.compact(result);
}

function compareNumberValue(current, next) {
  let result = null;
  const isCurrentInfinity = current.max === 'INFINITY';
  const isCurrentDisabled = current.max === 0;
  const isNextInfinity = next === '0';
  const isNextDisabled = next === '-1';

  if (isCurrentInfinity && !isNextInfinity) {
    result = {
      current: current.max,
      next: getPlanReadableNumericValue(next),
    };
    return result;
  }

  if (!isCurrentDisabled && isNextDisabled) {
    result = {
      current: current.max,
      next: getPlanReadableNumericValue(next),
    };
    return result;
  }

  if (!isCurrentInfinity && !isNextInfinity && !isCurrentDisabled && !isNextDisabled) {
    if (+current.max > +next) {
      result = {
        current: current.max,
        next,
      };
      return result;
    }
  }

  return result;
}

function compareBoolValue(current, next) {
  let result = null;

  if (current === true && next === 'false') {
    result = {
      current: 'ALLOWED',
      next: 'NOT_ALLOWED',
    };
  }
  return result;
}

function getPlanReadableNumericValue(value) {
  if (value === '0') {
    return 'INFINITY';
  }

  if (value === '-1') {
    return 'NOT_ALLOWED';
  }

  return +value;
}

export function preparePlayerRating(matches, finalRatings) {
  let playerRatings = null;
  let averages = {
    sum: null,
    count: null,
  };

  matches.forEach((m) => {
    const season = getSeasonFromDate(m.date);
    const matchRating = finalRatings && finalRatings.length && finalRatings.find((r) => r.match_id === m.match_id);

    let rating = 0;
    if (matchRating) {
      rating = matchRating.value;
    }
    if (!rating && rating !== 0) return;

    if (!playerRatings) { playerRatings = { }; averages = { sum: 0, count: 0 }; }

    if (!playerRatings[season]) {
      playerRatings[season] = [];
    }
    playerRatings[season].push(rating);
    averages.sum += rating;
    averages.count += 1;
  });

  if (averages.count > 0) {
    averages.average = averages.sum / averages.count;
    averages.formatted = averages.average.toFixed(2);
  } else {
    averages.average = 'N/D';
    averages.formatted = 'N/D';
  }

  return { final: playerRatings, averages };
}

export function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min;
}

export function getTeamImage(teamId) {
  return `https://static.sp7f.com/teams/${teamId}.jpg`;
}

export function getPlayerImage(player) {
  if (!player || (!player.player_id && !player.master_id)) return null;

  const masterImage = ({ master_id }) => `https://static.sp7f.com/v2/players/${+master_id}.jpg`;
  const oldImage = ({ player_id }) => `https://static.sp7f.com/players/${player_id}.jpg`;

  if (player.player_id === 'demo') {
    const ids = window.genderContext === 'female' ? { player_id: 4524865, master_id: 1524865 } : { player_id: 133798, master_id: 37094 };

    return masterImage(ids);
  }

  return player.master_id ? masterImage(player) : oldImage(player);
}

export function getPlayerName(player) {
  return `${player?.first_name} ${player?.last_name}`;
}

export function getLeagueImage(leagueId) {
  return `https://static.sp7f.com/v2/leagues/${leagueId}.png`;
}

export function getRandomNumberBetween(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function getRandomArbitrary(min, max) {
  return Math.random() * (max - min) + min;
}

export function getTeamName(team, lng, t) {
  return _.get(team, `name_${lng}`, _.get(team, 'name', t('UNKNOWN_TEAM')));
}

export function getLeagueName(league, lng, t) {
  return _.get(league, `name_${lng}`, _.get(league, 'name', t('UNKNOWN_LEAGUE')));
}

export function getInitials(name) {
  const [first, last] = name.split(' ');
  let result = '';
  if (typeof first === 'string' && first) result += first[0];
  if (typeof last === 'string' && last) result += last[0];
  return result;
}

export function getRelativeDay(date, lng = 'en', t) {
  const locales = {
    en: localeEn,
    de: localeDe,
    pt: localePt,
  };

  const formatRelativeLocale = {
    lastWeek: 'dd.MM.yyyy',
    yesterday: `'${t('YESTERDAY')}'`,
    today: `'${t('TODAY')}'`,
    tomorrow: `'${t('TOMORROW')}'`,
    nextWeek: 'dd.MM.yyyy',
    other: 'dd.MM.yyyy',
  };

  const curLocale = locales[lng || 'en'];

  const locale = {
    ...curLocale,
    formatRelative: (token) => formatRelativeLocale[token],
  };

  return formatRelative(date, new Date(), { locale });
}

export function getFormattedToday(lng = 'en') {
  const locales = {
    en: localeEn,
    de: localeDe,
    pt: localePt,
  };
  const locale = locales[lng];
  return formatDate(new Date(), 'EEEE, MMMM d', { locale });
}

export function formatPlayerData(player, t, teams, lng, leagues, positions) {
  const first_name = _.get(player, 'first_name', '');
  const last_name = _.get(player, 'last_name', '');
  let player_id = _.get(player, 'player_id', 'placeholder');
  player_id = player_id === 'demo' ? 133798 : player_id;
  const jersey = _.get(player, 'jersey', 0);
  const country = _.get(player, 'country', null);
  let weight = _.get(player, 'weight', null);
  if (isNaN(weight)) weight = null;
  const height = _.get(player, 'height', null);
  const birth_date = player?.birth_date ? moment(player.birth_date, 'YYYY-MM-DD') : null;
  const age = birth_date ? moment()
    .diff(birth_date, 'years') : null;
  const foot = _.get(player, 'foot', null) || 'N/D';
  const foot_translated = t(foot);
  const contract = player?.contract_until && player.contract_until !== 'Invalid date' ? moment(player.contract_until) : null;
  const contract_ages = contract ? contract.format('DD/MM/YYYY') : null;
  const team_id = _.get(player, 'team_id', null);
  const team = _.get(teams, `[${team_id}]`, null);
  const team_img = team ? `https://static.sp7f.com/teams/${team_id}.jpg` : null;
  const team_name = team ? _.get(team, `name_${lng}`, _.get(team, 'name', null)) : null;
  const team_league = team ? team.standard_league : null;
  const team_league_name = _.get(leagues, `[${team_league}].name_${lng}`, _.get(leagues, `[${team_league}].name`, t('UNKNOWN_LEAGUE')));
  const position_id = _.get(player, 'position_id', null);
  const position = _.get(positions, `[${position_id}].desc_short_${lng}`, _.get(positions, `[${position_id}].desc_short`, 'N/D'));
  const marketvalue = _.get(player, 'marketvalue', null);
  const joinDateRaw = player?.join_date && moment(player.join_date, 'YYYY-MM-DD')
    .isValid() && moment(player.join_date, 'YYYY-MM-DD') > moment('1970-01-01', 'YYYY-MM-DD') ? moment(player.join_date, 'YYYY-MM-DD') : null;
  const joinDate = player?.join_date && moment(player.join_date, 'YYYY-MM-DD')
    .isValid() && moment(player?.join_date, 'YYYY-MM-DD') > moment('1970-01-01', 'YYYY-MM-DD') ? moment(player.join_date, 'YYYY-MM-DD')
      .format('DD/MM/YYYY') : '–';
  const playerImage = getPlayerImage(player);

  console.log({ leagues, team_league, team });

  return {
    first_name,
    last_name,
    jersey,
    player_id,
    weight,
    height,
    birth_date,
    age,
    foot,
    contract,
    contract_ages,
    team_img,
    team_name,
    team_league_name,
    position_id,
    position,
    marketvalue,
    joinDateRaw,
    joinDate,
    foot_translated,
    playerImage,
    country,
  };
}
export { validateBilling } from './validateBilling';

function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

export function formatQuarterAndBirthYear(player) {
  const date = new Date(player.birth_date) || null;
  if (isValidDate(date)) {
    const year = formatDate(date, 'yy');
    const month = +formatDate(date, 'MM');
    const quater = Math.ceil(month / 3);
    return `${year}/Q${quater}`;
  }

  return 'N/D';
}
