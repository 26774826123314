import React from 'react';
import './data_item.scss';
import PlayerImage from '../../../player-image';

export default function (props) {
  const {
    label, value, postfix, prefix, image, icon,
  } = props;

  let printValue = value;
  const imageComponent = null;

  if (value === null || value === undefined) {
    printValue = 'N/D';
  } else {
    printValue = value;

    if (postfix) {
      printValue += postfix;
    }

    if (prefix) {
      printValue = prefix + printValue;
    }
  }

  return (
    <div className="data-item">
      { icon ? <div className="data-item__icon">{icon}</div> : null }
      <div className="data-item__content">
        <div className="data-item__value">
          {image ? <PlayerImage className="data-item__image" src={image} /> : null }
          {printValue}
        </div>
        <div className="data-item__label">{label}</div>
      </div>
    </div>
  );
}
