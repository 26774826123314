import React from 'react';
import dateFormat from 'date-fns/format';
import { browserHistory, Link } from 'react-router';

import PropTypes from 'prop-types';
import Tag from './tag';

import Team from './team';
import Player from './player';
import Match from './match';

import './match-card.scss';
import Icon from '../../Icon';

import {
  getTeamImage, getTeamName, getLeagueName, getInitials, getRelativeDay, getPlayerImage, getPlayerName,
} from '../../../helpers';

export default function MatchCard({
  report, t, lng, teams, leagues, authUser, players, onEdit,
}) {
  const {
    type, match_id, home, away, player_id, date, league_id, assigned_by, assigned_by_user, user_id, user, is_done, is_in_progress, last_event_id, demo,
  } = report;

  // 1007090, 182, 157 - Borussia, 155 - Arminia
  const homeTeam = teams[home.team_id];
  const awayTeam = teams[away.team_id];
  const league = leagues[league_id];
  const homeImg = getTeamImage(home.team_id);
  const homeName = getTeamName(homeTeam, lng, t);
  const awayImg = getTeamImage(away.team_id);
  const awayName = getTeamName(awayTeam, lng, t);
  const leagueName = getLeagueName(league, lng, t);
  const groupMatch = authUser._id !== user_id;

  let userText = null;
  if (assigned_by) {
    if (user_id == authUser._id) {
      userText = t('ASSIGNED_BY', { assignor: getInitials(assigned_by_user) });
    } else {
      userText = t('ASSIGNED_TO', { assignor: getInitials(user) });
    }
  } else if (user && groupMatch) {
    userText = `${t('BY')} ${getInitials(user)}`;
  }

  const dateFormatted = getRelativeDay(new Date(date), lng, t);
  const timeFormatted = dateFormat(new Date(date), 'kk:mm');

  let tag = null;
  if (demo) {
    tag = <Tag type="DEMO" />;
  } else if (is_done) {
    tag = <Tag type="COMPLETED" />;
  } else if (is_in_progress) {
    tag = <Tag type="IN_PROGRESS" />;
  } else {
    tag = <Tag type="NOT_STARTED" />;
  }

  const player = players[player_id];

  const playerImg = getPlayerImage(player);
  const playerName = getPlayerName(player);

  const content = type === 'single' ? (
    <>
      <Player img={playerImg} name={playerName} />
      <Match homeImg={homeImg} awayImg={awayImg} />
    </>
  ) : (
    <>
      <Team name={homeName} img={homeImg} />
      <div className="match-card__divider">VS</div>
      <Team name={awayName} img={awayImg} />
    </>
  );

  return (
    <Link className="match-card" to={`/match/${match_id}`}>
      <div className="match-card__attrs">
        <div className="match-card__status">
          {tag}
          <div className="match-card__by">
            { userText }
          </div>
        </div>
        <div className="match-card__meta">
          <div className="match-card__date">
            <strong>{dateFormatted}</strong>
            <br />
            {timeFormatted}
          </div>
          <div className="match-card__league">
            {leagueName}
          </div>
        </div>
      </div>
      <div className="match-card__info">
        {content}
      </div>
      <div className="match-card__controls">
        {!demo && onEdit ? (
          <span
            className="match-card__button"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onEdit(report);
            }}
          >
            <Icon name="edit_v2" />
          </span>
        ) : null}
        <Link
          to={`/pdf/${match_id}`}
          className="match-card__button"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Icon name="pdf_v2" className="pdf-icon" />
        </Link>
      </div>
    </Link>
  );
}

MatchCard.propTypes = {
  onEdit: PropTypes.func,
};
