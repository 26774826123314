import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Portal } from 'react-portal';
import { createIndicator, updateIndicator } from '../../../../actions/indicators';
import { fetchIndicators } from '../../../../actions/index';
import Select from '../../../ui/select';
import Checkbox from '../../../ui/round-checkbox';
import FadeScreen from '../../../ui/fade-screen';

import './create-shortcut-form.scss';
import Icon from '../../../Icon';

function validate(values) {
  const errors = {};
  if (!values.name || values.name.length > 15) {
    errors.name = 'Name required';
  }
  if (!values.short || values.short.length > 6) {
    errors.short = 'Short name required and should be less 6 symbols';
  }

  if (!values.class) {
    errors.class = 'Class required';
  }

  if (!values.Goalkeeper && !values.Fielder) {
    errors.Goalkeeper = 'Select at least one';
  }

  return errors;
}

const renderField = ({
  input, type, placeholder, meta: { touched, error, warning }, className,
}) => (
  <input {...input} placeholder={placeholder} type={type} className={`${className} ${(touched && error) ? 'no-valid' : ''}`} />
);

const renderCheckbox = ({
  input, label, meta: { touched, error, warning },
}) => (
  <div className="checkbox-block">
    <Checkbox
      {...input}
      isChecked={input.value}
      id={input.name}
      onClick={() => {
        input.onChange(!input.value);
      }}
    />
    <label htmlFor={input.name}>
      {label}
    </label>
  </div>
);

@withTranslation()
@connect((state, props) => {
  const { t, edit } = props;

  const classesOptions = [
    {
      value: 'Physis',
      label: t('PHYSIS'),
    },
    {
      value: 'On the Ball',
      label: t('ON_THE_BALL'),
    },
    {
      value: 'Off the Ball',
      label: t('OFF_THE_BALL'),
    },
  ];

  return {
    indicators: state.indicators,
    initialValues: edit ? {
      ...edit,
      class: classesOptions.find((option) => option.value === edit.class),
    } : undefined,
    classesOptions,
  };
}, { fetchIndicators, createIndicator, updateIndicator })
@reduxForm({
  form: 'new_indicator',
  validate,
})
class CreateShortcutForm extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      isLoading: false,
    };
  }

  onSubmit(values) {
    const { edit } = this.props;
    const {
      createIndicator, updateIndicator, fetchIndicators, onClose,
    } = this.props;
    this.setState({ isLoading: true });
    values.class = values?.class?.value;

    const promise = edit ? updateIndicator(edit._id, values) : createIndicator(values);

    promise.then(() => {
      fetchIndicators().then(() => {
        this.setState({ isLoading: false });
        onClose();
      });
    }).catch((err) => {
      console.log(err);
    });
  }

  render() {
    const {
      t, handleSubmit, onClose, classesOptions, edit,
    } = this.props;
    const { isLoading } = this.state;

    return (
      <Portal>
        <FadeScreen>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(this.onSubmit)(e);
            }}
            className="create-shortcut-form sp-modal"
          >
            <div className="sp-modal__heading">
              <div className="sp-modal__name">
                <Icon name="note" />
                { t('CREATE_INDICATOR') }
              </div>
              <button
                type="reset"
                className="sp-modal__close"
                onClick={onClose}
              >
                <Icon name="close" />
              </button>
            </div>
            <div className="sp-modal__body">
              <div className="row form-row">
                <div className="col-6">
                  <Field component={renderField} name="name" t={t} label={t('INDICATOR_NAME')} placeholder={t('INDICATOR_NAME')} />
                </div>
                <div className="col-6">
                  <Field
                    component={renderField}
                    name="short"
                    t={t}
                    label={t('INDICATOR_SHORT_NAME')}
                    placeholder={t('INDICATOR_SHORT_NAME')}
                  />
                </div>
              </div>
              <div className="row form-row">
                <div className="col-12">
                  <Field
                    component={renderField}
                    name="description"
                    t={t}
                    label={t('DESCRIPTION')}
                    placeholder={t('DESCRIPTION')}
                  />
                </div>
              </div>
              <div className="row form-row">
                <div className="col-3">
                  <Field component={renderCheckbox} name="Goalkeeper" label={t('GOALKEEPER')} />
                </div>
                <div className="col-3">
                  <Field component={renderCheckbox} name="Fielder" label={t('FIELDER')} />
                </div>
                <div className="col-6">
                  <Field
                    placeholder={t('INDICATOR_TYPE')}
                    name="class"
                    component={Select}
                    options={classesOptions}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 buttons">
                  <button type="reset" className="btn btn-red" onClick={onClose}>{t('CANCEL')}</button>
                  <button className="btn" type="submit" disabled={isLoading}>{edit ? t('SAVE') : t('CREATE')}</button>
                </div>
              </div>
            </div>
          </form>
        </FadeScreen>
      </Portal>
    );
  }
}

CreateShortcutForm.defaultProps = {
  createIndicator: () => {
  },
  fetchIndicators: () => {
  },
  onClose: () => {},
};

CreateShortcutForm.propTypes = {
  createIndicator: PropTypes.func.isRequired,
  fetchIndicators: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func,
  onClose: PropTypes.func,
};

export default CreateShortcutForm;
