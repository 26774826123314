import React, { Component } from 'react';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import StadiumSelect from '../stadium-select';
import LeagueSelect from '../league-select';
import Select from '../select';
import { createTeam } from '../../../actions';
import { LeagueForm } from '../league-form';

import './team-form.scss';
import Icon from '../../Icon';

const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = 'Required';
  }

  if (!values.country) {
    errors.country = 'Required';
  }

  return errors;
};

const fieldInput = ({ input, ...rest }) => (
  <input
    {...input}
    name={rest.name}
    placeholder={rest.placeholder}
    className={`input ${rest.className} ${rest.meta.touched && rest.meta.error ? 'no-valid' : ''}`}
  />
);

@withTranslation()
@connect((state, props) => {
  const { leagues, countries } = state;
  const { i18n } = props;
  const lng = i18n.language;

  const league = props.league ? leagues[props.league] : null;
  const country = league && league.country_id ? countries.listById[league.country_id] : null;

  const initialValues = {
    name: props.name,
    league: league ? { value: league.id, label: _.get(league, `name_${lng}`, league.name) } : '',
    country: country ? { value: country.id, label: _.get(country, `name_${lng}`, country.name) } : '',
  };

  return {
    teams: state.teams,
    leagues,
    countries,
    stadiums: state.stadiums,
    allCountries: state.countries.list,
    allCountriesById: state.countries.listById,
    form: 'createTeam',
    initialValues,
  };
}, { createTeam })
@reduxForm({ validate })
export default class TeamForm extends Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.createStadium = this.createStadium.bind(this);

    this.state = {
      leagues: [],
      countries: [],
    };
  }

  componentDidMount() {
    const {
      allCountries, t, i18n,
    } = this.props;
    const lng = i18n.language;

    const countries = _.chain(allCountries).map((c) => ({
      value: c._id,
      label: _.get(c, `name_${lng}`, _.get(c, 'name', t('UNKNOWN_COUNTRY'))),
    })).value();

    this.setState({
      countries,
    });
  }

  createStadium(stadium) {
    this.props.addStadium(stadium)
      .then((action) => {
        const id = _.get(action, 'payload.stadium.id', null);
        const stadium = _.get(action, 'payload.stadium.stadium', null);
        if (id) {
          this.props.change('stadium', { value: id, label: stadium });
        }
      });
  }

  onSubmit(values) {
    this.props.createTeam({
      name: values.name,
      stadium_id: _.get(values, 'stadium.value', null),
      country_id: values.country.value,
      standard_league: _.get(values, 'league.value', null),
    }).then((response) => {
      this.props.onCreate(response.payload.data[0].team, this.props.side);
    });
  }

  render() {
    const { t, handleSubmit } = this.props;
    const { countries } = this.props;

    return (
      <div className="create-team-form">
        <form className="form" onSubmit={handleSubmit(this.onSubmit)}>
          <h2>{t('CREATE_TEAM')}</h2>
          <div className="row form-row">
            <div className="col-6">
              <label>{t('TEAM_NAME')}</label>
              <Field
                placeholder={t('ENTER_TEAM_NAME')}
                name="name"
                component={fieldInput}
              />
            </div>
            <div className="col-6">
              <label>{t('COUNTRY')}</label>
              <Field
                placeholder={t('SELECT_COUNTRY')}
                name="country"
                options={this.state.countries}
                component={Select}
              />
            </div>
          </div>
          <div className="row form-row">
            <div className="col-6">
              <label>
                {t('LEAGUE')}
                {' '}
                (
                {t('OPTIONAL')}
                )
              </label>
              <Field
                countries={countries}
                placeholder={t('SELECT_LEAGUE')}
                name="league"
                component={LeagueSelect}
              />
            </div>
            <div className="col-6">
              <label>
                {t('STADIUM')}
                {' '}
                (
                {t('OPTIONAL')}
                )
              </label>
              <Field
                placeholder={t('SELECT_LOCATION')}
                name="stadium"
                component={StadiumSelect}
                creatable
                formatCreateLabel={(label) => { t('CREATE_STADIUM', { label }); }}
                onCreate={this.createStadium}
              />
            </div>
          </div>
          <div className="row form-row submit-row">
            <button className="btn" onClick={this.props.onCancel}>{ t('CANCEL') }</button>
            <button className="btn">{ t('SUBMIT') }</button>
          </div>
        </form>
      </div>
    );
  }
}
