import React from 'react';
import Icon from '../../Icon';
import './demo-message.scss';

export default function DemoMessage({ text, arrowPosition, className }) {
  // arrowPosition can be left, right, top-right, top-left
  return (
    <div className={`demo-message ${className || ''}`}>
      <Icon name="demo-arrow" className={`demo-arrow ${arrowPosition || 'top-left'}`} />
      {text}
    </div>
  );
}
