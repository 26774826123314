import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  fetchReports,
  clearReports,
  setReportsLoading,
  deleteReport,
  updateReport,
  changeFilters,
  generateFilterOptions,
  fetchOffline,
} from '../../actions/reports';
import ReportsList from './components/reports-list';
import ReportsControls from './components/reports-controls';
import { updateReportsLimits } from '../../actions/subscription';
import { isGroupUsers } from '../../helpers/limits';
import eventTracker from '../../helpers/event-tracker';

function ReportsPage({
  reports,
  teams,
  leagues,
  fetchReports,
  user,
  clearReports,
  setReportsLoading,
  positions,
  type,
  deleteReport,
  fetchOffline,
  updateReport,
  subscription,
  changeFilters,
  generateFilterOptions,
}) {
  const {
    list, total, hasNextPage, isListLoading, offset, reportsPerPage, players, filters, filtersList,
  } = reports;

  const { t } = useTranslation();

  const canUseGroup = isGroupUsers(subscription);
  const isAdmin = user.role.tag !== 'user';

  const loadReports = (offset) => {
    setReportsLoading(true);
    return fetchReports(filters, offset, reportsPerPage);
  };

  const startLoadingFromBeginning = () => {
    clearReports();
    loadReports(0);
  };

  useEffect(() => {
    changeFilters({ ...filters, type });
  }, [type]);

  useEffect(() => {
    startLoadingFromBeginning();
  }, [filters]);

  useEffect(() => {
    const usersOptions = _.map(user?.users, (user) => ({
      value: user._id,
      label: `${user.firstName} ${user.lastName}`,
    }));

    generateFilterOptions({ usersOptions, canUseGroup, isAdmin });
  }, [teams, leagues, user]);

  const loadNextPage = () => {
    if (hasNextPage) {
      return loadReports(offset);
    }
    return null;
  };

  const onDeleteReport = (_id) => {
    const action = deleteReport(_id);
    action.request.then(() => {
      setTimeout(() => {
        fetchOffline();
      }, 350);
    });
    updateReportsLimits({ current: subscription.reports.current - 1 });
    eventTracker.trackEvent('Reports', 'Delete report');
  };

  let emptyMessage = t('NO_REPORTS_FOUND');

  if (type === 'team') {
    emptyMessage = t('REPORTS_TEAMS_EMPTY');
  } else if (type === 'players') {
    emptyMessage = t('REPORTS_PLAYERS_EMPTY');
  } else if (type === 'assigned') {
    if (user.role.tag !== 'user') {
      emptyMessage = t('REPORTS_ASSIGNED_EMPTY');
    } else {
      emptyMessage = t('REPORTS_ASSIGNED_EMPTY_USER');
    }
  }

  return (
    <div className="reports__wrapper">
      <ReportsControls
        teams={teams}
        positions={positions}
        filters={filters}
        filtersList={filtersList}
        changeFilters={changeFilters}
        type={type}
      />
      <ReportsList
        list={list}
        teams={teams}
        leagues={leagues}
        hasNextPage={list.length < total}
        isListLoading={isListLoading}
        loadNextPage={loadNextPage}
        total={total}
        authUser={user}
        players={players}
        deleteReport={onDeleteReport}
        updateReport={updateReport}
        reportsPerPage={reportsPerPage}
        emptyMessage={emptyMessage}
      />
    </div>
  );
}

export default connect((state, props) => {
  return {
    teams: state.teams,
    leagues: state.leagues,
    users: state.users,
    reports: state.reports,
    positions: state.positions,
    subscription: state.subscription,
    user: state.auth.user,
    type: props.params.type,
  };
}, {
  fetchReports,
  clearReports,
  setReportsLoading,
  deleteReport,
  updateReport,
  changeFilters,
  generateFilterOptions,
  fetchOffline,
})(ReportsPage);

ReportsPage.propTypes = {
  type: PropTypes.string,
  fetchReports: PropTypes.func,
  teams: PropTypes.object,
  positions: PropTypes.object,
  leagues: PropTypes.object,
  reports: PropTypes.object,
  user: PropTypes.object,
  clearReports: PropTypes.func,
  setReportsLoading: PropTypes.func,
  deleteReport: PropTypes.func,
  updateReport: PropTypes.func,
  subscription: PropTypes.object,
  changeFilters: PropTypes.func,
  generateFilterOptions: PropTypes.func,
  fetchOffline: PropTypes.func,
};
