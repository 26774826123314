import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  reduxForm, Field, formValueSelector,
} from 'redux-form';
import MaskedInput from 'react-maskedinput';
import _ from 'lodash';
import moment from 'moment';
import TeamSelect from '../teams-select/simple';
import Select from '../select';
import RadioSwitcher from '../radio-switcher';
import PlayerSelect from '../player-select/index';

const renderField = ({
  input, type, placeholder, meta: { touched, error, warning }, className,
}) => (
  <input {...input} placeholder={placeholder} type={type} className={`${className} ${(touched && error) ? 'no-valid' : ''}`} />
);

const maskedInput = ({ input, ...rest }) => (
  <MaskedInput
    {...input}
    name={rest.name}
    placeholder={rest.placeholder}
    mask={rest.mask}
    className={`input ${rest.className} ${rest.meta.touched && rest.meta.error ? 'no-valid' : ''}`}
  />
);

const fieldPlayerSelect = ({
  input, key, onCreate, ...rest
}) => {
  if (typeof input.value === 'string') {
    input.value = undefined;
  }
  return (
    <PlayerSelect
      key={key}
      name={input.name}
      value={input.value}
      onCreate={onCreate}
      isDisabled={rest.isDisabled}
      onChange={input.onChange}
      filter={rest.filter}
      teams={rest.teams}
    />
  );
};

const validate = (values) => {
  const errors = {};

  if (!values.team) {
    errors.team = 'Required';
  }

  if (values.type === 'new') {
    if (!values.first_name) { errors.first_name = 'Required'; }
    if (!values.last_name) { errors.last_name = 'Required'; }
    if (!values.jersey) { errors.jersey = 'Required'; }
    if (+values.jersey < 0) { errors.jersey = 'Cant be minus'; }
    if (!values.position) { errors.position = 'Required'; }

    if (values.birth_date) {
      const isDateValid = moment(values.birth_date, 'DD/MM/YYYY').isValid();
      if (!isDateValid) {
        errors.birth_date = 'Please, enter correct';
      }
    }
  } else if (!values.player) {
    errors.player = 'Required';
  }

  return errors;
};

@withTranslation()
@connect((state, props) => {
  const selector = formValueSelector('createPlayer');
  const initialValues = {
    type: 'existing',
    team: props.options[0],
  };

  return {
    positions: state.positions,
    allCountries: state.countries.list,
    allCountriesById: state.countries.listById,
    form: 'createPlayer',
    initialValues,
    formValues: {
      type: selector(state, 'type'),
      team: selector(state, 'team'),
      player: selector(state, 'player'),
      first_name: selector(state, 'first_name'),
      last_name: selector(state, 'last_name'),
      jersey: selector(state, 'jersey'),
      position: selector(state, 'position'),
      birth_date: selector(state, 'birth_date'),
      foot: selector(state, 'foot'),
    },
  };
}, { })
@reduxForm({ validate })
class CreatePlayerForm extends Component {
  constructor(props) {
    super(props);

    this.rendeAddPlayer = this.rendeAddPlayer.bind(this);
    this.renderExistingPlayer = this.renderExistingPlayer.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(values) {
    this.props.onCreate(values);
  }

  renderExistingPlayer() {
    const { t, teams } = this.props;

    return (
      <div className="row indented match-edit-form__row">
        <div className="col-12 indented">
          <Field
            name="player"
            placeholder={t('SELECT_PLAYER')}
            component={fieldPlayerSelect}
            teams={teams}
          />
        </div>
      </div>
    );
  }

  rendeAddPlayer() {
    const { t, i18n, positions } = this.props;
    const lng = i18n.language;

    const footOptions = [
      { value: null, label: t('N/D') },
      { value: 'Right', label: t('Right') },
      { value: 'Left', label: t('Left') },
      { value: 'Both', label: t('Both') },
    ];

    const positionsOptions = _.map(positions, (position) => ({
      label: _.get(position, `desc_short_${lng}`, _.get(position, 'desc_short', t('UNDEFINED'))),
      value: position.id,
    })).filter((p) => p.value < 9 && p.value != 0);

    return (
      <>
        <div className="row indented match-edit-form__row">
          <div className="col-4 indented">
            <label>{t('FIRST_NAME')}</label>
            <Field className="input" name="first_name" type="text" placeholder={t('FIRST_NAME')} component={renderField} />
          </div>
          <div className="col-4 indented">
            <label>{t('LAST_NAME')}</label>
            <Field className="input" name="last_name" type="text" placeholder={t('LAST_NAME')} component={renderField} />
          </div>
          <div className="col-4 indented">
            <label>{t('BIRTH_DATE')}</label>
            <Field
              className="input"
              name="birth_date"
              type="number"
              placeholder={t('BIRTH_DATE')}
              component={maskedInput}
              mask="11/11/1111"
            />
            <span className="input-placeholder-example">DD/MM/YYYY</span>
          </div>
        </div>
        <div className="row indented match-edit-form__row">
          <div className="col-4 indented jersey">
            <label>{t('JERSEY')}</label>
            <Field className="input" name="jersey" type="number" placeholder={t('NUMBER')} component={renderField} />
          </div>
          <div className="col-4 indented">
            <label>{t('POSITION')}</label>
            <Field placeholder={t('POSITION')} name="position" component={Select} options={positionsOptions} />
          </div>
          <div className="col-4 indented">
            <label>{t('FOOT')}</label>
            <Field
              placeholder={t('FOOT')}
              name="foot"
              component={Select}
              options={footOptions}
            />
          </div>
        </div>
      </>
    );
  }

  render() {
    const {
      t, options, handleSubmit, formValues, onClose,
    } = this.props;

    const validation = validate(formValues);
    const isDisabledSubmit = validation && Object.keys(validation).length !== 0 && Object.getPrototypeOf(validation) === Object.prototype;

    return (
      <div className="match-edit-form">
        <form onSubmit={handleSubmit(this.onSubmit)}>
          <div className="row indented match-edit-form__row">
            <div className="col-12 indented">
              <label>{t('TEAM')}</label>
              <Field
                name="team"
                placeholder={t('SELECT_TEAM')}
                component={TeamSelect}
                options={options}
              />
            </div>

          </div>

          <div className="row indented match-edit-form__row">
            <div className="col-12 indented">
              <Field
                name="type"
                component={RadioSwitcher}
                options={[
                  {
                    value: 'existing',
                    label: t('ADD_PLAYER_FROM_DB', { context: window.genderContext }),
                    icon: null,
                  },
                  {
                    value: 'new',
                    label: t('CREATE_NEW_PLAYER', { context: window.genderContext }),
                    icon: null,
                  },
                ]}
              />
            </div>
          </div>
          {formValues.type === 'existing' ? this.renderExistingPlayer() : this.rendeAddPlayer()}
          <div className="row indented match-edit-form__row">
            <div className="col-12 indented match-edit-form__buttons">
              <button type="reset" className="btn btn-secondary" onClick={onClose}>{t('CANCEL')}</button>
              <button type="submit" className="btn" disabled={isDisabledSubmit}>{t('SAVE')}</button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

CreatePlayerForm.propTypes = {
  teams: PropTypes.object,
  options: PropTypes.array,
  onClose: PropTypes.func,
  t: PropTypes.func,
  onCreate: PropTypes.func,
};

export default CreatePlayerForm;
