import React, { Component } from 'react';
import { FixedSizeList as List } from 'react-window';
import PropTypes from 'prop-types';

export default class VirtualList extends Component {
  static propTypes = {
    classNames: PropTypes.string,
    itemSize: PropTypes.number,
    item: PropTypes.func,
    count: PropTypes.number,
  };

  constructor(props) {
    super(props);
    this.resizeHandler = this.resizeHandler.bind(this);

    this.state = {
      width: 0,
      height: props.itemSize,
    };
  }

  componentDidMount() {
    this.resizeHandler();

    window.addEventListener('resize', this.resizeHandler);
  }

  componentWillUnmount() {}

  resizeHandler() {
    window.requestAnimationFrame(() => {
      if (this.listContainer) {
        const rect = this.listContainer.getBoundingClientRect();
        this.setState({
          width: rect.width || 0,
          height: rect.height || 0,
        });
      }
    });
  }

  render() {
    const {
      classNames, itemSize, item, count, ...rest
    } = this.props;

    const { width, height } = this.state;

    return (
      <div
        className={`virtual-list ${classNames}`}
        ref={(listContainer) => {
          this.listContainer = listContainer;
        }}
      >
        <List
          height={height}
          itemCount={count}
          itemSize={itemSize}
          width={width}
          className="virtual-list-instance"
          {...rest}
        >
          {item}
        </List>
      </div>
    );
  }
}
