import axios from 'axios';
import localforage from 'localforage';
import { NotificationManager } from '../components/notifications';

import { ROOT_URL } from '../actions';
import { MATCHES_COLLECTION } from './constants';
import storageMatchHistory from './storageMatchHistory';

export default function (id, matches) {
  const userId = localStorage.getItem('userId');

  const gender = localStorage.getItem('selectedGender');
  const collectionName = `${MATCHES_COLLECTION}_${gender}`;

  NotificationManager.create({
    message: 'DELETING',
    id: 'id',
    timeOut: 3000,
  });

  switch (window.online) {
    case true:
      return axios
        .delete(`${ROOT_URL}/match/${id}`)
        .then(() => {
          localforage.getItem(`${collectionName}-${userId}`).then((matchesClient) => {
            matchesClient = matchesClient || [];
            const index = matchesClient.findIndex((m) => m._id === id);

            let promise = null;
            if(index !== -1) {
              matchesClient = [...matchesClient.slice(0, index), ...matchesClient.slice(index + 1)];
              promise = localforage.setItem(`${collectionName}-${userId}`, matchesClient);
            }

            storageMatchHistory(id, 'MATCH_DELETE', {});
            NotificationManager.remove('id');
            NotificationManager.create({
              message: 'MATCH_DELETED',
              id: 'id',
              timeOut: 3000,
            });

            return Promise.all(promise).then(() => {
              return Promise.resolve({ matches: matchesClient });
            });
          });
        })
        .catch((err) => {
          console.error(err);
          NotificationManager.remove('id');
          NotificationManager.create({
            message: 'MATCH_NOT_DELETED',
            id: 'id',
            timeOut: 3000,
            type: 'error',
          });
        });

    case false:
      break;
  }
}
