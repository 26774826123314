import React from 'react';
import Sidebar from '../sidebar';
import Pitch from '../pitch';
import Controls from '../controls';
import AddPlayer from '../add-player';
import Preview from '../preview';

export default function MatchReport() {
  return (
    <>
      <Sidebar />
      <Pitch />
      <Controls />
      <AddPlayer />
      <Preview />
    </>
  );
}
