import React, { Component } from 'react';
import { connect } from 'react-redux';
import TextareaAutosize from 'react-textarea-autosize';
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import onClickOutside from 'react-onclickoutside';

import {
  createNoteTag, updateNote, selectNote, setNoteMode, deleteNote,
} from '../../../../../actions/notes';

import './note-form.scss';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { style, theme } from '../../../../ui/select_props';

@withTranslation()
@onClickOutside
class NotesForm extends Component {
  constructor(props) {
    super(props);

    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.onEditDone = this.onEditDone.bind(this);
  }

  handleClickOutside(e) {
    this.onEditDone();
  }

  componentDidMount() {
    const { notes, updateNote } = this.props;
    const {
      mode, filter, tags, selectedNote,
    } = notes;

    if (mode === 'create' && selectedNote._id && filter.tag_id) {
      const currentTag = tags.filter((tag) => +tag._id === +filter.tag_id);

      if (currentTag && currentTag.length) {
        updateNote({
          ...selectedNote,
          tags: [{
            id: currentTag[0]._id,
            tag: currentTag[0].tag,
          }],
        });
      }
    }
  }

  onEditDone() {
    const { selectNote } = this.props;
    const { selectedNote } = this.props.notes;

    if (selectedNote && !selectedNote.title && !selectedNote.value) {
      this.props.setNoteMode(null);
      this.props.deleteNote(selectedNote._id);
    } else {
      selectNote(selectedNote);
    }
  }

  renderSelect() {
    const { playersOptions } = this.props.notes.dictionaries;
    const { selectedNote } = this.props.notes;
    const { t } = this.props;

    return (
      <Select
        className="note-player-select"
        placeholder={t('SELECT_PLAYER', { context: window.genderContext })}
        value={selectedNote.player_id}
        options={playersOptions}
        onChange={(val) => {
          this.props.updateNote({ ...selectedNote, player_id: val.value });
        }}
        optionRenderer={(opt) => {
          return (
            <span>
              <span className="player-card">
                <span className="player-text">
                  <span className="player-name">{opt.label}</span>
                  <span className="player-team">{opt.team}</span>
                </span>
              </span>
            </span>
          );
        }}
        valueRenderer={(val) => {
          return (
            <span>
              <span className="player-card">
                <span className="player-text">
                  <span className="player-name">{val.label}</span>
                  <span className="player-team">{val.team}</span>
                </span>
              </span>
            </span>
          );
        }}
      />
    );
  }

  render() {
    const { tagsOptions, tagsByKey } = this.props.notes.dictionaries;
    const { selectedNote } = this.props.notes;
    const { player_id, t } = this.props;

    const createDate = selectedNote.updatedAt || selectedNote.createdAt;
    const date = createDate ? moment(createDate).format('DD.MM.YYYY - HH:mm') : '';

    // <div className="note-content__meta">By {`${selectedNote.user.firstName} ${selectedNote.user.lastName}`}, { date }</div>

    return (
      <form className="note-form">

        {!player_id
          ? (
            <div className="note-form__player">
              { this.renderSelect() }
              <button className="btn note-form__done" onClick={this.onEditDone}>
                {t('DONE')}
              </button>
            </div>
          )
          : (
            <div>
              <button className="btn note-form__done" onClick={this.onEditDone}>
                {t('DONE')}
              </button>
            </div>
          )}

        <TextareaAutosize
          autoFocus
          className="note-form__title"
          placeholder={t('NOTE_TITLE_PLACEHOLDER')}
          maxRows={3}
          value={selectedNote.title || ''}
          onChange={(e) => {
            this.props.updateNote({ ...selectedNote, title: e.target.value });
          }}
        />
        <TextareaAutosize
          className="note-form__text"
          placeholder={t('NOTE_TEXT_PLACEHOLDER')}
          value={selectedNote.value || ''}
          rows={6}
          onChange={(e) => {
            this.props.updateNote({ ...selectedNote, value: e.target.value });
          }}
        />

        <div className="note-form__tags">
          <Creatable
            value={selectedNote.tags.map((t) => ({ label: tagsByKey[t].tag, value: tagsByKey[t]._id }))}
            className="note-tag-select"
            placeholder={t('ADD_TAG')}
            theme={theme}
            styles={style}
            promptTextCreator={(value) => t('CREATE_TAG', value)}
            onCreateOption={(value) => {
              this.props.createNoteTag({ tag: value }).then((action) => {
                const { data } = action.payload;
                const v = [...selectedNote.tags, data._id];
                this.props.updateNote({ ...selectedNote, tags: v });
              });
            }}
            options={tagsOptions}
            isMulti
            searchable
            onChange={(val) => {
              const v = Array.isArray(val) ? [...val] : [val];
              this.props.updateNote({ ...selectedNote, tags: v.map((t) => t.value) });
            }}
          />
        </div>

      </form>
    );
  }
}

export default connect((state) => ({ notes: state.notes }), {
  createNoteTag, updateNote, selectNote, setNoteMode, deleteNote,
})(NotesForm);
