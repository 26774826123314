import React, { Component, PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Link } from 'react-router';
import { withTranslation } from 'react-i18next';
import { getCurrencySign, getPaymentMethodName, renderDate } from '../../../../helpers/index';

import './customer.scss';

@withTranslation()
class Customer extends PureComponent {
  render() {
    const {
      t, itemData, edit, deactivate, remove,
    } = this.props;
    const { style, customer } = itemData;

    let planName = t('NO_PLAN');
    if (customer.plan) {
      planName = customer.plan !== 'free' ? _.get(customer, 'stripe_cache.plan_name', t('UNKNOWN_PLAN', { plan_id: customer.plan })) : 'Free';
    }

    const coupon = customer.coupon ? customer.coupon : '—';
    const paymentMethod = _.get(customer, 'stripe_cache.payment_method', null);
    const paymentMethodFormatted = getPaymentMethodName(paymentMethod, '—');
    const currencySign = getCurrencySign(customer.currency);

    const renewalDate = _.get(customer, 'stripe_cache.current_period_end', null);
    const formattedRenewal = renderDate(renewalDate);
    const registered = renderDate(customer.createdAt);
    const trialEnd = _.get(customer, 'stripe_cache.trial_end', null);
    const formattedTrialEnd = renderDate(trialEnd);
    const acceptPrivacy = renderDate(customer.acceptPrivacyDate);
    const lastLoggedIn = renderDate(customer.lastLoggedIn);

    const isPayingCustomer = paymentMethod && (customer.plan && customer.plan !== 'free') && customer.active;

    let customerStatus = 'regular';

    if (isPayingCustomer) { customerStatus = 'paying'; }
    if (!customer.active) { customerStatus = 'disabled'; }

    const credits = customer.credits || 0;
    const daysSessions = customer.daysSessions || 0;

    const invites = customer.invites || [];
    const invitesTotal = invites.length;
    const invitesFree = invites.filter((i) => i.status === 'subscribe_free').length;
    const invitesPro = invites.filter((i) => i.status === 'subscribe_pro').length;

    const { bonus } = customer;
    const totalMilestones = 7;
    let activeMilestones = 0;

    if (bonus) {
      delete bonus.earn2;
      _.map(bonus, (i) => {
        if (i.active === true) { activeMilestones++; }
      });
    }

    return (
      <div key={customer._id} className="customer-list-item__wrapper" style={style}>
        <div className={`customer-list-item ${!customer.active ? 'disabled' : ''} ${isPayingCustomer ? 'highlighted' : ''}`}>
          <div className="customer-list-item__container">
            <div className="customer-list-item__column">
              <p className="customer-list-item__id">
                <span className={`customer-list-item__indicator ${customerStatus}`} />
                ID:
                {' '}
                {customer._id}
              </p>
              <p>
                <Link to={`/admin/customers/${customer._id}`} className="customer-list-item__name">{customer.name}</Link>
                {' '}
                <span className="customer-list-item__customer-type">{t(customer.customerType)}</span>
              </p>
              <p className="customer-list-item__email">{ customer.clubEmail }</p>
              <p className="customer-list-item__users">
                <span className="label">Users:</span>
                {' '}
                { customer.users }
              </p>
            </div>
            <div className="customer-list-item__column">
              <p className="customer-list-item__plan">
                {planName ? `${planName},` : '-,'}
                {' '}
                {currencySign}
              </p>
              <p className="customer-list-item__coupon">
                <span className="label">
                  {t('COUPON')}
                  :
                </span>
                {' '}
                { coupon }
              </p>
              <p className="customer-list-item__payment_method">
                <span className="label">
                  {t('PAYMENT_METHOD')}
                  :
                </span>
                {' '}
                {paymentMethodFormatted}
              </p>
              <p className="customer-list-item__renewal_date">
                <span className="label">
                  {t('RENEWAL_DATE')}
                  :
                </span>
                {' '}
                {formattedRenewal}
              </p>
            </div>
            <div className="customer-list-item__column">
              <p className="customer-list-item__registered">
                <span className="label">
                  {t('REGISTERED')}
                  :
                </span>
                {' '}
                {registered}
              </p>
              <p className="customer-list-item__trial-end">
                <span className="label">
                  {t('TRIAL_END')}
                  :
                </span>
                {' '}
                {formattedTrialEnd}
              </p>
              <p className="customer-list-item__accept-privacy">
                <span className="label">
                  {t('ACCEPT_PRIVACY')}
                  :
                </span>
                {' '}
                {acceptPrivacy}
              </p>
              <p className="customer-list-item__last-logged-in">
                <span className="label">
                  {t('LAST_LOGGED_IN')}
                  :
                </span>
                {' '}
                {lastLoggedIn}
              </p>
            </div>
            <div className="customer-list-item__column">
              <p className="customer-list-item__credits">
                <span className="label">
                  {t('ADMIN_CREDITS')}
                  :
                </span>
                {' '}
                {credits}
              </p>
              <p className="customer-list-item__milestones">
                <span className="label">
                  {t('ADMIN_MILESTONES')}
                  :
                </span>
                {' '}
                {activeMilestones}
                {' '}
                /
                {' '}
                {totalMilestones}
              </p>
              <p className="customer-list-item__sessions">
                <span className="label">
                  {t('ADMIN_SESSIONS')}
                  :
                </span>
                {' '}
                {daysSessions}
              </p>
              <p className="customer-list-item__referrals">
                <span className="label">
                  {t('ADMIN_REFERRALS_FREE_PRO')}
                  :
                </span>
                {' '}
                {invitesTotal}
                {' '}
                /
                {' '}
                {invitesFree}
                {' '}
                /
                {' '}
                {invitesPro}
              </p>
            </div>
            <div className="customer-list-item__column customer-list-item__buttons">
              <p className="button">
                <span onClick={() => { edit(customer._id); }} className="js-link">{t('EDIT')}</span>
              </p>
              {
                customer.active
                  ? <p className="button"><span onClick={() => { deactivate(customer._id, false); }} className="js-link orange">{t('DEACTIVATE')}</span></p>
                  : <p className="button"><span onClick={() => { deactivate(customer._id, true); }} className="js-link blue">{t('ACTIVATE')}</span></p>
              }
              <p className="button"><span onClick={() => { remove(customer._id); }} className="js-link red">{t('DELETE')}</span></p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Customer.propTypes = {
  itemData: PropTypes.object,
  t: PropTypes.func,
  edit: PropTypes.func,
  deactivate: PropTypes.func,
  remove: PropTypes.func,
};

export default Customer;
