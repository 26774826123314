import _ from 'lodash';
import {
  FETCH_INVITES,
  CREATE_INVITE,
} from '../actions/invites';

export const initState = [];

function sortedInvites(invites) {
  return _.orderBy(invites.map((i) => {
    let sortIndex = 0;
    // 'new', 'registered', 'active', 'subscribe_free', 'subscribe_pro'
    switch (i.status) {
      case 'new':
        sortIndex = 4;
        break;
      case 'registered':
        sortIndex = 3;
        break;
      case 'active':
        sortIndex = 2;
        break;
      case 'subscribe_free':
        sortIndex = 1;
        break;
      case 'subscribe_pro':
        sortIndex = 0;
        break;
    }
    return { ...i, sortIndex };
  }), ['sortIndex'], ['asc']);
}

export default function (state = initState, action) {
  let invites = [];
  switch (action.type) {
    case FETCH_INVITES:
      invites = action.payload.data.invites;
      invites = sortedInvites(invites);
      return invites;
    case CREATE_INVITE:
      const response = action.payload.data;
      return response.success ? [...state, response.invite] : state;
    default:
      return state;
  }
}
