import _keyBy from 'lodash/keyBy';
import { FETCH_HOME } from '../actions/home';

const INITIAL_STATE = {
  players: {
    list: [],
    info: {},
  },
  watchlists: [],
  reports: [],
  reportPlayers: {},
  message: null,
  fetched: false,
};

export default function (state = INITIAL_STATE, action) {
  let data = {};
  switch (action.type) {
    case FETCH_HOME:
      data = action.payload.data;
      if (data.success) {
        return {
          ...data,
          reportPlayers: _keyBy([...data.reportPlayers.mmPlayers, ...data.reportPlayers.playerEntries], 'player_id'),
          message: null,
          fetched: true,
        };
      }
      return {
        ...state,
        message: data.message,
      };
    default:
      return state;
  }
}
