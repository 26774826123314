import { FETCH_TEAMS, ADD_TEAM } from '../actions/index';

const INITIAL_STATE = {};

export default function (state = INITIAL_STATE, action) {
  let teams = {};
  switch (action.type) {
    case FETCH_TEAMS:
      return action.payload.teams;
    case ADD_TEAM:
      teams = {
        ...state,
        [action.payload.data[0].team.id]: action.payload.data[0].team,
      };
      return teams;
    default:
      return state;
  }
}
