import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import { connect } from 'react-redux';
import NoteForm from '../note-form';
import Note from '../note';

import './notes-content.scss';
import '../../note-tags-select.scss';
import '../../styled-react-select.scss';
import '../../note-player-select.scss';

@connect((state) => ({
  notes: state.notes.notes,
  mode: state.notes.mode,
  selectedNote: state.notes.selectedNote,
}), {})
export default class NotesContent extends Component {
  render() {
    const {
      height, mode, selectedNote, player_id,
    } = this.props;

    return (
      <div className="notes-content">
        <Scrollbars style={{ height }} autoHide>
          <div className="notes-content__wrapper">
            {!mode ? <Note player_id={player_id} /> : <NoteForm note={selectedNote} player_id={player_id} /> }
          </div>
        </Scrollbars>
      </div>
    );
  }
}

NotesContent.propTypes = {
  height: PropTypes.number,
  mode: PropTypes.string,
  selectedNote: PropTypes.object,
  player_id: PropTypes.number,
};
