import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { withTranslation } from 'react-i18next';
import { editSelectTheme, styleTableEditSelect } from '../../../ui/select_props';
import { validateEmail, validateText } from '../../../../helpers';

const currencyOptions = [
  {
    value: 'eur',
    label: 'EUR',
  },
  {
    value: 'usd',
    label: 'USD',
  },
];

@withTranslation()
class CustomerEditForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customer: props.customer ? { ...props.customer } : {
        coupon: '',
        name: '',
        clubEmail: '',
        currency: null,
      },
      validationRules: {
        text: ['name'],
        select: ['currency'],
        email: ['clubEmail'],
      },
      validate: null,
    };

    this.onCancelClick = this.onCancelClick.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.changeText = this.changeText.bind(this);
    this.changeSelect = this.changeSelect.bind(this);
  }

  onCancelClick() {
    const { onCancel } = this.props;
    onCancel();
  }

  validateForm() {
    const {
      customer,
    } = this.state;

    const errors = {};
    let isErrors = false;

    const { text, select, email } = this.state.validationRules;

    text.forEach((property) => {
      if (!validateText(customer[property], true, 1, 255)) {
        isErrors = true;
        errors[property] = true;
      }
    });

    email.forEach((property) => {
      if (!validateEmail(customer[property])) {
        isErrors = true;
        errors[property] = true;
      }
    });

    select.forEach((property) => {
      if (customer[property] === undefined || customer[property] === null) {
        isErrors = true;
        errors[property] = true;
      }
    });

    return isErrors ? errors : null;
  }

  setValidClass(property) {
    const { validate } = this.state;
    const isInvalidProperty = _.get(validate, property, false);
    return isInvalidProperty ? 'invalid' : '';
  }

  onSubmit() {
    const { onSubmit } = this.props;
    const { customer } = this.state;
    const errors = this.validateForm();

    if (errors) {
      return this.setState({
        validate: errors,
      });
    }

    return onSubmit && onSubmit(customer);
  }

  changeText(e, property) {
    this.setState({
      customer: {
        ...this.state.customer,
        [property]: e.target.value,
      },
    });
  }

  changeSelect(value, property) {
    this.setState({
      customer: {
        ...this.state.customer,
        [property]: value,
      },
    });
  }

  render() {
    const { t, isNew } = this.props;
    const { customer } = this.state;
    const currencyValue = currencyOptions.find((opt) => opt.value === customer.currency);

    return (
      <div className="customer-page__info">
        <div className="customer-page__row">
          <div className="customer-page__column">
            <label>{t('NAME')}</label>
            <input
              type="text"
              value={customer.name}
              className={this.setValidClass('name')}
              onChange={(e) => {
                this.changeText(e, 'name');
              }}
            />
          </div>
          <div className="customer-page__column">
            <label>{t('EMAIL')}</label>
            <input
              type="text"
              value={customer.clubEmail}
              className={this.setValidClass('clubEmail')}
              onChange={(e) => {
                this.changeText(e, 'clubEmail');
              }}
            />
          </div>
          {isNew ? (
            <div className="customer-page__column">
              <label>{t('CURRENCY')}</label>
              <Select
                value={currencyValue}
                options={currencyOptions}
                styles={styleTableEditSelect}
                theme={editSelectTheme}
                invalid={this.setValidClass('currency')}
                onChange={(value) => {
                  this.changeSelect(value.value, 'currency');
                }}
              />
            </div>
          ) : null}
          {isNew ? (
            <div className="customer-page__column">
              <label>{t('COUPON')}</label>
              <input
                type="text"
                value={customer.coupon}
                onChange={(e) => {
                  this.changeText(e, 'coupon');
                }}
              />
            </div>
          ) : null}
        </div>
        <div className="customer-page__form-buttons">
          <button
            className="btn btn-small btn-red"
            onClick={this.onCancelClick}
          >
            {t('CANCEL')}
          </button>
          <button
            className="btn btn-small"
            onClick={this.onSubmit}
          >
            {t('SAVE')}
          </button>
        </div>
      </div>
    );
  }
}

CustomerEditForm.propTypes = {
  customer: PropTypes.object,
  isNew: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  t: PropTypes.func,
};

export default CustomerEditForm;
