import axios from 'axios';
import { ROOT_URL } from '.';
import eventTracker from '../helpers/event-tracker';

export const FETCH_INVITES = 'FETCH_INVITES';
export const CREATE_INVITE = 'CREATE_INVITE';
export const DELETE_INVITE = 'DELETE_INVITE';

export function createInvite(inviteeEmail) {
  const request = axios.post(`${ROOT_URL}/invites/`, { inviteeEmail });
  return {
    type: CREATE_INVITE,
    payload: request,
  };
}

export function fetchInvites() {
  const request = axios.get(`${ROOT_URL}/invites/my`);

  return {
    type: FETCH_INVITES,
    payload: request,
  };
}

export function deleteInvite() {
  return {
    type: DELETE_INVITE,
    payload: invite,
  };
}
