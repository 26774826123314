import axios from 'axios';
import localforage from 'localforage';
import { ROOT_URL } from './index';
import { MATCHES_COLLECTION, PLAYERS_COLLECTION } from '../storageMiddleware/constants';

import { storageDeleteMatch } from '../storageMiddleware/index';

export const FETCH_REPORTS = 'FETCH_REPORTS';
export const FETCH_DEMO_REPORTS = 'FETCH_DEMO_REPORTS';
export const CLEAR_REPORTS = 'CLEAR_REPORTS';
export const SET_REPORTS_LOADING = 'SET_REPORTS_LOADING';
export const UPDATE_REPORT = 'UPDATE_REPORT';
export const DELETE_REPORT = 'DELETE_REPORT';
export const CHANGE_REPORT_FILTER = 'CHANGE_REPORT_FILTER';
export const GENERATE_REPORT_FILTERS = 'GENERATE_REPORT_FILTERS';
export const FETCH_REPORTS_OVERVIEW = 'FETCH_REPORTS_OVERVIEW';
export const FETCH_OFFLINE_REPORTS = 'FETCH_OFFLINE_REPORTS';

let controllers = [];
export function fetchReports(filter, skip, limit) {
  const filterJSON = JSON.stringify(filter);
  const controller = new AbortController();
  const request = axios.get(`${ROOT_URL}/v2/matches`, {
    signal: controller.signal,
    params: {
      skip, limit, filter: filterJSON,
    },
  }).catch(() => {
    return {
      type: FETCH_REPORTS,
      payload: {
        data: null,
        success: true,
      },
    };
  });

  controllers.push(controller);

  return {
    type: FETCH_REPORTS,
    payload: request,
  };
}

export function fetchDemo() {
  const request = axios.get(`${ROOT_URL}/v2/matches/demo`);

  return {
    type: FETCH_DEMO_REPORTS,
    payload: request,
  };
}

export function fetchOverview(league_id) {
  const request = axios.get(`${ROOT_URL}/v2/matches/overview`, { league_id });
  return {
    type: FETCH_REPORTS_OVERVIEW,
    payload: request,
  };
}

export function changeFilters(filters) {
  controllers.map((c) => c.abort());
  controllers = [];
  return {
    type: CHANGE_REPORT_FILTER,
    payload: filters,
  };
}

export function clearReports() {
  controllers.map((c) => c.abort());
  controllers = [];

  return {
    type: CLEAR_REPORTS,
  };
}

export function setReportsLoading(status) {
  return {
    type: SET_REPORTS_LOADING,
    payload: status,
  };
}

export function deleteReport(_id) {
  const request = storageDeleteMatch(_id);

  return {
    type: DELETE_REPORT,
    request,
    payload: _id,
  };
}

export function updateReport(values) {
  const request = axios.post(`${ROOT_URL}/match/`, { match: values });
  return {
    type: UPDATE_REPORT,
    payload: request,
  };
}

export function generateFilterOptions(values) {
  return {
    type: GENERATE_REPORT_FILTERS,
    payload: values,
  };
}

export function fetchOffline() {
  const userId = localStorage.getItem('userId');
  const gender = localStorage.getItem('selectedGender');
  const collectionName = `${MATCHES_COLLECTION}_${gender}`;
  const playersCollectionName = `${PLAYERS_COLLECTION}_${gender}`;
  const offline = localforage.getItem(`${collectionName}-${userId}`).then((values) => values);
  const offlinePlayers = localforage.getItem(`${playersCollectionName}-${userId}`).then((values) => values);
  return {
    type: FETCH_OFFLINE_REPORTS,
    payload: Promise.all([offline, offlinePlayers]),
  };
}
