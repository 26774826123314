import './area.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Players from './players';
import Positions from './positions';

@connect((state) => ({
  match: state.match,
}))

class Area extends Component {
  static propTypes = {
    match: PropTypes.object,
  };

  render() {
    const { layout, snapshot } = this.props.match;
    const { width, height } = layout;

    return (
      <div className="area">
        <svg className="area__svg" {...{ width, height }}>
          <pattern id="Pattern" x="0" y="0" width="147px" height="147px" patternUnits="userSpaceOnUse">
            <path fill="#148535" d="M1.5,147L147,1.5V75l-72,72H1.5z M0,74.9L74.9,0H1.4L0,1.4V74.9z" />
          </pattern>

          <rect fill="#0f6630" {...{ width, height }} />
          <rect fill="url(#Pattern)" {...{ width, height }} />

          <rect fill="transparent" stroke="rgba(255, 255, 255, .75)" className="pixel-perfect" x={0.5} y={0.5} width={Math.floor(width - 1)} height={Math.floor(height - 1)} />
          <line className="pixel-perfect" x1="50%" y1="0" x2="50%" y2="100%" stroke="rgba(255, 255, 255, .75)" />

          <rect fill="transparent" stroke="rgba(255, 255, 255, .75)" className="pixel-perfect" x={0.5} y={Math.floor((height - height * 0.5) / 2)} width={Math.floor(width * 0.17)} height={Math.floor(height * 0.5)} />
          <rect fill="transparent" stroke="rgba(255, 255, 255, .75)" className="pixel-perfect" x={0.5} y={Math.floor((height - height * 0.25) / 2)} width={Math.floor(width * 0.07)} height={Math.floor(height * 0.25)} />

          <rect fill="transparent" stroke="rgba(255, 255, 255, .75)" className="pixel-perfect" x={Math.floor(width - width * 0.17 + 1)} y={Math.floor((height - height * 0.5) / 2)} width={Math.floor(width * 0.17)} height={Math.floor(height * 0.5)} />
          <rect fill="transparent" stroke="rgba(255, 255, 255, .75)" className="pixel-perfect" x={Math.floor(width - width * 0.07 + 1)} y={Math.floor((height - height * 0.25) / 2)} width={Math.floor(width * 0.07)} height={Math.floor(height * 0.25)} />

          <circle fill="transparent" stroke="rgba(255, 255, 255, .75)" cx="50%" cy="50%" r={Math.floor(height * 0.25 / 2)} />
        </svg>

        <Players />
        { snapshot === 'lineup' ? <Positions /> : null }
      </div>
    );
  }
}

export default Area;
