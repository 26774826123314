import React from 'react';
import PropTypes from 'prop-types';

import './radio-switcher.scss';

export default function RadioSwitcher({
  options, input, isDisabled, ...rest
}) {
  return (
    <div className="radio-switcher">
      {
        options.map((option) => {
          const { value, icon, label } = option;
          return (
            <button
              key={value}
              type="button"
              className={input.value === value ? 'active' : ''}
              onClick={() => input.onChange(value)}
              disabled={isDisabled}
            >
              {icon}
              {label}
            </button>
          );
        })
      }
    </div>
  );
}

RadioSwitcher.propTypes = {
  options: PropTypes.array,
  input: PropTypes.any,
};
