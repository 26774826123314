import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InfiniteLoader from 'react-window-infinite-loader';

import VirtualList from '../../../ui/virtual-list';
import Customer from '../customer';

class CustomersList extends Component {
  constructor(props) {
    super(props);

    this.renderCustomer = this.renderCustomer.bind(this);
    this.loadNextPage = this.loadNextPage.bind(this);
  }

  componentDidMount() {
    const { customers } = this.props;
    this.props.fetch(0, customers.filterValues, customers.sortProperty, customers.sortDirection);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.customers.sortId !== prevProps.customers.sortId
      || this.props.customers.filterId !== prevProps.customers.filterId
    ) {
      const reset = this.infiniteLoaderRef.resetloadMoreItemsCache.bind(this.infiniteLoaderRef);
      reset();
    }
  }

  loadNextPage() {
    const { customers } = this.props;
    if (customers.hasNextPage && !customers.isListLoading) {
      const nextPage = customers.page + 1;
      this.props.fetch(nextPage, customers.filterValues, customers.sortProperty, customers.sortDirection);
    }
  }

  renderCustomer({ index, style }) {
    const {
      customers, edit, deactivate, remove,
    } = this.props;

    if (index >= customers.list.length) {
      return (
        <div className="customer-list-item__wrapper customer-list-item__loader" style={style}>
          <div className="customer-list-item">
            <div className="customer-list-item__container">
              Loading...
            </div>
          </div>
        </div>
      );
    }

    const customer = customers.list[index];
    return (
      <Customer
        key={customer._id}
        style={style}
        itemData={{ index, style, customer }}
        edit={edit}
        deactivate={deactivate}
        remove={remove}
      />
    );
  }

  render() {
    const { customers } = this.props;

    const itemCount = customers.hasNextPage ? customers.list.length + 1 : customers.list.length;
    const loadMoreItems = customers.isListLoading ? () => {} : this.loadNextPage;

    return (
      <InfiniteLoader
        ref={(il) => this.infiniteLoaderRef = il}
        isItemLoaded={(index) => !customers.hasNextPage || index < customers.list.length}
        itemCount={itemCount}
        loadMoreItems={loadMoreItems}
        minimumBatchSize={20}
        threshold={20}
      >
        {
          ({ onItemsRendered, ref }) => (
            <VirtualList
              item={this.renderCustomer}
              count={itemCount}
              onItemsRendered={onItemsRendered}
              ref={ref}
              itemSize={134}
              list={customers.list}
              classNames="customers__virtual-list"
            />
          )
        }
      </InfiniteLoader>
    );
  }
}

CustomersList.propTypes = {
  customers: PropTypes.object.isRequired,
  fetch: PropTypes.func.isRequired,
  edit: PropTypes.func,
  remove: PropTypes.func,
  deactivate: PropTypes.func,
};

export default CustomersList;
