import React, { Component } from 'react';
import { FixedSizeGrid as Grid } from 'react-window';
import PropTypes from 'prop-types';

export default class VirtualListGrid extends Component {
  static propTypes = {
    classNames: PropTypes.string,
    itemSize: PropTypes.array,
    item: PropTypes.func,
    count: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
    columnCount: PropTypes.number,
    rowCount: PropTypes.number,
  };

  render() {
    const {
      classNames, itemSize, item, count, height, width, columnCount, rowCount, ...rest
    } = this.props;

    const [itemWidth, itemHeight] = itemSize;

    return (
      <div
        className={`virtual-list-grid ${classNames}`}
      >
        <Grid
          itemCount={count}
          columnWidth={itemWidth}
          width={width}
          columnCount={columnCount}
          rowCount={rowCount}
          height={height}
          rowHeight={itemHeight}
          className="virtual-list-instance"
          {...rest}
        >
          {item}
        </Grid>
      </div>
    );
  }
}
