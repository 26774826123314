import reduxApi, { transformers } from 'redux-api';
import adapterFetch from 'redux-api/lib/adapters/fetch';

import { ROOT_URL } from '.';

const api = reduxApi({
  roles: {
    url: `${ROOT_URL}/roles`,
    reducerName: 'roles',
    transformer: transformers.array,
  },
  user: {
    url: `${ROOT_URL}/users/:id`,
    crud: true,
    reducerName: 'user',
  },
  users: {
    url: `${ROOT_URL}/users/`,
    crud: true,
    transformer: transformers.array,
    reducerName: 'users',
  },
  groupUsers: {
    url: `${ROOT_URL}/group/users/`,
    crud: true,
    transformer: transformers.array,
    reducerName: 'users',
  },
}).use('fetch', adapterFetch(fetch));

api.use('options', {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  credentials: 'include',
});

export default api;
