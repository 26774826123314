import axios from 'axios';

import _uniq from 'lodash/uniq';

import { ROOT_URL } from './index';
import eventTracker from '../helpers/event-tracker';
import DemoWatchlistMan from '../../demo_watchlist.json';
import DemoWatchlistWomen from '../../demo_watchlist_women.json';

export const FETCH_WATCHLISTS = 'FETCH_WATCHLISTS';
export const CREATE_WATCHLIST = 'CREATE_WATCHLIST';
export const DELETE_WATCHLIST = 'DELETE_WATCHLIST';
export const COPY_WATCHLIST = 'COPY_WATCHLIST';
export const SORT_WATCHLISTS = 'SORT_WATCHLISTS';

export const FETCH_WATCHLIST = 'FETCH_WATCHLIST';
export const UPDATE_WATCHLIST = 'UPDATE_WATCHLISTS';
export const RESET_CURRENT_WATCHLIST = 'RESET_CURRENT_WATCHLIST';

export function resetCurrentWatchlist() {
  return {
    type: RESET_CURRENT_WATCHLIST,
  };
}

export function fetchWatchlists() {
  const request = axios.get(`${ROOT_URL}/watchlists`);
  return {
    type: FETCH_WATCHLISTS,
    payload: request,
  };
}

export function createWatchlist(name, description, color_id, icon_id) {
  const request = axios.post(`${ROOT_URL}/watchlists/`, {
    name, description, color_id, icon_id,
  });
  eventTracker.trackEvent('Watchlists', 'Create watchlist');
  return {
    type: CREATE_WATCHLIST,
    payload: request,
  };
}

export function deleteWatchlist(id) {
  if (id !== 'demo') {
    axios.delete(`${ROOT_URL}/watchlists/${id}`);
    eventTracker.trackEvent('Watchlists', 'Delete watchlist');
    return {
      type: DELETE_WATCHLIST,
      payload: id,
    };
  }

  return {
    type: DELETE_WATCHLIST,
    payload: null,
  };
}

export function copyWatchlist(id) {
  const request = axios.get(`${ROOT_URL}/watchlists/copy/${id}`);

  eventTracker.trackEvent('Watchlists', 'Copy watchlist');

  return {
    type: COPY_WATCHLIST,
    payload: request,
  };
}

export function fetchWatchlist(id) {
  if (id !== 'demo') {
    const request = axios.get(`${ROOT_URL}/watchlists/${id}`);

    eventTracker.trackEvent('Watchlists', 'Open watchlist');

    return {
      type: FETCH_WATCHLIST,
      payload: request,
    };
  }

  const DemoWatchlist = window.genderContext === 'female' ? DemoWatchlistWomen : DemoWatchlistMan;

  return {
    type: FETCH_WATCHLIST,
    payload: Promise.resolve({ data: DemoWatchlist, status: 200 }),
  };
}

export function updateWatchlist(watchlist) {
  if (watchlist._id !== 'demo') {
    const upd = { ...watchlist };
    const result = { ...watchlist };
    upd.players = _uniq(upd.players);
    result.players = _uniq(result.players);
    delete upd.details;

    axios.post(`${ROOT_URL}/watchlists/${watchlist._id}`, upd);
    eventTracker.trackEvent('Watchlists', 'Update watchlist');

    return {
      type: UPDATE_WATCHLIST,
      payload: result,
    };
  }

  return {
    type: UPDATE_WATCHLIST,
    payload: { ...watchlist },
  };
}
