import './history.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import { withTranslation } from 'react-i18next';
import { outerControlLabel } from '../../../../helpers';
import { removeStory, decreaseGoal } from '../../../../actions/match';

import Icon from '../../../Icon';

@withTranslation()
@connect((state) => ({
  match: state.match,
  indicators: state.indicators,
}), { removeStory, decreaseGoal })

export default class History extends Component {
  static propTypes = {
    match: PropTypes.object,
    indicators: PropTypes.object,
    removeStory: PropTypes.func,
    height: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  };

  constructor() {
    super();

    this.state = {
      editEnable: false,
    };

    this.renderHistory = this.renderHistory.bind(this);
    this.removeStoryHandler = this.removeStoryHandler.bind(this);
    this.editModeChange = this.editModeChange.bind(this);
  }

  editModeChange() {
    const { editEnable } = this.state;
    this.setState({ editEnable: !editEnable });
  }

  removeStoryHandler(story, event) {
    const { decreaseGoal } = this.props;
    event.preventDefault();

    if (story.key === 'event_type' && story.value === 'goal') {
      decreaseGoal(story.side, story.time);
    }

    if (story.key === 'event_type' && story.value === 'own_goal') {
      decreaseGoal(story.side === 'home' ? 'away' : 'home', story.time);
    }

    this.props.removeStory(story);
  }

  renderHistory() {
    const { editEnable } = this.state;
    const {
      indicators, t, i18n, match,
    } = this.props;
    const lng = i18n.language;

    const { data, periods } = this.props.match;
    let history = [];

    const { type } = match;
    const isSingle = type === 'single';

    _.forEach(['home', 'away'], (side) => {
      _.forEach(data[side].values, (times, player_id) => {

        _.forEach(times, (values, time) => {
          if (_.size(values) > 2) {
            _.forEach(values, (value, key) => {
              if (key === 'player_id' || key === 'event_id' || (key === 'notes' && !value) || (key === 'event_type' && !value.length) || key === 'tags') {
                return;
              }

              if (key === 'event_type' && value.length) {
                value.map((v) => {
                  history.push({
                    player_id,
                    time,
                    side,
                    key,
                    value: v,
                    period: time.slice(0, 1),
                    min: time.slice(1, 4),
                    sec: time.slice(4, 6),
                  });
                });
              } else {
                history.push({
                  player_id,
                  time,
                  side,
                  key,
                  value,
                  period: time.slice(0, 1),
                  min: time.slice(1, 4),
                  sec: time.slice(4, 6),
                });
              }
            });
          }
        });
      });
    });

    history = _.orderBy(history, ['period', 'min', 'sec'], ['desc', 'desc', 'desc']);
    const groupedHistory = _.groupBy(history, 'period');

    let historyComponent = [];

    [4, 3, 2, 1, 0].map((periodIndex) => {
      if (!groupedHistory[periodIndex] || !groupedHistory[periodIndex].length) return null;
      const group = groupedHistory[periodIndex];
      const period = periodIndex;

      const periodLabel = _.get(periods, `[${period}].label`, 'PDF');

      historyComponent.push(<div className="history-group" key={period}>{t(periodLabel)}</div>);

      const stories = group.map((story, index) => {
        const {
          min, sec, side, player_id, key, value, time,
        } = story;

        const notIsEvent = key !== 'event_type';
        const indicator_name = notIsEvent ? _.get(indicators, ['info', key, `name_${lng}`], _.get(indicators, ['info', key, 'name'], '')) : t(value);

        const icon = key !== 'event_type' ? (<Icon name={key} className="story-icon" />)
          : (<Icon name={value.replaceAll('_', '')} className="story-icon story-icon__event" />);

        return (
          <div className={`story ${isSingle ? 'small-padding' : ''} ${key === 'note' ? 'story-note' : ''}`} key={`${player_id}_${time}_${key}_${value}_${index}`}>
            <div className={`story-time ${isSingle ? 'story-time__single' : ''}`}>
              <div>{ periodLabel !== 'PDF' ? `${`000${min}`.substr(min < 100 ? -2 : -3)}:${sec}` : null}</div>
            </div>
            {!isSingle ? (<div className="story-image" style={{ backgroundImage: `url(https://static.sp7f.com/teams/${data[side].team_id}.jpg)` }} />) : null}
            {!isSingle ? (<div className="story-name">{ data[side].players[player_id].information.last_name }</div>) : null}
            <div className={`story-value story-value${key !== 'notes' ? value : '_note'}`} key={key}>
              {icon}
              { indicator_name }
              {' '}
              { key !== 'notes' ? outerControlLabel(value) : value }
            </div>
            {
              editEnable
                ? (
                  <a
                    href="#"
                    onClick={(e) => this.removeStoryHandler({
                      side, player_id, time, key, value,
                    }, e)}
                    className="story-delete"
                  >
                    <Icon name="delete" />
                  </a>
                )
                : null
            }
          </div>
        );
      });
      historyComponent = historyComponent.concat(stories);
    });

    return historyComponent;
  }

  render() {
    const { height, t } = this.props;
    const { editEnable } = this.state;

    return (
      <div className={`history ${editEnable ? 'edit' : ''}`}>
        <div className="history-header">
          <b className="title">{t('LATEST_EVENTS')}</b>
          {' '}
          <a onClick={this.editModeChange} href="#" className="edit-btn">{editEnable ? t('CANCEL') : t('EDIT_EVENTS')}</a>
        </div>
        <div className="history-list" style={{ height }}>
          { this.renderHistory() }
        </div>
      </div>
    );
  }
}
