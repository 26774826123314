import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Input extends Component {
  static propTypes = {
    defaultValue: PropTypes.string,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    unit: PropTypes.string,
  };

  constructor() {
    super();

    this.state = {
      value: '',
      edit: false,
    };

    this.focusHandler = this.focusHandler.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.blurHandler = this.blurHandler.bind(this);
  }

  componentDidMount() {
    this.setState({
      value: this.props.defaultValue,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.defaultValue !== this.state.value) {
      this.setState({
        value: nextProps.defaultValue,
      });
    }
  }

  focusHandler() {
    this.setState({
      edit: true,
    });
    this.props.onFocus();
  }

  changeHandler(event) {
    if (event.target.value.length < 3) {
      this.setState({
        edit: true,
        value: event.target.value,
      });
    }
  }

  blurHandler(event) {
    const { defaultValue, unit } = this.props;
    const value = parseInt(event.target.value);

    this.setState({
      edit: false,
    });

    this.props.onBlur(unit, ((value || value === 0) && typeof value === 'number') ? value : parseInt(defaultValue));
  }

  render() {
    const { value } = this.state;
    return (
      <input
        type="number"
        value={value}
        onFocus={this.focusHandler}
        onChange={this.changeHandler}
        onBlur={this.blurHandler}
      />
    );
  }
}
