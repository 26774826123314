import React, { Component } from 'react';
import { connect } from 'react-redux';
import Icon from '../Icon';
import './gender-switcher.scss';
import { updateUser } from '../../actions/auth';
import { bindActionCreators } from 'redux';
import accountApi from '../../actions/account';
import { withTranslation } from 'react-i18next';

@withTranslation()
@connect((state) => {
  return {
    user: state.auth.user,
  };
}, (dispatch) => bindActionCreators({
  dispatch,
  updateUser,
}, dispatch))
class GenderSwitcher extends Component {
  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);
  }

  submit(gender) {
    const { user } = this.props;
    const { selectedGender } = user;

    if (selectedGender === gender) {
      return;
    }

    this.props.dispatch(accountApi.actions.user.put({
      id: this.props.user._id,
    }, {
      body: JSON.stringify({ selectedGender: gender }),
    }, (err, result) => {
      window.location.reload();
    }));
  }

  render() {
    const { user, t } = this.props;
    const { selectedGender } = user;
    return (
      <div className="gender-switcher">
        <button className={`${selectedGender === 'male' ? 'active' : ''}`} onClick={() => this.submit('male')}>
          <Icon name="men" />
          {t('MALE')}
        </button>
        <button className={`${selectedGender === 'female' ? 'active' : ''}`} onClick={() => this.submit('female')}>
          <Icon name="women" />
          {t('FEMALE')}
        </button>
      </div>
    );
  }
}

export default GenderSwitcher;
