import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { browserHistory, Link } from 'react-router';
import { changeLoading, fetchAddMatch } from '../../actions';
import {
  fetchReports, clearReports, setReportsLoading, deleteReport, updateReport, changeFilters, generateFilterOptions, fetchOverview, fetchOffline,
} from '../../actions/reports';
import { updateReportsLimits } from '../../actions/subscription';
import ReportsControls from './components/reports-controls';
import { getFormattedToday } from '../../helpers';

import './reports-overview.scss';
import Icon from '../Icon';

import UpcomingCard from '../ui/match-card/upcoming';
import MatchCard from '../ui/match-card/index';
import ShadowMatchBlock from '../ui/match-card/shadow';
import EditMatch from '../ui/match-edit-form';
import { canAddReport } from '../../helpers/limits';
import eventTracker from '../../helpers/event-tracker';
import DemoMessage from '../ui/demo-message';
import GroupAd from './components/group-ad';

function Overview({
  user,
  reports,
  teams,
  leagues,
  subscription,
  fetchOverview,
  changeLoading,
  fetchAddMatch,
  updateReportsLimits,
  updateReport,
  deleteReport,
  fetchOffline,
}) {
  const { t, i18n } = useTranslation();
  const currentDate = getFormattedToday(i18n.language);
  const [edit, setEdit] = useState(null);
  const [add, setAdd] = useState(null);

  const canUseGroup = subscription.scouts.max !== 1;

  const {
    player, team, group, upcoming,
  } = reports.overview;
  const { players, demoSingle, demoMatch } = reports;
  const isNormalUser = user?.role?.tag === 'user';

  useEffect(() => {
    changeLoading({ component: 'app', loading: true });
    fetchOverview().then(() => {
      changeLoading({ component: 'app', loading: false });
    });
  }, []);

  const onSubmitMatch = ({ newMatch, oldMatch }) => {
    if (add) {
      const {
        date, stadium, home, away, league, assign_to, type, player_id,
      } = newMatch;

      if (!canAddReport(subscription)) {
        return null;
      }

      setAdd(false);
      updateReportsLimits({ current: subscription.reports.current + 1 });
      return fetchAddMatch({
        date,
        stadium_id: stadium,
        league_id: league,
        home_team_id: home,
        away_team_id: away,
        assign_to,
        type,
        player_id,
      }, subscription).then((result) => {
        if (result.payload.match) {
          eventTracker.trackEvent('homepage', 'match created');
          browserHistory.push(`/match/${result.payload.match.match_id}`);
        }
      }).catch((err) => {
        console.log(err);
      });
    }

    const updObject = {
      _id: oldMatch._id,
      stadium_id: newMatch.stadium,
      league_id: newMatch.league,
      date: newMatch.date,
      assign_to: newMatch.assign_to,
    };
    delete updObject.home;
    delete updObject.away;
    delete updObject.player;
    delete updObject.player_id;
    delete updObject.start;
    setEdit(false);
    updateReport(updObject);
  };

  const onDelete = (_id) => {
    changeLoading({ component: 'app', loading: true });
    const action = deleteReport(_id);
    setEdit(false);
    action.request.then(() => {
      fetchOverview().then(() => {
        setTimeout(() => {
          fetchOffline();
          changeLoading({ component: 'app', loading: false });
        }, 350);
      });
    });
  };

  const renderUpcoming = (report, index) => {
    return (
      <UpcomingCard
        key={index}
        report={report}
        index={index}
        teams={teams}
        leagues={leagues}
        onClick={(match) => {
          const newMatch = {
            home: { team_id: match.team_id_home },
            away: { team_id: match.team_id_away },
            type: 'match',
            date: new Date(match.date),
            league_id: match.league_id,
          };
          setAdd(newMatch);
        }}
        t={t}
        lng={i18n.language}
      />
    );
  };

  const renderMatch = (report, index) => {
    return (
      <MatchCard
        key={index}
        report={report}
        index={index}
        teams={teams}
        leagues={leagues}
        t={t}
        lng={i18n.language}
        authUser={user}
        players={players}
        onEdit={(report) => setEdit(report)}
      />
    );
  };

  // 406

  const teamSection = team.length >= 3 ? team.map((report, index) => renderMatch(report, index))
    : (
      <>
        {renderMatch(demoMatch, 0)}
        {team.map((report, index) => renderMatch(report, index))}
        {team.length === 0 ? <DemoMessage text={t('TEAM_MATCH_DEMO_MESSAGE')} /> : null}
      </>
    );

  const playerSection = player.length >= 3 ? player.map((report, index) => renderMatch(report, index))
    : (
      <>
        {renderMatch(demoSingle, 0)}
        {player.map((report, index) => renderMatch(report, index))}
        {player.length === 0 ? <DemoMessage className="player-demo-message" text={t('SINGLE_MATCH_DEMO_MESSAGE', { context: window.genderContext })} arrowPosition="top-right" /> : null}
      </>
    );

  let groupColumn = null;

  const emptyMessage = isNormalUser ? t('EMPTY_GROUP_USER') : t('EMPTY_GROUP_ADMIN');
  const noGroupReports = (
    <>
      <ShadowMatchBlock />
      <DemoMessage text={emptyMessage} />
    </>
  );

  if (isNormalUser) {
    groupColumn = null;
    const groupHeading = (
      <Link to="/reports/assigned/">
        { t('REPORTS_ASSIGNED_TO_ME') }
        <Icon name="chevron-right-new" className="chevron-right" />
      </Link>
    );

    const groupSection = group.length ? group.map((report, index) => renderMatch(report, index)) : noGroupReports;

    groupColumn = (
      <div className="reports-overview__col">
        <h2 className="overview-heading">
          { groupHeading }
        </h2>
        <div className="reports-overview__matches__column">
          {groupSection}
        </div>
      </div>
    );
  } else {
    const groupContent = canUseGroup && group.length ? group.map((report, index) => renderMatch(report, index)) : noGroupReports;
    const groupSection = canUseGroup ? groupContent : <GroupAd />;

    const groupHeading = canUseGroup ? (
      <Link to="/reports/group/">
        {t('REPORTS_OF_MY_GROUP')}
        <Icon name="chevron-right-new" className="chevron-right" />
      </Link>
    ) : t('REPORTS_OF_MY_GROUP');

    groupColumn = (
      <div className="reports-overview__col">
        <h2 className="overview-heading">
          { groupHeading }
        </h2>
        <div className="reports-overview__matches__column">
          {groupSection}
        </div>
      </div>
    );
  }

  return (
    <div className="reports-overview">
      <ReportsControls
        teams={teams}
        type="overview"
      />
      <div className="reports-overview__block__upcoming">
        <p className="today-date">{currentDate}</p>
        <h2 className="overview-heading">
          <Link to="/reports/coming-up">
            {t('COMING_UP')}
            <Icon name="chevron-right-new" className="chevron-right" />
          </Link>
        </h2>
        <div className="reports-overview__matches__row">
          {upcoming.map((report, index) => renderUpcoming(report, index))}
        </div>
      </div>
      <div className="reports-overview__block">
        <div className="reports-overview__col">
          <h2 className="overview-heading">
            <Link to="/reports/team/">
              {t('TEAM_REPORTS')}
              <Icon name="chevron-right-new" className="chevron-right" />
            </Link>
          </h2>
          <div className="reports-overview__matches__column">
            {teamSection}
          </div>
        </div>
        <div className="reports-overview__col">
          <h2 className="overview-heading">
            <Link to="/reports/players/">
              {t('PLAYER_REPORTS')}
              <Icon name="chevron-right-new" className="chevron-right" />
            </Link>
          </h2>
          <div className="reports-overview__matches__column">
            {playerSection}
          </div>
        </div>
        {groupColumn}
      </div>
      {edit || add
        ? (
          <EditMatch
            isAdd={!!add}
            match={edit || add}
            onClose={
              () => {
                setEdit(false);
                setAdd(false);
              }
            }
            onSubmit={onSubmitMatch}
            onDelete={onDelete}
            players={players}
          />
        )
        : null}
    </div>
  );
}

export default connect((state, props) => {
  return {
    teams: state.teams,
    leagues: state.leagues,
    users: state.users,
    reports: state.reports,
    positions: state.positions,
    subscription: state.subscription,
    user: state.auth.user,
    type: props.params.type,
  };
}, {
  fetchAddMatch,
  fetchReports,
  clearReports,
  setReportsLoading,
  deleteReport,
  updateReport,
  changeFilters,
  generateFilterOptions,
  fetchOverview,
  changeLoading,
  updateReportsLimits,
  fetchOffline,
})(Overview);

Overview.propTypes = {
  type: PropTypes.string,
  fetchReports: PropTypes.func,
  teams: PropTypes.object,
  positions: PropTypes.object,
  leagues: PropTypes.object,
  reports: PropTypes.object,
  user: PropTypes.object,
  subscription: PropTypes.object,
  changeFilters: PropTypes.func,
  fetchOverview: PropTypes.func,
  changeLoading: PropTypes.func,
  updateReportsLimits: PropTypes.func,
  updateReport: PropTypes.func,
  fetchAddMatch: PropTypes.func,
  deleteReport: PropTypes.func,
  fetchOffline: PropTypes.func,
};
