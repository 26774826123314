import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _find from 'lodash/find';
import _mapValues from 'lodash/mapValues';
import moment from 'moment';
import _omitBy from 'lodash/omitBy'
import _isEqual from 'lodash/isEqual'

import './favorites-filter.scss';

import * as selectors from './selectors';
import { normalizeValuesForFilterConstructor } from './utils';
import { changeFavoritesFilter, resetFilter } from '../../../actions/favorites';

import FiltersConstructor from '../../ui/filters-constructor';
import Icon from '../../Icon';

const FavoritesFilter = props => {
	const { t, i18n } = useTranslation();
	const dispatch = useDispatch();
	const favoriteFilters = useSelector(state => state.favorites.filter);
	const filterInitial = useSelector(state => state.favorites.filterInitial);
	const positionOptions = useSelector(state => selectors.getPositionOptions(state, { i18n }));
	const scoutOptions = useSelector(state => selectors.getScoutOptions(state, { i18n }));
	const subscription = useSelector(state => state.subscription);
	const user = useSelector(state => state.auth.user);

	const teams_options = [{ label: t('ALL_TEAMS'), value: -1 }];

	const filters = [
		{
			type: 'select-teams',
			name: t('TEAM'),
			field: 'team',
			options: teams_options,
		},
		{
			type: 'select',
			name: t('POSITION'),
			field: 'position',
			options: positionOptions,
		}, {
			type: 'input-range',
			name: t('AGE'),
			field: 'age',
			minValue: favoriteFilters.age.minValue,
			maxValue: favoriteFilters.age.maxValue,
			formatLabel: (value) => (
				<span>
					{value}
					{' '}
					(<i>{moment().subtract(value, 'years').format('YYYY')}</i>)
				</span>
			)
		}, {
			type: 'slider',
			name: t('COL_OVERALL_RATING'),
			field: 'final_rating',
			minValue: 0,
			maxValue: 5,
		}, {
			type: 'player-category',
			name: t('CATEGORY'),
			field: 'category',
		}
	];

	if (user.role.tag !== 'user' && scoutOptions.length > 3 && !subscription.isFree) {
		filters.unshift({
			type: 'select',
			name: t('FAV_BY', { context: window.genderContext }),
			field: 'list_user_id',
			options: scoutOptions,
		})
	}

	const onFilterChange = ({ inputValue }, filter) => {
		const { type, name, field, options } = filter;

		let value;
		switch (field) {
			case 'age':
			case 'final_rating': {
				value = { min: inputValue[0], max: inputValue[1] }
				break;
			}
			case 'position': {
				value = _find(options, { value: inputValue });
				break;
			}
			default: {
				value = inputValue;
			}
		}

		dispatch(changeFavoritesFilter({
			[field]: value,
		}));
	}

	const clearAllFilters = () => dispatch(resetFilter());

	const values = normalizeValuesForFilterConstructor(favoriteFilters, filters);
	const changedFilters = Object.keys(_omitBy(favoriteFilters, (values, key) =>  key === 'name' || _isEqual(filterInitial[key], values)))?.length || 0;

	return (
		<div className="favorites-filter">
			<FiltersConstructor
				classNames="reports-filters"
				filters={filters}
				values={values}
				onFilterChange={onFilterChange}
				applyFilters={() => {}}
				renderInline
				clearAllFilters={clearAllFilters}
				BtnComponent={({ onClick, active }) => (
					<button
						type="button"
						onClick={onClick}
						className={`btn btn--filter ${active ? 'active' : ''}`}
					>
						<Icon name="filter" />
						{ changedFilters ? <span className="btn--filter__num">{changedFilters}</span> : null }
					</button>
				)}
			/>
		</div>
	);
}

FavoritesFilter.propTypes = {

}

export default FavoritesFilter;
