import axios from 'axios';
import localforage from 'localforage';
import moment from 'moment';
import _ from 'lodash';

import store from '../store';

import { ROOT_URL } from '../actions';
import { MATCHES_COLLECTION } from './constants';

export default function (props) {
  const userId = localStorage.getItem('userId');
  const gender = localStorage.getItem('selectedGender');
  const collectionName = `${MATCHES_COLLECTION}_${gender}`;

  const localHistoryCollection = `history-${collectionName}-${userId}`;

  switch (window.online) {
    case true:
      return Promise
        .all([
          axios.get(`${ROOT_URL}/matches`, { params: { ...props, notFetchGroup: !!window.isiPad } }),
          localforage.getItem(`${collectionName}-${userId}`),
          localforage.getItem(localHistoryCollection),
        ])
        .then(([resServer, matchesClient, history]) => {
          matchesClient = matchesClient || [];
          history = JSON.parse(history);

          const matchesServer = resServer.data.matches || [];
          const { total_count } = resServer.data;

          history = history || [];

          if (matchesClient && matchesClient.length) {
            let matchesClientClone = _.keyBy(matchesClient, (match) => match._id);
            const matchesServerClone = _.keyBy(matchesServer, (match) => match._id);

            _.forEach(matchesServerClone, (serverMatch, key) => {
              if (matchesClientClone[key]) {
                const isDemo = _.get(matchesClientClone, `[${key}].demo`, false);
                const user = _.get(matchesClientClone, `[${key}].user_id`, null);
                if (isDemo || +user !== +userId) {
                  matchesClientClone[key] = serverMatch;
                  return;
                }

                if (moment(serverMatch.ts).unix() > moment(matchesClientClone[key].ts).unix()) {
                  const { teams } = store.getState();
                  const home_team = teams[matchesClientClone[key].home.team_id];
                  const away_team = teams[matchesClientClone[key].away.team_id];
                  if (confirm(`Do you really want update match ${home_team && home_team.name || 'undefined'} vs ${away_team && away_team.name || 'undefined'} from server?`)) {
                    // delete local history for this match
                    history = history.filter((record) => record.match_id === serverMatch._id);
                    matchesClientClone[key] = serverMatch;
                  } else {
                    delete matchesClientClone[key].ts;
                    axios.post(`${ROOT_URL}/match/`, {
                      match: matchesClientClone[key],
                    });
                  }
                } else {
                  matchesClientClone[key] = serverMatch;
                }
              } else {
                matchesClientClone[key] = serverMatch;
                console.info('add match from server');
              }
            });

            matchesClientClone = _.filter(matchesClientClone, (match) => match && match.match_id && matchesServerClone[match._id]);

            if (history.length) {
            	axios.post(`${ROOT_URL}/history/pack/`, { history });
            }

            localforage.setItem(`${collectionName}-${userId}`, matchesClientClone);
            localforage.setItem(localHistoryCollection, JSON.stringify([]));

            return Promise.resolve({
              matches: matchesClientClone,
              total_count,
            });
          }

          localforage.setItem(`${collectionName}-${userId}`, matchesServer);
          return Promise.resolve({
            matches: matchesServer,
            total_count,
          });
        })
        .catch((err) => {
          console.error(err);
        });

    case false:
      return localforage
        .getItem(`${collectionName}-${userId}`)
        .then((matches) => Promise.resolve({ matches: matches || [] }))
        .catch((err) => {
          console.error(err);
        });
  }
}
