import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';

import './checkbox.scss';

class Checkbox extends Component {
  render() {
    const { isChecked } = this.props;
    return (
      <span className={`c-checkbox ${isChecked ? 'checked' : ''}`} onClick={this.props.onClick}>
        { isChecked ? <Icon className="checkbox-mark" name="checkmark" /> : null }
      </span>
    );
  }
}

Checkbox.propTypes = {
  isChecked: PropTypes.bool,
  onClick: PropTypes.func,
};

export default Checkbox;
