import React from 'react';
import PropTypes from 'prop-types';

import './match-card.scss';

export default function MatchLoader() {
  return (
    <div className="match-card match-card__shadow" />
  );
}

MatchLoader.propTypes = {
  noAnimation: PropTypes.bool,
};
