import _ from 'lodash';
import {
  FETCH_SUBSCRIPTION,
  UPDATE_FAVORITE_LIMITS,
  UPDATE_REPORTS_LIMITS,
  UPDATE_USERS_LIMITS,
  UPDATE_SHADOW_TEAM_LIMITS, UPDATE_WATCHLISTS_LIMITS,
} from '../actions/subscription';
import eventTracker from '../helpers/event-tracker';

const INITIAL_STATE = null;

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_SUBSCRIPTION:
      const plan = _.get(action.payload, 'data.plan.title', 'null');
      const trial = _.get(action.payload, 'data.isTrial', 'false');
      const active = _.get(action.payload, 'data.active', 'false');

      eventTracker.trackPlanAndTrial(trial, plan, active);
      return {
        ...state,
        ...action.payload.data,
      };
    case UPDATE_REPORTS_LIMITS:
      return {
        ...state,
        reports: {
          ...state.reports,
          ...action.payload,
        },
      };

    case UPDATE_FAVORITE_LIMITS:
      return {
        ...state,
        favorites: {
          ...state.favorites,
          ...action.payload,
        },
      };

    case UPDATE_USERS_LIMITS:
      return {
        ...state,
        scouts: {
          ...state.scouts,
          ...action.payload,
        },
      };

    case UPDATE_SHADOW_TEAM_LIMITS:
      return {
        ...state,
        shadowTeams: {
          ...state.shadowTeams,
          ...action.payload,
        },
      };

    case UPDATE_WATCHLISTS_LIMITS:
      return {
        ...state,
        watchlists: {
          ...state.watchlists,
          ...action.payload,
        },
      };

    default:
      return state;
  }
}
