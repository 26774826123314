import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Portal } from 'react-portal';
import FadeScreen from '../ui/fade-screen';
import Checkbox from '../ui/checkbox';
import BgImage from '../../../images/auth_bg3.jpg';

import './survey.scss';
import { REASONS } from '../../constants';
import Icon from '../Icon';

@withTranslation()
class Survey extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: [],
      otherReason: '',
      maxSelected: 2,
      error: false,
    };

    this.submit = this.submit.bind(this);
    this.onSelect = this.onSelect.bind(this);
  }

  onSelect(id, forceTrue) {
    const { selected, maxSelected } = this.state;
    const index = selected.indexOf(id);

    if (index !== -1) {
      if (!forceTrue) {
        this.setState({ selected: [...selected.slice(0, index), ...selected.slice(index + 1)] });
      }
    } else {
      this.setState({
        selected: selected.length >= maxSelected ? [...selected.slice(0, maxSelected - 1), id] : [...selected, id],
      });
    }
  }

  submit() {
    const { onSubmit } = this.props;
    const { selected, otherReason } = this.state;
    const isOther = selected.indexOf(3) !== -1;

    if (isOther && !otherReason) {
      return this.setState({ error: 'other' });
    }

    if (!selected.length) {
      return this.setState({ error: 'no-selected' });
    }

    this.setState({ error: false });
    return onSubmit && onSubmit({ reasonsId: selected, otherReasonText: otherReason });
  }

  renderReason({
    id, name, description, icon, iconStyle,
  }) {
    const { t } = this.props;
    const { selected, otherReason, error } = this.state;
    const isChecked = selected.indexOf(id) !== -1;

    return (
      <li key={id} className="reason" onClick={() => { this.onSelect(id); }}>
        <Checkbox isChecked={isChecked} />
        <p className="reason-name">
          <Icon name={icon} style={iconStyle ? { ...iconStyle } : {}} />
          {t(name)}
        </p>
        {
          description
            ? <p>{t(description)}</p>
            : (
              <input
                type="text"
                className={`other-input ${error === 'other' && isChecked ? 'error' : null}`}
                value={otherReason}
                placeholder={t('PLEASE_SPECIFY')}
                onClick={(e) => e.stopPropagation()}
                onFocus={() => { this.onSelect(id, true); }}
                onChange={(e) => { this.setState({ otherReason: e.target.value }); }}
              />
            )
        }
      </li>
    );
  }

  render() {
    const { t } = this.props;
    const { selected } = this.state;

    return (
      <Portal>
        <FadeScreen image={BgImage} withLogo>
          <div className="survey-form">
            <h1>{t('SURVEY_TITLE')}</h1>
            <h3>{t('SURVEY_SUB_TITLE')}</h3>
            <ul>
              { REASONS.map((reason) => this.renderReason(reason)) }
            </ul>
            <div className="submit-row">
              <button
                className={`btn submit ${!selected.length ? 'disabled' : ''}`}
                onClick={this.submit}
              >
                {t('SUBMIT')}
              </button>
            </div>
          </div>
        </FadeScreen>
      </Portal>
    );
  }
}

Survey.propTypes = {
  t: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default Survey;
