import React from 'react';
import PropTypes from 'prop-types';
import './reports-controls.scss';
import { useTranslation } from 'react-i18next';
import { browserHistory } from 'react-router';

import MatchesSearch from '../../ui/matches-search';
import CreateReport from './create-report';
import Icon from '../../Icon';
import FiltersConstructor from '../../ui/filters-constructor';

import './report-filters.scss';
import eventTracker from '../../../helpers/event-tracker';

export default function ReportsControls({
  teams, filtersList, filters, changeFilters, type,
}) {
  const { t } = useTranslation();

  let filtersLength = 0;
  let isFilterActive = false;

  if (filters) {
    const keys = Object.keys(filters);
    filtersLength = keys.map((key) => filters[key]).filter((filter) => {
      if (!filter.inputValue) return false;
      if (typeof filter.inputValue === 'object') {
        if (!filter.inputValue.value && !filter.inputValue.start && !filter.inputValue.end) return false;
      }
      return true;
    }).length;
    isFilterActive = filtersLength > 0;
  }

  return (
    <div className="reports__controls">
      <div className="reports__controls__search">
        <MatchesSearch
          teams={teams}
          noResultsText={(props) => {
            if (!props.inputValue) { return null; }
            return t('NO_REPORTS_FOUND', { context: window.genderContext });
          }}
          onChange={(value) => { browserHistory.push(`/pdf/${value.value}/`); }}
        />
      </div>
      {
        type !== 'overview' ? (
          <div className="reports__controls__filter">
            <FiltersConstructor
              filters={filtersList}
              classNames="reports-filters"
              onFilterChange={
                (values, filterName) => {
                  eventTracker.trackEvent('Reports', 'Use filter');
                  changeFilters({
                    ...filters,
                    [filterName.field]: values,
                    type,
                  });
                }
              }
              clearAllFilters={() => {
                changeFilters({ type });
              }}
              values={filters}
              t={t}
              renderInline
              applyFilters={() => {}}
              BtnComponent={({ onClick, active }) => (
                <button
                  type="button"
                  onClick={onClick}
                  className={`btn btn__filter ${active || isFilterActive ? 'active' : ''}`}
                >
                  <Icon name="filter" />
                  {isFilterActive ? (<span className="btn__filter__num">{filtersLength}</span>) : null}
                </button>
              )}
            />
          </div>
        ) : null
      }
      <div className="reports__controls__new">
        <CreateReport t={t} />
      </div>
    </div>
  );
}

ReportsControls.propTypes = {
  teams: PropTypes.object,
  positions: PropTypes.object,
  filtersList: PropTypes.array,
  filters: PropTypes.object,
  changeFilters: PropTypes.func,
  type: PropTypes.string,
};
