import React from 'react';
import { components } from 'react-select';
import LeagueView from '../league-view';

export default function (props) {
  const { data } = props;
  return (
    <components.Option {...props} firstLevel={data.firstLevel || false}>
      <LeagueView {...data} />
    </components.Option>
  );
}
