import React from 'react';

export function Dashboard({ lang }) {
  switch (lang) {
    case 'de':
      return (
        <div>
          <h1>Dein dashboard</h1>
          <p className="hero">Dieses Video-Tutorial erklärt, wie du dich schnell und einfach mit dem Dashboard durch die unterschiedlichen Bereiche wie Favoriten, Schattenteams und mehr navigierst.</p>
          <div dangerouslySetInnerHTML={{ __html: "<iframe src='https://player.vimeo.com/video/473354269' width='800' height='450' frameborder='0' webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>" }} />
        </div>
      );
    case 'pt':
      return (
        <div>
          <h1>O seu Painel de instrumentos</h1>
          <p className="hero">Este vídeo tutorial explica como navegar através do seu painel de instrumentos para aceder rápida e facilmente às secções personalizadas do seu Scoutpad, tais como Watchlist, Shadow team e muito mais.</p>
          <div dangerouslySetInnerHTML={{ __html: "<iframe src='https://player.vimeo.com/video/473354269' width='800' height='450' frameborder='0' webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>" }} />
        </div>
      );
    default:
      return (
        <div>
          <h1>Your dashboard</h1>
          <p className="hero">This video tutorial explains how to navigate through your dashboard to quickly and easily access your personalised Scoutpad sections such as Watchlist, Shadow team and more.</p>
          <div dangerouslySetInnerHTML={{ __html: "<iframe src='https://player.vimeo.com/video/473354269' width='800' height='450' frameborder='0' webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>" }} />
        </div>
      );
  }
}
export function StartMatch({ lang }) {
  switch (lang) {
    case 'de':
      return (
        <div>
          <h1>Erstelle ein spiel</h1>
          <p className="hero">Dieses Video-Tutorial zeigt, wie man ein neues Spiel für einen Scouting-Bericht erstellt und gibt Einblicke in den Import von Daten fehlender Spieler, Mannschaften oder Wettbewerbe.</p>
          <div dangerouslySetInnerHTML={{ __html: "<iframe src='https://player.vimeo.com/video/477927707' width='800' height='450' frameborder='0' webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>" }} />
        </div>
      );
    case 'pt':
      return (
        <div>
          <h1>Comece uma partida</h1>
          <p className="hero">Este tutorial em vídeo explica como criar um novo jogo para um relatório de escuta e dá uma visão sobre a importação de dados de jogadores, equipas ou competições em falta.</p>
          <div dangerouslySetInnerHTML={{ __html: "<iframe src='https://player.vimeo.com/video/477927707' width='800' height='450' frameborder='0' webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>" }} />
        </div>
      );
    default:
      return (
        <div>
          <h1>Start a match</h1>
          <p className="hero">This video tutorial explains how to create a new match for a scouting report and give insights on importing data of missing players, teams or competitions.</p>
          <div dangerouslySetInnerHTML={{ __html: "<iframe src='https://player.vimeo.com/video/477927707' width='800' height='450' frameborder='0' webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>" }} />
        </div>
      );
  }
}
export function EvaluatePlayers({ lang }) {
  switch (lang) {
    case 'de':
      return (
        <div>
          <h1>Bewerte einen spieler</h1>
          <p className="hero">Dieses Video-Tutorial veranschaulicht, wie man die Leistungen von Spielern mit vordefinierten Indikatoren bewertet und umfangreiche Notizen mit intelligenten Filtersystem erstellt.</p>
          <div dangerouslySetInnerHTML={{ __html: "<iframe src='https://player.vimeo.com/video/478372318' width='800' height='450' frameborder='0' webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>" }} />
        </div>
      );
    case 'pt':
      return (
        <div>
          <h1>Avaliar um jogador</h1>
          <p className="hero">Este vídeo tutorial descreve como avaliar o desempenho do leitor com atalhos pré-determinados e gravar notas poderosas com um sistema de filtragem inteligente.</p>
          <div dangerouslySetInnerHTML={{ __html: "<iframe src='https://player.vimeo.com/video/478372318' width='800' height='450' frameborder='0' webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>" }} />
        </div>
      );
    default:
      return (
        <div>
          <h1>Evaluate players</h1>
          <p className="hero">This video tutorial outlines how to evaluate player performances with pre-determined shortcuts and record powerful notes with a smart filtering system.</p>
          <div dangerouslySetInnerHTML={{ __html: "<iframe src='https://player.vimeo.com/video/478372318' width='800' height='450' frameborder='0' webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>" }} />
        </div>
      );
  }
}
export function DetailsPage({ lang }) {
  switch (lang) {
    case 'de':
      return (
        <div>
          <h1>Spieler-detailseite</h1>
          <p className="hero">Dieses Video-Tutorial veranschaulicht, wie du mit Hilfe der Spielerdetailseite deine Berichtsstatistiken und Spielernotizen effizient verwalten und organisieren kannst.</p>
          <div dangerouslySetInnerHTML={{ __html: "<iframe src='https://player.vimeo.com/video/478508153' width='800' height='450' frameborder='0' webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>" }} />
        </div>
      );
    case 'pt':
      return (
        <div>
          <h1>Página de reprodução de detalhes</h1>
          <p className="hero">Este tutorial em vídeo dá uma visão sobre a gestão das estatísticas do seu relatório e notas na sua lista de observação através da página de detalhes do leitor.</p>
          <div dangerouslySetInnerHTML={{ __html: "<iframe src='https://player.vimeo.com/video/478508153' width='800' height='450' frameborder='0' webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>" }} />
        </div>
      );
    default:
      return (
        <div>
          <h1>Detail player page</h1>
          <p className="hero">This video tutorial gives insights into managing your report statistics and notes on your watchlist by means of the player detail page.</p>
          <div dangerouslySetInnerHTML={{ __html: "<iframe src='https://player.vimeo.com/video/478508153' width='800' height='450' frameborder='0' webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>" }} />
        </div>
      );
  }
}
export function SquadManagement({ lang }) {
  switch (lang) {
    case 'de':
      return (
        <div>
          <h1>Kadermanagament</h1>
          <p className="hero">Dieses Video-Tutorial zeigt, wie du mit Hilfe von perönlichen Schattenteams deine kategorisierten Alternativspieler aus der Beobachtungsliste organisieren und verwalten kannst.</p>
          <div dangerouslySetInnerHTML={{ __html: "<iframe src='https://player.vimeo.com/video/478815781' width='800' height='450' frameborder='0' webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>" }} />
        </div>
      );
    case 'pt':
      return (
        <div>
          <h1>Gestão de esquadrões</h1>
          <p className="hero">Este vídeo tutorial explica como organizar e partilhar os jogadores da sua lista de observação categorizada com equipas de sombras pessoais.</p>
          <div dangerouslySetInnerHTML={{ __html: "<iframe src='https://player.vimeo.com/video/478815781' width='800' height='450' frameborder='0' webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>" }} />
        </div>
      );
    default:
      return (
        <div>
          <h1>Squad management</h1>
          <p className="hero">This video tutorial explains how to organize and share your categorized watchlist players with personal shadow teams.</p>
          <div dangerouslySetInnerHTML={{ __html: "<iframe src='https://player.vimeo.com/video/478815781' width='800' height='450' frameborder='0' webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>" }} />
        </div>
      );
  }
}
export function NiceToKnow({ lang }) {
  switch (lang) {
    case 'de':
      return (
        <div>
          <h1>Gut zu wissen</h1>
        </div>
      );
    case 'pt':
      return (
        <div>
          <h1>É bom saber</h1>
        </div>
      );
    default:
      return (
        <div>
          <h1>Nice to know</h1>
        </div>
      );
  }
}
