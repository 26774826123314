import React from 'react';
import './customer-view.scss';

export default function (props) {
  let customerName = props.groupName;

  if (props.companyName) {
    customerName = [props.groupName, props.companyName].join('/');
  }

  return (
    <div className={`customer-view ${props.isOption ? 'option' : ''}`}>
      <div className="customer-view__label">
        <span className="customer-view__type">{props.typeTranslated}</span>
        <strong className="customer-view__name">{props.label}</strong>
        { props.type === 'USER' ? (
          <span className="customer-view__customer">
            ,
            {customerName}
          </span>
        ) : null}
        <span className="customer-view__email">{props.email}</span>
      </div>
    </div>
  );
}
