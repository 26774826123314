import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import _ from 'lodash';
import AsyncSelect from 'react-select/async';
import { withTranslation } from 'react-i18next';
import { theme, style } from '../select_props';
import { ROOT_URL } from '../../../actions';
import Option from './customer-option';
import SingleValue from './customer-value';

@withTranslation()
export default class TeamSelect extends Component {
  static propTypes = {
    limit: PropTypes.number,
    offset: PropTypes.number,
    value: PropTypes.any,
    t: PropTypes.func,
    onChange: PropTypes.func,
  };

  render() {
    const {
      t, value, limit, offset, onChange, ...rest
    } = this.props;

    return (
      <AsyncSelect
        {...rest}
        styles={style}
        cacheOptions
        clearable={false}
        maxHeight={200}
        defaultOptions
        placeholder={t('TYPE_TO_SEARCH_CUSTOMER')}
        components={{ Option, SingleValue }}
        hideSelectedOptions
        value={value}
        loadOptions={(inputValue) => {
          return axios.get(`${ROOT_URL}/customers/search/`, {
            params: {
              needle: inputValue,
              limit,
              offset,
            },
          }).then((result) => {
            const list = _.get(result, 'data.search', { customers: [], users: [] });
            const dictionary = _.get(result, 'data.dictionary', { groupsById: {}, companiesById: {} });

            const fullList = _.orderBy([
              ...list.customers.map((c) => ({
                ...c,
                type: c.customerType,
                typeTranslated: t(c.customerType),
                email: c.clubEmail,
              })),
              ...list.users.map((u) => {
                const group = _.get(dictionary, `groupsById[${u.groupId}]`, {});
                const groupName = group.name || t('UNDEFINED_GROUP');

                let companyName = null;

                if (group.companyId) {
                  const company = _.get(dictionary, `companiesById[${group.companyId}]`, {});
                  companyName = company.name || t('UNDEFINED_COMPANY');
                }

                return {
                  ...u,
                  name: `${u.firstName} ${u.lastName}`,
                  type: 'USER',
                  typeTranslated: t('USER'),
                  groupName,
                  companyName,
                };
              }),
            ], ['score'], ['desc']);
            return fullList.map((t) => ({
              ...t, value: t._id, label: t.name, type: t.type,
            }));
          }).catch((err) => {
            console.error(err.message);
            return [];
          });
        }}
        onChange={(value) => {
          onChange && onChange(value);
        }}
        theme={theme}
      />
    );
  }
}
