import React from 'react';
import Select from 'react-select';
import { withTranslation } from 'react-i18next';
import { theme, style } from '../select_props';

function UsersSelect({
  input, t, i18n, ...rest
}) {
  const options = rest.users;
  const lng = i18n.language;

  return (
    <Select
      className={rest.meta.touched && rest.meta.error ? 'no-valid' : ''}
      {...rest}
      name={input.name}
      styles={style}
      cacheOptions
      clearable={false}
      maxHeight={200}
      options={options}
      placeholder={rest.placeholder}
      value={input.value}
      onChange={(value) => {
        rest.changeCallback && rest.changeCallback(value);
        input.onChange(value);
      }}
      theme={theme}
    />
  );
}

export default withTranslation()(UsersSelect);
