import React from 'react';
import './player-view.scss';

export default function (props) {
  const master_id = _.get(props, 'data.master_id', null);
  const team_id = _.get(props, 'data.team_id', null);
  const playerImage = master_id ? `url(https://static.sp7f.com/v2/players/${master_id}.jpg` : `url(https://static.sp7f.com/players/${props.value}.jpg)`;
  const { position } = props;
  const isCustom = _.get(props, 'data.isCustom', false);

  return (
    <div className="player-view">
      <div
        className="player-view__img"
        style={{ backgroundImage: playerImage }}
      />
      <div className="player-view__label">
        <span className="player-view__name">{props.label}</span>
        {isCustom ? <span className="custom-player-indicator">Custom</span> : null }
        {props.team ? (
          <span className="player-view__team-name">
            {team_id ? (
              <div
                className="player-view__team-img"
                style={{ backgroundImage: `url(https://static.sp7f.com/teams/${team_id}.jpg)` }}
              />
            ) : null}
            {props.team}
            {position ? `, ${position}` : ''}
          </span>
        ) : null}
      </div>
    </div>
  );
}
