import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getPosition } from '../../../../helpers';

import './positions_list.scss';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

@withTranslation()
@connect((state) => {
  return {
    positions: state.positions,
  };
}, {})
export default class PositionsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showAll: false,
    };
  }

  renderCurrentPosition() {
    const { currentPosition, positions } = this.props;
    const positionName = _.get(positions, `[${currentPosition}].desc_short`, null);

    if (!positionName) return null;

    const coordinates = getPosition(positionName);

    return <Position key={currentPosition} left={`${coordinates.x * 100}%`} top={`${coordinates.y * 100}%`} className="current-position" />;
  }

  renderPositions() {
    const { playerPositions } = this.props;
    return Object.keys(playerPositions).map((key) => this.renderPosition(key));
  }

  renderPosition(pos) {
    const { positions } = this.props;
    const positionName = _.get(positions, `[${pos}].desc_short`, null);

    if (!positionName) return null;

    const coordinates = getPosition(positionName);

    return <Position key={pos} left={`${coordinates.x * 100}%`} top={`${coordinates.y * 100}%`} />;
  }

  renderPositionListItems() {
    const { playerPositions } = this.props;
    const { showAll } = this.state;

    const list = Object.keys(playerPositions).sort((a, b) => playerPositions[b] - playerPositions[a]).map((key) => (this.renderPositionListItem(key)));

    return showAll ? list : list.slice(0, 3);
  }

  renderPositionListItem(pos) {
    const {
      playerPositions, totalMins, positions, i18n, t,
    } = this.props;

    const lng = i18n.language;

    const val = playerPositions[pos];
    const positionName = _.get(positions, `[${pos}].desc_long_${lng}`, _.get(positions, `[${pos}].desc_short`, null));
    const percents = (+val * 100 / +totalMins);

    if (!pos || pos === '0') {
      return null;
    }

    return (
      <li className="positions-list__list-item" key={pos}>
        <label>{positionName}</label>
        <Progress value={val} max={totalMins} />
        <div className="progress-legend">
          {Math.round(percents)}
          %
          {' '}
          <span>
            (
            {val}
            {' '}
            {t('MINS')}
            )
          </span>
        </div>
      </li>
    );
  }

  render() {
    const { playerPositions, t } = this.props;
    const { showAll } = this.state;

    const positionsLength = Object.keys(playerPositions).length;

    if (!Object.keys(playerPositions).length) {
      return (
        <div className="positions-list">
          <div className="positions-list__pitch">
            <div className="home" />
            <div className="away" />
            <div className="center" />
            { this.renderCurrentPosition() }
          </div>
          <div className="positions-list__positions-list">
            <p className="empty-message">{t('POSITIONS_EMPTY', { context: window.genderContext })}</p>
          </div>
        </div>
      );
    }

    return (
      <div className="positions-list">
        <div className="positions-list__pitch">
          <div className="home" />
          <div className="away" />
          <div className="center" />
          { this.renderPositions() }
        </div>
        <ul className="positions-list__positions-list">
          { this.renderPositionListItems() }
          {
            positionsLength > 3 ? (
              <li className="positions-list__show-more-item">
                <span
                  onClick={() => { this.setState({ showAll: !showAll }); }}
                  className="positions-list__show-more"
                >
                  {showAll ? 'Show less' : 'Show more'}
                </span>
              </li>
            ) : null
          }
        </ul>
      </div>
    );
  }
}

export function Position({
  onClick, className, left, top, ...rest
}) {
  return (<div role="button" onClick={() => onClick && onClick()} className={`positions-list__pitch-position ${className || ''}`} style={{ left, top }} {...rest} />);
}

Position.propTypes = {
  onClick: PropTypes.func,
  left: PropTypes.string,
  top: PropTypes.string,
  className: PropTypes.string,
};

export function Progress(props) {
  const { value, max } = props;
  let width = (+value * 100 / +max);
  width = width ? `${width}%` : 0;

  return (
    <div className="positions-list__progress">
      <div className="positions-list__progress-bar" style={{ width }} />
    </div>
  );
}
