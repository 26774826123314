import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './tag.scss';

function Tag({ type }) {
  const { t } = useTranslation();

  const tagName = t(type);
  const tagTypeClass = type.toLowerCase();

  return (
    <div className={`match-card__tag ${tagTypeClass}`}>
      {tagName}
    </div>
  );
}

export default Tag;

Tag.propTypes = {
  type: PropTypes.string,
};
