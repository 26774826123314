import _ from 'lodash';

import { storageMatchUpdate } from '../storageMiddleware';

import {
  SET_PDF_MATCH,
  CHANGE_TEAM_NOTE,
  CHANGE_PLAYER_NOTE,
  CHANGE_GOAL,
  CHANGE_FINAL_RATING,
  CHANGE_SNAPSHOT,
  CHANGE_NOTE_TAGS,
  UPDATE_SINGLE_REPORT_MINS,
} from '../actions/pdf';
import { EVENTS_TYPES } from '../constants';

const INITIAL_STATE = {
  match: {},
  substitutions: {
    home: {},
    away: {},
  },
  values: {
    home: {},
    away: {},
  },
  rates: {
    home: {},
    away: {},
  },
  notes: {
    home: {},
    away: {},
  },
  team_notes: {
    home: '',
    away: '',
  },
  tags: {
    home: [],
    away: [],
  },
  events: {
    home: [],
    away: [],
  },
  homegoals: null,
  awaygoals: null,
  homegoals_ht: null,
  awaygoals_ht: null,
  snapshots: {},
};

const parsePlayerPositions = (positions) => _.chain(positions).filter((player) => player.swap && player.swapTime).groupBy('swapTime').value();

const parsePlayerValues = (values) => _
  .chain(values)
  .map((player) => (
    _
      .chain(player)
      .map((mins, min) => ({ ...mins, min: +min }))
      .filter((mins) => (typeof mins.event_id !== 'undefined')))
    .value())
  .flatten()
  .value();

const getAverage = (players) => {
  const result = {};

  _.forEach(players, (values, player_id) => {
    result[player_id] = {};

    _.forEach(values, (value) => {
      _.forEach(value, (rate_value, rate_name) => {
        if (rate_name === 'event_type') return;
        if (rate_name === 'tags') return;
        const result_rate = result[player_id][rate_name];

        if (['player_id', 'min', 'event_id', 'notes'].indexOf(rate_name) === -1) {
          if (result_rate) {
            result[player_id][rate_name] = {
              value: parseFloat(rate_value) + parseFloat(result_rate.value),
              count: result_rate.count + 1,
            };
          } else {
            result[player_id][rate_name] = {
              value: rate_value,
              count: 1,
            };
          }
        }
      });
    });
  });

  _.forEach(result, (value, player_id) => {
    _.forEach(value, (rate_value, rate_name) => {
      const result_rate = result[player_id][rate_name];

      if (['player_id', 'min', 'event_id', 'notes'].indexOf(rate_name) === -1) {
        result[player_id][rate_name] = result_rate.value / result_rate.count;
      }
    });
  });

  return result;
};

const getNotes = (players) => {
  const result = {};

  _.forEach(players, (values, player_id) => {
    result[player_id] = {
      Note: '',
    };

    _.forEach(values, (value) => {
      const time = value.min; // `${value.min}`.slice(0, 6);
      if (value.notes || value.event_type) {
        if (result[player_id][time]) {
          result[player_id][time] = `${result[player_id][time]}\n${value.notes}`;
        } else if (value.event_id !== -1) {
          result[player_id][time] = value.notes;
        } else {
          result[player_id].Note = value.notes;
        }
      }
    });
  });

  return result;
};

const getEvents = (players) => {
  const result = [];

  _.forEach(players, (values, player_id) => {
    _.forEach(values, (value) => {
      const time = value.min;
      if (Array.isArray(value.event_type) && value.event_type.length) {
        result.push({ time, player_id, event_type: value.event_type });
      }
    });
  });

  return result;
};

const getTags = (players) => {
  const result = [];

  _.forEach(players, (values, player_id) => {
    _.forEach(values, (value) => {
      const time = value.min;
      if (Array.isArray(value.tags) && value.tags.length) {
        result.push({ time, player_id, tags: value.tags });
      }
    });
  });

  return result;
};

function getPlayerSide(player_id, match) {
  const { home, away } = match;
  const isHome = !!_.get(home, `players[${match.player_id}]`, false);
  const isAway = !!_.get(away, `players[${match.player_id}]`, false);

  if (isHome || isAway) {
    return isHome ? 'home' : 'away';
  }

  return null;
}

function getPlayer(player_id, side, match) {
  const { players } = match[side];
  const information = players[player_id] && players[player_id].information || {};
  return information;
}

const findEvent = (event, eventType) => {
  return Array.isArray(event.event_type) && event.event_type.indexOf(eventType) !== -1;
};

const generateFormattedTimelineFromMinutes = (mins) => {
  const period = mins < 45 ? 1 : mins < 90 ? 2 : mins < 105 ? 3 : 4;
  const minsFormatted = `000${mins}`.substr(-3);
  return `${period}${minsFormatted}00`;
};

export default function (state = INITIAL_STATE, action) {
  let clone;

  switch (action.type) {
    case SET_PDF_MATCH: {
      const homePlayersValues = _.groupBy(parsePlayerValues(action.match.home.values), 'player_id');
      const awayPlayersValues = _.groupBy(parsePlayerValues(action.match.away.values), 'player_id');
      const type = _.get(action, 'match.type', 'match');
      const player_id = type === 'single' ? _.get(action, 'match.player_id', null) : null;
      const side = type === 'single' ? getPlayerSide(player_id, action.match) : null;
      const player = type === 'single' && side ? getPlayer(player_id, side, action.match) : null;

      const matchStats = {
        mins: 0,
        goal: 0,
        assist: 0,
        yellow: 0,
        red: 0,
        yellow_red: 0,
        substitution_in: null,
        substitution_out: null,
      };

      if (type === 'single') {
        const prevTimeline = localStorage.getItem(`timeline_${action.match._id}`) || 100000;
        const timelinesMins = parseInt(`${prevTimeline}`.slice(1, 4), 10);
        matchStats.mins = timelinesMins;

        let events = getEvents(side === 'home' ? homePlayersValues : awayPlayersValues);
        if (events && Array.isArray(events)) {
          events = events.filter((e) => e.player_id === player_id);
          events.map((e) => {
            EVENTS_TYPES.map((et) => {
              if (findEvent(e, et)) {
                if (et !== 'substitution_in' && et !== 'substitution_out') {
                  matchStats[et] += 1;
                } else {
                  matchStats[et] = parseInt(e?.time?.toString().slice(1, 4), 10);
                }
              }
            });
          });

          if (matchStats.substitution_out !== null && !matchStats.substitution_in) {
            matchStats.mins = matchStats.substitution_out;
          }

          if (matchStats.substitution_in !== null && !matchStats.substitution_out) {
            if (timelinesMins && !Number.isNaN(timelinesMins) && timelinesMins > matchStats.substitution_in) {
              matchStats.mins = timelinesMins - matchStats.substitution_in;
            } else {
              matchStats.mins = 90 - matchStats.substitution_in;
            }
          }

          if (matchStats.substitution_in !== null && matchStats.substitution_out !== null) {
            matchStats.mins = matchStats.substitution_out - matchStats.substitution_in;
          }
        }

        if (action.match.mins) {
          matchStats.mins = action.match.mins;
        }
      }

      const newMatch = {
        ...state,
        match: action.match,
        type,
        player_id,
        side,
        player,
        substitutions: {
          home: parsePlayerPositions(_.get(action, 'match.home.positions.lineup', [])),
          away: parsePlayerPositions(_.get(action, 'match.away.positions.lineup', [])),
        },
        values: {
          home: homePlayersValues,
          away: awayPlayersValues,
        },
        rates: {
          home: getAverage(homePlayersValues),
          away: getAverage(awayPlayersValues),
        },
        notes: {
          home: getNotes(homePlayersValues),
          away: getNotes(awayPlayersValues),
        },
        events: {
          home: getEvents(homePlayersValues),
          away: getEvents(awayPlayersValues),
        },
        tags: {
          home: getTags(homePlayersValues),
          away: getTags(awayPlayersValues),
        },
        team_notes: {
          home: action.match.home.notes,
          away: action.match.away.notes,
        },
        homegoals: action.match.homegoals,
        awaygoals: action.match.awaygoals,
        homegoals_ht: action.match.homegoals_ht,
        awaygoals_ht: action.match.awaygoals_ht,
        snapshots: _.zipObject(_.keys(action.match.home.positions), _.keys(action.match.home.positions)),
        stats: matchStats,
      };

      return newMatch;
    }

    case UPDATE_SINGLE_REPORT_MINS: {
      const mins = !action.value ? null : action.value;

      const newState = {
        ...state,
        match: {
          ...state.match,
          mins,
        },
      };

      storageMatchUpdate({ match: newState.match });

      return newState;
    }

    case CHANGE_TEAM_NOTE: {
      return {
        ...state,
        team_notes: {
          ...state.team_notes,
          [action.side]: action.note,
        },
      };
    }

    case CHANGE_PLAYER_NOTE: {
      return {
        ...state,
        notes: {
          ...state.notes,
          [action.side]: {
            ...state.notes[action.side],
            [action.player_id]: {
              ...state.notes[action.side][action.player_id],
              [action.time]: action.note,
            },
          },
        },
      };
    }

    case CHANGE_NOTE_TAGS:
      const allTags = state.tags[action.side];
      const time = action.time === 'Note' ? 0 : action.time;
      const cTags = allTags.find((t) => t.time == time && t.player_id == action.player_id);

      if (!action.tags || !action.tags.length) {
        const index = allTags.findIndex((t) => t.time == time && t.player_id == action.player_id);
        if (index === -1) {
          return {
            ...state,
          };
        }
        return {
          ...state,
          tags: {
            ...state.tags,
            [action.side]: [
              ...state.tags[action.side].slice(0, index),
              ...state.tags[action.side].slice(index + 1),
            ],
          },
        };
      }

      if (!cTags && action.tags && action.tags.length) {
        const nState = {
          ...state,
          tags: {
            ...state.tags,
            [action.side]: [
              ...state.tags[action.side],
              {
                player_id: action.player_id,
                tags: action.tags,
                time,
              },
            ],
          },
        };

        return nState;
      }

      const index = allTags.findIndex((t) => t.time == time && t.player_id == action.player_id);
      return {
        ...state,
        tags: {
          ...state.tags,
          [action.side]: [
            ...state.tags[action.side].slice(0, index),
            {
              player_id: action.player_id,
              tags: action.tags,
              time,
            },
            ...state.tags[action.side].slice(index + 1),
          ],
        },
      };

      break;
    case CHANGE_FINAL_RATING: {
      clone = _.cloneDeep(state);
      const { side, player_id, value } = action;

      clone.match[side].final_rating = clone.match[side].final_rating || {};

      if (action.value) {
        clone.match[side].final_rating[player_id] = value;
      } else {
        delete clone.match[side].final_rating[player_id];
      }

      // storageMatchUpdate({ match: clone.data });

      return clone;
    }

    case CHANGE_GOAL: {
      return {
        ...state,
        [action.key]: action.value,
      };
    }

    case CHANGE_SNAPSHOT: {
      return {
        ...state,
        snapshots: {
          ...state.snapshots,
          [action.key]: action.value,
        },
      };
    }

    default:
      return state;
  }
}
