import React, { Component } from 'react';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { findDOMNode } from 'react-dom';
import { DragSource, DropTarget } from 'react-dnd';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Icon from '../Icon';
import { canAddWatchlists } from '../../helpers/limits';
import WatchlistIcon from '../ui/watchlist-icon';
import DemoBadge from '../ui/demo-badge';

const cardSource = {
  beginDrag(props) {
    return {
      id: props.id,
      index: props.index,
    };
  },
};

const cardTarget = {
  hover(props, monitor, component) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;
    if (dragIndex === hoverIndex) {
      return;
    }
    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
    const clientOffset = monitor.getClientOffset();
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return;
    }
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return;
    }
    props.changeSort(dragIndex, hoverIndex);
    monitor.getItem().index = hoverIndex;
  },
};

@withTranslation()
@connect((state) => ({
  subscription: state.subscription,
  user: state.auth.user,
  users: state.users,
}))
@DropTarget('WATCHLIST_PLAYER', cardTarget, (connect) => ({
  connectDropTarget: connect.dropTarget(),
}))
@DragSource('WATCHLIST_PLAYER', cardSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging(),
}))
class DraggableWatchlist extends Component {
  render() {
    const {
      watchlist, isDragging, connectDragSource, connectDropTarget, subscription, copyWatchlist, deleteWatchlist, user, users, disabledControls,
    } = this.props;

    let firstName = '';
    let lastName = '';

    if (users.data) {
      if (+watchlist.user_id !== +user._id && watchlist.user_id !== null) {
        const usr = users.data.find((u) => u._id === watchlist.user_id) || {};
        firstName = usr.firstName;
        lastName = usr.lastName;
      } else {
        firstName = user.firstName;
        lastName = user.lastName;
      }
    }

    const initials = firstName[0] + lastName[0];
    const avatar = watchlist.user_id === user._id ? 'ME' : initials.toUpperCase();

    const isDemo = watchlist._id === 'demo';

    return connectDropTarget(<li className={`dragable-watchlist ${isDragging ? 'dragging' : ''}`}>
      {isDemo ? <DemoBadge /> : null}
      <span className="col-drag-handle">

        { !disabledControls ? connectDragSource(<span className="drag-handle"><Icon name="dragger" /></span>) : (<span className="drag-handle inactive"><Icon name="dragger" /></span>) }
      </span>
      <span className="td col-title">
        <Link to={`/watchlists/${watchlist._id}/`} className="dragable-watchlist__name">
          <WatchlistIcon icon_id={watchlist.icon_id} color_id={watchlist.color_id} isSmall />
          {watchlist.name}
        </Link>
      </span>
      <span className="td col-players">
        {_.uniq(watchlist.players).length}
      </span>
      <span className="col-date">
        {moment(watchlist.createdAt).format('DD.MM.YYYY')}
      </span>
      <span className="col-date">
        {moment(watchlist.updatedAt).format('DD.MM.YYYY')}
      </span>
      <span className="td col-author">
        {isDemo ? (<span className="avatars">SP</span>) : (<span className="avatars">{ avatar }</span>)}
      </span>
      <span className="td col-controls">
        {isDemo ? (
          <span className="shadow-team__table-controls">
            <span className="control-btn">
              <Icon name="copy" onClick={() => { }} />
            </span>
            <span className="control-btn" onClick={() => {}}>
              <Icon name="trashcan" />
            </span>
          </span>
        ) : (
          <span className="shadow-team__table-controls">
            {canAddWatchlists(subscription) && !disabledControls ? (
              <span className="control-btn">
                <Icon name="copy" onClick={() => { copyWatchlist(watchlist._id); }} />
              </span>
            ) : null}
            <span className="control-btn" onClick={() => { deleteWatchlist(watchlist); }}>
              <Icon name="trashcan" />
            </span>
          </span>
        ) }
      </span>
    </li>);
  }
}

DraggableWatchlist.propTypes = {
  watchlist: PropTypes.object,
  t: PropTypes.func,
  deleteWatchlist: PropTypes.func,
  copyWatchlist: PropTypes.func,
  subscription: PropTypes.object,
  user: PropTypes.object,
  users: PropTypes.array,
  disabledControls: PropTypes.bool,
};

export default DraggableWatchlist;
