import React from 'react';
import PropTypes from 'prop-types';
import './fade.scss';

function FadeScreen({ image, children, withLogo }) {
  if (withLogo) {
    return (
      <div className={`fade-wrapper ${withLogo ? 'with-logo' : ''}`} style={{ backgroundImage: image ? `url(${image})` : 'none' }}>
        <div className="auth-logo">
          <div className="top-shade" />
          <img src="/images/logo-full.svg" width="196" height="57" alt="Scoutpad" />
        </div>
        <div className="fade-body">
          { children }
        </div>
      </div>
    );
  }

  return (
    <div className={`fade-wrapper ${withLogo ? 'with-logo' : ''}`} style={{ backgroundImage: image ? `url(${image})` : 'none' }}>
      { children }
    </div>
  );
}

FadeScreen.propTypes = {
  children: PropTypes.any,
  image: PropTypes.string,
  withLogo: PropTypes.bool,
};

export default FadeScreen;
