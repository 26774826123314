import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import onClickOutside from 'react-onclickoutside';
import { browserHistory } from 'react-router';
import { addToFavorite, removeFromFavorites } from '../../../actions/favorites';
import './add-to-favorites.scss';

import Icon from '../../Icon';
import { NotificationManager } from '../../notifications';
import { updateFavoriteLimits } from '../../../actions/subscription';

import { canAddWatchlist } from '../../../helpers/limits';

@withTranslation()
@connect((state) => ({
  subscription: state.subscription,
  user: state.auth.user,
  favorites: state.favorites,
}), {
  addToFavorite,
  removeFromFavorites,
  updateFavoriteLimits,
})
@onClickOutside
export default class AddToFavorites extends Component {
  static propTypes = {
    player: PropTypes.object,
    subscription: PropTypes.object,
    user: PropTypes.object,
    favorites: PropTypes.object,
    added_message: PropTypes.string,
    isDemo: PropTypes.bool, // show random color
    demoCategory: PropTypes.number, // show fake category color
  };

  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };

    this.addToFavorite = this.addToFavorite.bind(this);
    this.removeFromFavorite = this.removeFromFavorite.bind(this);
  }

  handleClickOutside() {
    this.setState({ open: false });
  }

  addToFavorite(category, isFavorite) {
    const {
      player, user, match_id, subscription, addToFavorite, updateFavoriteLimits, added_message,
    } = this.props;
    const user_id = user._id;
    const player_id = player?.player_id || player?.key;

    if (!player_id) return null;

    if (player_id === 'demo') return;

    this.setState({ open: false });

    if (!isFavorite) {
      if (!canAddWatchlist(subscription)) {
        NotificationManager.create({
          message: 'FAVORITES_LIMIT_MESSAGE',
          id: 'fav',
          timeOut: 3000,
          type: 'error',
          onClick: () => {
            browserHistory.push('/settings/billing');
          },
        });
        return;
      }

      updateFavoriteLimits({ current: ++subscription.favorites.current });
      if (subscription.favorites.max === 'INFINITY') {
        NotificationManager.create({
          message: added_message || 'FAVORITES_ADDED_MESSAGE',
          id: 'fav',
          timeOut: 3000,
          props: { max: subscription.favorites.max, current: subscription.favorites.current },
        });
      } else {
        NotificationManager.create({
          message: added_message || 'FAVORITES_ADDED_MESSAGE_PROPS',
          id: 'fav',
          timeOut: 3000,
          props: { max: subscription.favorites.max, current: subscription.favorites.current },
        });
      }

      addToFavorite(player_id, player, match_id, user_id, category);
    } else {
      NotificationManager.create({
        message: added_message || 'FAVORITES_ADDED_MESSAGE',
        id: 'fav',
        timeOut: 3000,
        props: { max: subscription.favorites.max, current: subscription.favorites.current },
      });
      addToFavorite(player_id, player, match_id, user_id, category);
    }
  }

  removeFromFavorite(favorite_id) {
    const { subscription, updateFavoriteLimits, removeFromFavorites } = this.props;

    this.setState({ open: false });

    NotificationManager.create({
      message: 'FAVORITES_REMOVED_MESSAGE',
      id: 'fav',
      timeOut: 3000,
    });
    updateFavoriteLimits({ current: subscription.favorites.current - 1 });
    removeFromFavorites(favorite_id);
  }

  shouldShow(category, isFavorite) {
    return !isFavorite || (isFavorite && isFavorite.category !== category);
  }

  render() {
    const {
      t, player, favorites, isDemo, demoCategory,
    } = this.props;
    const { list } = favorites;

    const player_id = player?.player_id || player?.key;
    if (!player_id) return null;

    let isFavorite = player_id !== 'demo' ? list.find((f) => player_id == f.player_id) : { category: 3 };

    if (isDemo) {
      isFavorite = { category: demoCategory || 0 };
    }

    return (
      <div className="add-to-favorites">
        <div
          className={`trigger ${isFavorite ? `${'active ' + 'cat_'}${isFavorite.category}` : ''}`}
          onClick={() => {
            this.setState({ open: !this.state.open });
          }}
        >
          {this.props.children}
        </div>
        { this.state.open ? (
          <ul className="list">
            { this.shouldShow(3, isFavorite) ? (
              <li onClick={() => { this.addToFavorite(3, isFavorite); }}>
                <Icon name="favorite" className="fav_icon cat_3" />
                {' '}
                {t('CAT_IMMEDIATELY')}
              </li>
            ) : null }
            { this.shouldShow(2, isFavorite) ? (
              <li onClick={() => { this.addToFavorite(2, isFavorite); }}>
                <Icon name="favorite" className="fav_icon cat_2" />
                {' '}
                {t('CAT_FOCUS')}
              </li>
            ) : null }
            { this.shouldShow(1, isFavorite) ? (
              <li onClick={() => { this.addToFavorite(1, isFavorite); }}>
                <Icon name="favorite" className="fav_icon cat_1" />
                {' '}
                {t('CAT_PERSPECTIVE')}
              </li>
            ) : null }
            { this.shouldShow(0, isFavorite) ? (
              <li onClick={() => { this.addToFavorite(0, isFavorite); }}>
                <Icon name="favorite" className="fav_icon cat_0" />
                {' '}
                {t('CAT_NOTABLE')}
              </li>
            ) : null }
            {isFavorite ? <li onClick={() => { this.removeFromFavorite(isFavorite._id); }}>{t('CAT_REMOVE')}</li> : null}
          </ul>
        ) : null }
      </div>
    );
  }
}
