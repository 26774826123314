import React from 'react';
import { components } from 'react-select';
import PlayerView from '../player-view';

export default function (props) {
  const { innerProps, innerRef, data } = props;

  return (
    <components.SingleValue {...props}>
      <PlayerView {...data} />
    </components.SingleValue>
  );
}
