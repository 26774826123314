import './match.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import { Link } from 'react-router';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';

import { DNDOptions } from '../../constants';
import MatchReport from './match-report';
import SingleReport from './single-report';

import Icon from '../Icon';

import {
  toggleSidebar, setMatch, changeSize, hideControls,
} from '../../actions/match';
import { fetchMatch, changeLoading } from '../../actions/index';
import { getWindowHeight, getWindowWidth } from '../../helpers';

@withTranslation()
@connect((state) => ({
  match: state.match,
  indicators: state.indicators,
}), {
  toggleSidebar, setMatch, changeSize, hideControls, fetchMatch, changeLoading,
})

export default class Match extends Component {
  static propTypes = {
    match: PropTypes.object,
    indicators: PropTypes.object,
    toggleSidebar: PropTypes.func,
    setMatch: PropTypes.func,
    changeSize: PropTypes.func,
    hideControls: PropTypes.func,
    fetchMatch: PropTypes.func,
    changeLoading: PropTypes.func,
    params: PropTypes.object,
  };

  static defaultProps = {
    layouts: {
      desktop: {
        ratio: 1.675,
        lineupsWidth: 50,
        substitutionsHeight: 62 + 14,
        timeLineHeight: 43 + 25,
        matchTitleHeight: 60 + 20,
        margin: 20,
        sidebar: 285,
      },
      mobile: {
        ratio: 2,
        lineupsWidth: 32,
        substitutionsHeight: 44 + 4,
        timeLineHeight: 29 + 10,
        matchTitleHeight: 30 + 10,
        margin: 10,
        sidebar: 170,
      },
    },
  };

  constructor() {
    super();

    this.state = {
      width: 0,
      height: 0,
    };

    this.handleResize = this.handleResize.bind(this);
    this.hideControls = this.hideControls.bind(this);
  }

  componentDidMount() {
    const {
      changeLoading, fetchMatch, setMatch, params,
    } = this.props;

    changeLoading({ component: 'match', loading: true });
    fetchMatch(params.match_id).then((data) => {
      if (data.payload.match) {
        setMatch(data.payload.match);
      }

      changeLoading({ component: 'match', loading: false });
      this.handleResize(null, this.props);
    });

    window.addEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.layout.sidebar !== this.props.match.layout.sidebar) {
      this.handleResize(null, this.props);
    }
  }

  componentWillUnmount() {
    this.props.setMatch(null);

    window.removeEventListener('resize', this.handleResize);
  }

  handleResize(event, nextProps) {
    const mediaQuery = getWindowWidth() > 960 ? 'desktop' : 'mobile';
    const props = nextProps || this.props;

    const { match, layouts } = props;

    const {
      lineupsWidth, substitutionsHeight, timeLineHeight, matchTitleHeight, margin, ratio, sidebar,
    } = layouts[mediaQuery];
    const { layout } = match;

    const shouldCalcSubstitutions = match.type !== 'single' ? substitutionsHeight : 0;

    const maxHeight = getWindowHeight() - margin * 2 - shouldCalcSubstitutions - timeLineHeight - matchTitleHeight;
    const maxWidth = getWindowWidth() - margin - (layout.sidebar ? sidebar : 54) - lineupsWidth;
    const widthFromHeight = maxHeight * ratio;
    const heightFromWidth = maxWidth / ratio;

    let height;
    let width;

    if (match.type !== 'single') {
      if (heightFromWidth > maxHeight) {
        width = widthFromHeight;
        height = maxHeight;
      }

      if (widthFromHeight > maxWidth) {
        height = heightFromWidth;
        width = maxWidth;
      }
    } else {
      width = maxWidth;
      height = maxHeight;
    }

    this.props.changeSize({ width, height, mediaQuery });
  }

  hideControls() {
    const { match, hideControls } = this.props;

    if (match.controls.player || (match.positionSelected.side && _.size(match.positionSelected.position)) || _.size(match.substitutionSelected.side)) {
      hideControls();
    }
  }

  render() {
    const { indicators } = this.props;
    const { match } = this.props;
    const {
      layout, controls, addPlayer, type,
    } = match;
    const { inner } = controls;

    const hideClose = inner === 'notes' || inner === 'more';

    const from = _.get(this.props, 'location.query.from', null);

    return (
      <DndProvider backend={TouchBackend} options={DNDOptions}>
        <div className={`match ${layout.sidebar ? 'sidebar--visible' : ''} ${indicators.constant.indexOf(inner) !== -1 && inner !== 'favorite' ? 'side-controls--visible' : ''} ${addPlayer ? 'side-add-player--visible' : ''}`} onClick={() => { this.hideControls(); }}>
          { hideClose ? null
            : (<Link className="match__close" to={from ? `/players/${from}/reports` : '/reports/'}><Icon name="close" /></Link>)}
          { type === 'single' ? <SingleReport /> : <MatchReport />}
        </div>
      </DndProvider>
    );
  }
}
