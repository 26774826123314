import './pitch.scss';

import React from 'react';

import Area from './area';
import Title from './title';
import Substitutions from './substitutions';
import Timeline from './timeline';
import Snapshots from './snapshots';
import DefaultLineups from './defaultlineups';

function Pitch() {
  return (
    <div className="pitch">
      <Title />
      <Area />
      <Snapshots />
      <Substitutions />
      <DefaultLineups />
      <Timeline />
    </div>
  );
}

export default Pitch;
