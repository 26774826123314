import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { withTranslation } from 'react-i18next';
import { editSelectTheme, styleTableEditSelect } from '../../../ui/select_props';
import { validateEmail, validateText } from '../../../../helpers';

@withTranslation()
class EditUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editUser: props.user,
      validate: null,
      validationRules: {
        text: ['firstName', 'lastName'],
        select: ['roleId'],
        email: ['email'],
      },
    };

    this.inputChange = this.inputChange.bind(this);
  }

  validateForm() {
    const {
      editUser,
    } = this.state;

    const errors = {};
    let isErrors = false;

    const { text, select, email } = this.state.validationRules;

    text.forEach((property) => {
      if (!validateText(editUser[property], true, 1, 255)) {
        isErrors = true;
        errors[property] = true;
      }
    });

    email.forEach((property) => {
      if (!validateEmail(editUser[property])) {
        isErrors = true;
        errors[property] = true;
      }
    });

    select.forEach((property) => {
      if (editUser[property] === undefined || editUser[property] === null) {
        isErrors = true;
        errors[property] = true;
      }
    });

    return isErrors ? errors : null;
  }

  setValidClass(property) {
    const { validate } = this.state;
    const isInvalidProperty = _.get(validate, property, false);
    return isInvalidProperty ? 'invalid' : '';
  }

  inputChange(e, name) {
    this.setState({
      ...this.state,
      editUser: {
        ...this.state.editUser,
        [name]: e.target.value,
      },
    });
  }

  render() {
    const {
      t, rolesOptions, onSubmit, onCancel,
    } = this.props;
    const user = this.state.editUser;
    const userRole = user.roleId !== undefined ? rolesOptions.find((r) => +r.value === +user.roleId) : null;

    return (
      <tbody>
        <tr className="admin-users-table__form">
          <td colSpan="6">
            <div className="admin-users-table__form-row">
              <div className="admin-users-table__form-col">
                <label htmlFor="FirstName">{t('FIRST_NAME')}</label>
                <input
                  id="FirstName"
                  type="text"
                  placeholder={t('FIRST_NAME')}
                  className={this.setValidClass('firstName')}
                  value={user.firstName || ''}
                  onChange={(e) => {
                    this.inputChange(e, 'firstName');
                  }}
                />
              </div>
              <div className="admin-users-table__form-col">
                <label htmlFor="LastName">{t('LAST_NAME')}</label>
                <input
                  id="LastName"
                  type="text"
                  placeholder={t('LAST_NAME')}
                  className={this.setValidClass('lastName')}
                  value={user.lastName || ''}
                  onChange={(e) => {
                    this.inputChange(e, 'lastName');
                  }}
                />
              </div>
              <div className="admin-users-table__form-col">
                <label htmlFor="Email">{t('EMAIL')}</label>
                <input
                  id="Email"
                  type="text"
                  className={this.setValidClass('email')}
                  placeholder={t('EMAIL')}
                  value={user.email || ''}
                  onChange={(e) => {
                    this.inputChange(e, 'email');
                  }}
                />
              </div>
              <div className="admin-users-table__form-col">
                <label htmlFor="Role">{t('ROLE')}</label>
                <Select
                  id="Role"
                  styles={styleTableEditSelect}
                  theme={editSelectTheme}
                  options={rolesOptions}
                  value={userRole}
                  invalid={this.setValidClass('roleId')}
                  onChange={(value) => {
                    this.setState({
                      editUser: {
                        ...this.state.editUser,
                        roleId: value.value,
                      },
                    });
                  }}
                />
              </div>
              <div className="admin-users-table__form-col admin-users-table__buttons">
                <span
                  className="js-link"
                  onClick={() => {
                    const errors = this.validateForm();
                    const user = this.state.editUser;

                    if (errors) {
                      return this.setState({
                        validate: errors,
                      });
                    }

                    return onSubmit && onSubmit(user);
                  }}
                >
                  {t('SAVE')}
                </span>
                <span
                  className="js-link red"
                  onClick={() => {
                    onCancel && onCancel();
                  }}
                >
                  {t('CANCEL')}
                </span>
              </div>
            </div>
          </td>
        </tr>
        <tr className="empty">
          <td colSpan="6" />
        </tr>
      </tbody>
    );
  }
}

EditUser.propTypes = {
  user: PropTypes.object,
  rolesOptions: PropTypes.array,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  t: PropTypes.func,
};

export default EditUser;
