import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import PositionsSelector from '../../match/single-report/position-selector';
import MatchInfo from './match-info';
import MatchStats from './match-stats';

import { updateSingleReportMins } from '../../../actions/pdf';

@connect((state) => {
  return ({
    pdf: state.pdf,
    formations: state.formations,
  });
}, { updateSingleReportMins })
class Match extends Component {
  render() {
    const { pdf, formations } = this.props;
    const { side, player_id } = pdf;

    const formationId = _.get(pdf, `match[${side}].formations.lineup.id`, []);
    const currentFormation = formations[formationId];

    const position_id_detail = _.get(pdf, `match[${side}].positions.lineup[${player_id}].position_id_detail`, null);
    const position_id_upper = currentFormation.positions.find((i) => +i.position_id_detail === +position_id_detail).position_id;

    const activePosition = position_id_upper ? { position_id_upper, position_id_detail } : {};

    return (
      <div className="pdf-block">
        <div className="col single-pdf__match">
          <MatchInfo />
        </div>
        <div className="col single-pdf__match-stats">
          <MatchStats stats={pdf.stats} mins={pdf.match.mins} updateSingleReportMins={this.props.updateSingleReportMins} />
        </div>
        <div className="col single-pdf__position">
          <PositionsSelector
            activePosition={activePosition}
            disabled
            inverted
            match={{
              ...pdf.match, data: pdf.match, side: pdf.side, player_id: pdf.player_id,
            }}
          />
        </div>
      </div>
    );
  }
}

Match.propTypes = {
  pdf: PropTypes.object,
  formations: PropTypes.array,
};

export default Match;
