import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import _ from 'lodash';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { fetchInvites } from '../../../actions/invites';

import { useCredits, calculateUseCredits, fetchCreditsAndBonusMilestones } from '../../../actions/group';
import {
  changeLoading,
} from '../../../actions/index';

import SmallLoader from '../../ui/small-loader';

import InviteForm from './invite-form';
import Invite from './invite';
import MilestonesTable from './milestones-table';

import './circular-chart.scss';

import './earn.scss';
import confirm from '../../confirm';
import { getCurrencySign } from '../../../helpers';
import { NotificationManager } from '../../notifications';

@withTranslation()
@connect((state) => {
  return {
    invites: state.invites,
    group: state.auth.user.group,
    user: state.auth.user,
    subscription: state.subscription,
  };
}, {
  fetchInvites, useCredits, calculateUseCredits, changeLoading, fetchCreditsAndBonusMilestones,
})
class InvitePage extends Component {
  constructor(props) {
    super(props);

    this.state = { loading: false };

    this.calculateUseCredits = this.calculateUseCredits.bind(this);
    this.promotePopup = this.promotePopup.bind(this);
  }

  componentDidMount() {
    this.props.changeLoading({ component: 'app', loading: true });
    Promise.all([
      this.props.fetchCreditsAndBonusMilestones(),
      this.props.fetchInvites(),
    ]).then(() => {
      this.props.changeLoading({ component: 'app', loading: false });
    });
  }

  calculateUseCredits() {
    const { t, group } = this.props;
    this.setState({ loading: true });
    this.props.calculateUseCredits().then((result) => {
      this.setState({ loading: false });
      const { amount, canUse } = result.payload.data;
      const currencySign = getCurrencySign(group.currency);
      const text = <div>{t('CREDITS_USE_CONFIRM', { canUse, amount, currencySign })}</div>;

      confirm(text, {
        context: this.context,
        cancelText: t('CANCEL'),
        confirmText: t('CONTINUE'),
      }).then((res) => {
        this.props.useCredits().then((res) => {
          NotificationManager.create({
            type: 'success',
            message: 'CREDIT_USED_SUCCESS',
            timeOut: 3000,
          });
        });
      });
    });
  }

  promotePopup() {
    const { t } = this.props;
    const text = <div>{t('UPDATE_SUBSCRIPTION_TO_USE_CREDITS')}</div>;
    confirm(text, {
      context: this.context,
      cancelText: t('CANCEL'),
      confirmText: t('CONTINUE'),
    }).then((res) => {
      browserHistory.push('/settings/billing');
    });
  }

  renderInvite(invite) {
    return (
      <div key={invite._id} className="earn__invite-list-item">
        <Invite invite={invite} />
      </div>
    );
  }

  renderInviteeList(invites) {
    const { t } = this.props;

    return (
      <div className="earn__invite-list narrow-container">
        { !invites.length ? (<h4>{t('EMPTY_INVITES_LISTS')}</h4>) : (
          <div>
            <h4>{t('INVITES_LIST')}</h4>
            { invites.map((invite) => this.renderInvite(invite)) }
          </div>
        ) }
      </div>
    );
  }

  renderMilestones(bonusMilestones) {
    return <MilestonesTable milestones={bonusMilestones} />;
  }

  render() {
    const {
      t, invites, group, user, subscription,
    } = this.props;
    const paymentMethod = _.get(subscription, 'details.payment_method', null);

    const credits = _.get(group, 'credits', 0);
    const { bonusMilestones } = group;

    const isFreePlan = (_.get(subscription, 'plan.id', null) === 'free' && !group.nextPlan) || group.nextPlan === 'free';

    let buttonComponent = !this.state.loading
      ? (credits ? <button className="btn" onClick={this.calculateUseCredits}>{t('USE_CREDITS')}</button> : null)
      : <div className="earn__btn-loader"><SmallLoader /></div>;

    const shouldShowUpdateButton = !paymentMethod || isFreePlan;
    const shouldShowControls = user.roleId !== 0;

    if (shouldShowUpdateButton) {
      buttonComponent = <button className="btn" onClick={this.promotePopup}>{t('UPDATE_SUBSCRIPTION')}</button>;
    }
    if (!shouldShowControls) {
      buttonComponent = null;
    }

    return (
      <div className="earn">
        <h1 className="settings-h1 overlay-h1">
          {t('EARN_CREDITS')}
        </h1>

        <div className="earn__status narrow-container">
          <div className="earn__status-description">
            <p dangerouslySetInnerHTML={{ __html: t('EARN_STATUS_DESCRIPTION') }} />
          </div>
          <div className="earn__status-chart">
            <CircularProgressbarWithChildren
              value={credits}
              maxValue={50}
              strokeWidth={10}
            >
              <div className="CircularProgressbar-text">
                <span className="value">{credits}</span>
                <span className="of-value">
                  {t('OF')}
                  {' '}
                  50
                </span>
                {t('CREDITS')}
                <br />
                {' '}
                {t('EARNED')}
              </div>
            </CircularProgressbarWithChildren>
            { buttonComponent }
          </div>
        </div>
        <InviteForm group={this.props.group} />
        {this.renderInviteeList(invites)}
        {this.renderMilestones(bonusMilestones)}
      </div>
    );
  }
}

InvitePage.propTypes = {
  t: PropTypes.func,
  invites: PropTypes.array,
  group: PropTypes.object,
  user: PropTypes.object,
  useCredits: PropTypes.func,
  calculateUseCredits: PropTypes.func,
};

export default InvitePage;
