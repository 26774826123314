import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { browserHistory, Link } from 'react-router';

import './users.scss';

import { withTranslation, Trans } from 'react-i18next';
import Add from './Add';
import User from './User';

import accountApi from '../../../actions/account';
import { fetchMatches } from '../../../actions/index';
import { sendRegisterRequest } from '../../../actions/auth';
import { getPlans } from '../../../actions/plans';
import { getGroup } from '../../../actions/group';

import { updateUserLimits } from '../../../actions/subscription';

@withTranslation()
@connect((state) => ({
  auth: state.auth,
  user: state.auth.user,
  users: state.users,
  roles: state.roles,
  plans: state.plans,
  group: state.group,
  subscription: state.subscription,
}))

class Users extends Component {
  static propTypes = {
    auth: PropTypes.object,
    user: PropTypes.object,
    users: PropTypes.object,
    roles: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object,
    ]),
    plans: PropTypes.array,
    group: PropTypes.object,
    dispatch: PropTypes.func,
  };

  constructor() {
    super();

    this.state = {
      roles: [],
      edit: null,
    };

    this.addNewUser = this.addNewUser.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
    this.setEditMode = this.setEditMode.bind(this);
    this.editUser = this.editUser.bind(this);
  }

  componentDidMount() {
    const { i18n } = this.props;
    const lng = i18n.language;

    const roleName = this.props.auth.user.role.name;
    const groupAdmin = roleName === 'Group admin';

    if (roleName === 'Admin' || groupAdmin) {
      this.props.dispatch(accountApi.actions.roles((err) => {
        if (!err) {
          this.setState({
            roles:
              this.props.roles.data
                .map((role) => ({
                  value: role._id,
                  label: _.get(role, `name_${lng}`, role.name),
                  tag: role.tag,
                })),
          });
        }
      }));
      this.props.dispatch(accountApi.actions.groupUsers());
      this.props.dispatch(getPlans());
      this.props.dispatch(getGroup());
    } else {
      browserHistory.replace('/');
    }
  }

  componentDidUpdate(prevProps) {
    const { auth } = this.props;
    const prevAuth = prevProps.auth;
    if (prevAuth.signUpSuccess !== auth.signUpSuccess) {
      this.props.dispatch(accountApi.actions.groupUsers());
    }
  }

  render() {
    let roleOptions = this.state.roles;
    const admin = this.props.auth.user.role.tag === 'admin';
    const groupAdmin = this.props.auth.user.role.tag === 'group_admin';

    if (groupAdmin) {
      roleOptions = roleOptions.filter((role) => role.tag !== 'admin');
    }

    const permissions = {
      canEditUser: false,
      canEditGroupAdmin: false,
      canEditAdmin: false,
    };

    if (admin) {
      permissions.canEditUser = true;
      permissions.canEditGroupAdmin = true;
      permissions.canEditAdmin = true;
    }

    if (groupAdmin) {
      permissions.canEditUser = true;
      permissions.canEditGroupAdmin = true;
      permissions.canEditAdmin = false;
    }

    const {
      users, group, plans, t, subscription,
    } = this.props;

    if (!subscription.details) {
      return (
        <div>
          <p className="users-empty-message">
            {t('CHOOSE_A_SUBSCRIPTION_FOR_MANAGEMENT')}
          </p>
        </div>
      );
    }

    if (!plans.length || !group._id || !users.data.length) return null;
    const groupOptions = [{ value: group._id, label: group.name }];

    let isMaxAccounts = true;
    const maxScouts = subscription.scouts.max;

    if (subscription.scouts.current < maxScouts) {
      isMaxAccounts = false;
    }

    if (subscription.scouts.max === 'INFINITY') {
      isMaxAccounts = false;
    }

    return (
      <div>
        <h1 className="settings-h1 overlay-h1">
          {t('MENU_GROUP_MEMBERS')}
          {' '}
          {subscription.scouts.max !== 'INFINITY' ? (
            <span>
              {subscription.scouts.current}
              {' '}
              /
              {' '}
              {subscription.scouts.max}
            </span>
          ) : null}
        </h1>
        { this.renderAddAccountOrLimitMessage(isMaxAccounts, roleOptions, groupOptions, groupAdmin) }
        <table className="users-list">
          <thead>
            <tr>
              <th width="8%">{t('ID')}</th>
              <th width="16%">{t('FIRST_NAME')}</th>
              <th width="16%">{t('LAST_NAME')}</th>
              <th width="16%">{t('EMAIL')}</th>
              <th>{t('ROLE')}</th>
              <th />
            </tr>
          </thead>
          <tbody>
            { this.renderUsersList({ groupOptions, roleOptions, permissions }) }
          </tbody>
        </table>
      </div>
    );
  }

  renderAddAccountOrLimitMessage(isMaxAccounts, roleOptions, groupOptions, groupAdmin) {
    const { t } = this.props;
    if (isMaxAccounts) {
      return (
        <p className="limits-message">
          {t('SUBSCRIPTION_LIMIT_MESSAGE')}
        </p>
      );
    }

    return <Add roles={roleOptions} groups={groupOptions} groupAdmin={groupAdmin} create={this.addNewUser} />;
  }

  renderUsersList({ groupOptions, roleOptions, permissions }) {
    const users = this.props.users.data;
    const groupAdmin = this.props.auth.user.role.tag === 'group_admin';

    if (!users) return null;

    return users.map((user, i) => (
      <User
        roles={this.state.roles}
        permissions={permissions}
        roleOptions={roleOptions}
        groupAdmin={groupAdmin}
        groups={groupOptions}
        key={user._id}
        user={{ ...user, index: ++i }}
        delete={this.deleteUser}
        editMode={this.state.edit === user._id}
        setEdit={this.setEditMode}
        edit={this.editUser}
      />
    ));
  }

  setEditMode(id) {
    this.setState({ edit: id });
  }

  addNewUser(user) {
    const { subscription } = this.props;
    const emailDuplicate = _.find(this.props.users.data, { email: user.email });
    if (emailDuplicate) {
      return 'email';
    }

    this.props.dispatch(updateUserLimits({ current: ++subscription.scouts.current }));
    this.props.dispatch(sendRegisterRequest({ ...user, groupId: this.props.group._id }));
    return false;
  }

  deleteUser(id, importGames) {
    const { subscription } = this.props;
    this.props.dispatch(updateUserLimits({ current: --subscription.scouts.current }));
    this.props
      .dispatch(accountApi.actions.user.delete({ id, importGames }))
      .then(() => {
        this.props
          .dispatch(accountApi.actions.groupUsers()).then(() => {
            this.props.dispatch(fetchMatches());
          });
      });
  }

  editUser(user) {
    const {
      id, firstName, lastName, email, roleId, groupId, active,
    } = user;
    const emailDuplicate = _.find(this.props.users.data, { email });

    if (emailDuplicate && emailDuplicate._id !== id) {
      return 'email';
    }
    this.props.dispatch(accountApi.actions.user.put({ id }, {
      body: JSON.stringify({
        firstName, lastName, email, roleId, groupId: this.props.group._id, active,
      }),
    }));

    return false;
  }
}

export default Users;
