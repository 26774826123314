import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import './position-selector.scss';
import ReactTooltip from 'react-tooltip';
import { Position } from '../../../favorite/components/positions_list';
import { getPosition } from '../../../../helpers';
import DemoMessage from '../../../ui/demo-message';

import { changeFormationPosition } from '../../../../actions/match';

@withTranslation()
@connect((state, props) => {
  return {
    match: props.match || state.match,
    positions: state.positions,
  };
}, { changeFormationPosition })
export default class PositionSelector extends Component {
  constructor(props) {
    super(props);

    this.setPosition = this.setPosition.bind(this);
  }

  componentDidMount() {
    ReactTooltip.rebuild();
  }

  setPosition(position, id) {
    const { changeFormationPosition, match } = this.props;
    const { side, player_id, data } = match;
    const player = _.get(data, `[${side}].players[${player_id}]`);

    changeFormationPosition({
      side,
      top: position.top,
      left: position.left,
      information: player.information,
      position,
    });
  }

  render() {
    const {
      match, t, positions, activePosition, disabled, inverted,
    } = this.props;
    const { side } = match;

    let currentFormation = _.get(match, `data[${side}].formations.lineup.positions`, []);
    currentFormation = currentFormation.filter((p) => p.position_id_detail !== undefined);

    return (
      <div className="single-report__position-select">
        <div className={`positions-list__pitch ${inverted ? 'inverted' : ''}`}>
          <div className="home" />
          <div className="away" />
          <div className="center" />
          {_.map(currentFormation, (position, id) => {
            const positionName = _.get(positions, `[${position.position_id_detail}].desc_short`, '');
            const coordinates = getPosition(positionName);
            const isActivePosition = !_.isEmpty(activePosition);
            const notSelectedClassName = isActivePosition && activePosition.position_id_detail !== position.position_id_detail ? 'not-selected-position' : '';
            const currentPositionClassName = activePosition.position_id_detail == position.position_id_detail ? 'current-position' : '';
            return (
              <Position
                data-tip={positionName}
                onClick={() => !disabled && this.setPosition(position, id)}
                key={id}
                left={`${coordinates.x * 100}%`}
                top={`${coordinates.y * 100}%`}
                className={`clickable ${notSelectedClassName} ${currentPositionClassName}`}
              />
            );
          })}
        </div>
        <label>{t('POSITION')}</label>
        <ReactTooltip />

        {!disabled && _.isEmpty(activePosition) ? <DemoMessage text={t('CHOOSE_POSITION_FIRST')} arrowPosition="top-right" /> : null}
      </div>
    );
  }
}

PositionSelector.propTypes = {
  match: PropTypes.object,
  positions: PropTypes.object,
  activePosition: PropTypes.object.isRequired,
  changeFormationPosition: PropTypes.func,
  inverted: PropTypes.bool,
  disabled: PropTypes.bool,
  t: PropTypes.func,
};
