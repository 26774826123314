import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import { withTranslation } from 'react-i18next';
import DraggablePlayer from './draggable-player';
import './draggable-list.scss';
import { DNDOptions } from '../../constants';

@withTranslation()
class DraggableList extends Component {
  renderHeading(name, className, sortPropName) {
    const { sort, sortDirection, changeSort } = this.props;
    const triangle = sort === sortPropName ? <span className={`tri ${sortDirection}`} /> : null;
    const sortClassName = sort === sortPropName ? 'sorted' : '';

    return (
      <span
        key={`${name}_${sortPropName}`}
        className={`th ${className} ${sortClassName}`}
        onClick={() => { changeSort(sortPropName, sortDirection === 'asc' ? 'desc' : 'asc'); }}
      >
        {triangle}
        {name}
      </span>
    );
  }

  render() {
    const {
      watchlist,
      t,
      disabledControls,
      changeOrder,
      removePlayer,
      players,
      disableDrag,
    } = this.props;

    if (!watchlist.details) return null;

    const headerColumns = [
      // name, className, propName
      [t('SORT'), 'col-drag-handle', 'sort'],
      [t('NAME'), 'col-player', 'last_name'],
      [t('POS'), 'col-position', 'position_name'],
      [t('AGE'), 'col-age', 'birth_date'],
      [t('TEAM'), 'col-team', 'team_name'],
      [t('CONTRACT_UNTIL'), 'col-date', 'contract_until'],
      [t('COL_OVERALL_RATING'), 'col-final-rating', 'final_rating_formatted'],
      [t('CATEGORY'), 'col-favorite', 'category'],
    ];

    return (
      <DndProvider backend={TouchBackend} options={DNDOptions}>
        <ul className="watchlists__players-table">
          <li className="heading">
            {
              headerColumns.map((col, i) => {
                return this.renderHeading(col[0], col[1], col[2]);
              })
            }
            <span className="th col-delete" />
          </li>
          {players.map((p, i) => (
            <DraggablePlayer
              key={p._id}
              index={i}
              player={p}
              watchlist={watchlist}
              changeOrder={changeOrder}
              removePlayer={removePlayer}
              disabledControls={disabledControls}
              disableDrag={disableDrag}
            />
          ))}
        </ul>
      </DndProvider>
    );
  }
}

DraggableList.propTypes = {
  watchlist: PropTypes.object,
  changeOrder: PropTypes.func,
  removePlayer: PropTypes.func,
  disabledControls: PropTypes.bool,
  t: PropTypes.func,
  sort: PropTypes.string,
  sortDirection: PropTypes.string,
  changeSort: PropTypes.func,
  players: PropTypes.array,
  disableDrag: PropTypes.bool,
};

export default DraggableList;
