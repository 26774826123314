import './notes.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import _ from 'lodash';

import { withTranslation } from 'react-i18next';
import { changeTeamNotes } from '../../../../actions/match';

function TextField({ input, ...other }) {
  return (
    <textarea
      {...input}
      name={other.name}
      placeholder={other.placeholder}
      id={`team-note-${input.name}`}
      onBlur={() => {
        other.onFieldSubmit();
      }}
    />
  );
}

@withTranslation()
@connect((state) => ({
  teams: state.teams,
  match: state.match,
  form: 'teams-note',
  enableReinitialize: true,
  initialValues: {
    home: state.match.data.home.notes,
    away: state.match.data.away.notes,
  },
}), { changeTeamNotes })

@reduxForm({})

export default class Notes extends Component {
  static propTypes = {
    changeTeamNotes: PropTypes.func,
    teams: PropTypes.object,
    match: PropTypes.object,
    form: PropTypes.string,
    enableReinitialize: PropTypes.bool,
    initialValues: PropTypes.object,
    handleSubmit: PropTypes.func,
    height: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  };

  constructor() {
    super();

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      const el = document.getElementById('team-note-home');
      el && el.focus();
    }, 500);
  }

  onSubmit(form) {
    const { home, away } = form;
    this.props.changeTeamNotes({
      home: home || '',
      away: away || '',
    });
  }

  render() {
    const {
      height, teams, match, handleSubmit, t, i18n,
    } = this.props;
    const { data } = match;
    const lng = i18n.language;

    if (!_.size(teams)) return null;

    return (
      <form className="notes" style={{ height }} onSubmit={handleSubmit(this.onSubmit)}>

        <div className="note">
          <div className="team">
            <span className="team-image" style={{ backgroundImage: `url(https://static.sp7f.com/teams/${data.home.team_id}.jpg)` }} />
            { _.get(teams, `[${data.home.team_id}].name_${lng}`, _.get(teams, `[${data.home.team_id}].name`, null)) }
          </div>
          <div className="teamnotes">
            <Field name="home" component={TextField} placeholder={t('TEAMNOTES_PLACEHOLDER', { team_name: _.get(teams, `[${data.home.team_id}].name_${lng}`, _.get(teams, `[${data.home.team_id}].name`, '')) })} onFieldSubmit={handleSubmit(this.onSubmit)} />
          </div>
        </div>

        <div className="note">
          <div className="team">
            <span className="team-image" style={{ backgroundImage: `url(https://static.sp7f.com/teams/${data.away.team_id}.jpg)` }} />
            { _.get(teams, `[${data.away.team_id}].name_${lng}`, _.get(teams, `[${data.away.team_id}].name`, null)) }
          </div>
          <div className="teamnotes">
            <Field name="away" component={TextField} placeholder={t('TEAMNOTES_PLACEHOLDER', { team_name: _.get(teams, `[${data.away.team_id}].name_${lng}`, _.get(teams, `[${data.away.team_id}].name`, '')) })} onFieldSubmit={handleSubmit(this.onSubmit)} />
          </div>
        </div>

      </form>
    );
  }
}
