import React from 'react';
import axios from 'axios';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { withTranslation } from 'react-i18next';
import { theme, style } from '../select_props';

import { ROOT_URL } from '../../../actions';

import DropdownIndicator from '../select/components/dropdown-indicator';

function StadiumSelect({ input, ...rest }) {
  return (
    <AsyncCreatableSelect
      className={rest.meta.touched && rest.meta.error ? 'no-valid' : ''}
      {...rest}
      name={input.name}
      value={input.value}
      styles={style}
      cacheOptions
      clearable={false}
      maxHeight={200}
      defaultOptions
      allowCreateWhileLoading={false}
      placeholder={rest.t('TYPE_TO_SEARCH_STADIUM')}
      components={{ DropdownIndicator }}
      hideSelectedOptions
      loadOptions={(inputValue) => {
        return axios.get(`${ROOT_URL}/stadiums/search`, {
          params: {
            needle: inputValue,
          },
        }).then((result) => {
          return result.data.map((t) => ({ value: t.id, label: t.stadium }));
        }).catch((err) => {
          console.error(err.message);
          return [];
        });
      }}
      onChange={(value) => {
        rest.changeCallback && rest.changeCallback(value);
        input.onChange(value);
      }}
      onCreateOption={(value) => {
        rest.onCreate(value);
      }}
      theme={theme}
    />
  );
}

export default withTranslation()(StadiumSelect);
