import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Icon from '../../Icon';

import './milestones-table.scss';
import { withTranslation } from 'react-i18next';

@withTranslation()
class MilestonesTable extends Component {
  constructor(props) {
    super(props);

    this.renderItem = this.renderItem.bind(this);
  }

  list = ['logged3Times', 'logged10Times', 'logged20Times', 'completed5Reports', 'added30Rating', 'added20Notes', 'added10Watchlist'];

  names = {
    logged3Times: 'LOGGED_IN_3_TIMES',
    logged10Times: 'LOGGED_IN_10_TIMES',
    logged20Times: 'LOGGED_IN_20_TIMES',
    completed5Reports: 'COMPLETED_5_MATCH_REPORTS',
    added30Rating: 'ADDED_30_RATINGS',
    added20Notes: 'ADDED_20_NOTES',
    added10Watchlist: 'ADDED_10_PLAYERS_TO_WATCHLIST',
  };

  icons = {
    logged3Times: 'logins',
    logged10Times: 'logins',
    logged20Times: 'logins',
    completed5Reports: 'match-icon',
    added30Rating: 'match-icon',
    added20Notes: 'match-icon',
    added10Watchlist: 'match-icon',
  };

  renderProgress(current, total) {
    let width = Math.round((100 * current) / total);
    if (width > 100) width = 100;
    return (
      <div className="progress-container">
        <div className="progress-line" style={{ width: `${width}%` }} />
      </div>
    );
  }

  renderItem(l) {
    const { t, milestones } = this.props;
    const item = milestones[l];

    if (!item) { return null; }
    if (l === 'logged20Times' && !milestones.logged10Times.active) { return null; }
    if (l === 'logged10Times' && !milestones.logged3Times.active) { return null; }

    const icon = (
      <span className="icon-container">
        <Icon name={this.icons[l]} />
        <span className="badge">
          {item.goal}
          ×
        </span>
      </span>
    );

    const current = item.active || item.current >= item.goal ? item.goal : item.current;

    return (
      <div className="milestones__row" key={l}>
        <div className="milestones__col milestones__icon">{icon}</div>
        <div className="milestones__col milestones__name">{t(this.names[l])}</div>
        <div className="milestones__col milestones__progress-bar">{ this.renderProgress(current, item.goal) }</div>
        <div className="milestones__col milestones__progress-count">
          {current}
          {' '}
          /
          {' '}
          {item.goal}
        </div>
        <div className="milestones__col milestones__credits">
          +
          {item.credits}
          {' '}
          Cr
        </div>
        <div className="milestones__col milestones__status">
          {item.active ? (<Icon name="checkmark" />) : null }
        </div>
      </div>
    );
  }

  render() {
    const { t, milestones } = this.props;
    if (!milestones) return null;

    return (
      <div className="milestones narrow-container">
        <h4>{t('MILESTONES')}</h4>
        {this.list.map((l) => this.renderItem(l))}
      </div>
    );
  }
}

MilestonesTable.propTypes = {
  milestones: PropTypes.object,
  t: PropTypes.func,
};

export default MilestonesTable;
