import React from 'react';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { theme, style } from '../select_props';
import Option from './player-option';
import SingleValue from './player-value';
import DropdownIndicator from '../select/components/dropdown-indicator';

import { fetchData } from './helpers';

function PlayerSelect(props) {
  const {
    teams, t, i18n, isDisabled, name, value, onChange, changeCallback, filter, exclude, key, onCreate, ...rest
  } = props;
  const lng = i18n.language;

  return (
    <AsyncCreatableSelect
      {...rest}
      key={key}
      name={name}
      styles={style}
      cacheOptions
      clearable={false}
      maxHeight={200}
      defaultOptions
      placeholder={t('TYPE_TO_SEARCH_PLAYER', { context: window.genderContext })}
      components={{ Option, SingleValue, DropdownIndicator }}
      hideSelectedOptions
      value={value}
      isDisabled={isDisabled}
      formatCreateLabel={(value) => { return t('CREATE_PLAYER', { context: window.genderContext }); }}
      onCreateOption={(value) => { onCreate(value); }}
      createOptionPosition="first"
      allowCreateWhileLoading
      loadOptions={fetchData(exclude, filter, teams, lng)}
      onChange={(value) => {
        changeCallback && changeCallback(value);
        onChange(value);
      }}
      theme={theme}
    />
  );
}

PlayerSelect.propTypes = {
  isDisabled: PropTypes.bool,
  name: PropTypes.string,
  teams: PropTypes.object,
  t: PropTypes.func,
  i18n: PropTypes.object,
  value: PropTypes.object,
  onChange: PropTypes.func,
  changeCallback: PropTypes.func,
  filter: PropTypes.array,
  onCreate: PropTypes.func,
  key: PropTypes.string,
  exclude: PropTypes.array,
};

export default withTranslation()(PlayerSelect);
