import { FETCH_UPCOMING_MATCHES, CLEAR_UPCOMING_MATCHES, SET_UPCOMING_LOADING, CHANGE_UPCOMING_FILTER } from '../actions/upcoming_matches';

const filtersList = [
  {
    field: 'league_id',
    type: 'select-leagues',
    name: 'LEAGUES',
    options: [
      {
        value: null,
        label: 'ALL_LEAGUES',
      },
    ],
  },
  {
    field: 'team_id',
    type: 'select-teams',
    name: 'TEAMS',
    options: [
      {
        value: null,
        label: 'ALL_TEAMS',
      },
    ],
  },
  {
    field: 'date',
    type: 'date-range',
    name: 'TIMEFRAME',
  },
];

const INITIAL_STATE = {
  total: null,
  list: [],
  reportsPerPage: 25,
  offset: 0,
  isListLoading: false,
  hasNextPage: false,
  filterId: null,
  sortId: null,
  listError: null,
  players: {},
  filtersList,
  filters: {},
};

export default function (state = INITIAL_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case CLEAR_UPCOMING_MATCHES: {
      return {
        ...state,
        list: [],
        total: 0,
        offset: 0,
      };
    }
    case FETCH_UPCOMING_MATCHES: {
      const { data } = payload;
      if (!data) {
        return {
          ...state,
          listError: null,
        };
      }

      const {
        success,
        message,
      } = data;
      if (success) {
        const {
          total,
          offset,
          upcoming,
        } = data;

        return {
          ...state,
          isListLoading: false,
          list: [...state.list, ...upcoming],
          total,
          hasNextPage: state.list.length < total,
          offset,
          listError: null,
        };
      }
      return {
        ...state,
        listError: message,
      };
    }
    case SET_UPCOMING_LOADING: {
      return {
        ...state,
        isListLoading: payload,
      };
    }
    case CHANGE_UPCOMING_FILTER: {
      return {
        ...state,
        filters: payload,
      };
    }
    default:
      return state;
  }
}
