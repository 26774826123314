import './add-new.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import VirtualizedSelect from 'react-virtualized-select';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import MaskedInput from 'react-maskedinput';
import moment from 'moment';

import { addNewPlayer } from '../../../../actions/match';

import TeamsSelect from '../../../ui/teams-select';
import TeamForm from '../../../ui/team-form';

const renderField = ({
  input, type, placeholder, meta: { touched, error, warning }, className,
}) => (
  <input {...input} placeholder={placeholder} type={type} className={`${className} ${(touched && error) ? 'no-valid' : ''}`} />
);

const maskedInput = ({ input, ...rest }) => (
  <MaskedInput
    {...input}
    name={rest.name}
    placeholder={rest.placeholder}
    mask={rest.mask}
    className={`input ${rest.className} ${rest.meta.touched && rest.meta.error ? 'no-valid' : ''}`}
  />
);

const fieldSelect = ({ input, ...rest }) => (
  <VirtualizedSelect
    className={rest.meta.touched && rest.meta.error ? 'no-valid' : ''}
    {...rest}
    name={input.name}
    value={input.value}
    clearable={false}
    searchable
    onChange={(value) => {
      input.onChange(value);
    }}
  />
);

const validate = (values, props) => {
  const errors = {};
  if (!values.first_name) { errors.first_name = 'Required'; }
  if (!values.last_name) { errors.last_name = 'Required'; }
  if (!values.jersey) { errors.jersey = 'Required'; }
  if (+values.jersey < 0) { errors.jersey = 'Cant be minus'; }
  if (!values.position) { errors.position = 'Required'; }

  if (values.birth_date) {
    const isDateValid = moment(values.birth_date, 'DD/MM/YYYY').isValid();
    if (!isDateValid) {
      errors.birth_date = 'Please, enter correct';
    }
  }

  if (props.onCreate && !values.team) {
    errors.team = 'Required';
  }

  if (props.match.data && values.jersey && !errors.jersey && !props.onCreate) {
    errors.jersey = _.map(props.match.data[props.match.sidebar.subTab].players, (player) => +player.information.jersey).indexOf(+values.jersey) !== -1;
  }

  return errors;
};

@withTranslation()
@connect((state) => ({
  positions: state.positions,
  match: state.match,
  auth: state.auth,
  form: 'add-player',
  enableReinitialize: true,
}), { addNewPlayer })

@reduxForm({ validate })

export default class AddNew extends Component {
  static propTypes = {
    addNewPlayer: PropTypes.func,
    reset: PropTypes.func,
    handleSubmit: PropTypes.func,
    positions: PropTypes.object,

  };

  constructor() {
    super();

    this.onSubmit = this.onSubmit.bind(this);
    this.createTeam = this.createTeam.bind(this);
    this.onTeamCreate = this.onTeamCreate.bind(this);
    this.onCancel = this.onCancel.bind(this);

    this.state = {
      isFormShown: false,
      team_name: '',
    };
  }

  createTeam(value) {
    this.setState({
      isFormShown: true,
      team_name: value,
    });
  }

  onTeamCreate(team) {
    this.props.change('team', { label: team.name, value: team.id });
    this.setState({
      isFormShown: false,
      team_name: '',
    });
  }

  onCancel() {
    this.setState({
      isFormShown: false,
      team_name: '',
    });
  }

  onSubmit(values) {
    const {
      first_name, last_name, jersey, position, birth_date, foot, team,
    } = values;

    const { match } = this.props;

    if (this.props.onCreate) {
      this.props.onCreate({
        first_name,
        last_name,
        jersey,
        team_id: team ? team.value : null,
        position_id: position.value,
        birth_date: birth_date && moment(birth_date, 'DD/MM/YYYY').isValid() ? moment(birth_date, 'DD/MM/YYYY').format('YYYY-MM-DD') : '',
        foot: foot && foot.value ? foot.value : '',
      });
    } else {
      this.props.addNewPlayer({
        first_name,
        last_name,
        jersey,
        position_id: position.value,
        birth_date: birth_date && moment(birth_date, 'DD/MM/YYYY').isValid() ? moment(birth_date, 'DD/MM/YYYY').format('YYYY-MM-DD') : '',
        foot: foot && foot.value ? foot.value : '',
      }, match);
    }

    setTimeout(() => {
      this.props.reset();
    }, 0);
  }

  render() {
    const { handleSubmit, t, i18n } = this.props;
    const lng = i18n.language;

    const positions = _.map(this.props.positions, (position) => ({
      label: _.get(position, `desc_short_${lng}`, _.get(position, 'desc_short', t('UNDEFINED'))),
      value: position.id,
    })).filter((p) => p.value < 9 && p.value != 0);

    const footOptions = [
      { value: null, label: t('N/D') },
      { value: 'Right', label: t('Right') },
      { value: 'Left', label: t('Left') },
      { value: 'Both', label: t('Both') },
    ];

    return (
      <div className="add-new">
        <form className="add-player-form" onSubmit={handleSubmit(this.onSubmit)}>
          <div className="add-player-title">{t('CREATE_NEW_PLAYER')}</div>
          {this.props.onCreate ? (
            <div className="row">
              <div className="col-12 team">
                <Field
                  placeholder={t('SELECT_TEAM')}
                  name="team"
                  component={TeamsSelect}
                  onCreate={this.createTeam}
                />
              </div>
            </div>
          ) : null}
          <div className="row">
            <div className="col-6">
              <Field className="input" name="first_name" type="text" placeholder={t('FIRST_NAME')} component={renderField} />
            </div>
            <div className="col-6">
              <Field className="input" name="last_name" type="text" placeholder={t('LAST_NAME')} component={renderField} />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <Field className="input" name="jersey" type="number" placeholder={t('NUMBER')} component={renderField} />
            </div>
            <div className="col-6">
              <Field placeholder={t('POSITION')} name="position" component={fieldSelect} options={positions} />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <Field
                placeholder={t('FOOT')}
                name="foot"
                component={fieldSelect}
                options={footOptions}
              />
            </div>
            <div className="col-6">
              <Field
                className="input"
                name="birth_date"
                type="number"
                placeholder="Birth date"
                component={maskedInput}
                mask="11/11/1111"
              />
              <span className="input-placeholder-example">DD/MM/YYYY</span>
            </div>
          </div>
          <div className="row">
            <div className="col-12 buttons-flex">
              <button className="btn btn-block">{t('ADD')}</button>
              {
                this.props.withClose ? (
                  <button
                    className="btn btn-block btn-red"
                    onClick={this.props.onCancelClick}
                  >
                    {t('CANCEL')}
                  </button>
                )
                  : null
              }
            </div>
          </div>
        </form>
        {this.state.isFormShown ? (
          <TeamForm
            name={this.state.team_name}
            onCreate={this.onTeamCreate}
            onCancel={this.onCancel}
          />
        ) : null}
      </div>
    );
  }
}
