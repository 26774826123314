import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { connect } from 'react-redux';
import _ from 'lodash';
import Select from 'react-select';
import { styleFilterNotes, filterTheme } from '../../../../ui/select_props';

import './notes-filter.scss';
import { updateNotesFilter } from '../../../../../actions/notes';
import { filterNotes } from '../../../../../reducers/notes';
import { withTranslation } from 'react-i18next';

@withTranslation()
@connect((state) => ({
  dictionaries: state.notes.dictionaries,
  notes: state.notes.notes,
  filter: state.notes.filter,
  filteredNotes: state.notes.filteredNotes,
  tags: state.notes.tags,
  user: state.auth.user,
}), {
  updateNotesFilter,
})
export default class NotesFilter extends Component {
  constructor(props) {
    super(props);
    this.onFilterClick = this.onFilterClick.bind(this);
    this.resetFilter = this.resetFilter.bind(this);
  }

  render() {
    const { height, user, t } = this.props;

    return (
      <div className="notes-filter">
        <Scrollbars style={{ height }} autoHide>
          <div className="notes-filter__wrapper">
            <h4 className="notes-filter__section-header">{t('FILTER')}</h4>
            {this.renderMatches()}
            {this.renderEvents()}
            {this.renderTags()}
            {user.role.name === 'Admin' || user.role.name === 'Group admin' ? this.renderUsers() : null}
          </div>
        </Scrollbars>
      </div>
    );
  }

  renderEvents() {
    const { filter, t } = this.props;
    const { events, notesByEvents } = this.props.dictionaries;
    const isAllActive = filter.event_id === null ? 'active' : null;

    const allItems = (
      <li className={`notes-filter__item all-items ${isAllActive}`} key="all" onClick={() => { this.resetFilter({ event_id: null }); }}>
        <span className="text">{t('ALL')}</span>
      </li>
    );

    return (
      <div className="notes-filter__section">
        <h4 className="notes-filter__section-title">{t('Events')}</h4>
        <ul>
          { allItems }
          {
            events.map((e) => {
              const activeClass = filter.event_id === e ? 'active' : '';
              const realCount = (notesByEvents[e] || []).length;

              if (!realCount) return null;

              return (
                <li
                  className={`notes-filter__item category ${activeClass}`}
                  key={e}
                  onClick={() => { this.onFilterClick({ event_id: e }); }}
                >
                  <span className="text">{t(e)}</span>
                  <span className="label">{realCount}</span>
                </li>
              );
            })
          }
        </ul>
      </div>
    );
  }

  renderMatches() {
    const { filter, t } = this.props;
    const isAllActive = filter.match_id === null ? 'active' : null;
    const { matchesByKey, notesByMatch } = this.props.dictionaries;

    let matches = [];
    for (const [key, value] of Object.entries(matchesByKey)) {
      matches.push(value);
    }

    if (!matches) {
      return null;
    }

    matches = _.orderBy(matches, 'date');

    const matchesOptions = [
      { value: null, label: t('ALL') },
      ...matches.map((m) => ({
        value: m._id,
        label: `${m.name} (${_.get(notesByMatch, `[${m._id}]`, []).length})`,
      })),
    ];

    const value = matchesOptions.find((o) => o.value === filter.match_id);

    const allItems = (
      <li className={`notes-filter__item all-items ${isAllActive}`} key="all" onClick={() => { this.resetFilter({ match_id: null }); }}>
        <span className="text">{t('ALL')}</span>
      </li>
    );

    return (
      <div className="notes-filter__section">
        <h4 className="notes-filter__section-title">{t('MATCHES')}</h4>

        <Select
          searchable
          styles={styleFilterNotes}
          theme={filterTheme}
          options={matchesOptions}
          value={value}
          menuPosition="fixed"
          menuPlacement="bottom"
          hideSelectedOptions
          onChange={(v) => {
            this.onFilterClick({ match_id: v.value });
          }}
        />
      </div>
    );
  }

  renderTags() {
    const { tags, filter, t } = this.props;

    const filteredNotes = filterNotes(this.props.notes, { ...this.props.filter, tag_id: null });

    if (!tags.length) { return null; }

    const isAllActive = filter.tag_id === null ? 'active' : null;

    const allItems = (
      <li className={`notes-filter__item all-items ${isAllActive}`} key="all" onClick={() => { this.resetFilter({ tag_id: null }); }}>
        <span className="text">{t('ALL')}</span>
      </li>
    );

    return (
      <div className="notes-filter__section">
        <h4 className="notes-filter__section-title">{t('TAGS')}</h4>
        <ul>
          { allItems }
          {
            tags.map((t) => {
              const activeClass = filter.tag_id === t._id ? 'active' : '';
              const realCount = filteredNotes.filter((n) => n.tags.find((tag) => t._id === tag)).length;

              if (!realCount) return null;

              return (
                <li
                  className={`notes-filter__item category ${activeClass}`}
                  key={t._id}
                  onClick={() => { this.onFilterClick({ tag_id: t._id }); }}
                >
                  <span className="text">{t.tag}</span>
                  <span className="label">{realCount}</span>
                </li>
              );
            })
          }
        </ul>
      </div>
    );
  }

  renderUsers() {
    const { notesByUser } = this.props.dictionaries;
    const { filter, t } = this.props;

    const filteredNotes = filterNotes(this.props.notes, { ...this.props.filter, user_id: null });

    const list = [];

    const isAllActive = filter.user_id === null ? 'active' : null;

    const allItems = (
      <li className={`notes-filter__item all-items ${isAllActive}`} key="all" onClick={() => { this.resetFilter({ user_id: null }); }}>
        <span className="text">{t('ALL')}</span>
      </li>
    );

    list.push(allItems);

    _.forOwn(notesByUser, (value, key) => {
      const user_id = _.get(value, '[0].user_id', null);
      const activeClass = filter.user_id === key ? 'active' : '';
      list.push(<li
        className={`notes-filter__item author ${activeClass}`}
        key={key}
        onClick={() => { this.onFilterClick({ user_id: key }); }}
      >
        <span className="text">{key}</span>
        <span className="label">{filteredNotes.filter((n) => +n.user_id === +user_id).length}</span>
      </li>);
    });

    return (
      <div className="notes-filter__section">
        <h4 className="notes-filter__section-title">{t('NOTES_BY')}</h4>
        <ul>
          { list }
        </ul>
      </div>
    );
  }

  onFilterClick(newFilter) {
    const { filter } = this.props;

    if (newFilter.player_id) {
      if (filter.player_id === newFilter.player_id) {
        return this.props.updateNotesFilter({ player_id: null });
      }
      return this.props.updateNotesFilter({ ...newFilter });
    }

    if (newFilter.user_id) {
      if (filter.user_id === newFilter.user_id) {
        return this.props.updateNotesFilter({ user_id: null });
      }
      return this.props.updateNotesFilter({ ...newFilter });
    }

    if (newFilter.tag_id) {
      if (filter.tag_id === newFilter.tag_id) {
        return this.props.updateNotesFilter({ ...newFilter, tag_id: null });
      }
      return this.props.updateNotesFilter({ ...newFilter });
    }

    if (newFilter.event_id) {
      if (filter.event_id === newFilter.event_id) {
        return this.props.updateNotesFilter({ ...newFilter, event_id: null });
      }
      return this.props.updateNotesFilter({ ...newFilter });
    }

    return this.props.updateNotesFilter({ ...newFilter });
  }

  resetFilter(props) {
    const { filter } = this.props;
    this.props.updateNotesFilter({ ...filter, ...props });
  }
}
