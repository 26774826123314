import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';

import { renderDate } from '../../../../helpers';
import EditForm from './edit';

import './users-table.scss';

@withTranslation()
class UsersTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: null,
      editUser: null,
    };

    this.executeScroll = this.executeScroll.bind(this);
  }

  componentDidMount() {
    if (this.props.selectedUser) {
      this.executeScroll();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedUser !== this.props.selectedUser) {
      this.executeScroll();
    }
  }

  executeScroll() {
    if (this.selectedUser) {
      this.selectedUser.scrollIntoView();
    }
  }

  renderUser(user) {
    const {
      t, i18n, roles, updateUser, deleteUser, selectedUser, resetPassword,
    } = this.props;

    const lng = i18n.language;

    const registered = renderDate(user.createdAt);
    const acceptPrivacy = renderDate(user.acceptPrivacyDate);
    const lastLoggedIn = renderDate(user.lastLoggedIn);
    const role = user.roleId !== undefined ? roles.find((r) => r._id === user.roleId) : {};
    const roleName = _.get(role, `name_${lng}`, _.get(role, 'name', '—'));

    return (
      <tbody
        key={user.id}
        ref={(rf) => {
          if (selectedUser && +user._id === +selectedUser) { this.selectedUser = rf; }
        }}
      >
        <tr className={`${!user.active ? 'disabled' : ''} ${+selectedUser === +user._id ? 'selected' : ''}`}>
          <td>{user.id}</td>
          <td>
            <strong>{`${user.firstName} ${user.lastName}`}</strong>
            <p className="reset-user-password-link"><span className="js-link" onClick={() => resetPassword(user._id)}>{t('RESET_PASSWORD')}</span></p>
          </td>
          <td>{user.email}</td>
          <td>{roleName}</td>
          <td>
            <p>
              <span className="label">
                {t('REGISTERED')}
                :
              </span>
              {' '}
              {registered}
            </p>
            <p>
              <span className="label">
                {t('ACCEPT_PRIVACY')}
                :
              </span>
              {' '}
              {acceptPrivacy}
            </p>
            <p>
              <span className="label">
                {t('LAST_LOGGED_IN')}
                :
              </span>
              {' '}
              {lastLoggedIn}
            </p>
          </td>
          <td className="admin-users-table__buttons">
            <p className="button">
              <span
                onClick={() => {
                  this.setState({ mode: 'edit', editUser: { ...user } });
                }}
                className="js-link"
              >
                {t('EDIT')}
              </span>
            </p>
            {
              user.active
                ? <p className="button"><span onClick={() => { updateUser({ ...user, active: false }); }} className="js-link orange">{t('DEACTIVATE')}</span></p>
                : <p className="button"><span onClick={() => { updateUser({ ...user, active: true }); }} className="js-link blue">{t('ACTIVATE')}</span></p>
            }
            <p className="button"><span onClick={() => { deleteUser(user); }} className="js-link red">{t('DELETE')}</span></p>
          </td>
        </tr>
        <tr className="empty">
          <td colSpan="6" />
        </tr>
      </tbody>
    );
  }

  renderUserForm() {
    const { i18n, roles, updateUser } = this.props;
    const lng = i18n.language;
    const user = this.state.editUser;
    const rolesOptions = roles.map((r) => ({ value: r._id, label: _.get(r, `name_${lng}`, r.name) }));

    return (
      <EditForm
        key={user && user._id}
        user={user}
        rolesOptions={rolesOptions}
        onSubmit={(user) => {
          updateUser(user);
          this.setState({ mode: null, editUser: null });
        }}
        onCancel={() => {
          this.setState({ mode: null, editUser: null });
        }}
      />
    );
  }

  render() {
    const {
      users, t, groupId, selectedUser,
    } = this.props;
    const { mode, editUser } = this.state;

    const isNew = mode === 'new';
    const isEdit = mode === 'edit';

    return (
      <table className="admin-users-table">
        <thead>
          <tr className="head">
            <th className="col__id">{t('ID')}</th>
            <th className="col__name">{t('NAME')}</th>
            <th className="col__email">{t('EMAIL')}</th>
            <th className="col__role">{t('ROLE')}</th>
            <th className="col__dates" />
            <th className="col__buttons add-user">
              <button
                className="btn btn-small"
                onClick={() => {
                  this.setState({
                    mode: 'new',
                    editUser: {
                      groupId,
                    },
                  });
                }}
              >
                {t('ADD_USER')}
              </button>
            </th>
          </tr>
        </thead>
        { isNew ? this.renderUserForm() : null }
        {
          users.map((u) => {
            if (isEdit && editUser._id === u._id) {
              return this.renderUserForm();
            }
            return this.renderUser(u);
          })
        }
      </table>
    );
  }
}

UsersTable.propTypes = {
  users: PropTypes.array,
  t: PropTypes.func,
  add: PropTypes.func,
  update: PropTypes.func,
  remove: PropTypes.func,
  selectedUser: PropTypes.number,
  updateUser: PropTypes.func,
  deleteUser: PropTypes.func,
  resetPassword: PropTypes.func,
  roles: PropTypes.array,
};

export default UsersTable;
