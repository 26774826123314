import _ from 'lodash';

import { FETCH_PLANS } from '../actions/plans';

const INITIAL_STATE = [];

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_PLANS:
      return action.payload.data.plans;

    default:
      return state;
  }
}
