import React, { Component } from 'react';
import * as d3 from 'd3';
import { Link } from 'react-router';
import _ from 'lodash';

import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PlayerImage from '../../../player-image';
import AddToFavorite from '../../../ui/add-to-favorites';

import { fetchFavoritesInfo, removeFromFavorites } from '../../../../actions/favorites';
import { getPlayerImage, getTeamImage, getWatchlistCategory } from '../../../../helpers';
import { updateFavoriteLimits } from '../../../../actions/subscription';

import PlayerMenu from '../../../ui/player-table-menu';
import NotePopup from '../../../ui/note-popup';

import Icon from '../../../Icon';

import './players_list.scss';
import DemoBadge from '../../../ui/demo-badge';

@withTranslation()
@connect((state) => ({
  favorites: state.favorites,
  teams: state.teams,
  positions: state.positions,
  matches: state.matches,
  user: state.auth.user,
  users: state.users,
  subscription: state.subscription,
}), { fetchFavoritesInfo, removeFromFavorites, updateFavoriteLimits })
export default class PlayersList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sort: 'sort',
      dir: 'asc',
      playerForNote: null,
    };

    this.renderRating = this.renderRating.bind(this);
    this.renderPlayer = this.renderPlayer.bind(this);
    this.renderPlayers = this.renderPlayers.bind(this);
  }

  renderRating = (rate) => {
    const { t } = this.props;
    const color = d3.scaleLinear().domain([1, 2, 3, 4, 5]).range(['#1D619F', '#6CB5D7', '#A4A6A4', '#F98736', '#DA4B06']);
    const title = [t('NONE'), t('UNINTERESTING'), t('LESS_INTERESTING'), t('FOCUS'), t('INTENSIVE_FOCUS'), t('IMMEDIATELY')];

    const intRate = Math.round(rate);

    if (!intRate) {
      return (
        <span className="favorite-rate">
          <span className="legend" style={{ backgroundColor: '#000' }} />
          { title[0] }
        </span>
      );
    }

    return (
      <span className="favorite-rate">
        <span className="legend" style={{ backgroundColor: color(intRate) }} />
        { title[intRate] }
        ,
        {intRate}
      </span>
    );
  };

  renderPlayer = (player, isFirst, isLast, i) => {
    const { t, user, subscription } = this.props;

    const isSort = this.state.sort === 'sort';

    const {
      player_id,
      first_name,
      last_name,
      birth_date,
      country,
      team_name,
      team_id,
      contract_until,
      final_rating_formatted,
    } = player;

    const playerImage = getPlayerImage(player);
    const teamImage = getTeamImage(team_id);

    const isGroupPlayer = +user._id !== +_.get(player, 'meta.user_id', null);

    const actions = [
      {
        name: 'ADD_A_NOTE',
        action: () => {
          this.setState({ playerForNote: player });
        },
        icon: <Icon name="note" className="list-icon add-note-icon" />,
      },
      {
        name: 'REMOVE_FROM_WATCHLIST',
        action: () => {
          if (confirm(isGroupPlayer ? t('DELETE_GROUP_MEMBER_FAVORITE', { context: window.genderContext }) : t('DELETE_FROM_FAVORITE', { context: window.genderContext }))) {
            this.props.removeFromFavorites(player.favorite_id);
            this.props.updateFavoriteLimits({ current: subscription.favorites.current - 1 });
          }
        },
        icon: <Icon name="close" className="list-icon close-icon" />,
      },
    ];

    return (
      <tr key={player_id} ые>
        <td className="col__last-name">
          { player_id === 'demo' ? <DemoBadge /> : null }
          <span className="favorite-player-name">
            <Link to={`/players/${player_id}/summary`}>
              <PlayerImage className="player-image" src={playerImage} />
              <span className="favorite-player-name_last">{ last_name }</span>
              {' '}
              { first_name !== last_name ? first_name : null }
            </Link>
          </span>
        </td>
        <td className="col__birth-date">{ birth_date }</td>
        <td className="col__country">{ country }</td>
        <td className="col__team">
          {
            team_id
              ? (
                <span className="favorite-player-team">
                  <PlayerImage className="team-image" src={teamImage} />
                  { team_name }
                </span>
              )
              : '–'
          }
        </td>
        <td className="col__contract">{ contract_until }</td>
        <td className="col__final-rating">{final_rating_formatted}</td>
        <td className="col__rating" style={{ position: 'relative', zIndex: 999 - i }}>
          {this.props.noStarOptions ? null : (
            <AddToFavorite
              match_id={null}
              player={player}
              added_message={t('WATCHLIST_CATEGORY_CHANGED')}
            >
              <div className="favorite-selector">
                <Icon name="favorite" />
                {' '}
                {getWatchlistCategory(player, t)}
                {' '}
                <Icon name="chevron_down" className="chevron-down" />
              </div>
            </AddToFavorite>
          )}
        </td>
        <td className="col__menu" style={{ position: 'relative', zIndex: 1000 - i }}>
          <PlayerMenu actionsList={actions} player={player} />
        </td>
      </tr>
    );
  };

  changeSort(field) {
    let { sort, dir } = this.state;

    if (sort === field) {
      dir = dir === 'asc' ? 'desc' : 'asc';
    } else {
      sort = field;
      dir = 'asc';
    }

    this.setState({ sort, dir });
  }

  renderPlayers() {
    const { players } = this.props;
    const { sort, dir } = this.state;
    const sortedList = _.orderBy(players, sort, dir);

    return sortedList.map((p, i) => this.renderPlayer(p, i === 0, i === sortedList.length - 1, i));
  }

  render() {
    const { sort, dir } = this.state;
    const dirCls = dir === 'asc' ? 'asc' : 'desc';
    const {
      t, i18n, positions, position_id, zIndex,
    } = this.props;
    const lng = i18n.language;
    // <th onClick={ () => { this.changeSort('position_name') }} className={ sort === 'position_name' ? 'sorted' : ''}>{t('POSITION').substring(0,3)}. { sort === 'position_name' ? <span className={`tri ${dirCls}`} /> : null }</th>
    return (
      <section className="favorites-list__wrapper" style={{ zIndex }}>
        <h2>{_.get(positions, `[${position_id}].desc_long_${lng}`, _.get(positions, `[${position_id}].desc_long`, ''))}</h2>
        <table className="favorites-list">
          <thead>
            <tr>
              <th onClick={() => { this.changeSort('last_name'); }} className={`col__last-name ${sort === 'last_name' ? 'sorted' : ''}`}>
                {t('PLAYER', { context: window.genderContext })}
                {' '}
                { sort === 'last_name' ? <span className={`tri ${dirCls}`} /> : null }
              </th>
              <th onClick={() => { this.changeSort('birth_date_raw'); }} className={`col__birth-date ${sort === 'birth_date_raw' ? 'sorted' : ''}`}>
                {t('AGE')}
                {' '}
                { sort === 'birth_date_raw' ? <span className={`tri ${dirCls}`} /> : null }
              </th>
              <th onClick={() => { this.changeSort('country'); }} className={`col__country ${sort === 'country' ? 'sorted' : ''}`}>
                {t('COUNTRY')}
                {' '}
                { sort === 'country' ? <span className={`tri ${dirCls}`} /> : null }
              </th>
              <th onClick={() => { this.changeSort('team_name'); }} className={`col__team ${sort === 'team_name' ? 'sorted' : ''}`}>
                {t('TEAM')}
                {' '}
                { sort === 'team_name' ? <span className={`tri ${dirCls}`} /> : null }
              </th>
              <th onClick={() => { this.changeSort('contract_until_raw'); }} className={`col__contract ${sort === 'contract_until_raw' ? 'sorted' : ''}`}>
                {t('CONTRACT_UNTIL')}
                {' '}
                { sort === 'contract_until_raw' ? <span className={`tri ${dirCls}`} /> : null }
              </th>
              <th onClick={() => { this.changeSort('final_rating'); }} className={`col__final-rating ${sort === 'final_rating' ? 'sorted' : ''}`}>
                {t('COL_OVERALL_RATING')}
                {' '}
                { sort === 'final_rating' ? <span className={`tri ${dirCls}`} /> : null }
              </th>
              <th onClick={() => { this.changeSort('category'); }} className={`col__rating ${sort === 'category' ? 'sorted' : ''}`}>
                {t('CATEGORY')}
                {' '}
                { sort === 'category' ? <span className={`tri ${dirCls}`} /> : null }
              </th>
              <th className="col__menu" />
            </tr>
          </thead>
          <tbody>
            { this.renderPlayers() }
          </tbody>
        </table>
        { this.state.playerForNote ? <NotePopup player={this.state.playerForNote} onCloseClick={() => { this.setState({ playerForNote: null }); }} /> : null }
      </section>
    );
  }
}
