import React from 'react';
import { components } from 'react-select';
import TeamView from '../team-view';

export default function (props) {
  const { innerProps, innerRef, data } = props;
  return (
    <components.SingleValue {...props}>
      <TeamView {...{ ...data, noImage: props.selectProps.noImage }} />
    </components.SingleValue>
  );
}
