import './player.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { DragSource } from 'react-dnd';

import { withTranslation } from 'react-i18next';
import Icon from '../../../../Icon.js';
import PlayerImage from '../../../../player-image';
import { changeInnerControl, changePlayerControl } from '../../../../../actions/match';
import { getPlayerImage } from '../../../../../helpers';

@withTranslation()
@connect((state) => ({
  positions: state.positions,
  match: state.match,
}), { changeInnerControl, changePlayerControl })

@DragSource('player', {
  canDrag(props) {
    const { player, match, side } = props;

    const active = _.get(match, `current_positions[${side}][${match.snapshot}][${player.player_id}].active`, null);
    const subst = _.get(match, `current_positions[${side}][${match.snapshot}][${player.player_id}].substitution`, null);

    return !(active || subst);
  },

  beginDrag(props) {
    return {
      side: props.side,
      information: props.player.information,
    };
  },
}, (connect, monitor) => ({
  connectDragPreview: connect.dragPreview(),
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging(),
  canDrag: monitor.canDrag(),
}))

export default class Player extends Component {
  static propTypes = {
    item: PropTypes.object,
    itemType: PropTypes.string,
    currentOffset: PropTypes.shape({
      x: PropTypes.number.isRequired,
      y: PropTypes.number.isRequired,
    }),
    isDragging: PropTypes.bool.isRequired,
    side: PropTypes.string,
    player: PropTypes.object,
    positions: PropTypes.object,
    match: PropTypes.object,
    connectDragSource: PropTypes.func,
    changePlayerControl: PropTypes.func,
    changeInnerControl: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.openEditPlayer = this.openEditPlayer.bind(this);
  }

  openEditPlayer(side, player_id) {
    this.props.changePlayerControl({ side, player_id });
    setTimeout(() => {
      this.props.changeInnerControl({ value: 'notes' });
    }, 0);
  }

  render() {
    const {
      player, positions, connectDragSource, match, side, i18n,
    } = this.props;
    const lng = i18n.language;
    const { information } = player;

    const active = _.get(match, `current_positions[${side}][${match.snapshot}][${player.player_id}].active`, null);
    const subst = _.get(match, `current_positions[${side}][${match.snapshot}][${player.player_id}].substitution`, null);

    const disabled = active || subst;

    const pos_short = _.get(positions, `[${information.position_id}].desc_short`, '');

    const position = _.size(positions) ? _.get(positions, `[${information.position_id}].desc_short_${lng}`, pos_short) : null;
    const playerImage = getPlayerImage(information);

    return (
      <div className={`player ${disabled ? 'disabled' : ''}`}>
        <div className="player__number">{ information.jersey }</div>
        <PlayerImage className="player__image" src={playerImage} />
        <div className="player__name">
          { information.first_name !== information.last_name ? information.first_name : null }
          {' '}
          { information.last_name }
        </div>
        <div className="player__position">{ position }</div>
        <div
          className="player__edit"
          onClick={() => this.openEditPlayer(side, player.player_id)}
        >
          <Icon name="pencil" />
        </div>
        {
          connectDragSource(<div className="player-grab">
            <Icon name="player-move" />
                            </div>)
        }
      </div>
    );
  }
}
