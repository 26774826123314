import './settings.scss';

import React, { Component } from 'react';
import { connect } from 'react-redux';

import { withTranslation } from 'react-i18next';
import SubNav from '../sub-nav';
import Shortcuts from './shortcuts';
import MyAccount from './my-account';
import GroupMembers from './group-members';
import Billing from './billing';
import Earn from './earn';

import AddCard from './billing/components/add_card';
import BillingForm from './billing/components/billing_form';

@withTranslation()
@connect((state, own) => ({
  auth: state.auth,
  page: own.params.section,
  subsection: own.params.subsection,
  subscription: state.subscription,
}), { })
class Settings extends Component {
  render() {
    const {
      auth, page, subsection, t, subscription,
    } = this.props;
    const isAuthorized = auth && (auth.user.role.tag === 'group_admin' || auth.user.role.tag === 'admin');
    const canManageScouts = subscription.scouts.max === 'INFINITY' || +subscription.scouts.max > 1;

    const query = _.get(this.props, 'location.query', null);

    const sections = [
      {
        name: t('EARN_CREDITS'),
        link: 'earn',
        component: <Earn />,
        render: isAuthorized,
      },
      {
        name: t('MENU_SHORTCUTS'),
        link: 'shortcuts',
        component: <Shortcuts />,
        render: true,
      },
      {
        name: t('MENU_MY_ACCOUNT'),
        link: 'my-account',
        component: <MyAccount />,
        render: true,
      },
      {
        name: t('MENU_GROUP_MEMBERS'),
        link: 'members',
        component: <GroupMembers />,
        render: isAuthorized && canManageScouts,
      },
      {
        name: t('MENU_SUBSCRIPTION'),
        link: 'billing',
        component: <Billing subsection={subsection} />,
        render: isAuthorized,
        subsection: [
          {
            name: t('ADD_CARD'),
            showInMenu: false,
            link: 'add-card',
            component: <AddCard query={query} />,
            render: isAuthorized,
          },
          {
            name: t('BILLING_FORM'),
            link: 'address',
            showInMenu: false,
            component: <BillingForm query={query} />,
            render: isAuthorized,
          },
        ],
      },
    ];

    return (
      <div className="settings">
        <SubNav title={t('SETTINGS')} name="settings" list={sections} current={page} subsection={subsection} />
      </div>
    );
  }
}

export default Settings;
