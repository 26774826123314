import { FETCH_GROUP_COUPONS } from '../actions/group';

const INITIAL_STATE = null;

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_GROUP_COUPONS:
      return _.get(action, 'payload.data.data.coupon', null);
    default:
      return state;
  }
}
