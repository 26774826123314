import React, { Component } from 'react';
import * as d3 from 'd3';
import { Link } from 'react-router';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PlayerImage from '../../../player-image';
import AddToFavorite from '../../../ui/add-to-favorites';

import { fetchFavoritesInfo } from '../../../../actions/favorites';
import { getTeamImage, getWatchlistCategory } from '../../../../helpers';
import { deletePlayer } from '../../../../actions/entries';

import Icon from '../../../Icon';

import './entries_list.scss';
import confirm from '../../../confirm';

@withTranslation()
@connect((state) => ({
  favorites: state.favorites,
  teams: state.teams,
  positions: state.positions,
  matches: state.matches,
  user: state.auth.user,
  users: state.users,
}), { fetchFavoritesInfo, deletePlayer })
export default class PlayersList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sort: 'sort',
      dir: 'asc',
      page: 1,
      showOnPage: 10,
    };

    this.renderRating = this.renderRating.bind(this);
    this.renderPlayer = this.renderPlayer.bind(this);
    this.renderPlayers = this.renderPlayers.bind(this);
    this.deleteEntry = this.deleteEntry.bind(this);
  }

  renderRating = (rate) => {
    const { t } = this.props;
    const color = d3.scaleLinear().domain([1, 2, 3, 4, 5]).range(['#1D619F', '#6CB5D7', '#A4A6A4', '#F98736', '#DA4B06']);
    const title = [t('NONE'), t('UNINTERESTING'), t('LESS_INTERESTING'), t('FOCUS'), t('INTENSIVE_FOCUS'), t('IMMEDIATELY')];

    const intRate = Math.round(rate);

    if (!intRate) {
      return (
        <span className="favorite-rate">
          <span className="legend" style={{ backgroundColor: '#000' }} />
          { title[0] }
        </span>
      );
    }

    return (
      <span className="favorite-rate">
        <span className="legend" style={{ backgroundColor: color(intRate) }} />
        { title[intRate] }
        ,
        {intRate}
      </span>
    );
  };

  deleteEntry(player) {
    const { t } = this.props;

    const text = (dismiss, onCheck, isChecked) => (
      <div className="privacy-confirm">
        <h2 className="h2">{t('DELETE_ENTRY_CONFIRM_TITLE')}</h2>
        <p dangerouslySetInnerHTML={{ __html: t('DELETE_ENTRY_CONFIRM_TEXT') }} />
      </div>
    );

    confirm(text, {
      context: this,
      cancelText: t('CANCEL'),
      confirmText: t('CONTINUE'),
    }).then(() => {
      this.props.deletePlayer(player);
    }).catch((err) => {});
  }

  renderPlayer = (player, isFirst, isLast, i) => {
    const { t } = this.props;

    const {
      player_id,
      first_name,
      last_name,
      birth_date,
      country,
      team_name,
      team_id,
      notes,
      evaluations,
    } = player;

    const teamImage = getTeamImage(team_id);

    return (
      <tr key={player_id}>
        <td className="col__last-name">
          <span className="entry-player-name">
            <Link to={`/players/my/${player_id}/summary`}>
              <span className="favorite-player-name_last">{ last_name }</span>
              {' '}
              { first_name !== last_name ? first_name : null }
            </Link>
          </span>
        </td>
        <td className="col__birth-date">{ birth_date }</td>
        <td className="col__country">{ country }</td>
        <td className="col__team">
          {
            team_id
              ? (
                <span className="favorite-player-team">
                  <PlayerImage className="team-image" src={teamImage} />
                  { team_name }
                </span>
              )
              : '–'
          }
        </td>
        <td className="col__notes">{ notes }</td>
        <td className="col__evaluations">{ evaluations }</td>
        <td className="col__rating" style={{ position: 'relative', zIndex: 999 - i }}>
          <AddToFavorite
            match_id={null}
            player={player}
            added_message={t('WATCHLIST_CATEGORY_CHANGED')}
          >
            <div className="favorite-selector">
              <Icon name="favorite" />
              {' '}
              {getWatchlistCategory(player, t)}
              {' '}
              <Icon name="chevron_down" className="chevron-down" />
            </div>
          </AddToFavorite>
        </td>
        <td className="col__delete"><span className="delete-entry" onClick={() => this.deleteEntry(player)}><Icon name="trashcan" /></span></td>
      </tr>
    );
  };

  saveUserSort(player, dir) {
    this.props.updateSort(player, dir, this.props.position_id);
  }

  changeSort(field) {
    let { sort, dir } = this.state;

    if (sort === field) {
      dir = dir === 'asc' ? 'desc' : 'asc';
    } else {
      sort = field;
      dir = 'asc';
    }

    this.setState({ sort, dir });
  }

  renderPlayers() {
    const { players, withPaging } = this.props;
    const {
      sort, dir, page, showOnPage,
    } = this.state;
    let sortedList = _.orderBy(players, sort, dir);

    if (withPaging) {
      sortedList = sortedList.slice(0, page * showOnPage);
    }

    return sortedList.map((p, i) => this.renderPlayer(p, i === 0, i === sortedList.length - 1, i));
  }

  render() {
    const {
      sort, dir, page, showOnPage,
    } = this.state;
    const dirCls = dir === 'asc' ? 'asc' : 'desc';
    const {
      t, i18n, positions, position_id, zIndex, players, withPaging,
    } = this.props;

    const lng = i18n.language;

    const total = players.length;
    const isNextPage = page * showOnPage < total;
    const left = total - (page * showOnPage);
    // <th onClick={ () => { this.changeSort('position_name') }} className={ sort === 'position_name' ? 'sorted' : ''}>{t('POSITION').substring(0,3)}. { sort === 'position_name' ? <span className={`tri ${dirCls}`} /> : null }</th>
    return (
      <section className="favorites-list__wrapper" style={{ zIndex }}>
        <h2>{_.get(positions, `[${position_id}].desc_long_${lng}`, _.get(positions, `[${position_id}].desc_long`, ''))}</h2>
        <table className="favorites-list entries-list">
          <thead>
            <tr>
              <th onClick={() => { this.changeSort('last_name'); }} className={`col__last-name ${sort === 'last_name' ? 'sorted' : ''}`}>
                {t('PLAYER', { context: window.genderContext })}
                {' '}
                { sort === 'last_name' ? <span className={`tri ${dirCls}`} /> : null }
              </th>
              <th onClick={() => { this.changeSort('birth_date_raw'); }} className={`col__birth-date ${sort === 'birth_date_raw' ? 'sorted' : ''}`}>
                {t('AGE')}
                {' '}
                { sort === 'birth_date_raw' ? <span className={`tri ${dirCls}`} /> : null }
              </th>
              <th onClick={() => { this.changeSort('country'); }} className={`col__country ${sort === 'country' ? 'sorted' : ''}`}>
                {t('COUNTRY')}
                {' '}
                { sort === 'country' ? <span className={`tri ${dirCls}`} /> : null }
              </th>
              <th onClick={() => { this.changeSort('team_name'); }} className={`col__team ${sort === 'team_name' ? 'sorted' : ''}`}>
                {t('TEAM')}
                {' '}
                { sort === 'team_name' ? <span className={`tri ${dirCls}`} /> : null }
              </th>
              <th onClick={() => { this.changeSort('notes'); }} className={`col__notes ${sort === 'notes' ? 'sorted' : ''}`}>
                {t('NOTES')}
                {' '}
                { sort === 'notes' ? <span className={`tri ${dirCls}`} /> : null }
              </th>
              <th onClick={() => { this.changeSort('evaluations'); }} className={`col__evaluations ${sort === 'evaluations' ? 'sorted' : ''}`}>
                {t('EVALUATIONS')}
                {' '}
                { sort === 'evaluations' ? <span className={`tri ${dirCls}`} /> : null }
              </th>
              <th onClick={() => { this.changeSort('category'); }} className={`col__rating ${sort === 'category' ? 'sorted' : ''}`}>
                {t('CATEGORY')}
                {' '}
                { sort === 'category' ? <span className={`tri ${dirCls}`} /> : null }
              </th>
              <th className="col__delete" />
            </tr>
          </thead>
          <tbody>
            { this.renderPlayers() }
            { isNextPage && withPaging ? (
              <tr>
                <td colSpan={7} className="next-page">
                  <span
                    className="js-link"
                    onClick={() => {
                      this.setState({ page: page + 1 });
                    }}
                  >
                    {t('SHOW_NEXT_NUM_FROM', { num: showOnPage, left })}
                  </span>
                </td>
              </tr>
            ) : null }
          </tbody>
        </table>
      </section>
    );
  }
}

PlayersList.propTypes = {
  withPaging: PropTypes.bool,
  isEntries: PropTypes.bool,
  players: PropTypes.array,
  position_id: PropTypes.number,
  zIndex: PropTypes.number,
  updateSort: PropTypes.func,
  deletePlayer: PropTypes.func,
};
