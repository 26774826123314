import './area-player.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DragSource, DropTarget } from 'react-dnd';

import Controls from '../../../controls';
import Icon from '../../../../../Icon';
import PitchEventsIcons from '../../../../../ui/pitch-events-icons';

import { deactivatePlayer, changePlayerControl, swapPlayers } from '../../../../../../actions/match';
import { formatQuarterAndBirthYear } from '../../../../../../helpers';

function offset(el) {
  const rect = el.getBoundingClientRect();
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
}

@connect((state) => ({
  match: state.match,
  positions: state.positions,
}), { deactivatePlayer, changePlayerControl, swapPlayers })

@DragSource('player', {
  beginDrag(props) {
    const { player, side, match } = props;
    const { information } = match.data[side].players[player.player_id];

    return { side, information };
  },

  endDrag(props, monitor) {
    const { side, information } = monitor.getItem();

    !monitor.didDrop() && props.deactivatePlayer({ side, information });
  },
}, (connect, monitor) => ({
  connectDragPreview: connect.dragPreview(),
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging(),
  canDrag: true,
}))

@DropTarget('player', {
  drop(props, monitor) {
    const item = monitor.getItem();

    setTimeout(() => {
      props.swapPlayers({
        from: {
          player_id: item.information.key,
          side: item.side,
        },
        to: {
          player_id: props.player.player_id,
          side: props.side,
        },
      });
    }, 0);
  },
}, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  isOverCurrent: monitor.isOver({ shallow: true }),
  canDrop: true,
  itemType: monitor.getItemType(),
  monitor,
  item: monitor.getItem(),
}))

export default class Player extends Component {
  static propTypes = {
    item: PropTypes.object,
    itemType: PropTypes.string,
    currentOffset: PropTypes.shape({
      x: PropTypes.number.isRequired,
      y: PropTypes.number.isRequired,
    }),
    isDragging: PropTypes.bool.isRequired,
    side: PropTypes.string,
    match: PropTypes.object,
    changePlayerControl: PropTypes.func,
    player: PropTypes.object,
    connectDragSource: PropTypes.func,
    connectDropTarget: PropTypes.func,
    isOver: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      offsetLeft: 0,
      offsetTop: 0,
    };

    this.playerClick = this.playerClick.bind(this);
  }

  playerClick(player_id, side, event) {
    const { match } = this.props;

    if (player_id !== match.controls.player || side !== match.controls.side) {
      event.stopPropagation();

      this.props.changePlayerControl({ player_id, side });

      // Animate controls moving, to be always on screen;
      setTimeout(() => {
        const controls = this.refs.player_container.querySelector('.player-controls');
        const cOffset = offset(this.refs.player_container.querySelector('.player-controls'));
        const width = controls.clientWidth;
        const height = controls.clientHeight;
        const winW = window.innerWidth || d.documentElement.clientWidth || d.getElementsByTagName('body')[0].clientWidth;
        const winH = window.innerHeight || d.documentElement.clientHeight || d.getElementsByTagName('body')[0].clientHeight;

        if (cOffset.left < 0) {
          this.setState({ offsetLeft: Math.abs(cOffset.left) });
        } else if (cOffset.left + width > winW) {
          this.setState({ offsetLeft: -1 * (cOffset.left + width - winW) });
        }

        if (cOffset.top < 0) {
          this.setState({ offsetTop: Math.abs(cOffset.top) });
        } else if (cOffset.top + height > winH) {
          this.setState({ offsetHeight: -1 * (cOffset.top + height - winH) });
        }
      }, 10);
    }
  }

  render() {
    const {
      player, side, match, connectDragSource, isDragging, connectDropTarget, isOver, item,
    } = this.props;

    const { offsetLeft, offsetTop } = this.state;

    if (!match.data[side].players[player.player_id]) return null;

    const { information } = match.data[side].players[player.player_id];
    const itemSubstitution = item && isOver ? match.current_positions[item.side][match.snapshot][item.information.key].substitution : null;
    const substituted = match.data[side].positions[match.snapshot][player.player_id].swap;
    const { left, top } = player.coordinates;
    const isControlsOpen = match.controls.player == information.key && match.controls.side == side;
    const formattedAge = formatQuarterAndBirthYear(information);


    return (
      <div
        onClick={(event) => { this.playerClick(information.key, side, event); }}
        className={`area-player ${side} ${information.key == match.controls.player ? 'active' : ''} ${substituted ? 'substituted' : ''}`}
        style={{
          left: `${left * 100}%`, top: `${top * 100}%`, display: isDragging ? 'none' : 'block', transform: `translate(${isControlsOpen ? offsetLeft : 0}px,${isControlsOpen ? offsetTop : 0}px)`,
        }}
        ref="player_container"
      >
        <PitchEventsIcons
          match={match.data}
          player_id={player.player_id}
          side={side}
        />
        <div className="player__name">
          { information.last_name }
        </div>
        { isOver && (side === item.side || (!player.substitution && !itemSubstitution)) ? <Icon className="swap" name="swap" /> : null }
        { isControlsOpen ? <Controls /> : null }
        { connectDropTarget(connectDragSource(
          <div className="player__number">
            <div className="player__number-text">{ information.jersey }</div>
            <div className="player__birthdate">{formattedAge}</div>
          </div>)) }
      </div>
    );
  }
}
