import React, { Component } from 'react';
import { components } from 'react-select';

export default class CountryGroup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.handleHeaderClick = this.handleHeaderClick.bind(this);
  }

  handleHeaderClick() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  render() {
    const newProps = { ...this.props };
    let { isOpen } = this.state;

    if (this.props.selectProps.inputValue) {
      isOpen = true;
    }

    if (!isOpen) {
      newProps.options = [];
      newProps.children = [];
    }

    return (
      <components.Group
        {...newProps}
        headingProps={{ ...newProps.headingProps, handleHeaderClick: this.handleHeaderClick, isOpen }}
      />
    );
  }
}
