import _ from 'lodash';

import {
  FETCH_GROUP, SAVE_GROUP_DATA, CHANGE_GROUP_PLAN, CHANGE_GROUP_PERIOD, CANCEL_GROUP_SUBSCRIPTION, ADD_ADDITIONAL_PLAN_LICENCE, USE_GROUP_CREDITS, FETCH_CREDITS_AND_BONUS_MILESTONES,
} from '../actions/group';

const INITIAL_STATE = null;

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_GROUP:
      return action.payload.data;

    case SAVE_GROUP_DATA:
      return action.payload.data;

    case CHANGE_GROUP_PLAN:
      if (!action?.payload?.success) {
        return state;
      }

      return action.payload.data;

    case CANCEL_GROUP_SUBSCRIPTION:
      return action.payload.data;

    case ADD_ADDITIONAL_PLAN_LICENCE:
      const group = action.payload.data;
      if (!group) return state;
      return action.payload.data;

    case USE_GROUP_CREDITS:
      return state;

    default:
      return state;
  }
}
