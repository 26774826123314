import React, { useState } from 'react';
import axios from 'axios';
import AsyncSelect from 'react-select/async';
import { withTranslation } from 'react-i18next';
import { theme, styleHome } from '../select_props';

import { ROOT_URL } from '../../../actions';
import Option from './player-option';
import SingleValue from './player-option';
import Control from './control';
import Menu from './menu';
import LoadingMessage from './loading-message';

function PlayerSearch(props) {
  const {
    teams, positions, i18n,
  } = props;
  const lng = i18n.language;
  const [isLoading, setLoading] = useState(false);

  return (
    <AsyncSelect
      name={props.name}
      styles={styleHome}
      cacheOptions
      clearable={false}
      maxHeight={200}
      placeholder={props.t('SEARCH')}
      defaultOptions={false}
      isLoading={isLoading}
      noOptionsMessage={props.noResultsText}
      components={{
        Option,
        SingleValue,
        Control,
        Menu,
        LoadingMessage,
        DownChevron: null,
        CrossIcon: null,
        DropdownIndicator: null,
      }}
      hideSelectedOptions
      value={null}
      className="home-search"
      classNamePrefix="react-select"
      loadOptions={(inputValue) => {
        setLoading(true);
        return axios.get(`${ROOT_URL}/players/search`, {
          params: {
            needle: inputValue,
          },
        }).then((result) => {
          const { players } = result.data;
          const options = players.map((t) => {
            let playerName;

            if (t.first_name && t.last_name) {
              playerName = `${t.last_name}, ${t.first_name}`;
            }

            if (!t.first_name && t.last_name) {
              playerName = `${t.last_name}`;
            }

            if (!t.last_name) {
              playerName = `${t.short_name}`;
            }

            const team = _.get(teams, `[${t.team_id}].name_${lng}`, _.get(teams, `[${t.team_id}].name`, null));
            const position = _.get(positions, `[${t.position_id}].desc_short_${lng}`, _.get(positions, `[${t.position_id}].desc_short`, null));
            setLoading(false);
            return {
              value: t.player_id, label: playerName, team, data: t, position,
            };
          });
          return options;
        }).catch((err) => {
          console.error(err.message);
          setLoading(false);
          return [];
        });
      }}
      onChange={(value) => {
        props.onChange(value);
      }}
      theme={theme}
    />
  );
}

export default withTranslation()(PlayerSearch);
