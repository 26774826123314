import React from 'react';
import Icon from '../../Icon';
import './demo-badge.scss';

function DemoBadge() {
  return (
    <span className="demo-badge">
      <Icon name="demo-badge" />
    </span>
  );
}

export default DemoBadge;
