import React, { Component } from 'react';
import { connect } from 'react-redux';
import { submitCoupon, fetchCoupons } from '../../../../../actions/group';

import './coupons.scss';
import { withTranslation } from 'react-i18next';

@withTranslation()
@connect(() => {
  return {};
}, {
  submitCoupon, fetchCoupons,
})
export default class Coupons extends Component {
  constructor(props) {
    super(props);

    this.state = {
      coupon: '',
      error: '',
      pending: false,
    };

    this.submitCoupon = this.submitCoupon.bind(this);
    this.onCouponChange = this.onCouponChange.bind(this);
  }

  render() {
    const { currentPlan, t } = this.props;
    const nextInvoice = _.get(currentPlan, 'nextInvoice', null);
    let coupon = _.get(nextInvoice, 'discount.coupon', null);

    if (_.get(coupon, 'metadata.credits_coupon', null)) coupon = null;

    const couponValue = this.state.coupon;

    return (
      <div className="coupons">
        {
          coupon ? (
            <div dangerouslySetInnerHTML={{ __html: t('COUPON_USED', { coupon: coupon.id }) }} />
          ) : t('NO_ACTIVE_COUPONS')
        }

        <p className="label">{t('ADD_NEW_COUPONS')}</p>
        {
          !this.state.pending ? (
            <form className="coupon-form" onSubmit={this.submitCoupon}>
              <input className="inp" type="text" value={couponValue} placeholder={t('COUPON')} onChange={this.onCouponChange} />
              <button className="btn" style={{ display: couponValue != '' ? 'block' : 'none' }}>{t('ADD_COUPON')}</button>
            </form>
          ) : null
        }
        {
          this.state.error
            ? (
              <p className="coupon-error">
                {t(this.state.error)}
              </p>
            ) : null
        }
        {
          this.state.success
            ? (
              <p className="coupon-success">
                {this.state.success}
              </p>
            ) : null
        }

      </div>
    );
  }

  onCouponChange(e) {
    this.setState({ error: '', coupon: e.target.value });
  }

  submitCoupon(e) {
    e.preventDefault();
    const { coupon, error } = this.state;
    const { t } = this.props;

    if (!coupon) {
      this.setState({ error: t('COUPON_ERROR') });
      return;
    }
    this.setState({ pending: true, coupon: '' });
    this.props.submitCoupon(coupon)
      .then((result) => {
        const res = result.payload.data;
        if (!res.success) {
          this.setState({ pending: false, error: res.error ? res.error : '' });
        } else {
          window.location.reload();
        }
      });
  }
}
