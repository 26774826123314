import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, reduxForm } from 'redux-form';
import Validator from 'validator';
import Select from 'react-select';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { NotificationManager } from '../../../../notifications';

import i18next from '../../../../../i18n';

import { accountTheme, style } from '../../../../ui/select_props';

import './account-form.scss';
import accountApi from '../../../../../actions/account';
import { updateUser } from '../../../../../actions/auth';

function FieldInput({ input, t, ...rest }) {
  return (
    <div className="input-container">
      { rest.label ? <label>{rest.label}</label> : null }
      <input
        {...input}
        name={rest.name}
        placeholder={rest.placeholder}
        className={`inp input ${rest.className} ${rest.meta.touched && rest.meta.error ? 'no-valid' : ''}`}
      />
    </div>
  );
}

function FieldSelect({
  input, t, ...rest
}) {
  return (
    <div className="input-container">
      { rest.label ? <label>{rest.label}</label> : null }
      <Select
        {...rest}
        value={{ value: input.value, label: input.value === 'en' ? 'English' : input.value === 'pt' ? 'Portuguese' : 'Deutsch' }}
        name={input.name}
        isSearchable={false}
        placeholder={rest.placeholder}
        className={`select ${rest.className} ${rest.meta.touched && rest.meta.error ? 'no-valid' : ''}`}
        options={[
          { value: 'en', label: 'English' },
          { value: 'de', label: 'Deutsch' },
          { value: 'pt', label: 'Portuguese' },
        ]}
        theme={accountTheme}
        styles={style}
        onChange={(value) => {
          input.onChange(value.value);
        }}
      />
    </div>
  );
}

function GenderSelect({
  input, t, ...rest
}) {
  return (
    <div className="input-container">
      { rest.label ? <label>{rest.label}</label> : null }
      <Select
        {...rest}
        value={{ value: input.value, label: input.value === 'male' ? 'Male' : 'Female' }}
        name={input.name}
        isSearchable={false}
        placeholder={rest.placeholder}
        className={`select ${rest.className} ${rest.meta.touched && rest.meta.error ? 'no-valid' : ''}`}
        options={[
          { value: 'male', label: 'Male' },
          { value: 'female', label: 'Female' },
        ]}
        theme={accountTheme}
        styles={style}
        onChange={(value) => {
          input.onChange(value.value);
        }}
      />
    </div>
  );
}

@withTranslation()
@connect((state) => ({
  user: state.auth.user,
  initialValues: state.auth.user && state.auth.user._id ? {
    firstName: state.auth.user.firstName,
    lastName: state.auth.user.lastName,
    email: state.auth.user.email,
    position: state.auth.user.position,
    phone: state.auth.user.phone,
    lang: state.auth.user.lang,
    selectedGender: state.auth.user.selectedGender,
  } : undefined,
}), (dispatch) => bindActionCreators({
  dispatch,
  updateUser,
}, dispatch))
@reduxForm({
  form: 'accountDetails',
  validate: validateAccount,
})
export default class BilllingForm extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      error: null,
    };
  }

  onSubmit(values) {
    const { t, initialValues } = this.props;

    NotificationManager.create({
      message: 'SAVING',
      id: 'id',
      timeOut: 3000,
    });

    this.props.dispatch(accountApi.actions.user.put({
      id: this.props.user._id,
    }, {
      body: JSON.stringify(values),
    }, (err, result) => {
      if (err) {
        this.setState({ error: err.message });
        NotificationManager.create({
          message: 'ACCOUNT_NOT_SAVED',
          id: 'id',
          timeOut: 3000,
          type: 'error',
        });
      } else {
        if (values.selectedGender !== initialValues.selectedGender) {
          window.location.reload();
        }

        this.setState({ error: null });
        this.props.updateUser(result);
        NotificationManager.create({
          message: 'ACCOUNT_SAVED',
          id: 'id',
          timeOut: 3000,
        });
        i18next.changeLanguage(result.lang);
        moment.locale(result.lang);
      }
    }));
  }

  render() {
    const { handleSubmit, t, user } = this.props;

    return (
      <form className="my-account__form" onSubmit={handleSubmit(this.onSubmit)}>
        <h2 className="settings-h1 overlay-h1">{t('ACCOUNT_DETAILS')}</h2>
        <div className="form-row">
          <Field component={FieldInput} name="firstName" t={t} label={t('FIRST_NAME_LABEL')} placeholder={t('FIRST_NAME_PLACEHOLDER')} />
          <Field component={FieldInput} name="lastName" t={t} label={t('LAST_NAME_LABEL')} placeholder={t('LAST_NAME_PLACEHOLDER')} />
        </div>
        <div className="form-row">
          <Field component={FieldInput} name="position" t={t} label={t('POSITION_LABEL')} placeholder={t('POSITION_PLACEHOLDER')} />
          <Field component={FieldInput} name="phone" t={t} label={t('PHONE_LABEL')} placeholder={t('PHONE_PLACEHOLDER')} />
        </div>
        <div className="form-row">
          <Field component={FieldInput} name="email" t={t} label={t('EMAIL_LABEL')} placeholder={t('EMAIL_PLACEHOLDER')} />
          <Field component={FieldSelect} name="lang" t={t} label={t('LANGUAGE_LABEL')} placeholder={t('LANGUAGE_PLACEHOLDER')} />
        </div>
        {/* <div className="form-row">
          <Field component={GenderSelect} name="selectedGender" t={t} label={t('GENDER_LABEL')} placeholder={t('GENDER_PLACEHOLDER')} />
        </div> */}
        { this.state.error ? <p className="error-message">{ this.state.error }</p> : null}
        <div className="form-row submit-row">
          <button className="btn" type="submit">{t('SAVE_CHANGES')}</button>
        </div>
      </form>
    );
  }
}

export function validateAccount(values) {
  const errors = {};

  if (!values.firstName) {
    errors.firstName = 'FIRST_NAME_ERROR';
  }

  if (!values.lastName) {
    errors.lastName = 'LAST_NAME_ERROR';
  }

  if (values.phone && !Validator.isMobilePhone(values.phone, 'any')) {
    errors.phone = 'PHONE_NUMBER_ERROR';
  }

  if (!values.email || !Validator.isEmail(values.email)) {
    errors.email = 'EMAIL_ERROR';
  }

  return errors;
}
