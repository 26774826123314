import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import * as d3 from 'd3';
import { withTranslation } from 'react-i18next';

import './inner.scss';

import { changeInnerControl } from '../../../../../actions/match';
import { addToFavorite, removeFromFavorites } from '../../../../../actions/favorites';
import { getWindowWidth } from '../../../../../helpers';

@withTranslation()
@connect((state) => ({
  match: state.match,
  indicators: state.indicators,
  favorites: state.favorites,
  formations: state.formations,
}), { changeInnerControl, addToFavorite, removeFromFavorites })

export default class Inner extends Component {
  static propTypes = {
    match: PropTypes.object,
    indicators: PropTypes.object,
    favorites: PropTypes.object,
    formations: PropTypes.object,
    removeFromFavorites: PropTypes.func,
    addToFavorite: PropTypes.func,
    changeInnerControl: PropTypes.func,
    stopTimer: PropTypes.func,
  };

  constructor() {
    super();

    this.renderControl = this.renderControl.bind(this);
    this.renderControls = this.renderControls.bind(this);
    this.clickControl = this.clickControl.bind(this);
  }

  renderControl(key, transform) {
    const mediaQuery = getWindowWidth() > 960 ? 1 : 2 / 3;
    const { controls } = this.props.match;
    const { indicators, i18n } = this.props;
    const lng = i18n.language;
    const { iconSize } = controls;
    let title = _.get(indicators, ['info', key, `short_${lng}`], _.get(indicators, ['info', key, 'short'], ''));
    const wordsLength = title.split(' ').length;

    const iconY = -iconSize[1] / 2 * mediaQuery - (wordsLength > 1 ? 12 * mediaQuery : 8 * mediaQuery) - (getWindowWidth() > 960 ? 2 : 4);
    const textY = iconSize[1] / 2.5 * mediaQuery + (wordsLength > 1 ? 4 * mediaQuery : 8 * mediaQuery) - (getWindowWidth() > 960 ? 2 : 0);

    if (wordsLength > 1 && key !== 'relation') {
      title = title.split(' ').map((text, i) => <tspan x={0} y={textY} dy={i === 0 ? '0' : '1.1em'} key={i}>{ text }</tspan>);
    }

    if (key === 'relation') {
      title = title.split(' ');
      title = [
        <tspan key={1} x={0} y={textY} dy="0">1 vs. 1</tspan>,
        <tspan key={2} x={0} y={textY} dy="1.1em">{title[title.length - 1]}</tspan>,
      ];
    }

    if (key.startsWith('custom_')) {
      key = 'bars';
    }

    return (
      <g>
        <use transform={`translate(${transform})`} width={iconSize[0] * mediaQuery} height={iconSize[1] * mediaQuery} x={-iconSize[0] / 2 * mediaQuery} y={iconY} className="icon" xlinkHref={`/images/sprite.symbol.v107.svg#${key}`} />
        <text transform={`translate(${transform})`} y={textY}>{ title }</text>
      </g>
    );
  }

  clickControl(value, event) {
    event.stopPropagation();

    const {
      favorites, match, removeFromFavorites, addToFavorite, changeInnerControl, stopTimer,
    } = this.props;

    if (value === 'favorite') {
      if (_.find(favorites.list, { id: match.controls.player })) {
        removeFromFavorites(match.controls.player);
      } else {
        addToFavorite(match.controls.player);
      }
    } else {
      changeInnerControl({ value });
    }

    stopTimer();
  }

  renderControls() {
    const desktop = getWindowWidth() > 960;
    const { controls, current_positions, data } = this.props.match;
    const { indicators, favorites, formations } = this.props;
    const arc = d3.arc().innerRadius(desktop ? 21 : 13).outerRadius(desktop ? 105 : 75);
    const labelArc = d3.arc().innerRadius(desktop ? 30 : 25).outerRadius(desktop ? 107 : 75);
    const position_id_detail = _.get(current_positions, `[${controls.side}].lineup[${controls.player}].position_id_detail`, null);
    const current_formation_id = _.get(data, `${controls.side}.formations.lineup.id`, null);
    const current_formation = formations[current_formation_id];
    const position_id_upper = current_formation.positions.find((i) => +i.position_id_detail === +position_id_detail).position_id;
    const pie = d3.pie().startAngle(-Math.PI / 2).endAngle(Math.PI * 2 - Math.PI / 2).value((d) => 1);
    const primaryIndicators = _.get(indicators, `positions[${position_id_upper}].primary`, []).filter((i) => _.get(indicators, ['info', i, 'public'], true));

    return pie([...primaryIndicators, ...indicators.constant]).map((control, key) => (
      <g key={key} className={`control ${control.data !== 'favorite' ? (controls.inner === control.data ? 'active' : '') : (_.find(favorites.list, { id: controls.player }) ? 'active' : '')}`}>
        <path className="section" d={arc(control)} onClick={(e) => this.clickControl(control.data, e)} />
        { this.renderControl(control.data, labelArc.centroid(control)) }
      </g>
    ));
  }

  render() {
    return (
      <g className="inner">
        { this.renderControls() }
      </g>
    );
  }
}
