import _ from 'lodash';
import { FETCH_COUNTRIES } from '../actions/countries.js';

const INITIAL_STATE = {
  list: [],
  listById: {},
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_COUNTRIES:
      return {
        list: action.payload.data,
        listById: _.keyBy(action.payload.data, 'id'),
        listByAlpha2: _.keyBy(action.payload.data, 'alpha2code'),
      };
    default:
      return state;
  }
}
