import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import './Login.scss';

import { sendLoginRequest } from '../../actions/auth';

@withTranslation()
@connect((state) => ({
  signInError: state.auth.signInError,
}), { sendLoginRequest })
class Login extends Component {
  static propTypes = {
    sendLoginRequest: PropTypes.func,
    signInError: PropTypes.string,
  };

  constructor() {
    super();

    this.submit = this.submit.bind(this);
  }

  submit(event) {
    event.preventDefault();

    const { sendLoginRequest } = this.props;
    const email = this.email.value.toLowerCase().trim();
    const password = this.password.value;

    sendLoginRequest({ email, password });
  }

  render() {
    const { signInError, t } = this.props;

    return (
      <div className="wrapper">
        <div className="auth-logo">
          <div className="top-shade" />
          <img src="/images/logo-full.svg" width="196" height="57" />
        </div>

        <div className="auth-align">
          <div className="auth-container">
            <div className="auth-title">{t('LOGIN')}</div>

            <form className="auth-login" onSubmit={this.submit}>

              <div className="input">
                <label>E-mail address</label>
                <input type="email" ref={(c) => { this.email = c; }} placeholder={t('EMAIL')} required />
              </div>

              <div className="input">
                <label>Password</label>
                <p><Link to="/auth/reset" className="pull-left forgotpass">{t('FORGOT_PASSWORD')}</Link></p>
                <input type="password" ref={(c) => { this.password = c; }} placeholder={t('PASSWORD')} required />
                <div className={`error-message ${signInError ? 'visible' : ''}`}>{ signInError }</div>
              </div>

              <button type="submit" className="btn btn-block">{t('LOGIN')}</button>
              <p className="pull-left signup-text">
                <Link to="/registration/" className="pull-left signup-link">
                  {t('SIGNUP_LINK')}
                  {' '}
                  {t('SIGNUP_LINK_TEXT')}
                </Link>
              </p>
            </form>
          </div>
          <div className="auth-helpers" />
        </div>
      </div>
    );
  }
}

export default Login;
