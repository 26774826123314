import axios from 'axios';
import { ROOT_URL } from './index';

export const FETCH_HOME = 'FETCH_HOME';

export function fetchHome() {
  const request = axios.get(`${ROOT_URL}/home/`);
  return {
    type: FETCH_HOME,
    payload: request,
  };
}
