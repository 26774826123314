import axios from 'axios';
import localforage from 'localforage';
import { ROOT_URL } from '../actions';
import { MATCHES_COLLECTION, PLAYERS_COLLECTION } from './constants';

export default function (props) {
  const userId = localStorage.getItem('userId');
  const gender = localStorage.getItem('selectedGender');
  const collectionName = `${MATCHES_COLLECTION}_${gender}`;
  const playersCollectionName = `${PLAYERS_COLLECTION}_${gender}`;

  switch (window.online) {
    case true:
      return Promise
        .all([
          axios.get(`${ROOT_URL}/match/${props}`),
          localforage.getItem(`${collectionName}-${userId}`),
          localforage.getItem(`${playersCollectionName}-${userId}`),
        ])
        .then(([resServer, matchesClient, playersClient]) => {
          matchesClient = matchesClient || [];
          const matchServer = resServer.data.match;
          const playerServer = resServer.data.player;
          playersClient = playersClient || [];

          if (playerServer) {
            const index = playersClient.findIndex((p) => p.player_id === playerServer.player_id);
            if (index === -1) {
              playersClient.unshift(playerServer);
              playersClient = playersClient.slice(0, 10);
            } else {
              playersClient = [{...playerServer}, ...playersClient.slice(0, index), ...playersClient.slice(index + 1)].slice(0, 10);
            }
          }

          const index = matchesClient.findIndex((m) => m._id === matchServer._id);

          if (index !== -1) {
            matchesClient = [{ ...matchServer }, ...matchesClient.slice(0, index), ...matchesClient.slice(index + 1)];
          } else {
            matchesClient.unshift(matchServer);
            matchesClient = matchesClient.slice(0, 10);
          }

          localforage.setItem(`${collectionName}-${userId}`, matchesClient);
          localforage.setItem(`${playersCollectionName}-${userId}`, playersClient);
          return Promise.resolve({
            match: matchServer,
          });
        })
        .catch((err) => {
          console.error(err);
        });

    case false:
      return localforage
        .getItem(`${collectionName}-${userId}`)
        .then(([matches, players]) => {
          const match = matches.find((m) => m.match_id === props);
          return Promise.resolve({
            match,
          });
        })
        .catch((err) => {
          console.error(err);
        });
  }
}
