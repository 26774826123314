import _ from 'lodash';

import {
  FETCH_CUSTOMERS,
  CHANGE_SORT,
  FETCH_CUSTOMER,
  ADD_CUSTOMER,
  UPDATE_CUSTOMER,
  DELETE_CUSTOMER,
  RESET_CUSTOMER,
  LOAD_CUSTOMERS_LIST,
  LOAD_CUSTOMER_DETAILS,
  ERROR_CUSTOMERS,
  ERROR_CUSTOMER,
  GENERATE_FILTERS,
  CHANGE_FILTER,
  DELETE_CUSTOMER_USER,
  UPDATE_CUSTOMER_USER,
  CREATE_CUSTOMER_USER,
  RESET_CUSTOMER_USER_PASSWORD,
  MANUALLY_VERIFY_CUSTOMER_EMAIL,
} from '../actions/customers';

const filtersList = [
  {
    field: 'name-email',
    type: 'text',
    name: 'NAME_EMAIL',
    placeholder: 'TYPE_NAME_OR_EMAIL',
  },
  {
    field: 'active',
    type: 'select',
    name: 'ACTIVE_BOOL',
    options: [
      {
        value: null,
        label: 'SHOW_ALL',
      },
      {
        value: true,
        label: 'ACTIVE',
      },
      {
        value: false,
        label: 'INACTIVE',
      },
    ],
  },
  {
    field: 'createdAt',
    type: 'date-range',
    name: 'REGISTERED',
  },
  {
    field: 'stripe_cache.trial_end',
    type: 'date-range',
    name: 'TRIAL_END',
  },
  {
    field: 'accept-privacy-bool',
    type: 'select',
    name: 'ACCEPT_PRIVACY_BOOL',
    options: [
      {
        value: null,
        label: 'SHOW_ALL',
      },
      {
        value: true,
        label: 'PRIVACY_ACCEPTED',
      },
      {
        value: false,
        label: 'PRIVACY_NOT_ACCEPTED',
      },
    ],
  },
  {
    field: 'acceptPrivacyDate',
    type: 'date-range',
    name: 'ACCEPT_PRIVACY',
  },
  {
    field: 'last-logged-bool',
    type: 'select',
    name: 'LAST_LOGGED_IN_BOOL',
    options: [
      {
        value: null,
        label: 'SHOW_ALL',
      },
      {
        value: true,
        label: 'LOGGED_IN',
      },
      {
        value: false,
        label: 'NEVER_LOGGED_IN',
      },
    ],
  },
  {
    field: 'lastLoggedIn',
    type: 'date-range',
    name: 'LAST_LOGGED_IN',
  },
  {
    field: 'plan',
    type: 'select',
    name: 'PLAN',
    options: [
      {
        value: null,
        label: 'SHOW_ALL',
      },
      {
        value: 'no-plan',
        label: 'NO_PLAN',
      },
      {
        value: 'free',
        label: 'FREE_PLAN',
      },
      {
        value: 'all',
        label: 'ALL_PAID_PLANS',
      },
    ],
  },
  {
    field: 'coupon',
    type: 'text',
    name: 'COUPON',
    placeholder: 'START_TYPE_COUPON',
  },
  {
    field: 'stripe_cache.payment_method',
    type: 'select',
    name: 'PAYMENT_METHOD',
    options: [
      {
        value: null,
        label: 'SHOW_ALL',
      },
      {
        value: false,
        label: 'NO_PAYMENT_METHOD',
      },
      {
        value: 'sepa_debit',
        label: 'SEPA_DEBIT',
      },
      {
        value: 'card',
        label: 'CREDIT_CARD',
      },
    ],
  },
];

const INITIAL_STATE = {
  page: 0,
  filtersList,
  filterValues: {},
  sortProperty: 'createdAt',
  sortDirection: 'desc',
  isListLoading: false,
  hasNextPage: false,
  list: [],
  listError: null,
  total: null,
  details: {}, // information, limits, users,
  detailsError: false,
  isDetailsLoading: false,
  filterId: null,
  sortId: null,
};

export default function (state = INITIAL_STATE, action) {
  const { type, payload } = action;
  const filterJsonStr = JSON.stringify(state.filterValues);
  const filterId = Buffer.from(filterJsonStr).toString('base64');

  const sortJsonStr = JSON.stringify({ sortProperty: state.sortProperty, sortDirection: state.sortDirection });
  const sortId = Buffer.from(sortJsonStr).toString('base64');
  let index;

  switch (type) {
    case RESET_CUSTOMER:
      return {
        ...state,
        details: {},
        detailsError: false,
        isDetailsLoading: false,
      };
    case GENERATE_FILTERS:
      return {
        ...state,
      };
    case CHANGE_SORT:
      return {
        ...state,
        sortProperty: payload.property,
        sortDirection: payload.direction,
      };
    case CHANGE_FILTER:
      return {
        ...state,
        filterValues: { ...payload },
      };
    case LOAD_CUSTOMERS_LIST:
      return {
        ...state,
        isListLoading: true,
        page: action.page,
      };
    case FETCH_CUSTOMERS:
      return {
        ...state,
        list: action.page === 0 ? [...payload.list] : [...state.list, ...payload.list],
        page: action.page,
        total: payload.count,
        hasNextPage: payload.hasNextPage,
        isListLoading: false,
        listError: null,
        filterId,
        sortId,
      };
    case ERROR_CUSTOMERS:
      return {
        ...state,
        isListLoading: false,
        listError: payload,
      };
    case DELETE_CUSTOMER:
      index = state.list.findIndex((l) => l._id === payload);
      return {
        ...state,
        total: state.total - 1,
        list: [...state.list.slice(0, index), ...state.list.slice(index + 1)],
      };
    case ADD_CUSTOMER:
      if (payload.data.success) {
        return {
          ...state,
          list: [{ ...payload.data.customer }, ...state.list],
        };
      }
      return state;
    case UPDATE_CUSTOMER:
      if (payload.data.success) {
        index = state.list.findIndex((l) => l._id === payload.data.customer._id);

        const detailsId = +_.get(state.details, 'information._id', {});
        const payloadId = +payload.data.customer._id;
        return {
          ...state,
          list: [...state.list.slice(0, index), { ...state.list[index], ...payload.data.customer }, ...state.list.slice(index + 1)],
          details: detailsId === payloadId ? {
            ...state.details,
            information: {
              ...state.details.information,
              ...payload.data.customer,
            },
          } : state.details,
        };
      }
      return state;

    case MANUALLY_VERIFY_CUSTOMER_EMAIL:
      return {
        ...state,
        details: {
          ...state.details,
          information: {
            ...state.details.information,
            emailConfirmed: true,
          },
        },
      };
    case LOAD_CUSTOMER_DETAILS:
      return {
        ...state,
        isDetailsLoading: true,
        detailsError: false,
        details: null,
      };
    case FETCH_CUSTOMER:
      return {
        ...state,
        isDetailsLoading: false,
        detailsError: false,
        details: payload,
      };
    case ERROR_CUSTOMER:
      return {
        ...state,
        isDetailsLoading: false,
        detailsError: true,
        details: {
          message: payload.message,
        },
      };
    case DELETE_CUSTOMER_USER:
      index = state.details.users.findIndex((u) => u._id === payload);
      return {
        ...state,
        details: {
          ...state.details,
          users: index !== -1 ? [...state.details.users.slice(0, index), ...state.details.users.slice(index + 1)] : [...state.details.users],
        },
      };
    case UPDATE_CUSTOMER_USER:
      if (payload.status === 200) {
        index = state.details.users.findIndex((u) => u._id === payload.data._id);
        return {
          ...state,
          details: {
            ...state.details,
            users: [
              ...state.details.users.slice(0, index),
              {
                ...state.details.users[index],
                ...payload.data,
              },
              ...state.details.users.slice(index + 1)],
          },
        };
      }
      return state;
    case RESET_CUSTOMER_USER_PASSWORD:
      return state;
    case CREATE_CUSTOMER_USER:
      if (payload.status === 200) {
        return {
          ...state,
          details: {
            ...state.details,
            users: [
              { ...payload.data.user },
              ...state.details.users,
            ],
          },
        };
      }
      return state;
    default:
      return state;
  }
}
