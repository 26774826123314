import React from 'react';
import dateFormat from 'date-fns/format';

import PropTypes from 'prop-types';

import Team from './team';

import './match-card.scss';

import {
  getTeamImage, getTeamName, getLeagueName, getRelativeDay,
} from '../../../helpers';
import Icon from '../../Icon';

export default function UpcomingCard({
  report, t, lng, teams, leagues, onClick,
}) {
  const {
    date, league_tm, team_id_away, team_id_home,
  } = report;

  // 1007090, 182, 157 - Borussia, 155 - Arminia
  const homeTeam = teams[team_id_home];
  const awayTeam = teams[team_id_away];
  const league = leagues[league_tm];
  const homeImg = getTeamImage(team_id_home);
  const homeName = getTeamName(homeTeam, lng, t);
  const awayImg = getTeamImage(team_id_away);
  const awayName = getTeamName(awayTeam, lng, t);
  const leagueName = getLeagueName(league, lng, t);

  const dateFormatted = getRelativeDay(new Date(date), lng, t);
  const timeFormatted = dateFormat(new Date(date), 'kk:mm');

  const content = (
    <>
      <Team name={homeName} img={homeImg} />
      <div className="match-card__divider">VS</div>
      <Team name={awayName} img={awayImg} />
    </>
  );

  return (
    <div
      className="match-card"
      onClick={() => onClick({
        date, team_id_away, team_id_home, league_id: league_tm,
      })}
    >
      <div className="match-card__attrs">
        <div className="match-card__add">
          <Icon name="plus" />
        </div>
        <div className="match-card__meta">
          <div className="match-card__date">
            <strong>{dateFormatted}</strong>
            <br />
            {timeFormatted}
          </div>
          <div className="match-card__league">
            {leagueName}
          </div>
        </div>
      </div>
      <div className="match-card__info">
        {content}
      </div>
    </div>
  );
}

UpcomingCard.propTypes = {
  onEdit: PropTypes.func,
};
