import { FETCH_LEAGUES, ADD_LEAGUE } from '../actions/index';

const INITIAL_STATE = {};

export default function (state = INITIAL_STATE, action) {
  let league = null;
  switch (action.type) {
    case FETCH_LEAGUES:
      return action.payload.leagues;
    case ADD_LEAGUE:
      league = _.get(action, 'payload.data[0].league', null);
      console.log({ league, state });
      return league ? {
        ...state,
        [league.id]: league,
      } : { ...state };
    default:
      return state;
  }
}
