import _ from 'lodash';

import {
  FETCH_NOTES,
  UPDATE_NOTE,
  DELETE_NOTE,
  CREATE_NOTE,
  UPDATE_NOTE_FILTER,
  UPDATE_NOTE_DICTIONARIES,
  SELECT_NOTE,
  FETCH_NOTE_TAGS,
  CREATE_NOTE_TAG,
  SET_NOTE_MODE,
  CLEAR_NOTES,
} from '../actions/notes';

import DemoNotes from '../../gnabry_notes.json';
import DemoNotesTags from '../../gnabry_note_tags.json';

import { AUTH_LOGOUT } from '../actions/auth';

export const emptyNote = `
To add a note, click the plus button.

To remove a note, click the Trash button at the top right.

You can search all your notes by typing in the search field at the top of the note list.

You can add tags to a note at the bottom of the note editor, and then use the sidebar to help you stay organized.

This note will hide after you’ve created your first note
`;

export const emptyNoteDe = `
Um eine Notiz hinzuzufügen, klicke auf das Plus.

Um eine Notiz zu löschen, klicke auf den Papierkorb oben rechts.

Durch Eingabe in das Suchfeld oberhalb des Notizfeldes, kannst du alle deine Notizen durchsuchen.

Um dir die Organisation zu erleichtern, kannst du unterhalb des Notiz-Editors eigene Tags hinzufügen und anschließend als Indikator in der Filterleiste nutzen.

Diese Notiz verschwindet, sobald du deine erste eigene Notiz erstellt hast.
`;

export const emptyNotePt = `
Para adicionar uma anotação, clique no botão de mais.

Para deletar uma anotação, clique na lata de lixo no canto superior direito.

Você pode procurar todas as suas anotações digitando no campo de pesquisa no topo da lista de anotações.

Você pode adicionar tags a uma anotação no canto inferior do editor e então usar a barra lateral para manter-se organizado.

Esta anotação irá sumir após você criar a sua primeira anotação.
`;

export const initState = {
  dictionaries: {
    favoritePlayersByKey: {},
    favoritePlayersByTeam: {},
    teamsByKey: {},
    tagsByKey: {},
    playersOptions: [],
    tagsOptions: [],
    notesByKey: {},
    notesByPlayer: {},
    notesByTag: {},
    notesByUser: {},
    notesByEvents: {},
    matchesByKey: {},
    events: ['goal', 'red', 'yellow', 'yellow_red', 'assist', 'own_goal'],
  },
  filter: {
    player_search: '',
    player_id: null,
    user_id: null,
    tag_id: null,
    match_id: null,
    event_id: null,
    search: '',
  },
  tags: [],
  notes: [],
  filteredNotes: [],
  matches: [],
  selectedNote: null,
  moreNotes: [],
  mode: null, // edit / create / null (view)
  newNote: null,
  emptyNote: {
    id: null,
    title: 'Welcome to Scoutpad Notes',
    title_de: 'Willkommen bei den Scoutpad Notizen',
    title_pt: 'Bem-Vindo as anotações do Scoutpad"',
    value: emptyNote,
    value_de: emptyNoteDe,
    value_pt: emptyNotePt,
    user: { firstName: 'Scoutpad', lastName: 'app' },
    player_id: null,
    tags: [],
    createdAt: '2018-01-01T12:00:58.000Z',
    updatedAt: '2018-01-01T12:00:58.000Z',
  },
};

export default function (state = initState, action) {
  switch (action.type) {
    case CLEAR_NOTES:
      return { ...initState };

    case FETCH_NOTES:
      const notes = _.get(action, 'payload.data', []);

      if (action.playerId === 'demo') {
        return {
          ...state,
          notes: window.genderContext === 'female' ? [] : DemoNotes,
        };
      }

      return {
        ...state,
        notes,
      };
    case CREATE_NOTE:
      const newNote = {
        ...action.payload.data,
      };

      return {
        ...state,
        notes: [newNote, ...state.notes],
        selectedNote: newNote,
        mode: 'create',
      };
    case UPDATE_NOTE:
      const updIndex = state.notes.findIndex((n) => n._id === action.payload._id);
      return { ...state, selectedNote: { ...action.payload }, notes: [...state.notes.slice(0, updIndex), { ...action.payload }, ...state.notes.slice(updIndex + 1)] };
    case DELETE_NOTE:
      const id = action.payload;
      const index = state.notes.findIndex((n) => n._id === id);
      return {
        ...state, notes: [...state.notes.slice(0, index), ...state.notes.slice(index + 1)], mode: null, selectedNote: null,
      };
    case SELECT_NOTE:
      const note = _.cloneDeep(action.payload);
      return {
        ...state,
        moreNotes: note ? [...state.notes.filter((n) => n.player_id == note.player_id && n._id !== note._id)] : [],
        mode: null,
        selectedNote: note,
      };

    case UPDATE_NOTE_FILTER:
      const newFilter = { ...state.filter, ...action.payload };
      return {
        ...state,
        filteredNotes: filterNotes(state.notes, newFilter, state),
        filter: newFilter,
      };
    case UPDATE_NOTE_DICTIONARIES:
      return {
        ...state,
        dictionaries: {
          ...state.dictionaries,
          ...action.payload,
        },
      };
    case FETCH_NOTE_TAGS:

      if (action.playerId === 'demo') {
        return {
          ...state,
          tags: window.genderContext === 'female' ? [] : DemoNotesTags,
        };
      }

      return {
        ...state,
        tags: action.payload.data,
      };
    case CREATE_NOTE_TAG:
      const newTag = action.payload.data;
      return {
        ...state,
        tags: [...state.tags, newTag],
      };
    case SET_NOTE_MODE:
      return {
        ...state,
        mode: action.payload,
      };
    case AUTH_LOGOUT:
      return initState;
    default:
      return state;
  }
}

export function filterNotes(notes, filter) {
  let filteredNotes = [...notes];

  if (filter.event_id) {
    filteredNotes = filteredNotes.filter((n) => {
      const f = n.event_type.find((e) => n._id && e === filter.event_id);
      return !!f;
    });
  }

  if (filter.tag_id) {
    filteredNotes = filteredNotes.filter((n) => {
      const f = n.tags.find((t) => n._id && t === filter.tag_id);
      return !!f;
    });
  }

  if (filter.user_id) {
    filteredNotes = filteredNotes.filter((n) => filter.user_id === `${n.user.firstName} ${n.user.lastName}`);
  }

  if (filter.match_id) {
    filteredNotes = filteredNotes.filter((n) => filter.match_id === n.match_id);
  }

  if (filter.search) {
    const regexp = new RegExp(filter.search, 'ig');
    filteredNotes = filteredNotes.filter((n) => {
      return regexp.test(n.title) || regexp.test(n.value);
    });
  }

  return [...filteredNotes];
}
