import './add-player-action.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withTranslation } from 'react-i18next';
import Icon from '../../../../Icon';

import { addPlayerToggle } from '../../../../../actions/match';

@withTranslation()
@connect(() => ({}), { addPlayerToggle })
export default class AddPlayerAction extends Component {
  static propTypes = {
    addPlayerToggle: PropTypes.func,
  };

  constructor() {
    super();
  }

  render() {
    const { t } = this.props;

    return (
      <div className="add-player-action">
        <a className="add-btn" onClick={() => { this.props.addPlayerToggle(true); }}>
          <Icon name="add" />
          {t('ADD_NEW_PLAYER')}
        </a>
      </div>
    );
  }
}
