import React from 'react';
import { components } from 'react-select';
import LeagueView from '../league-view';

export default function (props) {
  const { innerProps, innerRef, data } = props;

  return (
    <components.SingleValue {...props}>
      <LeagueView {...{ ...data, noImage: props.selectProps.noImage }} />
    </components.SingleValue>
  );
}
