import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Icon from '../../../Icon';

import './substitutions-controls.scss';

function validateNum(number, maxValue) {
  return +number <= maxValue && !Number.isNaN(+number);
}

export default function SubstitutionControls({
  onClose, type, currentTimeline, onSave, values
}) {
  const { t } = useTranslation();

  const [mins, setMins] = useState(0);
  const [secs, setSecs] = useState(0);

  useEffect(() => {
    const initMin = parseInt(`${currentTimeline}`.slice(1, 4), 10);
    const initSec = parseInt(`${currentTimeline}`.slice(4, 6), 10);
    setMins(initMin);
    setSecs(initSec);
  }, []);

  const period = mins < 45 ? 1 : mins < 90 ? 2 : mins < 105 ? 3 : 4;
  const minParsed = `000${mins}`.substr(-3);
  const secParsed = `00${secs}`.substr(-2);
  const composedTimeline = parseInt(`${period}${minParsed}${secParsed}`, 10);

  const value = values[composedTimeline];

  return (
    <div className="substitution-control-window">
      <div className="substitution-control-window__pad">
        <h3 className="substitution-control-window__title">
          <Icon name={type.replace('_', '')} />
          {t('PLAYER_IS_SUBSTITUTED', { context: window.genderContext })}
        </h3>
        <span className="substitution-control-window__close" onClick={onClose}><Icon name="close" /></span>
        <div className="substitution-control-window__timeline">
          <label>{t('MIN')}</label>
          <input
            className="mins"
            type="text"
            placeholder="00"
            value={mins}
            onChange={(e) => {
              if (validateNum(e.target.value, 120)) {
                setMins(e.target.value);
              }
            }}
          />
          <span>:</span>
          <input
            value={secs}
            type="text"
            placeholder="00"
            onChange={(e) => {
              if (validateNum(e.target.value, 59)) {
                setSecs(e.target.value);
              }
            }}
          />
          <label>{t('SEC')}</label>
        </div>
        <div className="substitution-control-window__buttons">
          <button
            type="button"
            className="btn"
            onClick={onClose}
          >
            {t('CANCEL')}
          </button>
          <button
            type="button"
            className="btn btn-submit"
            onClick={() => {
              const period = mins < 45 ? 1 : mins < 90 ? 2 : mins < 105 ? 3 : 4;
              const minParsed = `000${mins}`.substr(-3);
              const secParsed = `00${secs}`.substr(-2);
              const composedTimeline = parseInt(`${period}${minParsed}${secParsed}`, 10);
              onSave(composedTimeline, value);
              onClose();
            }}
          >
            {t('SUBSTITUTED')}
          </button>
        </div>
      </div>
    </div>
  );
}

SubstitutionControls.propTypes = {
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  type: PropTypes.string,
  selectedTimeline: PropTypes.number,
  currentTimeline: PropTypes.number,
  values: PropTypes.object,
};
