import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { reducer as form } from 'redux-form';

import matches from './matches';
import reports from './reports';
import teams from './teams';
import match from './match';
import positions from './positions';
import stadiums from './stadiums';
import leagues from './leagues';
import auth from './auth.js';
import account from '../actions/account';
import indicators from './indicators';
import pdf from './pdf';
import loading from './loading';
import subscription from './subscription';
import groups from './groups';
import group from './group';
import plans from './plans';
import favorites from './favorites';
import formations from './formations';
import invoices from './invoices';
import upcomingMatches from './upcoming_matches';
import countries from './countries';
import shadowTeams from './shadow-teams';
import shadowTabs from './shadow-tabs';
import coupons from './coupons';
import notes from './notes';
import entries from './entries';
import customers from './customers';
import watchlists from './watchlists';
import invites from './invites';
import icons from './icons';
import colors from './colors';
import home from './home';
import recentlyRated from './recently-rated';

const rootReducer = combineReducers({
  routing: routerReducer,
  reports,
  teams,
  match,
  form,
  positions,
  stadiums,
  auth,
  leagues,
  indicators,
  pdf,
  loading,
  groups,
  group,
  plans,
  favorites,
  formations,
  invoices,
  upcomingMatches,
  countries,
  subscription,
  shadowTeams,
  shadowTabs,
  coupons,
  notes,
  entries,
  customers,
  watchlists,
  invites,
  icons,
  colors,
  home,
  recentlyRated,
  ...account.reducers,
});

export default rootReducer;
