import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { browserHistory } from 'react-router';
import { withTranslation } from 'react-i18next';
import Icon from '../../Icon';

import { getPlans } from '../../../actions/plans';
import {
  changeGroupPlan,
  getGroup,
  getGroupInvoices,
  cancelSubscription,
  bookAdditionalPersonLicence,
  fetchCoupons,
} from '../../../actions/group';

import CurrentPlan from './components/current_plan';
import PlanSelector from './components/plans_selector';
import PaymentMethod from './components/payment_method';
import BillingInformation from './components/billing_information';
import Coupons from './components/coupons';
import CancelPlan from './components/cancel';

import { ROOT_URL, changeLoading } from '../../../actions';
import { fetchSubscription } from '../../../actions/subscription';

import './billing.scss';

@withTranslation()
@connect((state) => ({
  user: state.auth.user,
  plans: state.plans,
  group: state.group,
  invoices: state.invoices,
  subscription: state.subscription,
  countries: state.countries,
  coupons: state.coupons,
}), {
  getPlans,
  getGroup,
  changeGroupPlan,
  getGroupInvoices,
  cancelSubscription,
  bookAdditionalPersonLicence,
  changeLoading,
  fetchSubscription, // todo: update fetch limits
  fetchCoupons,
})
class Billing extends Component {
  static propTypes = {
    plans: PropTypes.array,
  };

  constructor(props) {
    super(props);

    this.state = {
      interval: 'month',
      isChange: false,
    };

    this.onCurrentPlanChange = this.onCurrentPlanChange.bind(this);
  }

  componentDidMount() {
    this.props.changeLoading({ component: 'billing', loading: true });
    Promise.all([
      this.props.getGroup(),
      this.props.getPlans(),
      this.props.fetchSubscription(),
      this.props.fetchCoupons(),
      !this.props.invoices && this.props.getGroupInvoices(),
    ]).then(() => {
      this.props.changeLoading({ component: 'billing', loading: false });
    });
  }

  componentDidUpdate(prevProps) {
    const oldInterval = _.get(prevProps, 'subscription.details.plan.interval', 'month');
    const interval = _.get(this.props, 'subscription.details.plan.interval', 'month');
    if (interval !== oldInterval) {
      this.setState({
        interval,
      });
    }
  }

  changeInterval(interval) {
    this.setState({ ...this.state, interval });
  }

  onCurrentPlanChange(planState) {
    this.setState({
      isChange: planState,
    });
  }

  renderInvoices() {
    const { invoices } = this.props;

    return (
      <ul className="invoices-list">
        {
          invoices.map((invoice, i) => {
            const isNew = invoice.status !== 'paid';
            const currencySign = invoice.currency === 'eur' ? String.fromCharCode(8364) : '$';

            let summ = invoice.total === 0 ? '0.00' : (invoice.total / 100).toFixed(2);
            summ += currencySign;

            return (
              <li key={invoice.number} className="invoice-item">
                {isNew ? <span className="invoice-new">new</span> : null}
                <a
                  target="_blank"
                  href={`${ROOT_URL}/group/invoice/${invoice.status === 'draft' ? 'upcoming' : invoice.id}`}
                  rel="noreferrer"
                >
                  {moment(invoice.date * 1000).format('DD.MM.YYYY')}
                  ,
                  {' '}
                  {summ}
                </a>
              </li>
            );
          })
        }
      </ul>
    );
  }

  render() {
    const {
      plans, group, t, changeLoading, subscription, countries,
    } = this.props;
    const { interval, isChange } = this.state;

    const isPlanCanceled = _.get(subscription, 'details.subscription.cancel_at_period_end', null);

    const isOnPaidSubscription = !!_.get(subscription, 'details.currentSource', false);

    if (!group || !plans || !plans.length) return null;

    return (
      <div className="billing">

        <h1 className="settings-h1 overlay-h1">
          {subscription && !isChange ? t('SUBSCRIPTION_AND_BILLING_TITLE') : (
            <div>
              <a onClick={() => {
                this.setState({ isChange: false });
              }}
              >
                &lsaquo;
                {t('BACK')}
              </a>
              {t('SELECT_YOUR_PLAN')}
            </div>
          )}
        </h1>

        {group.plan && subscription && !isChange ? (
          <CurrentPlan subscription={subscription} group={group} onChangeClick={this.onCurrentPlanChange} isTrial={subscription.isTrial} />
        )
          : (
            <div>
              <div className="radio-group">
                <span
                  className={`pseudo-link ${interval === 'month' && 'active'}`}
                  onClick={this.changeInterval.bind(this, 'month')}
                >
                  {t('MONTHLY')}
                </span>
                <span
                  className={`pseudo-link ${interval === 'year' && 'active'}`}
                  onClick={this.changeInterval.bind(this, 'year')}
                >
                  {t('YEARLY')}
                </span>
              </div>
              <PlanSelector
                onCloseClick={() => {
                  this.setState({ isChange: false });
                }}
                subscription={subscription}
                plans={plans}
                countries={countries}
                interval={interval}
                changeGroupPlan={this.props.changeGroupPlan}
                currentPlan={group.plan && subscription.details}
                changeLoading={changeLoading}
                source={_.get(subscription, 'details.currentSource', null) || _.get(subscription, 'details.source', null)}
                group={group}
                onChangePlan={(plan) => {
                  document.location.reload();
                }}
              />

              <div className="billing-columns">
                <div className="billing-col">
                  <h2 className="settings-h2">{t('COUPON_CODES')}</h2>
                  <Coupons currentPlan={subscription.details} group={group} />
                </div>
              </div>
            </div>
          )}

        {subscription && !isChange ? (
          <div>
            <div className="billing-columns">
              <div className="billing-col">
                <h2 className="settings-h2">{t('PAYMENT_METHOD')}</h2>
                <PaymentMethod currentPlan={subscription.details} />
              </div>
              <div className="billing-col">
                <h2 className="settings-h2">{t('BILLING_INFORMATION')}</h2>
                <BillingInformation group={group} countries={countries} />
              </div>
              <div className="billing-col">
                <h2 className="settings-h2">{t('COUPON_CODES')}</h2>
                <Coupons currentPlan={subscription.details} group={group} />
              </div>
            </div>
            <div className="billing-col">
              <h2 className="settings-h2">{t('PAYMENT_HISTORY')}</h2>
              <p dangerouslySetInnerHTML={{ __html: t('PAYMENT_HISTORY_TEXT') }} />
              {this.renderInvoices()}
            </div>
            <div className="billing-col">
              <h2 className="settings-h2">{t('ABOUT_BILLING')}</h2>
              <h4>{t('ABOUT_BILLING1')}</h4>
              <p>
                {t('ABOUT_BILLING2')}
                <br />
                <Icon name="visa" />
                <Icon name="amex" />
                <Icon name="mastercard" />
                <Icon name="sepa" className="sepa" />
                <Icon name="ApplePay" className="applepay" />
                <Icon name="GooglePay" className="googlepay" />
              </p>
              <h4>{t('ABOUT_BILLING3')}</h4>
              <p>{t('ABOUT_BILLING4')}</p>
              <h4>{t('ABOUT_BILLING5')}</h4>
              <p>{t('ABOUT_BILLING6')}</p>

              {!isPlanCanceled
                ? (
                  <CancelPlan currentPlan={subscription.details} />
                )
                : null}
            </div>
          </div>
        )
          : (
            <div>
              <div className="billing-col">
                <h2 className="settings-h2">{t('ABOUT_BILLING')}</h2>
                <h4>{t('ABOUT_BILLING1')}</h4>
                <p>
                  {t('ABOUT_BILLING2')}
                  <br />
                  <Icon name="visa" />
                  <Icon name="amex" />
                  <Icon name="mastercard" />
                  <Icon name="sepa" className="sepa" />
                  <Icon name="ApplePay" className="applepay" />
                  <Icon name="GooglePay" className="googlepay" />
                </p>
                <h4>{t('ABOUT_BILLING3')}</h4>
                <p>{t('ABOUT_BILLING4')}</p>
                <h4>{t('ABOUT_BILLING7')}</h4>
                <p>{t('ABOUT_BILLING8')}</p>
              </div>
            </div>
          )}

      </div>

    );
  }
}

export default Billing;

/*

*/
