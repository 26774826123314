import { FETCH_ICONS } from '../actions/index';

const INITIAL_STATE = {
  list: [],
  message: null,
};

export default function (state = INITIAL_STATE, action) {
  let data;
  switch (action.type) {
    case FETCH_ICONS:
      data = action.payload.data;
      if (data.success) {
        return {
          ...state,
          list: data.icons,
          message: null,
        };
      }
      return {
        ...state,
        message: data.message,
      };
    default:
      return state;
  }
}
