import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import Select from 'react-select';

import './control-player-info.scss';

import MaskedInput from 'react-maskedinput';
import PlayerImage from '../../../player-image';
import Icon from '../../../Icon';
import AddToFavorites from '../../../ui/add-to-favorites';

import { updatePlayerInMatch, deletePlayerFromMatch } from '../../../../actions/favorites';
import { editSelectTheme, styleEditSelect } from '../../../ui/select_props';
import { generateFootOptions } from '../../../../constants';
import { getPlayerImage } from '../../../../helpers';
import confirm from '../../../confirm';

function SmallSelect({ value, options, onChange }) {
  return (
    <span className="select-container">
      <Select
        value={options.find((o) => o.value == value)}
        options={options}
        onChange={onChange}
        styles={styleEditSelect}
        theme={editSelectTheme}
      />
    </span>
  );
}

@withTranslation()
@connect((state) => ({
  match: state.match,
  positions: state.positions,
}), { updatePlayerInMatch, deletePlayerFromMatch })

export default class Player extends Component {
  static propTypes = {
    match: PropTypes.object,
    positions: PropTypes.object,
  };

  constructor() {
    super();

    this.state = {
      edit: {
        first_name: false,
        last_name: false,
        jersey: false,
        position_id: false,
        foot: false,
        birth_date: false,
      },
      error: {
        first_name: false,
        last_name: false,
        jersey: false,
        position_id: false,
        foot: false,
        birth_date: false,
      },
      value: {
        first_name: '',
        last_name: '',
        jersey: '',
        position_id: null,
        foot: null,
        birth_date: null,
      },
    };

    this.changePlayerInfo = this.changePlayerInfo.bind(this);
    this.savePlayerInfo = this.savePlayerInfo.bind(this);
    this.savePlayerSelect = this.savePlayerSelect.bind(this);
    this.editActivate = this.editActivate.bind(this);
    this.deletePlayer = this.deletePlayer.bind(this);
  }

  componentWillUnmount() {
    this.state = {
      edit: {
        first_name: false,
        last_name: false,
        jersey: false,
        birth_date: false,
      },
      error: {
        first_name: false,
        last_name: false,
        jersey: false,
        birth_date: false,
      },
    };
  }

  editActivate(value, event, field) {
    event.preventDefault();

    this.setState({
      edit: {
        ...this.state.edit,
        [field]: true,
      },
      value: {
        ...this.state.value,
        [field]: value,
      },
    });
  }

  validateDate(value) {
    let error = false;
    const date = moment(value, 'DD/MM/YYYY');
    if (!date.isValid() || moment().diff(date, 'years') < 5 || moment().diff(date, 'years') > 60) {
      error = true;
    }

    return [value, error];
  }

  savePlayerInfo(event, field) {
    const { match } = this.props;
    const { data, controls } = match;
    const information = _.get(data, `[${controls.side}].players[${controls.player}].information`, {});

    this.setState({
      edit: {
        ...this.state.edit,
        [field]: false,
      },
      error: {
        ...this.state.error,
        [field]: !(event.target.value && event.target.value.length && event.target.value.length > 0),
      },
    });

    let { value } = event.target;
    let error = false;
    if (field === 'birth_date') {
      [value, error] = this.validateDate(value);
      value = moment(value, 'DD/MM/YYYY')
        .format('YYYY-MM-DD');
    }

    if (value && value.length && value.length > 0 && !error) {
      this.props.updatePlayerInMatch({ ...information, [field]: value }, match.data.match_id);
    }
  }

  savePlayerSelect(value, field) {
    const { match } = this.props;
    const { data, controls } = match;
    const information = _.get(data, `[${controls.side}].players[${controls.player}].information`, {});

    this.setState({
      edit: {
        ...this.state.edit,
        [field]: null,
      },
      error: {
        ...this.state.error,
      },
    });

    this.props.updatePlayerInMatch({ ...information, [field]: value }, match.data.match_id);
  }

  changePlayerInfo(event, field) {
    let { value } = event.target;
    let error = false;
    if (field === 'birth_date') {
      [value, error] = this.validateDate(value);
    }

    this.setState({
      value: {
        ...this.state.value,
        [field]: value,
      },
      error: {
        ...this.state.error,
        [field]: !(value && value.length && (field === 'birth_date' || value > 0) && !error),
      },
    });
  }

  deletePlayer(player, match) {
    const { t } = this.props;
    const { player_id } = player;
    const { match_id } = match;

    const text = () => (
      <div className="privacy-confirm">
        <h2 className="h2">{t('DELETE_ENTRY_CONFIRM_TITLE')}</h2>
        <p dangerouslySetInnerHTML={{ __html: t('DELETE_ENTRY_CONFIRM_TEXT') }} />
      </div>
    );

    confirm(text, {
      context: this,
      cancelText: t('CANCEL'),
      confirmText: t('CONTINUE'),
    }).then(() => {
      this.props.deletePlayerFromMatch(player_id, match_id);
    }).catch((err) => {});
  }

  render() {
    const {
      match, positions, t,
    } = this.props;



    const { data, controls } = match;
    const { edit, value, error } = this.state;

    if (!_.size(data) || !_.size(positions)) return null;

    const information = _.get(data, `[${controls.side}].players[${controls.player}].information`, {});
    const birth_date_formated = information.birth_date ? moment(information.birth_date, 'YYYY-MM-DD').format('DD.MM.YYYY') : t('N/D');

    const {
      key, first_name, last_name, jersey, position_id, country, foot, height, weight, contract_until,
    } = information;

    const player = _.get(data, `[${controls.side}].players[${controls.player}]`, {});
    const isUserCreated = +information.key !== +controls.player;

    let age = 0;
    if (information.birth_date) {
      age = moment().diff(moment(information.birth_date, 'YYYY-MM-DD'), 'years');
    }

    const position_options = _.chain(positions)
      .filter((p) => p.upper_position)
      .orderBy(['upper_rank'], ['asc'])
      .map((p) => ({ value: p.id, label: _.get(p, 'desc_short', 'N/D') }))
      .value();

    const playerImage = getPlayerImage(information);

    return (
      <div className="control-player-info">
        <PlayerImage className="player__image" src={playerImage} />
        <div className="player__name">
          <div>
            { edit.first_name ? <input type="text" className={error.first_name ? 'invalid' : ''} autoFocus value={value.first_name} onChange={(e) => this.changePlayerInfo(e, 'first_name')} onBlur={(e) => this.savePlayerInfo(e, 'first_name')} /> : first_name }
            { edit.first_name ? null
              : (
                <a className="info-edit-action" onClick={(event) => this.editActivate(first_name, event, 'first_name')}>
                  <Icon name="pencil" />
                </a>
              )}
          </div>
          <div>
            { edit.last_name ? <input type="text" className={error.last_name ? 'invalid' : ''} autoFocus value={value.last_name} onChange={(e) => this.changePlayerInfo(e, 'last_name')} onBlur={(e) => this.savePlayerInfo(e, 'last_name')} /> : last_name }
            { edit.last_name ? null
              : (
                <a className="info-edit-action" onClick={(event) => this.editActivate(last_name, event, 'last_name')}>
                  <Icon name="pencil" />
                </a>
              )}
          </div>

        </div>
        <div>
          <b>
            {t('NUMBER')}
            :
          </b>
          {' '}
          { edit.jersey ? <input type="number" className={error.jersey ? 'invalid' : ''} autoFocus maxLength="2" value={value.jersey} onChange={(e) => this.changePlayerInfo(e, 'jersey')} onBlur={(e) => this.savePlayerInfo(e, 'jersey')} /> : jersey }
          { edit.jersey ? null
            : (
              <a className="info-edit-action" onClick={(event) => this.editActivate(jersey, event, 'jersey')}>
                <Icon name="pencil" />
              </a>
            )}
        </div>
        { _.size(positions)
          ? (
            <div>
              <b>{t('POSITION')}</b>
              <span />

              { edit.position_id ? (
                <SmallSelect value={position_id} options={position_options} onChange={(val) => this.savePlayerSelect(val.value, 'position_id')} />
              ) : (+position_id ? t(`${positions[+position_id].desc_short}`) : 'N/D') }

              { edit.position_id ? null : (
                <a className="info-edit-action" onClick={(event) => this.editActivate(jersey, event, 'position_id')}>
                  <Icon name="pencil" />
                </a>
              )}
            </div>
          )
          : null}
        <div>
          <b>{t('FOOT')}</b>
          <span />
          { edit.foot ? (
            <SmallSelect value={foot} options={generateFootOptions(t)} onChange={(val) => this.savePlayerSelect(val.value, 'foot')} />
          ) : (foot ? t(foot) : t('N/D')) }
          {edit.foot ? null : (
            <a className="info-edit-action" onClick={(event) => this.editActivate(foot, event, 'foot')}>
              <Icon name="pencil" />
            </a>
          )}
        </div>
        <div>
          <b>{t('AGE')}</b>
          <span />
          {
            edit.birth_date ? (

              <MaskedInput
                onBlur={(e) => {
                  this.savePlayerInfo(e, 'birth_date');
                }}
                onChange={(e) => this.changePlayerInfo(e, 'birth_date')}
                value={value.birth_date}
                mask="11/11/1111"
                placeholderChar=" "
                placeholder="DD/MM/YYYY"
                autoFocus
                className={`date_input ${error.birth_date ? 'invalid' : ''}`}
              />
            ) : (
              <span>
                { age }
                {' '}
                <span className="small">
                  (
                  {birth_date_formated}
                  )
                </span>
              </span>
            )
          }
          {edit.birth_date ? null : (
            <a className="info-edit-action" onClick={(event) => this.editActivate(moment(information.birth_date, 'YYYY-MM-DD').format('DD/MM/YYYY'), event, 'birth_date')}>
              <Icon name="pencil" />
            </a>
          )}
        </div>
        { country ? (
          <div>
            <b>{t('COUNTRY')}</b>
            {' '}
            { country }
          </div>
        ) : null }
        { height ? (
          <div>
            <b>{t('HEIGHT')}</b>
            {' '}
            { height }
            cm
          </div>
        ) : null }
        { weight ? (
          <div>
            <b>{t('WEIGHT')}</b>
            {' '}
            { weight }
            kg
          </div>
        ) : null }
        { contract_until && contract_until !== '1900-01-01' ? (
          <div>
            <b>{t('CONTRACT_UNTIL')}</b>
            {' '}
            { moment(contract_until, 'YYYY-MM-DD').format('DD/MM/YYYY') }
          </div>
        ) : null }
        <AddToFavorites player={information} match_id={match.data.match_id}>
          <span className="player-favorite">
            <Icon name="favorite" />
            <span className="tri" />
          </span>
        </AddToFavorites>
        {
          isUserCreated
            ? <span className="player_delete" onClick={() => this.deletePlayer(player, data)}><Icon name="trashcan" /></span>
            : null
        }
      </div>
    );
  }
}
