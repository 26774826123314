import React from 'react';
import { components } from 'react-select';
import Icon from '../../../Icon';

import './dropdown-indicator.scss';

export default function DropdownIndicator(props) {
  return (
    <components.DropdownIndicator {...props}>
      <Icon name="icon-chevron-league-select" className="react-select__dropdown-indicator" />
    </components.DropdownIndicator>
  );
}
