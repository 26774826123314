import React from 'react';
import Lottie from 'lottie-react';
import { browserHistory } from 'react-router';
import confirm from '../../confirm';
import i18n from '../../../i18n';
import Icon from '../../Icon';
import { default as animationData } from './lf20_veiyrucg.json';

import './congratulations-confirm.scss';

const ids = [];

const types = {
  LOGIN: 'LOGIN',
  CREATED_FREE_ACCOUNT: 'CREATED_FREE_ACCOUNT',
  CREATED_PRO_ACCOUNT: 'CREATED_PRO_ACCOUNT',
  COMPLETED_REPORTS: 'COMPLETED_REPORTS',
  ADDED_NOTES: 'ADDED_NOTES',
  ADDED_PLAYERS_TO_WATCHLIST: 'ADDED_PLAYERS_TO_WATCHLIST',
  ADDED_PLAYERS_RATINGS: 'ADDED_PLAYERS_RATINGS',
  EARN_MESSAGE: 'EARN_MESSAGE',
  UPGRADE_PLAN: 'UPGRADE_PLAN',
};

function getContent(event) {
  let text = null;
  let icon = <Icon name="present" />;
  let buttonName = i18n.t('EARN_CREDITS');
  let confetti = false;
  let heading = (
    <h3 className="congratulations__heading">
      <Icon name="present" />
      {' '}
      { event.type === types.EARN_MESSAGE ? i18n.t('EARN_CREDITS') : i18n.t('CREDITS_EARNED')}
    </h3>
  );
  let link = '/settings/earn';

  switch (event.type) {
    case types.UPGRADE_PLAN:
      heading = null;
      icon = <Icon name="upgrade" className="upgrade-icon-big" />;
      text = (
        <div className="text">
          <h4 dangerouslySetInnerHTML={{ __html: i18n.t('UPGRADE_POPUP_HEADER', {}) }} />
          <div dangerouslySetInnerHTML={{ __html: i18n.t('UPGRADE_POPUP_TEXT') }} />
        </div>
      );
      buttonName = i18n.t('UPGRADE_TO_PRO');
      link = '/settings/billing';
      break;
    case types.LOGIN:
      icon = <Icon name="logins" />;
      text = (
        <div className="text">
          <h4 dangerouslySetInnerHTML={{ __html: i18n.t('CONGRATULATIONS_LOGIN_HEADER', { number: event.number, count: event.credits }) }} />
          <div dangerouslySetInnerHTML={{ __html: i18n.t('CONGRATULATIONS_LOGIN_TEXT') }} />
        </div>
      );
      buttonName = i18n.t('CONGRATULATIONS_LOGIN_BUTTON');
      confetti = true;
      break;
    case types.CREATED_FREE_ACCOUNT:
      text = (
        <div className="text">
          <h4 dangerouslySetInnerHTML={{ __html: i18n.t('CONGRATULATIONS_FREE_ACCOUNT_HEADER', { name: event.name, count: event.credits }) }} />
          <div dangerouslySetInnerHTML={{ __html: i18n.t('CONGRATULATIONS_FREE_ACCOUNT_TEXT') }} />
        </div>
      );
      buttonName = i18n.t('USE_CREDITS');
      confetti = true;

      break;
    case types.CREATED_PRO_ACCOUNT:
      text = (
        <div className="text">
          <h4 dangerouslySetInnerHTML={{ __html: i18n.t('CONGRATULATIONS_PRO_ACCOUNT_HEADER', { name: event.name, count: event.credits }) }} />
          <div dangerouslySetInnerHTML={{ __html: i18n.t('CONGRATULATIONS_PRO_ACCOUNT_TEXT') }} />
        </div>
      );
      buttonName = i18n.t('USE_CREDITS');
      confetti = true;
      break;
    case types.COMPLETED_REPORTS:
      icon = <Icon name="match-icon" />;
      text = (
        <div className="text">
          <h4 dangerouslySetInnerHTML={{ __html: i18n.t('CONGRATULATIONS_MATCH_REPORTS_HEADER', { count: event.number }) }} />
          <div dangerouslySetInnerHTML={{ __html: i18n.t('CONGRATULATIONS_REPORT_TEXT') }} />
        </div>
      );
      buttonName = i18n.t('USE_CREDITS');
      confetti = true;
      break;
    case types.ADDED_NOTES:
      icon = <Icon name="match-icon" />;
      text = (
        <div className="text">
          <h4 dangerouslySetInnerHTML={{ __html: i18n.t('CONGRATULATIONS_MATCH_NOTES_HEADER', { count: event.number }) }} />
          <div dangerouslySetInnerHTML={{ __html: i18n.t('CONGRATULATIONS_REPORT_TEXT') }} />
        </div>
      );
      buttonName = i18n.t('USE_CREDITS');
      confetti = true;
      break;
    case types.ADDED_PLAYERS_TO_WATCHLIST:
      icon = <Icon name="match-icon" />;
      text = (
        <div className="text">
          <h4 dangerouslySetInnerHTML={{ __html: i18n.t('CONGRATULATIONS_MATCH_WATCHLIST_HEADER', { count: event.number }) }} />
          <div dangerouslySetInnerHTML={{ __html: i18n.t('CONGRATULATIONS_REPORT_TEXT') }} />
        </div>
      );
      buttonName = i18n.t('USE_CREDITS');
      confetti = true;
      break;
    case types.ADDED_PLAYERS_RATINGS:
      icon = <Icon name="match-icon" />;
      text = (
        <div className="text">
          <h4 dangerouslySetInnerHTML={{ __html: i18n.t('CONGRATULATIONS_MATCH_RATINGS_HEADER', { count: event.number }) }} />
          <div dangerouslySetInnerHTML={{ __html: i18n.t('CONGRATULATIONS_REPORT_TEXT') }} />
        </div>
      );
      buttonName = i18n.t('USE_CREDITS');
      confetti = true;
      break;
    case types.EARN_MESSAGE:
      text = (
        <div className="text">
          <h4 dangerouslySetInnerHTML={{ __html: i18n.t('INVITE_HEADER', { count: event.number }) }} />
          <div dangerouslySetInnerHTML={{ __html: i18n.t('INVITE_TEXT') }} />
        </div>
      );
      icon = <Icon name="present" />;
      buttonName = i18n.t('EARN_CREDITS');
      confetti = false;
      break;
  }

  const body = (
    <div className="congratulations__body">
      {heading}
      <div className="congratulations__row">
        <div className="icon">
          {icon}
          {confetti ? (
            <div className="confetti">
              <Lottie
                loop
                autoplay
                animationData={animationData}
                style={{
                  height: 600,
                  width: 600,
                }}
                preserveAspectRatio="xMidYMid slice"
              />
            </div>
          ) : null}
        </div>
        <div className="text">
          {text}
        </div>
      </div>
    </div>
  );

  return [body, buttonName, link];
}

function showConfirm(i, events, seenNotification) {
  const [text, buttonText, link] = getContent(events[i]);
  if (ids.findIndex((id) => id === events[i]._id) === -1) {
    ids.push(events[i]._id);
    return confirm(text, {
      className: 'congratulations-confirm',
      context: this,
      hasClose: true,
      hideCancel: true,
      confirmText: buttonText,
      onConfirm: () => { seenNotification(events[i]._id); },
    }).then(() => {
      browserHistory.push(link);
      setTimeout(() => { continueShow(i, events, seenNotification); }, 100);
    }, () => {
      setTimeout(() => { continueShow(i, events, seenNotification); }, 100);
    });
  }
  setTimeout(() => { continueShow(i, events, seenNotification); }, 100);
}

function continueShow(i, events, seenNotification) {
  i++;
  if (events[i]) {
    return showConfirm(i, events, seenNotification);
  }
  return null;
}

export default function congratulationsConfirm(events = [], seenNotification = () => {}) {
  const i = 0;
  showConfirm(i, events, seenNotification);
}
