import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import Icon from '../../../Icon';
import { updateWatchlist } from '../../../../actions/watchlists';
import { addWatchlist, removeWatchlist } from '../../../../actions/favorites';

import './watchlists.scss';
import WatchlistPane from '../../../ui/watchlist-pane';

@connect((state) => {
  return {
    users: state.users,
    user: state.auth.user,
  };
}, { addWatchlist, removeWatchlist, updateWatchlist })
@withTranslation()
class Watchlists extends Component {
  constructor(props) {
    super(props);

    this.openAddPane = this.openAddPane.bind(this);
    this.closeAddPane = this.closeAddPane.bind(this);
    this.removePlayerFromWatchlist = this.removePlayerFromWatchlist.bind(this);
    this.addPlayerToWatchlist = this.addPlayerToWatchlist.bind(this);

    this.state = {
      showAdd: false,
    };
  }

  addPlayerToWatchlist(watchlist) {
    const { info } = this.props;
    const { id } = info;

    const players = [...watchlist.players];
    if (players.findIndex((p) => p.player_id == id) === -1) {
      players.push(id);
      this.props.updateWatchlist({
        ...watchlist,
        players,
        details: {
          ...watchlist.details,
          [id]: info.player,
        },
      });
      this.props.addWatchlist(watchlist);
      this.closeAddPane();
    }
  }

  removePlayerFromWatchlist(e, watchlist) {
    const { t, info, user } = this.props;
    const { id } = info;
    e.preventDefault();
    e.stopPropagation();

    let confirmText = t('WATCHLIST_CONFIRM_REMOVE', { context: window.genderContext });

    if (watchlist.user_id != user._id) {
      confirmText = t('WATCHLIST_CONFIRM_GROUP_REMOVE', { context: window.genderContext });
    }

    if (window.confirm(confirmText)) {
      const index = watchlist.players.findIndex((p) => p == id);
      if (index !== -1) {
        const newPlayers = [...watchlist.players.slice(0, index), ...watchlist.players.slice(index + 1)];
        this.props.updateWatchlist({
          ...watchlist,
          players: newPlayers,
        });
        this.props.removeWatchlist(watchlist._id);
      }
    }
  }

  openAddPane() {
    this.setState({ showAdd: true });
  }

  closeAddPane() {
    this.setState({ showAdd: false });
  }

  renderWatchlist(watchlist) {
    const { users, user } = this.props;

    let firstName = '';
    let lastName = '';

    if (users.data) {
      if (+watchlist.user_id !== +user._id) {
        const usr = users.data.find((u) => u._id === watchlist.user_id) || {};
        firstName = usr.firstName;
        lastName = usr.lastName;
      } else {
        firstName = user.firstName;
        lastName = user.lastName;
      }
    }

    const initials = firstName[0] + lastName[0];
    const avatar = watchlist.user_id === user._id ? 'ME' : initials.toUpperCase();

    return (
      <Link to={`/watchlists/${watchlist._id}`} className="watchlist__item">
        <span className="watchlist__avatar">{ avatar }</span>
        <span className="watchlist__name">{watchlist.name}</span>
        <span className="watchlist__remove" onClick={(e) => this.removePlayerFromWatchlist(e, watchlist)}><Icon name="close" /></span>
      </Link>
    );
  }

  renderWatchlists() {
    const { watchlists } = this.props.info;
    return (
      <div className="watchlists__list">
        <ul>
          {watchlists.map((w) => {
            return (
              <li key={w._id}>
                { this.renderWatchlist(w) }
              </li>
            );
          })}
        </ul>
        <div className="watchlists__button">
          {this.renderAdd(true)}
        </div>
      </div>
    );
  }

  renderAdd(secondary) {
    const { t } = this.props;
    return (
      <div className="watchlists__button">
        <button className="btn" onClick={this.openAddPane}>{secondary ? t('ADD_TO_ANOTHER_WATCHLIST') : t('ADD_TO_WATCHLISTS')}</button>
      </div>
    );
  }

  render() {
    const { t, info } = this.props;
    const { watchlists } = info;

    return (
      <div className="watchlists">
        <p className="watchlists__heading">{t('WATCHLISTS')}</p>
        {
          watchlists.length ? this.renderWatchlists() : this.renderAdd()
        }

        { this.state.showAdd ? (
          <div>
            <div className="add-watchlist-fade" />
            <WatchlistPane onClickOutside={() => { this.closeAddPane(); }} onWatchlistClick={this.addPlayerToWatchlist} reduceSet={watchlists} />
          </div>
        ) : null }
      </div>
    );
  }
}

Watchlists.propTypes = {
  info: PropTypes.object,
  t: PropTypes.func,
  removeWatchlist: PropTypes.func,
  addWatchlist: PropTypes.func,
  updateWatchlist: PropTypes.func,
};

export default Watchlists;
