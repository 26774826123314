import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './reports.scss';
import { withTranslation } from 'react-i18next';

import { fetchAddMatch } from '../../actions';
import { updateReportsLimits } from '../../actions/subscription';
import DemoMessage from '../ui/demo-message';
import MatchCard from '../ui/match-card';

@withTranslation()
@connect((state) => {
  return {
    teams: state.teams,
    leagues: state.leagues,
    subscription: state.subscription,
    user: state.auth.user,
  };
}, { fetchAddMatch, updateReportsLimits })
class Reports extends Component {
  constructor(props) {
    super(props);

    this.renderItem = this.renderItem.bind(this);

    this.state = {
      isAdd: false,
    };
  }

  renderItem(match, index) {
    const {teams, leagues, t, i18n, players, user} = this.props;
    const lng = i18n.language;
    return (
      <li
        className="homepage__reports-item"
        key={match.match_id}
      >
        <MatchCard
          report={match}
          index={index}
          teams={teams}
          leagues={leagues}
          t={t}
          lng={lng}
          authUser={user}
          players={players}
          onEdit={null}
        />
      </li>
    );
  }

  renderDemoMatch(report) {
    return (
      <div className="homepage__demo-match">
        {this.renderItem(report)}
      </div>
    );
  }

  render() {
    const { reports, t, subscription, demoMatch, demoSingle } = this.props;

    let isDemo = false;
    let showMessage = false;

    let showReports = [];

    if (!reports.length) {
      isDemo = true;
      showMessage = true;
      showReports = [ demoSingle, demoMatch ];
    } else {
      showReports = [...reports];
    }

    if (isDemo || reports.length === 1) {
      showMessage = true;
    }

    if (!subscription.plan) {
      showMessage = false;
    }

    return (
      <div className="homepage__reports">
        { !isDemo ? showReports.map(this.renderItem) : this.renderDemoMatch(showReports[0])}
        { isDemo ? this.renderDemoMatch(showReports[1]) : null}
        { showMessage ? <DemoMessage text={t('HOME_DEMO_REPORTS')} arrowPosition="left" /> : null }
      </div>
    );
  }
}

Reports.propTypes = {
  reports: PropTypes.array,
  teams: PropTypes.array,
  leagues: PropTypes.array,
  t: PropTypes.func,
  i18n: PropTypes.object,
  subscription: PropTypes.object,
  updateReportsLimits: PropTypes.func,
  demoMatch: PropTypes.object,
  demoSingle: PropTypes.object,
};

export default Reports;
