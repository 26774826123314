import axios from 'axios';

import { ROOT_URL } from '.';

export const FETCH_GROUPS = 'FETCH_GROUPS';
export const ADD_GROUP = 'ADD_GROUP';
export const UPDATE_GROUP = 'UPDATE_GROUP';
export const DELETE_GROUP = 'DELETE_GROUP';

export function getGroups() {
  const request = axios.get(`${ROOT_URL}/groups`);

  return {
    type: FETCH_GROUPS,
    payload: request,
  };
}

export function addGroup(props) {
  const {
    name, coupon, clubEmail, currency,
  } = props;

  const request = axios.post(`${ROOT_URL}/groups`, {
    name, coupon, clubEmail, currency,
  });

  return {
    type: ADD_GROUP,
    payload: request,
  };
}

export function updateGroup(props) {
  const {
    id, name, coupon, clubEmail, currency,
  } = props;
  const request = axios.put(`${ROOT_URL}/groups/${id}`, {
    name, coupon, clubEmail, currency,
  });

  return {
    type: UPDATE_GROUP,
    payload: request,
  };
}

export function deleteGroup(props) {
  const { id } = props;
  const request = axios.delete(`${ROOT_URL}/groups/${id}`);

  return {
    type: DELETE_GROUP,
    payload: request,
  };
}
