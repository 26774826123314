import './players.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import _ from 'lodash';

import { withTranslation } from 'react-i18next';
import Player from './player';
import AddPlayer from './add-player';
import Icon from '../../../Icon';
import { getWindowWidth } from '../../../../helpers';
import { saveDefaultLineup } from '../../../../actions/match';

@withTranslation()
class Players extends Component {
  static propTypes = {
    match: PropTypes.object,
    positions: PropTypes.object,
    height: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    saveDefaultLineup: PropTypes.func,
    user: PropTypes.object,
    t: PropTypes.func,
  };

  constructor() {
    super();

    this.state = {
      playersGroupsToggle: {},
    };

    this.renderPlayers = this.renderPlayers.bind(this);
    this.togglePositionGroup = this.togglePositionGroup.bind(this);
    this.saveLineup = this.saveLineup.bind(this);
    this.isDefaultLineup = this.isDefaultLineup.bind(this);
  }

  togglePositionGroup(position_id) {
    const { playersGroupsToggle } = this.state;
    playersGroupsToggle[position_id] = !playersGroupsToggle[position_id];
    this.setState({ playersGroupsToggle });
  }

  saveLineup() {
    const {
      match, saveDefaultLineup,
    } = this.props;
    const { subTab } = match.sidebar;

    const team = _.get(match, `data[${subTab}].team_id`, null);
    const lineup = _.get(match, `current_positions[${subTab}].lineup`, {});
    const formation = _.get(match, `data[${subTab}].formations.lineup.id`, null);
    const lineupObject = _.chain(lineup).map((player) => {
      return { player_id: player.player_id, position_id_detail: player.position_id_detail, substitution: player.substitution };
    }).filter((p) => p.position_id_detail || p.substitution).value();

    saveDefaultLineup(lineupObject, team, formation, subTab);
  }

  renderPlayers() {
    const {
      positions, match, t
    } = this.props;
    const { data } = match;
    const { subTab } = match.sidebar;
    const { playersGroupsToggle } = this.state;
    let i = 0;

    if (!_.size(data)) return null;

    const playersGroupsByPosition = _.groupBy(data[subTab].players, (player) => +player.information.position_id ? player.information.position_id : null);
    const isMobile = getWindowWidth() < 960;

    return _
      .map(playersGroupsByPosition, (group, position_id) => (
        <div className="players-group" key={`${position_id}_${++i}`}>
          <a className={'group-action' + `${(!isMobile && playersGroupsToggle[position_id]) || (isMobile && !playersGroupsToggle[position_id]) ? ' down' : ' up'}`} onClick={() => this.togglePositionGroup(position_id)}>
            <Icon name={`arrow-${(!isMobile && playersGroupsToggle[position_id]) || (isMobile && !playersGroupsToggle[position_id]) ? 'down' : 'up'}`} />
            { t(_.get(positions, `[${position_id}].translation_key`, 'UNKNOWN_POSITION'), { context: window.genderContext }) }
          </a>
          <div className="group-list" style={{ maxHeight: (!isMobile && playersGroupsToggle[position_id]) || (isMobile && !playersGroupsToggle[position_id]) ? 0 : _.size(group) * 44 }}>
            { _.chain(group).orderBy((player) => player.information.jersey).map((player) => <Player key={player.player_id} {...{ player, side: subTab }} />).value() }
          </div>
        </div>
      ));
  }

  isDefaultLineup() {
    const { match } = this.props;
    const { subTab } = match.sidebar;

    const lineup = _.get(match, `current_positions[${subTab}].lineup`, {});
    const defaultLineup = _.get(match, `data[${subTab}].defaultLineup`, {});
    const formation = _.get(match, `data[${subTab}].formations.lineup.id`, null);
    const lineupObject = {
      formation_id: formation,
      lineup: _.chain(lineup).map((player) => {
        return { player_id: player.player_id, position_id_detail: player.position_id_detail, substitution: player.substitution };
      }).filter((p) => p.position_id_detail || p.substitution).value(),
    };

    if (+defaultLineup.formation_id !== +lineupObject.formation_id) {
      return false;
    }

    if (lineupObject.lineup.length !== defaultLineup.lineup.length) {
      return false;
    }

    let result = true;

    lineupObject.lineup.map((p) => {
      const { player_id, position_id_detail, substitution } = p;
      const defaultPos = defaultLineup.lineup.find((p) => player_id === p.player_id);
      if (!defaultPos) { result = false; return; }
      if (defaultPos.position_id_detail !== position_id_detail) { result = false; return; }
      if (defaultPos.substitution !== substitution) { result = false; }
    });

    return result;
  }

  render() {
    const {
      height, t,
    } = this.props;

    const isDefaultLineup = this.isDefaultLineup();

    return (
      <div className="players">
        <Scrollbars autoHide style={{ height }}>
          { this.renderPlayers() }
          <AddPlayer />
        </Scrollbars>
      </div>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    match: state.match,
    positions: state.positions,
    teams: state.teams,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, { saveDefaultLineup })(Players);
