import axios from 'axios';
import _get from 'lodash/get';
import { ROOT_URL } from '../../../actions';
import { getTeamImage } from '../../../helpers';

export const fetchData = (exclude, filter, teams, lng) => {
  return (inputValue) => {
    return axios.get(`${ROOT_URL}/players/search`, {
      params: {
        needle: inputValue,
        exclude: exclude || [],
        teams: filter,
      },
    })
      .then((result) => {
        const { players } = result.data;
        const options = players.map((player) => {
          let playerName;

          if (player.first_name && player.last_name) {
            playerName = `${player.last_name}, ${player.first_name}`;
          }

          if (!player.first_name && player.last_name) {
            playerName = `${player.last_name}`;
          }

          if (!player.last_name) {
            playerName = `${player.short_name}`;
          }

          const team = _get(teams, `[${player.team_id}].name_${lng}`, _get(teams, `[${player.team_id}].name`, null));
          const teamLogo = team && getTeamImage(player.team_id);
          return {
            value: player.player_id,
            label: playerName,
            team,
            teamLogo,
            data: player,
          };
        });
        return options;
      })
      .catch((err) => {
        console.error(err.message);
        return [];
      });
  };
};
