import { FETCH_STADIUMS, ADD_STADIUM } from '../actions/index';

const INITIAL_STATE = {};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_STADIUMS:
      return action.payload.stadiums;
    case ADD_STADIUM:
      if (action.payload.success) {
        const newState = { ...state };
        newState[action.payload.stadium.id] = action.payload.stadium;
        return newState;
      }
      console.error(action.payload.message);
      return state;

    default:
      return state;
  }
}
