import './pdf-lineups.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Textarea from 'react-textarea-autosize';
import _ from 'lodash';

import Header from '../header';
import Pitch from '../pitch';

import { changeSnapshot } from '../../../actions/pdf';

@connect((state) => ({
  pdf: state.pdf,
}), { changeSnapshot })

export default class pdfPitch extends Component {
  static propTypes = {
    pdf: PropTypes.object,
    changeSnapshot: PropTypes.func,
  };

  render() {
    const { pdf } = this.props;

    const snapshots = _.cloneDeep(pdf.match.home.positions) || {};
    delete snapshots.lineup;

    if (!_.size(snapshots)) return null;

    return (
      <div className="pdf-page">
        <Header />

        <div className="pdf-lineups">
          <div className="row">
            {
              _.map(snapshots, (snapshot, name) => (
                <div className="col-6 snapshot-pitch" key={name}>
                  <Textarea className="title" onChange={(e) => { this.props.changeSnapshot({ key: name, value: e.target.value }); }} value={pdf.snapshots[name]} />
                  <Pitch snapshot={name} width={402} icons="xs-small" />
                </div>
              ))
            }
          </div>
        </div>
      </div>
    );
  }
}
