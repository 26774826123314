import './side-controls.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';

import { changeInnerControl, hideControls } from '../../../actions/match';
import { getWindowHeight } from '../../../helpers';

import Icon from '../../Icon';
import Player from './player';
import Note from './note';
import ControlsList from './controls-list';

@connect((state) => ({
  match: state.match,
}), { changeInnerControl, hideControls })

export default class Controls extends Component {
  static propTypes = {
    match: PropTypes.object,
    changeInnerControl: PropTypes.func,
    hideControls: PropTypes.func,
  };

  constructor() {
    super();

    this.state = {
      height: getWindowHeight(),
    };

    this.handleResize = this.handleResize.bind(this);
    this.stopPropagation = this.stopPropagation.bind(this);
  }

  componentDidMount() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  handleResize() {
    this.setState({
      height: getWindowHeight(),
    });
  }

  render() {
    const { side, player, inner } = this.props.match.controls;
    const { height } = this.state;

    if (!side || !player || !inner || inner === 'favorite') return <div className="side-controls" />;

    return (
      <div className="side-controls" onClick={this.stopPropagation}>
        <Scrollbars autoHide style={{ height }}>
          { this.props.match.type !== 'single' ? <Player /> : null}
          <a className="side-controls__close" onClick={() => { this.props.hideControls(); }}><Icon name="close" /></a>
          { inner === 'notes' ? <Note /> : <ControlsList /> }
        </Scrollbars>
      </div>
    );
  }
}
