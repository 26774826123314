import React, { Component } from 'react';
import AddCard from './add_card';
import './add_card.scss';
import { connect } from 'react-redux';
import { changeLoading } from '../../../../../actions';

import { StripeProvider, Elements } from 'react-stripe-elements';

@connect((state, own) => {
  return {
    subscription: state.subscription,
    group: state.group,
  };
}, { changeLoading })
export default class AddCardScreen extends Component {
  render() {
    const { group } = this.props;
    const keys = ['pk_test_2jaG8o8VTv7seJy49kujtECy', 'pk_live_X2pKWYFJtyHmZwKc2b9QR1kx'];
    const source = _.get(this.props, 'subscription.details.currentSource', null);
    let key = keys[0];
    if (window.location.hostname === 'app.scoutpad.de') {
      key = keys[1];
    }

    return (
      <StripeProvider apiKey={key}>
        <div className="billing-add-card">
          <Elements>
            <AddCard current={source} group={group} {...this.props} query={this.props.query} />
          </Elements>
        </div>
      </StripeProvider>
    );
  }
}
