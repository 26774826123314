import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

import Icon from '../Icon';

import './sub_nav.scss';

@connect((state, own) => ({
  list: own.list,
  current: own.current,
}), {})
class Sections extends Component {
  static propTypes = {
    sections: PropTypes.array,
  };

  renderNavItem(section, current) {
    if (!section.render) return null;

    const base = this.props.name;

    const isActive = section.link === current;
    let icon = null;
    if (section.subpages) {
      icon = <Icon name="chevron_right" className={`section-icon ${isActive ? 'section-icon__active' : ''}`} />;
    }

    return (
      isActive
        ? (
          <li key={section.link}>
            <Link className="active" key={section.link} to={`/${base}/${section.link}`}>
              {icon}
              {section.name}
            </Link>
            { section.subpages && section.subpages.length ? this.render2ndLevel(section.subpages) : null }
          </li>
        )
        : (
          <li key={section.link}>
            <Link key={section.link} to={`/${base}/${section.link}`}>
              {icon}
              {section.name}
            </Link>
          </li>
        )
    );
  }

  renderNav() {
    const { list, title, children } = this.props;
    let { current } = this.props;

    if (!current) {
      current = list[0].link;
    }

    return (
      <div className="sub-nav__navigation">
        { title ? <h2>{title}</h2> : null }
        <ul>{ list.map((section) => this.renderNavItem(section, current)) }</ul>
        { children }
      </div>
    );
  }

  render2ndLevel(subpages) {
    const { subCurrent, current } = this.props;

    return (
      <div className="sub-nav__second-level">
        <ul>
          { subpages.map((section) => this.renderNavItem(section, `${current}/${subCurrent}`)) }
        </ul>
      </div>
    );
  }

  render() {
    const {
      list, current, subCurrent, subsection, containerClassName,
    } = this.props;

    let section = list.find((s) => s.link === current);
    const subsectionComponent = subsection ? section.subsection.find((s) => s.link === subsection) : null;

    if (!section) {
      section = list.find((l) => l.render);
    }

    if (subCurrent) {
      section = section.subpages.find((s) => s.link === `${current}/${subCurrent}`);
    }

    return (
      <div className="sub-nav">
        { this.renderNav() }
        <div className="sub-nav__content">
          <div className={`container ${containerClassName}`}>
            { !subsectionComponent ? section.component : subsectionComponent.component }
          </div>
        </div>
      </div>
    );
  }
}

export default Sections;
