import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DropTarget } from 'react-dnd';
import { connect } from 'react-redux';

import { changeFormationPosition } from '../../../../../actions/match';

@connect((state) => ({
  match: state.match,
}), { changeFormationPosition })

@DropTarget('player', {
  drop(props, monitor) {
    const {
      left, top, side, position,
    } = props;
    const { side: playerSide, information } = monitor.getItem();

    if (side === playerSide) {
      props.changeFormationPosition({
        side, left, top, position, information,
      });
    }
  },
}, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver({ shallow: true }),
  isOverCurrent: monitor.isOver({ shallow: true }),
  canDrop: monitor.canDrop(),
  itemType: monitor.getItemType(),
}))

export default class Position extends Component {
  static propTypes = {
    onClick: PropTypes.func,
    connectDropTarget: PropTypes.func,
    side: PropTypes.string,
    left: PropTypes.number,
    top: PropTypes.number,
    position: PropTypes.object,
    match: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.clickHandler = this.clickHandler.bind(this);
  }

  clickHandler(position) {
    const { onClick, side } = this.props;
    onClick({ position, side });
  }

  render() {
    const {
      connectDropTarget, left, top, side, position, match: { positionSelected },
    } = this.props;
    const active = positionSelected.position.position_id_detail === position.position_id_detail && side === positionSelected.side;

    return connectDropTarget(<div onClick={(event) => this.clickHandler(position, event)} className={`formation-target ${side} ${active ? 'active' : ''}`} style={{ left: `${left * 100}%`, top: `${top * 100}%` }} />);
  }
}
