import './substitution-player.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DragSource, DropTarget } from 'react-dnd';

import Controls from '../../../controls';
import Icon from '../../../../../Icon';

import { deactivatePlayer, changePlayerControl, swapPlayers } from '../../../../../../actions/match';
import PitchEventsIcons from '../../../../../ui/pitch-events-icons';
import { formatQuarterAndBirthYear } from '../../../../../../helpers';

@connect((state) => ({
  match: state.match,
}), {
  deactivatePlayer, changePlayerControl, swapPlayers,
})

@DragSource('player', {
  beginDrag(props) {
    const { player, side, match } = props;
    const { information } = match.data[side].players[player.player_id];

    return { side, information };
  },

  canDrag(props, monitor) {
    return props.match.snapshot === 'lineup';
  },
  endDrag(props, monitor) {
    const { side, information } = monitor.getItem();

    !monitor.didDrop() && props.deactivatePlayer({ side, information });
  },
}, (connect, monitor) => ({
  connectDragPreview: connect.dragPreview(),
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging(),
  canDrag: true,
}))

@DropTarget('player', {
  canDrop(props, monitor) {
    const item = monitor.getItem();
    return item.side == props.side && props.match.snapshot === 'lineup';
  },
  drop(props, monitor) {
    const item = monitor.getItem();

    setTimeout(() => {
      props.swapPlayers({
        from: {
          player_id: item.information.key,
          side: item.side,
        },
        to: {
          player_id: props.player.player_id,
          side: props.side,
        },
      });
    }, 0);
  },
}, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  isOverCurrent: monitor.isOver({ shallow: true }),
  canDrop: monitor.canDrop(),
  itemType: monitor.getItemType(),
  item: monitor.getItem(),
}))

export default class Player extends Component {
  static propTypes = {
    item: PropTypes.object,
    itemType: PropTypes.string,
    currentOffset: PropTypes.shape({
      x: PropTypes.number.isRequired,
      y: PropTypes.number.isRequired,
    }),
    isDragging: PropTypes.bool.isRequired,
    side: PropTypes.string,
    changePlayerControl: PropTypes.func,
    match: PropTypes.object,
    player: PropTypes.object,
    connectDragSource: PropTypes.func,
    connectDropTarget: PropTypes.func,
    isOver: PropTypes.bool,
    canDrop: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.playerClick = this.playerClick.bind(this);
  }

  playerClick(player_id, side, event) {
    const { match } = this.props;

    if (player_id !== match.controls.player || side !== match.controls.side) {
      event.stopPropagation();
      this.props.changePlayerControl({ player_id, side });
    }
  }

  render() {
    const {
      player, side, match, connectDragSource, isDragging, connectDropTarget, isOver, item,
    } = this.props;
    const { information } = match.data[side].players[player.player_id];
    const itemSubstitution = item && isOver ? match.current_positions[item.side][match.snapshot][item.information.key].substitution : null;
    const substituted = match.data[side].positions[match.snapshot][player.player_id].swap;
    const formattedAge = formatQuarterAndBirthYear(information);

    if (isDragging) {
      return (
        <div className="substitution" />
      );
    }

    return (
      <div onClick={(e) => this.playerClick(information.key, side, e)} className={`substitution-player ${information.key == match.controls.player ? 'active' : ''} ${substituted ? 'substituted' : ''}`}>
        <PitchEventsIcons
          match={match.data}
          player_id={player.player_id}
          side={side}
          type="small"
        />
        <div className="player__name">{ information.last_name }</div>
        { isOver && match.snapshot === 'lineup' && (side === item.side || (!player.substitution && !itemSubstitution)) ? <Icon className="swap" name="swap" /> : null }
        { match.controls.player == information.key && match.controls.side == side ? <Controls /> : null }
        { connectDropTarget(connectDragSource(<div className="player__number">
          <div className="player__number-text">{ information.jersey }</div>
          <div className="player__birthdate">{formattedAge}</div>
        </div>)) }
      </div>
    );
  }
}
