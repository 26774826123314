import React from 'react';
import formatDate from 'date-fns/format';
import './match-view.scss';

export default function ({
  type, homeImg, awayImg, playerImg, playerName, homeName, awayName, date
}) {
  const homeImgComp = (
    <div
      className="match-view__team-img"
      style={{ backgroundImage: `url(${homeImg})` }}
    />
  );

  const awayImgComp = (
    <div
      className="match-view__team-img"
      style={{ backgroundImage: `url(${awayImg})` }}
    />
  );

  return (
    <div className="match-view">
      { type === 'single' ? (
        <div className="match-view__single">
          <div className="match-view__row">
            <div
              className="match-view__player-img"
              style={{ backgroundImage: `url(${playerImg})` }}
            />
            <span className="match-view__name">{ playerName}</span>
          </div>
          <div className="match-view__row">
            <span className="match-view__teams">
              {homeImgComp}
              {awayImgComp}
            </span>
            <span className="match-view__date">
              {formatDate(new Date(date), 'dd.MM.yyyy')}
            </span>
          </div>
        </div>
      ) : (
        <div className="match-view__match">
          <div className="match-view__row">
            <span className="match-view__name home">{ homeName}</span>
            <span className="match-view__teams">
              {homeImgComp}
              {awayImgComp}
            </span>
            <span className="match-view__name away">{awayName}</span>
          </div>
          <div className="match-view__row">
            <span className="match-view__date">
              {formatDate(new Date(date), 'dd.MM.yyyy')}
            </span>
          </div>
        </div>
      ) }
    </div>
  );
}
