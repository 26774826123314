import './pdf-header.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import Textarea from 'react-textarea-autosize';

import { changeGoal } from '../../../actions/pdf';
import { changeGoals as matchChangeGoal } from '../../../actions/match';

@connect((state) => ({
  pdf: state.pdf,
  matches: state.matches,
  teams: state.teams,
  stadiums: state.stadiums,
}), { changeGoal, matchChangeGoal })

export default class pdfHeader extends Component {
  static propTypes = {
    teams: PropTypes.object,
    pdf: PropTypes.object,
    stadiums: PropTypes.object,
    changeGoal: PropTypes.func,
  };

  render() {
    const {
      teams, pdf, stadiums, small,
    } = this.props;

    const mins = _.get(pdf, 'match.mins', '');
    const stadiumId = _.get(pdf, 'match.stadium_id', null);
    const homeTeamId = _.get(pdf, 'match.home.team_id', null);
    const awayTeamId = _.get(pdf, 'match.away.team_id', null);

    const stadiumName = _.get(stadiums, `[${stadiumId}].stadium`, 'Unknown stadium');
    const stadiumCity = _.get(stadiums, `[${stadiumId}].city`, null);
    const matchDate = _.get(pdf, 'match.date', new Date());
    const formattedMatchDate = moment(matchDate).format('DD.MM.YYYY HH:mm');

    const homeTeamName = _.get(teams, `[${homeTeamId}].name`, 'Unknown team');
    const awayTeamName = _.get(teams, `[${awayTeamId}].name`, 'Unknown team');

    const weather = _.get(pdf, 'match.weather', null);
    const weatherWidget = weather ? `${pdf.match.weather.temp}°С ${pdf.match.weather.description}` : null;

    return (
      <div className={`pdf-header ${small ? 'small' : ''}`}>
        <div className="match-info">
          <div className="match-info-teams">
            <div className="home-team">
              <span className="team-logo" style={{ backgroundImage: `url(https://static.sp7f.com/teams/${homeTeamId}.jpg)` }} />
              { homeTeamName }
            </div>
            <div className="match-result">
              <div className="min">
                { mins }
                &#x27;
              </div>
              <div className="score">
                { this.renderGoalTextarea('homegoals') }
                :
                { this.renderGoalTextarea('awaygoals') }
              </div>
              <div className="second-score">
                { this.renderGoalTextarea('homegoals_ht') }
                :
                { this.renderGoalTextarea('awaygoals_ht') }
              </div>
            </div>
            <div className="away-team">
              <span className="team-logo" style={{ backgroundImage: `url(https://static.sp7f.com/teams/${awayTeamId}.jpg)` }} />
              { awayTeamName }
            </div>
          </div>
          {!small ? (
            <div className="match-info-other">
              { stadiumName }
              { stadiumCity ? `, ${stadiumCity}` : '' }
              {' '}
              |
              {' '}
              { formattedMatchDate }
              { weatherWidget }
            </div>
          ) : null}
          {!small ? (
            <div className="match-info-other">
              by
              {' '}
              {pdf.match.user}
            </div>
          ) : null}
        </div>
      </div>
    );
  }

  renderGoalTextarea(key) {
    const { pdf } = this.props;
    const goals = _.get(pdf, `[${key}]`, null);

    const goalsFormatted = goals || goals === 0 || goals === '0' ? goals.toString() : '';

    return (
      <Textarea
        onChange={(e) => {
          this.props.changeGoal({ key, value: e.target.value });
          this.props.matchChangeGoal({ key, value: e.target.value });
        }}
        value={goalsFormatted}
        placeholder="0"
        maxLength="1"
      />
    );
  }
}
