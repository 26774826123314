import './sub-tabs.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import { withTranslation } from 'react-i18next';
import { changeSubTab } from '../../../../actions/match';

@withTranslation()
class RootTabs extends Component {
  static propTypes = {
    changeSubTab: PropTypes.func,
    match: PropTypes.object,
    teams: PropTypes.object,
  };

  constructor() {
    super();

    this.renderTabs = this.renderTabs.bind(this);
  }

  renderTabs() {
    const {
      teams, changeSubTab, t, i18n,
    } = this.props;
    const lng = i18n.language;
    const { sidebar, data } = this.props.match;
    const { subTabs, subTab: active } = sidebar;

    if (!_.size(teams) || !_.size(data)) return null;

    return subTabs.map((tab) => (
      <a onClick={() => { changeSubTab(tab); }} className={`${tab === active ? 'active' : ''}`} key={tab}>
        <span className="team-image" style={{ backgroundImage: `url(https://static.sp7f.com/teams/${data[tab].team_id}.jpg)` }} />
        { _.get(teams, `[${data[tab].team_id}].name_${lng}`, _.get(teams, `[${data[tab].team_id}].name`, t('UNKNOWN'))) }
      </a>
    ));
  }

  render() {
    return (
      <div className="sub-tabs">
        { this.renderTabs() }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    match: state.match,
    teams: state.teams,
  };
}

export default connect(mapStateToProps, { changeSubTab })(RootTabs);
