import axios from 'axios';
import localforage from 'localforage';
import { MATCHES_COLLECTION } from './constants';
import { ROOT_URL } from '../actions';

export default function (props) {
  const userId = localStorage.getItem('userId');
  const gender = localStorage.getItem('selectedGender');
  const collection = `evaluations-${MATCHES_COLLECTION}-${gender}-${userId}`;

  switch (window.online) {
    case true:
      const updateOfflinePromise = Promise.all([
        localforage.getItem(collection),
      ]).then((args) => {
        let evaluations = args[0];
        evaluations = JSON.parse(evaluations);
        if (evaluations && evaluations.length) {
          return axios.post(`${ROOT_URL}/matches/indicators`, evaluations).then(() => {
            return localforage.setItem(collection, JSON.stringify([]));
          });
        }
      });

      if (props) {
        return axios.post(`${ROOT_URL}/matches/indicators`, [props]);
      }
      return updateOfflinePromise;

    case false:

      if (!props) {
        return null;
      }

      return localforage
        .getItem(collection)
        .then((evaluations) => {
          evaluations = JSON.parse(evaluations);
          const tmp = evaluations || [];
          tmp.push(props);
          return localforage.setItem(collection, JSON.stringify(tmp));
        });
  }
}
