import React from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../../Icon';

import './group-ad.scss';
import eventTracker from '../../../helpers/event-tracker';

export default function GroupAd() {
  const { t } = useTranslation();
  return (
    <div className="reports__group-ad">
      <span className="reports__group-ad__bg" />
      <span className="reports__group-ad__bg" />
      <span className="reports__group-ad__bg" />

      <div className="reports__group-ad__pane">
        <Icon name="group-upgrade" />
        <h3>{t('UPGRADE_TO_TEAM')}</h3>
        <p>{t('GROUP_AD_TEXT')}</p>
        <a
          className="btn btn-block"
          onClick={() => { eventTracker.trackEvent('Reports', 'Contact for custom license'); }}
          href="mailto:support@scoutpad.de"
        >
          {t('CONTACT_US')}
        </a>
      </div>
    </div>
  );
}
