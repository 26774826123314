import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { browserHistory } from 'react-router';

import './player-card.scss';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';

import PlayerImage from '../../../player-image';
import { getPlayerImage } from '../../../../helpers';

@withTranslation()
@connect((state, own) => {
  return {
    positions: state.positions,
    teams: state.teams,
  };
}, {})
export default class PlayerControls extends Component {
  render() {
    const {
      playerData, t, i18n, positions, teams, shadow_team_id,
    } = this.props;
    const lng = i18n.language;

    const data = playerData.information ? { ...playerData.information, ...playerData } : playerData;

    const birth_date_formated = moment(data.birth_date, 'YYYY-MM-DD').format('DD.MM.YYYY');
    const birth_year = moment(data.birth_date, 'YYYY-MM-DD').year();
    const {
      key, first_name, last_name, jersey, birth_date, country, foot, height, weight, contract_until, position_id, team_id,
    } = data;
    const playerImage = getPlayerImage(data);

    const team_name = _.get(teams, `[${team_id}].name_${lng}`, _.get(teams, `[${team_id}].name`, t('UNKNOWN_TEAM')));

    return (
      <div
        className={`shadow-team__player-information ${this.props.className}`}
        onClick={() => browserHistory.push(`/shadow-teams/${shadow_team_id}/${key}/summary/`)}
        style={{ top: this.props.top ? this.props.top : 'auto' }}
      >
        <PlayerImage className="player__image" src={playerImage} />
        <div className="player__name">
          { first_name !== last_name ? first_name : null }
          {' '}
          { last_name }
        </div>
        <div>
          <b>{t('TEAM')}</b>
          {' '}
          { team_name }
        </div>
        {
          jersey || jersey === 0
            ? (
              <div>
                <b>{t('NUMBER')}</b>
                {' '}
                {jersey}
              </div>
            )
            : null
        }
        { _.size(positions) && position_id ? (
          <div>
            <b>{t('POSITION')}</b>
            {' '}
            { t(`${positions[position_id].desc_short}`) }
          </div>
        ) : null }
        { birth_date && birth_year !== 1900 ? (
          <div>
            <b>{t('AGE')}</b>
            {' '}
            { moment().year() - birth_year }
            {' '}
            <span className="small">
              (
              {birth_date_formated}
              )
            </span>
          </div>
        ) : null }
        { country ? (
          <div>
            <b>{t('COUNTRY')}</b>
            {' '}
            { country }
          </div>
        ) : null }
        { foot ? (
          <div>
            <b>{t('FOOT')}</b>
            {' '}
            {t(`${foot}`) }
          </div>
        ) : null }
        { height ? (
          <div>
            <b>{t('HEIGHT')}</b>
            {' '}
            { height }
            cm
          </div>
        ) : null }
        { weight ? (
          <div>
            <b>{t('WEIGHT')}</b>
            {' '}
            { weight }
            kg
          </div>
        ) : null }
        { contract_until && contract_until !== '1900-01-01' && contract_until !== '1900-01-01 00:00:00' ? (
          <div>
            <b>{t('CONTRACT_UNTIL_SHORT')}</b>
            {' '}
            { moment(contract_until, 'YYYY-MM-DD').format('DD/MM/YYYY') }
          </div>
        ) : null }
      </div>
    );
  }
}
