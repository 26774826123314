import React from 'react';
import SliderOrigin, { Range as RangeOrigin } from 'rc-slider';
//import 'rc-slider/assets/index.css';
import './rc-slider.scss';

const Handle = ({ className, offset, value, tipFormatter }) => {
	return (
		<div className={className} style={{ left: `${offset}%` }}>
			<div className="handle-value">
				{ tipFormatter ? tipFormatter(value) : value }
			</div>
		</div>
	)
}

const Slider = (props) => <SliderOrigin {...props} handle={(handleProps) => <Handle {...handleProps} tipFormatter={props.tipFormatter} />} />;
const Range = (props) => <RangeOrigin {...props} handle={(handleProps) => <Handle {...handleProps} tipFormatter={props.tipFormatter} />} />;

export {
	Slider,
	Range,
}