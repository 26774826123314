import './change-password.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import { withTranslation } from 'react-i18next';
import { logout } from '../../../actions/auth';
import accountApi from '../../../actions/account';

@withTranslation()
@connect((state) => ({
  user: state.auth.user,
}), (dispatch) => bindActionCreators({
  logout,
  dispatch,
}, dispatch))
export default class General extends Component {
  static propTypes = {
    logout: PropTypes.func,
    dispatch: PropTypes.func,
    user: PropTypes.object,
  };

  constructor() {
    super();

    this.state = {
      changePassword: {
        oldPassword: '',
        newPassword: '',
        repeatPassword: '',
        error: false,
        success: false,
      },
    };

    this.changePassword = this.changePassword.bind(this);
  }

  onInputPassword(value, input) {
    const changePassword = _.cloneDeep(this.state.changePassword);
    changePassword[input] = value;

    this.setState({ changePassword });
  }

  changePassword(event) {
    event.preventDefault();

    const { t } = this.props;
    const { changePassword } = this.state;

    if (changePassword.newPassword.length < 6 || changePassword.repeatPassword < 6) {
      this.setState({ changePassword: { ...changePassword, error: t('PASSWORD_LENGTH') } });
    } else if (changePassword.newPassword !== changePassword.repeatPassword) {
      this.setState({ changePassword: { ...changePassword, error: t('PASSWORD_SAME') } });
    } else {
      if (!navigator.onLine) { alert(t('NO_CONNECTION')); return; }

      this.setState({ changePassword: { ...changePassword, error: false, success: false } });

      this.props.dispatch(accountApi.actions.user.put({
        id: this.props.user._id,
      }, {
        body: JSON.stringify({
          password: changePassword.newPassword,
          oldPassword: changePassword.oldPassword,
        }),
      }, (err) => {
        if (err) {
          console.log(err);
          this.setState({ changePassword: { ...changePassword, error: t('ERROR_OCURED') + err.message } });
        } else {
          this.setState({
            changePassword: {
              newPassword: '',
              repeatPassword: '',
              error: false,
              success: t('PASSWORD_UPDATED_EXPLAIN'),
            },
          });
        }
      }));
    }
  }

  render() {
    const { t } = this.props;
    return (
      <div className="change-password">
        <h2 className="settings-h1 overlay-h1">{t('PASSWORD')}</h2>
        <form className="password-form" onSubmit={this.changePassword}>
          <div className="row">
            <div className="col-3">
              <input className="inp" type="password" value={this.state.changePassword.oldPassword} onChange={(event) => this.onInputPassword(event.target.value, 'oldPassword')} placeholder={t('OLD_PASSWORD')} />
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <input className="inp" type="password" value={this.state.changePassword.newPassword} onChange={(event) => this.onInputPassword(event.target.value, 'newPassword')} placeholder={t('NEW_PASSWORD')} />
            </div>
            <div className="col-3">
              <input className="inp" type="password" value={this.state.changePassword.repeatPassword} onChange={(event) => this.onInputPassword(event.target.value, 'repeatPassword')} placeholder={t('CONFIRM_PASSWORD')} />
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <button type="submit" className="btn btn-block">{t('SAVE_NEW_PASSWORD')}</button>
            </div>
          </div>

          <div className={`error-message ${this.state.changePassword.error ? 'visible' : ''}`}>{ this.state.changePassword.error }</div>
          <div className={`success-message ${this.state.changePassword.success ? 'visible' : ''}`}>{ this.state.changePassword.success }</div>
        </form>
      </div>
    );
  }
}
