import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import Select from 'react-select';
import isTouchDevice from 'is-touch-device';

import { withTranslation } from 'react-i18next';
import { filterTheme, styleFilter } from '../ui/select_props';
import RecentList from './components/recent_list';
import PlayerSearch from './components/player-search';

import './favorites.scss';

import { removeFromFavorites } from '../../actions/favorites';
import { fetchRecentlyRated, changeRecentlyRatedFilter, resetFilter } from '../../actions/recently-rated';
import { changeLoading } from '../../actions/index';

import AddPlayer from './components/add-player';
import Icon from '../Icon';
import FiltersConstructor from '../ui/filters-constructor';
import _find from 'lodash/find';
import { normalizeValuesForFilterConstructor } from './favorites-filter/utils';
import _omitBy from 'lodash/omitBy';
import _isEqual from 'lodash/isEqual';

@withTranslation()
@connect((state) => ({
  favorites: state.favorites,
  teams: state.teams,
  positions: state.positions,
  matches: state.matches,
  user: state.auth.user,
  users: state.users,
  subscription: state.subscription,
  recentlyRated: state.recentlyRated,
}), {
  changeRecentlyRatedFilter, removeFromFavorites, fetchRecentlyRated, changeLoading, resetFilter,
})

export default class RecentlyRated extends Component {
  static propTypes = {
    recentlyRated: PropTypes.object,
    favorites: PropTypes.object,
    positions: PropTypes.object,
    teams: PropTypes.object,
    fetchFavoritesInfo: PropTypes.func,
    removeFromFavorites: PropTypes.func,
    changeRecentlyRatedFilter: PropTypes.func,
    resetFilter: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      showSearch: false,
      name: props.recentlyRated.filter.name,
    };

    this.renderPlayer = this.renderPlayer.bind(this);
    this.unFavorite = this.unFavorite.bind(this);
    this.closeSearch = this.closeSearch.bind(this);
    this.openSearch = this.openSearch.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.clearAllFilters = this.clearAllFilters.bind(this);
  }

  componentDidMount() {
    this.props.changeLoading({ component: 'app', loading: true });
    this.props.fetchRecentlyRated().then(() => {
      this.props.changeLoading({ component: 'app', loading: false });
    });
  }

  unFavorite(event, favorite_id) {
    const { t } = this.props;
    event.preventDefault();

    if (window.confirm(t('DO_YOU_REALLY_WANT_TO_REMOVE_FAVORITE', { context: window.genderContext }))) {
      this.props.removeFromFavorites(favorite_id);
    }
  }

  openSearch() {
    this.setState({ showSearch: true });
  }

  closeSearch() {
    this.setState({ showSearch: false });
  }

  renderPlayer() {
    const {
      favorites, positions, teams, lng, user, recentlyRated,
    } = this.props;

    const { filter } = recentlyRated;

    if (!_.size(recentlyRated.players)) return null;

    let sortedList = recentlyRated.players.map((p) => {
      const player = { ...p };
      const fav = favorites.list.find((p) => p.player_id == player.player_id);

      player.position_id = player.position_id || 0;
      player.join_date_raw = player.join_date && player.join_date !== '1900-01-01' ? +moment(player.join_date, 'YYYY-MM-DD').unix() : 0;
      player.contract_until_raw = player.contract_until && player.contract_until !== '1900-01-01' ? +moment(player.contract_until, 'YYYY-MM-DD').unix() : 0;

      player.team_name = _.get(teams, `[${player.team_id}].name_${lng}`, _.get(teams, `[${player.team_id}].name`, '-'));
      player.position_name = _.get(positions, `[${player.position_id}].desc_short_${lng}`, _.get(positions, `[${player.position_id}].desc_short`, '-'));
      player.birth_date = player.birth_date && player.birth_date !== '1900-01-01' ? moment().diff(player.birth_date, 'years') : '–';
      player.country = player.country || '-';
      player.join_date = player.join_date && moment(player.join_date, 'YYYY-MM-DD').isValid() && moment(player.join_date, 'YYYY-MM-DD') > moment('1970-01-01', 'YYYY-MM-DD') ? moment(player.join_date, 'YYYY-MM-DD').format('DD/MM/YYYY') : '–';
      player.contract_until = player.contract_until && moment(player.contract_until, 'YYYY-MM-DD').isValid() && moment(player.contract_until, 'YYYY-MM-DD') > moment('1970-01-01', 'YYYY-MM-DD') ? moment(player.contract_until, 'YYYY-MM-DD').format('DD/MM/YYYY') : '–';
      player.sort_name = `${player.first_name} ${player.last_name}`;
      player.date = player.date ? moment(player.date).format('DD/MM/YYYY HH:MM') : '-';

      if (fav) {
        player.fav = fav;
        player.category = fav.category;
        player.groupMemberFavorite = fav.group_member_favorite;
        player.favorite_id = fav._id;
        player.favorite_id = fav._id;
      } else {
        player.fav = undefined;
        player.category = undefined;
      }

      if (isNaN(player.birth_date)) {
        player.birth_date = '-';
      }

      return player;
    });

    if (user.role.tag !== 'user' && filter.list_user_id) {
      sortedList = sortedList.filter((p) => {
        return p.user_id === filter.list_user_id;
      });
    }

    if (filter.team && filter.team.value !== -1) {
      sortedList = sortedList.filter((p) => +p.team_id === +filter.team.value);
    }

    if (filter.position && filter.position.value !== -1) {
      sortedList = sortedList.filter((p) => +p.position_id === +filter.position.value);
    }

    if (filter.name) {
      const regexp = new RegExp(filter.name, 'ig');
      sortedList = sortedList.filter((p) => regexp.test(p.sort_name));
    }

    return (
      <RecentList
        isEntries
        players={sortedList}
        unFavorite={this.unFavorite}
        withPaging
      />
    );
  }

  onFilterChange({ inputValue }, filter) {
    const { changeRecentlyRatedFilter } = this.props;
    const { type, name, field, options } = filter;

    let value;
    switch (field) {
      case 'position': {
        value = _find(options, { value: inputValue });
        break;
      }
      default: {
        value = inputValue;
      }
    }

    changeRecentlyRatedFilter({
      [field]: value,
    })
  }

  clearAllFilters() {
    this.props.resetFilter();
  }

  render() {
    const {
      user, favorites, t, lng, positions, recentlyRated,
    } = this.props;

    const { list, info } = favorites;

    let position_options = _.chain(positions)
      .filter((p) => p.upper_position)
      .orderBy(['upper_rank'], ['asc'])
      .map((p) => ({ value: p.id, label: _.get(p, `desc_long_${lng}`, _.get(p, 'desc_long', 'N/D')) }))
      .value();
    const players_positions = _.uniq(list.filter((p) => info[p.player_id]).map((p) => +info[p.player_id].position_id));

    position_options = position_options.filter((pos_o) => (players_positions.indexOf(+pos_o.value) !== -1));
    position_options.unshift({ label: t('ALL_POSITIONS'), value: -1 });

    const teams_options = [{ label: t('ALL_TEAMS'), value: -1 }];

    const users = _.get(this.props.users, 'data', null);
    const recentlyRatedFilter = recentlyRated.filter;

    const scoutsOptions = _(users)
      .filter((u) => user._id !== u._id)
      .orderBy('lastName')
      .map((u) => {
        return { value: u._id, label: `${u.firstName} ${u.lastName}` };
      })
      .value();

    scoutsOptions.unshift({ value: null, label: t('ALL_SCOUTS') });
    scoutsOptions.unshift({ value: '-', label: '-', isDisabled: true });
    scoutsOptions.unshift({ value: user._id, label: t('MY_PLAYERS', { context: window.genderContext }) });

    const scoutValue = scoutsOptions.find((o) => o.value == recentlyRatedFilter.list_user_id);
    const changedFilters = Object.keys(_omitBy(recentlyRated.filter, (values, key) => key === 'name' || _isEqual(recentlyRated.filterInitial[key], values)))?.length || 0;

    const filters = [
      {
        type: 'select-teams',
        name: t('TEAM'),
        field: 'team',
        options: teams_options,
      }, {
        type: 'select',
        name: t('POSITION'),
        field: 'position',
        options: position_options,
      }
    ];

    const values = normalizeValuesForFilterConstructor(recentlyRatedFilter, filters);

    return (
      <div className="favorites">
        <div className="container">
          <div className="favorites-header">
            <PlayerSearch
              defaultName={recentlyRatedFilter.name}
              search={(value) => {
                this.props.changeRecentlyRatedFilter({ name: value });
              }}
            />

            <div className="favorites-list__filters">
              <div className="favorites-filter">
                <FiltersConstructor
                  classNames="reports-filters"
                  filters={filters}
                  values={values}
                  onFilterChange={this.onFilterChange}
                  applyFilters={() => {}}
                  renderInline
                  clearAllFilters={this.clearAllFilters}
                  BtnComponent={({ onClick, active }) => (
                    <button
                      type="button"
                      onClick={onClick}
                      className={`btn btn--filter ${active ? 'active' : ''}`}
                    >
                      <Icon name="filter" />
                      { changedFilters ? <span className="btn--filter__num">{changedFilters}</span> : null }
                    </button>
                  )}
                />
              </div>
              <span className="btn search-player" onClick={() => { this.openSearch(); }}>
                <Icon name="plus" className="create-icon" />
                {' '}
                {t('OPEN_ANOTHER_PLAYER', { context: window.genderContext })}
              </span>
            </div>
          </div>
          { !_.size(recentlyRated.players) ? (
            <div>
              <div className="empty-message">{t('EMPTY_RECENT_LIST', { context: window.genderContext })}</div>
            </div>
          ) : this.renderPlayer()}
        </div>
        { this.state.showSearch ? (
          <div>
            <div className="add-player-fade" />
            <AddPlayer onlySearch onClickOutside={() => { this.closeSearch(); }} />
          </div>
        ) : null }
      </div>
    );
  }
}
