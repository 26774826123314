import React, { Component } from 'react';
import { Link, browserHistory } from 'react-router';

import { connect } from 'react-redux';

import { Scrollbars } from 'react-custom-scrollbars';
import { withTranslation } from 'react-i18next';
import { fetchFavoritesInfo, removeFromFavorites, changeFavoritesFilter } from '../../actions/favorites';
import { fetchMatches, changeLoading } from '../../actions/index';
import {
  fetchShadowTeams,
  createShadowTeam,
  fetchShadowTabs,
  openShadowTab,
  closeShadowTab,
} from '../../actions/shadow-teams';

import { updateShadowTeamsTab } from '../../actions/auth';

import { ALPHABET } from '../../constants';

import accountApi from '../../actions/account';
import Icon from '../Icon';

import './shadow-teams.scss';
import DemoShadowTeamMan from '../../../demo_shadow.json';
import DemoShadowTeamWoman from '../../../demo_shadow_women.json';

import { exceededShadowTeamsLimit } from '../../helpers/limits';
import congratulationsConfirm from '../ui/congratulations-confirm';

@withTranslation()
@connect((state, own) => ({
  subscription: state.subscription,
  favorites: state.favorites,
  teams: state.teams,
  positions: state.positions,
  matches: state.matches,
  user: state.auth.user,
  users: state.users,
  shadowTeams: state.shadowTeams,
  shadowTabs: state.shadowTabs,
  currentTeam: own.params.shadow_team_id,
}), {
  fetchFavoritesInfo,
  removeFromFavorites,
  changeFavoritesFilter,
  fetchMatches,
  changeLoading,
  fetchShadowTeams,
  fetchShadowTabs,
  createShadowTeam,
  openShadowTab,
  closeShadowTab,
  updateShadowTeamsTab,
})
export default class Favorites extends Component {
  constructor(props) {
    super(props);

    this.createTeam = this.createTeam.bind(this);
  }

  componentDidMount() {
    const { user, favorites, subscription } = this.props;

    if (!favorites.fetched) {
      this.props.changeFavoritesFilter({ list_user_id: this.props.user._id });
      const promises = [this.props.fetchFavoritesInfo(), this.props.fetchShadowTeams(), this.props.fetchShadowTabs(this.props.user._id)];
      if (user.role.tag !== 'user') {
        promises.push(this.props.dispatch(accountApi.actions.groupUsers()));
      }

      this.props.changeLoading({ component: 'app', loading: true });
      Promise.all(promises)
        .then(() => {
          this.props.changeLoading({ component: 'app', loading: false });
          if (exceededShadowTeamsLimit(subscription)) {
            congratulationsConfirm([{ type: 'UPGRADE_PLAN', _id: 'UPGRAD_FAKE_ID' }]);
          }
        });
    }
  }

  renderShadowTeamsList(teamsFiltered) {
    const { currentTeam } = this.props;

    return teamsFiltered.map((team) => {
      const {
        _id, name,
      } = team;

      return (
        <li key={_id}>
          <a
            onClick={() => { browserHistory.push(`/shadow-teams/${_id}/`); }}
            className={currentTeam === _id ? 'active' : ''}
          >
            { name }
          </a>
        </li>
      );
    });
  }

  renderTabs() {
    const { shadowTeams, user, currentTeam } = this.props;
    const { shadowTeamsTabs } = user;

    return shadowTeamsTabs.map((tab) => {
      const team = shadowTeams.find((t) => t._id == tab);
      if (team && team._id) {
        return (
          <li key={team._id} className="deletable">
            <a
              onClick={() => { browserHistory.push(`/shadow-teams/${team._id}/`); }}
              className={currentTeam === team._id ? 'active' : ''}
            >
              { team.name }
            </a>
            <span
              className="delete"
              onClick={() => {
                this.deleteShadowTeamTab(team._id);
              }}
            >
              <Icon name="close" />
            </span>
          </li>
        );
      }
    });
  }

  deleteShadowTeamTab(id) {
    const { user, currentTeam } = this.props;
    const { shadowTeamsTabs } = user;
    const index = shadowTeamsTabs.findIndex((t) => t == id);
    this.props.updateShadowTeamsTab(user._id, [...shadowTeamsTabs.slice(0, index), ...shadowTeamsTabs.slice(index + 1)]);
    if (currentTeam === id) {
      browserHistory.push('/shadow-teams/');
    }
  }

  createTeam() {
    let name = '';

    for (let i = 0; i < ALPHABET.length; i++) {
      name = `${ALPHABET[i]}-Team`;
      const index = this.props.shadowTeams.findIndex((t) => t.name === name);
      if (index === -1) {
        break;
      }
    }

    this.props.createShadowTeam({ name, formation: 2 }).then((result) => {
      const { _id } = result.payload.data;
      this.props.openShadowTab(_id, this.props.user._id);
      browserHistory.push(`/shadow-teams/${_id}`);
    });
  }

  render() {
    const { t, subscription } = this.props;

    const { user, shadowTeams, currentTeam } = this.props;
    const { shadowTeamsTabs } = user;

    const shadowTeamsFiltered = shadowTeams.filter((t) => user._id == t.user_id);
    const team = currentTeam && shadowTeams.length ? shadowTeams.find((t) => t._id === currentTeam) : {};

    let list = shadowTeamsFiltered;

    const DemoShadowTeam = window.genderContext === 'female' ? DemoShadowTeamWoman : DemoShadowTeamMan;

    if (!shadowTeams.length) {
      list = DemoShadowTeam;
    }

    return (
      <div className="favorites-wrapper">
        <div className="sub-nav">
          <div className="sub-nav__navigation">
            <Scrollbars autoHide>
              <div className="pad">
                <h2>{t('SHADOW_TEAMS')}</h2>
                <ul>
                  <li className="nav-link"><Link to="/shadow-teams" onlyActiveOnIndex activeClassName="active">{t('OVERVIEW')}</Link></li>
                  { list.length ? <li className="divider" /> : null }
                  { list.length ? this.renderShadowTeamsList(list) : null }
                  { shadowTeamsTabs.length ? <li className="divider" /> : null }
                  { shadowTeamsTabs.length ? this.renderTabs() : null }
                </ul>
              </div>
            </Scrollbars>
          </div>
          <div className="sub-nav__content shadow-team">
            <Scrollbars autoHide>
              <div className="container">
                <div className="shadow-team__heading">
                  { !currentTeam ? <h3>{t('SHADOW_TEAMS')}</h3> : <h3>{team.name}</h3> }
                </div>
                { this.props.children }
              </div>
            </Scrollbars>
          </div>
        </div>
      </div>
    );
  }
}
