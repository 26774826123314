import React, { Component } from 'react';
import './final_rating_overtime.scss';
import _ from 'lodash';
import * as d3 from 'd3';

import { withTranslation } from 'react-i18next';
import { preparePlayerRating } from '../../../../helpers';

const color = d3.scaleLinear().domain([0, 1, 2, 3, 4, 5]).range(['#FFFFFF', '#1D619F', '#6CB5D7', '#A4A6A4', '#F98736', '#DA4B06']);
const label = ['NONE', 'UNINTERESTING', 'LESS_INTERESTING', 'FOCUS', 'INTENSIVE_FOCUS', 'IMMEDIATELY'];

@withTranslation()
export default class FinalRatingOvertime extends Component {
  render() {
    const {
      playerId, t, finalRatings, matches,
    } = this.props;

    const preparedFinalRating = preparePlayerRating(matches, finalRatings, playerId);

    return (
      <div className="final-rating-overtime">
        <div className="final-rating-overtime__heading">
          <label>{t('BASED_ON')}</label>
          {matches.length === 1 ? t('1_REPORT') : t('N_REPORTS', { n: matches.length })}
        </div>

        {
          preparedFinalRating && preparedFinalRating.final ? (
            <div className="final-rating-overtime__body">
              <label>{t('FINAL_RATING_OVER_TIME')}</label>
              { this.renderTable(preparedFinalRating.final) }
            </div>
          )
            : null
        }
      </div>
    );
  }

  renderTable(final) {
    const { t } = this.props;

    const components = [];

    _.forOwn(final, (value, key) => {
      const { length } = value;
      components.push(<tr key={key}>
        <td className="season-col">{key}</td>
        <td>{ this.renderBar(value) }</td>
        <td className="reports-col">{length ? (length === 1 ? t('1_REPORT') : t('N_REPORTS', { n: length })) : null }</td>
                      </tr>);
    });

    return (
      <table>
        <tbody>
          { components }
        </tbody>
      </table>
    );
  }

  renderBar(value) {
    const { matches } = this.props;
    const barWidth = (value.length * 100) / matches.length;

    return (
      <div className="bar" style={{ width: `${barWidth}%` }}>
        { value.map((v, index) => <div key={index} className={`bar-section ${value.length === 1 ? 'single' : ''}`} style={{ backgroundColor: color(v) }} />) }
      </div>
    );
  }
}
