import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import './create-report.scss';
import { updateReportsLimits } from '../../../actions/subscription';
import { fetchAddMatch } from '../../../actions';

import EditMatch from '../../ui/match-edit-form';
import eventTracker from '../../../helpers/event-tracker';
import { canAddReport } from '../../../helpers/limits';

function CreateReport({
  subscription,
  updateReportsLimits,
  fetchAddMatch,
}) {
  const [isAdd, setIsAdd] = useState(false);
  const { t } = useTranslation();

  if (!canAddReport(subscription)) {
    return null;
  }

  return (
    <>
      <button
        type="button"
        className="btn btn-primary btn__new"
        onClick={() => setIsAdd(true)}
      >
        +
        {' '}
        {t('ADD_A_REPORT')}
      </button>
      {isAdd ? (
        <EditMatch
          onSubmit={({ newMatch }) => {
            const {
              date, stadium, home, away, league, assign_to, type, player_id,
            } = newMatch;

            if (!canAddReport(subscription)) {
              return null;
            }

            updateReportsLimits({ current: subscription.reports.current + 1 });
            return fetchAddMatch({
              date,
              stadium_id: stadium,
              league_id: league,
              home_team_id: home,
              away_team_id: away,
              assign_to,
              type,
              player_id,
            }, subscription).then((result) => {
              if (result.payload.match) {
                eventTracker.trackEvent('homepage', 'match created');
                browserHistory.push(`/match/${result.payload.match.match_id}`);
              }
            }).catch((err) => {
              console.log(err);
            });
          }}
          onClose={() => setIsAdd(false)}
        />
      ) : null}
    </>
  );
}

CreateReport.propTypes = {
  t: PropTypes.func,
  subscription: PropTypes.object,
  updateReportsLimits: PropTypes.func,
  fetchAddMatch: PropTypes.func,
};

export default connect((state) => {
  return {
    teams: state.teams,
    leagues: state.leagues,
    subscription: state.subscription,
  };
}, { fetchAddMatch, updateReportsLimits })(CreateReport);
