import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { Link } from 'react-router';

import './cta.scss';
import Icon from '../../Icon';
import eventTracker from '../../../helpers/event-tracker';

@withTranslation()
class Cta extends Component {
  render() {
    const { t } = this.props;
    return (
      <div className="analytics-cta-chart">
        <div className="analytics-cta">
          <Icon name="upgrade-analytics" />
          <h4>{t('UPGRADE_TO_PRO')}</h4>
          <p>{t('ANALYTICS_CTA_TEXT')}</p>
          <Link to="/settings/billing" className="btn" onClick={() => { eventTracker.trackEvent('Analytics', 'Click Upgrade CTA'); }}>{t('UPGRADE')}</Link>
        </div>
      </div>
    );
  }
}

Cta.propTypes = {
  t: PropTypes.func,
};

export default Cta;
