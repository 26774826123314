import React from 'react';
import Sidebar from '../sidebar';
import ReportMain from './report-main';
import Controls from '../controls';

export default function SingleReport() {
  return (
    <>
      <Sidebar />
      <ReportMain />
      <Controls />
    </>
  );
}
