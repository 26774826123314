import React, { Component } from 'react';

import './add-player.scss';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import onClickOutside from 'react-onclickoutside';
import Scrollbars from 'react-custom-scrollbars';
import axios from 'axios';
import Tappable from 'react-tappable';
import isTouchDevice from 'is-touch-device';
import moment from 'moment';
import { browserHistory } from 'react-router';
import { ROOT_URL } from '../../../../actions/index';
import PlayerImage from '../../../player-image';
import PlayerCard from '../../../shadow-team/components/player-card';
import { addToFavorite, addPlayerInfo, createNewPlayer } from '../../../../actions/favorites';
import { updateFavoriteLimits } from '../../../../actions/subscription';

import AddNew from '../../../match/add-player/add-new';
import { getTeamImage } from '../../../../helpers';
import { canAddWatchlist } from '../../../../helpers/limits';

const ID = function () {
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 9 characters
  // after the decimal.
  return `_${Math.random().toString(36).substr(2, 9)}`;
};

@withTranslation()
@connect((state) => ({
  favorites: state.favorites,
  subscription: state.subscription,
  user: state.auth.user,
}), {
  addToFavorite, addPlayerInfo, updateFavoriteLimits, createNewPlayer,
})
@onClickOutside
export default class Favorites extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: '',
      list: [],
      hovered: null,
      player_pos: 0,
      scrollTop: 0,
      addPlayerMode: props.onlyCustom || false,
      onlySearch: props.onlySearch,
    };

    this.fetchPlayers = this.fetchPlayers.bind(this);
    this.addPlayer = this.addPlayer.bind(this);
    this.changeFilter = this.changeFilter.bind(this);
    this.renderPlayer = this.renderPlayer.bind(this);
    this.setAddPlayerMode = this.setAddPlayerMode.bind(this);
    this.fetchPlayers = _.debounce(this.fetchPlayers, 350);
    this.goToPlayer = this.goToPlayer.bind(this);
  }

  componentDidMount() {
    this.fetchPlayers();
  }

  handleClickOutside() {
    this.props.onClickOutside();
  }

  addPlayer(player) {
    const { subscription, user } = this.props;

    if (this.props.onAddPlayer) {
      this.props.onAddPlayer(player);
      return;
    }

    // todo: change to is max helper
    if (canAddWatchlist(subscription)) {
      this.props.addPlayerInfo(player);
      this.props.addToFavorite(player.player_id, player, null, user._id, 0);
      this.props.updateFavoriteLimits({ current: ++subscription.favorites.current });
      if (this.props.onAfterAddPlayer) {
        this.props.onAfterAddPlayer(player);
      }
      this.props.onClickOutside();
    } else {
      this.props.onClickOutside();
    }
  }

  goToPlayer(player) {
    browserHistory.push(`/players/recently-rated/${player.player_id}/summary`);
  }

  fetchPlayers(needle) {
    const request = axios.get(`${ROOT_URL}/players/search/`, {
      params: { needle },
    });
    request.then((result) => {
      this.setState({ list: result.data.players });
    });
  }

  changeFilter(needle) {
    this.setState({ filter: needle });
    this.fetchPlayers(needle);
  }

  renderPlayer(player, i) {
    const { t, onlySearch } = this.props;
    let playerName = '';
    const birth_year = moment(player.birth_date, 'YYYY-MM-DD').year();
    const age = player.birth_date && birth_year !== 1900 ? `(${moment().year() - (+birth_year)})` : '';

    if (player.first_name && player.last_name) {
      playerName = `${player.last_name}, ${player.first_name} ${age}`;
    }

    if (!player.first_name && player.last_name) {
      playerName = `${player.last_name} ${age}`;
    }

    if (!player.last_name) {
      playerName = player.short_name + age;
    }

    const teamImage = getTeamImage(player.team_id);

    return (
      <li key={`${player.player_id}_${i}`}>
        <Tappable
          onClick={() => {
            !onlySearch ? this.addPlayer(player) : this.goToPlayer(player);
          }}
          onMouseEnter={() => {
            this.setState({ hovered: player, player_pos: i });
          }}
          onMouseLeave={() => {
            this.setState({ hovered: null, player_pos: 0 });
          }}
          onTouchStart={() => {
            this.setState({ hovered: player, player_pos: i });
          }}
          onTouchEnd={() => {
            this.setState({ hovered: null, player_pos: 0 });
          }}
          onTap={() => {
            !onlySearch ? this.addPlayer(player) : this.goToPlayer(player);
          }}
        >
          <PlayerImage className="team-image" src={teamImage} />
          {playerName}
          {' '}
          {player.isCustom ? (<span className="custom-player-indicator">{t('CUSTOM')}</span>) : null}
        </Tappable>
      </li>
    );
  }

  setAddPlayerMode(isOpen) {
    this.setState({
      addPlayerMode: isOpen,
    });
  }

  render() {
    const { t, subscription, onlySearch } = this.props;
    const { filter, list } = this.state;

    return (
      <div className="add-player-pane">
        <div className="header">
          <h4>{onlySearch ? t('SEARCH_FOR_PLAYER', { context: window.genderContext }) : t('ADD_PLAYER_TO_WATCHLIST', { context: window.genderContext })}</h4>

          {!onlySearch && subscription.favorites.max !== 'INFINITY' ? (
            <p
              className={`limit ${subscription.favorites.current === subscription.favorites.max ? 'error' : ''}`}
            >
              {t('WATCHLIST_LIMITS_ON_PLAN', {
                current: subscription.favorites.max - subscription.favorites.current > 0 ? subscription.favorites.max - subscription.favorites.current : 0,
                max: subscription.favorites.max,
              })}
            </p>
          )
            : null}
          {!this.state.addPlayerMode ? (
            <input
              type="text"
              name="search"
              placeholder={t('TYPE_TO_SEARCH_PLAYER', { context: window.genderContext })}
              value={filter}
              autoFocus={!isTouchDevice()}
              onChange={(e) => {
                this.changeFilter(e.target.value);
              }}
            />
          ) : null}

        </div>

        {
          !onlySearch && this.state.addPlayerMode ? (
            <div className="add-new-player">
              <AddNew
                withClose
                onCancelClick={() => { this.setState({ addPlayerMode: false }); }}
                onCreate={(player) => {
                  const id = `new-${player.team_id}_${ID()}`;
                  const newPlayer = { ...player, id, player_id: id };
                  this.props.createNewPlayer(newPlayer);
                  this.addPlayer(newPlayer);
                }}
              />
            </div>
          ) : (
            <div className="players-list">
              {this.state.hovered
                ? (
                  <PlayerCard
                    playerData={this.state.hovered}
                    top={this.state.player_pos * 40 - this.state.scrollTop}
                    className="add left"
                  />
                ) : null}
              <Scrollbars
                style={{ height: window.innerHeight - 152 }}
                onScrollFrame={(vals) => {
                  this.setState({ scrollTop: vals.scrollTop });
                }}
              >
                <ul>
                  {list.length > 0 ? list.map(this.renderPlayer)
                    : <div className="no-results">{t('NO_PLAYERS_FOR_SEARCH', { context: window.genderContext })}</div>}
                </ul>
              </Scrollbars>
            </div>
          )
        }

        {
          (subscription.favorites.max === 'INFINITY' || subscription.favorites.current < subscription.favorites.max) && !this.state.addPlayerMode && !onlySearch
            ? (
              <button className="btn btn-block" onClick={() => this.setAddPlayerMode(true)}>
                +
                {t('CREATE_NEW_PLAYER', { context: window.genderContext })}
              </button>
            )
            : null
        }
      </div>
    );
  }
}
