import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import VirtualizedSelect from 'react-virtualized-select';

import { withTranslation } from 'react-i18next';
import { validateEmail } from '../../../helpers';

@withTranslation()
class Create extends Component {
  static propTypes = {
    groupAdmin: PropTypes.bool,
    roles: PropTypes.array.isRequired,
    groups: PropTypes.array.isRequired,
    create: PropTypes.func.isRequired,
  };

  constructor() {
    super();

    this.state = {
      createMode: false,
      create: {
        firstName: '',
        lastName: '',
        email: '',
        roleId: 0,
        groupId: null,
      },
      errors: {
        firstName: false,
        lastName: false,
        email: false,
      },
    };

    this.changeCreateValue = this.changeCreateValue.bind(this);
    this.toggleCreateMode = this.toggleCreateMode.bind(this);
    this.create = this.create.bind(this);
  }

  changeCreateValue(value, input) {
    const create = _.cloneDeep(this.state.create);
    const errors = _.cloneDeep(this.state.errors);
    create[input] = value;
    errors[input] = false;

    this.setState({ create, errors });
  }

  toggleCreateMode() {
    const { groupAdmin, groups } = this.props;

    this.setState({
      createMode: !this.state.createMode,
      create: {
        ...this.state.create,
        groupId: groupAdmin ? groups[0].value : null,
      },
    });
  }

  create() {
    const user = this.state.create;
    const errors = _.cloneDeep(this.state.errors);
    const {
      firstName, lastName, email,
    } = user;

    if (!firstName.length) { errors.firstName = true; }
    if (!lastName.length) { errors.lastName = true; }
    if (!email.length || !validateEmail(email)) { errors.email = true; }

    if (firstName.length > 1 && lastName.length > 1 && email.length && validateEmail(email)) {
      const error = this.props.create(user);

      if (error) {
        errors[error] = true;
        this.setState({ errors });
      } else {
        this.setState({
          createMode: false,
          create: {
            firstName: '',
            lastName: '',
            email: '',
            roleId: 0,
            groupId: null,
          },
          errors: {
            firstName: false,
            lastName: false,
            email: false,
          },
        });
      }
    } else {
      this.setState({ errors });
    }
  }

  render() {
    const { roles, t } = this.props;
    const { create, errors } = this.state;
    const {
      firstName, lastName, email, roleId, groupId,
    } = create;

    if (this.state.createMode) {
      return (
        <div className="create-container">
          <table className="users-list">
            <tbody>
              <tr className="edit">
                <td width="8%" />
                <td width="16%"><input className="inp" className={`${errors.firstName ? 'invalid' : ''}`} type="text" onChange={(event) => this.changeCreateValue(event.target.value, 'firstName')} value={firstName} placeholder={t('FIRST_NAME')} /></td>
                <td width="16%"><input className="inp" className={`${errors.lastName ? 'invalid' : ''}`} type="text" onChange={(event) => this.changeCreateValue(event.target.value, 'lastName')} value={lastName} placeholder={t('LAST_NAME')} /></td>
                <td width="16%"><input className="inp" className={`${errors.email ? 'invalid' : ''}`} type="email" onChange={(event) => this.changeCreateValue(event.target.value, 'email')} value={email} placeholder={t('EMAIL')} /></td>
                <td colSpan="2">
                  <VirtualizedSelect
                    options={roles}
                    onChange={(value) => { this.changeCreateValue(value.value, 'roleId'); }}
                    value={roleId}
                    clearable={false}
                  />
                </td>
                <td className="controls-col">
                  <a className="green-btn" href="#" onClick={this.create}>{t('INVITE')}</a>
                  <a className="red-btn" href="#" onClick={this.toggleCreateMode}>{t('CANCEL')}</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    }
    return (
      <div className="create-container">
        <div className="users-list text-right">
          <a className="green-btn" href="#" onClick={this.toggleCreateMode}>
            +
            {t('INVITE_NEW_USER')}
          </a>
        </div>
      </div>
    );
  }
}

export default Create;
