import React from 'react';

export default function ({ lang }) {
  switch (lang) {
    case 'de':
      return (
        <div>
          <h1 className="overlay-h1">Allgemeine Geschäftsbedingungen</h1>
          <p>
            Bitte beachte unsere <a href="https://scoutpad.com/wp-content/uploads/2016/01/Allgemeine_Geschaeftsbedingungen_Scoutpad.pdf" target="_blank" rel="noreferrer">Allgemeinen Geschäftsbedingungen</a>
            .
          </p>
          <br />
          <br />
          <br />
          <div>
            <h1 className="overlay-h1">Datenschutzerklärung</h1>
            <p>
              Bitte beachte unsere <a href="https://scoutpad.com/de/datenschutz-2/" target="_blank" rel="noreferrer">Datenschutzbestimmungen</a>
              .
            </p>
          </div>
        </div>
      );

    case 'pt':
      return (
        <div>
          <h1 className="overlay-h1">Termos de Serviço</h1>
          <p>
            Por favor, dê uma olhada em nosso <a href="https://scoutpad.com/wp-content/uploads/2016/01/Terms_and_Conditions_Scoutpad.pdf" target="_blank" rel="noreferrer">termos e condições gerais</a>
            .
          </p>
          <br />
          <br />
          <br />
          <div>
            <h1 className="overlay-h1">Política de Privacidade</h1>
            <p>
              Por favor, dê uma olhada em nossa <a href="https://scoutpad.com/privacy-policy-2/" target="_blank" rel="noreferrer">política de privacidade</a>
            </p>
          </div>
        </div>
      );

    default:
      return (
        <div>
          <h1 className="overlay-h1">Terms of Service</h1>
          <p>
            Please take a look at our <a href="https://scoutpad.com/wp-content/uploads/2016/01/Terms_and_Conditions_Scoutpad.pdf" target="_blank" rel="noreferrer">general terms and conditions</a>
            .
          </p>
          <br />
          <br />
          <br />
          <div>
            <h1 className="overlay-h1">Privacy Policy</h1>
            <p>
              Please take a look at our <a href="https://scoutpad.com/privacy-policy-2/" target="_blank" rel="noreferrer">privacy policy</a>
            </p>
          </div>
        </div>
      );
  }
}
