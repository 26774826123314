import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

export default function isSigned(Component) {
  class AuthenticatedComponent extends PureComponent {
    static propTypes = {
      user: PropTypes.object,
    };

    render() {
      return this.props.user ? <Component {...this.props} /> : null;
    }
  }

  const mapStateToProps = (state) => ({
    user: state.auth.user,
  });

  return connect(mapStateToProps)(AuthenticatedComponent);
}
