import React, { Component } from 'react';
import moment from 'moment';
import './summary.scss';

import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import { fetchFavorite, resetFavorite, updateFavorite } from '../../../actions/favorites';

import PlayerImage from '../../player-image';
import DataItemIcon from '../components/data_item_icon';

import PlayerInformation from '../../ui/player-information';
import PlayerTeamInformation from '../../ui/player-team-information';

import PositionsList from '../components/positions_list';
import StatsChart from '../components/stats_chart';
import FinalRatingOvertime from '../components/final_rating_overtime';
import PerformanceStats from '../components/performance_stats_overtime';
import MergeScreen from '../components/merge_screen';
import AddToFavorite from '../../ui/add-to-favorites';
import Watchlists from '../components/watchlists';
import { getWatchlistCategory, formatPlayerData } from '../../../helpers';
import Icon from '../../Icon';
import _ from 'lodash';

@withTranslation()
@connect((state) => ({
  teams: state.teams,
  leagues: state.leagues,
  info: state.favorites.detail,
  positions: state.positions,
  favorites: state.favorites,
  subscription: state.subscription,
}), { fetchFavorite, resetFavorite, updateFavorite })
export default class Summary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      edit: null,
      merging: false,
      isLeagueTableOpen: false,
    };

    this.onEditClick = this.onEditClick.bind(this);
    this.onSaveClick = this.onSaveClick.bind(this);
    this.onCancelClick = this.onCancelClick.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    ReactTooltip.rebuild();
  }

  onEditClick() {
    const { player } = this.props.info;

    this.setState({
      edit: {
        ...player,
      },
    });
  }

  onCancelClick() {
    this.setState({
      edit: null,
    });
  }

  onSaveClick() {
    this.props.updateFavorite(this.state.edit);
    this.setState({
      edit: null,
    });
  }

  onChange(property) {
    const { player } = this.props.info;
    if (!player) return;
    const { value } = property;
    if (value.value) {
      property.value = value.value;
    }

    const newPlayer = {
      ...player,
      ...this.state.edit,
    };
    newPlayer[property.name] = property.value;

    this.setState({
      edit: {
        ...newPlayer,
      },
    });
  }

  renderLockedLeaguesMessage() {
    const { t } = this.props;

    return (
      <div className="leagues-locked-message">
        <Icon name="icon-lock" />
        { t('LOCKED_LEAGUES') }
      </div>
    );
  }

  renderLeaguesTable(stats) {
    let items = [];
    const { t } = this.props;

    _.forEach(stats, (value, key) => {
      items.push((
        <tr key={key}>
          <td className="table-league-name">
            <PlayerImage className="data-item__image" src={value.img} />
            {value.name}
          </td>
          <td>
            {value.matches}
          </td>
          <td>
            {value.mins}
          </td>
          <td>
            {value.goals}
          </td>
          <td>
            {value.assists}
          </td>
          <td>
            {value.red}
          </td>
          <td>
            {value.yellow}
          </td>
          <td>
            {value.substitution_in}
          </td>
          <td>
            {value.substitution_out}
          </td>
        </tr>
      ));
    });

    let needToPaginateTable = false;
    const { isLeagueTableOpen } = this.state;

    if (items.length > 3) {
      needToPaginateTable = true;

      if (!isLeagueTableOpen) {
        items = items.slice(0, 3);
      }
    }

    return (
      <table className="leagues-table">
        <thead>
          <tr>
            <th>{t('LEAGUE')}</th>
            <th><Icon name="match" className="no-stroke" data-tip={t('MATCHES')} /></th>
            <th><Icon name="time" className="no-stroke" data-tip={t('MINS')} /></th>
            <th><Icon name="goals" data-tip={t('GOALS')} /></th>
            <th><Icon name="assists" data-tip={t('ASSISTS')} /></th>
            <th><Icon name="red" data-tip={t('RED')} /></th>
            <th><Icon name="yellow" data-tip={t('YELLOW')} /></th>
            <th><Icon name="subin" className="no-stroke" data-tip={t('SUBSTITUTIONS_IN')} /></th>
            <th><Icon name="subout" className="no-stroke" data-tip={t('SUBSTITUTIONS_OUT')} /></th>
          </tr>
        </thead>
        <tbody>
          {items}
        </tbody>
        {
        needToPaginateTable ? (
          <tbody>
            <tr>
              <td colSpan={9} className="pagination-link">
                <span onClick={() => {
                  this.setState({ isLeagueTableOpen: !isLeagueTableOpen });
                }}
                >
                  { isLeagueTableOpen ? t('SHOW_LESS') : t('SHOW_MORE') }
                </span>
              </td>
            </tr>
          </tbody>
        ) : null
      }
      </table>
    );
  }

  render() {
    const {
      teams, leagues, matches, positions, t, i18n, favorites, season, stats, subscription, info, finalRatings, overallRating,
    } = this.props;
    const lng = i18n.language;

    const { player } = info;

    if (!player) return null;
    const averages = {
      formatted: overallRating,
      count: finalRatings.length,
    };

    console.log({ leagues });

    const isCustom = new RegExp('new\-', 'ig').test(player.player_id);

    const {
      first_name,
      last_name,
      jersey,
      player_id,
      weight,
      height,
      birth_date,
      age,
      foot,
      contract,
      contract_ages,
      team_img,
      team_name,
      team_league_name,
      position_id,
      position,
      marketvalue,
      joinDateRaw,
      joinDate,
      foot_translated,
      playerImage,
    } = formatPlayerData(player, t, teams, lng, leagues, positions);

    const transfermarkt = 'https://www.transfermarkt.com';
    const youtubeSearch = 'https://www.youtube.com/results?search_query=';

    const tmLink = player.tm_link ? transfermarkt + player.tm_link : null;
    const youtubeLink = player.youtube_link ? youtubeSearch + player.youtube_link : null;

    const { list } = favorites;
    let fav = list.find((f) => f.player_id == player_id);

    if (player.player_id === 'demo') {
      fav = {
        category: 3,
        final_rating: 4.55,
        final_rating_formatted: '4.55',
      };

      averages.formatted = '4.55';
      averages.count = 1;
    }

    const position_options = _.chain(positions)
      .filter((p) => p.upper_position)
      .orderBy(['upper_rank'], ['asc'])
      .map((p) => ({ value: p.id, label: _.get(p, `desc_short_${lng}`, _.get(p, 'desc_short', 'N/D')) }))
      .value();

    const position_short = _.get(positions, `[${position_id}].desc_short_${lng}`, _.get(positions, `[${position_id}].desc_short`, 'N/D'));

    const matchesStats = stats.reduce((accumulator, matchStat) => {
      return {
        mins: accumulator.mins + matchStat.mins,
        goals: accumulator.goals + matchStat.goals,
        assists: accumulator.assists + matchStat.assists,
        red: accumulator.red + matchStat.red,
        yellow: accumulator.yellow + matchStat.yellow,
        positions: {
          ...accumulator.positions,
          [matchStat.position_id_detail]: (accumulator.positions[matchStat.position_id_detail] || 0) + matchStat.mins,
        },
      };
    }, {
      mins: 0, goals: 0, assists: 0, yellow: 0, red: 0, positions: {},
    });

    const matchesStatsByLeague = stats.reduce((accumulator, matchStat) => {
      const { league_id } = matchStat;

      const stat = accumulator[league_id];
      if (stat) {
        return {
          ...accumulator,
          [league_id]: {
            ...stat,
            mins: stat.mins + matchStat.mins,
            goals: stat.goals + matchStat.goals,
            assists: stat.assists + matchStat.assists,
            red: stat.red + matchStat.red,
            yellow: stat.yellow + matchStat.yellow,
            jersey: matchStat.jersey,
            substitution_in: matchStat.substitution_in ? stat.substitution_in + 1 : stat.substitution_in,
            substitution_out: matchStat.substitution_out ? stat.substitution_out + 1 : stat.substitution_out,
            matches: stat.matches + 1,
          },
        };
      }

      return {
        ...accumulator,
        [league_id]: {
          name: _.get(leagues, `[${league_id}].name_${lng}`, _.get(leagues, `[${league_id}].name`, t('UNKNOWN_LEAGUE'))),
          img: `https://static.sp7f.com/v2/leagues/${_.get(leagues, `[${league_id}].league_id_master`, null)}.png`,
          mins: matchStat.mins || 0,
          goals: matchStat.goals || 0,
          assists: matchStat.assists || 0,
          red: matchStat.red || 0,
          yellow: matchStat.yellow || 0,
          jersey: matchStat.jersey || 0,
          substitution_in: matchStat.substitution_in ? 1 : 0,
          substitution_out: matchStat.substitution_out ? 1 : 0,
          matches: 1,
        },
      };
    }, {});

    if (matchesStats.positions['0'] === 0) {
      delete matchesStats.positions['0'];
    }

    // `https://static.sp7f.com/players/${player_id}.jpg`

    return (
      <div className="favorite-summary">
        <div className="content-block player-information">
          <PlayerInformation
            isEdit={!!this.state.edit}
            onCancelClick={this.onCancelClick}
            t={t}
            onSaveClick={this.onSaveClick}
            onEditClick={this.onEditClick}
            player={{
              first_name,
              last_name,
              birth_date,
              playerImage,
              jersey,
              age,
              foot,
              weight,
              height,
              foot_translated,
            }}
            onDataChange={this.onChange}
          />

          <PlayerTeamInformation
            t={t}
            onDataChange={this.onChange}
            isEdit={!!this.state.edit}
            position_options={position_options}
            player={{
              team_name,
              team_img,
              team_league_name,
              position,
              position_id,
              position_short,
              contract_ages,
              contract,
              joinDate,
              joinDateRaw,
              marketvalue,
            }}
          />

          <div className="player-information__rating">
            <h4>{t('CATEGORY')}</h4>
            <AddToFavorite
              match_id={null}
              player={player}
              added_message={t('WATCHLIST_CATEGORY_CHANGED')}
            >
              <div className="favorite-selector">
                <Icon name="favorite" />
                {' '}
                {getWatchlistCategory(fav, t)}
                {' '}
                <Icon name="chevron_down" className="chevron-down" />
              </div>
            </AddToFavorite>

            <div className="player-information__scouting-level">
              <h4 data-tip={t('SCOUTING_LEVEL_TIP', {
                context: window.genderContext,
                count: averages.count,
              })}
              >
                {t('SCOUTING_LEVEL')}
                <Icon name="help" />
              </h4>
              {averages.count > 0 ? (
                <p>
                  {t('SCOUTING_LEVEL_TEXT', {
                    avg: averages.formatted,
                    count: averages.count,
                  })}
                </p>
              ) : <p>N/D</p>}

            </div>

          </div>
        </div>

        {
          isCustom ? (
            <div className="content-block custom-player-controls">
              <button
                className="btn"
                onClick={() => {
                  this.setState({ merging: true });
                }}
              >
                {t('MERGE_WITH_DATABASE_PLAYER')}
              </button>
              {
                this.state.merging ? (
                  <div>
                    <MergeScreen
                      player={player_id}
                      onClickOutside={() => {
                        this.setState({ merging: false });
                      }}
                    />
                    <div className="add-player-fade" />
                  </div>
                ) : null
              }
              {/* <button className="btn btn-red">Delete player</button> */}
            </div>
          ) : null
        }

        {stats.length ? (
          <div className="flex-row">
            <div className="content-block player-information__league-stats">
              { // todo: change to leagueTableAccess helper
                subscription.isFree && !subscription.isTrial ? this.renderLockedLeaguesMessage()
                  : (
                    <div>
                      {this.renderLeaguesTable(matchesStatsByLeague)}
                    </div>
                  )
              }

            </div>
          </div>
        ) : null}

        <div className="flex-row">
          <div className="player-information__column">
            {tmLink || youtubeLink ? (
              <div className="content-block player-information__external">
                <h3>{t('EXTERNAL_LINKS')}</h3>
                {tmLink
                  ? (
                    <a href={`https://sp7f.com/?url=${encodeURIComponent(tmLink)}`} target="_blank" rel="noreferrer">
                      <Icon
                        name="transfermarkt"
                      />
                    </a>
                  ) : null}
                {youtubeLink
                  ? (
                    <a
                      href={`https://sp7f.com/?url=${encodeURIComponent(youtubeLink)}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Icon
                        name="youtube"
                      />
                    </a>
                  ) : null}
              </div>
            ) : null}

            <div className="content-block player-information__stats">

              {season ? (<h4>{t('STATS_OF_SEASON', { season })}</h4>) : (<h4>{t('STATS_OF_ALL_SEASONS')}</h4>)}

              <div className="content-row">
                <DataItemIcon icon="pitch" label={t('MATCHES')} value={stats.length} />
                <DataItemIcon icon="mins" label={t('MINS')} value={matchesStats.mins} />
              </div>
              <div className="content-row">
                <DataItemIcon icon="goals" label={t('GOALS')} value={matchesStats.goals} />
                <DataItemIcon icon="assists" label={t('ASSISTS')} value={matchesStats.assists} />
              </div>
              <div className="content-row">
                <DataItemIcon icon="yellow" label={t('YELLOWS')} value={matchesStats.yellow} />
                <DataItemIcon icon="red" label={t('RED')} value={matchesStats.red} />
              </div>

              <div className="content-row positions-row">
                <PositionsList
                  playerPositions={matchesStats.positions}
                  totalMins={matchesStats.mins}
                  currentPosition={position_id}
                />
              </div>
            </div>
          </div>

          <div className="player-information__indicators">
            {this.renderStatsChart()}
          </div>
          <div className="player-information__reports">
            <div className="content-block player-information__reports-stats">
              <FinalRatingOvertime
                playerId={player.player_id}
                matches={matches}
                finalRatings={finalRatings}
                averages={averages}
              />
            </div>
            <div className="content-block player-information__performance">
              <PerformanceStats playerId={player.player_id} matches={matches} />
            </div>
            <div className="content-block player-information__watchlists">
              <Watchlists info={this.props.info} />
            </div>
          </div>
        </div>
        <ReactTooltip />
      </div>
    );
  }

  renderStatsChart() {
    const { player } = this.props.info;
    const { matches } = this.props;

    return <StatsChart playerId={player.player_id} matches={matches} />;
  }
}
