import _ from 'lodash';
import {
  FETCH_ENTRIES, CHANGE_ENTRIES_FILTER, MERGE_PLAYERS, DELETE_PLAYER, ENTRIES_RESET_FILTER,
} from '../actions/entries';
import { ADD_NEW_PLAYER } from '../actions/match';
import { CREATE_NEW_PLAYER_FROM_FAVORITES } from '../actions/favorites';

const filterDefault = {
  name: '',
  user_id: false,
  list_user_id: null,
  position: undefined,
  team: undefined,
};

const INITIAL_STATE = {
  players: [],
  playersById: {},
  teams: [],
  stadiums: [],
  fetched: true,
  filter: filterDefault,
  filterInitial: filterDefault,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_ENTRIES:
      const players = _.get(action.payload, 'data.players', []);
      const preparedPlayers = [];

      const newRegexp = /new\-/;
      _.forEach(players, (player) => {
        const { player_id } = player;
        if (newRegexp.test(player_id)) {
          preparedPlayers.push(player);
        }
      });

      const playersById = _.groupBy(preparedPlayers, 'player_id');

      const onlyLastPlayerEntry = [];
      _.forEach(playersById, (entries, id) => {
        const sortedEntries = entries.length > 1 ? _.orderBy(entries, [(p) => Number(new Date(p.updatedAt))], ['asc']) : entries;
        const lastEntry = sortedEntries[0];
        let player = { ...lastEntry };
        if (entries.length > 1) {
          entries.map((e) => {
            player = { ...player, ...e };
          });
        }
        onlyLastPlayerEntry.push(player);
      });

      return {
        ...state,
        players: onlyLastPlayerEntry,
        playersById,
        fetched: true,
      };

    case CHANGE_ENTRIES_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.payload,
        },
      };

    case MERGE_PLAYERS:
      return {
        ...state,
        fetched: false,
      };

    case DELETE_PLAYER:
      const data = _.get(action, 'payload.data', []);

      let newPlayers = [...state.players];
      const playersById2 = { ...state.playersById };

      data.map(({ player_id }) => {
        const index = newPlayers.findIndex((player) => player.player_id == player_id);
        newPlayers = [...newPlayers.slice(0, index), ...newPlayers.slice(index + 1)];
        delete playersById2[player_id];
      });

      return {
        ...state,
        players: newPlayers,
        playersById: playersById2,
      };

    case CREATE_NEW_PLAYER_FROM_FAVORITES:
      return {
        ...state,
        players: [...state.players, { ...action.payload, player_id: action.payload.id }],
        playersById: {
          ...state.playersById,
          [action.payload.id]: {
            player_id: action.payload.id,
            ...action.payload,
          },
        },

      };
    case ENTRIES_RESET_FILTER: {
      return {
        ...state,
        filter: state.filterInitial,
      }
    }

    default:
      return state;
  }
}
