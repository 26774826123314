import React, { Component } from 'react';
import Select from 'react-select';
import { filterTheme, styleFilter } from '../select_props';

export default function (props) {
  return (
    <Select
      {...props}
      styles={styleFilter}
      theme={filterTheme}
      hideSelectedOptions
    />
  );
}
