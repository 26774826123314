import {
  FETCH_WATCHLISTS,
  CREATE_WATCHLIST,
  DELETE_WATCHLIST,
  COPY_WATCHLIST,
  FETCH_WATCHLIST,
  RESET_CURRENT_WATCHLIST,
  UPDATE_WATCHLIST,
  SORT_WATCHLISTS,
} from '../actions/watchlists';
import { insertAndShift } from '../helpers';

const INITIAL_STATE = {
  list: [],
  current: null,
  fetched: false,
  error: null,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case UPDATE_WATCHLIST:
      if (action.payload._id === 'demo') return { ...state };

      const ind = state.list.findIndex((item) => item._id === action.payload._id);
      const nextList = [...state.list.slice(0, ind), { ...state.list[ind], name: action.payload.name, players: action.payload.players }, ...state.list.slice(ind + 1)];
      return {
        ...state,
        list: nextList,
        current: {
          ...action.payload,
        },
      };
    case RESET_CURRENT_WATCHLIST:
      return {
        ...state,
        current: null,
      };
    case FETCH_WATCHLIST:
      return {
        ...state,
        current: action.payload.data,
      };
    case FETCH_WATCHLISTS:
      const list = action.payload.data && action.payload.data.length ? action.payload.data : [];
      return {
        ...state,
        list,
        fetched: true,
      };
    case CREATE_WATCHLIST:
      return {
        ...state,
        list: [action.payload.data, ...state.list],
      };
    case DELETE_WATCHLIST:
      const id = action.payload;
      if (id === 'demo') {
        return { ...state };
      }

      const index = state.list.findIndex((w) => w._id === id);
      const newList = index !== -1 ? [...state.list.slice(0, index), ...state.list.slice(index + 1)] : state.list;
      return {
        ...state,
        list: [...newList],
      };
    case COPY_WATCHLIST:
      return {
        ...state,
        list: [action.payload.data, ...state.list],
      };
    case SORT_WATCHLISTS:
      const sortedList = [...state.list];
      insertAndShift(sortedList, action.payload.dragIndex, action.payload.hoverIndex);
      return {
        ...state,
        list: sortedList,
      };
    default:
      return state;
  }
}
