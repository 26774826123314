import React, { Component } from 'react';

import './watchlist-pane.scss';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import onClickOutside from 'react-onclickoutside';
import Scrollbars from 'react-custom-scrollbars';
import axios from 'axios';
import isTouchDevice from 'is-touch-device';
import { ROOT_URL } from '../../../actions/index';
import { canAddWatchlists } from '../../../helpers/limits';
import { ALPHABET } from '../../../constants';
import WatchlistEditForm from '../../watchlists/watchlist-edit-form';

import { createWatchlist } from '../../../actions/watchlists';
import { updateWatchlistsLimits } from '../../../actions/subscription';

@withTranslation()
@connect((state) => ({
  favorites: state.favorites,
  subscription: state.subscription,
  user: state.auth.user,
}), {
  createWatchlist, updateWatchlistsLimits,
})
@onClickOutside
export default class Favorites extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: '',
      list: [],
      addWatchlistMode: false,
    };

    this.fetchWatchlists = this.fetchWatchlists.bind(this);
    this.changeFilter = this.changeFilter.bind(this);
    this.renderWatchlist = this.renderWatchlist.bind(this);
    this.setAddWatchlistMode = this.setAddWatchlistMode.bind(this);
    this.createWatchlist = this.createWatchlist.bind(this);
  }

  componentDidMount() {
    this.fetchWatchlists();
  }

  handleClickOutside() {
    if (!this.state.addWatchlistMode) {
      this.props.onClickOutside();
    }
  }

  createWatchlist(name, description, color_id, icon_id) {
    const { subscription, user } = this.props;
    const limits = subscription.watchlists;

    if (!name) {
      for (let i = 0; i < ALPHABET.length; i++) {
        name = `Watchlist ${ALPHABET[i]}`;
        const index = this.state.list.findIndex((t) => t.name === name);
        if (index === -1) {
          break;
        }
      }
    }

    this.props.createWatchlist(name, description, color_id, icon_id).then((result) => {
      this.props.updateWatchlistsLimits({ current: ++limits.current });
      this.props.onWatchlistClick(result.payload.data);
      this.setState({ list: [...this.state.list, result.payload.data] });
    });
  }

  fetchWatchlists() {
    const request = axios.get(`${ROOT_URL}/watchlists`);
    request.then((result) => {
      this.setState({ list: result.data });
    });
  }

  changeFilter(needle) {
    this.setState({ filter: needle });
  }

  renderWatchlist(watchlist, i) {
    return (
      <li key={`${watchlist._id}_${i}`} className="watchlist" onClick={() => this.props.onWatchlistClick(watchlist)}>
        {watchlist.name}
      </li>
    );
  }

  setAddWatchlistMode(isOpen) {
    this.setState({
      addWatchlistMode: isOpen,
    });
  }

  render() {
    const { t, subscription, reduceSet } = this.props;
    const { filter, list } = this.state;
    const regexp = new RegExp(filter, 'ig');
    let filteredList = [];

    filteredList = list.filter((watchlist) => {
      return reduceSet.findIndex((w) => w._id === watchlist._id) === -1;
    });
    filteredList = filteredList.filter((watchlist) => {
      return regexp.test(watchlist.name);
    });

    return (
      <div className="add-watchlist-pane">
        <div className="header">
          <h4>{t('ADD_PLAYER_TO_WATCHLISTS', { context: window.genderContext })}</h4>
          {!this.state.addWatchlistMode ? (
            <input
              type="text"
              name="search"
              placeholder={t('TYPE_TO_SEARCH_WATCHLIST', { context: window.genderContext })}
              value={filter}
              autoFocus={!isTouchDevice()}
              onChange={(e) => {
                this.changeFilter(e.target.value);
              }}
            />
          ) : null}

        </div>

        {
          this.state.addWatchlistMode ? (
            <WatchlistEditForm mode="create" onCloseClick={() => this.setAddWatchlistMode(false)} onCreateClick={this.createWatchlist} />
          ) : (
            <div className="watchlists-list">
              <Scrollbars
                style={{ height: window.innerHeight - 152 }}
              >
                <ul>
                  {filteredList.length > 0 ? filteredList.map(this.renderWatchlist)
                    : <div className="no-results">{t('NO_WATCHLISTS_FOR_SEARCH')}</div>}
                </ul>
              </Scrollbars>
            </div>
          )
        }

        {
          (canAddWatchlists(subscription)) && !this.state.addWatchlistMode
            ? (
              <button className="btn btn-block" onClick={() => this.setAddWatchlistMode(true)}>
                +
                {t('CREATE_NEW_WATCHLIST')}
              </button>
            )
            : null
        }
      </div>
    );
  }
}
