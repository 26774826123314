import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

import Icon from '../Icon';

import './sections.scss';

@connect((state, own) => ({
  list: own.list,
  current: own.current,
}), {})
class Sections extends Component {
  static propTypes = {
    sections: PropTypes.array,
  };

  renderNavItem(section, current) {
    if (!section.render) return null;

    const base = this.props.name;

    if (section.deactivated) {
      return (
        <span key={section.link} className="locked">
          <Icon name="icon-lock" className="lock" />
          {section.name}
        </span>
      );
    }

    return (
      section.link === current ? <span key={section.link}>{section.name}</span>
        : <Link key={section.link} to={`/${base}/${section.link}`}>{section.name}</Link>
    );
  }

  renderNav() {
    let { list, current } = this.props;

    if (!current) { current = list[0].link; }

    return (
      <div className="section-nav">
        <div className="container">
          { list.map((section) => this.renderNavItem(section, current)) }
        </div>
      </div>
    );
  }

  render() {
    const { list, current } = this.props;
    let section = list.find((s) => s.link === current);

    if (!section) {
      section = list[0];
    }

    return (
      <div>
        { this.renderNav() }
        <div className="container">
          { section.component }
        </div>
      </div>
    );
  }
}

export default Sections;
