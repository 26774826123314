import React from 'react';
import { components } from 'react-select';
import './player-search-menu.scss';

export default function (props) {
  return (
    <components.Menu {...props} className="players-menu">
      {props.children}
    </components.Menu>
  );
}
