import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  reduxForm, Field, formValueSelector,
} from 'redux-form';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import MaskedInput from 'react-maskedinput';
import DatePicker from 'react-datepicker/dist/react-datepicker';
import _ from 'lodash';
import parseDate from 'date-fns/parse';
import setDate from 'date-fns/set';
import formatDate from 'date-fns/format';
import { v4 as uuidv4 } from 'uuid';
import TeamsSelect from '../teams-select';
import StadiumSelect from '../stadium-select';
import LeagueSelect from '../league-select';
import UserSelect from '../users-select';
import PlayerSelect from '../player-select/creatable';
import TeamForm from '../team-form';
import RadioSwitcher from '../radio-switcher';
import Icon from '../../Icon';
import { addStadium, createPlayer } from '../../../actions';
import confirm from '../../confirm';
import CreatePlayerForm from '../create-player-form';
import { getTeamImage } from '../../../helpers';

const validate = (values) => {
  const errors = {};

  if (!values.home) {
    errors.home = 'Required';
  }

  if (!values.away) {
    errors.away = 'Required';
  }

  if (values.home === values.away) {
    errors.away = 'Can\'t select same team';
  }

  if (!values.date) {
    errors.date = 'Required';
  }
  if (!values.start) {
    errors.start = 'Required';
  }

  if (values.type === 'single' && !values.player) {
    errors.player = 'Required';
  }

  return errors;
};

const fieldPlayerSelect = ({
  input, key, onCreate, ...rest
}) => {
  if (typeof input.value === 'string') {
    input.value = undefined;
  }
  return (
    <PlayerSelect
      key={key}
      name={input.name}
      value={input.value}
      onCreate={onCreate}
      isDisabled={rest.isDisabled}
      onChange={input.onChange}
      filter={rest.filter}
      teams={rest.teams}
    />
  );
};

const fieldInput = ({ input, ...rest }) => (
  <MaskedInput
    {...input}
    name={rest.name}
    placeholder={rest.placeholder}
    mask={rest.mask}
    disabled={rest.isDisabled}
    className={`input ${rest.className} ${rest.meta.touched && rest.meta.error ? 'no-valid' : ''}`}
  />
);

const fieldDatePicker = (({ input, ...rest }) => {
  if (typeof input.value === 'string') {
    input.value = parseDate(input.value, 'dd.MM.yyyy', new Date());
  }

  return (
    <DatePicker
      {...input}
      {...rest}
      selected={input.value}
      dateFormat="dd.MM.yyyy"
      portalId="DatePickerPortal"
      disabled={rest.isDisabled}
    />
  );
});

@withTranslation()
@connect((state, props) => {
  const selector = formValueSelector('match-edit-form');

  let initialValues = {
    type: 'match',
    date: new Date(),
    start: '20:00',
  };

  if (props.match) {
    const { i18n, t, players } = props;
    const lng = i18n.language;
    const { user } = state.auth;
    const {
      home, away, date, stadium_id, league_id, user_id, type, player_id,
    } = props.match;

    const homeId = _.get(home, 'team_id', null);
    const awayId = _.get(away, 'team_id', null);
    const homeName = _.get(state, `teams[${homeId}].name_${lng}`, _.get(state, `teams[${homeId}].name`, t('UNKNOWN')));
    const awayName = _.get(state, `teams[${awayId}].name_${lng}`, _.get(state, `teams[${awayId}].name`, t('UNKNOWN')));
    const stadiumName = _.get(state, `stadiums[${stadium_id}].stadium`, t('UNKNOWN'));
    const leagueName = _.get(state, `leagues[${league_id}].name_${lng}`, _.get(state, `leagues[${league_id}].name`, t('UNKNOWN')));
    const leagueCountry = _.get(state, `leagues[${league_id}].country_id`, t('UNKNOWN'));
    let assign_to = user.users.find((u) => (user_id === u._id) && (u._id !== user._id)) || null;

    assign_to = assign_to ? { value: assign_to._id, label: `${assign_to.firstName} ${assign_to.lastName}` } : null;

    const parsedDate = new Date(date);
    const player = players[player_id];

    initialValues = {
      home: { value: homeId, label: homeName },
      away: { value: awayId, label: awayName },
      date: formatDate(parsedDate, 'dd.MM.yyyy'),
      start: formatDate(parsedDate, 'HH:mm'),
      stadium: { value: stadium_id, label: stadiumName },
      league: { value: league_id, label: leagueName, country: leagueCountry },
      assign_to,
      type: type || 'match',
      player: player ? { value: player_id, label: `${player.first_name} ${player.last_name}`, data: player } : undefined,
    };
  }

  return {
    teams: state.teams,
    leagues: state.leagues,
    stadiums: state.stadiums,
    countries: state.countries,
    user: state.auth.user,
    initialValues,
    formValues: {
      home: selector(state, 'home'),
      away: selector(state, 'away'),
      date: selector(state, 'date'),
      start: selector(state, 'start'),
      league: selector(state, 'league'),
      type: selector(state, 'type'),
      player: selector(state, 'player'),
      assign_to: selector(state, 'assign_to'),
    },
  };
}, { addStadium, createPlayer })
@reduxForm({
  form: 'match-edit-form',
  validate,
})
class MatchEditForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      leagues: null,
      teams: [],
      isAddingTeam: false,
      isAddingPlayer: false,
      team_league: '',
      team_name: '',
      team_side: '',
    };

    this.createTeam = this.createTeam.bind(this);
    this.onPlayerCreate = this.onPlayerCreate.bind(this);
    this.createStadium = this.createStadium.bind(this);
    this.onTeamFormCancel = this.onTeamFormCancel.bind(this);
    this.onTeamCreate = this.onTeamCreate.bind(this);
    this.submit = this.submit.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.close = this.close.bind(this);
  }

  createTeam(value, name, league) {
    const { hideScrollbars } = this.props;
    hideScrollbars && hideScrollbars();

    this.setState({
      isAddingTeam: true,
      team_name: value,
      team_side: name,
      team_league: league,
    });
  }

  onTeamFormCancel() {
    const { showScrollbars } = this.props;
    showScrollbars && showScrollbars();
    this.setState({
      isAddingTeam: false,
      team_name: '',
      team_side: '',
      team_league: '',
    });
  }

  onTeamCreate(team, side) {
    const { change, showScrollbars } = this.props;

    change(side, { label: team.name, value: team.id });
    showScrollbars && showScrollbars();

    this.setState({
      isAddingTeam: false,
      team_name: '',
      team_side: '',
      team_league: '',
    });
  }

  onPlayerCreate(data) {
    const { change, user } = this.props;
    let object = {};

    if (data.type === 'existing') {
      object = {
        player: {
          ...data.player.data,
        },
        type: 'MODIFY',
        match_id: null,
        team_id: data?.team?.value,
      };
    } else {
      const id = `new-${data?.team?.value}_${data?.jersey}_${user._id}_${uuidv4()}`;

      const date = parseDate(data.birth_date, 'dd/MM/yyyy', new Date());

      object = {
        player: {
          player_id: id,
          birth_date: formatDate(date, 'yyyy-MM-dd', new Date()),
          first_name: data.first_name,
          last_name: data.last_name,
          jersey: data.jersey,
          foot: data?.foot?.value,
          position_id: data?.position?.value,
        },
        type: 'ADD',
        match_id: null,
        team_id: data?.team?.value,
      };
    }

    this.props.createPlayer(object).then((result) => {
      const team = data?.team?.label;
      const teamLogo = team && getTeamImage(data?.team?.value);
      const player_id = _.get(result, 'payload.data[0].player_id', null);

      change('player', {
        value: player_id, label: `${object?.player?.first_name} ${object?.player?.last_name}`, team, teamLogo, data: object.player,
      });
      this.setState({ isAddingPlayer: false });
    });
  }

  onDelete({ _id }) {
    const { t, onDelete } = this.props;
    const text = (dismiss) => {
      return (
        <div className="privacy-confirm">
          <h2 className="h2">{t('DELETE_MATCH_CONFIRM')}</h2>
        </div>
      );
    };

    confirm(text, {
      context: this.context,
      cancelText: t('CANCEL'),
      confirmText: t('DELETE'),
      hideCancel: false,
      confirmButtonClass: 'btn-red',
    })
      .then(
        () => {
          onDelete(_id);
        },
        () => {
        },
      );
  }

  submit(formValues) {
    const { match, reset, onSubmit } = this.props;
    const {
      away, home, league, stadium, assign_to, type, date, start, player,
    } = formValues;

    const [hours, minutes] = start.split(':').map((time) => +time);
    const dateObject = typeof date === 'string' ? parseDate(date, 'dd.MM.yyyy', new Date()) : date;
    const dateWithTime = setDate(dateObject, { hours, minutes, seconds: 0 });

    const newMatch = {
      ...formValues,
      away: away && away.value,
      home: home && home.value,
      league: league && league.value,
      stadium: stadium && stadium.value,
      assign_to: assign_to && assign_to.value,
      player_id: player && player.value,
      type,
      date: dateWithTime,
    };

    onSubmit && onSubmit({
      newMatch,
      oldMatch: match,
    });
    !match && reset();
  }

  close() {
    const { reset, onClose } = this.props;
    reset();
    onClose && onClose();
  }

  createStadium(stadium) {
    const { addStadium, change } = this.props;

    addStadium(stadium)
      .then((action) => {
        const id = _.get(action, 'payload.stadium.id', null);
        const stadium = _.get(action, 'payload.stadium.stadium', null);
        if (id) {
          change('stadium', { value: id, label: stadium });
        }
      });
  }

  render() {
    const {
      t, countries, formValues, user, handleSubmit, match, teams, isAdd,
    } = this.props;

    const { leagues } = this.state;
    const isDisabled = !formValues.league;
    const isDisabledForEdit = !!match && !isAdd;
    const isDisabledPlayer = !formValues.home || !formValues.away;
    const isAdmin = (user.role.tag === 'group_admin') || (user.role.tag === 'admin');
    const userOptions = isAdmin
      ? user.users.filter((u) => u._id !== user._id).map((u) => ({ value: u._id, label: `${u.firstName} ${u.lastName}` }))
      : [];

    if (userOptions.length) {
      userOptions.unshift({ value: '', label: t('DO_NOT_ASSIGN') });
    }

    let filterLeague = null;
    if (formValues.league && formValues.league.value !== '-') {
      filterLeague = formValues.league.value;
    }

    const validation = validate(formValues);
    const isDisabledSubmit = validation && Object.keys(validation).length !== 0 && Object.getPrototypeOf(validation) === Object.prototype;

    const selectedTeamsOptions = [];

    const teamsFilter = [];
    ['home', 'away'].map((side) => {
      if (formValues[side]) {
        selectedTeamsOptions.push(formValues[side]);
        teamsFilter.push(formValues[side].value);
        // selectedTeamsOptions
      }
    });

    return (
      <div className="match-edit-form">
        <form onSubmit={handleSubmit(this.submit)}>
          <div className="row indented match-edit-form__row">
            <div className="col-12 indented">
              <label>{t('LEAGUE')}</label>
              <Field
                countries={countries}
                placeholder={t('SELECT_LEAGUE')}
                leagues={leagues}
                emptyOption
                name="league"
                component={LeagueSelect}
              />
            </div>
          </div>
          <div className="row indented match-edit-form__row">
            <div className="col-6 indented">
              <label>{t('HOME_TEAM')}</label>
              <Field
                placeholder={t('SELECT_TEAM')}
                name="home"
                key={`${teamsFilter.join('_')}_home`}
                component={TeamsSelect}
                exclude={teamsFilter}
                onCreate={this.createTeam}
                isDisabled={isDisabled || isDisabledForEdit}
                league={filterLeague}
              />
            </div>
            <div className="col-6 indented">
              <label>{t('GUEST_TEAM')}</label>
              <Field
                key={`${teamsFilter.join('_')}_away`}
                placeholder={t('SELECT_TEAM')}
                name="away"
                component={TeamsSelect}
                exclude={teamsFilter}
                onCreate={this.createTeam}
                isDisabled={isDisabled || isDisabledForEdit}
                league={filterLeague}
              />
            </div>
          </div>
          <div className="row indented match-edit-form__row">
            <div className="col-6 indented">
              <div className="row indented">
                <div className="col-6 indented">
                  <label>{t('DATE')}</label>
                  <Field
                    className="input"
                    name="date"
                    mask="11.11.1111"
                    component={fieldDatePicker}
                    placeholder={t('MATCH_DATE_PLACEHOLDER')}
                    isDisabled={isDisabled}
                  />
                </div>
                <div className="col-6 indented">
                  <label>{t('STARTS_AT')}</label>
                  <Field
                    className="input react-timepicker__input-container"
                    name="start"
                    mask="11:11"
                    component={fieldInput}
                    type="text"
                    isDisabled={isDisabled}
                  />
                </div>
              </div>
            </div>
            <div className="col-6 indented">
              <label>{t('STADIUM')}</label>
              <Field
                placeholder={t('SELECT_LOCATION')}
                name="stadium"
                component={StadiumSelect}
                creatable
                formatCreateLabel={(label) => { t('CREATE_STADIUM', { label }); }}
                onCreate={this.createStadium}
                isDisabled={isDisabled}
              />
            </div>
          </div>
          <div className="row indented match-edit-form__row">
            <div className="col-12 indented">
              <Field
                name="type"
                component={RadioSwitcher}
                isDisabled={isDisabled || isDisabledForEdit}
                options={[
                  {
                    value: 'match',
                    label: t('MATCH_REPORT'),
                    icon: <Icon name="match-home" className="type-icon__match" />,
                  },
                  {
                    value: 'single',
                    label: t('SINGLE_PLAYER_REPORT'),
                    icon: <Icon name="player-home" className="type-icon__single" />,
                  },
                ]}
              />
            </div>
          </div>
          {
            formValues.type === 'single' ? (
              <div className="row indented match-edit-form__row">
                <div className="col-12 indented">
                  <Field
                    key={`${formValues?.home?.value}_${formValues?.away?.value}`}
                    name="player"
                    filter={teamsFilter}
                    component={fieldPlayerSelect}
                    placeholder={t('SELECT_PLAYER')}
                    teams={teams}
                    isDisabled={isDisabled || isDisabledForEdit || isDisabledPlayer}
                    onCreate={(value) => {
                      this.setState({ isAddingPlayer: value });
                    }}
                  />
                </div>
              </div>
            ) : null
          }
          <div className="row indented match-edit-form__row">
            {
              match && !isAdd ? (
                <div className="col-2 indented  match-edit-form__buttons">
                  <button
                    type="button"
                    className="btn btn-red"
                    onClick={() => this.onDelete(match)}
                  >
                    {t('DELETE')}
                  </button>
                </div>
              ) : null
            }
            <div className={`${match ? 'col-4' : 'col-6'} indented`}>

              { isAdmin ? (
                <>
                  <label>{t('ASSIGN_MATCH')}</label>
                  <Field
                    placeholder={t('ASSIGN_MATCH')}
                    users={userOptions}
                    name="assign_to"
                    component={UserSelect}
                    isDisabled={isDisabled}
                  />
                </>
              ) : null}
            </div>
            <div className="col-6 indented match-edit-form__buttons coll-pull-right">
              <button type="reset" className="btn btn-secondary" onClick={this.close}>{t('CANCEL')}</button>
              <button type="submit" className="btn" disabled={isDisabledSubmit}>{t('SAVE')}</button>
            </div>
          </div>
        </form>
        {this.state.isAddingTeam ? (
          <TeamForm
            name={this.state.team_name}
            side={this.state.team_side}
            league={this.state.team_league}
            onCreate={this.onTeamCreate}
            onCancel={this.onTeamFormCancel}
          />
        ) : null}
        {this.state.isAddingPlayer ? (
          <CreatePlayerForm
            teams={teams}
            options={selectedTeamsOptions}
            onClose={() => { this.setState({ isAddingPlayer: false }); }}
            onCreate={this.onPlayerCreate}
          />
        ) : null}
      </div>
    );
  }
}

MatchEditForm.propTypes = {
  handleSubmit: PropTypes.func,
  match: PropTypes.object,
  countries: PropTypes.object,
  t: PropTypes.func,
  hideScrollbars: PropTypes.func,
  showScrollbars: PropTypes.func,
  addStadium: PropTypes.func,
  change: PropTypes.func,
  formValues: PropTypes.object,
  reset: PropTypes.func,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
  createPlayer: PropTypes.func,
  user: PropTypes.object,
  isAdd: PropTypes.bool,
};

export default MatchEditForm;
