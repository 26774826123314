import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import _ from 'lodash';

import { withTranslation } from 'react-i18next';
import * as d3 from 'd3';
import moment from 'moment';
import PlayerImage from '../player-image';
import Icon from '../Icon';
import DemoPlayerMan from '../../../gnabry.json';
import DemoWomenPlayer from '../../../leupolz.json';

import './players.scss';
import AddPlayer from '../favorites/components/add-player';
import DemoMessage from '../ui/demo-message';
import DemoBadge from '../ui/demo-badge';
import { canAddWatchlist } from '../../helpers/limits';
import eventTracker from '../../helpers/event-tracker';
import { getPlayerImage, getTeamImage } from '../../helpers';
import AddToFavorite from '../ui/add-to-favorites';

@withTranslation()
@connect((state) => {
  return {
    teams: state.teams,
    favorites: state.favorites,
    positions: state.positions,
    subscription: state.subscription,
  };
})
class Players extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showAdd: false,
    };

    this.closeAddPlayer = this.closeAddPlayer.bind(this);
  }

  renderPlayer(player) {
    const {
      teams, i18n, t, favorites, positions,
    } = this.props;
    const lng = i18n.language;

    const playerImage = getPlayerImage(player);
    const teamImage = getTeamImage(player.team_id);
    const teamName = _.get(teams, `[${player.team_id}].name_${lng}`, _.get(teams, `[${player.team_id}].name`, t('UNKNOWN_TEAM')));
    const fav = favorites.list.find((l) => l.player_id == player.player_id);
    let category = null;
    const birth_date = player.birth_date ? moment(player.birth_date, 'YYYY-MM-DD') : null;
    const age = birth_date ? moment().diff(birth_date, 'years') : null;
    const position_id = _.get(player, 'position_id', null);
    const position = _.get(positions, `[${position_id}].desc_short_${lng}`, _.get(positions, `[${position_id}].desc_short`, 'N/D'));
    if (fav) {
      category = fav.category;
    }
    const color = d3.scaleLinear().domain([0, 1, 2, 3]).range(['#5F7582', '#EFDD30', '#F98736', '#DD4043']);

    return (
      <div
        className="homepage__player homepage__interactive-card"
        key={player.player_id}
        onClick={() => {
          eventTracker.trackEvent('homepage', 'click player');
          browserHistory.push(`/players/${player.player_id}`);
        }}
      >
        {
          player.player_id === 'demo' ? (<DemoBadge />) : null
        }
        <PlayerImage className="homepage__player-image" src={playerImage} />
        <div className="homepage__player-info">
          <div className="name">
            {player.first_name}
            {' '}
            {player.last_name}
          </div>
          {player.team_id ? (
            <div className="team">
              <PlayerImage className="homepage__team-image" src={teamImage} />

              {
                age ? (
                  <span>
                    { teamName }
                    {' '}
                    ·
                    {' '}
                    {age}
                    {' '}
                    yr ·
                    {' '}
                    { position }
                  </span>
                ) : (
                  <span>
                    { teamName }
                    {' '}
                    ·
                    {' '}
                    { position }
                  </span>
                )
              }
            </div>
          ) : null}
        </div>
        <div
          className="homepage__add-to-favorite-container"
          onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}
        >
          <AddToFavorite
            match_id={null}
            player={player}
            added_message={t('WATCHLIST_CATEGORY_CHANGED')}
          >
            <Icon name="favorite" className="favorite-icon" style={{ fill: category !== null ? color(category) : 'none', stroke: category === null ? color(category) : 'none' }} />
          </AddToFavorite>
        </div>
      </div>
    );
  }

  closeAddPlayer() {
    this.setState({ showAdd: false });
  }

  render() {
    const { t, subscription } = this.props;
    let { list } = this.props.players;
    const { info } = this.props.players;

    list = list.filter((l) => info[l]);
    list = list.map((l) => info[l]);
    list = list.slice(0, 6);
    let isDemo = false;

    const DemoPlayer = window.genderContext === 'female' ? DemoWomenPlayer : DemoPlayerMan;

    if (!list.length) {
      list = [{ ...DemoPlayer.player }];
      isDemo = true;
    }

    let showDemoMessage = isDemo;

    if (list.length <= 2) {
      showDemoMessage = true;
    }

    let showCreateButton = true;
    if (list.length > 4 || !subscription.plan || !canAddWatchlist(subscription)) {
      showCreateButton = false;
    }

    return (
      <div className="homepage__players-list">
        {list.map((player) => this.renderPlayer(player))}

        {
          showCreateButton ? (
            <button
              className="homepage__players-add homepage__interactive-card"
              onClick={() => {
                this.setState({ showAdd: true });
                eventTracker.trackEvent('homepage', 'click add player');
              }}
            >
              <PlayerImage
                className="homepage__player-image"
                src="https://static.sp7f.com/v2/players/123.jpg"
              />
              {t('ADD_PLAYER', { context: window.genderContext })}
              <Icon name="add" />
            </button>
          ) : null
        }
        {showDemoMessage ? <DemoMessage text={t('HOME_DEMO_PLAYER', { context: window.genderContext })} arrowPosition="top-right" /> : null}
        { this.state.showAdd ? (
          <div>
            <div className="add-player-fade" />
            <AddPlayer
              onClickOutside={() => { this.closeAddPlayer(); }}
              onAfterAddPlayer={(p) => { eventTracker.trackEvent('homepage', 'player added'); browserHistory.push(`/players/${p.player_id}`); }}
            />
          </div>
        ) : null }
      </div>
    );
  }
}

Players.propTypes = {
  players: PropTypes.object,
  teams: PropTypes.array,
  i18n: PropTypes.object,
  t: PropTypes.func,
  favorites: PropTypes.object,
  subscription: PropTypes.object,
};

export default Players;
