import React, { Component } from 'react';

export default class Image extends Component {
  constructor() {
    super();

    this.state = {
      error: false,
    };

    this.handleImageError = this.handleImageError.bind(this);
  }

  componentDidMount() {
    this.setState({ loading: true });

    const image = document.createElement('img');
    image.src = this.props.src;
    image.onerror = this.handleImageError;
  }

  handleImageError() {
    this.setState({ error: true });
  }

  render() {
    return (
      <div {...this.props} style={{ backgroundImage: `${this.state.error ? 'url(/images/no-player.png)' : `url(${this.props.src})`}`, opacity: `${this.state.error ? '.4' : '1'}` }} />
    );
  }
}
