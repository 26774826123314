import './pdf.scss';

import axios from 'axios';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Scrollbars } from 'react-custom-scrollbars';
import { Link } from 'react-router';
import { withTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import eventTracker, { EVENTS } from '../../helpers/event-tracker';

import Icon from '../Icon';

import Header from './header';
import Pitch from './pitch';
import Bench from './bench';
import Substitution from './substitution';
import Rates from './rates';
import LineUps from './lineups';
import EventsTable from './events-table';

import Single from './single';

import { getWindowHeight } from '../../helpers';
import { setPdfMatch, clearPDF } from '../../actions/pdf';
import { setMatch } from '../../actions/match';
import {
  ROOT_URL, fetchMatch, changeLoading, updateMatch,
} from '../../actions/index';
import { fetchNotifications } from '../../actions/notifications';

@withTranslation()
@connect((state) => ({
  pdf: state.pdf,
  match: state.match,
  teams: state.teams,
  stadiums: state.stadiums,
  user: state.auth.user,
}), {
  setPdfMatch, fetchMatch, changeLoading, setMatch, updateMatch, fetchNotifications, clearPDF,
})

export default class pdf extends Component {
  static propTypes = {
    pdf: PropTypes.object,
    match: PropTypes.object,
    changeLoading: PropTypes.func,
    fetchMatch: PropTypes.func,
    setMatch: PropTypes.func,
    setPdfMatch: PropTypes.func,
    params: PropTypes.object,
    teams: PropTypes.object,
    stadiums: PropTypes.object,
    updateMatch: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      height: getWindowHeight(),
    };

    this.handleResize = this.handleResize.bind(this);
    this.changeReportComplete = this.changeReportComplete.bind(this);
  }

  componentDidMount() {
    const {
      changeLoading, fetchMatch, setPdfMatch, setMatch, params,
    } = this.props;

    clearPDF();
    changeLoading({ component: 'pdf', loading: true });
    fetchMatch(params.match_id).then((data) => {
      if (data.payload.match) {
        setMatch(data.payload.match);
        setPdfMatch(data.payload.match);
      }
      changeLoading({ component: 'pdf', loading: false });
    });

    this.handleResize();

    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize() {
    this.setState({
      height: getWindowHeight(),
    });
  }

  download(event) {
    event.preventDefault();
    const { pdf } = this.props;

    const url = _.cloneDeep(pdf);
    url.weather = _.cloneDeep(pdf.match.weather);
    url.match_id = _.cloneDeep(pdf.match.match_id);
    delete url.match;

    this.props.changeLoading({ component: 'pdf', loading: true });

    const pdfWindow = window.open('', '', '');

    axios
      .post(`${ROOT_URL}/pdf/`, url)
      .then((data) => {
        if (data.data.success) {
          eventTracker.trackEvent('Match', 'Export to PDF');
          eventTracker.trackEvent('Report', 'Export to PDF');
          pdfWindow.location = `${ROOT_URL}/pdf/?match_id=${pdf.match.match_id}`;
          // window.open(`${ ROOT_URL }/pdf/?match_id=${ pdf.match.match_id }`, '_blank');
          this.props.changeLoading({ component: 'pdf', loading: false });
        } else {
          this.props.changeLoading({ component: 'pdf', loading: false });
        }
      })
      .catch((error) => {
        console.error(error);
        this.props.changeLoading({ component: 'pdf', loading: false });
      });
  }

  changeReportComplete(event, completeness) {
    event.preventDefault();
    const {
      changeLoading, fetchMatch, setPdfMatch, setMatch, params, pdf,
    } = this.props;

    eventTracker.trackEvent('Report', 'Change completeness');

    changeLoading({ component: 'pdf', loading: true });
    this.props.updateMatch({ match: { ...pdf.match, is_done: completeness } }).then(() => {
      fetchMatch(params.match_id).then((data) => {
        if (data.payload.match) {
          setMatch(data.payload.match);
          setPdfMatch(data.payload.match);
        }
        changeLoading({ component: 'pdf', loading: false });
        if (completeness === true) {
          this.props.fetchNotifications();
        }
      });
    });
  }

  render() {
    const { height } = this.state;
    const {
      pdf, teams, stadiums, t, user, params,
    } = this.props;

    if (params?.match_id !== pdf?.match?.match_id) {
      return null;
    }

    const from = _.get(this.props, 'location.query.from', null);

    if (!_.size(pdf.match) || !_.size(teams) || !_.size(stadiums)) return <div className="pdf"><div className="pdf-page" /></div>;

    const TopBar = (
      <div className="pdf-top-bar">
        <a href="#" onClick={this.download.bind(this)} className="btn">
          <Icon name="pdf" />
          {' '}
          {t('EXPORT')}
        </a>
        {
          !pdf.match.is_done ? (
            <a
              href={`/match/${pdf.match.match_id}`}
              className="btn go-to-match"
            >
              {t('GO_TO_MATCH')}
            </a>
          ) : null
        }
        {
          +user._id === +pdf.match.user_id && !pdf.match.is_done ? (
            <a
              href="#"
              data-tip={t('COMPLETENESS_TOOLTIP')}
              data-place="bottom"
              data-effect="solid"
              onClick={(e) => this.changeReportComplete(e, true)}
              className="btn notify"
            >
              <Icon name="checkbox" />
              {' '}
              {t('NOTIFY_ON_COMPLETE')}
            </a>
          ) : null
        }
        {
          +user._id === +pdf.match.user_id && pdf.match.is_done
            ? (
              <>
                <a
                  href="#"
                  onClick={(e) => this.changeReportComplete(e, false)}
                  className="btn mark-incomplete"
                >
                  {t('MARK_REPORT_AS_INCOMPLETE')}
                </a>
                <span
                  className="text complete"
                >
                  <Icon name="checkbox" />
                  {' '}
                  {t('MARKED_AS_COMPLETE')}
                </span>
              </>
            ) : null
        }

        {
          +user._id === +pdf.match.assigned_by && !pdf.match.is_done ? (
            <span
              className="text"
            >
              {t('REPORT_IN_WORK', { user: pdf.match.user })}
            </span>
          ) : null
        }

        {
          +user._id === +pdf.match.assigned_by && pdf.match.is_done ? (
            <a
              href="#"
              onClick={(e) => this.changeReportComplete(e, false)}
              className="btn mark-incomplete"
            >
              {t('MARK_REPORT_AS_INCOMPLETE')}
            </a>
          ) : null
        }

        {
          from ? (
            <Link className="pdf__close" to={`/players/${from}/reports`}><Icon name="close" /></Link>
          ) : (
            <Link className="pdf__close" to="/reports/"><Icon name="close" /></Link>
          )
        }

      </div>
    );

    const { type } = pdf;
    const content = type === 'single'
      ? (
        <div className="pdf-page single">
          <Single />
        </div>
      )
      : (
        <>
          <div className="pdf-page">
            <Header />
            <Pitch snapshot="lineup" width={822} />
            <EventsTable />
            <Bench />
            <Substitution />
          </div>
          <LineUps />
          <div className="pdf-page">
            <Rates side="home" />
          </div>
          <div className="pdf-page">
            <Rates side="away" />
          </div>
        </>
      );

    return (
      <div className="pdf">
        <Scrollbars autoHide style={{ height }}>
          {TopBar}
          {content}
        </Scrollbars>
        <ReactTooltip />
      </div>
    );
  }
}
