import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as d3 from 'd3';

import './outer.scss';

import _ from 'lodash';
import { changeOuterControl } from '../../../../../actions/match';
import { outerControlLabel, getWindowWidth } from '../../../../../helpers';

@connect((state) => ({
  match: state.match,
  indicators: state.indicators,
  formations: state.formations,
}), { changeOuterControl })

export default class Outer extends Component {
  static propTypes = {
    changeOuterControl: PropTypes.func,
    startTimer: PropTypes.func,
    indicators: PropTypes.object,
    match: PropTypes.object,
  };

  constructor() {
    super();

    this.renderControls = this.renderControls.bind(this);
    this.clickControl = this.clickControl.bind(this);
  }

  clickControl(value, event) {
    event.stopPropagation();
    this.props.changeOuterControl({ value });
    this.props.startTimer();
  }

  renderControls() {
    const desktop = getWindowWidth() > 960;
    const { indicators, formations, match } = this.props;
    const {
      controls, current_values, current_positions, data,
    } = match;
    const arc = d3.arc().innerRadius(desktop ? 105 : 75).outerRadius(desktop ? 165 : 120);
    const values = current_values[controls.side][controls.player];

    const position_id_detail = _.get(current_positions, `[${controls.side}].lineup[${controls.player}].position_id_detail`, null);
    const current_formation_id = _.get(data, `${controls.side}.formations.lineup.id`, null);
    const current_formation = formations[current_formation_id];
    const position_id_upper = current_formation.positions.find((i) => +i.position_id_detail === +position_id_detail).position_id;
    const primaryIndicators = _.get(indicators, `positions[${position_id_upper}].primary`, []);

    const pie = (innerControl) => {
      const startAngle = -Math.PI / 3 + Math.PI / 3 * innerControl - Math.PI / 3;
      const endAngle = Math.PI / 3 + Math.PI / 3 * innerControl - Math.PI / 3;

      return d3.pie().startAngle(startAngle).endAngle(endAngle).sort(null)
        .value(1);
    };

    if (!controls.inner || indicators.constant.indexOf(controls.inner) !== -1) return null;

    return pie([...primaryIndicators, ...indicators.constant].indexOf(controls.inner))([-2, -1, 0, 1, 2]).map((control, key) => (
      <g className={`control ${values[controls.inner] == control.data ? 'active' : ''}`} key={key}>
        <path onClick={(e) => this.clickControl(control.data, e)} className="section" d={arc(control)} />
        <text dy=".2em" transform={`translate(${arc.centroid(control)})`}>
          { outerControlLabel(control.data) }
        </text>
      </g>
    ));
  }

  render() {
    return (
      <g className="outer">
        { this.renderControls() }
      </g>
    );
  }
}
