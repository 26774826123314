export const UPPER_POSITIONS = [
  {
    short: 'GK', short_de: 'TW', full: 'Goalkeeper', id: 1,
  },
  {
    short: 'CB', short_de: 'IV', full: 'Centre-back', id: 2,
  },
  {
    short: 'DW', short_de: 'AV', full: 'Defensive Wing', id: 3,
  },
  {
    short: 'DM', short_de: 'DM', full: 'Defensive Midfield', id: 4,
  },
  {
    short: 'CM', short_de: 'ZM', full: 'Centre Midfield', id: 5,
  },
  {
    short: 'AM', short_de: 'OM', full: 'Attacking Midfield', id: 6,
  },
  {
    short: 'OW', short_de: 'AF', full: 'Offensive Wing', id: 7,
  },
  {
    short: 'FW', short_de: 'ST', full: 'Forward', id: 8,
  },
];

export const ROLE_NAMES = {
  GROUP_ADMIN: 'Group Admin',
  ADMIN: 'Admin',
  USER: 'User',
};

export const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

export const generateFootOptions = (t) => {
  return [
    { label: 'N/D', value: '' },
    { label: t('Right'), value: 'Right' },
    { label: t('Left'), value: 'Left' },
    { label: t('Both'), value: 'Both' },
  ];
};

export const REASONS = [
  {
    id: 0,
    name: 'REASON_CREATE_REPORTS',
    description: 'REASON_CREATE_REPORTS_DESCRIPTION',
    icon: 'pitch',
    iconStyle: { fill: 'currentColor', stroke: 'none' },
  },
  {
    id: 1,
    name: 'REASON_PLAYERS_AND_DATA_MANAGEMENT',
    description: 'REASON_PLAYERS_AND_DATA_MANAGEMENT_DESCRIPTION',
    icon: 'player',
  },
  {
    id: 2,
    name: 'REASON_RETRIEVE_PLAYER_ANALYSIS',
    description: 'REASON_RETRIEVE_PLAYER_ANALYSIS_DESCRIPTION',
    icon: 'pie-chart',
    iconStyle: {
      fill: 'none', stroke: 'currentColor', width: '23px', height: '23px',
    },
  },
  {
    id: 3,
    name: 'REASON_OTHER',
    icon: 'other',
    iconStyle: {
      top: '6px',
    },
  },
];

export const INDICATORS_BY_POSITION = {
  GK: ['xgsave', 'gkaerialduels', 'gkaerialduelswon', 'gkexits', 'gksuccessfulexits', 'gkshotsagainst', 'gksaves', 'successfulpassestofinalthird'],
  CB: ['aerialduels', 'aerialduelswon', 'dribblesagainst', 'dribblesagainstwon', 'slidingtackles', 'successfulslidingtackles', 'interceptions', 'successfulpassestofinalthird'],
  DW: ['dribblesagainst', 'dribblesagainstwon', 'slidingtackles', 'successfulslidingtackles', 'interceptions', 'dribbles', 'successfuldribbles', 'successfulpassestofinalthird'],
  DM: ['dribblesagainst', 'dribblesagainstwon', 'aerialduels', 'aerialduelswon', 'interceptions', 'successfulslidingtackles', 'xgassist', 'ownhalflosses'],
  CM: ['dribblesagainst', 'dribblesagainstwon', 'interceptions', 'xgassist', 'xgshot', 'successfulpassestofinalthird', 'dribbles', 'successfuldribbles'],
  AM: ['dribblesagainst', 'dribblesagainstwon', 'interceptions', 'xgassist', 'xgshot', 'successfulpassestofinalthird', 'dribbles', 'successfuldribbles'],
  OW: ['crosses', 'successfulcrosses', 'interceptions', 'xgassist', 'xgshot', 'successfulpassestofinalthird', 'dribbles', 'successfuldribbles'],
  FW: ['aerialduels', 'aerialduelswon', 'interceptions', 'xgassist', 'xgshot', 'successfulpassestofinalthird', 'dribbles', 'successfuldribbles'],
};

export const DNDOptions = {
  enableMouseEvents: true,
  delayTouchStart: 0,
  delayMouseStart: 0,
};

export const EVENTS_TYPES = ['yellow', 'red', 'goal', 'assist', 'yellow_red', 'substitution_in', 'substitution_out'];
