import axios from 'axios';
import localforage from 'localforage';
import moment from 'moment';
import { NotificationManager } from '../components/notifications';

import { ROOT_URL } from '../actions';
import { MATCHES_COLLECTION } from './constants';

export default function storageMatchUpdate(props) {
  const userId = localStorage.getItem('userId');
  const gender = localStorage.getItem('selectedGender');
  const collectionName = `${MATCHES_COLLECTION}_${gender}`;
  if (props.match.demo) return { match: props.match };

  NotificationManager.create({
    message: 'SAVING',
    id: 'id',
    timeOut: 3000,
  });


  if (props.skipServerUpdate) {
    return localforage.getItem(`${collectionName}-${userId}`)
      .then((matchesClient) => {
        matchesClient = matchesClient || [];
        const index = matchesClient.findIndex((m) => m._id === props.match._id);

        if (index !== -1) {
          matchesClient = [...matchesClient.slice(0, index), { ...props.match }, ...matchesClient.slice(index + 1)];
        }

        return localforage.setItem(`${collectionName}-${userId}`, matchesClient).then(() => {
          return { match: props.match };
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  switch (window.online) {
    case true:
      return Promise
        .all([
          axios.post(`${ROOT_URL}/match/`, props),
          localforage.getItem(`${collectionName}-${userId}`),
        ])
        .then(([resServer, matchesClient]) => {
          matchesClient = matchesClient || [];
          const matchServer = resServer.data.match;
          const index = matchesClient.findIndex((m) => m._id === props.match._id);

          if (index !== -1) {
            matchesClient = [...matchesClient.slice(0, index), { ...matchServer }, ...matchesClient.slice(index + 1)];
          }

          return localforage.setItem(`${collectionName}-${userId}`, matchesClient).then((res) => {
            return { match: matchServer };
          });
        })
        .catch((error) => {
          console.error(error);

          NotificationManager.create({
            message: 'NOT_SAVED',
            id: 'id',
            timeOut: 3000,
            type: 'error',
          });
        });

    case false:
      return localforage.getItem(`${collectionName}-${userId}`)
        .then((matchesClient) => {
          matchesClient = matchesClient || [];
          const index = matchesClient.findIndex((m) => m._id === props.match._id);
          matchesClient = [...matchesClient.slice(0, index), { ...props.match, ts: moment().toISOString() }, ...matchesClient.slice(index + 1)];
          NotificationManager.create({
            message: 'SAVED_OFFLINE',
            id: 'id',
            timeOut: 3000,
          });
          return localforage.setItem(`${collectionName}-${userId}`, matchesClient).then((m) => {
            return { match: props.match };
          });
        })
        .catch((error) => {
          console.error(error);

          NotificationManager.create({
            message: 'NOT_SAVED',
            id: 'id',
            timeOut: 3000,
            type: 'error',
          });
        });
      break;
  }
}
