import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import VirtualizedSelect from 'react-virtualized-select';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import confirm from '../../confirm';
import Icon from '../../Icon';

import { validateEmail } from '../../../helpers';

import { updateUserLimits } from '../../../actions/subscription';

@withTranslation()
@connect((state) => {
  return {
    subscription: state.subscription,
  };
}, {
  updateUserLimits,
})
class User extends Component {
  static propTypes = {
    permissions: PropTypes.object,
    user: PropTypes.object,
    roles: PropTypes.array,
    roleOptions: PropTypes.array,
    groups: PropTypes.array,
    editMode: PropTypes.bool,
    edit: PropTypes.func.isRequired,
    delete: PropTypes.func.isRequired,
    setEdit: PropTypes.func.isRequired,
  };

  constructor() {
    super();

    this.state = {
      edit: {
        firstName: '',
        lastName: '',
        email: '',
        roleId: null,
        groupId: null,
        active: true,
      },
      errors: {
        firstName: false,
        lastName: false,
        email: false,
      },
    };

    this.getRole = this.getRole.bind(this);
    this.saveUser = this.saveUser.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
    this.enableEdit = this.enableEdit.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
    this.changeUserActive = this.changeUserActive.bind(this);
  }

  componentDidMount() {
    const {
      firstName, lastName, email, roleId, groupId, active,
    } = this.props.user;
    this.setState({
      edit: {
        firstName, lastName, email, roleId, groupId, active,
      },
    });
  }

  getRole(roleId) {
    const { roles } = this.props;
    if (!roles.length) return '';

    const role = _.find(roles, { value: roleId });

    return role ? role.label : '';
  }

  getGroup(groupId) {
    const { groups } = this.props;

    if (!groups.length || !groupId) return '';

    const group = _.find(groups, { value: groupId });

    return group ? group.label : null;
  }

  deleteUser() {
    const { id } = this.props.user;
    const { firstName, lastName } = this.state.edit;
    const { t } = this.props;

    const text = (dismiss, onCheck, isChecked) => {
      const checked = isChecked ? 'checked' : '';
      return (
        <div className="confirm-body">
          <h2 className="h2">{t('PLEASE_CONFIRM')}</h2>
          <p>{t('USER_DELETE_CONFIRM', { firstName, lastName })}</p>

          <p className="with-checkbox">
            <span className={`checkbox ${checked}`} onClick={onCheck}>
              <Icon name="checkbox" />
            </span>
            {t('IMPORT_REPORTS_CHECKBOX')}
          </p>
        </div>
      );
    };

    confirm(text, {
      context: this.context, confirmButtonClass: 'btn-red', cancelText: t('CANCEL'), confirmText: t('CONTINUE'),
    })
      .then(
        ({ isChecked }) => {
          const importGames = isChecked;
          this.props
            .delete(id, importGames);
        },
        () => {},
      );

    //
  }

  enableEdit() {
    const { id } = this.props.user;
    this.props.setEdit(id);
  }

  cancelEdit() {
    const {
      firstName, lastName, email, roleId, groupId, active,
    } = this.props.user;

    this.props.setEdit(null);

    this.setState({
      edit: {
        firstName, lastName, email, roleId, groupId, active,
      },
      errors: {
        firstName: false,
        lastName: false,
        email: false,
      },
    });
  }

  saveUser() {
    const errors = _.cloneDeep(this.state.errors);
    const {
      firstName, lastName, email, roleId, groupId, active,
    } = this.state.edit;
    const { id } = this.props.user;
    const { edit, setEdit } = this.props;

    if (!firstName.length) { errors.firstName = true; }
    if (!lastName.length) { errors.lastName = true; }
    if (!email.length || !validateEmail(email)) { errors.email = true; }

    if (firstName.length > 1 && lastName.length > 1 && email.length && validateEmail(email)) {
      const error = edit({
        id, firstName, lastName, email, roleId, groupId, active,
      });

      if (error) {
        errors[error] = true;
        this.setState({ errors });
      } else {
        setEdit(null);
        this.setState({
          errors: {
            firstName: false,
            lastName: false,
            email: false,
          },
        });
      }
    } else {
      this.setState({ errors });
    }
  }

  changeEditValue(value, input) {
    const edit = _.cloneDeep(this.state.edit);
    const errors = _.cloneDeep(this.state.errors);
    edit[input] = value;
    errors[input] = false;

    this.setState({ edit, errors });
  }

  changeUserActive() {
    const edit = _.cloneDeep(this.state.edit);
    const { id } = this.props.user;
    const { subscription, t } = this.props;

    if (!edit.active) {
      if (subscription.scouts.current + 1 > subscription.scouts.max) {
        alert(t('SUBSCRIPTION_LIMIT_MESSAGE'));
        return;
      }
    }

    edit.active = !edit.active;

    if (edit.active) {
      this.props.updateUserLimits({ current: subscription.scouts.current + 1 });
    } else {
      this.props.updateUserLimits({ current: subscription.scouts.current - 1 });
    }

    this.setState({ edit });
    this.props.edit({ id, active: edit.active });
  }

  render() {
    const {
      permissions, roles, editMode, roleOptions, t,
    } = this.props;
    const { id, index } = this.props.user;
    const { errors, edit } = this.state;
    const {
      firstName, lastName, email, roleId, groupId, active,
    } = edit;

    const role = roles.find((r) => r.value === edit.roleId);
    const roleTag = role && role.tag;

    let canEdit = false;

    if (roleTag === 'admin' && permissions.canEditAdmin) {
      canEdit = true;
    }

    if (roleTag === 'group_admin' && permissions.canEditGroupAdmin) {
      canEdit = true;
    }

    if (roleTag === 'user' && permissions.canEditUser) {
      canEdit = true;
    }

    if (editMode) {
      return (
        <tr className={`${editMode ? 'edit' : ''}`}>
          <td>{ index || id }</td>
          <td><input className={`inp ${errors.firstName ? 'invalid' : ''}`} onChange={(event) => this.changeEditValue(event.target.value, 'firstName')} type="text" value={firstName} placeholder={t('FIRST_NAME')} /></td>
          <td><input className={`inp ${errors.lastName ? 'invalid' : ''}`} onChange={(event) => this.changeEditValue(event.target.value, 'lastName')} type="text" value={lastName} placeholder={t('LAST_NAME')} /></td>
          <td><input className={`inp ${errors.email ? 'invalid' : ''}`} onChange={(event) => this.changeEditValue(event.target.value, 'email')} type="text" value={email} placeholder={t('EMAIL')} /></td>
          <td>
            <VirtualizedSelect
              options={roleOptions}
              onChange={(value) => { this.changeEditValue(value.value, 'roleId'); }}
              value={this.state.edit.roleId}
              clearable={false}
            />
          </td>
          <td className="controls-col">
            <a className="green-btn" href="#" onClick={this.saveUser}>{t('SAVE')}</a>
            <a className="red-btn" href="#" onClick={this.cancelEdit}>{t('CANCEL')}</a>
          </td>
        </tr>
      );
    }

    return (
      <tr>
        <td><span className="align">{ index || id }</span></td>
        <td><span className="align">{ firstName }</span></td>
        <td><span className="align">{ lastName }</span></td>
        <td><span className="align">{ email }</span></td>
        <td><span className="align">{ (this.getRole(roleId)) }</span></td>
        <td className="controls-col">
          { canEdit
            ? [
              <a key={0} className={active ? 'orange-btn' : 'blue-btn'} href="#" onClick={this.changeUserActive}>{ active ? t('DEACTIVATE') : t('ACTIVATE') }</a>,
              <a key={1} className="green-btn" href="#" onClick={this.enableEdit}>{t('EDIT')}</a>,
              <a key={2} className="red-btn" href="#" onClick={this.deleteUser}>{t('DELETE')}</a>,
            ]
            : null}
        </td>
      </tr>
    );
  }
}

export default User;
