import _get from 'lodash/get';
import store from '../store';

const _paq = [];

const setUserId = (id) => {
  const userId = id;
  if (!userId) {
    // userId = _.get(store.getState(), 'auth.user._id', null);
  }

  if (userId !== null && _paq) {
    _paq.push(['setUserId', userId]);
    /* _paq.push(['trackPageView']); */
  } else {
    console.log('set user id', userId);
  }
};

const eventTracker = {
  trackUserId: (id) => {
    setUserId(id);
  },
  resetUserId: () => {
    if (_paq) {
      _paq.push(['resetUserId']);
    }
  },
  trackEvent: (category, name, value) => {
    const state = {};// store.getState();
    const groupId = _get(state, 'auth.user.groupId', null);
    if (_paq && groupId !== 5) {
      setUserId();
      _paq.push(['trackEvent', category, name, value]);
    } else {
      console.log('register fake event', { category, name, value });
    }
  },

  trackPageView: (event) => {
    const state = {};// store.getState();
    const groupId = _get(state, 'auth.user.groupId', null);
    if (_paq && groupId !== 5) {
      setUserId();
      _paq.push(['trackPageView']);
    } else {
      console.log('trackPageView');
    }
  },

  trackPlanAndTrial: (trial, plan, active) => {
    if (_paq) {
      setUserId();
      _paq.push(['setCustomDimension', 1, plan]);
      _paq.push(['setCustomDimension', 2, trial]);
      _paq.push(['setCustomDimension', 2, active]);
    } else {
      console.log('track dimension: ', { trial, plan, active });
    }
  },
};

export const EVENTS = {
  REGISTRATION: 'REGISTRATION',
  LOGIN: 'LOGIN',
  CREATE_MATCH: 'CREATE_MATCH',
  OPEN_MATCH: 'OPEN_MATCH',
  DELETE_MATCH: 'DELETE_MATCH',
  XML_EXPORT: 'XML_EXPORT',
  PDF_EXPORT: 'PDF_EXPORT',
  ADD_TO_WATCHLIST: 'ADD_TO_WATCHLIST',
  UPDATE_PLAYER_IN_WATCHLIST: 'UPDATE_PLAYER_IN_WATCHLIST',
  REMOVE_FROM_WATCHLIST: 'REMOVE_FROM_WATCHLIST',
  USE_WATCHLIST_FILTER: 'USE_WATCHLIST_FILTER',
  ADD_SHADOWTEAM: 'ADD_SHADOWTEAM',
  UPDATE_SHADOW_TEAM: 'UPDATE_SHADOW_TEAM',
  DELETE_SHADOW_TEAM: 'DELETE_SHADOW_TEAM',
  COPY_SHADOW_TEAM: 'COPY_SHADOW_TEAM',
  READ_HELP: 'READ_HELP',
  EDIT_ACCOUNT: 'EDIT_ACCOUNT',
  CHANGE_PLAN: 'CHANGE_PLAN',
  ADD_PAYMENT_DATA: 'ADD_PAYMENT_DATA',
  CHANGE_BILLING_INFO: 'CHANGE_BILLING_INFO',
  UPDATE_MATCH: 'UPDATE_MATCH',
  UPDATE_GROUP_DATA: 'UPDATE_GROUP_DATA',
  CHANGE_GROUP_PLAN: 'CHANGE_GROUP_PLAN',
  CANCEL_SUBSCRIPTION: 'CANCEL_SUBSCRIPTION',
  SUBMIT_COUPON: 'SUBMIT_COUPON',
  CREATE_NEW_PLAYER_FROM_FAVORITES: 'CREATE_NEW_PLAYER_FROM_FAVORITES',
  USE_ENTRIES_FILTER: 'USE_ENTRIES_FILTER',
  OPEN_CUSTOM_PLAYERS: 'OPEN_CUSTOM_PLAYERS',
  MERGE_CUSTOM_PLAYER: 'MERGE_CUSTOM_PLAYER',
};

export default eventTracker;
