import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import './watchlist-icon.scss';

@connect((state) => {
  return {
    icons: state.icons.list,
    colors: state.colors.list,
  };
})
class WatchlistIcon extends Component {
  render() {
    const {
      color_id, icon_id, icons, colors, isSmall, hover,
    } = this.props;

    const icon = icons.find((i) => i._id === icon_id) || icons[0];
    const color = colors.find((c) => c._id === color_id) || colors[0];
    const background = `rgba(${color.rgb[0]},${color.rgb[1]},${color.rgb[2]}, ${hover ? 1 : 0.25})`;
    const foreground = hover ? '#FFFFFF' : `rgba(${color.rgb[0]},${color.rgb[1]},${color.rgb[2]},1)`;

    return (
      <div
        style={{ background, fill: foreground, color: foreground }}
        dangerouslySetInnerHTML={{ __html: icon.code }}
        className={`watchlist-icon ${isSmall ? 'small' : ''}`}
      />
    );
  }
}

WatchlistIcon.propTypes = {
  color_id: PropTypes.string,
  icon_id: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  iconWidth: PropTypes.number,
  iconHeigh: PropTypes.number,
  icons: PropTypes.array,
  colors: PropTypes.array,
};

export default WatchlistIcon;
