import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { confirmable } from 'react-confirm';
import Modal from 'react-modal';
import Icon from '../Icon';

import './confirm-modal.scss';

class ConfirmDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isChecked: false,
      inputValue: '',
    };

    this.onCheck = this.onCheck.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.handleAfterOpen = this.handleAfterOpen.bind(this);
  }

  componentWillUnmount() {
    // this.overlayRef.removeEventListener('touchmove', this.preventer);
  }

  onCheck = () => {
    this.setState({ isChecked: !this.state.isChecked });
  };

  onInputChange = (e) => {
    this.setState({ inputValue: e.target.value });
  };

  handleAfterOpen = () => {
    // this.overlayRef.addEventListener('touchmove', this.preventer, false);
  };

  preventer(e) {
    e.preventDefault();
  }

  render() {
    const {
      show, proceed, dismiss, cancel, confirmation, options, t,
    } = this.props;

    const {
      withCheckbox, confirmButtonClass, withInput, hasClose, className, onConfirm,
    } = options;
    const { isChecked, inputValue } = this.state;

    return (
      <Modal
        shouldCloseOnOverlayClick={false}
        onRequestClose={dismiss}
        overlayRef={(node) => { this.overlayRef = node; }}
        ariaHideApp={false}
        isOpen={show}
        className={{
          base: `modal confirm-modal ${className || ''}`,
          afterOpen: 'myClass_after-open',
          beforeClose: 'myClass_before-close',
        }}
        overlayClassName={{
          base: 'modal-overlay',
          afterOpen: 'myOverlayClass_after-open',
          beforeClose: 'myOverlayClass_before-close',
        }}
        onAfterOpen={this.handleAfterOpen}
      >
        { hasClose ? (
          <Icon
            name="close"
            className="close-icon"
            onClick={() => {
              onConfirm && onConfirm();
              cancel();
            }}
          />
        ) : null}
        <div className="body">
          { typeof confirmation === 'function' ? confirmation(cancel, this.onCheck, isChecked, this.onInputChange, inputValue) : confirmation }
        </div>

        <div className="buttons">
          {!options.hideCancel ? <button className="btn btn-secondary" onClick={() => cancel()}>{options.cancelText || 'Cancel'}</button> : null }
          <button
            className={`btn ${confirmButtonClass || ''}`}
            disabled={withCheckbox && !isChecked}
            onClick={() => {
              onConfirm && onConfirm();
              proceed({
                inputValue,
                isChecked,
              });
            }}
          >
            {options.confirmText || 'Agree'}
          </button>
        </div>
      </Modal>
    );
  }
}

ConfirmDialog.propTypes = {
  show: PropTypes.bool, // from confirmable. indicates if the dialog is shown or not.
  proceed: PropTypes.func, // from confirmable. call to close the dialog with promise resolved.
  cancel: PropTypes.func, // from confirmable. call to close the dialog with promise rejected.
  dismiss: PropTypes.func, // from confirmable. call to only close the dialog.
  confirmation: PropTypes.any, // arguments of your confirm function
  options: PropTypes.object, // arguments of your confirm function
};

export default confirmable(ConfirmDialog);
