import * as PropTypes from 'prop-types';
import React from 'react';
import DataItem from '../../favorite/components/data_item';
import DataItemChangeable from '../../favorite/components/data_item_changeable';

export default function PlayerTeamInformation({
  t, player, position_options, onDataChange, isEdit, hideValue, canChangeTeam,
}) {
  return (
    <div className="player-information__team">
      <h4>Team</h4>
      <div className="content-row small-margin team-row">
        {
          canChangeTeam ? (
            <DataItemChangeable
              label={t('TEAM')}
              inputValue={{
                value: player.team_id,
                label: player.team_name,
              }}
              type="team_select"
              value={player.team_name}
              image={player.team_img}
              name="team_id"
              onChange={onDataChange}
              isChanging={!!isEdit}
            />
          ) : (
            <DataItem label={t('TEAM')} value={player.team_name} image={player.team_img} />
          )
        }

      </div>
      <div
        className="content-row small-margin align-end"
        style={{
          position: 'relative',
          zIndex: 3,
        }}
      >
        <div className={`${hideValue ? 'content-col-60' : 'content-col-66'}`}>
          <DataItem label={t('LEAGUE')} value={player.team_league_name} />
        </div>
        <div className={`${hideValue ? 'content-col-40' : 'content-col-33'}`}>
          <DataItemChangeable
            label={t('POSITION')}
            value={player.position}
            type="select"
            options={position_options}
            inputValue={{
              value: player.position_id,
              label: player.position,
            }}
            name="position_id"
            onChange={onDataChange}
            isChanging={!!isEdit}
          />
        </div>
      </div>
      <div className="content-row small-margin align-end">
        <div className={`${hideValue ? 'content-col-60' : 'content-col-33'}`}>
          <DataItemChangeable
            label={t('IN_TEAM_SINCE')}
            value={player.joinDate}
            inputValue={player.joinDateRaw}
            name="join_date"
            type="date"
            onChange={onDataChange}
            isChanging={!!isEdit}
          />
        </div>
        <div className={`${hideValue ? 'content-col-40' : 'content-col-33'}`}>
          <DataItemChangeable
            label={t('CONTRACT')}
            value={player.contract_ages}
            inputValue={player.contract}
            type="date"
            name="contract_until"
            onChange={onDataChange}
            isChanging={!!isEdit}
          />
        </div>
        {hideValue ? null : (
          <div className="content-col-33">
            <DataItemChangeable
              label={t('MARKETVALUE')}
              value={player.marketvalue}
              inputValue={player.marketvalue}
              name="marketvalue"
              type="number"
              onChange={onDataChange}
              isChanging={!!isEdit}
            />
          </div>
        )}
      </div>
    </div>
  );
}

PlayerTeamInformation.propTypes = {
  t: PropTypes.func,
  player: PropTypes.object,
  position_options: PropTypes.array,
  onDataChange: PropTypes.func,
  isEdit: PropTypes.bool,
  noValue: PropTypes.bool,
  canChangeTeam: PropTypes.bool,
};

PlayerTeamInformation.defaultProps = {
  canChangeTeam: true,
};
