import axios from 'axios';

import { ROOT_URL } from '.';

export const FETCH_PLANS = 'FETCH_PLANS';

export function getPlans(currency) {
  let request = null;

  if (!currency) {
    request = axios.get(`${ROOT_URL}/plans`);
  } else {
    request = axios.get(`${ROOT_URL}/all-plans${currency ? `?currency=${currency}` : ''}`);
  }

  return {
    type: FETCH_PLANS,
    payload: request,
  };
}
