import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { browserHistory } from 'react-router';

import CustomerSelect from '../../../ui/customer-select';

import './customers-header.scss';

@withTranslation()
class CustomersHeader extends Component {
  render() {
    const { t } = this.props;

    return (
      <div className="customers__header">
        <div className="customers__search">
          <CustomerSelect
            onChange={(v) => {
              if (v.type !== 'USER') {
                browserHistory.push(`/admin/customers/${v.value}`);
              } else {
                browserHistory.push(`/admin/customers/${v.groupId}?user=${v.value}`);
              }
            }}
          />
        </div>
        <div className="customers__create">
          <button
            className="btn"
            onClick={() => {
              browserHistory.push('/admin/customers/new');
            }}
          >
            {t('CREATE_CUSTOMER')}
          </button>
        </div>
      </div>
    );
  }
}

CustomersHeader.propTypes = {
  t: PropTypes.func,
};

export default CustomersHeader;
