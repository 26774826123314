import '../styles/styles.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router, browserHistory } from 'react-router';
import axios from 'axios';
import localforage from 'localforage';

import { I18nextProvider } from 'react-i18next';
import memoryStorageDriver from 'localforage-memoryStorageDriver';
import i18n from './i18n.js';
import store from './store';

import routes from './routes';

axios.defaults.withCredentials = true;

const rootElement = document.getElementById('app');

const isiPad = navigator.userAgent.match(/iPad/i) != null;

window.isiPad = isiPad;

if (isiPad) {
  localforage.defineDriver(memoryStorageDriver).then(() => {
    localforage.setDriver([
      localforage.WEBSQL,
      localforage.INDEXEDDB,
      localforage.LOCALSTORAGE,
      memoryStorageDriver._driver,
    ]);
  });
}

let ComponentEl;

window.online = navigator.onLine;
window.genderContext = 'male';

window.addEventListener('online', () => {
  window.online = true;
});
window.addEventListener('offline', () => {
  window.online = false;
});

ComponentEl = (
  <div>
    <Router history={browserHistory} routes={routes} />
  </div>
);

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      {ComponentEl}
    </Provider>
  </I18nextProvider>,
  rootElement,
);
