import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router';
import Icon from '../../Icon';

@withTranslation()
@connect((state) => {
  return {
    user: state.auth.user,
    subscription: state.subscription,
  };
}, {})
class RestartSubscriptionMessage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      minimized: false,
    };
  }

  render() {
    const { user, t } = this.props;
    const { minimized } = this.state;

    return (
      <div className={`restart-subscription main-message ${minimized ? 'minimized' : ''}`}>
        {!minimized ? (
          <div>
            <span className="close" onClick={() => { this.setState({ minimized: true }); }}>
              <Icon name="close" />
            </span>
            <h2>{t('BILLING_ADDRESS_WARNING')}</h2>
            {user.role.tag !== 'user' ? (
              <div>
                <h5>{t('BILLING_ADDRESS_WARNING_MESSAGE')}</h5>
                <p className="p__btn">
                  <Link to="/settings/billing/address?reason=address" className="btn">{t('EDIT_BILLING_ADDRESS')}</Link>
                </p>
              </div>
            ) : null}
          </div>
        ) : (
          <div>
            {
              user.role.tag !== 'user' ? (
                <span>
                  {t('BILLING_ADDRESS_WARNING')}
                  {' '}
                  <Link to="/settings/billing/address?reason=address">{t('EDIT_BILLING_ADDRESS')}</Link>
                </span>
              ) : null
            }
          </div>
        )}
      </div>
    );
  }
}

RestartSubscriptionMessage.propTypes = {
  user: PropTypes.object,
  subscription: PropTypes.object,
  t: PropTypes.func,
};

export default RestartSubscriptionMessage;
