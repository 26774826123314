import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import moment from 'moment';
import { browserHistory, Link } from 'react-router';
import ReactTooltip from 'react-tooltip';

import './watchlists.scss';
import { canAddWatchlists, canUseWatchlists } from '../../helpers/limits';
import Icon from '../Icon';
import WatchlistEditForm from '../watchlists/watchlist-edit-form';
import { ALPHABET } from '../../constants';

import { createWatchlist } from '../../actions/watchlists';
import { updateWatchlistsSorts } from '../../actions/auth';
import { updateWatchlistsLimits } from '../../actions/subscription';

import DemoWatchlistMan from '../../../demo_watchlist.json';
import DemoWatchlistWomen from '../../../demo_watchlist_women.json';
import DemoMessage from '../ui/demo-message';

import WatchlistPane from './watchlist-pane';
import eventTracker from '../../helpers/event-tracker';

@withTranslation()
@connect((state) => {
  return {
    user: state.auth.user,
    users: state.users,
    colors: state.colors,
    icons: state.icons,
    subscription: state.subscription,
  };
}, {
  createWatchlist, updateWatchlistsSorts, updateWatchlistsLimits,
})
class Watchlists extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showAdd: false,
    };

    this.onCreate = this.onCreate.bind(this);
  }

  componentDidUpdate() {
    ReactTooltip.rebuild();
  }

  onCreate(name, description, color_id, icon_id) {
    const { subscription, user } = this.props;
    const limits = subscription.watchlists;

    if (!name) {
      for (let i = 0; i < ALPHABET.length; i++) {
        name = `Watchlist ${ALPHABET[i]}`;
        const index = this.props.watchlists.list.findIndex((t) => t.name === name);
        if (index === -1) {
          break;
        }
      }
    }

    this.props.createWatchlist(name, description, color_id, icon_id).then((result) => {
      eventTracker.trackEvent('homepage', 'Watchlist created');
      const { _id } = result.payload.data;
      const watchlistsSort = user.watchlistsSort || {};
      this.props.updateWatchlistsSorts(user._id, { ...watchlistsSort, [_id]: 0 });
      this.props.updateWatchlistsLimits({ current: ++limits.current });
      browserHistory.push(`/watchlists/${_id}`);
    });
  }

  renderWatchlist(watchlist) {
    const {
      t, users, user,
    } = this.props;

    const day = moment(watchlist.updatedAt).calendar(null, {
      sameDay: `[${t('TODAY')}]`,
      nextDay: `[${t('TOMORROW')}]`,
      nextWeek: 'dddd',
      lastDay: `[${t('YESTERDAY')}]`,
      lastWeek: 'DD/MM/YYYY',
      sameElse: 'DD/MM/YYYY',
    });

    let firstName = '';
    let lastName = '';
    const wlUserId = watchlist.edited_by || watchlist.user_id;

    if (users.data) {
      if (+wlUserId !== +user._id) {
        const user = users.data.find((u) => +u._id === +wlUserId) || {};
        if (user.firstName) firstName = user.firstName;
        if (user.lastName) lastName = user.lastName;
      } else {
        if (user.firstName) firstName = user.firstName;
        if (user.lastName) lastName = user.lastName;
      }
    }

    const initials = (firstName[0] || '') + (lastName[0] || '');
    let by = +wlUserId === +user._id ? 'ME' : initials.toUpperCase();
    const isDemo = watchlist._id === 'demo';
    by = isDemo ? 'Scoutpad' : by;

    return (
      <WatchlistPane
        key={watchlist._id}
        onClick={() => {
          eventTracker.trackEvent('homepage', 'Watchlist clicked');
          browserHistory.push(`/watchlists/${watchlist._id}`);
        }}
        watchlist={watchlist}
        meta={t('EDITED_WHEN_BY', { when: day, by })}
      />
    );
  }

  render() {
    const {
      watchlists, subscription, t, user,
    } = this.props;

    let list = [...watchlists];
    let isDemo = false;

    if (!list.length) {
      const DemoWatchlist = window.genderContext === 'female' ? DemoWatchlistWomen : DemoWatchlistMan;
      list = [DemoWatchlist];
      isDemo = true;
    }

    let showAddButton = true;
    let showUpgradeButton = false;
    let showUpgradeNotify = false;

    if (list.length > 5 || !subscription.plan || !canUseWatchlists(subscription) || !canAddWatchlists(subscription)) {
      showAddButton = false;
    }

    if (!canUseWatchlists(subscription) || !canAddWatchlists(subscription)) {
      showUpgradeButton = true;
      showUpgradeNotify = true;
      if (watchlists.length >= 4) {
        showUpgradeNotify = false;
      }
    }

    return (
      <div className={`homepage__watchlists ${!watchlists.length ? 'new' : null}`}>
        {list.map((wl) => this.renderWatchlist(wl))}
        { showAddButton
          ? (
            <button
              className="add-watchlist homepage__interactive-card"
              onClick={() => {
                eventTracker.trackEvent('homepage', 'Watchlist add clicked');
                this.setState({ showAdd: true });
              }}
            >
              <div className="homepage__watchlist-icon-container">
                <Icon name="add" />
              </div>
              <span className="add-watchlist__text">{t('CREATE_WATCHLIST')}</span>
            </button>
          ) : null}
        { showUpgradeButton ? (
          <Link
            onClick={() => {
              eventTracker.trackEvent('homepage', 'Watchlist clicked upgrade button');
            }}
            to="/settings/billing/"
            className="upgrade-plan homepage__interactive-card"
          >
            <div className="homepage__watchlist-icon-container">
              <Icon name="upgrade" />
            </div>
            <span className="add-watchlist__text">{t('UPGRADE_TO_PRO')}</span>
          </Link>
        ) : null}
        {isDemo && !showUpgradeButton ? <DemoMessage text={t('HOME_DEMO_WATCHLIST', { context: user.selectedGender })} arrowPosition="top-left" /> : null}
        { showUpgradeButton && showUpgradeNotify ? <DemoMessage text={t('HOME_UPGRADE_PLAN_WATCHLIST', { context: window.genderContext })} arrowPosition="top-left" /> : null }
        { this.state.showAdd ? <WatchlistEditForm onCreateClick={this.onCreate} onCloseClick={() => this.setState({ showAdd: false })} /> : null }
        <ReactTooltip
          id="Watchlist"
          getContent={(dataTip) => {
            const parsedTip = JSON.parse(dataTip);
            if (!parsedTip) return;
            return (
              <span>
                <strong>{parsedTip.name}</strong>
                <br />
                {parsedTip.description}
              </span>
            );
          }}
        />
      </div>
    );
  }
}

Watchlists.propTypes = {
  t: PropTypes.func,
  users: PropTypes.object,
  user: PropTypes.object,
  watchlists: PropTypes.array,
  icons: PropTypes.object,
  colors: PropTypes.object,
  subscription: PropTypes.object,
  createWatchlist: PropTypes.func,
  updateWatchlistsSorts: PropTypes.func,
  updateWatchlistsLimits: PropTypes.func,
};

export default Watchlists;
