import React, { Component } from 'react';
import { connect } from 'react-redux';

import './help.scss';

import { withTranslation } from 'react-i18next';
import SubNav from '../sub-nav';
import FAQ from './faq';
import Shortcuts from './shortcuts';
import Terms from './terms';
import Videos from './videos';

import {
  Dashboard, StartMatch, EvaluatePlayers, DetailsPage, SquadManagement, NiceToKnow,
} from './videos/subpages';

@withTranslation()
@connect((state, own) => ({
  page: own.params.section,
  subPage: own.params.subsection,
}), { })
export default class Help extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lang: props.i18n.language,
    };

    this.changeLanguage = this.changeLanguage.bind(this);
  }

  changeLanguage(lang) {
    this.setState({ lang });
  }

  render() {
    const { lang } = this.state;
    const { page, subPage, t } = this.props;

    const sections = [
      {
        name: t('Videos'),
        link: 'videos',
        component: <Videos lang={lang} />,
        render: true,
        subpages: [
          {
            name: t('VIDEO_DASHBOARD'),
            link: 'videos/dashboard',
            component: <Dashboard lang={lang} />,
            render: true,
          },
          {
            name: t('VIDEO_START_MATCH'),
            link: 'videos/start-match',
            component: <StartMatch lang={lang} />,
            render: true,
          },
          {
            name: t('VIDEO_EVALUATE_PLAYER', { context: window.genderContext }),
            link: 'videos/evaluate-players',
            component: <EvaluatePlayers lang={lang} />,
            render: true,
          },
          {
            name: t('VIDEO_DETAILS_PAGE'),
            link: 'videos/details-page',
            component: <DetailsPage lang={lang} />,
            render: true,
          },
          {
            name: t('VIDEO_SQUAD_MANAGEMENT'),
            link: 'videos/squad-management',
            component: <SquadManagement lang={lang} />,
            render: true,
          },
          /* {
            name: t('VIDEO_NICE_TO_KNOW'),
            link: 'videos/nice-to-know',
            component: <NiceToKnow lang={lang} />,
            render: true,
          }, */
        ],
      },
      {
        name: t('FAQ'),
        link: 'faq',
        component: <FAQ lang={lang} />,
        render: true,
      },
      {
        name: t('MENU_SHORTCUTS'),
        link: 'shortcuts',
        component: <Shortcuts lang={lang} />,
        render: true,
      },
      {
        name: lang === 'en' ? 'Terms & Privacy' : lang === 'de' ? 'AGB & Datenschutz' : 'Termos & Privacidade',
        link: 'terms',
        component: <Terms lang={lang} />,
        render: true,
      },
    ];

    return (
      <div className="help">
        <SubNav title="Help" name="help" list={sections} current={page} subCurrent={subPage} />
      </div>
    );
  }
}
