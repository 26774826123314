import _mapValues from 'lodash/mapValues';

export const normalizeValuesForFilterConstructor = (initialValues) => {
	return _mapValues(initialValues, (value, key) => {
		if (!value) return value;

		switch (key) {
			case 'age':
			case 'final_rating': {
				return {
					dbValue: { [key]: [value.min, value.max] },
					inputValue: [value.min, value.max]
				}
			}
			case 'position': {
				return {
					dbValue: { [key]: value.value },
					inputValue: value.value
				}
			}
			default: {
				return {
					dbValue: { [key]: value },
					inputValue: value
				}
			}
		}
	})
}