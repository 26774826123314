import React, { Component } from 'react';
import axios from 'axios';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';

import PropTypes from 'prop-types';
import { theme, style } from '../select_props';
import { ROOT_URL } from '../../../actions';
import Option from './team-option';
import SingleValue from './team-value';
import DropdownIndicator from '../select/components/dropdown-indicator';

@withTranslation()
export default class TeamSelect extends Component {
  render() {
    const {
      input, t, i18n, league, onCreate, exclude, ...rest
    } = this.props;
    const lng = i18n.language;

    return (
      <AsyncCreatableSelect
        key={league}
        className={rest.meta.touched && rest.meta.error ? 'no-valid' : ''}
        {...rest}
        name={input.name}
        styles={style}
        cacheOptions
        clearable={false}
        maxHeight={200}
        defaultOptions
        placeholder={t('TYPE_TO_SEARCH_TEAM')}
        components={{ Option, SingleValue, DropdownIndicator }}
        menuShouldScrollIntoView
        hideSelectedOptions
        value={input.value}
        loadOptions={(inputValue) => {
          return axios.get(`${ROOT_URL}/teams/search`, {
            params: {
              needle: inputValue,
              league: league || '',
            },
          }).then((result) => {
            let list = result.data.map((team) => ({ value: team.id, label: _.get(team, `name_${lng}`, team.name) }));
            if (Array.isArray(exclude)) {
              list = list.filter((t) => exclude.findIndex((e) => e === t.value) === -1);
            }
            return list;
          }).catch((err) => {
            console.error(err.message);
            return [];
          });
        }}
        onChange={(value) => {
          rest.changeCallback && rest.changeCallback(value);
          input.onChange(value);
        }}
        formatCreateLabel={(value) => t('CREATE_TEAM', { value })}
        theme={theme}
        onCreateOption={(value) => { onCreate(value, input.name, league); }}
      />
    );
  }
}

TeamSelect.propTypes = {
  league: PropTypes.string,
  input: PropTypes.object,
  t: PropTypes.func,
  i18n: PropTypes.object,
  onCreate: PropTypes.func,
  exclude: PropTypes.number,
};
