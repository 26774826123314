import i18n from 'i18next';
import Http from 'i18next-http-backend';

i18n
  .use(Http)
  .init({
    fallbackLng: 'en',
    lng: 'en',
    debug: false,
    react: {
      defaultTransParent: true,
      wait: true,
      useSuspense: false, //   <---- this will do the magic
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
