import { FETCH_FORMATIONS } from '../actions/index';

const INITIAL_STATE = {};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_FORMATIONS:
      return action.payload.formations;
    default:
      return state;
  }
}
