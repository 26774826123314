import axios from 'axios';
import { ROOT_URL } from '.';
import eventTracker, { EVENTS } from '../helpers/event-tracker';

export const FETCH_GROUP = 'FETCH_GROUP';
export const SAVE_GROUP_DATA = 'SAVE_GROUP_DATA';
export const CHANGE_GROUP_PLAN = 'CHANGE_GROUP_PLAN';
export const ADD_ADDITIONAL_PLAN_LICENCE = 'ADD_ADDITIONAL_PLAN_LICENCE';
export const GET_GROUP_INVOICES = 'GET_GROUP_INVOICES';
export const CANCEL_GROUP_SUBSCRIPTION = 'CANCEL_GROUP_SUBSCRIPTION';
export const FETCH_CURRENT_PLAN = 'FETCH_CURRENT_PLAN';
export const ADD_COUPON = 'ADD_COUPON';
export const ADD_SOURCE = 'ADD_SOURCE';
export const FETCH_SETUP_INTENT_SECRET = 'FETCH_SETUP_INTENT_SECRET';
export const ADD_PAYMENT_METHOD_TO_CUSTOMER = 'ADD_PAYMENT_METHOD_TO_CUSTOMER';
export const FETCH_GROUP_COUPONS = 'FETCH_GROUP_COUPONS';
export const USE_GROUP_CREDITS = 'USE_GROUP_CREDITS';
export const CALCULATE_USE_CREDITS = 'CALCULATE_USE_CREDITS';
export const FETCH_CREDITS_AND_BONUS_MILESTONES = 'FETCH_CREDITS_AND_BONUS_MILESTONES';

export function fetchCreditsAndBonusMilestones() {
  const request = axios.get(`${ROOT_URL}/group/credits`);

  return {
    type: FETCH_CREDITS_AND_BONUS_MILESTONES,
    payload: request,
  };
}

export function getGroup() {
  const request = axios.get(`${ROOT_URL}/group/`);
  return {
    type: FETCH_GROUP,
    payload: request,
  };
}

export function saveGroupData(values) {
  const request = axios.post(`${ROOT_URL}/group/`, values);

  eventTracker.trackEvent('Billing', 'Update billing information');

  return {
    type: SAVE_GROUP_DATA,
    payload: request,
  };
}

export function changeGroupPlan(planId, period) {
  const request = axios.post(`${ROOT_URL}/group/plan`, { planId, period });

  eventTracker.trackEvent('Billing', 'Change plan');

  return {
    type: CHANGE_GROUP_PLAN,
    payload: request,
  };
}

export function getGroupInvoices() {
  const request = axios.get(`${ROOT_URL}/group/invoices`);

  return {
    type: GET_GROUP_INVOICES,
    payload: request,
  };
}

export function cancelSubscription() {
  const request = axios.get(`${ROOT_URL}/group/cancel`);

  eventTracker.trackEvent('Billing', 'Cancel subscription');

  return {
    type: CANCEL_GROUP_SUBSCRIPTION,
    payload: request,
  };
}

export function bookAdditionalPersonLicence() {
  const request = axios.get(`${ROOT_URL}/group/add-license`);

  eventTracker.trackEvent('Billing', 'Book additional license');

  return {
    type: ADD_ADDITIONAL_PLAN_LICENCE,
    payload: request,
  };
}

export function submitCoupon(coupon) {
  const request = axios.post(`${ROOT_URL}/group/add-coupon`, { coupon });

  eventTracker.trackEvent('Billing', 'Add coupon');

  return {
    type: ADD_COUPON,
    payload: request,
  };
}

export function addSourceToCustomer(token) {
  const request = axios.post(`${ROOT_URL}/group/add-source`, { token });

  return {
    type: ADD_SOURCE,
    payload: request,
  };
}

export function fetchSetupIntent() {
  const request = axios.get(`${ROOT_URL}/group/setup-intent`);

  return {
    type: FETCH_SETUP_INTENT_SECRET,
    payload: request,
  };
}

export function addPaymentMethodToCustomer(intent) {
  const request = axios.post(`${ROOT_URL}/group/add-payment-method`, { intent });

  eventTracker.trackEvent('Billing', 'Add payment method');

  return {
    type: ADD_PAYMENT_METHOD_TO_CUSTOMER,
    payload: request,
  };
}

export function fetchCoupons() {
  const request = axios.get(`${ROOT_URL}/group/coupons`);

  return {
    type: FETCH_GROUP_COUPONS,
    payload: request,
  };
}

export function useCredits() {
  const request = axios.get(`${ROOT_URL}/group/use-credits`);

  return {
    type: USE_GROUP_CREDITS,
    payload: request,
  };
}

export function calculateUseCredits() {
  const request = axios.get(`${ROOT_URL}/group/calculate-use-credits`);
  return {
    type: CALCULATE_USE_CREDITS,
    payload: request,
  };
}
