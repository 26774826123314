import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

import { withTranslation } from 'react-i18next';

import PlayersList from './components/players_list';
import DemoPlayerMan from '../../../gnabry.json';
import DemoWomenPlayer from '../../../leupolz.json';
import PlayersInfiniteLoader from './components/players-infinite-loader';

import { canAddWatchlist } from '../../helpers/limits';

import './favorites.scss';

import { changeFavoritesFilter, fetchFavoritesInfo, removeFromFavorites } from '../../actions/favorites';

import AddPlayer from './components/add-player';
import Icon from '../Icon';
import DemoMessage from '../ui/demo-message';
import UpgradeButton from '../ui/upgrade-button';
import FavoritesFilter from './favorites-filter';
import PlayerSearch from './components/player-search';

@withTranslation()
@connect((state) => ({
  favorites: state.favorites,
  teams: state.teams,
  positions: state.positions,
  matches: state.matches,
  user: state.auth.user,
  users: state.users,
  subscription: state.subscription,
}), {
  changeFavoritesFilter, fetchFavoritesInfo, removeFromFavorites,
})

export default class Favorites extends Component {
  static propTypes = {
    favorites: PropTypes.object,
    positions: PropTypes.object,
    teams: PropTypes.object,
    fetchFavoritesInfo: PropTypes.func,
    removeFromFavorites: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      showAdd: false,
    };

    this.renderFavoritePlayers = this.renderFavoritePlayers.bind(this);
    this.unFavorite = this.unFavorite.bind(this);
    this.openAddPlayer = this.openAddPlayer.bind(this);
    this.closeAddPlayer = this.closeAddPlayer.bind(this);
  }

  unFavorite(event, favorite_id) {
    const { t } = this.props;
    event.preventDefault();

    if (window.confirm(t('DO_YOU_REALLY_WANT_TO_REMOVE_FAVORITE', { context: window.genderContext }))) {
      this.props.removeFromFavorites(favorite_id);
    }
  }

  renderDemoPlayer() {
    const {
      t, teams, i18n, positions,
    } = this.props;
    const lng = i18n.language;

    const DemoPlayer = window.genderContext === 'female' ? DemoWomenPlayer : DemoPlayerMan;

    const player = { ...DemoPlayer.player };
    const fav = {
      category: 3,
    };

    const birth_date = new Date(player.birth_date);

    player.birth_date_raw = moment().diff(birth_date, 'years');

    player.join_date_raw = player.join_date && player.join_date !== '1900-01-01' ? +moment(player.join_date, 'YYYY-MM-DD').unix() : 0;
    player.contract_until_raw = player.contract_until && player.contract_until !== '1900-01-01' ? +moment(player.contract_until, 'YYYY-MM-DD').unix() : 0;

    player.team_name = _.get(teams, `[${player.team_id}].name_${lng}`, _.get(teams, `[${player.team_id}].name`, '-'));
    player.position_name = _.get(positions, `[${player.position_id}].desc_short_${lng}`, _.get(positions, `[${player.position_id}].desc_short`, '-'));
    player.birth_date = moment().diff(birth_date, 'years');
    player.country = player.country || '-';
    player.join_date = player.join_date && moment(player.join_date, 'YYYY-MM-DD').isValid() && moment(player.join_date, 'YYYY-MM-DD') > moment('1970-01-01', 'YYYY-MM-DD') ? moment(player.join_date, 'YYYY-MM-DD').format('DD/MM/YYYY') : '–';
    player.contract_until = player.contract_until && moment(player.contract_until, 'YYYY-MM-DD').isValid() && moment(player.contract_until, 'YYYY-MM-DD') > moment('1970-01-01', 'YYYY-MM-DD') ? moment(player.contract_until, 'YYYY-MM-DD').format('DD/MM/YYYY') : '–';
    player.groupMemberFavorite = fav.group_member_favorite;
    player.favorite_id = fav._id;
    player.meta = { ...fav };
    player.sort_name = `${player.first_name} ${player.last_name}`;
    player.sort = fav.sort;
    player.favorite_id = fav._id;
    player.fav = fav;
    player.category = fav.category;
    player.final_rating = 4.55;
    player.final_rating_formatted = '4.55';

    return (
      <div>
        <PlayersList players={[player]} />
        <div style={{ marginTop: 80 }}>
          <DemoMessage text={t('DEMO_PLAYER_MESSAGE', { context: window.genderContext })} arrowPosition="top-left" />
        </div>
      </div>
    );
  }

  renderFavoritePlayers() {
    const {
      favorites, positions, teams, lng, user,
    } = this.props;
    const { list, info, filter } = favorites;

    if (!_.size(favorites.info) || !_.size(favorites.list)) return null;

    let userSortedList = [...list];

    userSortedList = userSortedList
      .filter((favorite) => (info[favorite.player_id])).map((f, i) => {
        f = { ...f };
        const player = { ...info[f.player_id] };
        f.sort_name = `${player.last_name}, ${player.first_name}`;
        return f;
      })
      .sort((a, b) => a.sort_name > b.sort_name ? 1 : a.sort_name < b.sort_name ? -1 : 0);

    let sortedList = userSortedList.map((fav) => {
      const player = { ...info[fav.player_id] };

      player.birth_date_raw = player.birth_date && player.birth_date !== '1900-01-01' ? moment().diff(player.birth_date, 'years') : 0;
      player.join_date_raw = player.join_date && player.join_date !== '1900-01-01' ? +moment(player.join_date, 'YYYY-MM-DD').unix() : 0;
      player.contract_until_raw = player.contract_until && player.contract_until !== '1900-01-01' ? +moment(player.contract_until, 'YYYY-MM-DD').unix() : 0;

      player.team_name = _.get(teams, `[${player.team_id}].name_${lng}`, _.get(teams, `[${player.team_id}].name`, '-'));
      player.position_name = _.get(positions, `[${player.position_id}].desc_short_${lng}`, _.get(positions, `[${player.position_id}].desc_short`, '-'));
      player.birth_date = player.birth_date && player.birth_date !== '1900-01-01' ? moment().diff(player.birth_date, 'years') : '–';
      player.country = player.country || '-';
      player.join_date = player.join_date && moment(player.join_date, 'YYYY-MM-DD').isValid() && moment(player.join_date, 'YYYY-MM-DD') > moment('1970-01-01', 'YYYY-MM-DD') ? moment(player.join_date, 'YYYY-MM-DD').format('DD/MM/YYYY') : '–';
      player.contract_until = player.contract_until && moment(player.contract_until, 'YYYY-MM-DD').isValid() && moment(player.contract_until, 'YYYY-MM-DD') > moment('1970-01-01', 'YYYY-MM-DD') ? moment(player.contract_until, 'YYYY-MM-DD').format('DD/MM/YYYY') : '–';
      player.groupMemberFavorite = fav.group_member_favorite;
      player.favorite_id = fav._id;
      player.meta = { ...fav };
      player.sort_name = `${player.first_name} ${player.last_name}`;
      player.sort = fav.sort;
      player.favorite_id = fav._id;
      player.fav = fav;
      player.category = fav.category;
      player.final_rating = fav.final_rating;
      player.filtering_final_rating = fav.final_rating || 0;
      player.final_rating_formatted = player.final_rating === null || player.final_rating === undefined ? 'N/D' : player.final_rating.toFixed(2);

      return player;
    });

    if (user.role.tag !== 'user' && filter.list_user_id) {
      sortedList = sortedList.filter((p) => {
        const isInIndex = p?.meta?.users?.findIndex((id) => (filter.list_user_id === id)) !== -1;
        return isInIndex;
      });
    }

    if (filter.age) {
      sortedList = sortedList.filter((p) => {
        return !p.birth_date || p.birth_date === '–' || (p.birth_date >= filter.age.min && p.birth_date <= filter.age.max);
      });
    }

    if (filter.category) {
      sortedList = sortedList.filter((p) => {
        const category = p.category || 0;
        return filter.category.includes(category);
      });
    }

    if (filter.team && filter.team.value !== -1) {
      sortedList = sortedList.filter((p) => +p.team_id === +filter.team.value);
    }

    if (filter.position && filter.position.value !== -1) {
      sortedList = sortedList.filter((p) => +p.position_id === +filter.position.value);
    }

    if (filter.final_rating) {
      sortedList = sortedList.filter((p) => {
        return p.filtering_final_rating >= filter.final_rating.min && p.filtering_final_rating <= filter.final_rating.max;
      });
    }

    if (filter.name) {
      const regexp = new RegExp(filter.name, 'i');
      sortedList = sortedList.filter((p) => {
        return regexp.test(p.sort_name);
      });
    }

    return <PlayersInfiniteLoader players={sortedList} />;
  }

  openAddPlayer() {
    this.setState({ showAdd: true });
  }

  closeAddPlayer() {
    this.setState({ showAdd: false });
  }

  render() {
    const {
      user, favorites, t, subscription,
    } = this.props;
    const { filter } = favorites;

    return (
      <div className="favorites">
        <div className="container">
          <div className={`favorites-header  ${!_.size(favorites.list) ? 'empty' : ''}`}>
            <PlayerSearch
              defaultName={filter.name}
              search={(value) => {
                this.props.changeFavoritesFilter({ name: value });
              }}
            />

            <div className="favorites-list__filters">
              <FavoritesFilter />
              {
                canAddWatchlist(subscription) ? (
                  <span className="btn add-new-player" onClick={() => { this.openAddPlayer(); }}>
                    <Icon name="plus" className="create-icon" />
                    {' '}
                    {t('PLAYER', { context: window.genderContext })}
                  </span>
                ) : null
              }
            </div>
          </div>

          { // todo: change to favorites is Max helper
            !canAddWatchlist(subscription) && user.role.tag !== 'user'
              ? (
                <UpgradeButton message={t('UPGRADE_TO_PRO_PLAYERS', { context: window.genderContext })} classes="players" />
              ) : null
          }
          { !_.size(favorites.list) ? <div className="empty-message">{t('EMPTY_FAVORITES_LIST', { context: window.genderContext })}</div> : this.renderFavoritePlayers() }
          { !_.size(favorites.list) ? this.renderDemoPlayer() : null }
        </div>
        { this.state.showAdd ? (
          <div>
            <div className="add-player-fade" />
            <AddPlayer onClickOutside={() => { this.closeAddPlayer(); }} />
          </div>
        ) : null }
      </div>
    );
  }
}
