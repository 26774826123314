import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import InfiniteLoader from 'react-window-infinite-loader';
import { FixedSizeList } from 'react-window';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router';

import './players-infinite-loader.scss';

import { getPlayerImage, getTeamImage, getWatchlistCategory } from '../../../../helpers';

import Icon from '../../../Icon';
import DemoBadge from '../../../ui/demo-badge';
import PlayerImage from '../../../player-image';
import AddToFavorite from '../../../ui/add-to-favorites';
import PlayerMenu from '../../../ui/player-table-menu';

import { removeFromFavorites } from '../../../../actions/favorites';
import { updateFavoriteLimits } from '../../../../actions/subscription';
import NotePopup from '../../../ui/note-popup';

function PlayersInfiniteLoader(props) {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const bodyRef = useRef();
  const loaderRef = useRef();

  const [size, setSize] = useState([0, 0]);
  const [sort, changeSortState] = useState({ sortBy: 'last_name', sortDirection: 'asc' });

  const user = useSelector((state) => state.user);
  const subscription = useSelector((state) => state.subscription);

  const { players } = props;
  const sortedList = _.orderBy(players, sort.sortBy, sort.sortDirection);
  const itemCount = sortedList.length;

  const [playerForNote, setPlayerForNote] = useState(null);

  const resizeHandler = () => {
    const rect = bodyRef.current.getBoundingClientRect();
    setSize([rect?.width, rect?.height]);
  };

  useEffect(() => {
    resizeHandler();
    window.addEventListener('resize', resizeHandler);
    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  const loadMoreItems = () => {};

  function Player({ index, style }) {
    const player = sortedList[index];
    const {
      player_id, first_name, last_name, birth_date, country, team_name, team_id, contract_until, final_rating_formatted, position_name,
    } = player;

    const playerImage = getPlayerImage(player);
    const teamImage = getTeamImage(team_id);

    const isGroupPlayer = +user._id !== +_.get(player, 'meta.user_id', null);

    const actions = [
      {
        name: 'ADD_A_NOTE',
        action: () => {
          setPlayerForNote(player);
        },
        icon: <Icon name="note" className="list-icon add-note-icon" />,
      },
      {
        name: 'REMOVE_FROM_WATCHLIST',
        action: () => {
          if (confirm(isGroupPlayer ? t('DELETE_GROUP_MEMBER_FAVORITE', { context: window.genderContext }) : t('DELETE_FROM_FAVORITE', { context: window.genderContext }))) {
            dispatch(removeFromFavorites(player.favorite_id));
            dispatch(updateFavoriteLimits({ current: subscription.favorites.current - 1 }));
          }
        },
        icon: <Icon name="close" className="list-icon close-icon" />,
      },
    ];

    return (
      <div style={{ ...style, zIndex: 99999 - index }}>
        <table className="favorites-list">
          <tbody>
            <tr key={player_id}>
              <td className="col__last-name">
                { player_id === 'demo' ? <DemoBadge /> : null }
                <span className="favorite-player-name">
                  <Link to={`/players/${player_id}/summary`}>
                    <PlayerImage className="player-image" src={playerImage} />
                    <span className="favorite-player-name_last">{ last_name }</span>
                    {' '}
                    { first_name !== last_name ? first_name : null }
                  </Link>
                </span>
              </td>
              <td className="col__position-name">{ position_name }</td>
              <td className="col__birth-date">{ birth_date }</td>
              <td className="col__country">{ country }</td>
              <td className="col__team">
                {
                    team_id
                      ? (
                        <span className="favorite-player-team">
                          <PlayerImage className="team-image" src={teamImage} />
                          { team_name }
                        </span>
                      )
                      : '–'
                }
              </td>
              <td className="col__contract">{ contract_until }</td>
              <td className="col__final-rating">{final_rating_formatted}</td>
              <td className="col__rating">
                <AddToFavorite
                  match_id={null}
                  player={player}
                  added_message={t('WATCHLIST_CATEGORY_CHANGED')}
                >
                  <div className="favorite-selector">
                    <Icon name="favorite" />
                    {' '}
                    {getWatchlistCategory(player, t)}
                    {' '}
                    <Icon name="chevron_down" className="chevron-down" />
                  </div>
                </AddToFavorite>
              </td>
              <td className="col__menu">
                <PlayerMenu actionsList={actions} player={player} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  const changeSort = (field) => {
    let { sortDirection } = sort;
    let { sortBy } = sort;

    if (sortBy === field) {
      sortDirection = sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      sortBy = field;
      sortDirection = 'asc';
    }

    changeSortState({ sortBy, sortDirection });
    if (loaderRef.current) {
      loaderRef.current.resetloadMoreItemsCache();
    }
  };

  return (
    <div className="players-infinite-loader favorites-list__wrapper">
      <div className="players-infinite-loader__header">
        <table className="favorites-list">
          <thead>
            <tr>
              <th onClick={() => { changeSort('last_name'); }} className={`col__last-name ${sort.sortBy === 'last_name' ? 'sorted' : ''}`}>
                {t('PLAYER', { context: window.genderContext })}
                {' '}
                { sort.sortBy === 'last_name' ? <span className={`tri ${sort.sortDirection}`} /> : null }
              </th>
              <th onClick={() => { changeSort('position_name'); }} className={`col__position-name ${sort.sortBy === 'position_name' ? 'sorted' : ''}`}>
                {t('POSITION')}
                {' '}
                { sort.sortBy === 'position_name' ? <span className={`tri ${sort.sortDirection}`} /> : null }
              </th>
              <th onClick={() => { changeSort('birth_date_raw'); }} className={`col__birth-date ${sort.sortBy === 'birth_date_raw' ? 'sorted' : ''}`}>
                {t('AGE')}
                {' '}
                { sort.sortBy === 'birth_date_raw' ? <span className={`tri ${sort.sortDirection}`} /> : null }
              </th>
              <th onClick={() => { changeSort('country'); }} className={`col__country ${sort.sortBy === 'country' ? 'sorted' : ''}`}>
                {t('COUNTRY')}
                {' '}
                { sort.sortBy === 'country' ? <span className={`tri ${sort.sortDirection}`} /> : null }
              </th>
              <th onClick={() => { changeSort('team_name'); }} className={`col__team ${sort.sortBy === 'team_name' ? 'sorted' : ''}`}>
                {t('TEAM')}
                {' '}
                { sort.sortBy === 'team_name' ? <span className={`tri ${sort.sortDirection}`} /> : null }
              </th>
              <th onClick={() => { changeSort('contract_until_raw'); }} className={`col__contract ${sort.sortBy === 'contract_until_raw' ? 'sorted' : ''}`}>
                {t('CONTRACT_UNTIL')}
                {' '}
                { sort.sortBy === 'contract_until_raw' ? <span className={`tri ${sort.sortDirection}`} /> : null }
              </th>
              <th onClick={() => { changeSort('final_rating'); }} className={`col__final-rating ${sort.sortBy === 'final_rating' ? 'sorted' : ''}`}>
                {t('COL_OVERALL_RATING')}
                {' '}
                { sort.sortBy === 'final_rating' ? <span className={`tri ${sort.sortDirection}`} /> : null }
              </th>
              <th onClick={() => { changeSort('category'); }} className={`col__rating ${sort.sortBy === 'category' ? 'sorted' : ''}`}>
                {t('CATEGORY')}
                {' '}
                { sort.sortBy === 'category' ? <span className={`tri ${sort.sortDirection}`} /> : null }
              </th>
              <th className="col__menu" />
            </tr>
          </thead>
        </table>
      </div>
      <div className="players-infinite-loader__body" ref={bodyRef}>
        <InfiniteLoader
          ref={loaderRef}
          itemCount={players.length}
          loadMoreItems={loadMoreItems}
          isItemLoaded={(index) => index < players.length}
        >
          {({ onItemsRendered, ref }) => (
            <FixedSizeList
              itemCount={itemCount}
              onItemsRendered={onItemsRendered}
              ref={ref}
              width={size[0]}
              height={size[1]}
              itemSize={61}
            >
              { Player }
            </FixedSizeList>
          )}
        </InfiniteLoader>
        { playerForNote ? <NotePopup player={playerForNote} onCloseClick={() => { setPlayerForNote(null); }} /> : null }
      </div>
    </div>
  );
}

PlayersInfiniteLoader.propTypes = {
  players: PropTypes.array.isRequired,
};

export default PlayersInfiniteLoader;
