import React, { Component } from 'react';
import PlayerImage from '../../../player-image';
import PlayerControls from '../player-controls';
import Icon from '../../../Icon';

import './player-list.scss';
import { getRandomArbitrary, getTeamImage } from '../../../../helpers';
import ReactTooltip from 'react-tooltip';
import { withTranslation } from 'react-i18next';

@withTranslation()
export default class PlayersList extends Component {
  constructor(props) {
    super(props);

    this.renderPlayer = this.renderPlayer.bind(this);

    this.state = { mt: '0', timer: null };
  }

  componentDidUpdate(prevProps) {
    const { isFullList } = this.props;

    if (isFullList !== prevProps.isFullList) {
      let mt = '0';
      const list = this.refs.plist;
      const boundingRect = list.getBoundingClientRect();
      if (boundingRect.top < 0) {
        mt = `${boundingRect.top - 50}px`;
      }
      this.setState({ mt });
      ReactTooltip.rebuild();
    }
  }

  renderPlayer(player_id, i) {
    const {
      playersList, toggleMenu, position, menu_id, players, movePlayer, removePlayer, coords, team_id, t,
    } = this.props;

    const player = playersList[player_id];

    if (!player) return null;

    let data = player;

    if (data.information) {
      data = { ...data.information, ...data };
    }
    if (!data.last_name) {
      return null;
    }
    const teamImage = getTeamImage(data.team_id);

    const showControls = menu_id === player_id;

    let formattedFinalRating = null;
    if (team_id !== 'demo') {
      formattedFinalRating = isNaN(data.final_rating) || data.final_rating === null ? 'N/D' : data.final_rating.toFixed(2);
    } else {
      formattedFinalRating = getRandomArbitrary(0, 5);
      data.final_rating = formattedFinalRating;
      if (formattedFinalRating <= 2) {
        formattedFinalRating = 'N/D';
      } else {
        formattedFinalRating = formattedFinalRating.toFixed(2);
      }
    }

    return (
      <div className={`player-item ${i === 0 ? 'first' : ''} ${showControls ? 'active' : ''}`} key={`${player_id}__${i}`}>
        <div
          className={`player-name ${data.final_rating ? 'with-rating' : ''}`}
          onClick={() => {
            toggleMenu(position.id, player_id);
          }}
        >
          <PlayerImage className="team-image" src={teamImage} />
          {data.last_name}
          <span className="final-rating" data-tip={t('OVERALL_RATING')}>{formattedFinalRating}</span>
        </div>
        {showControls ? (
          <PlayerControls
            position_id={position.id}
            player_id={player_id}
            player_data={player}
            isFirst={i === 0}
            isLast={i === players.length - 1}
            movePlayer={movePlayer}
            removePlayer={removePlayer}
            toggleMenu={toggleMenu}
            coords={coords}
            team_id={team_id}
          />
        ) : null}
      </div>
    );
  }

  render() {
    const { players, toggleList } = this.props;
    const { isFullList } = this.props;

    const fPlayers = !isFullList ? players.slice(0, 3) : players;

    return (
      <div className="shadow-team__players-list" ref="plist" style={{ marginBottom: this.state.mt }}>
        { fPlayers.map(this.renderPlayer) }
        { players.length > 3 ? (
          <button
            className={`shadow-team__show-more-button ${isFullList ? 'isOpen' : ''}`}
            onClick={() => {
              toggleList(!isFullList);
            }}
          >
            <Icon name="chevron_down" />
          </button>
        ) : null}
        <ReactTooltip />
      </div>
    );
  }
}
