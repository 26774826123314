import {
  FETCH_SHADOW_TEAMS,
  UPDATE_SHADOW_TEAM,
  DELETE_SHADOW_TEAM,
  CREATE_SHADOW_TEAM,
  COPY_SHADOW_TEAM,
} from '../actions/shadow-teams';

import { AUTH_LOGOUT } from '../actions/auth';

import DEMO_TEAM_MAN from '../../demo_shadow.json';
import DEMO_TEAM_WOMEN from '../../demo_shadow_women.json';

const initState = [];

export default function (state = initState, action) {
  const DEMO_TEAM = window.genderContext === 'female' ? DEMO_TEAM_WOMEN : DEMO_TEAM_MAN;
  switch (action.type) {
    case FETCH_SHADOW_TEAMS:
      const teams_fetch = action.payload.data && action.payload.data.length ? action.payload.data.map((team) => {
        return team;
      }) : DEMO_TEAM;
      return teams_fetch;
    case UPDATE_SHADOW_TEAM:
      const team_update = action.payload.data;
      const { _id } = team_update;
      const index = state.findIndex((t) => t._id === _id);
      return [...state.slice(0, index), team_update, ...state.slice(index + 1)];
    case DELETE_SHADOW_TEAM:
      const teams_delete = action.payload.data && action.payload.data.length ? action.payload.data.map((team) => {
        return team;
      }) : DEMO_TEAM;
      return teams_delete;
    case COPY_SHADOW_TEAM:
      const team_copy = action.payload.data;
      return [...state, team_copy];
    case CREATE_SHADOW_TEAM:
      const created_team = action.payload.data;
      return [...state, created_team];
    case AUTH_LOGOUT:
      return initState;
    default:
      return state;
  }
}
