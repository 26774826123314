import React, { Component } from 'react';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import propTypes from 'prop-types';
import Select from '../select';
import { createLeague } from '../../../actions';

import './league-form.scss';

const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = 'Required';
  }

  if (!values.country) {
    errors.country = 'Required';
  }

  return errors;
};

const fieldInput = ({ input, ...rest }) => (
  <input
    {...input}
    name={rest.name}
    placeholder={rest.placeholder}
    className={`input ${rest.className} ${rest.meta.touched && rest.meta.error ? 'no-valid' : ''}`}
  />
);

@withTranslation()
@connect((state, props) => {
  const { countries } = state;

  const initialValues = {
    name: props.name,
    country: null,
  };

  return {
    teams: state.teams,
    countries,
    allCountries: state.countries.list,
    allCountriesById: state.countries.listById,
    form: 'createLeague',
    initialValues,
  };
}, { createLeague })
@reduxForm({ validate })
export default class LeagueForm extends Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      countries: [],
    };
  }

  componentDidMount() {
    const {
      allCountries, t, i18n,
    } = this.props;
    const lng = i18n.language;

    const countries = _.chain(allCountries).map((c) => ({
      value: c.id,
      label: _.get(c, `name_${lng}`, _.get(c, 'name', t('UNKNOWN_COUNTRY'))),
    })).value();

    this.setState({
      countries,
    });
  }

  onSubmit(values) {
    const { onCreate, createLeague } = this.props;

    createLeague({
      name: values.name,
      country_id: values.country.value,
    }).then((response) => {
      const league = _.get(response, 'payload.data[0].league', {});
      onCreate({
        value: league.id,
        label: league.name,
      });
    });
  }

  render() {
    const { t, handleSubmit, onCancel } = this.props;
    const { countries } = this.state;

    return (
      <div className="create-league-form">
        <form className="form" onSubmit={handleSubmit(this.onSubmit)}>
          <h2>{t('CREATE_LEAGUE')}</h2>
          <div className="row form-row">
            <div className="col-6">
              <label>{t('LEAGUE_NAME')}</label>
              <Field
                placeholder={t('ENTER_LEAGUE_NAME')}
                name="name"
                component={fieldInput}
              />
            </div>
            <div className="col-6">
              <label>{t('COUNTRY')}</label>
              <Field
                placeholder={t('SELECT_COUNTRY')}
                name="country"
                options={countries}
                component={Select}
              />
            </div>
          </div>
          <div className="row form-row submit-row">
            <button className="btn" onClick={onCancel}>{ t('CANCEL') }</button>
            <button className="btn">{ t('SUBMIT') }</button>
          </div>
        </form>
      </div>
    );
  }
}

LeagueForm.propTypes = {
  t: propTypes.func,
  onCancel: propTypes.func,
  onCreate: propTypes.func,
  handleSubmit: propTypes.func,
  createLeague: propTypes.func,
  countries: propTypes.array,
  allCountries: propTypes.object,
  i18n: propTypes.object,
  name: propTypes.string,
};
