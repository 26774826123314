import { createSelector } from 'reselect';

import _get from 'lodash/get';
import _orderBy from 'lodash/orderBy';
import _ from 'lodash';

const getLanguage = (state, props) => _get(props, 'i18n.language', 'en');
const getTranslator = (state, props) => _get(props, 'i18n.t', () => {});

export const getPositionOptions = createSelector(
	(state) => state.positions,
	(state) => state.favorites,
	getLanguage,
	getTranslator,
	(positions = {}, favorites, language, t) => {
		const { list, info } = favorites;

		const upperPositions = Object.values(positions).filter(({ upper_position }) => upper_position);
		const ordered = _orderBy(upperPositions, 'upper_rank', 'asc');

		const usedPositions = _.uniq(list.filter((p) => info[p.player_id]).map((p) => +info[p.player_id].position_id));

		let options = ordered.map((position) => ({
			value: +position.id,
			label: _get(position, `desc_long_${language}`, 'N/D')
		}));
		options = options.filter((pos_o) => (usedPositions.indexOf(+pos_o.value) !== -1));
		options.unshift({ label: t('ALL_POSITIONS'), value: -1 });

		return options;
});

export const getScoutOptions = createSelector(
	state => state.users.data,
	state => state.auth.user,
	getTranslator,
	(users, user, t) => {
		const scoutOptions = _(users)
			.filter((u) => user._id !== u._id)
			.orderBy('lastName')
			.map((u) => {
				return { value: u._id, label: `${u.firstName} ${u.lastName}` };
			})
			.value();

		scoutOptions.unshift({ value: null, label: t('ALL_SCOUTS') });
		scoutOptions.unshift({ value: '-', label: '-', isDisabled: true });
		scoutOptions.unshift({ value: user._id, label: t('MY_PLAYERS', { context: window.genderContext }) });

		return scoutOptions;
	}
);