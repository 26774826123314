import axios from 'axios';
import eventTracker, { EVENTS } from '../helpers/event-tracker';
import { ROOT_URL } from './index';
import { NotificationManager } from '../components/notifications';

export const SET_MATCH = 'SET_MATCH';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const CHANGE_TAB = 'CHANGE_TAB';
export const CHANGE_SUB_TAB = 'CHANGE_SUB_TAB';
export const CHANGE_TIMELINE = 'CHANGE_TIMELINE';
export const CHANGE_POSITION = 'CHANGE_POSITION';
export const CHANGE_SUBSTITUTION = 'CHANGE_SUBSTITUTION';
export const CHANGE_SNAPSHOT = 'CHANGE_SNAPSHOT';
export const MATCH_CHANGE_PLAYER_NOTE = 'MATCH_CHANGE_PLAYER_NOTE';
export const DEACTIVATE_PLAYER = 'DEACTIVATE_PLAYER';
export const CHANGE_SIZE = 'CHANGE_SIZE';
export const CHANGE_OUTER_CONTROL = 'CHANGE_OUTER_CONTROL';
export const CHANGE_INNER_CONTROL = 'CHANGE_INNER_CONTROL';
export const CHANGE_PLAYER_CONTROL = 'CHANGE_PLAYER_CONTROL';
export const CHANGE_INNER_AND_OUTER_CONTROL = 'CHANGE_INNER_AND_OUTER_CONTROL';
export const HIDE_CONTROLS = 'HIDE_CONTROLS';
export const CHANGE_TEAM_NOTES = 'CHANGE_TEAM_NOTES';
export const ADD_NEW_PLAYER = 'ADD_NEW_PLAYER';
export const ADD_PLAYER_DB = 'ADD_PLAYER_DB';
export const SWAP_PLAYERS = 'SWAP_PLAYERS';
export const ADD_PLAYER_TOGGLE = 'ADD_PLAYER_TOGGLE';
export const CHANGE_GOALS = 'CHANGE_GOALS';
export const CHANGE_JERSEY = 'CHANGE_JERSEY';
export const CREATE_SNAPSHOT = 'CREATE_SNAPSHOT';
export const REMOVE_SNAPSHOT = 'REMOVE_SNAPSHOT';
export const REMOVE_PLAYER_STORY = 'REMOVE_PLAYER_STORY';
export const CHANGE_FORMATION = 'CHANGE_FORMATION';
export const SELECT_FORMATION_POSITION = 'SELECT_FORMATION_POSITION';
export const CHANGE_FORMATION_POSITION = 'CHANGE_FORMATION_POSITION';
export const SELECT_SUBSTITUTION = 'SELECT_SUBSTITUTION';
export const SAVE_DEFAULT_LINEUP = 'SAVE_DEFAULT_LINEUP';
export const INCREASE_GOAL = 'INCREASE_GOAL';
export const DECREASE_GOAL = 'DECREASE_GOAL';
export const SET_GOALS_FOR_PERIOD = 'SET_GOALS_FOR_PERIOD';
export const SET_SUBSTITUTION_TIME = 'SET_SUBSTITUTION_TIME';

export function saveDefaultLineup(lineup, team_id, formation_id, side) {
  const request = axios.post(`${ROOT_URL}/lineup/`, { lineup, team_id, formation_id });

  NotificationManager.create({
    message: 'LINEUP_SAVED',
    id: 'SAVE_LINEUP',
    timeOut: 3000,
  });

  return {
    type: SAVE_DEFAULT_LINEUP,
    payload: request,
    lineup,
    team_id,
    formation_id,
    side,
  };
}

export function setMatch(match) {
  if (match) {
    eventTracker.trackEvent('Match', 'Open match');
  }

  return {
    type: SET_MATCH,
    match,
  };
}

export function setGoalsForPeriod(period) {
  return {
    type: SET_GOALS_FOR_PERIOD,
    period,
  };
}

export function increaseGoal(side, time) {
  return {
    type: INCREASE_GOAL,
    side,
    time,
  };
}

export function decreaseGoal(side, time) {
  return {
    type: DECREASE_GOAL,
    side,
    time,
  };
}

export function toggleSidebar(status) {
  return {
    type: TOGGLE_SIDEBAR,
    status,
  };
}

export function changeSize({ width, height, mediaQuery }) {
  return {
    type: CHANGE_SIZE,
    width,
    height,
    mediaQuery,
  };
}

export function changeTab(active) {
  return {
    type: CHANGE_TAB,
    active,
  };
}

export function changeSubTab(active) {
  return {
    type: CHANGE_SUB_TAB,
    active,
  };
}

export function changeSnapshot(active) {
  eventTracker.trackEvent('Match', 'Select snapshot');
  return {
    type: CHANGE_SNAPSHOT,
    active,
  };
}

export function changeTimeLine(time) {
  eventTracker.trackEvent('Match', 'Change timeline');
  return {
    type: CHANGE_TIMELINE,
    time,
  };
}

export function changePosition({ ...params }) {
  eventTracker.trackEvent('Match', 'Change position');
  return {
    type: CHANGE_POSITION,
    ...params,
  };
}

export function changeSubstitution({ ...params }) {
  eventTracker.trackEvent('Match', 'Change substitution');
  return {
    type: CHANGE_SUBSTITUTION,
    ...params,
  };
}

export function deactivatePlayer({ ...params }) {
  eventTracker.trackEvent('Match', 'Deactivate player');
  return {
    type: DEACTIVATE_PLAYER,
    ...params,
  };
}

export function changeOuterControl({ ...params }) {
  if (!Array.isArray(params.tags)) {
    eventTracker.trackEvent('Match', 'Evaluate player');
  }
  return {
    type: CHANGE_OUTER_CONTROL,
    ...params,
  };
}

export function changeInnerControl({ ...params }) {
  return {
    type: CHANGE_INNER_CONTROL,
    ...params,
  };
}

export function changePlayerControl({ ...params }) {
  return {
    type: CHANGE_PLAYER_CONTROL,
    ...params,
  };
}

export function hideControls() {
  return {
    type: HIDE_CONTROLS,
  };
}

export function changeInnerAndOuterControl({ ...params }) {
  eventTracker.trackEvent('Match', 'Evaluate player');
  return {
    type: CHANGE_INNER_AND_OUTER_CONTROL,
    ...params,
  };
}

export function changeTeamNotes({ ...params }) {
  eventTracker.trackEvent('Match', 'Change team notes');
  return {
    type: CHANGE_TEAM_NOTES,
    ...params,
  };
}

export function addNewPlayer({ ...params }, match) {
  eventTracker.trackEvent('Match', 'Add new player');
  return {
    type: ADD_NEW_PLAYER,
    ...params,
  };
}

export function addPlayerFromDB({ ...params }) {
  eventTracker.trackEvent('Match', 'Add new player from DB');
  return {
    type: ADD_PLAYER_DB,
    ...params,
  };
}

export function swapPlayers({ ...params }) {
  eventTracker.trackEvent('Match', 'Swap players');
  return {
    type: SWAP_PLAYERS,
    ...params,
  };
}

export function addPlayerToggle(status) {
  return {
    type: ADD_PLAYER_TOGGLE,
    status,
  };
}

export function changeGoals({ ...params }) {
  return {
    type: CHANGE_GOALS,
    ...params,
  };
}

export function changeJersey(number) {
  return {
    type: CHANGE_JERSEY,
    number,
  };
}

export function createSnapshot(snapshotName) {
  eventTracker.trackEvent('Match', 'Create snapshot');
  return {
    type: CREATE_SNAPSHOT,
    snapshotName,
  };
}

export function removeSnapshot(snapshotName) {
  eventTracker.trackEvent('Match', 'Remove snapshot');
  return {
    type: REMOVE_SNAPSHOT,
    snapshotName,
  };
}

export function removeStory(story) {
  eventTracker.trackEvent('Match', 'Remove record from match history');
  return {
    type: REMOVE_PLAYER_STORY,
    story,
  };
}

export function changeFormation({ ...params }) {
  eventTracker.trackEvent('Match', 'Change formation');
  return {
    type: CHANGE_FORMATION,
    ...params,
  };
}

export function changeFormationPosition({ ...params }) {
  return {
    type: CHANGE_FORMATION_POSITION,
    ...params,
  };
}

export function selectFormationPosition({ ...params }) {
  return {
    type: SELECT_FORMATION_POSITION,
    ...params,
  };
}

export function selectSubstitution({ ...params }) {
  eventTracker.trackEvent('Match', 'Select substitution');
  return {
    type: SELECT_SUBSTITUTION,
    ...params,
  };
}

export function changePlayerNote({ ...params }) {
  eventTracker.trackEvent('Match', 'Change player note');
  return {
    type: MATCH_CHANGE_PLAYER_NOTE,
    ...params,
  };
}
