import _ from 'lodash';
import moment from 'moment';
import {
  FETCH_FAVORITES_LIST,
  REMOVE_FROM_FAVORITE,
  FETCH_FAVORITES_INFO,
  FETCH_FAVORITE,
  RESET_FAVORITE,
  CHANGE_FAVORITE_FILTER,
  UPDATE_FAVORITE,
  ADD_TO_FAVORITE,
  ADD_PLAYER_INFO,
  UPDATE_PLAYER_IN_MATCH,
  CREATE_NEW_PLAYER_FROM_FAVORITES,
  FAVORITE_ADD_WATCHLIST,
  FAVORITE_REMOVE_WATCHLIST,
  FAVORITES_RESET_FILTER,
} from '../actions/favorites';

import {
  DELETE_PLAYER,
  MERGE_PLAYERS,
} from '../actions/entries';
import { storagePlayers } from '../storageMiddleware';

import DemoPlayerMan from '../../gnabry.json';
import DemoWomenPlayer from '../../leupolz.json';

const filterDefault = {
  name: '',
  user_id: false,
  list_user_id: null,
  age: {
    min: 1, max: 199, minValue: 1, maxValue: 199,
  },
  final_rating: { min: 0, max: 5 },
  position: undefined,
  category: [0, 1, 2, 3],
  team: undefined,
};

const INITIAL_STATE = {
  info: {},
  detail: {},
  list: [],
  fetched: false,
  filter: filterDefault,
  filterInitial: filterDefault,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case UPDATE_PLAYER_IN_MATCH: {
      const player_new = {
        ...action.payload,
        player_id: action.payload.key,
      };

      return {
        ...state,
        info: {
          ...state.info,
          [player_new.player_id]: {
            ...state.info[player_new.player_id],
            jersey: player_new.jersey,
            first_name: player_new.first_name,
            last_name: player_new.last_name,
          },
        },
      };
    }
    case UPDATE_FAVORITE:
      const player = action.payload;

      return {
        ...state,
        info: {
          ...state.info,
          [player.player_id]: {
            ...state.info[player.player_id],
            first_name: player.first_name,
            last_name: player.last_name,
            birth_date: player.birth_date,
            contract_until: player.contract_until,
            position_id: player.position_id,
          },
        },
        detail: {
          ...state.detail,
          player: {
            ...player,
          },
        },
      };

    case RESET_FAVORITE:
      return {
        ...state,
        detail: INITIAL_STATE.detail,
      };

    case FETCH_FAVORITE:
      const playerId = _.get(action.payload.data, 'player.player_id', null);

      const DemoPlayer = window.genderContext === 'female' ? DemoWomenPlayer : DemoPlayerMan;

      if (playerId === 'demo') {
        const demoPlayer = {
          ...state,
          detail: {
            id: playerId,
            ...DemoPlayer,
          },
        };
        return demoPlayer;
      }

      return {
        ...state,
        detail: {
          id: playerId,
          ...action.payload.data,
        },
      };

    case FETCH_FAVORITES_LIST: {
      const list = action.payload.data.favorites || [];

      return {
        ...state,
        list,
      };
    }

    case FETCH_FAVORITES_INFO: {
      const info = action.payload.data.favorites;


      let minAge = 200;
      let maxAge = 0;
      info.map(({ birth_date }) => {
        const birth = moment(birth_date, 'YYYY-MM-DD');
        const years = moment().diff(birth, 'years');
        if (birth_date === '1900-01-01') return;
        if (minAge > years) {
          minAge = years;
        }
        if (maxAge < years) {
          maxAge = years;
        }
      });

      minAge = minAge === 200 ? 0 : minAge;
      maxAge = maxAge === 0 ? 200 : maxAge;

      const filter = {
        ...state.filter,
        age: { min: minAge, max: maxAge, minValue: minAge, maxValue: maxAge }
      };

      return {
        ...state,
        fetched: true,
        info: _.keyBy(info, 'key'),
        filter,
        filterInitial: filter,
      };
    }

    case ADD_TO_FAVORITE: {
      if (!action?.payload?.data?.success) {
        return state;
      }
      const favorite = action?.payload?.data?.favorite;
      const info = action?.payload?.data?.info || {};
      const isFavoriteIndex = state.list.findIndex((f) => f.player_id == favorite.player_id);

      const newPlayer = {
        ...favorite,
      };

      return isFavoriteIndex === -1 ? {
        ...state,
        fetched: false,
        info: {
          ...state.info,
          [info.player_id]: {
            ...info,
          },
        },
        list: [
          ...state.list,
          newPlayer,
        ],
      } : {
        ...state,
        list: [
          ...state.list.slice(0, isFavoriteIndex),
          newPlayer,
          ...state.list.slice(isFavoriteIndex + 1),
        ],
      };
    }

    case DELETE_PLAYER:
      const data = _.get(action, 'payload.data', []);
      let newList1 = [...state.list];

      data.map(({ player_id }) => {
        const index = newList1.findIndex((player) => player.player_id == player_id);
        newList1 = [...newList1.slice(0, index), ...newList1.slice(index + 1)];
      });

      return { ...state, list: newList1 };

    case REMOVE_FROM_FAVORITE:
      const index = state.list.findIndex((favorite) => favorite._id === action.payload);
      const newList = [
        ...state.list.slice(0, index),
        ...state.list.slice(index + 1),
      ];

      return {
        ...state,
        list: newList,
      };

    case CHANGE_FAVORITE_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          ..._.mapValues(action.payload, (value, key) => {
            if (_.isPlainObject(value)) {
              return {
                ...state.filter[key],
                ...value,
              };
            }

            return value;
          }),
        },
      };

    case ADD_PLAYER_INFO:
      return {
        ...state,
        info: {
          ...state.info,
          [action.payload.player_id]: {
            ...action.payload,
          },
        },
      };

    case CREATE_NEW_PLAYER_FROM_FAVORITES:
      const {
        first_name, last_name, jersey, position_id, foot, birth_date, team_id, id,
      } = action.payload;

      storagePlayers({
        player: {
          first_name, last_name, jersey, position_id, foot, birth_date, player_id: id,
        },
        team_id,
        type: 'ADD',
        match_id: null,
      });

      return {
        ...state,
        list: [...state.list, id],
        info: {
          ...state.info,
          [id]: {
            ...action.payload,
          },
        },
      };

    case MERGE_PLAYERS:
      return {
        ...state,
        fetched: false,
        detail: {},
        list: [],
      };

    case FAVORITE_ADD_WATCHLIST:
      return {
        ...state,
        detail: {
          ...state.detail,
          watchlists: [...state.detail.watchlists, action.payload],
        },
      };
    case FAVORITE_REMOVE_WATCHLIST:
      const index2 = state.detail.watchlists.findIndex((wl) => wl._id === action.payload);
      return {
        ...state,
        detail: {
          ...state.detail,
          watchlists: [...state.detail.watchlists.slice(0, index2), ...state.detail.watchlists.slice(index2 + 1)],
        },
      };
    case FAVORITES_RESET_FILTER: {
      return {
        ...state,
        filter: state.filterInitial,
      }
    }
    default:
      return state;
  }
}
