import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import { withTranslation } from 'react-i18next';

import './area-formations.scss';

import Position from './position';
import PlayerImage from '../../../../player-image';

import { selectFormationPosition, changeFormationPosition } from '../../../../../actions/match';
import { getPlayerImage } from '../../../../../helpers';

@withTranslation()
@connect((state) => ({
  formations: state.formations,
  positions: state.positions,
  match: state.match,
}), { selectFormationPosition, changeFormationPosition })

export default class Positions extends Component {
  static propTypes = {
    match: PropTypes.object,
    selectFormationPosition: PropTypes.func,
    formations: PropTypes.object,
    positions: PropTypes.object,
    changeFormationPosition: PropTypes.func,
  };

  constructor() {
    super();

    this.renderPositions = this.renderPositions.bind(this);
    this.showPositionPlayers = this.showPositionPlayers.bind(this);
    this.renderPositionPlayers = this.renderPositionPlayers.bind(this);
  }

  showPositionPlayers({ position, side }) {
    const { match, selectFormationPosition } = this.props;

    const players = _
      .chain(match.data[side].players)
      .filter((player) => !match.current_positions[side].lineup[player.player_id].active && !match.current_positions[side].lineup[player.player_id].substitution)
      .orderBy([
        (player) => player.information.position_id === position.position_id ? 0 : 1,
        (player) => player.information.jersey,
      ])
      .value();

    selectFormationPosition({ side, position, players });
  }

  renderPositions(side) {
    const { match } = this.props;

    return match.data[side].formations && _.get(match.data[side].formations[match.snapshot], 'id')
      ? match.data[side].formations[match.snapshot].positions.map((position, key) => (
        <Position onClick={this.showPositionPlayers} side={side} position={position} key={key} left={position.left} top={position.top} />
      ))
      : null;
  }

  renderPositionPlayers() {
    const { changeFormationPosition, match, t } = this.props;
    const { position, side, players } = match.positionSelected;
    const { top, left } = position;

    if (!side || !_.size(position)) return null;

    const listPosition = { };

    if (left > 0.5) {
      listPosition.right = `${(1 - left) * 100}%`;
    } else {
      listPosition.left = `${left * 100}%`;
    }

    if (top > 0.5) {
      listPosition.bottom = `${(1 - top) * 100}%`;
    } else {
      listPosition.top = `${top * 100}%`;
    }

    return (
      <div className="position-players-list" style={{ ...listPosition }}>
        {
          players.map((player) => {
            const playerImage = getPlayerImage(player.information);
            return (
              <div
                className="player"
                key={player.player_id}
                onClick={() => {
                  changeFormationPosition({
                    side,
                    top,
                    left,
                    information: player.information,
                    position,
                  });
                }}
              >
                <div className="player__number">{player.information.jersey}</div>
                <PlayerImage className="player__image" src={playerImage} />
                <div
                  className="player__name"
                >
                  {player.information.first_name !== player.information.last_name ? player.information.first_name : null}
                  {' '}
                  {player.information.last_name}
                </div>
              </div>
            );
          })
        }
        { !players.length ? <div className="no-players">{t('NO_PLAYERS', { context: window.genderContext })}</div> : null }
      </div>
    );
  }

  render() {
    const { formations, positions, match } = this.props;

    if (!_.size(formations) || !_.size(positions) || !_.size(match.data)) return null;

    return (
      <div className="area-formations">
        { this.renderPositions('home') }
        { this.renderPositions('away') }
        { this.renderPositionPlayers() }
      </div>
    );
  }
}
