import axios from 'axios';
import { NotificationManager } from '../components/notifications';

import { ROOT_URL } from '../actions';
import storageMatchHistory from './storageMatchHistory';

export default function (props, subscription) {
  NotificationManager.create({
    message: 'CREATING',
    id: 'id',
    timeOut: 3000,
  });

  switch (window.online) {
    case true:
      return Promise
        .all([
          axios.put(`${ROOT_URL}/match`, { ...props }),
        ])
        .then(([resMatch]) => {
          if (!resMatch.data.success) {
            NotificationManager.remove('id');
            NotificationManager.create({
              message: resMatch.data.message,
              id: 'id',
              timeOut: 3000,
              type: 'error',
            });
            return { match: null };
          }

          const newMatch = resMatch.data.match;
          storageMatchHistory(newMatch._id, 'MATCH_ADD');

          NotificationManager.remove('id');

          if (subscription.reports.max !== 'INFINITY') {
            NotificationManager.create({
              message: 'MATCH_CREATED_PROPS',
              id: 'id',
              timeOut: 3000,
              props: { current: subscription.reports.current, max: subscription.reports.max },
            });
          } else {
            NotificationManager.create({
              message: 'MATCH_CREATED',
              id: 'id',
              timeOut: 3000,
            });
          }
          return Promise.resolve({ match: newMatch });
        })
        .catch((err) => {
          console.log(err);
          NotificationManager.remove('id');
          NotificationManager.create({
            message: 'MATCH_NOT_CREATED',
            id: 'id',
            timeOut: 3000,
            type: 'error',
          });
        });

    case false:
      break;
  }
}
