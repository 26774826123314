import React, { Component } from 'react';
import { Link } from 'react-router';

import { connect } from 'react-redux';

import { Scrollbars } from 'react-custom-scrollbars';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import {
  fetchFavoritesInfo, removeFromFavorites, changeFavoritesFilter, fetchFavoritesList,
} from '../../actions/favorites';
import { fetchMatches, changeLoading } from '../../actions/index';
import {
  fetchShadowTeams,
  fetchShadowTabs,
} from '../../actions/shadow-teams';

import './favorites.scss';
import '../sub-nav/sub_nav.scss';
import './favorite_wrapper.scss';
import accountApi from '../../actions/account';
import { updateFavoriteTabs } from '../../actions/auth';
import Icon from '../Icon';

@withTranslation()
@connect((state) => ({
  favorites: state.favorites,
  teams: state.teams,
  positions: state.positions,
  matches: state.matches,
  user: state.auth.user,
  users: state.users,
}), {
  fetchFavoritesInfo, removeFromFavorites, changeFavoritesFilter, fetchMatches, changeLoading, fetchShadowTeams, fetchShadowTabs, updateFavoriteTabs, fetchFavoritesList,
})
export default class Favorites extends Component {
  constructor(props) {
    super(props);

    this.deleteFavoriteTab = this.deleteFavoriteTab.bind(this);
  }

  componentDidMount() {
    const { user, favorites } = this.props;

    this.props.changeLoading({ component: 'app', loading: true });
    this.props.fetchFavoritesList().then(() => {
      this.props.changeFavoritesFilter({ list_user_id: this.props.user._id });
      const promises = [this.props.fetchFavoritesInfo(), this.props.fetchShadowTeams(), this.props.fetchShadowTabs(this.props.user._id)];
      if (user.role.tag !== 'user') {
        promises.push(this.props.dispatch(accountApi.actions.groupUsers()));
      }
      Promise.all(promises)
        .then(() => {
          this.props.changeLoading({ component: 'app', loading: false });
        });
    });
  }

  renderPlayersList() {
    const { favorites, matches } = this.props;
    const { list, info } = favorites;

    let sortedList = list.filter((favorite) => info[favorite.player_id]).map((fav) => {
      const player = { ...info[fav.player_id], sort_name: `${info[fav.player_id].first_name} ${info[fav.player_id].last_name}`, meta: { ...fav } };
      return player;
    });

    sortedList = sortedList.filter((p) => !p.meta.group_member_favorite);

    sortedList = _.orderBy(sortedList, 'last_name', 'asc');
    sortedList = _.uniqBy(sortedList, 'player_id');

    return sortedList.map((favorite) => {
      const {
        player_id, first_name, last_name,
      } = favorite;

      const current_id = this.props.params.favorite_id;

      return (
        <li key={player_id}>
          <a
            onClick={() => { this.props.router.push(`/players/${player_id}/`); }}
            className={current_id == player_id ? 'active' : ''}
          >
            { last_name }
            { first_name !== last_name ? `, ${first_name}` : null }
          </a>
        </li>
      );
    });
  }

  renderFavoritesTabs() {
    const { user } = this.props;
    const { favoritesTabs } = user;
    const { favorites } = this.props;
    const { list, info } = favorites;
    const current_id = this.props.params.favorite_id;

    return favoritesTabs.map((player) => {
      const details = info[player];

      if (!details) return null;

      const { player_id, first_name, last_name } = details;

      return (
        <li key={player_id} className="deletable">
          <a
            onClick={() => { this.props.router.push(`/players/${player_id}/`); }}
            className={current_id == player_id ? 'active' : ''}
          >
            { last_name }
            { first_name !== last_name ? `, ${first_name}` : null }
          </a>
          <span
            className="delete"
            onClick={() => {
              this.deleteFavoriteTab(player_id);
            }}
          >
            <Icon name="close" />
          </span>
        </li>
      );
    });
  }

  deleteFavoriteTab(id) {
    const { user } = this.props;
    const { favoritesTabs } = user;
    const index = favoritesTabs.findIndex((p) => p == id);
    this.props.updateFavoriteTabs(user._id, [...favoritesTabs.slice(0, index), ...favoritesTabs.slice(index + 1)]);
  }

  render() {
    const { t, user, favorites } = this.props;
    const { favoritesTabs } = user;
    const { list } = favorites;

    return (
      <div className="favorites-wrapper">
        <div className="sub-nav">
          <div className="sub-nav__navigation">
            <Scrollbars autoHide>
              <div className="pad">
                <h2>{t('PLAYERS', { context: window.genderContext })}</h2>
                <ul>
                  <li className="nav-link"><Link onlyActiveOnIndex to="/players" activeClassName="active">{t('OVERVIEW')}</Link></li>
                  <li className="nav-link"><Link onlyActiveOnIndex to="/players/my" activeClassName="active">{t('CUSTOM_PLAYERS', { context: window.genderContext })}</Link></li>
                  <li className="nav-link"><Link onlyActiveOnIndex to="/players/recently-rated" activeClassName="active">{t('RECENTLY_RATED')}</Link></li>
                  {list.length ? <li className="divider" /> : null }
                  {list.length ? this.renderPlayersList() : null }
                  {favoritesTabs.length ? <li className="divider" /> : null}
                  {favoritesTabs.length ? this.renderFavoritesTabs() : null}
                </ul>
              </div>
            </Scrollbars>
          </div>
          <div className="sub-nav__content">
            <Scrollbars autoHide>
              { this.props.children }
            </Scrollbars>
          </div>
        </div>
      </div>
    );
  }
}
