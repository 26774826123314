import React from 'react';
import Collapsible from 'react-collapsible';

export default function (props) {
  const { lang } = props;

  /* switch (lang) {

    case 'de':
      return ();
      break;

    default:
      return ();
      break
  } */

  switch (lang) {
    case 'de':
      return (
        <div>
          <h1 className="overlay-h1">Häufig gestellte Fragen</h1>

          <p>
            Du hast Probleme mit deinem ScoutPad Account, einem aktuellen Spiel oder möchtest einfach nur durch die FAQ´s surfen?
            Werfe einen Blick in unseren Hilfebereich und erhalte schnell und einfach Antworten auf deine Fragen. Lerne ScoutPad wie ein Profi zu beherrschen.
          </p>

          <p className="p__btn">
            <a href="https://intercom.help/scoutpad" target="_blank" className="btn" rel="noreferrer">Gehe zum Hilfebereich</a>
          </p>
        </div>
      );

    case 'pt':
      return (
        <div>
          <h1 className="overlay-h1">Perguntas Frequentes</h1>
          <p>
            Tem problemas com sua conta do ScoutPad, uma correspondência recente, ou quer apenas navegar pelas perguntas mais frequentes?
            Obtenha respostas instantâneas para as perguntas mais comuns e saiba como usar o ScoutPad como um profissional.
          </p>
          <p className="p__btn">
            <a href="https://intercom.help/scoutpad" target="_blank" className="btn" rel="noreferrer">Ir para o Centro de Ajuda</a>
          </p>
        </div>
      );

    default:
      return (
        <div>
          <h1 className="overlay-h1">Frequently Asked Questions</h1>
          <p>
            Got problems with your ScoutPad account, a recent match, or just want to browse through frequently asked questions?
            Get instant answers to the most common questions and learn how to use ScoutPad like a pro.
          </p>
          <p className="p__btn">
            <a href="https://intercom.help/scoutpad" target="_blank" className="btn" rel="noreferrer">Go to Help Center</a>
          </p>
        </div>
      );
  }
}
