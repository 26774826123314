import React from 'react';
import _ from 'lodash';

import './indicator.scss';

import * as PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';
import Icon from '../../../Icon';
import IndicatorControl from './indicator-controls';
import { getWindowWidth } from '../../../../helpers';

@onClickOutside
export default class Indicator extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showControls: false,
      isOnControls: false,
      left: 0,
      top: 0,
    };

    this.indicatorRef = React.createRef();
    this.valueRef = React.createRef();

    this.toggleControls = this.toggleControls.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.onIndicatorValueClick = this.onIndicatorValueClick.bind(this);
    this.onIndicatorControlsMouseEnter = this.onIndicatorControlsMouseEnter.bind(this);
    this.onIndicatorControlsMouseLeave = this.onIndicatorControlsMouseLeave.bind(this);
  }

  handleClickOutside(e) {
    if (e.target.classList.contains('indicator-control__value')) {
      return false;
    }

    if (this.state.showControls && !this.state.isOnControls) {
      this.toggleControls(false);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.scrollTop !== this.props.scrollTop) {
      this.toggleControls(false);
    }
  }

  toggleControls(value) {
    if (value) {
      const element = this.indicatorRef.current;
      const bounds = element.getBoundingClientRect();

      let offsetX = 80;
      let offsetY = 20;

      if (getWindowWidth() < 960) {
        offsetX = 60;
        offsetY = 20;
      }

      this.setState({ left: bounds.x - offsetX, top: bounds.y + offsetY });
    }
    this.setState({ showControls: value });
  }

  onIndicatorValueClick(e, value) {
    const { changeInnerAndOuterControl, setPlayer, id } = this.props;
    e.stopPropagation();
    e.preventDefault();
    setPlayer();
    changeInnerAndOuterControl({ inner: id, outer: value });
  }

  onIndicatorControlsMouseEnter() {
    this.setState({ isOnControls: true });
  }

  onIndicatorControlsMouseLeave() {
    this.setState({ isOnControls: false });
  }

  render() {
    const {
      id,
      info,
      lng,
      values,
    } = this.props;
    const indicatorName = _.get(info, `name_${lng}`, info.name);
    const { showControls, left, top } = this.state;
    const value = values && values[id];

    return (
      <div className={`indicator-control ${showControls ? 'active' : ''}`} ref={this.indicatorRef} onClick={() => this.toggleControls(!showControls)}>
        <div className="indicator-control__icon">
          <Icon name={info.key} />
        </div>
        <div className="indicator-control__name">{indicatorName}</div>
        {showControls ? (
          <IndicatorControl
            onMouseLeave={this.onIndicatorControlsMouseLeave}
            onMouseEnter={this.onIndicatorControlsMouseEnter}
            id={id}
            left={left}
            top={top}
            onClickHandler={this.onIndicatorValueClick}
            value={value}
            isOpen={this.state.showControls}
            toggle={this.toggleControls}
            valueRef={this.valueRef}
          />
        ) : null}
      </div>
    );
  }
}

Indicator.propTypes = {
  id: PropTypes.any,
  info: PropTypes.any,
  lng: PropTypes.string,
  values: PropTypes.any,
  scrollTop: PropTypes.any,
  changeInnerAndOuterControl: PropTypes.func,
  setPlayer: PropTypes.func,
};
