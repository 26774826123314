import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import _ from 'lodash';
import { browserHistory } from 'react-router';

import { changeLoading } from '../../../actions/index';
import {
  fetchCustomer,
  createCustomer,
  updateCustomer,
  deleteCustomer,
  deleteUser,
  updateUser,
  createUser,
  deactivateCustomer,
  resetCustomer,
  resetPassword,
  manuallyVerifyCustomerEmail,
} from '../../../actions/customers';
import CustomersHeader from '../customers/customers-header';
import CustomerInformation from './information';
import CustomerEditForm from './edit';
import UsersTable from './users-table';
import SmallLoader from '../../ui/small-loader';
import { NotificationManager } from '../../notifications';

import './customer.scss';

@withTranslation()
@connect((state, props) => {
  return {
    id: props.params.id,
    details: state.customers.details,
    detailsError: state.customers.detailsError,
    isDetailsLoading: state.customers.isDetailsLoading,
    countries: state.countries,
    roles: state.roles,
    selectedUser: props.location.query.user,
    isEdit: props.location.query.edit === 'true',
  };
}, {
  fetchCustomer,
  changeLoading,
  deleteUser,
  updateUser,
  createUser,
  createCustomer,
  updateCustomer,
  deleteCustomer,
  deactivateCustomer,
  resetCustomer,
  resetPassword,
  manuallyVerifyCustomerEmail,
})
class CustomerPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      edit: props.id === 'new' || props.isEdit,
      isNew: props.id === 'new',
    };

    this.loadCustomer = this.loadCustomer.bind(this);
    this.updateUser = this.updateUser.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
    this.onCustomerFormCancel = this.onCustomerFormCancel.bind(this);
    this.onCustomerFormSubmit = this.onCustomerFormSubmit.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.manuallyVerifyCustomerEmail = this.manuallyVerifyCustomerEmail.bind(this);
  }

  componentDidMount() {
    const { id } = this.props;

    if (id !== 'new') {
      this.setState({
        edit: this.props.isEdit,
        isNew: false,
      });
      this.props.changeLoading({ component: 'customer', loading: true });
      this.props.fetchCustomer(id).then(() => {
        this.props.changeLoading({ component: 'customer', loading: false });
      });
    } else {
      this.props.resetCustomer();
      this.setState({
        edit: true,
        isNew: true,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.id !== prevProps.id && this.props.id !== 'new') {
      this.setState({
        edit: this.props.isEdit,
        isNew: false,
      });
      this.props.changeLoading({ component: 'customer', loading: true });
      this.props.fetchCustomer(this.props.id).then(() => {
        this.props.changeLoading({ component: 'customer', loading: false });
      });
    }

    if (prevProps.id !== 'new' && this.props.id === 'new') {
      this.props.resetCustomer();
      this.setState({
        edit: true,
        isNew: true,
      });
    }
  }

  loadCustomer(id) {
    this.props.changeLoading({ component: 'customer', loading: true });
    this.props.fetchCustomer(id).then(() => {
      this.props.changeLoading({ component: 'customer', loading: false });
    });
  }

  updateUser(user) {
    const { updateUser, createUser } = this.props;
    const updateFunc = user._id ? updateUser : createUser;

    updateFunc(user)
      .then((response) => {
        if (response.error) {
          const message = _.get(response, 'payload.response.data.message', null);
          NotificationManager.create({
            message,
            id: 'CUSTOMER',
            timeOut: 3000,
            type: 'error',
          });
        }
      }).catch((err) => {
        console.log(err.message);
      });
  }

  deleteUser(user) {
    const { t, deleteUser } = this.props;
    if (confirm(t('DELETE_USER_CONFIRM'))) {
      deleteUser(user);
    }
  }

  resetPassword(id) {
    const { t, resetPassword } = this.props;
    if (confirm(t('RESET_PASSWORD_CONFIRM'))) {
      resetPassword(id).then(() => alert(t('NEW_CREDENTIALS_SENT')));
    }
  }

  manuallyVerifyCustomerEmail(id) {
    const { t, manuallyVerifyCustomerEmail } = this.props;
    if (confirm(t('MANUALLY_VERIFY_CUSTOMER_EMAIL_CONFIRM'))) {
      manuallyVerifyCustomerEmail(id);
    }
  }

  onCustomerFormCancel() {
    const { isNew } = this.state;

    if (isNew) {
      browserHistory.push('/admin/customers');
    } else {
      this.setState({
        edit: false,
        isNew: false,
      });
    }
  }

  onCustomerFormSubmit(customer) {
    const { createCustomer, updateCustomer } = this.props;
    const isCreating = !customer._id;
    const func = isCreating ? createCustomer : updateCustomer;

    func(customer).then((action) => {
      const data = _.get(action, 'payload.data', {});

      if (data.success) {
        if (isCreating) {
          browserHistory.push(`/admin/customers/${data.customer._id}`);
        }
        this.setState({
          edit: false,
          isNew: false,
        });
      } else {
        browserHistory.push('/admin/customers');
        NotificationManager.create({
          message: data.message,
          id: 'CUSTOMER_2',
          timeOut: 3000,
          type: 'error',
        });
      }
    });
  }

  render() {
    const {
      details, detailsError, isDetailsLoading, t, countries, roles, selectedUser,
    } = this.props;

    const { edit, isNew } = this.state;

    // Error
    if (detailsError) {
      return (
        <div className="customers customer-page">
          <CustomersHeader />
          <div className="customer-page__info customer-page__error">
            <h2>{details.message}</h2>
          </div>
        </div>
      );
    }

    // Loading
    if (!isNew && (isDetailsLoading || !details.information)) {
      return (
        <div className="customers customer-page">
          <CustomersHeader />
          <div className="customer-page__info customer-page__error loading">
            <SmallLoader />
          </div>
        </div>
      );
    }

    // New customer form
    if (isNew) {
      return (
        <div className="customers customer-page">
          <CustomersHeader />
          <CustomerEditForm
            customer={null}
            isNew={isNew}
            onCancel={this.onCustomerFormCancel}
            onSubmit={this.onCustomerFormSubmit}
          />
        </div>
      );
    }

    const {
      information, limits, users,
    } = details;

    const customer = information;

    const paymentMethod = _.get(customer, 'stripe_cache.payment_method', null);
    const isPayingCustomer = paymentMethod && (customer.plan && customer.plan !== 'free') && customer.active;
    const userName = customer.firstName || customer.lastName ? [customer.firstName, customer.lastName].join(' ') : '';

    let customerStatus = 'REGULAR';

    if (isPayingCustomer) { customerStatus = 'PAYING'; }
    if (limits.isTrial) { customerStatus = 'TRIALING'; }
    if (!customer.active) { customerStatus = 'DISABLED'; }

    return (
      <div className="customers customer-page">
        <CustomersHeader />
        <div className="customer-page__heading">
          <div className="customer-page__heading-row">
            <div className="customer-page__heading-column">
              <h1 className="customer-page__name">
                {information.name}
                {' '}
                <span className={`customer-page__customer-status ${customerStatus.toLowerCase()}`}>{ t(customerStatus) }</span>
              </h1>
              <p className="customer-page__user-name">
                {userName}
                {customer.clubPosition ? `, ${customer.clubPosition}` : ''}
              </p>
              <p className="customer-page__email">{`${customer.clubEmail}${customer.phone ? `, ${customer.phone}` : ''}`}</p>
            </div>
            {
              !edit ? (
                <div className="customer-page__heading-column customer-page__heading-column-controls">
                  <span
                    className="js-link"
                    onClick={() => { this.setState({ edit: true, isNew: false }); }}
                  >
                    { t('EDIT') }
                  </span>
                  {
                    customer.active ? (
                      <span
                        className="js-link orange"
                        onClick={() => {
                          const { deactivateCustomer } = this.props;
                          deactivateCustomer(customer._id, false);
                        }}
                      >
                        { t('DEACTIVATE') }
                      </span>
                    ) : (
                      <span
                        className="js-link blue"
                        onClick={() => {
                          const { deactivateCustomer } = this.props;
                          deactivateCustomer(customer._id, true);
                        }}
                      >
                        { t('ACTIVATE') }
                      </span>
                    )
                  }

                  <span
                    className="js-link red"
                    onClick={() => {
                      const { deleteCustomer, t } = this.props;
                      if (confirm(t('DELETE_CUSTOMER'))) {
                        deleteCustomer(customer._id);
                        browserHistory.push('/admin/customers');
                      }
                    }}
                  >
                    { t('DELETE') }
                  </span>
                </div>
              ) : null
            }
          </div>
        </div>

        {
          !edit
            ? (
              <CustomerInformation
                customer={details}
                countries={countries}
                manuallyVerifyCustomerEmail={this.manuallyVerifyCustomerEmail}
              />
            )
            : (
              <CustomerEditForm
                customer={customer}
                isNew={isNew}
                onCancel={this.onCustomerFormCancel}
                onSubmit={this.onCustomerFormSubmit}
              />
            )
        }

        <div className="customer-page__tabs">
          <nav className="customer-page__tabs-nav" />
          <div className="customer-page__tabs-content">
            <h2 className="customer-page__tab-title">{t('USERS')}</h2>
            <UsersTable
              users={users}
              roles={roles.data}
              groupId={information._id}
              deleteUser={this.deleteUser}
              updateUser={this.updateUser}
              resetPassword={this.resetPassword}
              selectedUser={+selectedUser || null}
            />
          </div>
        </div>
      </div>
    );
  }
}

CustomerPage.propTypes = {
  changeLoading: PropTypes.func,
  fetchCustomer: PropTypes.func,
  details: PropTypes.object,
  id: PropTypes.string,
  isDetailsLoading: PropTypes.bool,
  detailsError: PropTypes.bool,
  t: PropTypes.func,
  deleteUser: PropTypes.func,
  createCustomer: PropTypes.func,
  updateCustomer: PropTypes.func,
  deleteCustomer: PropTypes.func,
  deactivateCustomer: PropTypes.func,
  resetCustomer: PropTypes.func,
  resetPassword: PropTypes.func,
  manuallyVerifyCustomerEmail: PropTypes.func,
};

export default CustomerPage;
