import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { withTranslation } from 'react-i18next';
import Icon from '../../Icon';

import './upgrade-button.scss';
import DemoMessage from '../demo-message';

@withTranslation()
class UpgradeButton extends Component {
  render() {
    const { t, classes, message } = this.props;
    return (
      <div className={`upgrade-button ${classes}`}>
        <Link to="/settings/billing/" className="upgrade-button__row-button">
          <Icon name="upgrade-small" />
          { t('UPGRADE_TO_PRO') }
        </Link>
        <DemoMessage text={message} arrowPosition="top-left" />
      </div>
    );
  }
}

UpgradeButton.propTypes = {
  classes: PropTypes.string,
  message: PropTypes.string,
  t: PropTypes.func,
};

export default UpgradeButton;
