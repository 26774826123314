import './preview.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DragLayer } from 'react-dnd';

@DragLayer((monitor) => ({
  item: monitor.getItem(),
  itemType: monitor.getItemType(),
  clientOffset: monitor.getClientOffset(),
  isDragging: monitor.isDragging(),
}))

export default class Preview extends Component {
  static propTypes = {
    item: PropTypes.object,
    itemType: PropTypes.string,
    clientOffset: PropTypes.shape({
      x: PropTypes.number.isRequired,
      y: PropTypes.number.isRequired,
    }),
    isDragging: PropTypes.bool,
  };

  getPreviewStyles() {
    const { clientOffset } = this.props;

    return {
      transform: clientOffset ? `translate(${clientOffset.x}px, ${clientOffset.y}px)` : '',
    };
  }

  render() {
    const { item, isDragging } = this.props;

    if (!isDragging) { return null; }

    const { side, information } = item;

    return (
      <div className={`preview ${side}`} style={this.getPreviewStyles()}>
        <span className="circle">{ information.jersey }</span>
      </div>
    );
  }
}
