import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, browserHistory } from 'react-router';
import _ from 'lodash';
import moment from 'moment';
import Select from 'react-select';
import isTouchDevice from 'is-touch-device';

import { withTranslation } from 'react-i18next';
import Icon from '../Icon';
import { filterTheme, styleFilter } from '../ui/select_props';
import DemoTeamMan from '../../../demo_shadow.json';
import DemoTeamWomen from '../../../demo_shadow_women.json';

import { ALPHABET } from '../../constants';

import {
  changeFavoritesFilter,
} from '../../actions/favorites';

import {
  updateShadowTeamLimits,
} from '../../actions/subscription';

import {
  fetchShadowTeams,
  copyShadowTeam,
  deleteShadowTeam,
  updateShadowTeam,
  createShadowTeam,
  openShadowTab,
  closeShadowTab,
} from '../../actions/shadow-teams';

import { canAddShadowTeams, canUseShadowTeams, exceededShadowTeamsLimit } from '../../helpers/limits';
import DemoMessage from '../ui/demo-message';
import DemoBadge from '../ui/demo-badge';
import UpgradeButton from '../ui/upgrade-button';
import DemoShadowTeamWoman from '../../../demo_shadow_women.json';
import DemoShadowTeamMan from '../../../demo_shadow.json';

@withTranslation()
@connect((state) => {
  return {
    formations: state.formations,
    shadowTeams: state.shadowTeams,
    user: state.auth.user,
    users: state.users,
    favorites: state.favorites,
    subscription: state.subscription,
  };
}, {
  fetchShadowTeams, copyShadowTeam, deleteShadowTeam, updateShadowTeam, createShadowTeam, openShadowTab, closeShadowTab, changeFavoritesFilter, updateShadowTeamLimits,
})
class Overview extends Component {
  constructor(props) {
    super(props);

    this.changeCreateValue = this.changeCreateValue.bind(this);
    this.createTeam = this.createTeam.bind(this);
    this.deleteTeam = this.deleteTeam.bind(this);
  }

  createTeam() {
    const { subscription } = this.props;
    const stLimits = subscription.shadowTeams;

    let name = '';

    for (let i = 0; i < ALPHABET.length; i++) {
      name = `${ALPHABET[i]}-Team`;
      const index = this.props.shadowTeams.findIndex((t) => t.name === name);
      if (index === -1) {
        break;
      }
    }

    this.props.createShadowTeam({ name, formation: 2 }).then((result) => {
      const { _id } = result.payload.data;
      this.props.openShadowTab(_id, this.props.user._id);
      this.props.updateShadowTeamLimits({ current: ++stLimits.current });
      browserHistory.push(`/shadow-teams/${_id}`);
    });
  }

  deleteTeam(_id) {
    const { t, subscription } = this.props;
    const stLimits = subscription.shadowTeams;

    if (confirm(t('TEAM_DELETE_CONFIRM'))) {
      this.props.updateShadowTeamLimits({ current: --stLimits.current });
      this.props.deleteShadowTeam(_id);
      this.props.closeShadowTab(_id, this.props.user._id);
    }
  }

  copyTeam(_id) {
    const { subscription } = this.props;
    const stLimits = subscription.shadowTeams;

    this.props.copyShadowTeam(_id).then((result) => {
      const { _id } = result.payload.data;
      this.props.openShadowTab(_id, this.props.user._id);
      this.props.updateShadowTeamLimits({ current: ++stLimits.current });
      browserHistory.push(`/shadow-teams/${_id}`);
    });
  }

  getFormationName(formation_id) {
    const { formations } = this.props;
    return formations[formation_id].name;
  }

  changeCreateValue(value, input) {
    const create = _.cloneDeep(this.state.create);
    create[input] = value;
    create.errors[input] = false;

    this.setState({ create });
  }

  renderCreateLink() {
    const { t } = this.props;

    return (
      <span className="create-team btn col-6" onClick={() => { this.createTeam(); }}>
        <Icon name="plus" className="create-icon" />
        {t('CREATE_SHADOW_TEAM_LINK')}
      </span>
    );
  }

  renderTeamRow(team, i) {
    const {
      favorites, subscription, user, users,
    } = this.props;

    let firstName = '';
    let lastName = '';

    if (users.data) {
      if (team.user_id !== user._id) {
        const usr = users.data.find((u) => u._id === team.user_id) || {};
        firstName = usr.firstName;
        lastName = usr.lastName;
      } else {
        firstName = user.firstName;
        lastName = user.lastName;
      }
    }

    const isDemo = team._id == 'demo';

    return (
      <tr key={`${team._id}_${i}`}>
        <td className={`${isDemo ? 'with-demo-badge' : ''}`}>
          { isDemo ? <DemoBadge /> : null }
          <Link
            to={`/shadow-teams/${team._id}`}
            onClick={() => {
              this.props.openShadowTab(team._id, user._id);
            }}
          >
            {team.name}
          </Link>
          {user.role.tag !== 'user' && favorites.filter.list_user_id !== user._id ? (
            <span className="team-author">
              by
              {`${firstName} ${lastName}`}
            </span>
          ) : null}
        </td>
        <td>{this.getFormationName(team.formation_id)}</td>
        <td className="col__date">{moment(team.createdAt).format('DD.MM.YYYY')}</td>
        <td className="col__date">{moment(team.updatedAt).format('DD.MM.YYYY')}</td>
        <td>
          { isDemo ? (
            <span className="shadow-team__table-controls">
              <span className="control-btn">
                <Icon name="copy" onClick={() => { }} />
              </span>
              <span className="control-btn" onClick={() => {}}>
                <Icon name="trashcan" />
              </span>
            </span>
          ) : (
            <span className="shadow-team__table-controls">
              {canAddShadowTeams(subscription) ? (
                <span className="control-btn">
                  <Icon name="copy" onClick={() => { this.copyTeam(team._id); }} />
                </span>
              ) : null}
              <span className="control-btn" onClick={() => { this.deleteTeam(team._id); }}>
                <Icon name="trashcan" />
              </span>
            </span>
          ) }

        </td>
      </tr>
    );
  }

  renderTeamsTable(shadowTeamsFiltered) {
    const { t } = this.props;

    return (
      <table className="shadow-team__table">
        <colgroup>
          <col className="col__name" />
          <col className="col__formation" />
          <col className="col__date" />
          <col className="col__date" />
          <col className="col__controls" />
        </colgroup>
        <thead>
          <tr>
            <th>{t('SHADOW_TABLE_NAME')}</th>
            <th>{t('SHADOW_TABLE_FORMATION')}</th>
            <th className="col__date">{t('SHADOW_TABLE_CREATED')}</th>
            <th className="col__date">{t('SHADOW_TABLE_UPDATED')}</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {
          shadowTeamsFiltered.map((team, i) => this.renderTeamRow(team, i))
        }
        </tbody>
      </table>
    );
  }

  render() {
    const {
      shadowTeams, user, t, users, subscription,
    } = this.props;
    const favoriteFilters = this.props.favorites.filter;

    let shadowTeamsFiltered = shadowTeams;

    const scoutsOptions = _(users.data)
      .filter((u) => u._id !== user._id)
      .filter((u) => {
        return (shadowTeamsFiltered.findIndex((t) => t.user_id === u._id) !== -1) || (u._id == favoriteFilters.list_user_id);
      })
      .orderBy('lastName')
      .map((u) => {
        return { value: u._id, label: `${u.firstName} ${u.lastName}` };
      })
      .value();

    if (favoriteFilters.list_user_id) {
      shadowTeamsFiltered = shadowTeamsFiltered.filter((t) => t.user_id === favoriteFilters.list_user_id);
    }

    scoutsOptions.unshift({ value: null, label: t('ALL_SCOUTS') });
    scoutsOptions.unshift({ value: '-', label: '-', isDisabled: true });
    scoutsOptions.unshift({ value: user._id, label: t('MY_TEAMS') });

    const scoutValue = scoutsOptions.find((o) => o.value === favoriteFilters.list_user_id);

    const showAddButton = canAddShadowTeams(subscription);
    const canUseShadowTeam = canUseShadowTeams(subscription);

    const upgradeMessage = user.role.tag !== 'user' ? (
      <UpgradeButton classes="shadow-team" message={t('UPGRADE_TO_PRO_SHADOW_TEAMS')} />
    ) : (<p className="upgrade-message-user">{t('USER_UPGRADE_TO_PRO_SHADOW_TEAMS')}</p>);

    const isDemo = shadowTeams.length === 1 && shadowTeams[0]._id === 'demo';

    let emptyMessage = user.role.tag !== 'user' && favoriteFilters.list_user_id !== user._id
      ? <p className="empty-message">{t('NO_SHADOW_TEAMS_SCOUT')}</p>
      : <p className="empty-message">{t('NO_SHADOW_TEAMS_USER')}</p>;

    if (!canUseShadowTeam) {
      emptyMessage = null;
    }

    const showScoutsFilter = user.role.tag !== 'user' && scoutsOptions.length > 3 && !subscription.isFree;

    const DemoTeam = window.genderContext === 'female' ? DemoShadowTeamWoman : DemoShadowTeamMan;

    return (
      <div>
        <div className="row">
          {
            showScoutsFilter ? (
              <div className="col-5">
                <div className="filter-container">
                  <div className="filter select-scout">
                    <div>
                      <label>{t('TEAMS_BY')}</label>
                      <Select
                        styles={styleFilter}
                        theme={filterTheme}
                        placeholder={t('ALL_SCOUTS')}
                        options={scoutsOptions}
                        clearable
                        isSearchable={!isTouchDevice()}
                        defaultValue={scoutValue}
                        value={scoutValue}
                        onChange={(val) => {
                          this.props.changeFavoritesFilter({ list_user_id: val.value });
                        }}
                        isMulti={false}
                        className="matches-list-filter__item"
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : null

          }
          {
            showAddButton ? this.renderCreateLink() : null
          }
        </div>

        {
          shadowTeamsFiltered.length ? this.renderTeamsTable(shadowTeamsFiltered) : emptyMessage
        }

        {
          isDemo ? (
            <div>
              {this.renderTeamsTable(DemoTeam)}
              { canUseShadowTeam && !exceededShadowTeamsLimit(subscription) ? <DemoMessage text={t('DEMO_TEAM_MESSAGE')} arrowPosition="top-left" /> : null}
            </div>
          ) : null
        }

        { !canUseShadowTeam || exceededShadowTeamsLimit(subscription) || (canUseShadowTeam && !showAddButton) ? upgradeMessage : null }
      </div>
    );
  }
}

Overview.propTypes = {};

export default Overview;
