import React from 'react';
import { Route, IndexRoute, Redirect } from 'react-router';

import App from './components/App';
import Match from './components/match';
import Pdf from './components/pdf';
import Auth from './containers/Auth/Auth';
import Registration from './containers/registration';
import NotFoundView from './views/NotFoundView';
import isActive from './components/Auth/isActive';
import isSigned from './components/Auth/IsSigned';
import isSignedAdmin from './components/Auth/IsSignedAdmin';
import Settings from './components/settings';
import Admin from './components/admin';
import Customers from './components/admin/customers';
import Customer from './components/admin/customer';
import FavoritesWrapper from './components/favorites/favorites_wrapper';
import WatchlistsWrapper from './components/watchlists/watchlist_wrapper';
import Watchlists from './components/watchlists/watchlists';
import Watchlist from './components/watchlists/watchlist';
import Favorites from './components/favorites';
import CustomPlayers from './components/favorites/custom-players';
import RecentlyRated from './components/favorites/recently-rated';
import Favorite from './components/favorite';
import Help from './components/help';
import Reports from './components/reports';
import ReportsPage from './components/reports/reports-page';
import Upcoming from './components/reports/upcoming';
import ReportsOverview from './components/reports/overview';

import Home from './components/home';

import ShadowTeam from './components/shadow-team';
import ShadowTeamsOverview from './components/shadow-teams-overview';
import ShadowTeams from './components/shadow-teams';

import eventTracker from './helpers/event-tracker';

// add-card, address

export default (
  <Route onEnter={(e) => eventTracker.trackPageView(e)} onChange={(e) => eventTracker.trackPageView(e)}>
    <Route path="/registration/" component={Registration} />
    <Route path="/auth/:type" component={Auth} />
    <Route path="/" component={App}>
      <IndexRoute component={isSigned(Home)} />

      <Route path="/reports/" component={isSigned(Reports)}>
        <IndexRoute component={isSigned(ReportsOverview)} />
        <Route path="/reports/coming-up" component={isSigned(Upcoming)} />
        <Route path="/reports/:type/" component={isSigned(ReportsPage)} />
      </Route>

      <Route path="match/:match_id" component={isSigned(isActive(Match))} />
      <Route path="pdf/:match_id" component={isSigned(Pdf)} />

      <Route path="/settings" component={isSigned(Settings)}>
        <Route path="/settings/:section" component={isSigned(Settings)} />
        <Route path="/settings/:section/:subsection" component={isSigned(Settings)} />
      </Route>

      <Route path="/players" component={isSigned(FavoritesWrapper)}>
        <IndexRoute component={isSigned(Favorites)} />
        <Route path="/players/my" component={isSigned(CustomPlayers)} />
        <Route path="/players/my/:favorite_id(/:page)" component={isSigned(Favorite)} />
        <Route path="/players/recently-rated" component={isSigned(RecentlyRated)} />
        <Route path="/players/recently-rated/:favorite_id(/:page)" component={isSigned(Favorite)} />
        <Route path="/players/:favorite_id(/:page)" component={isSigned(Favorite)} />
      </Route>

      <Route path="/watchlists" component={isSigned(WatchlistsWrapper)}>
        <IndexRoute component={isSigned(Watchlists)} />
        <Route path="/watchlists/:watchlist_id" component={isSigned(Watchlist)}>
          <Route path="/watchlists/:watchlist_id/:favorite_id(/:page)" component={isSigned(Favorite)} />
        </Route>
      </Route>

      <Route path="/shadow-teams" component={isSigned(ShadowTeams)}>
        <IndexRoute component={isSigned(ShadowTeamsOverview)} />
        <Route path="/shadow-teams/:shadow_team_id" component={isSigned(ShadowTeam)} />
        <Route path="/shadow-teams/:shadow_team_id/:favorite_id(/:page)" component={isSigned(Favorite)} />
      </Route>

      <Route path="/help" component={isSigned(Help)}>
        <Route path="/help/:section" component={isSigned(Help)} />
        <Route path="/help/:section/:subsection" component={isSigned(Help)} />
      </Route>

      <Route path="/admin" component={isSignedAdmin(Admin)}>
        <Route path="/admin/customers" components={isSignedAdmin(Customers)}>
          <Route path="/admin/customers/:id(/:page)" component={isSignedAdmin(Customer)} />
        </Route>
        <Route path="/admin/:page" component={isSigned(Admin)} />
      </Route>
      <Route path="404" component={NotFoundView} />
      <Redirect from="*" to="404" />
    </Route>
  </Route>
);
