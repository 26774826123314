import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import DataItem from '../../favorite/components/data_item';
import DataItemChangeable from '../../favorite/components/data_item_changeable';
import Icon from '../../Icon';

const MatchStats = withTranslation()((props) => {
  const {
    t,
    stats,
    updateSingleReportMins,
    mins,
  } = props;

  const [minValue, setMinValue] = useState(0);

  useEffect(() => {
    const value = !mins ? stats.mins : mins;
    setMinValue(value);
  }, [stats]);

  return (
    <div className="pdf-match-stats">
      <h4 className="pdf-small-title">{t('PLAYER_STATS_FOR_THE_MATCH')}</h4>
      <div className="pdf-stats-row">
        <DataItemChangeable
          key={minValue}
          label={t('MINS')}
          value={minValue}
          name="mins"
          inputValue={minValue}
          type="number"
          disableAutofocus={minValue}
          onChange={(v) => {
            setMinValue(v.value);
            updateSingleReportMins({ value: !v.value ? null : v.value });
          }}
          isChanging
          icon={<Icon name="mins-dark" />}
        />
        <DataItem
          label={t('GOALS')}
          value={stats.goal}
          icon={<Icon name="goals-dark" />}
        />
      </div>
      <div className="pdf-stats-row">
        <DataItem
          label={t('YELLOW')}
          value={stats.yellow}
          icon={<Icon name="yellow" />}
        />
        <DataItem
          label={t('ASSISTS')}
          value={stats.assist}
          icon={<Icon name="assists-dark" />}
        />
      </div>
      <div className="pdf-stats-row">
        <DataItem
          label={t('YELLOW_RED')}
          value={stats.yellow_red}
          icon={<Icon name="yellow_red_contour" />}
        />
        <DataItem
          label={t('RED')}
          value={stats.red}
          icon={<Icon name="red" />}
        />
      </div>
      {stats.substitution_in !== null || stats.substitution_out !== null ? (
        <div className="pdf-stats-row">
          {stats.substitution_in !== null ? (
            <DataItem
              label={t('SUBSTITUTION_IN_SHORT')}
              value={`${stats.substitution_in}'`}
              icon={<Icon name="substitutionin" />}
            />
          ) : null}
          {stats.substitution_out !== null ? (
            <DataItem
              label={t('SUBSTITUTION_OUT_SHORT')}
              value={`${stats.substitution_out}'`}
              icon={<Icon name="substitutionout" />}
            />
          ) : null}
        </div>
      ) : null}
    </div>
  );
});

MatchStats.propTypes = {
  stats: PropTypes.object,
  mins: PropTypes.string,
};

export default MatchStats;
