import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import DeviceOrientation, { Orientation } from 'react-screen-orientation';
import { withTranslation } from 'react-i18next';
import Lottie from 'lottie-react';
import { NotificationManager, NotificationContainer } from './notifications';

import Icon from './Icon';
import Navigation from './navigation';
import congratulationsConfirm from './ui/congratulations-confirm';
import { default as animationData } from './loader.json';

import Survey from './survey';

import {
  checkAuth, updateSurvey, logout, setPrivacy,
} from '../actions/auth';
import { fetchNotifications, seenNotification } from '../actions/notifications';
import {
  fetchMatches,
  fetchTeams,
  fetchStadiums,
  fetchPositions,
  fetchLeagues,
  fetchIndicators,
  changeLoading,
  fetchFormations,
  syncEvaluations,
  fetchIcons,
  fetchColors,
} from '../actions/index';

import {
  fetchDemo,
} from '../actions/reports';

import {
  fetchNoteTags,
} from '../actions/notes';

import { fetchUpcoming } from '../actions/upcoming_matches';
import { fetchAllCountries } from '../actions/countries';
import { fetchSubscription } from '../actions/subscription';
import confirm from './confirm';
import { fetchFavoritesList } from '../actions/favorites';

import './app.scss';
import RestartSubscriptionMessage from './ui/restart-subscription';
import BillingAddressMessage from './ui/billing-address-message';
import FailedTaxMessage from './ui/failed-tax-message';

const getWindowHeight = (minus = 0) => document.documentElement.clientHeight ? document.documentElement.clientHeight - minus : window.innerHeight - minus;

@withTranslation()
@connect((state) => ({
  auth: state.auth,
  loading: state.loading,
  subscription: state.subscription,
}), {
  checkAuth,
  fetchMatches,
  fetchTeams,
  fetchStadiums,
  fetchPositions,
  fetchLeagues,
  fetchIndicators,
  changeLoading,
  fetchFormations,
  logout,
  setPrivacy,
  fetchUpcoming,
  fetchAllCountries,
  syncEvaluations,
  fetchSubscription,
  fetchFavoritesList,
  fetchNoteTags,
  fetchNotifications,
  seenNotification,
  fetchIcons,
  fetchColors,
  updateSurvey,
  fetchDemo,
})

export default class App extends Component {
  static propTypes = {
    checkAuth: PropTypes.func,
    updateSurvey: PropTypes.func,
    fetchMatches: PropTypes.func,
    fetchTeams: PropTypes.func,
    fetchStadiums: PropTypes.func,
    fetchPositions: PropTypes.func,
    fetchLeagues: PropTypes.func,
    fetchIndicators: PropTypes.func,
    changeLoading: PropTypes.func,
    fetchFormations: PropTypes.func,
    syncEvaluations: PropTypes.func,
    fetchAllCountries: PropTypes.func,
    fetchFormationPosition: PropTypes.func,
    fetchColors: PropTypes.func,
    fetchIcons: PropTypes.func,
    fetchUpcoming: PropTypes.func,
    fetchSubscription: PropTypes.func,
    fetchFavoritesList: PropTypes.func,
    fetchNoteTags: PropTypes.func,
    fetchNotifications: PropTypes.func,
    fetchDemo: PropTypes.func,
    auth: PropTypes.object,
    location: PropTypes.object,
    loading: PropTypes.object,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
  };

  constructor(props) {
    super(props);

    this.state = {
      height: '',
      initialized: false,
      path: null,
      interval: null,
    };

    this.handleResize = this.handleResize.bind(this);
    props.checkAuth();
  }

  handleResize() {
    this.setState({ height: getWindowHeight(0) });
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    if (this.props.auth.user) {
      this.getData();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    clearInterval(this.state.interval);
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.auth.user && this.props.auth.user) {
      this.getData();
    }
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({ path: this.props.location.pathname });
    }
  }

  getData() {
    this.props.changeLoading({ component: 'app', loading: true });

    const { t } = this.props;
    Promise.all([
      this.props.syncEvaluations(),
      this.props.fetchTeams(),
      this.props.fetchStadiums(),
      this.props.fetchPositions(),
      this.props.fetchLeagues(),
      this.props.fetchIndicators(),
      this.props.fetchFormations(),
      this.props.fetchAllCountries(),
      this.props.fetchUpcoming(),
      this.props.fetchSubscription(),
      this.props.fetchFavoritesList(),
      this.props.fetchNoteTags(),
      this.props.fetchNotifications(),
      this.props.fetchIcons(),
      this.props.fetchColors(),
      this.props.fetchDemo(),
    ]).then((result) => {
      this.setState({
        interval: setInterval(() => {
          const isMatch = new RegExp('/match/', 'ig').test(this.state.path);
          const isReport = new RegExp('/pdf/', 'ig').test(this.state.path);
          const isAuth = new RegExp('/auth/', 'ig').test(this.state.path);

          if (!isMatch && !isReport && !isAuth && window.online) {
            this.props.fetchNotifications().then((result) => {
              const notifications = _get(result, 'payload.data', []);
              if (notifications.length) {
                congratulationsConfirm(notifications, this.props.seenNotification);
              }
            });
          }
        }, 3000),
      });

      const notificationsResult = result[12];
      const notifications = _get(notificationsResult, 'payload.data', []);
      if (notifications.length) {
        congratulationsConfirm(notifications, this.props.seenNotification);
      }

      const { auth } = this.props;
      const userId = _get(auth, 'user._id', null);
      let subscription = result.find((r) => {
        return r.type === 'FETCH_SUBSCRIPTION';
      });
      subscription = subscription.payload.data;
      window.genderContext = _get(auth, 'user.selectedGender', 'male');

      const payment_method = _get(subscription, 'details.payment_method', null);
      const groupActive = _get(auth, 'user.group.active', null);
      const intercom_user_hash = _get(auth, 'user.intercom_user_hash', null);

      if (process.env.NODE_ENV === 'production') {
        if (userId && window.Intercom) {
          window.Intercom('boot', {
            app_id: 'd22mu9n4',
            alignment: 'right',
            horizontal_padding: 20,
            vertical_padding: 20,
            id: userId,
            isTrialing: subscription.isTrial,
            isFreePlan: subscription.isFree,
            active: subscription.isActive,
            isPaidPlan: !subscription.isTrial && !subscription.isFree,
            payingActiveUser: !subscription.isTrial && !subscription.isFree && payment_method && groupActive,
            set_password: true,
            product_id: 'pad',
            user_hash: intercom_user_hash,
          });
        }
      }

      this.props.changeLoading({ component: 'app', loading: false });
      this.setState({
        initialized: true,
      });
    }).then(() => {
      const text = (dismiss, onCheck, isChecked) => {
        const checked = isChecked ? 'checked' : '';
        return (
          <div className="privacy-confirm">
            <h2 className="h2">{t('PRIVACY_TITLE')}</h2>
            <p dangerouslySetInnerHTML={{ __html: t('PRIVACY_TEXT') }} />
            <p className="with-checkbox">
              <span className={`checkbox ${checked}`} onClick={onCheck}>
                <Icon name="checkbox" />
              </span>
              <span dangerouslySetInnerHTML={{ __html: t('PRIVACY_CHECKBOX') }} />
            </p>
          </div>
        );
      };

      const { user } = this.props.auth;

      if (!user.acceptPrivacy) {
        confirm(text, {
          context: this.context,
          withCheckbox: true,
          cancelText: t('DISAGREE'),
          confirmText: t('AGREE_AND_PROCEED'),
          hideCancel: true,
        })
          .then(
            () => {
              this.props.setPrivacy(true);
              window.scrollTo({ top: 0, behavior: 'smooth' });
            },
            () => {
              this.props.setPrivacy(false).then(() => {
                this.props.logout();
                document.location = '/';
              });
            },
          );
      }

      const active = _get(this.props, 'auth.user.active', true);
      const deactivationMessage = _get(this.props, 'auth.user.deactivationMessage', '');

      if (!active && deactivationMessage != 'Renew') {
        if (!deactivationMessage) {
          NotificationManager.create({
            message: t('USER_DEACTIVATED'),
            id: 'id',
            timeOut: 6000,
            type: 'error',
          });
        } else {
          NotificationManager.create({
            message: deactivationMessage,
            id: 'id',
            timeOut: 6000,
            type: 'error',
          });
        }
      }
    });
  }

  render() {
    const {
      loading, t, auth, subscription,
    } = this.props;
    const { height, initialized } = this.state;

    const shouldShowSurvey = auth.user && _get(auth, 'user.group', false) && !_get(auth, 'user.group.reasonsId', []).length && +_get(auth, 'user.role.weight', 0) > 0;

    if (shouldShowSurvey) {
      return (
        <Survey
          onSubmit={(reasons) => {
            const id = _get(auth, 'user._id', null);
            if (window.Intercom && id) {
              window.Intercom('update', {
                id,
                set_survey: true,
              });
              setTimeout(() => {
                window.Intercom('trackEvent', 'done-survey');
              }, 1000);
            }
            this.props.updateSurvey(reasons);
          }}
        />
      );
    }

    // if !trial and tax === unrecognized_location /// not_collecting /// failed
    const automaticTaxStatus = _get(subscription, 'customer.tax.automatic_tax', null);
    const isAutomaticTaxLocationUnrecognized = automaticTaxStatus === 'unrecognized_location';
    const automaticTaxTurnedOn = _.get(subscription, 'details.subscription.automatic_tax.enabled', true);
    const isAutomaticTaxError = automaticTaxStatus === 'failed';
    const isFreeOrTrial = subscription?.isFree || subscription?.isTrial;
    const isActivePaid = !isFreeOrTrial && subscription?.active;
    const isNotUser = _get(auth, 'user.role.tag', null) !== 'user';

    return (
      <div>
        <DeviceOrientation lockOrientation="landscape">
          <Orientation orientation="landscape" alwaysRender>
            {
              initialized ? (
                <div>
                  <div className="app-container" style={{ height }}>
                    <Navigation height={height} />
                    <div className="app-content" style={{ height }}>
                      <RestartSubscriptionMessage />
                      { isAutomaticTaxLocationUnrecognized && isActivePaid && isNotUser && automaticTaxTurnedOn ? <BillingAddressMessage /> : null }
                      { isAutomaticTaxError && isActivePaid && isNotUser && automaticTaxTurnedOn ? <FailedTaxMessage /> : null }
                      { this.props.children }
                    </div>
                  </div>
                  <NotificationContainer leaveTimeout={400} enterTimeout={0} />
                </div>
              ) : null
            }
            {loading.app || loading.match || loading.pdf || loading.billing || loading.add_card || loading.favorite || loading.payments ? (
              <div className="loader">
                <div className="loader-image">
                  <Lottie
                    loop
                    autoplay
                    animationData={animationData}
                    style={{
                      height: 120,
                      width: 120,
                    }}
                    preserveAspectRatio="xMidYMid slice"
                  />
                  {loading.message ? <div className="loader-message">{t(loading.message)}</div> : null }
                </div>

              </div>
            ) : null}
          </Orientation>

          <Orientation orientation="portrait">
            <div className="to-landscape">
              <Icon name="rotate" />
              <div className="rotate-text">{t('ROTATE_DEVICE')}</div>
            </div>
          </Orientation>
        </DeviceOrientation>
      </div>
    );
  }
}
