import React from 'react';
import PropTypes from 'prop-types';

import './match.scss';

export default function Match({ homeImg, awayImg }) {
  return (
    <div className="match-card__match">
      <div className="match-card__team-image" style={{ backgroundImage: `url(${homeImg})` }} />
      <div className="match-card__team-image" style={{ backgroundImage: `url(${awayImg})` }} />
    </div>
  );
}

Match.propTypes = {
  homeImg: PropTypes.string,
  awayImg: PropTypes.string,
};
