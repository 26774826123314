import './sidebar.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Tabs from './tabs';
import SubTabs from './sub-tabs';
import Players from './players';
import Notes from './notes';
import History from './history';
import Icon from '../../Icon';

import { getWindowHeight, getWindowWidth } from '../../../helpers';
import { toggleSidebar, hideControls } from '../../../actions/match';

@connect((state) => ({
  match: state.match,
}), { toggleSidebar, hideControls })

export default class Sidebar extends Component {
  static propTypes = {
    toggleSidebar: PropTypes.func,
    hideControls: PropTypes.func,
    match: PropTypes.object,
    tabHeight: PropTypes.shape({
      desktop: PropTypes.number,
      mobile: PropTypes.number,
    }),
    notesMarginBottom: PropTypes.number,
  };

  static defaultProps = {
    tabHeight: {
      desktop: 50,
      mobile: 32,
    },
    notesMarginBottom: 15,
  };

  constructor() {
    super();

    this.state = {
      height: getWindowHeight(),
    };

    this.handleResize = this.handleResize.bind(this);
    this.renderTabsContent = this.renderTabsContent.bind(this);
  }

  componentDidMount() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize() {
    this.setState({
      height: getWindowHeight(),
    });
  }

  renderTabsContent() {
    const { tab } = this.props.match.sidebar;
    const { height } = this.state;
    const { tabHeight, notesMarginBottom } = this.props;
    const mediaQuery = getWindowWidth() > 960 ? 'desktop' : 'mobile';

    switch (tab) {
      case 'Players':
        return [
          <SubTabs key="subtabs" />,
          <Players key="players" height={height - 2 * tabHeight[mediaQuery]} />,
        ];
      case 'Teamnotes':
        return <Notes height={height - tabHeight[mediaQuery] - notesMarginBottom} />;
      case 'History':
        return <History height={height - 2 * tabHeight[mediaQuery]} />;
      default:
        return null;
    }
  }

  render() {
    const { match } = this.props;
    const { layout, controls } = this.props.match;

    return (
      <div className="sidebar">
        <a
          className="toggle-btn"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            if (controls.inner === 'more' || controls.inner === 'notes') {
              this.props.hideControls();
              this.props.toggleSidebar(true);
            } else {
              this.props.hideControls();
              this.props.toggleSidebar(!layout.sidebar);
            }
          }}
        >
          <Icon name="menu" />
        </a>

        <Tabs />
        { this.renderTabsContent() }
      </div>
    );
  }
}
