import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { NotificationManager } from '../../notifications';
import { validateEmail } from '../../../helpers';

import { createInvite } from '../../../actions/invites';

import './invite-form.scss';
import { connect } from 'react-redux';
import eventTracker from '../../../helpers/event-tracker';

@withTranslation()
@connect((state) => {
  return {};
}, { createInvite })
class InviteForm extends Component {
  constructor(props) {
    super(props);

    const link = `${process.env.NODE_ENV === 'production' ? 'https://app.scoutpad.de' : window.location.origin}/registration/?ref=${props.group.referralCode}`;
    this.state = {
      link,
      tab: 'email',
      email: '',
    };

    this.copyLinkToClipboard = this.copyLinkToClipboard.bind(this);
    this.renderInviteEmail = this.renderInviteEmail.bind(this);
    this.renderInviteTabs = this.renderInviteTabs.bind(this);
    this.renderInviteLink = this.renderInviteLink.bind(this);
    this.sendInvite = this.sendInvite.bind(this);
  }

  sendInvite(e) {
    e.preventDefault();
    const { email } = this.state;

    if (!validateEmail(email)) {
      NotificationManager.create({
        type: 'error',
        message: 'EMAIL_ERROR',
        timeOut: 3000,
      });
      return null;
    }

    return this
      .props
      .createInvite(email).then((action) => {
        if (!action.payload.data.success) {
          NotificationManager.create({
            type: 'error',
            message: action.payload.data.message,
            timeOut: 3000,
          });
        } else {
          eventTracker.trackEvent('Referral Program', 'Send Invite');
          NotificationManager.create({
            type: 'success',
            message: 'USER_INVITED',
            timeOut: 3000,
          });
          this.setState({ email: '' });
        }
      });
  }

  copyLinkToClipboard(e) {
    e.preventDefault();
    navigator.clipboard.writeText(this.state.link);
    eventTracker.trackEvent('Referral Program', 'Copy referral link');
    NotificationManager.create({
      message: 'COPIED',
      timeOut: 3000,
    });
  }

  renderInviteTabs() {
    const { tab } = this.state;
    const { t } = this.props;
    return (
      <div className="earn__invite-tabs">
        <ul>
          <li
            className={tab === 'email' ? 'active' : ''}
            onClick={() => { this.setState({ tab: 'email' }); }}
          >
            {t('EMAIL')}
          </li>
          <li
            className={tab === 'link' ? 'active' : ''}
            onClick={() => { this.setState({ tab: 'link' }); }}
          >
            {t('COPY_LINK')}
          </li>
        </ul>
      </div>
    );
  }

  renderInviteEmail() {
    const { t } = this.props;
    const { email } = this.state;
    return (
      <form className="earn__invite-email earn__invite-form">
        <input
          type="email"
          placeholder={t('EMAIL_INVITE_PLACEHOLDER')}
          value={email}
          onChange={(e) => { this.setState({ email: e.target.value }); }}
        />
        <button className="btn" onClick={this.sendInvite}>{t('SEND_INVITE')}</button>
      </form>
    );
  }

  renderInviteLink() {
    const { t } = this.props;
    return (
      <form className="earn__invite-link earn__invite-form">
        <input
          type="text"
          value={this.state.link}
          onFocus={(e) => {
            e.target.select();
            this.copyLinkToClipboard(e);
          }}
        />
        <button className="btn" onClick={this.copyLinkToClipboard}>{t('COPY')}</button>
      </form>
    );
  }

  render() {
    const { tab } = this.state;
    return (
      <div className="earn__invite narrow-container">
        {this.renderInviteTabs()}
        {
          tab === 'email' ? this.renderInviteEmail() : this.renderInviteLink()
        }
      </div>
    );
  }
}

InviteForm.propTypes = {
  t: PropTypes.func,
  group: PropTypes.object,
  createInvite: PropTypes.func,
};

export default InviteForm;
