import React from 'react';
import PropTypes from 'prop-types';
import './team.scss';

export default function Team({ name, img }) {
  return (
    <div className="match-card__team">
      <div className="match-card__team-image" style={{ backgroundImage: `url(${img})` }} />
      <span className="match-card__name">{name}</span>
    </div>
  );
}

Team.propTypes = {
  name: PropTypes.string,
  img: PropTypes.string,
};
