import './match-title.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import VirtualizedSelect from 'react-virtualized-select';
import { withTranslation } from 'react-i18next';
import isTouchDevice from 'is-touch-device';

import { changeGoals, changeFormation } from '../../../../actions/match';
import { getPositionMatch } from '../../../../helpers';

@withTranslation()
@connect((state) => ({
  teams: state.teams,
  stadiums: state.stadiums,
  leagues: state.leagues,
  match: state.match,
  formations: state.formations,
  positions: state.positions,
}), { changeGoals, changeFormation })

export default class Title extends Component {
  static propTypes = {
    teams: PropTypes.object,
    stadiums: PropTypes.object,
    leagues: PropTypes.object,
    match: PropTypes.object,
    formations: PropTypes.object,
    positions: PropTypes.object,
    changeGoals: PropTypes.func,
    changeFormation: PropTypes.func,
  };

  constructor() {
    super();

    this.changeFormationHandler = this.changeFormationHandler.bind(this);
  }

  changeFormationHandler({ side, formation_id }) {
    const { formations, changeFormation, positions } = this.props;

    const formationPosition = formations[formation_id]?.positions?.filter((p) => p.position_id !== null);

    const formation_detail = {
      ...formations[formation_id],
      positions: formation_id ? formationPosition
        .filter((position) => position.position_id_detail)
        .map((position) => ({
          ...position,
          left: getPositionMatch(positions[position.position_id_detail].desc_short, side).x,
          top: getPositionMatch(positions[position.position_id_detail].desc_short, side).y,
        })) : [],
    };

    changeFormation({ side, formation_id, formation: formation_detail });
  }

  render() {
    const {
      match, teams, stadiums, changeGoals, formations, t, i18n,
    } = this.props;
    const lng = i18n.language;
    if (!_.size(match.data) || !_.size(teams) || !_.size(stadiums) || !_.size(formations)) return <div className="match-title" />;

    const formationOptions = [
      ..._.chain(formations).map((formation) => ({ label: formation.name, value: formation.id })).orderBy('label', 'asc').value(),
    ];

    return (
      <div className="match-title">
        <div className="title-teams">
          <div className="home">
            <div className="team">
              <div className="team-image" style={{ backgroundImage: `url(https://static.sp7f.com/teams/${match.data.home.team_id}.jpg)` }} />
              <div className="team-name">{ _.get(teams, `[${match.data.home.team_id}].name_${lng}`, _.get(teams, `[${match.data.home.team_id}].name`, t('UNKNOWN'))) }</div>
            </div>
            <VirtualizedSelect
              options={formationOptions}
              value={match.data.home.formations ? _.get(match.data.home.formations[match.snapshot], 'id', 0) : 0}
              clearable={false}
              isSearchable={!isTouchDevice()}
              placeholder={t('SELECT_LINEUP')}
              onChange={(value) => {
                this.changeFormationHandler({ side: 'home', formation_id: value.value });
              }}
            />
          </div>
          <div className="goals">
            <div className="goals-main">
              <textarea
                placeholder="–"
                maxLength="1"
                onChange={(e) => { changeGoals({ key: 'homegoals', value: e.target.value }); }}
                value={(match.data.homegoals || match.data.homegoals == 0) ? match.data.homegoals : ''}
              />
              :
              <textarea
                placeholder="–"
                maxLength="1"
                onChange={(e) => { changeGoals({ key: 'awaygoals', value: e.target.value }); }}
                value={(match.data.awaygoals || match.data.awaygoals == 0) ? match.data.awaygoals : ''}
              />
            </div>
            <div className="goals-ht">
              <textarea
                placeholder="–"
                maxLength="1"
                onChange={(e) => { changeGoals({ key: 'homegoals_ht', value: e.target.value }); }}
                value={(match.data.homegoals_ht || match.data.homegoals_ht == 0) ? match.data.homegoals_ht : ''}
              />
              :
              <textarea
                placeholder="–"
                maxLength="1"
                onChange={(e) => { changeGoals({ key: 'awaygoals_ht', value: e.target.value }); }}
                value={(match.data.awaygoals_ht || match.data.awaygoals_ht == 0) ? match.data.awaygoals_ht : ''}
              />
            </div>
          </div>
          <div className="away">
            <div className="team">
              <div className="team-image" style={{ backgroundImage: `url(https://static.sp7f.com/teams/${match.data.away.team_id}.jpg)` }} />
              <div className="team-name">{ _.get(teams, `[${match.data.away.team_id}].name_${lng}`, _.get(teams, `[${match.data.away.team_id}].name`, t('UNKNOWN'))) }</div>
            </div>
            <VirtualizedSelect
              options={formationOptions}
              value={match.data.away.formations ? _.get(match.data.away.formations[match.snapshot], 'id', 0) : 0}
              clearable={false}
              searchable={!isTouchDevice()}
              placeholder={t('SELECT_LINEUP')}
              onChange={(value) => {
                this.changeFormationHandler({ side: 'away', formation_id: value.value });
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
