import './substitutions.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Side from './side';

@connect((state) => ({
  match: state.match,
}))

export default class Substitutions extends Component {
  static propTypes = {
    match: PropTypes.object,
  };

  render() {
    const { width } = this.props.match.layout;

    return (
      <div className="substitutions" style={{ width }}>
        <Side side="home" />
        <Side side="away" />
      </div>
    );
  }
}
