import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import ReactTooltip from 'react-tooltip';
import { ROOT_URL } from '../../../actions';
import './analytics.scss';
import { connect } from 'react-redux';
import PlayerImage from '../../player-image';
import DataItem from '../components/data_item';
import { withTranslation } from 'react-i18next';
import { getPlayerImage, getLeagueImage, getSeasonFromDate } from '../../../helpers';
import { canUseAnalyticsSection } from '../../../helpers/limits';
import DataItemIcon from '../components/data_item_icon';
import Cta from './cta';

import { INDICATORS_BY_POSITION } from '../../../constants';
import eventTracker from '../../../helpers/event-tracker';

@withTranslation(['translation', 'indicators'])
@connect((state) => {
  return {
    teams: state.teams,
    leagues: state.leagues,
    info: state.favorites.detail,
    positions: state.positions,
    favorites: state.favorites,
    subscription: state.subscription,
  };
}, {})
class Analytics extends Component {
  constructor(props) {
    super(props);

    this.state = {
      img: null,
      loading: false,
    };

    this.getSvgChart = this.getSvgChart.bind(this);
  }

  getSvgChart(info, teams, leagues, season, league, subscription) {
    const { player } = info;

    if (!canUseAnalyticsSection(subscription)) {
      return null;
    }

    if (player.player_id === 'demo') {
      const controller = new AbortController();
      const request = axios.get(`${ROOT_URL}/players/analytics/${133798}/${getSeasonFromDate(new Date())}/${220}/${7}`, { signal: controller.signal });
      this.setState({ loading: true, request: controller });
      request.then((result) => {
        let { data } = result;
        data = data.replaceAll('data-indicator', 'data-tip');
        ReactTooltip.rebuild();
        this.setState({ img: data, loading: false, request: null });
      }).catch((err) => { console.log(err.message); });
      return;
    }

    const team_id = _.get(player, 'team_id', null);
    const team = _.get(teams, `[${team_id}]`, null);
    const team_league = team ? team.standard_league : null;
    const qLeague = !league ? _.get(leagues, `[${team_league}].league_id_master`, null) : _.get(leagues, `[${league}].league_id_master`, null);
    const qSeason = !season ? getSeasonFromDate(new Date()) : season;
    const qPosition = player.position_id;

    const controller = new AbortController();
    const request = axios.get(`${ROOT_URL}/players/analytics/${player.player_id}/${qSeason}/${qLeague}/${qPosition}`, { signal: controller.signal });

    this.setState({ loading: true, request: controller });

    request.then((result) => {
      let { data } = result;
      data = data.replaceAll('data-indicator', 'data-tip');
      if (data !== 'ERR_NO_DATA') {
        eventTracker.trackEvent('Analytics', 'Load analytics chart for the player', 'Data exist');
      } else {
        eventTracker.trackEvent('Analytics', 'Load analytics chart for the player', 'No Data exist');
      }
      this.setState({ img: data, loading: false, request: null });
      ReactTooltip.rebuild();
    }).catch((err) => { console.log(err.message); });
  }

  componentDidMount() {
    const {
      info, teams, leagues, subscription,
    } = this.props;
    const { season, league } = this.props;

    if (!info.id) return;

    if (teams && leagues && info && subscription) {
      this.getSvgChart(info, teams, leagues, season, league, subscription);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      info, teams, leagues, subscription,
    } = this.props;
    const { season, league } = this.props;

    if (!info.id) return;

    const { id } = info;
    const oldId = prevProps.info.id;
    const oldSeason = prevProps.season;
    const oldLeague = prevProps.league;

    if ((id !== oldId || oldSeason !== season || league !== oldLeague) && teams && leagues && info && subscription) {
      this.getSvgChart(info, teams, leagues, season, league, subscription);
    }
  }

  componentWillUnmount() {
    if (this.state.request) {
      this.state.request.abort();
    }
  }

  renderPlayer() {
    const {
      teams, leagues, t, i18n, info, league, season, stats,
    } = this.props;

    const lng = i18n.language;

    const { player } = info;

    if (!player) return null;

    const isCustom = new RegExp('new\-', 'ig').test(player.player_id);

    const first_name = _.get(player, 'first_name', '');
    const last_name = _.get(player, 'last_name', '');
    let player_id = _.get(player, 'player_id', 'placeholder');
    player_id = player_id === 'demo' ? 133798 : player_id;
    const country = _.get(player, 'country', null);
    const playerImage = getPlayerImage(player);

    const team_id = _.get(player, 'team_id', null);
    const team = _.get(teams, `[${team_id}]`, null);
    const team_league = team ? team.standard_league : null;
    const playerLeague = _.get(leagues, `[${team_league}]`, null);
    const meanLeague = league ? _.get(leagues, `[${league}]`, null) : playerLeague;
    const team_league_name = _.get(playerLeague, `name_${lng}`, _.get(playerLeague, 'name', t('UNKNOWN_LEAGUE')));
    const leagueImage = playerLeague ? getLeagueImage(playerLeague.league_id_master) : null;

    const meanLeagueName = _.get(meanLeague, `name_${lng}`, _.get(meanLeague, 'name', t('UNKNOWN_LEAGUE')));
    const meanLeagueImage = meanLeague ? getLeagueImage(meanLeague.league_id_master) : null;

    const matchesStats = stats.reduce((accumulator, matchStat) => {
      return {
        mins: accumulator.mins + matchStat.mins,
        goals: accumulator.goals + matchStat.goals,
        assists: accumulator.assists + matchStat.assists,
        red: accumulator.red + matchStat.red,
        yellow: accumulator.yellow + matchStat.yellow,
      };
    }, {
      mins: 0, goals: 0, assists: 0, yellow: 0, red: 0, positions: {},
    });

    return (
      <div className="dw-analytics__info">
        <div className="content-block">
          <div className="content-pad">
            <div className="content-row">
              <div className="player-data__image">
                <PlayerImage className="player-image" src={playerImage} />
              </div>
              <div className="player-data__passport">
                <div className="player-data__firstName">
                  {first_name}
                </div>
                <div className="player-data__lastName">
                  {last_name}
                </div>
                <div className="player-data__nation">{country}</div>
              </div>
            </div>
            <div className="content-row">
              <div className="content-col-50">
                <DataItem label={t('LEAGUE')} value={team_league_name} image={leagueImage} />
              </div>
              <div className="content-col-50">
                <DataItem label={t('SEASON')} value={!season ? getSeasonFromDate(new Date()) : season} />
              </div>
            </div>
          </div>
          <hr />
          <div className="content-pad">
            {season ? (<h4>{t('STATS_OF_SEASON', { season })}</h4>) : (<h4>{t('STATS_OF_ALL_SEASONS')}</h4>) }
            <div className="content-row">
              <DataItemIcon icon="pitch" label={t('MATCHES')} value={stats.length} />
              <DataItemIcon icon="mins" label={t('MINS')} value={matchesStats.mins} />
            </div>
            <div className="content-row">
              <DataItemIcon icon="goals" label={t('GOALS')} value={matchesStats.goals} />
              <DataItemIcon icon="assists" label={t('ASSISTS')} value={matchesStats.assists} />
            </div>
            <div className="content-row">
              <DataItemIcon icon="yellow" label={t('YELLOWS')} value={matchesStats.yellow} />
              <DataItemIcon icon="red" label={t('RED')} value={matchesStats.red} />
            </div>

          </div>
        </div>

        <div className="content-block">
          <div className="content-pad">
            <h4>{t('COMPARE_TO_AVG')}</h4>
            <div className="content-row">
              <div className="content-col-50">
                <DataItem label={t('LEAGUE')} value={meanLeagueName} image={meanLeagueImage} />
              </div>
              <div className="content-col-50">
                <DataItem label={t('SEASON')} value={season || getSeasonFromDate(new Date())} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderChart() {
    const { t, info, subscription } = this.props;
    const { loading } = this.state;

    const { id } = info;
    const isCustom = new RegExp('new', 'ig').test(id);

    if (!canUseAnalyticsSection(subscription)) {
      return (<div className="dw-analytics__chart"><Cta /></div>);
    }

    if (isCustom) {
      return (<div className="dw-analytics__chart">{t('NO_ANALYTICS_FOR_CUSTOM_PLAYER', { context: window.genderContext })}</div>);
    }

    if (this.state.img === 'ERR_NO_DATA') {
      return (<div className="dw-analytics__chart">{t('NO_ANALYTICS_CHART_DATA')}</div>);
    }

    return (
      !loading ? <div className="dw-analytics__chart" dangerouslySetInnerHTML={{ __html: this.state.img }} /> : (
        <div className="dw-analytics__chart">
          {t('LOADING')}
          ...
        </div>
      )
    );
  }

  renderHelp() {
    const { t, info, positions } = this.props;

    const { id } = info;
    const isCustom = new RegExp('new', 'ig').test(id);
    const { player } = info;

    if (isCustom || !player || !player.position_id) {
      return null;
    }
    const player_position_id = player.position_id;
    let player_position = _.get(positions, `[${player_position_id}]`, null);
    if (!player_position) {
      return null;
    }

    if (!player_position.upper_position) {
      player_position = _.get(positions, `[${player_position.upper_position_id}]`, null);
    }

    const positionName = player_position.desc_short;
    const indicators = INDICATORS_BY_POSITION[positionName];

    if (!indicators || !indicators.length) {
      return null;
    }

    return (
      <ReactTooltip
        className="indicator-tip"
        html
        getContent={(dataTip) => {
          return `
          <strong>${t(dataTip, { ns: 'indicators' })}</strong><br />
          <span>${t(`${dataTip}_description`, { ns: 'indicators' })} ${t('PER_90_MINUTES')}</span>
        `;
        }}
      />
    );
  }

  render() {
    return (
      <div className="dw-analytics">
        <div className="dw-analytics__content">
          {this.renderPlayer()}
          {this.renderChart()}
          {this.renderHelp()}
        </div>
      </div>
    );
  }
}

Analytics.propTypes = {};

export default Analytics;
