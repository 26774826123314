import axios from 'axios';
import localforage from 'localforage';
import _ from 'lodash';

import { ROOT_URL } from '../actions';

export default function (props) {
  switch (window.online) {
    case true:
      return axios
        .get(`${ROOT_URL}/positions`, {
          params: props,
        })
        .then((res) => {
          const positions = _.keyBy(res.data.positions, 'id');

          localforage.setItem('positions', JSON.stringify(positions));
          return Promise.resolve({ positions });
        })
        .catch((err) => {
          console.error(err);
        });

    case false:
      return localforage
        .getItem('positions')
        .then((positions) => Promise.resolve({ positions: JSON.parse(positions) }))
        .catch((err) => {
          console.error(err);
        });
  }
}
