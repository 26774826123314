import _ from 'lodash';
import {
  FETCH_REPORTS,
  CLEAR_REPORTS,
  SET_REPORTS_LOADING,
  DELETE_REPORT,
  UPDATE_REPORT,
  CHANGE_REPORT_FILTER,
  GENERATE_REPORT_FILTERS,
  FETCH_REPORTS_OVERVIEW,
  FETCH_DEMO_REPORTS,
  FETCH_OFFLINE_REPORTS,
} from '../actions/reports';

const filtersList = [
  {
    field: 'status',
    type: 'select',
    name: 'MATCHES',
    options: [
      {
        value: null,
        label: 'ALL_MATCHES',
      },
      {
        value: 'not_started',
        label: 'NOT_STARTED',
      },
      {
        value: 'in_progress',
        label: 'IN_PROGRESS',
      },
      {
        value: 'completed',
        label: 'COMPLETED',
      },
    ],
  },
  {
    field: 'user_id',
    type: 'select',
    name: 'USERS',
    options: [
      {
        value: null,
        label: 'ALL_USERS',
      },
    ],
  },
  {
    field: 'league_id',
    type: 'select-leagues',
    name: 'LEAGUES',
    options: [
      {
        value: null,
        label: 'ALL_LEAGUES',
      },
    ],
  },
  {
    field: 'team_id',
    type: 'select-teams',
    name: 'TEAMS',
    options: [
      {
        value: null,
        label: 'ALL_TEAMS',
      },
    ],
  },
  {
    field: 'date',
    type: 'date-range',
    name: 'TIMEFRAME',
  },
  {
    field: 'assigned',
    type: 'checkbox',
    name: 'ONLY_REPORTS_ASSIGNED_TO_ME',
  },
];

const INITIAL_STATE = {
  total: null,
  list: [],
  reportsPerPage: 25,
  offset: 0,
  isListLoading: false,
  hasNextPage: false,
  filterId: null,
  sortId: null,
  listError: null,
  players: {},
  filtersList,
  filters: {},
  overview: {
    upcoming: [],
    player: [],
    team: [],
    group: [],
  },
  demoSingle: null,
  demoMatch: null,
  offline: [],
  offlinePlayers: {},
};

export default function ReportsReducer(state = INITIAL_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_OFFLINE_REPORTS: {
      const offline = payload[0] || [];
      const offlinePlayers = payload[1] || [];

      const offlinePlayersByKey = _.keyBy(offlinePlayers, 'player_id');
      return {
        ...state,
        offline,
        offlinePlayers: offlinePlayersByKey,
      };
    }

    case CLEAR_REPORTS: {
      return {
        ...state,
        list: [],
        total: 0,
        offset: 0,
      };
    }

    case FETCH_DEMO_REPORTS: {
      const { data } = payload;
      const playersByKey = _.keyBy(data.players, 'player_id');
      if (data.success) {
        return {
          ...state,
          players: { ...state.players, ...playersByKey },
          demoSingle: data.singleMatch,
          demoMatch: data.match,
        };
      }
      return state;
    }
    case FETCH_REPORTS_OVERVIEW: {
      const { data } = payload;

      if (!data) {
        return { ...state, listError: null };
      }

      const { success, message } = data;

      if (success) {
        const {
          player, team, group, upcoming, players,
        } = data;
        const { mmPlayers, playerEntries } = players;
        const playerEntriesByKey = _.chain(playerEntries).uniqBy('player_id').keyBy('player_id').value();

        const mergedMMPlayers = mmPlayers.map((p) => {
          const entry = playerEntriesByKey[p.player_id];
          if (entry) {
            const mergedPlayer = { ...p, ...entry };
            delete playerEntriesByKey[p.player_id];
            return mergedPlayer;
          }
          return p;
        });
        const mmPlayersByKey = _.keyBy(mergedMMPlayers, 'player_id');
        const allPlayers = { ...mmPlayersByKey, ...playerEntriesByKey };

        return {
          ...state,
          players: { ...state.players, ...allPlayers },
          overview: {
            ...state.overview,
            upcoming: [...upcoming],
            player: [...player],
            team: [...team],
            group: [...group],
          },
        };
      }

      return { ...state, listError: message };
    }
    case FETCH_REPORTS: {
      const { data } = payload;

      if (!data) {
        return { ...state, listError: null };
      }

      const { success, message } = data;
      if (success) {
        const { total, reports, players } = data;
        const { mmPlayers, playerEntries } = players;

        const mmPlayersByKey = _.keyBy(mmPlayers, 'player_id');
        const playerEntriesByKey = _.chain(playerEntries).uniqBy('player_id').keyBy('player_id').value();
        const allPlayers = { ...mmPlayersByKey, ...playerEntriesByKey };

        return {
          ...state,
          isListLoading: false,
          list: [...state.list, ...reports],
          total,
          hasNextPage: state.list.length < total,
          offset: data.offset,
          players: { ...state.players, ...allPlayers },
          listError: null,
        };
      }
      return { ...state, listError: message };
    }

    case GENERATE_REPORT_FILTERS: {
      const { usersOptions, canUseGroup, isAdmin } = payload;

      const newFilterList = [
        ...state.filtersList.slice(0, 1),
        {
          ...state.filtersList[1],
          options: [
            ...state.filtersList[1].options,
            ...usersOptions,
          ],
        },
        ...state.filtersList.slice(2),
      ].filter((f) => {
        if (f.field === 'user_id' && (!canUseGroup || !isAdmin)) {
          return false;
        }
        if (f.field === 'assigned' && !canUseGroup) {
          return false;
        }
        return true;
      });

      return {
        ...state,
        filtersList: [
          ...newFilterList,
        ],
      };
    }

    case SET_REPORTS_LOADING:
      return {
        ...state,
        isListLoading: payload,
      };

    case UPDATE_REPORT: {
      if (payload?.data?.success) {
        const match = payload?.data?.match;
        const match_id = match?._id;
        ['home', 'away'].map((s) => { match[s] = { team_id: match[s].team_id }; });
        const index = state.list.findIndex((m) => m._id === match_id);

        const newOverview = {};
        ['player', 'team'].map((section) => {
          const arr = state.overview[section];
          const index = arr.findIndex((m) => m._id === match_id);
          if (index !== -1) {
            newOverview[section] = [...arr.slice(0, index), { ...match }, ...arr.slice(index + 1)];
          }
        });

        return {
          ...state,
          list: [...state.list.slice(0, index), { ...match }, ...state.list.slice(index + 1)],
          overview: {
            ...state.overview,
            ...newOverview,
          },
        };
      }
      return {
        ...state,
        listError: 'CANT_UPDATE_MATCH',
      };
    }

    case CHANGE_REPORT_FILTER: {
      return {
        ...state,
        filters: payload,
      };
    }

    case DELETE_REPORT: {
      const index = state.list.findIndex((m) => m._id === payload);
      return {
        ...state,
        list: [...state.list.slice(0, index), ...state.list.slice(index + 1)],
        total: state.total - 1,
      };
    }
    default: {
      return state;
    }
  }
}
