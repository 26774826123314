import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  fetchUpcoming, clearUpcoming, setUpcomingLoading, changeUpcomingFilter,
} from '../../actions/upcoming_matches';

import { updateReportsLimits } from '../../actions/subscription';
import { fetchAddMatch } from '../../actions';

import UpcomingList from './components/upcoming-list';
import ReportsControls from './components/reports-controls';

function UpcomingPage({
  upcoming,
  teams,
  leagues,
  user,
  setUpcomingLoading,
  positions,
  fetchUpcoming,
  clearUpcoming,
  subscription,
  updateReportsLimits,
  fetchAddMatch,
  changeUpcomingFilter,
}) {
  const {
    list, total, isListLoading, reportsPerPage, hasNextPage, offset, filters, filtersList,
  } = upcoming;

  const loadUpcoming = (filters, offset) => {
    setUpcomingLoading(true);
    return fetchUpcoming(filters, offset, reportsPerPage);
  };

  const startLoadingFromBeginning = () => {
    clearUpcoming();
    loadUpcoming(filters, 0);
  };

  useEffect(() => {
    startLoadingFromBeginning();
  }, [filters]);

  const loadNextPage = () => {
    if (hasNextPage) {
      return loadUpcoming(filters, offset);
    }
    return null;
  };

  return (
    <div className="reports__wrapper">
      <ReportsControls
        filters={filters}
        filtersList={filtersList}
        changeFilters={changeUpcomingFilter}
        teams={teams}
        leagues={leagues}
        positions={positions}
        type="upcoming"
      />
      <UpcomingList
        list={list}
        teams={teams}
        leagues={leagues}
        hasNextPage={list.length < total}
        isListLoading={isListLoading}
        loadNextPage={loadNextPage}
        total={total}
        authUser={user}
        reportsPerPage={reportsPerPage}
        subscription={subscription}
        updateReportsLimits={updateReportsLimits}
        fetchAddMatch={fetchAddMatch}
      />
    </div>
  );
}

export default connect((state, props) => {
  return {
    teams: state.teams,
    leagues: state.leagues,
    users: state.users,
    upcoming: state.upcomingMatches,
    reports: state.reports,
    positions: state.positions,
    subscription: state.subscription,
    user: state.auth.user,
    type: props.params.type,
  };
}, {
  fetchUpcoming, clearUpcoming, setUpcomingLoading, updateReportsLimits, fetchAddMatch, changeUpcomingFilter,
})(UpcomingPage);

UpcomingPage.propTypes = {
  type: PropTypes.string,
  fetchUpcoming: PropTypes.func,
  teams: PropTypes.object,
  positions: PropTypes.object,
  leagues: PropTypes.object,
  reports: PropTypes.object,
  upcoming: PropTypes.object,
  user: PropTypes.object,
  clearUpcoming: PropTypes.func,
  setUpcomingLoading: PropTypes.func,
  subscription: PropTypes.object,
  updateReportsLimits: PropTypes.func,
  fetchAddMatch: PropTypes.func,
  changeUpcomingFilter: PropTypes.func,
};
