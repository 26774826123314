import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DropTarget } from 'react-dnd';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';

import { changeSubstitution, selectSubstitution } from '../../../../../actions/match';

import Player from './player';
import PlayerImage from '../../../../player-image';
import { getPlayerImage } from '../../../../../helpers';

@withTranslation()
@connect((state) => ({
  match: state.match,
}), { changeSubstitution, selectSubstitution })

@DropTarget('player', {
  canDrop(props, monitor) {
    const { side } = monitor.getItem();
    return props.side === side && props.match.snapshot === 'lineup';
  },

  drop(props, monitor) {
    if (monitor.didDrop()) return;

    const { information } = monitor.getItem();
    const { side } = props;

    if (!(_.filter(props.match.current_positions[side][props.match.snapshot], (player) => player.substitution && player.player_id !== information.key).length === 9)) {
      props.changeSubstitution({ side, information });
    }
  },
}, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  isOverCurrent: monitor.isOver({ shallow: true }),
  canDrop: monitor.canDrop(),
  itemType: monitor.getItemType(),
}))

export default class Substitutions extends Component {
  static propTypes = {
    connectDropTarget: PropTypes.func,
    isOver: PropTypes.bool,
    isOverCurrent: PropTypes.bool,
    side: PropTypes.string.isRequired,
    match: PropTypes.object,
    max: PropTypes.number,
    selectSubstitution: PropTypes.func,
    changeSubstitution: PropTypes.func,
  };

  static defaultProps = {
    max: 9,
  };

  constructor() {
    super();

    this.renderPlayers = this.renderPlayers.bind(this);
  }

  renderPlayers() {
    const {
      side, match, max, selectSubstitution, changeSubstitution, t,
    } = this.props;
    const {
      snapshot, current_positions, data, substitutionSelected,
    } = match;

    if (!_.size(current_positions)) return _.times(max, (key) => <div key={key} className="substitution" />);

    const players = _
      .chain(_.get(current_positions, `${side}[${snapshot}]`, []))
      .filter((player) => player.substitution)
      .filter((player) => player.player_id)
      .orderBy((player) => data[side].players[player.player_id].information.jersey)
      .map((player) => <Player key={player.player_id} {...{ side, player }} />)
      .value();

    const allPlayers = _
      .chain(_.get(current_positions, `${side}[${snapshot}]`, []))
      .filter((player) => !match.current_positions[side].lineup[player.player_id].active && !match.current_positions[side].lineup[player.player_id].substitution)
      .filter((player) => !player.substitution)
      .filter((player) => player.player_id)
      .map((player) => data[side].players[player.player_id])
      .orderBy((player) => (player.information.jersey))
      .value();

    const available = _.times(max - players.length, (key) => (
      <div key={key} className="substitution" onClick={() => { selectSubstitution({ side, index: key }); }}>
        <div className="substitution-target" />
        {
          substitutionSelected.index === key && substitutionSelected.side === side
            ? (
              <div className="position-players-list" style={{ left: side === 'home' ? '50%' : 'auto', right: side === 'away' ? '50%' : 'auto' }}>
                {
                allPlayers.map((player) => (
                  <div className="player" key={player.player_id} onClick={() => { changeSubstitution({ side, information: player.information }); }}>
                    <div className="player__number">{ player.information.jersey }</div>
                    <PlayerImage className="player__image" src={getPlayerImage(player.information)} />
                    <div className="player__name">
                      { player.information.first_name !== player.information.last_name ? player.information.first_name : null }
                      {' '}
                      { player.information.last_name }
                    </div>
                  </div>
                ))
              }
                { !allPlayers.length ? <div className="no-players">{t('NO_PLAYERS')}</div> : null }
              </div>
            )
            : null
        }
      </div>
    ));

    return [...players, ...available];
  }

  render() {
    const { side, connectDropTarget } = this.props;

    return connectDropTarget(<div className={side}>
      {' '}
      <div className="substitutions-list">
        {' '}
        { this.renderPlayers() }
        {' '}
      </div>
      {' '}
                             </div>);
  }
}
