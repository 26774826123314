import { FETCH_COLORS } from '../actions/index';

const INITIAL_STATE = {
  list: [],
  message: null,
};

export default function (state = INITIAL_STATE, action) {
  let data = {};
  switch (action.type) {
    case FETCH_COLORS:
      data = action.payload.data;
      if (data.success) {
        return {
          ...state,
          list: data.colors,
          message: null,
        };
      }
      return {
        ...state,
        message: data.message,
      };
    default:
      return state;
  }
}
