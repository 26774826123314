import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Foo extends Component {
  static displayName = 'Foo';

  render() {
    return (
      <div>
        component foo
      </div>
    );
  }
}

Foo.propTypes = {};

export default Foo;
