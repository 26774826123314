import React from 'react';
import './small-loader.scss';

export default function () {
  return (
    <span className="inline-loader-container">
      <span className="inline-loader" />
    </span>
  );
}
