import React from 'react';
import './player-view.scss';
import { getPlayerImage } from '../../../../helpers';

export default function (props) {
  return (
    <div className="player-view">
      <div
        className="player-view__img"
        style={{ backgroundImage: `url(${getPlayerImage(props.data)})` }}
      />
      <div className="player-view__label">
        {props.label}
        {props.team ? (
          <span className="player-view__team-name">
            <span className="player-view__team-img" style={{ backgroundImage: `url(${props.teamLogo})` }} />
            {props.team}
          </span>
        ) : null}
      </div>
    </div>
  );
}
