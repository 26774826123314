import axios from 'axios';
import _ from 'lodash';
import eventTracker, { EVENTS } from '../helpers/event-tracker';

import { ROOT_URL } from '.';

export const FETCH_SHADOW_TEAMS = 'FETCH_SHADOW_TEAMS';
export const CREATE_SHADOW_TEAM = 'CREATE_SHADOW_TEAM';
export const UPDATE_SHADOW_TEAM = 'UPDATE_SHADOW_TEAM';
export const COPY_SHADOW_TEAM = 'COPY_SHADOW_TEAM';
export const DELETE_SHADOW_TEAM = 'DELETE_SHADOW_TEAM';
export const OPEN_SHADOW_TAB = 'OPEN_SHADOW_TAB';
export const CLOSE_SHADOW_TAB = 'CLOSE_SHADOW_TAB';
export const FETCH_SHADOW_TABS = 'FETCH_SHADOW_TABS';

export const fetchShadowTeams = () => {
  const request = axios.get(`${ROOT_URL}/shadow-team/`);

  return {
    type: FETCH_SHADOW_TEAMS,
    payload: request,
  };
};

export const createShadowTeam = (team) => {
  const request = axios.post(`${ROOT_URL}/shadow-team/`, { team });
  eventTracker.trackEvent('Shadow team', 'Create shadow team');

  return {
    type: CREATE_SHADOW_TEAM,
    payload: request,
  };
};

export const updateShadowTeam = (t) => {
  const team = _.cloneDeep(t);

  if (team._id !== 'demo') {
    axios.post(`${ROOT_URL}/shadow-team/${team._id}`, { team });
  }
  eventTracker.trackEvent('Shadow team', 'Update shadow team');

  return {
    type: UPDATE_SHADOW_TEAM,
    payload: { data: t },
  };
};

export const copyShadowTeam = (id) => {
  if (id !== 'demo') {
    const request = axios.get(`${ROOT_URL}/shadow-team/copy/${id}`);
    eventTracker.trackEvent('Shadow team', 'Copy shadow team');
    return {
      type: COPY_SHADOW_TEAM,
      payload: request,
    };
  }
  return {
    type: 'EMPTY_REQUEST',
  };
};

export const deleteShadowTeam = (id) => {
  if (id !== 'demo') {
    const request = axios.delete(`${ROOT_URL}/shadow-team/${id}`);

    eventTracker.trackEvent('Shadow team', 'Delete shadow team');

    return {
      type: DELETE_SHADOW_TEAM,
      payload: request,
    };
  }
  return {
    type: 'EMPTY_REQUEST',
  };
};

export const fetchShadowTabs = (userId) => {
  return {
    type: FETCH_SHADOW_TABS,
    payload: { userId },
  };
};

export const openShadowTab = (id, userId) => {
  return {
    type: OPEN_SHADOW_TAB,
    payload: { id, userId },
  };
};

export const closeShadowTab = (id, userId) => {
  return {
    type: CLOSE_SHADOW_TAB,
    payload: { id, userId },
  };
};
