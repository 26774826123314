import React, { Component } from 'react';
import { Link, browserHistory } from 'react-router';
import { connect } from 'react-redux';

import {
  CardNumberElement, CardExpiryElement, CardCVCElement, injectStripe, IbanElement, PaymentRequestButtonElement,
} from 'react-stripe-elements';
import { withTranslation } from 'react-i18next';
import {
  addSourceToCustomer,
  addPaymentMethodToCustomer,
  getGroup,
  changeGroupPlan,
  fetchSetupIntent,
  fetchCoupons,
} from '../../../../../actions/group';
import { changeLoading } from '../../../../../actions';

@withTranslation()
@connect((state, own) => {
  return {
    user: state.auth.user,
    group: state.group,
    subscription: state.subscription,
    coupons: state.coupons,
  };
}, {
  addSourceToCustomer,
  getGroup,
  changeGroupPlan,
  changeLoading,
  fetchSetupIntent,
  addPaymentMethodToCustomer,
  fetchCoupons,
})
class AddCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: '',
      method: '',
      name: '',
      nameError: null,
      email: '',
      emailError: null,
      canMakePayment: false,
      paymentRequest: null,
    };

    this.handleCardSubmit = this.handleCardSubmit.bind(this);
    this.handleSEPASubmit = this.handleSEPASubmit.bind(this);
    this.createOptions = this.createOptions.bind(this);
    this.getAmountAndCurrencyAndCountryAndName = this.getAmountAndCurrencyAndCountryAndName.bind(this);
    this.addPaymentMethod.bind(this);
  }

  createOptions(fontSize, padding) {
    return {
      style: {
        base: {
          fontSize: '16px',
          color: '#424770',
          letterSpacing: '0.025em',
          fontFamily: 'Source Sans Pro, Helvetica, sans-serif',
          '::placeholder': {
            color: '#aab7c4',
          },
          ...(padding ? { padding } : {}),
        },
        invalid: {
          color: '#9e2146',
        },
      },
    };
  }

  componentDidMount() {
    const { group, stripe } = this.props;
    this.props.changeLoading({ component: 'payments', loading: true });
    const promises = [];
    promises.push(this.props.fetchCoupons());
    if (group) {
      const fName = _.get(group, 'firstName', '');
      const lName = _.get(group, 'lastName', '');
      const email = _.get(group, 'clubEmail', '');
      const name = `${fName ? `${fName} ` : ''}${lName}`;
      this.setState({
        name,
        email,
        method: 'Card',
      });
    } else {
      promises.push(this.props.getGroup());
    }

    Promise.all(promises).then(() => {
      const {
        amount, currency, country, name,
      } = this.getAmountAndCurrencyAndCountryAndName();

      const paymentRequest = stripe.paymentRequest({
        country: 'DE',
        currency,
        total: {
          label: name,
          amount,
          pending: true,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      paymentRequest.canMakePayment().then((r) => {
        this.props.changeLoading({ component: 'payments', loading: false });
        if (r) {
          this.setState({ paymentRequest, canMakePayment: true });
        }
      });

      paymentRequest.on('paymentmethod', (ev) => {
        const { stripe, query } = this.props;
        this.props.changeLoading({ component: 'payments', loading: true });
        this.props.fetchSetupIntent().then((result) => {
          const { client_secret } = result.payload.data;
          stripe.confirmCardSetup(
            client_secret,
            { payment_method: ev.paymentMethod.id },
            { handleActions: false },
          ).then((result) => {
            if (result.error) {
              this.props.changeLoading({ component: 'payments', loading: false });
              alert(result.error.message);
            } else {
              this.props
                .addPaymentMethodToCustomer(result.setupIntent)
                .then((result) => {
                  const res = result.payload.data;

                  if (res.success) {
                    if (!query.plan) {
                      browserHistory.push('/settings/billing');
                      document.location.reload();
                      this.props.changeLoading({ component: 'payments', loading: false });
                    } else {
                      this.props.changeGroupPlan(query.plan).then((res) => {
                        browserHistory.push('/settings/billing');
                        document.location.reload();
                        this.props.changeLoading({ component: 'payments', loading: false });
                      });
                    }
                  } else {
                    this.props.changeLoading({ component: 'payments', loading: false });
                    this.setState({ error: res.error });
                  }
                });
            }
          });
        });
      });

      const { location } = this.props;
      const source = _.get(location, 'query.source', null);

      if (source) {
        window.location.replace('/settings/billing/');
        document.location.reload();
      }
    });
  }

  componentDidUpdate(prevProps) {
    const { props } = this;

    if (props.group !== prevProps.group) {
      const { group } = props;
      const fName = _.get(group, 'firstName', '');
      const lName = _.get(group, 'lastName', '');
      const email = _.get(group, 'clubEmail', '');
      const name = `${fName ? `${fName} ` : ''}${lName}`;
      this.setState({
        name,
        email,
        method: 'Card',
      });
    }
  }

  render() {
    const {
      current, group, t,
    } = this.props;
    const { method, paymentRequest, canMakePayment } = this.state;

    return (
      <div className="billing-edit">
        <h1 className="settings-h1 overlay-h1">
          <Link to="/settings/billing">
            &lsaquo;
            {' '}
            {t('BACK')}
          </Link>
          {!current ? t('ADD_PAYMENT_METHOD') : t('CHANGE_PAYMENT_METHOD')}
        </h1>

        <p>{t('SECURE_PROCESS')}</p>

        {
          group && group.currency === 'eur' ? (
            <ul className="method-selector">
              <li
                className={method === 'Card' ? 'checked' : ''}
                onClick={() => {
                  this.setState({ method: 'Card' });
                }}
              >
                <span className={`radio ${method === 'Card' ? 'checked' : ''}`} />
                {t('CREDIT_CARD')}
              </li>
              <li
                className={method === 'SEPA' ? 'checked' : ''}
                onClick={() => {
                  this.setState({ method: 'SEPA' });
                }}
              >
                <span className={`radio ${method === 'SEPA' ? 'checked' : ''}`} />
                {t('BANK_TRANSFER_SEPA')}
              </li>

              { canMakePayment ? (
                <li
                  className={method === 'Mobile' ? 'checked' : ''}
                  onClick={() => {
                    this.setState({ method: 'Mobile' });
                  }}
                >
                  <span className={`radio ${method === 'Mobile' ? 'checked' : ''}`} />
                  {t('MOBILE_PAYMENT')}
                </li>
              ) : null }
            </ul>
          ) : null
        }
        {
          method === 'Card' ? (
            <div className="card-form">
              <div className="card-form-body">
                <div className="stripe-body">
                  <div className="stripe-number">
                    <span>{t('CARD_NUMBER')}</span>
                    <CardNumberElement onReady={(el) => el.focus()} {...this.createOptions()} />
                  </div>

                  <div className="stripe-expiration">
                    <span>{t('EXPIRATION_DATE')}</span>
                    <CardExpiryElement onReady={(el) => el.focus()} {...this.createOptions()} />
                  </div>

                  <div className="stripe-cvc">
                    <span>{t('CVC')}</span>
                    <CardCVCElement onReady={(el) => el.focus()} {...this.createOptions()} />
                  </div>

                </div>
              </div>
              {this.state.error ? (
                <p className="add-card-error">{this.state.error}</p>
              ) : null}
              <p>{t('AUTHORIZE_MESSAGE_STRIPE')}</p>
              {this.calculateAmount() ? (<p className="next-payment">{t('YOUR_NEXT_PAYMENT', { amount: this.calculateAmount() })}</p>) : null}
              <button className="btn" onClick={this.handleCardSubmit}>{t('USE_THIS_CARD')}</button>
              <Link to="/settings/billing">{t('CANCEL')}</Link>
            </div>
          ) : null
        }

        {
          method === 'SEPA' ? (
            <div className="card-form sepa-form">
              <div className="card-form-body">
                <span>{t('NAME_LABEL')}</span>
                <input
                  className="inp"
                  type="text"
                  placeholder="Name"
                  value={this.state.name}
                  onChange={(e) => {
                    this.setState({ name: e.target.value });
                  }}
                />
                <span>{t('EMAIL_LABEL')}</span>
                <input
                  className="inp"
                  type="text"
                  placeholder="Email"
                  value={this.state.email}
                  onChange={(e) => {
                    this.setState({ email: e.target.value });
                  }}
                />
                <div className="stripe-body">
                  <span>{t('IBAN')}</span>
                  <IbanElement
                    onReady={(el) => el.focus()}
                    supportedCountries={['SEPA']}
                    {...this.createOptions()}
                  />
                </div>
              </div>
              {this.state.error ? (
                <p className="add-card-error">{this.state.error}</p>
              ) : null}
              <p className="sepa-mandate">
                {t('IBAN_MESSAGE')}
              </p>
              {this.calculateAmount() ? (<p className="next-payment">{t('YOUR_NEXT_PAYMENT', { amount: this.calculateAmount() })}</p>) : null}
              <button className="btn" onClick={this.handleSEPASubmit}>{t('USE_BANK_TRANSFER')}</button>
              <Link to="/settings/billing">{t('CANCEL')}</Link>
            </div>
          ) : null
        }

        {
          method === 'Mobile' && canMakePayment ? (
            <div className="card-form sepa-form">
              <div className="card-form-body">
                <PaymentRequestButtonElement paymentRequest={paymentRequest} />
              </div>
              {this.state.error ? (
                <p className="add-card-error">{this.state.error}</p>
              ) : null}
              {this.calculateAmount() ? (<p className="next-payment">{t('YOUR_NEXT_PAYMENT', { amount: this.calculateAmount() })}</p>) : null}
              <Link to="/settings/billing">{t('CANCEL')}</Link>
            </div>
          ) : null
        }
      </div>
    );
  }

  applyCoupon(amount, coupons) {
    if (!coupons) return amount;
    amount = parseFloat(amount);

    if (coupons.percent_off) {
      return (parseFloat(amount) - (amount * (parseFloat(coupons.percent_off) / 100))).toFixed(2);
    }
    if (coupons.amount_off) {
      return (amount - (parseFloat(coupons.amount_off) / 100)).toFixed(2);
    }
    return 0;
  }

  getAmountAndCurrencyAndCountryAndName() {
    const { query, group } = this.props;

    const nextInvoice = _.get(this.props.subscription, 'details.nextInvoice', null);
    const amount = ((+_.get(query, 'amount', 0)) * 100) || +_.get(nextInvoice, 'amount_remaining', 0);
    const currency = _.get(query, 'currency', false) || _.get(nextInvoice, 'currency', 'usd');
    const country = _.get(nextInvoice, 'account_country', group.country);
    const name = query.amount ? `Subscription ${+amount / 100} ${currency}` : _.get(nextInvoice, 'lines.data[0].description', 'Subscription');
    return {
      amount: Math.round(amount), currency, country, name,
    };
  }

  calculateAmount() {
    const nextInvoice = _.get(this.props.subscription, 'details.nextInvoice', null);
    const { group } = this.props;
    const currencySign = group.currency === 'eur' ? String.fromCharCode(8364) : '$';
    const { amount } = this.props.query;
    const { coupons } = this.props;

    if (amount) return currencySign + this.applyCoupon(amount, coupons);
    if (nextInvoice) return currencySign + (nextInvoice.total / 100);

    return null;
  }

  addPaymentMethod(setupIntent) {
    const { query } = this.props;
    this.props
      .addPaymentMethodToCustomer(setupIntent)
      .then((result) => {
        const res = result.payload.data;
        if (res.success) {
          if (!query.plan) {
            this.props.changeLoading({ component: 'billing', loading: false });
            browserHistory.push('/settings/billing');
            document.location.reload();
          } else {
            this.props.changeGroupPlan(query.plan).then((res) => {
              browserHistory.push('/settings/billing');
              document.location.reload();
              this.props.changeLoading({ component: 'billing', loading: false });
            });
          }
        } else {
          this.props.changeLoading({ component: 'billing', loading: false });
          this.setState({ error: res.error });
        }
      });
  }

  handleCardSubmit(ev) {
    ev.preventDefault();

    const { stripe, query } = this.props;
    this.props.changeLoading({ component: 'billing', loading: true });

    this.props.fetchSetupIntent().then((result) => {
      const { client_secret } = result.payload.data;

      stripe.handleCardSetup(client_secret, {}).then((result) => {
        if (result.error) {
          alert(result.error.message);
          this.props.changeLoading({ component: 'billing', loading: false });
        } else {
          this.addPaymentMethod(result.setupIntent);
        }
      });
    });
  }

  handleSEPASubmit(ev) {
    ev.preventDefault();
    this.props.changeLoading({ component: 'billing', loading: true });
    const {
      stripe, elements,
    } = this.props;
    const iban = elements.getElement('iban');
    this.props.fetchSetupIntent().then((result) => {
      const { client_secret } = result.payload.data;
      stripe.confirmSepaDebitSetup(client_secret, {
        payment_method: {
          sepa_debit: iban,
          billing_details: {
            name: this.state.name,
            email: this.state.email,
          },
        },
      }).then((result) => {
        if (result.error) {
          alert(result.error.message);
          this.props.changeLoading({ component: 'billing', loading: false });
        } else {
          this.addPaymentMethod(result.setupIntent);
        }
      });
    });
  }
}

export default injectStripe(AddCard);
