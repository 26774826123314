import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import localforage from 'localforage';
import { browserHistory } from 'react-router';
import { subDays, formatISO, format } from 'date-fns';
import axios from 'axios';

import './my-account.scss';
import DatePicker from 'react-datepicker/dist/react-datepicker';
import { withTranslation } from 'react-i18next';
import Password from '../password';
import { getPlans } from '../../../actions/plans';
import { getGroup, saveGroupData } from '../../../actions/group';
import { deleteAccount, logout } from '../../../actions/auth';
import confirm from '../../confirm';
import AccountForm from './components/account-form';
import GroupForm from './components/group-form';

import { ROOT_URL, changeLoading } from '../../../actions';

@withTranslation()
@connect((state) => ({
  user: state.auth.user,
  plans: state.plans,
  group: state.group,
  subscription: state.subscription,
  initialValues: state.group && state.group._id ? {
    name: state.group.name,
    firstName: state.group.firstName,
    lastName: state.group.lastName,
    clubEmail: state.group.clubEmail,
    clubPosition: state.group.clubPosition,
    city: state.group.city,
    streetNumber: state.group.streetNumber,
    zipCode: state.group.zipCode,
    country: state.group.country,
    phone: state.group.phone,
    vatNumber: state.group.vatNumber,
  } : undefined,
}), {
  getPlans, getGroup, saveGroupData, deleteAccount, logout, changeLoading,
})
class MyAccount extends Component {
  static propTypes = {
    sections: PropTypes.array,
  };

  constructor(props) {
    super(props);

    this.state = {
      confirmPassword: '',
      ConfirmInput: null,
      exportStartDate: subDays(new Date(), 30),
      exportEndDate: new Date(),
      exportLoading: false,
    };

    this.confirmDelete = this.confirmDelete.bind(this);
    this.changeConfirmPassword = this.changeConfirmPassword.bind(this);
    this.handleExportChangeStart = this.handleExportChangeStart.bind(this);
    this.handleExportChangeEnd = this.handleExportChangeEnd.bind(this);
    this.handleExportRangeChange = this.handleExportRangeChange.bind(this);
    this.submitExport = this.submitExport.bind(this);
  }

  componentDidMount() {
    this.props.changeLoading({ component: 'app', loading: true });
    Promise.all([
      this.props.getPlans(),
      this.props.getGroup(),
    ]).then(() => {
      this.props.changeLoading({ component: 'app', loading: false });
    });
  }

  changeConfirmPassword(e) {
    this.setState({ confirmPassword: e.target.value });
  }

  confirmDelete() {
    const { confirmPassword } = this.state;
    const { logout, t } = this.props;

    const deleteAccountHeading = t('DELETE_ACCOUNT_HEADING');
    const deleteAccountText = t('DELETE_ACCOUNT_TEXT');
    const deleteAccountInputPlaceholder = t('DELETE_ACCOUNT_INPUT_PLACEHOLDER');
    const caution = t('CAUTION');
    const caution2 = t('GROUP_ADMIN_WARNING_DELETE');
    const lastWarning = t('LAST_WARNING');
    const lastWarning2 = t('LAST_WARNING_SUBHEADING');

    const text = (cancel, onCheck, isChecked, onInputChange, value) => {
      return (
        <div className="delete-confirm">
          <h2 className="h2">{deleteAccountHeading}</h2>
          <p>{deleteAccountText}</p>
          <input className="inp" type="password" name="password" value={value} onChange={onInputChange} placeholder={deleteAccountInputPlaceholder} />

          <p>
            <strong>{caution}</strong>
            <br />
            {caution2}
          </p>
        </div>
      );
    };

    const secondConfirmText = (
      <div className="delete-confirm">
        <h2 className="h2">{lastWarning}</h2>
        <p>{lastWarning2}</p>
      </div>
    );

    const cancelText = t('CANCEL');
    const confirmText = t('PROCEED');

    confirm(text, {
      context: this, cancelText, confirmText, confirmButtonClass: 'btn-red', pwd: confirmPassword,
    })
      .then(({ inputValue }) => {
        const password = inputValue;
        confirm(secondConfirmText, {
          context: this, cancelText, confirmText, confirmButtonClass: 'btn-red', pwd: confirmPassword,
        })
          .then(() => {
            this.props
              .deleteAccount(password).then((result) => {
                const status = _.get(result, 'payload.response.status', 200);

                if (status === 200) {
                  Cookies.remove('authToken');
                  localforage.clear();
                  logout()
                    .then(() => {
                      browserHistory.push('/auth/login');
                    })
                    .catch((error) => {
                      console.error(error);
                      alert(t('CANT_LOGOUT_OFFLINE'));
                    });
                } else if (status === 400) {
                  alert(t('CHECK_PASSWORD'));
                }
              });
          });
      });
  }

  handleExportRangeChange = ({ startDate, endDate }) => {
    startDate = startDate || this.state.exportStartDate;
    endDate = endDate || this.state.exportEndDate;

    if (startDate > endDate) {
      endDate = startDate;
    }

    this.setState({ exportStartDate: startDate, exportEndDate: endDate });
  };

  handleExportChangeStart = (startDate) => this.handleExportRangeChange({ startDate });

  handleExportChangeEnd = (endDate) => this.handleExportRangeChange({ endDate });

  submitExport = (submit) => {
    this.setState({ exportLoading: true });
    axios({
      url: `${ROOT_URL}/xml/dates/${formatISO(this.state.exportStartDate)}/${formatISO(this.state.exportEndDate)}`,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${format(this.state.exportStartDate, 'dd-MM-yyyy')}_${format(this.state.exportEndDate, 'dd-MM-yyyy')}.zip`);
      document.body.appendChild(link);
      link.click();
      this.setState({ exportLoading: false });
    });
  };

  render() {
    const {
      user, t, subscription, group,
    } = this.props;

    if (!group || !user) return null;

    return (
      <div className="my-account">
        {user.roleId > 0 ? (
          <GroupForm group={group} />
        ) : null}

        <AccountForm user={user} />

        <Password />

        {
          subscription.xmlExport ? (
            <div className="my-account__export">
              <h2 className="settings-h1 overlay-h1">{t('EXPORT_DATA')}</h2>
              <div className="row">
                <div className="col-2">
                  <DatePicker
                    selectsStart
                    selected={this.state.exportStartDate}
                    startDate={this.state.exportStartDate}
                    endDate={this.state.exportEndDate}
                    onChange={this.handleExportChangeStart}
                    dateFormat="dd/MM/yyyy"
                  />
                </div>
                <div className="col-2">
                  <DatePicker
                    selectsEnd
                    selected={this.state.exportEndDate}
                    startDate={this.state.exportStartDate}
                    endDate={this.state.exportEndDate}
                    onChange={this.handleExportChangeEnd}
                    dateFormat="dd/MM/yyyy"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-3">
                  { this.state.exportLoading ? <p>{t('GENERATING_REPORTS')}</p> : <button className="btn" onClick={this.submitExport}>{t('EXPORT_MATCHES')}</button>}
                </div>
              </div>
            </div>
          ) : null
        }

        <div className="my-account__delete">
          <h2 className="settings-h1 overlay-h1">{t('DELETE_ACCOUNT')}</h2>
          <p>
            {t('DELETE_ACCOUNT_SUBHEADING1')}
            <br />
            {t('DELETE_ACCOUNT_SUBHEADING2')}
          </p>
          <br />
          <button className="btn btn-red" onClick={this.confirmDelete}>{t('DELETE_MY_ACCOUNT')}</button>
        </div>

      </div>
    );
  }
}

export default MyAccount;
