import React, { Component } from 'react';
import Select from 'react-select';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { theme, style } from '../select_props';
import Option from './team-option';
import SingleValue from './team-value';
import DropdownIndicator from '../select/components/dropdown-indicator';

@withTranslation()
export default class TeamSelect extends Component {
  render() {
    const {
      input, t, i18n, league, onCreate, ...rest
    } = this.props;
    const lng = i18n.language;

    return (
      <Select
        key={league}
        className={rest.meta.touched && rest.meta.error ? 'no-valid' : ''}
        {...rest}
        name={input.name}
        styles={style}
        cacheOptions
        clearable={false}
        maxHeight={200}
        placeholder={t('TYPE_TO_SEARCH_TEAM')}
        components={{ Option, SingleValue, DropdownIndicator }}
        hideSelectedOptions
        value={input.value}
        onChange={(value) => {
          rest.changeCallback && rest.changeCallback(value);
          input.onChange(value);
        }}
        theme={theme}
      />
    );
  }
}

TeamSelect.propTypes = {
  league: PropTypes.string,
  input: PropTypes.object,
  t: PropTypes.func,
  i18n: PropTypes.object,
  onCreate: PropTypes.func,
};
