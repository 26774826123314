import React, { Component } from 'react';
import * as d3 from 'd3';
import { Link } from 'react-router';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PlayerImage from '../../../player-image';
import AddToFavorite from '../../../ui/add-to-favorites';

import { fetchFavoritesInfo } from '../../../../actions/favorites';
import { getPlayerImage, getTeamImage, getWatchlistCategory } from '../../../../helpers';
import { deletePlayer } from '../../../../actions/entries';

import Icon from '../../../Icon';

import './recent_list.scss';

@withTranslation()
@connect((state) => ({
  favorites: state.favorites,
  teams: state.teams,
  positions: state.positions,
  matches: state.matches,
  user: state.auth.user,
  users: state.users,
}), { fetchFavoritesInfo, deletePlayer })
export default class PlayersList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sort: 'sort',
      dir: 'asc',
    };

    this.renderRating = this.renderRating.bind(this);
    this.renderPlayer = this.renderPlayer.bind(this);
    this.renderPlayers = this.renderPlayers.bind(this);
  }

  renderRating = (rate) => {
    const { t } = this.props;
    const color = d3.scaleLinear().domain([1, 2, 3, 4, 5]).range(['#1D619F', '#6CB5D7', '#A4A6A4', '#F98736', '#DA4B06']);
    const title = [t('NONE'), t('UNINTERESTING'), t('LESS_INTERESTING'), t('FOCUS'), t('INTENSIVE_FOCUS'), t('IMMEDIATELY')];

    const intRate = Math.round(rate);

    if (!intRate) {
      return (
        <span className="favorite-rate">
          <span className="legend" style={{ backgroundColor: '#000' }} />
          { title[0] }
        </span>
      );
    }

    return (
      <span className="favorite-rate">
        <span className="legend" style={{ backgroundColor: color(intRate) }} />
        { title[intRate] }
        ,
        {intRate}
      </span>
    );
  };

  renderPlayer = (player, isFirst, isLast, i) => {
    const { t } = this.props;

    const {
      player_id,
      date,
      first_name,
      last_name,
      birth_date,
      country,
      team_name,
      team_id,
      notes,
      evaluations,
    } = player;

    const playerImage = getPlayerImage(player);
    const teamImage = getTeamImage(team_id);

    return (
      <tr key={player_id}>
        <td className="col__last-name">
          <div className="recent-player-name">
            <Link to={`/players/recently-rated/${player_id}/summary`}>
              <PlayerImage className="player-image" src={playerImage} />
              <span className="favorite-player-name_last">{ last_name }</span>
              {' '}
              { first_name !== last_name ? first_name : null }
            </Link>
          </div>
        </td>
        <td className="col__updated-at">{date}</td>
        <td className="col__birth-date">{ birth_date }</td>
        <td className="col__country">{ country }</td>
        <td className="col__team">
          {
            team_id
              ? (
                <span className="favorite-player-team">
                  <PlayerImage className="team-image" src={teamImage} />
                  { team_name }
                </span>
              )
              : '–'
          }
        </td>
        <td className="col__notes">{ notes }</td>
        <td className="col__evaluations">{ evaluations }</td>
        <td className="col__rating" style={{ position: 'relative', zIndex: 999 - i }}>
          <AddToFavorite
            match_id={null}
            player={player}
            added_message={t('WATCHLIST_CATEGORY_CHANGED')}
          >
            <div className="favorite-selector">
              <Icon name="favorite" />
              {' '}
              {getWatchlistCategory(player, t)}
              {' '}
              <Icon name="chevron_down" className="chevron-down" />
            </div>
          </AddToFavorite>
        </td>
      </tr>
    );
  };

  changeSort(field) {

    /* let { sort, dir } = this.state;

    if (sort === field) {
      dir = dir === 'asc' ? 'desc' : 'asc';
    } else {
      sort = field;
      dir = 'asc';
    }

    this.setState({ sort, dir }); */
  }

  renderPlayers() {
    const { players, withPaging } = this.props;
    const {
      sort, dir, page, showOnPage,
    } = this.state;
    const sortedList = _.orderBy(players, sort, dir);

    return sortedList.map((p, i) => this.renderPlayer(p, i === 0, i === sortedList.length - 1, i));
  }

  render() {
    const {
      sort, dir,
    } = this.state;
    const dirCls = dir === 'asc' ? 'asc' : 'desc';
    const {
      t,
    } = this.props;

    return (
      <section className="favorites-list__wrapper">
        <table className="favorites-list recent-list">
          <thead>
            <tr>
              <th className="col__last-name">{t('PLAYER', { context: window.genderContext })}</th>
              <th className="col__updated-at">{t('UPDATED_AT')}</th>
              <th className="col__birth-date">{t('AGE')}</th>
              <th className="col__country">{t('COUNTRY')}</th>
              <th className="col__team">{t('TEAM')}</th>
              <th className="col__notes">{t('NOTES')}</th>
              <th className="col__evaluations">{t('EVALUATIONS')}</th>
              <th className="col__rating">{t('CATEGORY')}</th>
            </tr>
          </thead>
          <tbody>
            { this.renderPlayers() }
          </tbody>
        </table>
      </section>
    );
  }
}

PlayersList.propTypes = {
  withPaging: PropTypes.bool,
  isEntries: PropTypes.bool,
  players: PropTypes.array,
  updateSort: PropTypes.func,
  deletePlayer: PropTypes.func,
};
