import _ from 'lodash';

import {
  FETCH_GROUPS, ADD_GROUP, UPDATE_GROUP, DELETE_GROUP,
} from '../actions/groups';

const INITIAL_STATE = [];

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_GROUPS:
      return action.payload.data.groups;

    case ADD_GROUP: {
      if (!action.payload.data.success) {
        alert(`Sorry, an error occured while creating a group.\nError message: ${action.payload.data.message}`);
        return state;
      }

      return [...state, action.payload.data.group];
    }

    case UPDATE_GROUP: {
      if (!action.payload.data.success) {
        alert(`Sorry, an error occured while edit a group.\nError message: ${action.payload.data.message}`);
        return state;
      }

      return state.map((group) => {
        if (group._id == action.payload.data.group._id) {
          return { ...action.payload.data.group };
        }
        return group;
      });
    }

    case DELETE_GROUP: {
      const { id } = action.payload.data;

      if (!action.payload.data.success) {
        alert(`Sorry, an error occured while delete a group.\nError message: ${action.payload.data.message}`);
        return state;
      }

      return state.filter((group) => group._id != id);
    }

    default:
      return state;
  }
}
