import React, { Component } from 'react';
import { connect } from 'react-redux';
import './performace_stats_overtime.scss';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';

@withTranslation()
@connect((state) => {
  return {
    indicators: state.indicators,
  };
}, {})
export default class PerformanceStatsOvertime extends Component {
  render() {
    const { matches, t } = this.props;

    const stats = this.prepareRatings(matches);

    return (
      <div className="performance-stats-overtime">
        <div className="performance-stats-overtime__heading">
          <label>{t('WITH_OVERALL')}</label>
          {t('N_PERFORMANCE_RATINGS', { n: stats.total })}
        </div>
        {
          stats.total ? (
            <div className="performance-stats-overtime__body">
              { this.renderTable(stats) }
            </div>
          )
            : null
        }
      </div>
    );
  }

  renderTable(stats) {
    const { t } = this.props;
    return (
      <table>
        <tbody>
          <tr>
            <td className="label">{t('POSITIVE')}</td>
            <td className="positive">{ this.renderBar('positive', stats) }</td>
            <td className="value">
              { Math.round(stats.positive.total / stats.total * 100) }
              %
            </td>
          </tr>
          <tr>
            <td className="label">{t('NEUTRAL')}</td>
            <td className="neutral">{ this.renderBar('neutral', stats) }</td>
            <td className="value">
              { Math.round(stats.neutral.total / stats.total * 100) }
              %
            </td>
          </tr>
          <tr>
            <td className="label">{t('NEGATIVE')}</td>
            <td className="negative">{ this.renderBar('negative', stats) }</td>
            <td className="value">
              { Math.round(stats.negative.total / stats.total * 100) }
              %
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

  renderBar(key, stats) {
    const components = ['on', 'off', 'physis'].map(
      (class_key) => stats[key][class_key] ? <div key={class_key} className={`${class_key} bar-section`} style={{ width: `${Math.round(stats[key][class_key] / stats.total * 100)}%` }} /> : null,
    );

    return (
      <div className="bar">
        { components }
      </div>
    );
  }

  prepareRatings(matches) {
    const { playerId } = this.props;

    const playerStats = matches.map((m) => {
      return _.get(m, `home.values[${playerId}]`, null) || _.get(m, `away.values[${playerId}]`, null);
    });

    const performance = {
      positive: {
        on: 0,
        off: 0,
        physis: 0,
        total: 0,
      },
      neutral: {
        on: 0,
        off: 0,
        physis: 0,
        total: 0,
      },
      negative: {
        on: 0,
        off: 0,
        physis: 0,
        total: 0,
      },
      total: 0,
    };

    const meanIndicators = {};

    playerStats.forEach((match) => {
      _.forOwn(match, (entry) => {
        _.forOwn(entry, (indicator, key) => {
          if (key === 'player_id' || key === 'event_id' || key === 'notes' || !this.getKey(key)) return;

          if (!meanIndicators[key]) {
            meanIndicators[key] = {};
            if (!meanIndicators[key].value) meanIndicators[key].value = 0;
            if (!meanIndicators[key].count) meanIndicators[key].count = 0;
            if (!meanIndicators[key].count) meanIndicators[key].mean = 0;
          }

          meanIndicators[key].value += +indicator;
          meanIndicators[key].count += 1;
          meanIndicators[key].mean = meanIndicators[key].value / meanIndicators[key].count;
        });
      });
    });

    _.forOwn(meanIndicators, (stats, key) => {
      if (stats.mean > 0) {
        performance.positive[this.getKey(key)] += 1;
        performance.positive.total++;
        performance.total++;
      } else if (stats.mean === 0) {
        performance.neutral[this.getKey(key)] += 1;
        performance.neutral.total++;
        performance.total++;
      } else {
        performance.negative[this.getKey(key)] += 1;
        performance.negative.total++;
        performance.total++;
      }
    });

    return performance;
  }

  getKey(key) {
    const { playerId, indicators } = this.props;
    const { info } = indicators;

    if (!info[key]) return null;

    const ON_THE_BALL = 'On the Ball';
    const OFF_THE_BALL = 'Off the Ball';
    const PHYSIS = 'Physis';

    if (info[key].class === ON_THE_BALL) {
      return 'on';
    }

    if (info[key].class === OFF_THE_BALL) {
      return 'off';
    }

    if (info[key].class === PHYSIS) {
      return 'physis';
    }
  }
}
