import React from 'react';
import { useTranslation } from 'react-i18next';
import { Portal } from 'react-portal';
import FadeScreen from '../fade-screen';
import Icon from '../../Icon';

import MatchEditForm from './match-edit-form';

import './modal-commom.scss';
import './match-edit-form.scss';
import PropTypes from 'prop-types';

// TODO: Add edit match
export default function MatchEditFormModal({ ...rest }) {
  const { t } = useTranslation();
  const { match } = rest;
  const title = match ? t('EDIT_MATCH') : t('CREATE_REPORT');
  // TODO: MOVE IT TO HOC Modal
  return (
    <Portal>
      <FadeScreen>
        <div className="match-edit-form-modal sp-modal">
          <div className="sp-modal__heading">
            <div className="sp-modal__name">
              <Icon name="report" />
              {title}
            </div>
            <button
              type="reset"
              className="sp-modal__close"
              onClick={rest.onClose}
            >
              <Icon name="close" />
            </button>
          </div>
          <div className="sp-modal__body">
            <MatchEditForm {...rest} />
          </div>
        </div>
      </FadeScreen>
    </Portal>
  );
}

MatchEditFormModal.propTypes = {
  match: PropTypes.object,
  onClose: PropTypes.func,
};
