import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import CSSTransition from 'react-transition-group/CSSTransition';
import { Scrollbars } from 'react-custom-scrollbars';
import sub from 'date-fns/sub';
import _omitBy from 'lodash/omitBy';

import TeamsSelect from '../teams-select/simple-async';
import LeagueSelect from '../league-select/simple-async';
import DatesFilter from '../filter-dates';
import FilterSelect from '../filter-select';
import RangeSlider from '../range-slider';
import PlayerCategoryField from '../player-category-field';
import { Range } from '../rc-slider';

import './filters-constructor.scss';
import Checkbox from '../round-checkbox';

const getSliderValues = (props) => {
  const sliderValues = {};
  for (let i = 0, l = props.filters.length; i < l; i += 1) {
    const filter = props.filters[i];
    const { type, field } = filter;

    if (type === 'slider' || type === 'input-range') {
      sliderValues[field] = props.values[field].inputValue;
    }
  }
  return sliderValues;
};

@withTranslation()
class FiltersConstructor extends Component {
  constructor(props) {
    super(props);

    const sliderValues = getSliderValues(props);

    this.state = {
      isOpen: false,
      sliderValues,
      sliderDrag: false,
    };

    this.clearFilter = this.clearFilter.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleAsyncSelectChange = this.handleAsyncSelectChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.clearAllFilters = this.clearAllFilters.bind(this);
    this.handleSliderChange = this.handleSliderChange.bind(this);
    this.renderSlider = this.renderSlider.bind(this);
    this.renderPlayerCategory = this.renderPlayerCategory.bind(this);
    this.handlePlayerCategoryChange = this.handlePlayerCategoryChange.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    const sliderValues = getSliderValues(props);
    const diffValues = _omitBy(sliderValues, (value, key) => value === state.sliderValues[key]);

    if (state.sliderDrag) return {};

    return {
      sliderValues: {
        ...state.sliderValues,
        ...diffValues,
      },
    };
  }

  clearFilter(filter) {
    const combinedValue = {
      inputValue: null,
      dbValue: null,
    };

    this.props.onFilterChange(combinedValue, filter);
  }

  clearAllFilters() {
    this.props.clearAllFilters();
  }

  handleDateChange({ start, end }, filter) {
    const combinedValue = {
      inputValue: null,
      dbValue: null,
    };

    if (!start && !end) {
      this.props.onFilterChange(combinedValue, filter);
    }

    combinedValue.inputValue = {};
    combinedValue.dbValue = { [filter.field]: {} };

    if (start) {
      combinedValue.inputValue.start = start;
      combinedValue.dbValue[filter.field].$gte = start;
    }

    if (end) {
      combinedValue.inputValue.end = end;
      combinedValue.dbValue[filter.field].$lte = end;
    }

    this.props.onFilterChange(combinedValue, filter);
  }

  handleTextChange(value, filter) {
    let combinedValue = {
      inputValue: null,
      dbValue: null,
    };
    if (!value) {
      this.props.onFilterChange(combinedValue, filter);
    }

    combinedValue = {
      inputValue: value,
      dbValue: {
        [filter.field]: value,
      },
    };

    this.props.onFilterChange(combinedValue, filter);
  }

  handleCheckboxChange(value, filter) {
    let combinedValue = {
      inputValue: null,
      dbValue: null,
    };

    if (!value) {
      this.props.onFilterChange(combinedValue, filter);
    }

    combinedValue = {
      inputValue: value,
      dbValue: {
        [filter.field]: value,
      },
    };

    this.props.onFilterChange(combinedValue, filter);
  }

  handleSelectChange(selected, filter) {
    let combinedValue = {
      inputValue: null,
      dbValue: null,
    };

    if (selected.value === null) {
      this.props.onFilterChange(combinedValue, filter);
    }

    combinedValue = {
      inputValue: selected.value,
      dbValue: {
        [filter.field]: selected.value,
      },
    };

    this.props.onFilterChange(combinedValue, filter);
  }

  handleSliderChange(value, filter) {
    let combinedValue = {
      inputValue: null,
      dbValue: null,
    };

    if (!value) {
      this.props.onFilterChange(combinedValue, filter);
    }

    combinedValue = {
      inputValue: value,
      dbValue: {
        [filter.field]: value,
      },
    };

    this.props.onFilterChange(combinedValue, filter);
  }

  handlePlayerCategoryChange(value, filter) {
    let combinedValue = {
      inputValue: null,
      dbValue: null,
    };

    if (!value) {
      this.props.onFilterChange(combinedValue, filter);
    }

    combinedValue = {
      inputValue: value,
      dbValue: {
        [filter.field]: value,
      },
    };

    this.props.onFilterChange(combinedValue, filter);
  }

  handleAsyncSelectChange(selected, filter) {
    let combinedValue = {
      inputValue: null,
      dbValue: null,
    };

    if (selected.value === null) {
      this.props.onFilterChange(combinedValue, filter);
    }

    combinedValue = {
      inputValue: selected,
      dbValue: {
        [filter.field]: selected.value,
      },
    };

    this.props.onFilterChange(combinedValue, filter);
  }

  renderTextInput(filter) {
    const { t, values } = this.props;

    let value = '';
    if (values[filter.field]) {
      value = values[filter.field].inputValue;
    }

    return (
      <input
        type="text"
        className="text-input"
        placeholder={t(filter.placeholder)}
        value={value}
        onChange={(e) => this.handleTextChange(e.target.value, filter)}
      />
    );
  }

  renderDateInput(filter) {
    const { values } = this.props;

    const value = {
      start: null,
      end: null,
    };

    if (values[filter.field]) {
      if (values[filter.field]?.inputValue?.start) {
        value.start = values[filter.field].inputValue.start;
      }
      if (values[filter.field]?.inputValue?.end) {
        value.end = values[filter.field].inputValue.end || new Date();
      }
    }

    return (
      <DatesFilter
        startDate={value.start}
        endDate={value.end}
        allTimesStart={sub(new Date(), { years: 3 })}
        className="api-list-filters__filter-date-range white"
        handleChangeStart={(start) => { this.handleDateChange({ start, end: value.end }, filter); }}
        handleChangeEnd={(end) => { this.handleDateChange({ end, start: value.start }, filter); }}
        handleChangeBoth={(start, end) => { this.handleDateChange({ start, end }, filter); }}
      />
    );
  }

  renderTeamsSelect(filter) {
    const { t, values } = this.props;

    let value = { ...filter.options[0], label: t(filter?.options[0]?.label) };

    if (values[filter.field]) {
      value = values[filter.field].inputValue;
    }

    return (
      <TeamsSelect
        value={value}
        color="#FFFFFF"
        placeholderColor="rgba(255,255,255,.75)"
        baseOptions={filter.options}
        onChange={(value) => this.handleAsyncSelectChange(value, filter)}
      />
    );
  }

  renderLeagueSelect(filter) {
    const { t, values } = this.props;

    let value = { ...filter.options[0], label: t(filter?.options[0]?.label) };

    if (values[filter.field]) {
      value = values[filter.field].inputValue;
    }

    return (
      <LeagueSelect
        value={value}
        color="#FFFFFF"
        placeholderColor="rgba(255,255,255,.75)"
        baseOptions={filter.options}
        onChange={(value) => this.handleAsyncSelectChange(value, filter)}
      />
    );
  }

  renderSelectInput(filter) {
    const { t, values } = this.props;

    const inputValue = values[filter.field]?.inputValue || null;
    let value = filter.options.find((v) => v.value === inputValue);

    if (!value) {
      value = { ...filter.options[0], label: t(filter?.options[0]?.label) };
    } else {
      value = { ...value, label: t(value?.label) };
    }

    return (
      <FilterSelect
        color="#FFFFFF"
        placeholderColor="rgba(255,255,255,.75)"
        options={filter.options.map((o) => ({ ...o, label: t(o.label) }))}
        onChange={(v) => { this.handleSelectChange(v, filter); }}
        value={value}
      />
    );
  }

  renderCheckbox(filter) {
    const { values } = this.props;

    let value = false;
    if (values[filter.field]) {
      value = values[filter.field].inputValue;
    }

    value = value?.toString() === 'true';

    return <Checkbox type="checkbox" onClick={() => this.handleCheckboxChange(!value, filter)} isChecked={value} />;
  }

  renderSlider(filter) {
    const { sliderValues } = this.state;
    const {
      field, minValue = 0, maxValue = 10, formatLabel = undefined,
    } = filter;

    return (
      <div className="api-list-filters__filter-slider">
        <Range
          value={sliderValues[field]}
          min={minValue}
          max={maxValue}
          onBeforeChange={() => { this.setState({ sliderDrag: true }); }}
          onChange={(value) => this.setState({ sliderValues: { ...sliderValues, [field]: value } })}
          onAfterChange={(value) => { this.setState({ sliderDrag: false }); this.handleSliderChange(value, filter); }}
          tipFormatter={formatLabel}
        />
      </div>
    );
  }

  renderInputRange(filter) {
    const { t } = this.props;
    const { sliderValues } = this.state;
    const {
      field, minValue = 0, maxValue = 10, formatLabel = undefined,
    } = filter;

    return (
      <div className="api-list-filters__filter-input-range row">
        <div className="col-6">
          <input
            type="number"
            value={sliderValues[field][0]}
            onChange={(event) => {
              this.setState({ sliderDrag: true, sliderValues: { ...sliderValues, [field]: [+event.target.value, sliderValues[field][1]] } });
            }}
            onBlur={() => {
              this.handleSliderChange(sliderValues[field], filter);
              this.setState({ sliderDrag: false });
            }}
          />
          <label className="label" htmlFor="">{t('FROM')}</label>
        </div>
        <div className="col-6">
          <input
            type="number"
            value={sliderValues[field][1]}
            onChange={(event) => {
              this.setState({ sliderDrag: true, sliderValues: { ...sliderValues, [field]: [sliderValues[field][0], +event.target.value] } });
            }}
            onBlur={() => {
              this.handleSliderChange(sliderValues[field], filter);
              this.setState({ sliderDrag: false });
            }}
          />
          <label className="label" htmlFor="">{t('TO')}</label>
        </div>
      </div>
    );
  }

  renderPlayerCategory(filter) {
    const { values } = this.props;
    const { field } = filter;

    let value = false;
    if (values[field]) {
      value = values[field].inputValue;
    }

    return (
      <PlayerCategoryField
        value={value}
        onChange={(value) => { this.handlePlayerCategoryChange(value, filter); }}
      />
    );
  }

  renderFilterControl(filter) {
    return (
      <div className="input">
        { filter.type === 'text' ? this.renderTextInput(filter) : null }
        { filter.type === 'date-range' ? this.renderDateInput(filter) : null }
        { filter.type === 'select' ? this.renderSelectInput(filter) : null }
        { filter.type === 'select-teams' ? this.renderTeamsSelect(filter) : null }
        { filter.type === 'select-leagues' ? this.renderLeagueSelect(filter) : null }
        { filter.type === 'checkbox' ? this.renderCheckbox(filter) : null }
        { filter.type === 'slider' ? this.renderSlider(filter) : null }
        { filter.type === 'player-category' ? this.renderPlayerCategory(filter) : null }
        { filter.type === 'input-range' ? this.renderInputRange(filter) : null }
      </div>
    );
  }

  renderFilter(filter, zIndex) {
    const { t } = this.props;

    return (
      <div className={`api-list-filters__filter api-list-filters__filter__${filter.type}`} key={filter.field} style={{ zIndex }}>
        <label className="label">
          {t(filter.name)}
          {
            filter.type === 'date-range'
              ? (
                <span
                  className="js-link"
                  onClick={() => { this.clearFilter(filter); }}
                >
                  {t('CLEAR')}
                </span>
              )
              : null
          }
        </label>
        { this.renderFilterControl(filter) }
      </div>
    );
  }

  render() {
    const {
      t, filters, title, BtnComponent, renderInline, classNames,
    } = this.props;
    const { isOpen } = this.state;

    const totalLength = filters.length + 1;

    if (renderInline) {
      return (
        <>
          <BtnComponent active={isOpen} onClick={() => this.setState({ isOpen: true })} />
          {
            isOpen ? (
              <div className={`api-list-filters ${classNames || ''}`}>
                <div
                  className="api-list-filters__fade"
                  onClick={() => {
                    this.props.applyFilters();
                    this.setState({ isOpen: false });
                  }}
                />
                <div className="api-list-filters__pane">
                  <div className="pad">
                    {filters.map((f, i) => this.renderFilter(f, totalLength - i))}
                    <button
                      className="btn btn-block api-list-filters__clear"
                      onClick={this.clearAllFilters}
                    >
                      {t('CLEAR_FILTERS')}
                    </button>
                  </div>
                </div>
              </div>
            ) : null
          }
        </>
      );
    }

    return (
      <div className="api-list-filters">
        <div className="api-list-filters__trigger-container">
          <span
            className="js-link api-list-filters__trigger"
            onClick={() => this.setState({ isOpen: true })}
          >
            {t('SHOW_FILTERS')}
          </span>
          {
            isOpen ? (
              <CSSTransition
                enter
                exit
                classNames="api-list-filters__transition"
                timeout={{
                  appear: 350,
                  enter: 350,
                  exit: 350,
                }}
              >
                <>
                  <div
                    className="api-list-filters__fade"
                    onClick={() => {
                      this.props.applyFilters();
                      this.setState({ isOpen: false });
                    }}
                  />
                  <div className="api-list-filters__pane">
                    <Scrollbars
                      style={{ height: window.innerHeight }}
                    >
                      <div className="pad">
                        <h2>{title}</h2>
                        {filters.map((f, i) => this.renderFilter(f, totalLength - i))}
                        <button
                          className="btn btn-block api-list-filters__clear"
                          onClick={this.clearAllFilters}
                        >
                          {t('CLEAR_FILTERS')}
                        </button>
                      </div>
                    </Scrollbars>
                  </div>
                </>
              </CSSTransition>
            ) : null
          }
        </div>
      </div>
    );
  }
}

FiltersConstructor.propTypes = {
  filters: PropTypes.array,
  t: PropTypes.func,
  onFilterChange: PropTypes.func,
  clearAllFilters: PropTypes.func,
  title: PropTypes.string,
  applyFilters: PropTypes.func,
  values: PropTypes.object,
  BtnComponent: PropTypes.any,
  renderInline: PropTypes.bool,
  classNames: PropTypes.string,
};

export default FiltersConstructor;
