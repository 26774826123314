import axios from 'axios';
import { ROOT_URL } from '.';

export const FETCH_UPCOMING_MATCHES = 'FETCH_UPCOMING_MATCHES';
export const CLEAR_UPCOMING_MATCHES = 'CLEAR_UPCOMING_MATCHES';
export const SET_UPCOMING_LOADING = 'SET_UPCOMING_LOADING';
export const CHANGE_UPCOMING_FILTER = 'CHANGE_UPCOMING_FILTER';

let controllers = [];
export function fetchUpcoming(filters, skip, limit) {
  const controller = new AbortController();
  const request = axios.get(`${ROOT_URL}/v2/matches/upcoming`, {
    signal: controller.signal,
    params: {
      skip, limit, filters,
    },
  }).catch(() => {
    return {
      type: FETCH_UPCOMING_MATCHES,
      payload: {
        data: null,
        success: true,
      },
    };
  });

  return {
    type: FETCH_UPCOMING_MATCHES,
    payload: request,
  };
}

export function clearUpcoming() {
  controllers.map((c) => c.abort());
  controllers = [];

  return {
    type: CLEAR_UPCOMING_MATCHES,
  };
}

export function setUpcomingLoading(status) {
  return {
    type: SET_UPCOMING_LOADING,
    payload: status,
  };
}

export function changeUpcomingFilter(values) {
  return {
    type: CHANGE_UPCOMING_FILTER,
    payload: values,
  };
}
