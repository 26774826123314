import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Icon from '../../Icon';

import './player-category-field.scss';

@withTranslation()
export default class PlayerCategoryField extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.array,
  };

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
  }

  toggle(value) {
    let values = this.props.value;
    const index = values.findIndex((v) => v === value);

    if (index !== -1) {
      values = [
        ...values.slice(0, index),
        ...values.slice(index + 1),
      ];
    } else {
      values.push(value);
    }

    this.props.onChange(values);
  }

  checkActive(value) {
    const values = this.props.value || [];
    return values.includes(value);
  }

  renderItem(value) {
    return (
      <Icon name="favorite" className={`icon_btn cat_${value} ${this.checkActive(value) ? 'active' : ''}`} onClick={() => this.toggle(value)} key={value} />
    );
  }

  render() {
    return (
      <div className="player-category-field">
        {[0, 1, 2, 3].map((value) => this.renderItem(value))}
      </div>
    );
  }
}
