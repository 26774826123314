import React, { Component } from 'react';
import AsyncSelect from 'react-select/async';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import axios from 'axios';
import _ from 'lodash';
import { styleFilter, filterTheme } from '../select_props';
import Option from './team-option';
import SingleValue from './team-value';
import DropdownIndicator from '../select/components/dropdown-indicator';
import { ROOT_URL } from '../../../actions';

@withTranslation()
export default class TeamSelect extends Component {
  render() {
    const {
      t, i18n, league, baseOptions, onChange, value, ...rest
    } = this.props;
    const lng = i18n.language;

    return (
      <AsyncSelect
        key={league}
        {...rest}
        noImage
        name="teams"
        styles={styleFilter}
        cacheOptions
        defaultOptions
        clearable={false}
        maxHeight={200}
        placeholder={t('TYPE_TO_SEARCH_TEAM')}
        components={{ Option, SingleValue, DropdownIndicator }}
        hideSelectedOptions
        value={value}
        loadOptions={(inputValue) => {
          return axios.get(`${ROOT_URL}/teams/search`, {
            params: {
              needle: inputValue,
              league: league || '',
            },
          }).then((result) => {
            return [...baseOptions.map((o) => ({
              ...o,
              label: t(o.label),
            })), ...result.data.map((team) => ({ value: team.id, label: _.get(team, `name_${lng}`, team.name) }))];
          }).catch((err) => {
            console.error(err.message);
            return [...baseOptions.map((o) => ({ ...o, label: t(o.label) }))];
          });
        }}
        onChange={(value) => {
          onChange(value);
        }}
        theme={filterTheme}
      />
    );
  }
}

TeamSelect.propTypes = {
  league: PropTypes.string,
  input: PropTypes.object,
  t: PropTypes.func,
  i18n: PropTypes.object,
  onCreate: PropTypes.func,
};
