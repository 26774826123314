import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Portal } from 'react-portal';

import Icon from '../Icon';
import './watchlist-edit-form.scss';

@withTranslation()
@connect((state) => {
  return {
    colors: state.colors.list,
    icons: state.icons.list,
  };
})
class WatchlistEditForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: props.name || '',
      description: props.description || '',
      color_id: props.color_id || null,
      icon_id: props.icon_id || null,
      mode: props.mode || 'create', // update
    };
  }

  changeInputValue(name, value) {
    this.setState({ [name]: value });
  }

  renderColor(color) {
    const currentColor = this.state.color_id;

    return (
      <div
        role="button"
        key={color._id}
        onClick={() => { this.setState({ color_id: color._id }); }}
        className="create-watchlist-form__color"
        style={currentColor === color._id ? { background: `rgba(${color.rgb[0]},${color.rgb[1]},${color.rgb[2]},1)`, borderColor: `rgba(${color.rgb[0]},${color.rgb[1]},${color.rgb[2]},1)` } : { borderColor: `rgba(${color.rgb[0]},${color.rgb[1]},${color.rgb[2]},1)` }}
      />
    );
  }

  renderIcon(icon) {
    const currentIcon = this.state.icon_id;

    return (
      <div
        role="button"
        key={icon._id}
        dangerouslySetInnerHTML={{ __html: icon.code }}
        onClick={() => { this.setState({ icon_id: icon._id }); }}
        className={`create-watchlist-form__icon ${currentIcon === icon._id ? 'active' : ''}`}
      />
    );
  }

  render() {
    const {
      t, onCloseClick, onCreateClick, colors, icons,
    } = this.props;
    const {
      name, description, mode, color_id, icon_id,
    } = this.state;

    return (
      <Portal>
        <div className="popup fade-in create-watchlist-form__wrapper">
          <div className="create-watchlist-form">
            <h1>
              <Icon name="watchlistsactive" className="title-icon" />
              { mode !== 'update' ? t('CREATE_NEW_WATCHLIST') : t('UPDATE_WATCHLIST')}
            </h1>
            <Icon name="close" onClick={onCloseClick} className="close-icon" />
            <div className="input">
              <label>{t('LIST_NAME')}</label>
              <input
                name="text"
                onChange={(e) => { this.changeInputValue('name', e.target.value); }}
                value={name}
                placeholder={t('WATCHLIST_NAME_PLACEHOLDER')}
              />
            </div>
            <div className="create-watchlist-form__row">
              <div className="create-watchlist-form__color-picker">
                <label>{t('COLOR')}</label>
                <div className="create-watchlist-form__colors-list">
                  {colors.map((c) => this.renderColor(c))}
                </div>
              </div>
              <div className="create-watchlist-form__icon-picker">
                <label>{t('ICON')}</label>
                <div className="create-watchlist-form__icons-list">
                  {icons.map((c) => this.renderIcon(c))}
                </div>
              </div>
            </div>
            <div className="input">
              <label>{t('LIST_DESCRIPTION')}</label>
              <input
                name="text"
                onChange={(e) => { this.changeInputValue('description', e.target.value); }}
                value={description}
                placeholder={t('WATCHLIST_DESCRIPTION_PLACEHOLDER')}
              />
            </div>
            <button onClick={() => onCreateClick(name, description, color_id, icon_id)} className="btn">{mode !== 'update' ? t('CREATE') : t('UPDATE')}</button>
          </div>
        </div>
      </Portal>
    );
  }
}

WatchlistEditForm.propTypes = {
  onCreateClick: PropTypes.func,
  onCloseClick: PropTypes.func,
  name: PropTypes.string,
  description: PropTypes.string,
  color_id: PropTypes.string,
  icon_id: PropTypes.string,
  mode: PropTypes.string,
  colors: PropTypes.array,
  icons: PropTypes.array,
};

export default WatchlistEditForm;
