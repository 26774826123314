import React from 'react';
import { components } from 'react-select';
import TeamView from '../team-view';

export default function (props) {
  const { innerProps, innerRef, data } = props;
  return (
    <components.Option {...props}>
      <TeamView {...data} />
    </components.Option>
  );
}
