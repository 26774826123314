import React, { Component } from 'react';
import moment from 'moment/moment';
import { withTranslation } from 'react-i18next';
import Limits from '../limits';

@withTranslation()
export default class CurrentPlan extends Component {
  constructor(props) {
    super(props);
    this.onChangeClick = this.onChangeClick.bind(this);
  }

  onChangeClick() {
    this.props.onChangeClick(true);
  }

  renderPrice(invoice) {
    const currencySign = invoice.currency === 'eur' ? String.fromCharCode(8364) : '$';
    return `${(invoice.total / 100).toFixed(2).replace('.', ',')} ${currencySign}`;
  }

  render() {
    const {
      subscription, group, i18n, t, isTrial,
    } = this.props;
    const lng = i18n.language;

    const currentPlan = subscription.details;

    if (!group || !currentPlan.plan) return null;

    const { nextInvoice } = currentPlan;
    const plan = currentPlan.plan && currentPlan.plan.metadata;

    const planTitle = _.get(plan, `title_${lng}`, plan.title);
    const planSubtitle = _.get(plan, `subtitle_${lng}`, plan.subtitle);
    const planDescription = _.get(plan, `description_${lng}`, plan.description);
    const createdAt = _.get(group, 'createdAt', new Date());

    let trialEnd = _.get(currentPlan, 'subscription.trial_end', null) * 1000 || moment(createdAt).add(14, 'days');
    trialEnd = moment(trialEnd);
    const daysBeforTrialEnd = trialEnd && trialEnd.diff(moment(), 'days');

    const isNextFree = subscription.nextPlan === 'free';
    const isNextPlan = subscription.nextPlan && subscription.nextPlan !== 'free';
    const isPaymentMethod = _.get(subscription, 'details.payment_method.id', null);

    let switchOnFree = _.get(currentPlan, 'subscription.cancel_at', null);
    switchOnFree = +switchOnFree ? moment(switchOnFree * 1000) : null;
    const daysBeforeSwithcOnFree = switchOnFree && switchOnFree.isValid() && switchOnFree.diff(moment(), 'days');
    let daysBeforeNextPlanSwitch = _.get(subscription, 'details.subscription.current_period_end', 0);
    daysBeforeNextPlanSwitch = moment(daysBeforeNextPlanSwitch * 1000);
    daysBeforeNextPlanSwitch = daysBeforeNextPlanSwitch && daysBeforeNextPlanSwitch.isValid() && daysBeforeNextPlanSwitch.diff(moment(), 'days');

    const source = _.get(currentPlan, 'currentSource', null);

    return (
      <div className="plan-selector">
        <div className="plan single">
          <div className="plan-head">
            <h4>{planTitle}</h4>
            <h5>{planSubtitle}</h5>
          </div>
          <div className="body" dangerouslySetInnerHTML={{ __html: planDescription }} />
          {source
            ? nextInvoice && nextInvoice.total ? (
              <p
                className="payment-next"
                dangerouslySetInnerHTML={{
                  __html: t('PLAN_RENEW_MESSAGE', { date: moment.unix(nextInvoice.period_end).format('DD.MM.YYYY'), price: this.renderPrice(nextInvoice) }),
                }}
              />
            ) : null
            : <p className="payment-next">{t('PLAN_ADD_PAYMENT_METHOD')}</p>}
          <div className="cta">
            <button className="btn" onClick={this.onChangeClick}>
              {t('CHANGE_PLAN')}
            </button>
          </div>
        </div>

        <div className="trial-information">
          <Limits />
          {
              trialEnd && trialEnd > moment() && isTrial ? (
                <p>{t('TRIAL_ENDS_MESSAGE', { count: daysBeforTrialEnd })}</p>
              ) : null
            }
          {
              isNextFree ? (
                <p>{ t('NEXT_FREE_PLAN_MESSAGE', { count: daysBeforeSwithcOnFree }) }</p>
              ) : null
            }
          {
              isNextPlan ? (<p>{ t('NEXT_PLAN_MESSAGE', { plan: subscription.nextPlan, days: daysBeforeNextPlanSwitch }) }</p>) : null
            }
          {
              isPaymentMethod && isTrial ? (<p>{ t('NEXT_PLAN_MESSAGE', { plan: planTitle, days: daysBeforTrialEnd }) }</p>) : null
            }
        </div>

      </div>
    );
  }
}
