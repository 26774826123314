import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DemoBadge from '../ui/demo-badge';
import WatchlistIcon from '../ui/watchlist-icon';

export default class WatchlistPane extends Component {
  constructor(props) {
    super(props);

    this.state = { hovered: false };
  }

  render() {
    const { onClick, watchlist, meta } = this.props;

    return (
      <div
        className="homepage__watchlist homepage__interactive-card"
        onClick={onClick}
        data-tip={JSON.stringify({ name: watchlist.name, description: watchlist.description })}
        data-place="bottom"
        data-effect="solid"
        data-for="Watchlist"
        data-delay-show={500}
        onMouseEnter={() => this.setState({ hovered: true })}
        onMouseLeave={() => this.setState({ hovered: false })}
      >
        {watchlist._id === 'demo' ? <DemoBadge /> : null}
        <div className="homepage__watchlist-icon-container">
          <WatchlistIcon hover={this.state.hovered} icon_id={watchlist.icon_id} color_id={watchlist.color_id} />
        </div>
        <div className="homepage__watchlist-description">
          <div className="homepage__watchlist-name" title={watchlist.name}>{watchlist.name}</div>
          <div className="homepage__watchlist-meta">{meta}</div>
        </div>
      </div>
    );
  }
}

WatchlistPane.propTypes = {
  onClick: PropTypes.func,
  watchlist: PropTypes.any,
  t: PropTypes.any,
};
