import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import Select from 'react-select';
import isTouchDevice from 'is-touch-device';

import { withTranslation } from 'react-i18next';
import { filterTheme, styleFilter } from '../ui/select_props';
import EntriesList from './components/entries_list';
import PlayerSearch from './components/player-search';

import './favorites.scss';

import { changeFavoritesFilter, removeFromFavorites } from '../../actions/favorites';
import { fetchAllEntries, changeEntriesFilter, resetFilter } from '../../actions/entries';
import { updateFavoriteSorts } from '../../actions/auth';
import { changeLoading } from '../../actions/index';
import { canAddWatchlist } from '../../helpers/limits';

import AddPlayer from './components/add-player';
import Icon from '../Icon';
import FiltersConstructor from '../ui/filters-constructor';
import _find from 'lodash/find';
import { normalizeValuesForFilterConstructor } from './favorites-filter/utils';
import _omitBy from 'lodash/omitBy';
import _isEqual from 'lodash/isEqual';

@withTranslation()
@connect((state) => ({
  favorites: state.favorites,
  teams: state.teams,
  positions: state.positions,
  matches: state.matches,
  user: state.auth.user,
  users: state.users,
  subscription: state.subscription,
  entries: state.entries,
}), {
  updateFavoriteSorts, changeEntriesFilter, removeFromFavorites, fetchAllEntries, changeLoading, resetFilter,
})

export default class Favorites extends Component {
  static propTypes = {
    favorites: PropTypes.object,
    positions: PropTypes.object,
    teams: PropTypes.object,
    fetchFavoritesInfo: PropTypes.func,
    removeFromFavorites: PropTypes.func,
    updateFavoriteSorts: PropTypes.func,
    changeEntriesFilter: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      minAge: 0,
      maxAge: 200,
      curAge: { min: 0, max: 20 },
      showAdd: false,
      name: props.entries.filter.name,
    };

    this.renderCustomPlayers = this.renderCustomPlayers.bind(this);
    this.unFavorite = this.unFavorite.bind(this);
    this.closeAddPlayer = this.closeAddPlayer.bind(this);
    this.openAddPlayer = this.openAddPlayer.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.clearAllFilters = this.clearAllFilters.bind(this);
  }

  componentDidMount() {
    this.props.changeLoading({ component: 'app', loading: true });
    this.props.fetchAllEntries().then(() => {
      this.props.changeLoading({ component: 'app', loading: false });
    });
  }

  unFavorite(event, favorite_id) {
    const { t } = this.props;
    event.preventDefault();

    if (window.confirm(t('DO_YOU_REALLY_WANT_TO_REMOVE_FAVORITE', { context: window.genderContext }))) {
      this.props.removeFromFavorites(favorite_id);
    }
  }

  openAddPlayer() {
    this.setState({ showAdd: true });
  }

  closeAddPlayer() {
    this.setState({ showAdd: false });
  }

  renderCustomPlayers() {
    const {
      favorites, positions, teams, matches, t, lng, user, entries,
    } = this.props;

    const { filter } = entries;

    if (!_.size(entries.players)) return null;

    let sortedList = entries.players.map((entry) => {
      const player = { ...entry };
      const fav = favorites.list.find((p) => p.player_id == player.player_id);

      player.position_id = player.position_id || 0;
      player.birth_date_raw = player.birth_date && player.birth_date !== '1900-01-01' ? moment().diff(player.birth_date, 'years') : 0;
      player.join_date_raw = player.join_date && player.join_date !== '1900-01-01' ? +moment(player.join_date, 'YYYY-MM-DD').unix() : 0;
      player.contract_until_raw = player.contract_until && player.contract_until !== '1900-01-01' ? +moment(player.contract_until, 'YYYY-MM-DD').unix() : 0;

      player.team_name = _.get(teams, `[${player.team_id}].name_${lng}`, _.get(teams, `[${player.team_id}].name`, '-'));
      player.position_name = _.get(positions, `[${player.position_id}].desc_short_${lng}`, _.get(positions, `[${player.position_id}].desc_short`, '-'));
      player.birth_date = player.birth_date && player.birth_date !== '1900-01-01' ? moment().diff(player.birth_date, 'years') : '–';
      player.country = player.country || '-';
      player.join_date = player.join_date && moment(player.join_date, 'YYYY-MM-DD').isValid() && moment(player.join_date, 'YYYY-MM-DD') > moment('1970-01-01', 'YYYY-MM-DD') ? moment(player.join_date, 'YYYY-MM-DD').format('DD/MM/YYYY') : '–';
      player.contract_until = player.contract_until && moment(player.contract_until, 'YYYY-MM-DD').isValid() && moment(player.contract_until, 'YYYY-MM-DD') > moment('1970-01-01', 'YYYY-MM-DD') ? moment(player.contract_until, 'YYYY-MM-DD').format('DD/MM/YYYY') : '–';
      player.sort_name = `${player.first_name} ${player.last_name}`;

      if (fav) {
        player.fav = fav;
        player.category = fav.category;
        player.groupMemberFavorite = fav.group_member_favorite;
        player.favorite_id = fav._id;
        player.sort = fav.sort;
        player.favorite_id = fav._id;
      } else {
        player.fav = undefined;
        player.category = undefined;
      }

      if (isNaN(player.birth_date)) {
        player.birth_date = '-';
      }

      return player;
    });

    if (user.role.tag !== 'user' && filter.list_user_id) {
      sortedList = sortedList.filter((p) => {
        return p.user_id == filter.list_user_id;
      });
    }

    if (filter.team && filter.team.value !== -1) {
      sortedList = sortedList.filter((p) => +p.team_id === +filter.team.value);
    }

    if (filter.position && filter.position.value !== -1) {
      sortedList = sortedList.filter((p) => +p.position_id === +filter.position.value);
    }

    if (filter.name) {
      const regexp = new RegExp(filter.name, 'i');
      sortedList = sortedList.filter((p) => {
        return regexp.test(p.sort_name);
      });
    }

    const byPosition = _.groupBy(sortedList, 'position_id');

    let positions_list = [];

    _.forOwn(byPosition, (players, position_id) => {
      positions_list.push(position_id);
    });

    positions_list = _.orderBy(positions_list, [(p_id) => (_.get(positions, `[${p_id}].upper_rank`, 100))], ['asc']);

    const sections = [];

    positions_list.map((p_id, i) => {
      const fsort = i;

      const players = byPosition[p_id]
        .sort((a, b) => _.get(fsort, `[${a.favorite_id}]`, 9999) - _.get(fsort, `[${b.favorite_id}]`, 9999))
        .map((f, i) => {
          f.sort = _.get(fsort, `[${f.favorite_id}]`, i);
          return f;
        });

      const section = (
        <EntriesList
          isEntries
          players={players}
          position_id={p_id}
          key={p_id}
          unFavorite={this.unFavorite}
          zIndex={50 - i}
          withPaging
        />
      );

      sections.push(section);
    });

    return sections;
  }

  onFilterChange({ inputValue }, filter) {
    const { changeEntriesFilter } = this.props;
    const { type, name, field, options } = filter;

    let value;
    switch (field) {
      case 'position': {
        value = _find(options, { value: inputValue });
        break;
      }
      default: {
        value = inputValue;
      }
    }

    changeEntriesFilter({
      [field]: value,
    })
  }

  clearAllFilters() {
    this.props.resetFilter();
  }

  render() {
    const {
      user, favorites, t, lng, positions, subscription, entries,
    } = this.props;
    const { list, info } = favorites;

    let position_options = _.chain(positions)
      .filter((p) => p.upper_position)
      .orderBy(['upper_rank'], ['asc'])
      .map((p) => ({ value: p.id, label: _.get(p, `desc_long_${lng}`, _.get(p, 'desc_long', 'N/D')) }))
      .value();
    const players_positions = _.uniq(list.filter((p) => info[p.player_id]).map((p) => +info[p.player_id].position_id));

    position_options = position_options.filter((pos_o) => (players_positions.indexOf(+pos_o.value) !== -1));
    position_options.unshift({ label: t('ALL_POSITIONS'), value: -1 });

    const teams_options = [{ label: t('ALL_TEAMS'), value: -1 }];

    const users = _.get(this.props.users, 'data', null);
    const entriesFilter = entries.filter;

    const scoutsOptions = _(users)
      .filter((u) => user._id !== u._id)
      .orderBy('lastName')
      .map((u) => {
        return { value: u._id, label: `${u.firstName} ${u.lastName}` };
      })
      .value();

    scoutsOptions.unshift({ value: null, label: t('ALL_SCOUTS') });
    scoutsOptions.unshift({ value: '-', label: '-', isDisabled: true });
    scoutsOptions.unshift({ value: user._id, label: t('MY_PLAYERS', { context: window.genderContext }) });

    const scoutValue = scoutsOptions.find((o) => o.value == entriesFilter.list_user_id);

    const changedFilters = Object.keys(_omitBy(entries.filter, (values, key) => key === 'name' || _isEqual(entries.filterInitial[key], values)))?.length || 0;

    const filters = [
      {
        type: 'select-teams',
        name: t('TEAM'),
        field: 'team',
        options: teams_options,
      }, {
        type: 'select',
        name: t('POSITION'),
        field: 'position',
        options: position_options,
      }
    ];

    if (user.role.tag !== 'user' && scoutsOptions.length > 3 && !subscription.isFree) {
      filters.unshift({
        type: 'select',
        name: t('FAV_BY', { context: window.genderContext }),
        field: 'list_user_id',
        options: scoutsOptions,
      })
    }

    const values = normalizeValuesForFilterConstructor(entriesFilter, filters);

    return (
      <div className="favorites">
        <div className="container">
          <div className="favorites-header">
            <PlayerSearch
              defaultName={entriesFilter.name}
              search={(value) => {
                this.props.changeEntriesFilter({ name: value });
              }}
            />

            <div className="favorites-list__filters">
              <div className="favorites-filter">
                <FiltersConstructor
                  classNames="reports-filters"
                  filters={filters}
                  values={values}
                  onFilterChange={this.onFilterChange}
                  applyFilters={() => {}}
                  renderInline
                  clearAllFilters={this.clearAllFilters}
                  BtnComponent={({ onClick, active }) => (
                    <button
                      type="button"
                      onClick={onClick}
                      className={`btn btn--filter ${active ? 'active' : ''}`}
                    >
                      <Icon name="filter" />
                      { changedFilters ? <span className="btn--filter__num">{changedFilters}</span> : null }
                    </button>
                  )}
                />
              </div>
              {
                canAddWatchlist(subscription) ? (
                  <span className="btn add-new-player" onClick={() => { this.openAddPlayer(); }}>
                      <Icon name="plus" className="create-icon" />
                    {' '}
                    {t('PLAYER', { context: window.genderContext })}
                    </span>
                ) : null
              }
            </div>
          </div>
          { !_.size(entries.players) ? (
            <div className="empty-message">{t('EMPTY_CUSTOM_LIST', { context: window.genderContext })}</div>
          ) : this.renderCustomPlayers() }
        </div>
        { this.state.showAdd ? (
          <div>
            <div className="add-player-fade" />
            <AddPlayer onlyCustom onClickOutside={() => { this.closeAddPlayer(); }} />
          </div>
        ) : null }
      </div>
    );
  }
}
