import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router';

import Indicator from './indicator';
import './indicators-list.scss';

import { Scrollbars } from 'react-custom-scrollbars';
import Icon from '../../../Icon';

function IndicatorsList({
  classNames, title, t, indicators, i18n, information, changeInnerAndOuterControl, setPlayer, values, mediaQuery, height, scrollTop,
}) {
  const lng = i18n.language;
  const [showArrow, setShowArrow] = useState(false);
  const [top, setTop] = useState(null);
  const scrollbarRef = useRef(null);

  useEffect(() => {
    if (scrollTop !== null) {
      setTop(scrollTop);
    }

    if (scrollbarRef && scrollbarRef.current) {
      const values = scrollbarRef.current.getValues();
      if (values.clientHeight >= values.scrollHeight) {
        setShowArrow(false);
      } else {
        setShowArrow(true);
      }
    }
  }, [height, mediaQuery, scrollTop]);

  let content = (
    <div className="indicators-list__body">
      <div className="indicators-list__empty">
        <p>{t('INDICATORS_EMPTY_MESSAGE')}</p>
        <Link className="btn btn-secondary" to="/settings/shortcuts">{t('ADD_IN_SETTINGS')}</Link>
      </div>
    </div>
  );

  if (indicators.length) {
    content = (
      <div className="indicators-list__body">
        {indicators.map((id) => {
          return (
            <Indicator
              values={values}
              key={id}
              id={id}
              lng={lng}
              info={information[id]}
              changeInnerAndOuterControl={changeInnerAndOuterControl}
              setPlayer={setPlayer}
              scrollTop={top}
            />
          );
        })}
      </div>
    );
  }

  if (mediaQuery !== 'mobile' && height >= 700) {
    content = (
      <Scrollbars
        ref={scrollbarRef}
        autoHide
        hideTracksWhenNotNeeded
        onUpdate={(values) => {
          if (values.top >= 0.95 || values.clientHeight >= values.scrollHeight) { setShowArrow(false); } else { setShowArrow(true); }
          setTop(values.top);
        }}
      >
        {content}
      </Scrollbars>
    );
  }

  return (
    <div className={`${classNames} indicators-list`}>
      <h4>{t(title.replaceAll(' ' ,'_').toUpperCase())}</h4>
      {content}
      {scrollbarRef.current && showArrow
        ? (
          <Icon
            onClick={() => {
              if (scrollbarRef && scrollbarRef.current) {
                const top = scrollbarRef.current.getScrollTop();
                const newTop = top + 100;
                scrollbarRef.current.scrollTop(newTop);
              }
            }}
            name="scroll-arrow"
            className="scroll-arrow"
          />
        )
        : null}
    </div>
  );
}

IndicatorsList.propTypes = {
  scrollTop: PropTypes.number,
  classNames: PropTypes.string,
  title: PropTypes.string,
  t: PropTypes.func,
  indicators: PropTypes.array,
  mediaQuery: PropTypes.string,
};

export default withTranslation()(IndicatorsList);
