import React from 'react';
import { Portal } from 'react-portal';
import { useTranslation } from 'react-i18next';
import FadeScreen from '../fade-screen';
import Icon from '../../Icon';
import CreatePlayerForm from './create-player-form';

import './create-player-form.scss';

function CreatePlayerFormModal({ ...rest }) {
  const { t } = useTranslation();

  return (
    <Portal>
      <FadeScreen>
        <div className="add-player-form-modal sp-modal">
          <div className="sp-modal__heading">
            <div className="sp-modal__name">
              <Icon name="players" />
              {t('ADD_NEW_PLAYER', { context: window.genderContext })}
            </div>
            <button
              type="reset"
              className="sp-modal__close"
              onClick={rest.onClose}
            >
              <Icon name="close" />
            </button>
          </div>
          <div className="sp-modal__body">
            <CreatePlayerForm {...rest} />
          </div>
        </div>
      </FadeScreen>
    </Portal>
  );
}

CreatePlayerFormModal.propTypes = {};

export default CreatePlayerFormModal;
