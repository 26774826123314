import './navigation.scss';

import React, { Component } from 'react';
import { Link, browserHistory } from 'react-router';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Scrollbars } from 'react-custom-scrollbars';

import { withTranslation } from 'react-i18next';
import Icon from '../Icon';

import { logout } from '../../actions/auth';

@withTranslation()
@connect((state) => ({
  auth: state.auth,
  favorites: state.favorites,
}), { logout })

export default class Navigation extends Component {
  constructor(props) {
    super(props);

    this.logout = this.logout.bind(this);
  }

  logout(event) {
    event.preventDefault();

    const { t } = this.props;

    const result = confirm(t('DO_YOU_REALLY_WANT_TO_LOGOUT'));

    if (result) {
      this.props.logout()
        .then(() => {
          window.location = '/auth/login';
        })
        .catch((error) => {
          console.error(error);
          alert(t('CANT_LOGOUT_OFFLINE'));
        });
    }
  }

  render() {
    const role = _.get(this.props, 'auth.user.role.tag', 'user');

    return (
      <div className="navigation">
        <Scrollbars>
          <img className="logo" src="/images/logo.svg" alt="" />
          <ul className="links">
            <li>
              <Link to="/" onlyActiveOnIndex activeClassName="active" className="home">
                <Icon name="nav-home" className="svg-inactive" />
                <Icon name="nav-home-active" className="svg-active" />
              </Link>
            </li>
            <li>
              <Link to="/reports/" activeClassName="active" className="reports">
                <Icon name="pitch" className="svg-inactive" />
                <Icon name="pitchactive" className="svg-active" />
              </Link>
            </li>
            <li>
              <Link to="/players/" className="favorites" activeClassName="active">
                <Icon name="players" className="svg-inactive" />
                <Icon name="playersactive" className="svg-active" />
              </Link>
            </li>
            <li>
              <Link to="/watchlists/" className="watchlists" activeClassName="active">
                <Icon name="watchlists" className="svg-inactive" />
                <Icon name="watchlistsactive" className="svg-active" />
              </Link>
            </li>
            <li>
              <Link to="/shadow-teams" className="favorites" activeClassName="active">
                <Icon name="shadowteam" className="svg-inactive" />
                <Icon name="shadowteamactive" className="svg-active" />
              </Link>
            </li>
            <li><Link to="/help/" activeClassName="active"><Icon name="help" /></Link></li>
            { role === 'admin' ? (
              <li>
                <Link to="/admin/customers/" activeClassName="active">
                  <Icon name="keys" className="svg-inactive" />
                  <Icon className="svg-active" name="keysactive" />
                </Link>
              </li>
            ) : null }
            <li><Link to="/settings/" activeClassName="active"><Icon name="gear" /></Link></li>
            <li><a onClick={this.logout}><Icon name="logout" /></a></li>
          </ul>
        </Scrollbars>
      </div>
    );
  }
}
