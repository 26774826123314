import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import Scrollbars from 'react-custom-scrollbars';
import Title from '../../pitch/title';
import Timeline from '../../pitch/timeline';
import Player from '../player';
import ShortcutsControls from '../shortcuts-conrols';
import Substitutions from '../substitutions';

import './report-main.scss';
import Icon from '../../../Icon';

const ReportMain = function ReportMain(props) {
  const {
    match,
    formations,
  } = props;
  const {
    side,
    player_id,
    layout,
    current_positions,
  } = match;
  const { mediaQuery } = layout;

  const fomrationId = _.get(match, `data[${side}].formations.lineup.id`, []);
  const currentFormation = formations[fomrationId];

  const position_id_detail = _.get(current_positions, `[${side}].lineup[${player_id}].position_id_detail`, null);
  const position = currentFormation.positions.find((i) => +i.position_id_detail === +position_id_detail);
  const position_id_upper = position?.position_id;

  const activePosition = position_id_upper ? {
    position_id_upper,
    position_id_detail,
  } : {};

  const [top, setTop] = useState(null);
  const [showArrow, setShowArrow] = useState(false);
  const scrollbarRef = useRef(null);

  useEffect(() => {
    if (scrollbarRef && scrollbarRef.current) {
      const values = scrollbarRef.current.getValues();
      if (values.clientHeight >= values.scrollHeight) {
        setShowArrow(false);
      } else {
        setShowArrow(true);
      }
    }
  }, [layout.height, mediaQuery]);

  let content = (
    <div className="scroll-container">
      <Player activePosition={activePosition} />
      <ShortcutsControls activePosition={activePosition} scrollTop={top} />
    </div>
  );

  let useScrollbars = false;
  if (mediaQuery === 'mobile' || layout.height < 700) {
    useScrollbars = true;
    content = (
      <Scrollbars
        ref={scrollbarRef}
        onUpdate={(values) => {
          setTop(values.top);
          if (values.top >= 0.95 || values.clientHeight >= values.scrollHeight) {
            setShowArrow(false);
          } else {
            setShowArrow(true);
          }
        }}
      >
        {content}
      </Scrollbars>
    );
  }

  return (
    <div className="container report-main">
      <Title />
      <div className={`player-body ${layout.height < 700 ? 'with-scroll' : null}`} style={{ height: layout.height }}>
        {content}
        {scrollbarRef.current && showArrow && useScrollbars
          ? (
            <Icon
              onClick={() => {
                if (scrollbarRef && scrollbarRef.current) {
                  const top = scrollbarRef.current.getScrollTop();
                  const newTop = top + 100;
                  scrollbarRef.current.scrollTop(newTop);
                }
              }}
              name="scroll-arrow"
              className="scroll-arrow"
            />
          )
          : null}
      </div>
      <Timeline />
    </div>
  );
};

export default connect((state) => {
  return {
    match: state.match,
    formations: state.formations,
  };
})(ReportMain);
